import {
	Bitcoin,
	BitcoinFont,
	Coin,
	CryptoDogecoin,
	CryptoSolana,
	DogecoinFont,
	DollarSign,
	Etherium,
	EtheriumFont,
	LootieFont,
	SolanaFont,
	USDollarFont,
} from 'assets/images';
import { PrimaryBtn } from 'components/common/buttons/PrimaryBtn';
import { useUserContext, useWindowDimensions } from 'hooks';
import ReactDOM from 'react-dom';
import { CurrencyOptions, useAppStore } from 'store/useAppStore';
import { usePopupStore } from 'store/usePopupStore';
import { shallow } from 'zustand/shallow';

import { screens } from '../../../constants';
import { WithPrimaryPopup } from '../../common/popups/WithPrimaryPopup';
import { FontChooseACurrency } from './assets';

const PLAY_CURRENCY_OPTIONS = [
	{
		key: CurrencyOptions.LOOTIES,
		titleImg: LootieFont,
		logo: Coin,
	},
	{
		key: CurrencyOptions.US_DOLLAR,
		titleImg: USDollarFont,
		logo: DollarSign,
	},
	{
		key: CurrencyOptions.BITCOIN,
		titleImg: BitcoinFont,
		logo: Bitcoin,
	},
	{
		key: CurrencyOptions.ETHERIUM,
		titleImg: EtheriumFont,
		logo: Etherium,
	},
	{
		key: CurrencyOptions.SOLANA,
		titleImg: SolanaFont,
		logo: CryptoSolana,
	},
	{
		key: CurrencyOptions.DOGECOIN,
		titleImg: DogecoinFont,
		logo: CryptoDogecoin,
	},
];

const getResponsiveSize = (
	screenWidth: number,
	screens: Record<string, { width: number }>,
	sizes: Record<string, number>
): string => {
	const sortedBreakpoints = Object.entries(screens).sort(
		(a, b) => b[1].width - a[1].width // Sort by width descending
	);

	for (const [key, { width }] of sortedBreakpoints) {
		if (screenWidth >= width && sizes[key] !== undefined) {
			return `${sizes[key]}px`;
		}
	}

	return `${sizes.xxs}px`; // Default to xxs if no match
};

export function MobileChooseCurrencyPopup() {
	const { selectedCurrency, setSelectedCurrency } = useAppStore(
		(state) => ({
			selectedCurrency: state.selectedCurrency,
			setSelectedCurrency: state.setSelectedCurrency,
		}),
		shallow
	);

	const { isCurrencyPopupVisible, setIsCurrencyPopupVisible } = usePopupStore((state) => ({
		setIsCurrencyPopupVisible: state.setIsCurrencyPopupVisible,
		isCurrencyPopupVisible: state.isCurrencyPopupVisible,
	}));

	const { user } = useUserContext();

	const { screenWidth } = useWindowDimensions();

	const portalRoot = document.getElementById('portal-popup-root');

	if (!portalRoot) {
		console.error('Portal root not found. Make sure the element with id "portal-popup-root" exists in your HTML.');
		return null;
	}

	return ReactDOM.createPortal(
		<WithPrimaryPopup
			childrenWrapperStyle={{ maxHeight: '90vh', width: '90vw', maxWidth: '700px' }}
			onClose={() => setIsCurrencyPopupVisible(false)}
			useAnimation={true}
			isExternallyVisible={isCurrencyPopupVisible && !!user}
		>
			<div className="flex items-end w-full justify-center gap-x-[10px] pr-[20px] mb-[30px]">
				<img
					src={FontChooseACurrency}
					className="h-[55px] ms:h-[65px] sm:h-[75px] md:h-[83px] object-contain max-w-full"
					alt=""
				/>
			</div>
			<div className="flex flex-wrap gap-[25px] w-full items-center justify-center">
				{PLAY_CURRENCY_OPTIONS.map((currency) => (
					<PrimaryBtn
						key={currency.key}
						style={{
							width: getResponsiveSize(screenWidth, screens, {
								xxs: 120,
								xs: 160,
								sm: 190,
								md: 210,
								lg: 230,
							}),
							height: getResponsiveSize(screenWidth, screens, {
								xxs: 100,
								xs: 120,
								sm: 140,
								md: 160,
								lg: 180,
							}),
							borderWidth: '4px',
						}}
						innerContentStyle={{
							borderWidth: '2px',
							borderColor: currency.key === selectedCurrency ? '#2AD100' : '#3559d7',
						}}
						onClick={() => {
							setSelectedCurrency(currency.key);
							setIsCurrencyPopupVisible(false);
						}}
						content={
							<div className="flex flex-col items-center h-full justify-evenly">
								<img
									src={currency.logo}
									alt={currency.key}
									className="h-[24px] ms:h-[30px] sm:h-[34px] md:h-[38px] lg:h-[44px]"
								/>
								<img
									src={currency.titleImg}
									alt={`${currency.key} Font`}
									className="h-[20px] ms:h-[24px] sm:h-[27px] md:h-[30px] lg:h-[35px]"
								/>
							</div>
						}
					/>
				))}
			</div>
		</WithPrimaryPopup>,
		portalRoot
	);
}
