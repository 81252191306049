import { useAutoAnimate } from '@formkit/auto-animate/react';
import { OrderDbInterface } from 'interfaces/OrderInterfaces';
import { useReturnOrdersByOrder } from 'pages/MyOrders/hooks/useReturnOrderItems';
import { useState } from 'react';

import { KeyPopup } from '../../components/KeyPopup';
import { NotePopup } from '../../components/NotePopup';
import { OrderProductRow } from '../OrderProductRow/OrderProductRow';
import { ReturnProductRow } from '../ReturnProductRow/ReturnProductRow';
import { OrderActionBtns } from './OrderActionBtns';
import { OrderReturnGroupContainer } from './OrderReturnGroupContainer';
import { OrderRowHeader } from './OrderRowHeader';
import { OrderShippingAddress } from './OrderShippingAddress';

interface Props {
	order: OrderDbInterface;
}

export function ExpandableOrderRow({ order }: Props) {
	const [isExpanded, setIsExpanded] = useState(false);
	const [keyPopup, setKeyPopup] = useState<string | undefined>();
	const [notePopup, setNotePopup] = useState<string | undefined>();

	const [parent] = useAutoAnimate();

	const { data: returnOrders } = useReturnOrdersByOrder({ orderId: order._id });

	return (
		<>
			{keyPopup && <KeyPopup onClose={() => setKeyPopup(undefined)} value={keyPopup} />}
			{notePopup && <NotePopup onClose={() => setNotePopup(undefined)} text={notePopup} />}

			<div className="border-[2px] border-white rounded-[20px] w-full md:w-[550px] lg:w-[650px] xl:w-[750px]">
				<div
					ref={parent}
					className="border-[2px] border-darkBlue flex flex-col gap-y-[15px] w-full px-[12px] py-[10px] rounded-[18px]"
					style={{
						background: 'linear-gradient(331.41deg, rgba(35, 109, 244, 0.3) 6.78%, rgba(51, 240, 167, 0.3) 204.87%)',
					}}
				>
					<OrderRowHeader
						order={order}
						isExpanded={isExpanded}
						onExpandedToogle={() => setIsExpanded((prev) => !prev)}
					/>
					{isExpanded && <OrderShippingAddress address={order.shippingAddress} />}

					{isExpanded && (
						<>
							<div className="flex flex-col gap-y-[15px] pb-[10px]">
								{order.items.map((item) => (
									<OrderProductRow
										key={item._id}
										item={item}
										onKeyPopup={(key) => setKeyPopup(key)}
										onNotePopup={(text) => setNotePopup(text)}
										order={order}
									/>
								))}
							</div>

							<OrderActionBtns order={order} />

							{returnOrders && returnOrders.length > 0 && (
								<div className="flex flex-col gap-y-[10px] mt-[10px]">
									<h1 className="font-semibold text-[17px] text-white">Product Returns</h1>

									<div className="flex flex-col gap-y-[15px]">
										{/* Show items without groupId */}
										{returnOrders
											?.flatMap((returnOrder) => returnOrder.items)
											.filter((item) => !item.groupId)
											.map((item) => <ReturnProductRow key={item._id} item={item} />)}

										{/* Show return groups and their items */}
										{returnOrders
											?.flatMap((returnOrder) => returnOrder.returnGroups)
											?.map((returnGroup, index) => (
												<OrderReturnGroupContainer
													key={index}
													returnGroup={returnGroup}
													items={returnOrders.flatMap((returnOrder) => returnOrder.items)} // Pass all items to filter in the OrderReturnGroup
												/>
											))}
									</div>
								</div>
							)}
						</>
					)}
				</div>
			</div>
		</>
	);
}
