import { useOutsideClick, useScrollLock, useUserContext } from 'hooks';
import { useGenerateNewHashedServerSeed } from 'hooks/data/user/useGenerateNewHashedServerSeed';
import { useHashedServerSeed } from 'hooks/data/user/useHashedServerSeed';
import { useWindowDimensions } from 'hooks/utility/layout-measurements/useWindowDimensions';
import { useBoxClientSeed } from 'pages/OpenBox/hooks/useBoxClientSeed';
import { useBoxOpeningStoreDesktop } from 'pages/OpenBox/store/useBoxOpeningStoreDesktop';
import { useEffect, useRef, useState } from 'react';

import { screens } from '../../../../../constants';
import { ClientSeedInput } from './ClientSeedInput';
import { ServerSeedInput } from './ServerSeedInput';
import { VerifiedFairnessHeader } from './VerifiedFairnessHeader';
import { VerifiedFairnessSwitch } from './VerifiedFairnessSwitch';

export default function VerifiedFairnessPopup() {
	const { user } = useUserContext();
	const [showSeedPage, setShowSeedPage] = useState(true);

	const { updateClientSeed } = useBoxClientSeed({ isMobile: false, initOnMount: false });

	const { setIsFairnessPopupVisible, clientSeed } = useBoxOpeningStoreDesktop();

	const { data: hashedServerSeed, isLoading: isHashedServerSeedLoading } = useHashedServerSeed();
	const { mutate: requestNewHashedServerSeed, isLoading: isGenerateNewServerSeedReqLoading } =
		useGenerateNewHashedServerSeed();

	const [localClientSeed, setLocalClientSeed] = useState('');

	const { screenHeight } = useWindowDimensions();

	const isClientBtnDisabled = !user || clientSeed === localClientSeed || isHashedServerSeedLoading;

	const popupRef = useRef<HTMLDivElement>(null);
	useScrollLock();

	useOutsideClick(popupRef, () => {
		setIsFairnessPopupVisible(false);
	});

	useEffect(() => {
		if (clientSeed) {
			setLocalClientSeed(clientSeed);
		}
	}, [clientSeed]);

	function handleClientSeedUpdate() {
		if (user && localClientSeed && localClientSeed.length > 0) {
			updateClientSeed(localClientSeed);
		}
	}

	function handleServerSeedUpdate() {
		requestNewHashedServerSeed();
	}

	return (
		<div className="z-30 fixed left-0 top-0 bg-[rgba(0,0,0,0.35)] flex items-center justify-center w-[100vw] h-[100vh] pb-4 overflow-y-scroll pt-[calc(63px+32px)]">
			<div
				ref={popupRef}
				className={`border-[3px] border-white ml-[72px] rounded-[20px]  ${screenHeight > screens.sm.height ? 'h-[300px] xl:h-[350px] xxl:h-[400px]' : 'h-fit'} w-[420px] xl:w-[450px] xxl:w-[490px]`}
			>
				<div
					className="border-[2px] border-darkBlue w-full h-full flex flex-col justify-start px-5 pt-4 pb-5 rounded-[18px] text-white"
					style={{
						background: ' linear-gradient(331.41deg, rgba(35, 109, 244, 0.7) 6.78%, rgba(51, 240, 167, 0.7) 204.87%)',
					}}
				>
					<VerifiedFairnessHeader onClose={() => setIsFairnessPopupVisible(false)} />

					{screenHeight > screens.sm.height && (
						<VerifiedFairnessSwitch
							showSeedPage={showSeedPage}
							onShowSeedPageChange={(show) => setShowSeedPage(show)}
						/>
					)}

					{showSeedPage && (
						<div className="flex-grow flex-col flex justify-around">
							<ClientSeedInput
								value={localClientSeed}
								onChange={(v) => setLocalClientSeed(v)}
								isBtnDisabled={isClientBtnDisabled}
								onUpdate={handleClientSeedUpdate}
								isInputDisabled={!user || isHashedServerSeedLoading}
							/>
							<ServerSeedInput
								value={hashedServerSeed}
								onUpdate={handleServerSeedUpdate}
								isDisabled={!user || isHashedServerSeedLoading}
								isLoading={isHashedServerSeedLoading || isGenerateNewServerSeedReqLoading}
							/>
						</div>
					)}
					{!showSeedPage && (
						<p className="text-white text-justify text-[12px] leading-[17px] xl:text-[14px] xl:leading-[19px] xxl:text-[16px] xxl:leading-[23px] flex-grow overflow-auto pr-2">
							At MrLoot, we prioritize the transparency and unpredictability of our loot draws. To achieve this, we
							employ a dual-seed mechanism: the "Client Seed" and the "Server Seed". The "Client Seed" is either
							generated by your device or input by you, ensuring that you play a part in the randomization process. On
							the other hand, the "Server Seed" is generated and secured by our system. When these two seeds are
							combined, they dictate the outcome of the loot you receive in a manner that neither we nor you can foresee
							or manipulate. Before each draw, we provide a hashed version of our server seed, so we're committed to its
							integrity. After your loot is revealed, the actual server seed gets disclosed, granting you the power to
							verify the legitimacy of your draw. This intricate process ensures a balanced and transparent approach,
							where both you, the player, and we, the platform, collaborate to determine the loot outcome.
						</p>
					)}
				</div>
			</div>
		</div>
	);
}
