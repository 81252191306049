import { faTruckFast } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
	onClick: () => void;
	isDisabled: boolean;
}

export function ShipHomeBtn({ onClick, isDisabled }: Props) {
	return (
		<button
			onClick={onClick}
			disabled={isDisabled}
			className={`space-x-[11px] text-[14px] bg-primary-gradient border-[2px] border-darkBlue outline outline-white outline-[2px] flex items-center justify-center rounded-full w-[170px] h-[40px] text-white font-semibold
        ${isDisabled && 'opacity-50'}`}
		>
			<FontAwesomeIcon icon={faTruckFast} className="text-white text-[16px]" />
			<span className="text-white">Ship Home</span>
		</button>
	);
}
