import { ItemInterface } from './ItemInterfaces';
import { BaseSelectedOptionSet } from './OrderInterfaces';

export enum OrderReturnStatus {
	INITIATED = 'initiated', // Return process has started by the customer and waits for approval by store/admin
	APPROVED = 'approved', // Return has been approved by the store/admin
	COMPLETED = 'completed', // Return process is fully completed
	CANCELLED = 'cancelled', // The return process was canceled by the customer/store
}

export enum RefundMethod {
	STORE_CREDIT = 'storeCredit', // Return process has started by the customer and waits for approval by store/admin
	RETURN_TO_INVENTORY = 'returnToInventory',
}

export enum OrderReturnItemStatus {
	PENDING_APPROVAL = 'pendingApproval', // Item's return is pending approval
	APPROVED = 'approved', // Item's return has been approved
	COMPLETED = 'completed', // Return process is fully completed
	CANCELLED = 'cancelled', // The return process was canceled by the customer/store
}

export enum ReturnReason {
	DAMAGED_DEFECTIVE = 'Damaged or defective',
	WRONG_ITEM = 'Wrong item received',
	NOT_AS_DESCRIBED = 'Not as described',
	MISSING_PARTS = 'Missing parts',
	UNWANTED_ITEM = 'Unwanted item',
	DOES_NOT_FIT = 'Does not fit',
	ARRIVED_TOO_LATE = 'Arrived too late',
	OTHER = 'Other',
}

export const orderReturnItemStatusLabels: Record<OrderReturnItemStatus, string> = {
	[OrderReturnItemStatus.PENDING_APPROVAL]: 'In Progress',
	[OrderReturnItemStatus.APPROVED]: 'Approved',
	[OrderReturnItemStatus.COMPLETED]: 'Completed',
	[OrderReturnItemStatus.CANCELLED]: 'Cancelled',
};

export enum ReturnGroupType {
	MANUAL_INSTRUCTIONS = 'manual instructions',
	QR_CODE = 'qr-code',
	PDF = 'pdf',
}

export interface OrderReturnOptionSet extends BaseSelectedOptionSet {
	orderOptionId: string;
}

export interface OrderReturnGroup {
	_id: string;
	companyPaidReturnFee: number;
	rmaNumber?: string;
	type: ReturnGroupType;
	returnInstructions?: string;
	returnInstructionQRCodeUrl?: string;
	returnInstructionPdfUrl?: string;
	trackingNumber: string;
	createdAt?: Date;
	updatedAt?: Date;
}

export interface OrderReturnItem {
	_id?: string;
	groupId?: string; // mapping to return group
	orderItemId: string;
	itemId: string | ItemInterface;
	quantity: number;
	status: OrderReturnItemStatus;
	options?: OrderReturnOptionSet[]; // Options related to the product (e.g., size, color)
	returnReason: ReturnReason; // Reason for return
	refundMethod: RefundMethod;
	userAvgPurchaseValue: number;
	supplier?: string;
	purchaseDate?: Date;
	approvedAt?: Date;
	createdAt?: Date;
	updatedAt?: Date;
}

export interface DBOrderReturnItem extends OrderReturnItem {
	_id: string;
	createdAt: Date;
	updatedAt: Date;
}

export interface DBOrderReturnItemPopulated extends DBOrderReturnItem {
	itemId: ItemInterface;
}

export interface OrderReturns {
	_id?: string;
	returnNr?: number;
	orderId: string;
	orderNr: number;
	userId: string;
	status: OrderReturnStatus;
	items: OrderReturnItem[];
	returnGroups?: OrderReturnGroup[];
	returnHandlingFee: number;
	createdAt?: Date;
	updatedAt?: Date;
}

export interface DBOrderReturns extends OrderReturns {
	_id: string;
	items: DBOrderReturnItem[];
	returnNr: number;
	updatedAt?: Date;
	createdAt: Date;
}

export interface DBOrderReturnsWithPopulatedItems extends DBOrderReturns {
	items: DBOrderReturnItemPopulated[];
}
