import { Skeleton } from 'components';
import { WithImgSkeleton } from 'components/common/WithImgSkeleton';

import { CLOUDFRONT_IMG_URL } from '../../../../constants';

interface Props {
	showSkeleton: boolean;
	landscapeImg?: string;
	boxName?: string;
}

export function MobileBoxImg({ showSkeleton, landscapeImg, boxName }: Props) {
	if (!landscapeImg) {
		return <Skeleton style={{ borderRadius: '42px', aspectRatio: '1.75 / 1', height: 'auto' }} />;
	}

	return (
		<WithImgSkeleton
			showSkeleton={showSkeleton}
			disableAnimation={true}
			style={{ borderRadius: '42px', aspectRatio: '1.75 / 1', height: 'auto' }}
		>
			<img
				src={`${CLOUDFRONT_IMG_URL}/${landscapeImg}`}
				className="object-cover w-full rounded-[42px] aspect-[1.75/1]"
				alt={boxName}
			/>
		</WithImgSkeleton>
	);
}
