import { AmountDisplayWithCurrency } from 'components/common/AmountDisplayWithCurrency';
import { PrimaryBtn } from 'components/common/buttons/PrimaryBtn';
import { SecondaryBtn } from 'components/common/buttons/SecondaryBtn';
import { ToolTip } from 'components/common/ToolTip';
import { ToastErrorNotification } from 'components/toasts/ToastErrorNotification';
import { ToastSuccessNotification } from 'components/toasts/ToastSuccessNotification';
import { useUserContext } from 'hooks';
import { usePayOutstandingOrderItemAmount } from 'pages/MyOrders/hooks/usePayOutstandingOrderItemAmount';
import { useRefundOrderItem } from 'pages/MyOrders/hooks/useRefundOrderItem';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { QUERY_KEYS } from '../../.././../../../constants';

interface Props {
	outstandingAmount: number;
	orderId: string;
	orderItemId: string;
	colorTheme?: 'blue' | 'white';
}

export function OutstandingInvoice({ outstandingAmount, orderId, orderItemId, colorTheme = 'white' }: Props) {
	const queryClient = useQueryClient();

	const { user, load: reloadUser } = useUserContext();

	const { mutate: refundOrderItem, isLoading: isRefundItemLoading } = useRefundOrderItem({
		onSuccess: () => {
			queryClient.invalidateQueries(QUERY_KEYS.orderData);
			queryClient.invalidateQueries(QUERY_KEYS.inventoryItemData);
			toast(<ToastSuccessNotification message="Item has been refunded" />);
		},
		onError: (error) => {
			toast(<ToastErrorNotification message={error} />);
		},
	});

	const { mutate: payOutstandingAmount, isLoading: isPayOutstandingAmountLoading } = usePayOutstandingOrderItemAmount({
		onSuccess: () => {
			queryClient.invalidateQueries(QUERY_KEYS.orderData);
			reloadUser();
			toast(<ToastSuccessNotification message="Payment was successful. Product is going to be shipped soon" />);
		},
		onError: (error) => {
			toast(<ToastErrorNotification message={error} />);
		},
	});

	function handlePayOutstandingAmount() {
		if (user && user.balance < outstandingAmount) {
			toast(<ToastErrorNotification message="Balance is too low" />);
			return;
		}
		payOutstandingAmount({ orderId, orderItemId });
	}

	return (
		<div className="flex items-center justify-between gap-x-[8px] mt-[3px]">
			<div className={`flex items-center gap-x-[5px] ${colorTheme === 'white' ? 'text-white' : 'text-black'}`}>
				<h1 className="text-[15px] font-semibold ">Notice</h1>
				<ToolTip
					message="Ooops! The price of this product has increased due to market changes"
					iconSize={18}
					position="top-left"
				/>
			</div>
			<PrimaryBtn
				onClick={handlePayOutstandingAmount}
				isDisabled={isPayOutstandingAmountLoading || isRefundItemLoading}
				style={{ height: '35px', width: '130px', boxShadow: '0 4px 5px rgba(0, 0, 0, 0.3)' }}
				content={
					<div className="flex items-center text-[13px] gap-x-[2px]">
						<span className="mr-1">Pay</span>
						<AmountDisplayWithCurrency amount={outstandingAmount} useDivWrapper={false} imgStyle={{ height: '14px' }} />
					</div>
				}
			/>
			<SecondaryBtn
				onClick={() => refundOrderItem({ orderId, orderItemId })}
				isDisabled={isPayOutstandingAmountLoading || isRefundItemLoading}
				content="Cancel"
				innerContentStyle={{
					background: 'linear-gradient(331.41deg, rgba(35, 109, 244, 0.7) 6.78%, rgba(51, 240, 167, 0.7) 204.87%)',
				}}
				style={{
					height: '35px',
					width: '130px',
					fontSize: '13px',
					boxShadow: '0 4px 5px rgba(0, 0, 0, 0.3)',
				}}
			/>
		</div>
	);
}
