import { Skeleton } from 'components';
import { useWindowDimensions } from 'hooks';

import { screens } from '../../../../constants';

export function SkeletonMobileBoxCard() {
	const { screenWidth } = useWindowDimensions();
	const cardHeight = screenWidth > screens.sm.width ? '290px' : '224px';
	return <Skeleton style={{ borderRadius: '18px', aspectRatio: '0.65 / 1', height: cardHeight, width: 'auto' }} />;
}
