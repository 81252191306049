import { Howl } from 'howler';
import { useCallback, useEffect, useRef } from 'react';

type UseRandomSoundOptions = {
	src: string;
	volume?: number;
	loop?: boolean;
	minPlays: number; // Minimum number of plays
	maxPlays: number; // Maximum number of plays
	durationMS: number; // Time span in milliseconds
};

type UseRandomSoundReturn = {
	start: () => void;
	stop: () => void;
};

export const usePlaySoundRandomly = (options: UseRandomSoundOptions): UseRandomSoundReturn => {
	const soundRef = useRef<Howl | null>(null);
	const timeoutRef = useRef<NodeJS.Timeout | null>(null);
	const playsRef = useRef(0); // Track the number of plays
	const consumedTimeRef = useRef(0); // Track total time consumed by sound plays

	useEffect(() => {
		soundRef.current = new Howl({
			src: options.src,
			volume: options.volume || 1,
			loop: false, // We handle looping manually for random plays
		});

		return () => {
			soundRef.current?.unload();
			stop(); // Clean up any timeouts
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [options.src, options.volume, options.loop]);

	const stop = useCallback(() => {
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
		}
		soundRef.current?.stop();
		playsRef.current = 0;
		consumedTimeRef.current = 0;
	}, []);

	const getRandomDelay = useCallback((): number => {
		const soundDurationMs = soundRef.current ? soundRef.current.duration() * 1000 : 0;
		const remainingTime = options.durationMS - consumedTimeRef.current; // Remaining time to distribute
		const remainingPlays = options.maxPlays - playsRef.current;

		// Calculate the maximum possible delay based on remaining time and plays
		const maxDelay =
			remainingPlays > 1
				? (remainingTime - soundDurationMs * remainingPlays) / remainingPlays
				: remainingTime - soundDurationMs;

		// Generate a random delay, but cap it at maxDelay to prevent overshooting
		const randomDelay = Math.random() * maxDelay;

		return randomDelay;
	}, [options.durationMS, options.maxPlays]);

	const playNextSound = useCallback(
		(remainingPlays: number) => {
			if (!soundRef.current || remainingPlays <= 0) {
				stop();
				return;
			}

			soundRef.current.play();
			playsRef.current += 1;

			const soundDurationMs = soundRef.current.duration() * 1000;
			consumedTimeRef.current += soundDurationMs; // Add sound duration to consumed time

			soundRef.current.once('end', () => {
				const randomDelay = getRandomDelay();
				consumedTimeRef.current += randomDelay; // Add delay to consumed time

				timeoutRef.current = setTimeout(() => {
					playNextSound(remainingPlays - 1);
				}, randomDelay);
			});
		},
		[getRandomDelay, stop]
	);

	const start = useCallback(() => {
		if (soundRef.current?.playing() || !soundRef.current) {
			return;
		}

		playsRef.current = 0;
		consumedTimeRef.current = 0;

		const numberOfPlays = Math.floor(Math.random() * (options.maxPlays - options.minPlays + 1)) + options.minPlays;

		// Get a random delay for the first sound just like subsequent sounds
		const initialRandomDelay = getRandomDelay();

		// Apply the random delay before starting the first sound
		timeoutRef.current = setTimeout(() => {
			playNextSound(numberOfPlays);
		}, initialRandomDelay);
	}, [getRandomDelay, options.maxPlays, options.minPlays, playNextSound]);

	return {
		start,
		stop,
	};
};
