import { Skeleton } from 'components';

const Header = ({ totalItems, heading }: { totalItems: number; heading: string }) => (
	<div className="flex items-center justify-between px-mobile-side-padding w-full">
		<h2 className="text-mrLootBlue font-bold text-[16px]">{heading}</h2>
		<span className="text-[15px] text-[#0C2638]">
			{totalItems} {totalItems > 1 ? 'Items' : 'Item'}
		</span>
	</div>
);

const HeaderSkeleton = () => (
	<div className="flex items-center justify-between px-mobile-side-padding w-full">
		<Skeleton style={{ height: '25px', width: '106px' }} color="#d1d1d6" />
		<Skeleton style={{ height: '23px', width: '50px' }} color="#d1d1d6" />
	</div>
);

interface Props {
	heading: string;
	isLoading?: boolean;
	totalItems?: number;
}

export function ProductsHeader({ heading, isLoading, totalItems }: Props) {
	return <>{isLoading || !totalItems ? <HeaderSkeleton /> : <Header heading={heading} totalItems={totalItems} />}</>;
}
