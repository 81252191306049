import { useCallback } from 'react';

interface RecordBoxOpenProps {
	boxId: string;
	amountSpent: number;
	numBoxesOpened: number;
	amountWon?: number;
	boxCategory: string;
	boxName?: string;
}

const generateTransactionId = () => {
	// eslint-disable-next-line no-magic-numbers
	return `${Date.now()}-${Math.random().toString(36).substring(2, 10)}`;
};

export const useGTMBoxOpenTracker = () => {
	const recordBoxOpen = useCallback(
		({ amountSpent, boxId, numBoxesOpened, amountWon, boxCategory, boxName }: RecordBoxOpenProps) => {
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				event: 'boxOpen',
				opened_box_ids: [boxId],
				box_name: boxName,
				opening_transaction_id: generateTransactionId(),
				num_opened_boxes: numBoxesOpened,
				boxOpenPrice: amountSpent,
				won_opening_amount: amountWon,
				box_category: boxCategory,
				boxes: [
					{
						item_id: boxId, // Unique identifier for the box
						item_name: boxName, // Name of the box
						price: amountSpent, // Price of the box opening
						quantity: numBoxesOpened, // Number of boxes opened in this transaction
						category: boxCategory, // Box category (e.g., "Premium", "Gold", etc.)
						won_value: amountWon, // Custom parameter to track the won amount
					},
				],
			});
		},
		[]
	);

	return recordBoxOpen;
};
