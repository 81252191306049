import { Carousel } from 'components';
import { LoadSpinner } from 'components/common/loading/LoadSpinner';
import { CarouselList } from 'components/data-display/carousel/CarouselList';
import { useInfiniteScroll } from 'hooks';
import { useCallback, useRef } from 'react';

import { useCheapBoxContainer } from '../hooks/useCheapBoxContainer';
import { usePopularBoxes } from '../hooks/usePopularBoxes';
import { useRecommendationContainerLoader } from '../hooks/useRecommendationContainerLoader';
import { useScrollPositionAboveRef } from '../hooks/useScrollPositionAboveRef';
import Top10BoxCarousel from './top10BoxCarousel/Top10BoxCarousel';

const DEFAULT_PAGE_SIZE = 5;

export function RecommendedBoxCarousels() {
	const {
		data: recommendedContainer,
		isLoading: isRecommendedContainersLoading,
		loadMore,
		hasNextPage,
	} = useRecommendationContainerLoader({
		limit: DEFAULT_PAGE_SIZE,
	});

	//const { isLoading: isNewestBoxesLoading, data: newestBoxes } = useLoadNewestBoxes();
	const { isLoading: isPopularBoxesLoading, data: popularBoxes } = usePopularBoxes();
	const { isLoading: isCheapBoxContainerLoading, data: cheapBoxContainer } = useCheapBoxContainer();

	const containerLoaderRef = useRef<HTMLDivElement>(null);

	const handleLoadOnInfiniteScroll = useCallback(() => {
		if (hasNextPage && !isRecommendedContainersLoading) {
			loadMore();
		}
	}, [hasNextPage, isRecommendedContainersLoading, loadMore]);

	useInfiniteScroll(containerLoaderRef, handleLoadOnInfiniteScroll, recommendedContainer?.length);

	const top10Ref = useRef<HTMLDivElement>(null);
	const isAboveTop10 = useScrollPositionAboveRef({ top10Ref });

	return (
		<div className="flex flex-col mt-[30px]">
			<Carousel label="Popular WinBoxes" isLoading={isPopularBoxesLoading} boxList={popularBoxes} />
			<div className={`flex flex-col ${!isAboveTop10 && 'translate-y-[calc(100vh-340px)]'}`}>
				<Carousel
					label={cheapBoxContainer?.containerName}
					isLoading={isCheapBoxContainerLoading}
					boxList={cheapBoxContainer?.boxes}
				/>
			</div>

			{recommendedContainer && (
				<>
					<div ref={top10Ref}>
						<Top10BoxCarousel style={{ marginBottom: '30px' }} />
					</div>

					<CarouselList
						carousels={recommendedContainer}
						isLoading={isRecommendedContainersLoading}
						numLoadSkeletons={3}
					/>
					{recommendedContainer && isRecommendedContainersLoading && (
						<div className="w-full flex items-center justify-center mt-[30px]">
							<LoadSpinner size="34px" />
						</div>
					)}
					<div ref={containerLoaderRef} />
				</>
			)}
		</div>
	);
}
