/* eslint-disable no-magic-numbers */
import { useEffect, useState } from 'react';

interface TimeInterface {
	days: number;
	hours: number;
	minutes: number;
	seconds: number;
}

interface Props {
	targetDate: Date;
}

export function CountdownTimer({ targetDate }: Props) {
	const [timeLeft, setTimeLeft] = useState<TimeInterface>(calculateTimeLeft());

	function calculateTimeLeft() {
		const difference = +new Date(targetDate) - +new Date();
		let timeLeft: TimeInterface = { days: 0, hours: 0, minutes: 0, seconds: 0 };

		if (difference > 0) {
			timeLeft = {
				days: Math.floor(difference / (1000 * 60 * 60 * 24)),
				hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
				minutes: Math.floor((difference / 1000 / 60) % 60),
				seconds: Math.floor((difference / 1000) % 60),
			};
		}

		return timeLeft;
	}

	useEffect(() => {
		const timer = setTimeout(() => {
			setTimeLeft(calculateTimeLeft());
		}, 1000);

		// Cleanup the interval on component unmount
		return () => clearTimeout(timer);
	});

	return (
		<>
			{Object.keys(timeLeft).length > 0 ? (
				<span>
					{timeLeft.days}d {timeLeft.hours}h {timeLeft.minutes}m {timeLeft.seconds}s
				</span>
			) : (
				<span>0d 0h 0m 0s</span>
			)}
		</>
	);
}
