import { AmountDisplayWithCurrency } from 'components/common/AmountDisplayWithCurrency';
import { ItemInterface } from 'interfaces/ItemInterfaces';
import { CSSProperties } from 'react';
import { useNavigate } from 'react-router-dom';

import { CLOUDFRONT_IMG_URL } from '../../../constants';

function getProductLink(itemName: string) {
	// Replace existing hyphens with double hyphens to differentiate
	const tempName = itemName.replace(/-/g, '--');
	// Replace spaces with single hyphen
	const formattedName = tempName.replace(/ /g, '-');
	return encodeURIComponent(formattedName);
}

interface Props {
	item: ItemInterface;
	style?: CSSProperties;
}

export function ProductCard({ item, style }: Props) {
	const navigate = useNavigate();

	function handleProductClick(itemName: string) {
		const productLink = getProductLink(itemName);
		navigate(`/shop/${productLink}`);
	}

	return (
		<button
			onClick={() => handleProductClick(item.name)}
			className="group border-[1px] border-white rounded-[16px] h-[240px] w-full flex flex-col items-center justify-between px-[20px] py-[20px] hover:border-mrLootBlue"
			style={style}
		>
			<div className="bg-[rgba(255,255,255,0.7)] rounded-[8px] h-[120px] w-full p-3">
				{/* Default image */}
				<img
					src={`${CLOUDFRONT_IMG_URL}/${item.imageUrls.qualityMedium}`}
					alt={item.name}
					className="h-full w-full object-contain group-hover:hidden"
				/>

				{/* Hover image if available */}
				{item.showcaseImgUrls?.[0] && (
					<img
						src={`${CLOUDFRONT_IMG_URL}/${item.showcaseImgUrls[0]}`}
						alt={item.name}
						className="h-full w-full object-contain hidden group-hover:block"
					/>
				)}
			</div>
			<h2 className="text-[14px] text-center font-semibold line-clamp-2 text-white group-hover:text-mrLootBlue">
				{item.name}
			</h2>
			<div className="flex items-center gap-x-[6px] text-[15px] text-white group-hover:text-mrLootBlue">
				<AmountDisplayWithCurrency amount={item.price} useDivWrapper={false} />
			</div>
		</button>
	);
}
