import { useEffect } from 'react';
import { useAppStore } from 'store/useAppStore';
import { shallow } from 'zustand/shallow';

export function useBackBtnInTopBar() {
	const setShowBackBtnInTopBar = useAppStore((state) => state.setShowBackBtnInTopBar, shallow);

	useEffect(() => {
		setShowBackBtnInTopBar(true);
		return () => setShowBackBtnInTopBar(false);
	}, [setShowBackBtnInTopBar]);
}
