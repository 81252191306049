import { BoxSearchResultInterface } from 'hooks';
import { PopulatedBoxInterface } from 'interfaces/BoxInterface';
import { InfiniteData } from 'react-query';
import { create } from 'zustand';

interface SearchState {
	searchQuery: string;
	setSearchQuery: (newQuery: string) => void;
	savedRandomBoxes: PopulatedBoxInterface[] | null; // to save random boxes when navigating away
	setSavedRandomBoxes: (boxes: PopulatedBoxInterface[] | null) => void;
	searchPage: number | null;
	setSearchPage: (page: number) => void;
	savedSearchBoxes: InfiniteData<BoxSearchResultInterface> | undefined;
	setSavedSearchBoxes: (boxes: InfiniteData<BoxSearchResultInterface> | undefined) => void;
	resetStore: () => void;
}

export const useSearchStore = create<SearchState>((set) => ({
	searchQuery: '',
	setSearchQuery: (newQuery: string) => set({ searchQuery: newQuery }),
	savedRandomBoxes: null,
	savedSearchBoxes: undefined,
	setSavedRandomBoxes: (boxes: PopulatedBoxInterface[] | null) => set({ savedRandomBoxes: boxes }),
	setSavedSearchBoxes: (boxes: InfiniteData<BoxSearchResultInterface> | undefined) => set({ savedSearchBoxes: boxes }),
	searchPage: null,
	setSearchPage: (page) => set({ searchPage: page }),
	resetStore: () =>
		set({
			searchQuery: '',
			savedRandomBoxes: null,
			searchPage: null,
			savedSearchBoxes: undefined,
		}),
}));
