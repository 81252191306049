import { useAutoAnimate } from '@formkit/auto-animate/react';
import { useUserContext } from 'hooks';
import { useCheckoutStore } from 'pages/checkout/store/CheckoutStore';
import { useState } from 'react';

import { AddShippingAddressBtn } from './AddShippingAddressBtn';
import { FilledAddressCard } from './FilledAddressCard';

export function ShippingAddressList() {
	const { user } = useUserContext();
	const [isEditMode, setIsEditMode] = useState(false);

	const { selectedShippingAddress, updateSelectedAddress } = useCheckoutStore();
	const [animateParent] = useAutoAnimate();

	return (
		<div ref={animateParent} className="flex flex-col gap-y-[7px]">
			<div className="w-full flex items-center justify-between !mt-[30px] !mb-[15px]">
				<h2 className="text-white font-semibold text-[18px] ">Shipping Address</h2>
				<button onClick={() => setIsEditMode((prev) => !prev)} className="font-semibold text-[15px] text-mrLootBlue">
					{isEditMode ? 'select' : 'edit'}
				</button>
			</div>

			{user?.addresses.map((address) => (
				<FilledAddressCard
					key={address._id}
					address={address}
					isSelected={address._id === selectedShippingAddress?._id}
					onClick={() => updateSelectedAddress(address)}
					isEditMode={isEditMode}
				/>
			))}
			<AddShippingAddressBtn style={{ marginTop: '7px' }} />
		</div>
	);
}
