import { RefundMethod, ReturnReason } from 'interfaces/OrderReturns.interface';

export enum UIOrderStatus {
	TO_RECEIVE = 'to-receive',
	COMPLETED = 'completed',
	CANCELLED = 'cancelled',
}

export interface ReturnFormItemOption {
	id: string;
	value: string;
}

export interface ReturnCreationForm {
	items: ReturnCreationFormItem[];
}

export interface ReturnCreationFormItem {
	checked: boolean;
	quantity: number;
	selectedOptions: ReturnFormItemOption[];
	returnReason: ReturnReason;
	refundMethod: RefundMethod;
	itemId: string;
	orderItemId: string;
	userAvgPurchaseValue: number;
}
