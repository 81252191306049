import { CheckBox } from 'components';
import { Link } from 'react-router-dom';

interface Props {
	isShippingPolicyChecked: boolean;
	onIsShippingPolicyCheckedChange: () => void;
}

export function Step2({ isShippingPolicyChecked, onIsShippingPolicyCheckedChange }: Props) {
	return (
		<div className="flex-col space-y-[35px] w-full py-[32px] border-[3px] rounded-[24px] shadow-[0_4px_4px_rgba(0,0,0,0.25)] border-white01 px-[28px] text-white">
			<h1 className="text-[24px]">2. Order Assignment</h1>
			<div className="flex items-center w-full justify-start text-[14px] space-x-[20px]">
				<CheckBox
					checked={isShippingPolicyChecked}
					onClick={onIsShippingPolicyCheckedChange}
					borderColor="rgba(255,255,255,0.2)"
					borderWidth="2px"
					backgroundColor="white"
					borderRadius="4px"
					strokeColor="#1A1C1E"
					size={{ height: '19px', width: '19px' }}
				/>
				<span>
					I have read and agree to the{' '}
					<Link to="shipping-policy" className="underline">
						Shipping Policy
					</Link>{' '}
					and wish to proceed with shipping
				</span>
			</div>
		</div>
	);
}
