import { CSSProperties } from 'react';

interface Props {
	heading: string;
	subHeading: string;
	style?: CSSProperties;
}

export function MobileCheckoutHeading({ heading, subHeading, style }: Props) {
	return (
		<div className="flex flex-col text-white items-center w-full px-mobile-side-padding" style={style}>
			<h1 className="text-[22px] font-semibold leading-[24px]">{heading}</h1>
			<span className="text-[13px] text-center">{subHeading}</span>
		</div>
	);
}
