import { ErrorMrloot } from 'assets/images';

interface Props {
	message: string;
}

export function ToastInfoNotification({ message }: Props) {
	return (
		<div className="flex items-center">
			<img src={ErrorMrloot} alt="Error" className="min-h-[70px] h-[75px] flex-grow max-w-[25%] object-contain" />
			<span className="text-center text-white font-bold text-[15px] mx-auto max-w-[70%]">{message}</span>
		</div>
	);
}
