import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useState } from 'react';

import { MobileDropdownOverlay } from './MobileDropdownOverlay';

interface Props {
	heading: string;
	options: string[];
	value: string;
	onChange: (options: string) => void;
	hasError?: boolean;
	placeholder?: string;
}

export function MobileDropdown({ onChange, options, value = '', hasError, heading, placeholder }: Props) {
	const [isOverlayVisible, setIsOverlayVisible] = useState(false);

	const handleClose = useCallback(() => {
		setIsOverlayVisible(false);
	}, []);

	return (
		<>
			<button
				type="button"
				onClick={() => setIsOverlayVisible(true)}
				className={`relative w-[110px] h-[55px] ${hasError ? 'border-errorRed' : 'border-black'} border-[1px] rounded-[4px] flex items-center px-[12px]`}
			>
				<span
					className={`text-[15px] mx-auto line-clamp-2 font-semibold ${value.length === 0 ? 'text-[rgba(0,0,0,0.6)]' : 'text-black'} text-wrap`}
				>
					{value.length === 0 ? (placeholder ?? 'Select') : value}
				</span>
				<FontAwesomeIcon icon={faChevronDown} className=" text-mrLootBlue ml-auto" />
			</button>

			{isOverlayVisible && (
				<MobileDropdownOverlay
					onClose={handleClose}
					onChange={onChange}
					options={options}
					value={value}
					heading={heading}
				/>
			)}
		</>
	);
}
