import { SecondaryBtn } from 'components/common/buttons/SecondaryBtn';
import { DBOrderReturnItemPopulated, OrderReturnGroup, ReturnGroupType } from 'interfaces/OrderReturns.interface';
import { useState } from 'react';

import { ReturnInstructionPopup } from '../../components/ReturnInstructionPopup/ReturnInstructionPopup';
import { ReturnProductRow } from '../ReturnProductRow/ReturnProductRow';

interface Props {
	returnGroup?: OrderReturnGroup;
	items: DBOrderReturnItemPopulated[];
}

export function OrderReturnGroupContainer({ items, returnGroup }: Props) {
	const [showReturnInstructionPopup, setShowReturnInstructionPopup] = useState(false);

	if (!returnGroup) {
		return null;
	}
	// Filter the items that belong to this returnGroup by matching the groupId
	const filteredItems = items.filter((item) => item.groupId === returnGroup._id);

	const header = returnGroup.type === ReturnGroupType.QR_CODE ? 'QR Code' : 'Instructions';
	const showInstructionBtn =
		!!returnGroup.returnInstructionPdfUrl || returnGroup.returnInstructionQRCodeUrl || returnGroup.returnInstructions;

	return (
		<>
			{showReturnInstructionPopup && (
				<ReturnInstructionPopup onClose={() => setShowReturnInstructionPopup(false)} returnGroup={returnGroup} />
			)}

			{filteredItems.length > 0 && (
				<div className="grid grid-cols-[auto_130px] gap-[30px] p-[10px] border border-orange-500 rounded-[20px]">
					<div className="flex flex-col gap-y-[10px]">
						<h2 className="font-semibold text-white text-[18px]">Return Label</h2>
						{filteredItems.map((item) => (
							<ReturnProductRow key={item._id} item={item} style={{ border: 'none', padding: '0px' }} />
						))}
					</div>
					{showInstructionBtn && (
						<div>
							<SecondaryBtn
								content={header}
								style={{ fontSize: '14px' }}
								onClick={() => setShowReturnInstructionPopup(true)}
							/>
						</div>
					)}
				</div>
			)}
		</>
	);
}
