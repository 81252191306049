import { PrimaryBtn } from 'components/common/buttons/PrimaryBtn';

import { CookieIcon } from '../assets';

interface Props {
	onClick: () => void;
}

export function CookieAcceptBtn({ onClick }: Props) {
	return (
		<PrimaryBtn
			onClick={onClick}
			content={
				<div className="flex items-center gap-x-[4px] justify-center">
					<img src={CookieIcon} alt="" className="h-[13px] object-contain" />
					<span className="font-bold text-[14px]">Accept cookies</span>
				</div>
			}
			style={{ width: '170px', height: '46px' }}
		/>
	);
}
