import { PopulatedBoxInterface } from 'interfaces/BoxInterface';
import { PrizeInterface } from 'interfaces/ItemInterfaces';
import { NUM_PRE_SLOT_PRIZES, NUM_SURROUNDING_PRIZES } from 'pages/OpenBox/box-opening.constants';
import { generateSlotItems } from 'pages/OpenBox/util';
import { useCallback } from 'react';

interface Props {
	numPreSlotPrizes?: number;
}

/**
 * provides functions to generate various types of slot items used in the box-opening
 * animation, including surrounding prizes, pre/post slot items, demo spin prizes, and pre-slot
 * prizes on spin continuation.
 *
 * @returns {Object} - Contains the following functions:
 *
 * - `generateNewSurroundingSlotPrizes`: Generates surrounding slot items based on the specified number
 *   for the given box.
 *   @param {PopulatedBoxInterface} box - The box object for which slot items are generated.
 *   @returns {PrizeInterface[]} An array of surrounding slot prize items.
 *
 * - `generatePreAndPostSlotItems`: Generates both pre and surrounding slot items for initial slot setup.
 *   @param {PopulatedBoxInterface} box - The box object for which slot items are generated.
 *   @returns {Object} An object containing:
 *     - `preSlotItems`: Prizes that appear in the pre-slot position.
 *     - `surroundingSlotItems`: Prizes that surround the main slot.
 *
 * - `generateDemoSpinPrize`: Generates a single prize for a demo spin.
 *   @param {PopulatedBoxInterface} box - The box object for generating a demo prize.
 *   @returns {PrizeInterface} A single prize for demonstration purposes.
 *
 * - `generatePreSlotPrizesOnSpinContinue`: Generates pre-slot prizes when a spin is continued, including
 *   the previously won prize and surrounding items.
 *   @param {PopulatedBoxInterface} box - The box object for which slot items are generated.
 *   @param {PrizeInterface} wonPrize - The prize that was won in the previous spin.
 *   @param {PrizeInterface[]} slotPrizesSurroundingWon - Prizes surrounding the won prize.
 *   @returns {PrizeInterface[]} An array of pre-slot items for continued spins.
 */
export function useBoxSlotItems({ numPreSlotPrizes = NUM_PRE_SLOT_PRIZES }: Props = {}) {
	const generateNewSurroundingSlotPrizes = useCallback((box: PopulatedBoxInterface, isBonusSpin?: boolean) => {
		// eslint-disable-next-line no-magic-numbers
		const bonusMinProb = 0.04;
		const bonusSkipN = 5;
		const newSurroundingSlotItems = generateSlotItems(
			box,
			NUM_SURROUNDING_PRIZES,
			isBonusSpin,
			bonusMinProb,
			bonusSkipN
		);
		return newSurroundingSlotItems;
	}, []);

	const generatePreAndPostSlotItems = useCallback(
		(box: PopulatedBoxInterface, isBonusSpin?: boolean) => {
			const preSlotItems = generateSlotItems(box, numPreSlotPrizes, isBonusSpin);
			const surroundingSlotItems = generateNewSurroundingSlotPrizes(box, isBonusSpin);
			return { surroundingSlotItems, preSlotItems };
		},
		[generateNewSurroundingSlotPrizes, numPreSlotPrizes]
	);

	const generateDemoSpinPrize = useCallback((box: PopulatedBoxInterface) => {
		const newPrize = generateSlotItems(box, 1)[0];
		return newPrize;
	}, []);

	const generatePreSlotPrizesOnSpinContinue = useCallback(
		(box: PopulatedBoxInterface, wonPrize: PrizeInterface, slotPrizesSurroundingWon: PrizeInterface[]) => {
			if (!slotPrizesSurroundingWon || !wonPrize) {
				return;
			}
			const lenSurroundingWonPrizes = slotPrizesSurroundingWon.length;
			const firstHalf = slotPrizesSurroundingWon.slice(0, lenSurroundingWonPrizes / 2);
			const secondHalf = slotPrizesSurroundingWon.slice(lenSurroundingWonPrizes / 2, lenSurroundingWonPrizes);
			const furtherGenerateNum = numPreSlotPrizes - lenSurroundingWonPrizes - 1; // -1 for for last won prize added
			const newPreSlotItems = [...firstHalf, wonPrize, ...secondHalf, ...generateSlotItems(box, furtherGenerateNum)];
			return newPreSlotItems;
		},
		[numPreSlotPrizes]
	);

	return {
		generatePreAndPostSlotItems,
		generateDemoSpinPrize,
		generatePreSlotPrizesOnSpinContinue,
		generateNewSurroundingSlotPrizes,
	};
}
