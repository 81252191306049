import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Line } from 'components/common/Line';
import { ItemOrderStatus, OrderDbInterface } from 'interfaces/OrderInterfaces';
import { useMemo, useState } from 'react';

import { ProductInfoRow } from '../components/ProductInfoRow/ProductInfoRow';
import { InfoBlockHeader } from './InfoBlockHeader';
import { InfoBlockSummaryFooter } from './InfoBlockSummaryFooter';

interface Props {
	order: OrderDbInterface;
}

export function OrderInfoBlock({ order }: Props) {
	const [showAllItems, setShowAllItems] = useState(false);

	const displayedItems = useMemo(
		() => (showAllItems ? order.items : order.items.slice(0, 1)),
		[showAllItems, order.items]
	);

	const showNoticePoint =
		order.items.some((item, index) => index > 0 && item.status === ItemOrderStatus.AWAITING_PAYMENT) && !showAllItems;

	return (
		<div className="bg-mrLootBlue flex flex-col w-full py-[15px] gap-y-[15px]">
			<InfoBlockHeader order={order} status={order.publicStatus} />
			<Line style={{ backgroundColor: '#DBDBDB', height: '0.5px' }} />
			<div className="flex flex-col gap-y-[23px] px-[20px]">
				{displayedItems.map((item) => (
					<ProductInfoRow key={item._id} orderId={order._id} orderedProduct={item} />
				))}
				{order.items.length > 1 && (
					<button
						onClick={() => setShowAllItems((prev) => !prev)}
						className="relative flex items-center gap-x-[8px] text-white text-[15px] w-fit"
					>
						{showNoticePoint && (
							<span className="absolute -right-[13px] top-1/2 -translate-y-1/2 bg-red-500 animate-pulse w-[8px] h-[8px] rounded-full" />
						)}
						<FontAwesomeIcon icon={showAllItems ? faMinus : faPlus} />
						<span className="font-semibold">{showAllItems ? 'Show Less' : 'Show More'}</span>
					</button>
				)}
				<Line />
				<InfoBlockSummaryFooter order={order} />
			</div>
		</div>
	);
}
