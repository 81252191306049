interface Props {
	width?: number;
	height?: number;
}

export function IconCircleInfo({ height = 24, width = 24 }: Props = {}) {
	return (
		<>
			{/* Icon used under Creative Commons Attribution 4.0 International (CC BY 4.0) License */}
			{/* No changes were made to this icon. */}
			{/* License details: https://creativecommons.org/licenses/by/4.0/ */}
			<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
				<path
					fill="none"
					stroke="currentColor"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
					d="M12 11v5m0 5a9 9 0 1 1 0-18a9 9 0 0 1 0 18m.05-13v.1h-.1V8z"
				/>
			</svg>
		</>
	);
}
