import { Line } from 'components/common/Line';
import { useReturnOrdersByOrder } from 'pages/MyOrders/hooks/useReturnOrderItems';

import { ReturnProductInfoRow } from '../MyOrdersOverview/components/ReturnProductInfoRow/ReturnProductInfoRow';
import { MobileReturnGroupContainer } from './MobileReturnGroupContainer';
import { ProductsHeader } from './ProductsHeader';

interface Props {
	orderId: string;
}

export function ReturnProductsInfo({ orderId }: Props) {
	const { data: returnOrders } = useReturnOrdersByOrder({ orderId: orderId });

	return (
		<>
			{returnOrders && returnOrders.length > 0 && (
				<div className="w-full flex flex-col py-[15px] gap-y-[15px]">
					<div className="flex flex-col gap-y-[15px]">
						{/* Show items without groupId */}
						<div className="flex flex-col gap-y-[15px] px-mobile-side-padding bg-white pt-[15px] pb-[10px]">
							<ProductsHeader
								totalItems={
									returnOrders?.flatMap((returnOrder) => returnOrder.items).filter((item) => !item.groupId).length
								}
								heading="Product Return"
							/>

							<Line style={{ backgroundColor: '#DBDBDB' }} />
							{returnOrders
								?.flatMap((returnOrder) => returnOrder.items)
								.filter((item) => !item.groupId)
								.map((item, index) => <ReturnProductInfoRow key={index} returnOrderItem={item} />)}
						</div>

						{/* Show return groups and their items */}
						{returnOrders
							?.flatMap((returnOrder) => returnOrder.returnGroups)
							?.map((returnGroup, index) => (
								<MobileReturnGroupContainer
									key={index}
									returnGroup={returnGroup}
									items={returnOrders.flatMap((returnOrder) => returnOrder.items)} // Pass all items to filter in the OrderReturnGroup
								/>
							))}
					</div>
				</div>
			)}
		</>
	);
}
