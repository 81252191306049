import 'swiper/css/bundle';

import { Skeleton } from 'components/common/Skeleton';
import { useWindowDimensions } from 'hooks';
import { PopulatedBoxInterface } from 'interfaces/BoxInterface';
import { forwardRef, useRef } from 'react';

import { BOX_CARD_HEIGHT_TO_WIDTH_RATIO, screens } from '../../../constants';
import { BoxSlide } from './BoxSlides';
import { CarouselLabel } from './CarouselLabel';
import { useCarouselBtns } from './hooks/useCarouselBtns';
import { NextBtn } from './NextBtn';
import { PrevBtn } from './PrevBtn';

interface Props {
	boxList?: PopulatedBoxInterface[];
	label?: string;
	isLoading?: boolean;
}

const DEFAULT_BOX_LEN = 9;

export const Carousel = forwardRef<HTMLDivElement, Props>(({ boxList, label, isLoading }, ref) => {
	const { screenWidth } = useWindowDimensions();

	const carouselSwiperRef = useRef<HTMLDivElement>(null);
	const carouselContainerRef = useRef<HTMLDivElement>(null);

	// eslint-disable-next-line no-magic-numbers
	const cardHeight = screenWidth >= screens.lg.width ? 270 : 260;
	const spaceBetween = 20;
	const slidesPerGroup = Math.floor(
		(carouselContainerRef.current?.clientWidth ?? 0) / (cardHeight * BOX_CARD_HEIGHT_TO_WIDTH_RATIO + spaceBetween)
	);

	const {
		handleBtnNext,
		handleBtnPrev,
		isAnimating: isCarouselAnimating,
		showNextBtn,
		showPrevBtn,
	} = useCarouselBtns({ carouselSwiperRef, slidesPerGroup });

	return (
		<div ref={ref} className="relative -left-desktop-right-padding z-20 w-[calc(100%+40px)]">
			<CarouselLabel label={label} isLoading={isLoading} />
			<div ref={carouselContainerRef} className="overflow-x-hidden">
				<div
					ref={carouselSwiperRef}
					style={{ columnGap: spaceBetween }}
					className="no-scrollbar pl-[40px] overflow-x-auto h-[340px] flex items-center"
				>
					{!isLoading &&
						boxList?.map((el, index) => <BoxSlide key={el._id} box={el} index={index} cardHeight={cardHeight} />)}
					{isLoading &&
						Array.from({ length: DEFAULT_BOX_LEN })
							.fill(null)
							.map((_, index) => (
								<Skeleton key={index} style={{ height: cardHeight, aspectRatio: '0.65 / 1', width: 'auto' }} />
							))}
				</div>
			</div>

			{!isCarouselAnimating && showPrevBtn && <PrevBtn onClick={handleBtnPrev} />}
			{!isCarouselAnimating && showNextBtn && <NextBtn onClick={handleBtnNext} />}
		</div>
	);
});
