import { FontRegister } from 'assets/images';
import { PrimaryBtn } from 'components/common/buttons/PrimaryBtn';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppStore } from 'store/useAppStore';
import { shallow } from 'zustand/shallow';

export function RegisterBtn() {
	const navigate = useNavigate();
	const location = useLocation();

	const setLastLocationBeforeLogin = useAppStore((state) => state.setLastLocationBeforeLogin, shallow);

	function handleSignUpNavigate() {
		const prevUrl = location.pathname;
		setLastLocationBeforeLogin(prevUrl);
		navigate('/auth/register');
	}

	return (
		<PrimaryBtn
			onClick={handleSignUpNavigate}
			style={{ width: '119px', height: '34px' }}
			content={
				<span className="text-white text-[12px] font-bold">
					<img src={FontRegister} alt="register" className="h-[18px]" />
				</span>
			}
		/>
	);
}
