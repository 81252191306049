import { EmptyInventoryFont, EmptyInventoryMrloot, StarsIcon } from 'assets/images';
import { LoadSpinner } from 'components/common/loading/LoadSpinner';
import { SectionHeader } from 'components/common/SectionHeader';
import { useInfiniteScroll } from 'hooks';
import { useBackBtnInTopBar } from 'hooks/app-layout/useBackBtnInTopbar';
import { useTopbarBackBtnListener } from 'hooks/events/useTopbarBackBtnListener';
import { UnboxedHistoryInterface } from 'interfaces/UnboxedHistoryInterface';
import { useWinBoxHistory } from 'pages/AccountSettings/hooks/useWinBoxHistory';
import { useCallback, useRef, useState } from 'react';

import WinBoxHistoryCard from './WinBoxHistoryCard';
import { WinBoxHistoryDetailsOverlay } from './WinBoxHistoryDetailsOverlay';

const EmptyWinBoxHistory = () => (
	<div className="flex flex-col space-y-4 items-center justify-center my-auto w-full h-full">
		<img
			alt=""
			src={EmptyInventoryMrloot}
			className="object-scale-down h-[25vh] xs:h-[29vh] ss:h-[32vh] sm:h-[35vh] max-w-full"
		/>
		<img
			alt="Inventory is empty"
			src={EmptyInventoryFont}
			className="object-scale-down h-[9vh] xs:h-[10vh] ss:h-[11vh]  sm:h-[12vh] max-w-full"
		/>
	</div>
);

const BOX_LIMIT_PER_PAGE = 12;

export function WinBoxHistoryOverview() {
	const [currentHistoryDetailsOverlay, setCurrentHistoryDetailsOverlay] = useState<
		UnboxedHistoryInterface | undefined
	>();

	useBackBtnInTopBar();

	useTopbarBackBtnListener(
		useCallback(() => {
			window.history.back();
		}, [])
	);

	const lastBoxRef = useRef<HTMLDivElement>(null);

	const {
		fetchNextPage,
		hasNextPage,
		data: winBoxHistory,
		isLoading,
		isFetchingNextPage,
	} = useWinBoxHistory({ limit: BOX_LIMIT_PER_PAGE });

	const handleLoadOnInfiniteScroll = useCallback(() => {
		if (hasNextPage && !isLoading) {
			fetchNextPage();
		}
	}, [fetchNextPage, hasNextPage, isLoading]);

	useInfiniteScroll(lastBoxRef, handleLoadOnInfiniteScroll, winBoxHistory?.length);

	return (
		<>
			{currentHistoryDetailsOverlay && (
				<WinBoxHistoryDetailsOverlay
					unboxedBox={currentHistoryDetailsOverlay}
					onClose={() => setCurrentHistoryDetailsOverlay(undefined)}
				/>
			)}
			<div className="flex flex-col gap-y-[45px] min-h-[calc(100dvh-120px)] pb-[20px]">
				<SectionHeader iconSrc={StarsIcon} title="WinBox History" rightContent={<></>} />
				{isLoading && (
					<div className="flex items-center justify-center h-[calc(100dvh-120px)] pb-[100px]">
						<LoadSpinner />
					</div>
				)}
				{!isLoading && winBoxHistory.length === 0 && <EmptyWinBoxHistory />}
				{!isLoading && winBoxHistory && (
					<div className="grid grid-cols-3 ss:grid-cols-4 sm:grid-cols-5 lg:grid-cols-6 gap-x-[40px] sm:gap-x-[55px] gap-y-[50px] pr-[25px]">
						{winBoxHistory?.map((boxHistoryEl, index) => (
							<WinBoxHistoryCard
								key={boxHistoryEl._id}
								onClick={() => setCurrentHistoryDetailsOverlay(boxHistoryEl)}
								unboxedDetails={boxHistoryEl}
								ref={index === winBoxHistory.length - 1 ? lastBoxRef : null}
							/>
						))}
					</div>
				)}
				{isFetchingNextPage && (
					<div className="flex items-center justify-center w-full">
						<LoadSpinner size="35px" />
					</div>
				)}
			</div>
		</>
	);
}
