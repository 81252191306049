import { useEnterOrSpacePress, useOutsideClick } from 'hooks';
import { PopulatedBoxItemInterface } from 'interfaces/BoxInterface';
import { ItemInterface } from 'interfaces/ItemInterfaces';
import { useEffect, useRef, useState } from 'react';
import { getBgCardUrl } from 'utils';

import { CLOUDFRONT_IMG_URL, rarities } from '../../../../constants';
import { CardHeader } from './CardHeader';
import { PriceAndBoxDisplay } from './PriceAndBoxDisplay';

interface Props {
	item: PopulatedBoxItemInterface | ItemInterface;
	boxId?: string;
	showBrandname?: boolean;
	style?: React.CSSProperties;
}

/**
 * ExpandableItemCard Component
 *
 * This component displays an item card that can be expanded or collapsed upon user interaction.
 * It provides a detailed view of the item, including its image, rarity, price, and related boxes
 * if available. The card expands on click and contracts when the mouse leaves or when clicked again.
 *
 * @component
 *
 * @param {Props} props - The component properties.
 * @param {PopulatedBoxItemInterface | ItemInterface} props.item - The item object to display within the card.
 * @param {string} [props.boxId] - (Optional) The ID of the box that the item belongs to, used to exclude it from related boxes.
 * @param {boolean} [props.showBrandname=true] - (Optional) Whether to display the item's brand name on the card.
 * @param {boolean} [props.isSelected] - (Optional) Indicates if the card is in a "selected" state, highlighting it visually.
 * @param {React.CSSProperties} [props.style] - (Optional) Additional inline styles to apply to the card container.
 *
 * @returns {JSX.Element} The rendered ExpandableItemCard component.
 **/
export function ExpandableItemCard({ item, boxId, showBrandname = true, style }: Props) {
	const isPopulatedBoxItem = 'probability' in item;
	const actualItem = isPopulatedBoxItem ? (item as PopulatedBoxItemInterface).itemId : (item as ItemInterface);
	const probability = isPopulatedBoxItem ? (item as PopulatedBoxItemInterface).probability : undefined;

	const parentRef = useRef<HTMLDivElement>(null);

	const [isCardExpanded, setIsCardExpanded] = useState(false);

	const [isHovering, setIsHovering] = useState(false);

	function handleMouseLeave() {
		setIsCardExpanded(false);
		setIsHovering(false);
	}

	function handleMouseEnter() {
		setIsCardExpanded(false);
		setIsHovering(true);
	}

	const handleKeyPress = useEnterOrSpacePress(() => setIsCardExpanded((prev) => !prev));

	useOutsideClick(parentRef, () => setIsCardExpanded(false));

	const [isTransitioning, setIsTransitioning] = useState(false);

	useEffect(() => {
		if (isCardExpanded) {
			setIsTransitioning(true);
		}
	}, [isCardExpanded]);

	return (
		<div
			onClick={() => setIsCardExpanded((prev) => !prev)}
			onKeyDown={(event) => handleKeyPress(event)}
			role="button"
			tabIndex={0}
			onTransitionEnd={() => {
				if (!isCardExpanded) {
					setIsTransitioning(false);
				} // Reset z-index after collapse finishes
			}}
			className={`${isCardExpanded && 'scale-[150%]'} ${isCardExpanded || isTransitioning ? 'z-30' : 'z-0'} h-full flex-shrink-0 flex justify-center outline-none`}
			style={{
				filter: isHovering ? `drop-shadow(0 0 4px ${rarities[actualItem.rarity].color})` : 'none',
				transition: isCardExpanded ? 'transform 0.25s ease-out' : 'transform 0.4s ease-out',
			}}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			<div
				ref={parentRef}
				className={` rounded-[16px] relative flex flex-col items-center w-full h-full cursor-pointer`}
				style={{ ...style }}
			>
				<img
					alt={`Card background color is ${rarities[actualItem.rarity].label}`}
					src={getBgCardUrl(actualItem.rarity)}
					className="rounded-[14px] h-full w-full object-fill absolute left-0 top-0"
				/>

				<div
					className={`size-container z-20 h-full w-full flex flex-col justify-between pb-[12px]  space-y-[3px] xxl:space-y-[12px] pt-2 xxl:pt-3 items-center `}
				>
					<CardHeader showProbability={!isCardExpanded} probability={probability} />
					<div className={`flex flex-col px-3 justify-between flex-grow w-full space-y-1 overflow-hidden`}>
						<img
							src={`${CLOUDFRONT_IMG_URL}/${actualItem.imageUrls.qualityMedium}`}
							alt={actualItem.name}
							className={`max-w-full mx-auto h-[37cqh] object-scale-down`}
						/>
						<div className={`flex flex-col justify-center items-center text-white text-[4.6cqh] `}>
							{showBrandname && actualItem.brandName !== 'undefined' && (
								<span className={`text-center font-[400] line-clamp-1`}>{actualItem.brandName}</span>
							)}
							<span className={`line-clamp-2 text-center font-bold`}>{actualItem.name}</span>
						</div>
						<PriceAndBoxDisplay excludedBoxId={boxId} item={actualItem} showPrice={isCardExpanded} />
					</div>
				</div>
			</div>
		</div>
	);
}
