import { faSpinner, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Skeleton } from 'components';
import { MobileBackground } from 'components/layout/background/MobileBackground';
import { MobileMenuHeader } from 'components/layout/nav/mobile/menu/MobileMenuHeader';
import { gsap } from 'gsap';
import { useFindSimiliarBoxes, useInfiniteScroll, useSlideUpAndDragToDismiss } from 'hooks';
import { useDvhFallback } from 'hooks/layout/useDvhFallback';
import { useBoxOpeningStoreMobile } from 'pages/OpenBox/store/useBoxOpeningStoreMobile';
import { MobileBoxCard } from 'pages/overview/mobile/components/MobileBoxCard';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { v4 as uuidv4 } from 'uuid';

interface Props {
	pageSize?: number;
	boxName?: string;
}

const INIT_NUM_DISPLAYED_BOXES = 6;

export function PopupSimiliarBoxes({ pageSize = 14, boxName }: Props) {
	const { setShowSimiliarBoxesPopupOfCurrentBoxStack, addBoxToPopupStack } = useBoxOpeningStoreMobile();

	const { boxes, isLoading, loadMoreBoxes, isNextPageLoading, isAllItemsLoaded } = useFindSimiliarBoxes({
		boxName: boxName,
		pageSize: pageSize,
		maxPage: 2,
	});

	const lastBoxRef = useRef<HTMLDivElement>(null);
	useInfiniteScroll(lastBoxRef, loadMoreBoxes, boxes?.length);

	const [isSlideUpComplete, setIsSlideUpComplete] = useState(false);

	const navigate = useNavigate();
	const blackBgRef = useRef<HTMLDivElement>(null);
	const dragRef = useRef<HTMLDivElement>(null);

	const handleDissMiss = useCallback(() => {
		setShowSimiliarBoxesPopupOfCurrentBoxStack(false);
	}, [setShowSimiliarBoxesPopupOfCurrentBoxStack]);

	const handleSlideUpComplete = useCallback(() => {
		setIsSlideUpComplete(true);
	}, []);

	useEffect(() => {
		const tl = gsap.timeline();
		tl.set(blackBgRef.current, { opacity: 0 });
		tl.to(blackBgRef.current, { opacity: 1, duration: 0.3 });
	}, []);

	const { playDismissAnimation } = useSlideUpAndDragToDismiss({
		ref: dragRef,
		onDismiss: handleDissMiss,
		onInitAnimationComplete: handleSlideUpComplete,
		animationTime: 0.3,
		dissmissAnimationTime: 0.25,
	});

	function handleBoxCardClick(boxName: string) {
		const id = uuidv4();
		addBoxToPopupStack({ id, boxName: boxName, isSimiliarBoxesPopupVisible: false, prizeInspectPopup: null });
	}

	useDvhFallback({
		ref: dragRef,
		properties: [{ property: 'maxHeight', value: 100 }],
	});

	return (
		<div className="z-50 absolute inset-0 h-dvh">
			<div ref={blackBgRef} className="absolute inset-0 bg-[rgba(0,0,0,0.4)]" />
			<div
				ref={dragRef}
				className="mt-[10px] w-full h-full overflow-y-auto max-h-dvh rounded-[16px_16px_0_0] overscroll-none"
			>
				<div className="w-[50px] min-h-[5px] bg-[rgba(255,255,255,0.6)] z-40 rounded-full mb-[10px] mx-auto" />
				<MobileBackground>
					<div className="w-full h-full px-mobile-side-padding pt-[25px] gap-y-[30px] pb-[30px] flex flex-col items-center">
						<MobileMenuHeader
							icon={<FontAwesomeIcon icon={faXmark} />}
							onIconClick={playDismissAnimation}
							onLogoClick={() => navigate('/')}
						/>

						<div className="grid grid-cols-2 xs:grid-cols-3 ss:grid-cols-4 sm:grid-cols-5 md:grid-cols-6 lg:grid-cols-7 gap-[20px] w-full">
							{isSlideUpComplete &&
								boxes?.map((box, index) => (
									<MobileBoxCard
										key={box._id}
										box={box}
										ref={index === boxes.length - 1 ? lastBoxRef : null}
										style={{ width: '100%' }}
										onClick={() => handleBoxCardClick(box.name)}
									/>
								))}
							{(!isSlideUpComplete || isLoading) &&
								Array.from({ length: INIT_NUM_DISPLAYED_BOXES }).map((_, index) => (
									<Skeleton key={index} style={{ width: '100%', aspectRatio: '0.65 / 1' }} />
								))}
						</div>
						{!isAllItemsLoaded && (
							<FontAwesomeIcon
								icon={faSpinner}
								className={`${isNextPageLoading ? 'opacity-100' : 'opacity-0'} text-[20px] text-[rgba(255,255,255,0.7)] animate-spin !mx-[0px]`}
							/>
						)}
					</div>
				</MobileBackground>
			</div>
		</div>
	);
}
