import { faBan, faHourglassHalf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconInventory } from 'assets/icons/IconInventory';
import { Skeleton } from 'components';
import { Line } from 'components/common/Line';
import { PublicOrderStatus, ShippingAddressInterface } from 'interfaces/OrderInterfaces';
import { LocationIcon } from 'pages/MyOrders/assets';

const ShippingInfo = ({ shippingAddress }: { shippingAddress: ShippingAddressInterface }) => (
	<div className="flex flex-col gap-y-[5px]">
		<span className="font-bold text-[#0C2638] text-[15px]">
			{shippingAddress.firstName} {shippingAddress.lastName}
		</span>
		<p className="text-[#0C2638] text-[13px]">
			{shippingAddress.street} {shippingAddress.houseNr} {shippingAddress.zipCode} {shippingAddress.city}{' '}
			{shippingAddress.country}
		</p>
	</div>
);

const StatusInfo = ({ status }: { status: PublicOrderStatus }) => {
	const info =
		status === PublicOrderStatus.DELIVERED
			? 'Delivered!'
			: status === PublicOrderStatus.CANCELLED
				? 'Cancelled!'
				: status === PublicOrderStatus.IN_PROCESS
					? 'In progress'
					: 'On its way';

	const OrderStatusIcon = () => (
		<>
			{status === PublicOrderStatus.DELIVERED && <IconInventory height={17} width={17} />}
			{(status === PublicOrderStatus.IN_PROCESS || status === PublicOrderStatus.SHIPPED) && (
				<FontAwesomeIcon icon={faHourglassHalf} className="text-[15px]" />
			)}
			{status === PublicOrderStatus.CANCELLED && <FontAwesomeIcon icon={faBan} className="text-[15px]" />}
		</>
	);

	return (
		<>
			<OrderStatusIcon />
			<span className="text-[14px] text-black">{info}</span>
		</>
	);
};

interface Props {
	shippingAddress?: ShippingAddressInterface;
	status?: PublicOrderStatus;
	isLoading: boolean;
}

export function GeneralOrderInfoSection({ shippingAddress, status, isLoading }: Props) {
	const ShippingInfoSkeleton = () => (
		<div className="flex flex-col gap-y-[5px]">
			<Skeleton style={{ width: '80px', height: '23px' }} color="#d1d1d6" />
			<Skeleton style={{ width: '130px', height: '20px' }} color="#d1d1d6" />
		</div>
	);

	const StatusInfoSkeleton = () => (
		<>
			<Skeleton style={{ width: '18px', height: '22px' }} color="#d1d1d6" />
			<Skeleton style={{ width: '80px', height: '22px' }} color="#d1d1d6" />
		</>
	);

	return (
		<div className="w-full flex flex-col bg-white py-[15px] gap-y-[15px]">
			<div className="grid grid-cols-[auto_60px] items-center px-mobile-side-padding">
				{shippingAddress && <ShippingInfo shippingAddress={shippingAddress} />}
				{isLoading && <ShippingInfoSkeleton />}
				<img src={LocationIcon} alt="" className="h-[35px] w-auto" />
			</div>

			<Line style={{ backgroundColor: '#DBDBDB' }} />

			<div className="w-full flex items-center gap-x-[12px] text-mrLootBlue px-mobile-side-padding">
				{status && <StatusInfo status={status} />}
				{isLoading && <StatusInfoSkeleton />}
			</div>
		</div>
	);
}
