import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StarsIcon } from 'assets/images';
import { SectionHeader } from 'components/common/SectionHeader';
import { ToastErrorNotification } from 'components/toasts/ToastErrorNotification';
import { ToastSuccessNotification } from 'components/toasts/ToastSuccessNotification';
import { useUserContext } from 'hooks';
import { useBackBtnInTopBar } from 'hooks/app-layout/useBackBtnInTopbar';
import { useTopbarBackBtnListener } from 'hooks/events/useTopbarBackBtnListener';
import { useUserPasswordUpdate } from 'pages/AccountSettings/hooks/useUserPasswordUpdate';
import { useCallback } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

import { PasswordInput } from './components/PasswordInput';

interface PasswordFormValues extends FieldValues {
	currentPassword: string;
	newPassword: string;
	confirmPassword: string;
}

export function MobileChangePassword() {
	const { load: reloadUser } = useUserContext();
	const navigate = useNavigate();

	const {
		register,
		handleSubmit,
		setError,
		formState: { errors },
	} = useForm<PasswordFormValues>();
	const { mutate: updatePassword, isLoading } = useUserPasswordUpdate({
		onSuccess() {
			toast(<ToastSuccessNotification message="Your Password has been updated" />);
			reloadUser();
			navigate('/my-account/edit-profile');
		},
		onError(message) {
			toast(<ToastErrorNotification message={message} />);
		},
	});

	useBackBtnInTopBar();

	useTopbarBackBtnListener(
		useCallback(() => {
			window.history.back();
		}, [])
	);

	const onSubmit = (data: PasswordFormValues) => {
		if (data.newPassword !== data.confirmPassword) {
			setError('confirmPassword', { type: 'manual', message: 'New Password and Confirm Password do not match' });
			return;
		}
		updatePassword(data);
	};

	const isBtnDisabled = isLoading;

	return (
		<form onSubmit={handleSubmit(onSubmit)} className="flex flex-col justify-between h-[calc(100dvh-100px)] pb-[80px]">
			<div className="flex flex-col gap-y-[25px]">
				<SectionHeader title="Edit Profile" iconSrc={StarsIcon} rightContent={<></>} />
				<div className="flex flex-col gap-y-[12px]">
					<span className="font-bold text-[16px] text-white">Change Password</span>
					<PasswordInput<PasswordFormValues>
						name="currentPassword"
						placeholder="Enter current password"
						register={register}
						error={errors.currentPassword}
					/>
					<PasswordInput<PasswordFormValues>
						name="newPassword"
						placeholder="Enter new password"
						register={register}
						error={errors.newPassword}
					/>
					<PasswordInput<PasswordFormValues>
						name="confirmPassword"
						placeholder="Confirm new password"
						register={register}
						error={errors.confirmPassword}
					/>
				</div>
			</div>
			<button
				type="submit"
				disabled={isBtnDisabled}
				className={`${isBtnDisabled && 'opacity-50'} rounded-full bg-mrLootBlue flex items-center justify-center text-white font-bold h-[56px]`}
			>
				{!isLoading && <span>Save Changes</span>}
				{isLoading && <FontAwesomeIcon icon={faSpinner} className="animate-spin" />}
			</button>
		</form>
	);
}
