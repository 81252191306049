import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
	quantity: number;
	onQuantityChange?: (q: number) => void;
	disabled: boolean;
}

export function QuantityInputWithButtons({ quantity, onQuantityChange, disabled }: Props) {
	return (
		<div className="flex items-center space-x-[7px]">
			{!disabled && (
				<button
					disabled={quantity <= 1}
					onClick={() => onQuantityChange && onQuantityChange(Math.max(1, quantity - 1))}
					className={`text-white text-[10px] rounded-full ${quantity <= 1 ? 'bg-[rgba(255,255,255,0.05)]' : 'bg-white01 hover:bg-[rgba(255,255,255,0.2)]'} w-[20px] h-[20px] flex items-center justify-center`}
				>
					<FontAwesomeIcon icon={faMinus} />
				</button>
			)}
			<span className="text-white text-[18px] font-semibold">{quantity}</span>
			{!disabled && (
				<button
					onClick={() => onQuantityChange && onQuantityChange(quantity + 1)}
					className={`text-white text-[10px] rounded-full bg-white01 hover:bg-[rgba(255,255,255,0.2)] w-[20px] h-[20px] flex items-center justify-center`}
				>
					<FontAwesomeIcon icon={faPlus} />
				</button>
			)}
		</div>
	);
}
