import { useEffect } from 'react';

export function useAutoFocus(ref: React.RefObject<HTMLDivElement>, isDeactivated?: boolean) {
	useEffect(() => {
		if (isDeactivated) {
			return;
		}

		ref.current?.focus();
	}, [ref, isDeactivated]);
}
