export interface AffiliateInfo {
	code: string;
	userId?: string;
}

export enum PaymentProvider {
	STRIPE_PAYMENTS = 'stripe_payments',
	CRYPTO = 'crypto',
	GIFT_CARDS = 'gift_card',
}

export interface Transaction {
	paymentId: string;
	affiliate: AffiliateInfo;
	creditedAmount: number;
	originalCurrencyAmount: number;
	payinCurrency: string;
	paymentMethod: PaymentProvider;
}
