import { useCallback } from 'react';

interface RecordDepositProps {
	depositId: string;
	depositAmount: number;
	currency: string;
	paymentMethod: string;
}

export const useGTMDepositTracker = () => {
	const recordDeposit = useCallback(({ currency, depositAmount, paymentMethod, depositId }: RecordDepositProps) => {
		// Retrieve UTM parameters from localStorage
		const utmSource = localStorage.getItem('utm_source') || 'direct'; // Default to 'direct' if not available
		const utmMedium = localStorage.getItem('utm_medium') || 'none';
		const utmCampaign = localStorage.getItem('utm_campaign') || 'none';

		// Push deposit data along with UTM parameters to the dataLayer
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: 'deposit',
			deposit_id: depositId,
			deposit_amount: depositAmount,
			deposit_currency: currency,
			payment_method: paymentMethod,
			utm_source: utmSource,
			utm_medium: utmMedium,
			utm_campaign: utmCampaign,
		});
	}, []);

	return recordDeposit;
};
