import { faGift, faUserGroup } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PrimaryInput } from 'components/common/PrimaryInput';

export function GiftAndAffiliateCodeInput() {
	return (
		<div className="flex items-center gap-x-[10px] mt-[20px]">
			<PrimaryInput
				style={{ flexGrow: 1 }}
				icon={<FontAwesomeIcon icon={faUserGroup} />}
				placeholder="Enter Affiliate Code"
			/>
			<button
				className="mr-[10px] bg-primary-gradient border-[2px] border-[#3559D7] rounded-[10px] w-[102px] h-[48px] flex-shrink-0"
				style={{
					boxShadow: '0px 0px 0px #FFFFFF, 0px 4px 5px rgba(0, 0, 0, 0.3)',
					background: 'linear-gradient(331.41deg, #236DF4 6.78%, #33F0A7 204.87%)',
				}}
			>
				Apply
			</button>
			<PrimaryInput icon={<FontAwesomeIcon icon={faGift} />} placeholder="Enter Gift Code" />
			<button
				className="bg-primary-gradient border-[2px] border-[#3559D7] rounded-[10px] w-[102px] h-[48px] flex-shrink-0"
				style={{
					boxShadow: '0px 0px 0px #FFFFFF, 0px 4px 5px rgba(0, 0, 0, 0.3)',
					background: 'linear-gradient(331.41deg, #236DF4 6.78%, #33F0A7 204.87%)',
				}}
			>
				Apply
			</button>
		</div>
	);
}
