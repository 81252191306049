/* eslint-disable no-magic-numbers */
export const rarities = {
	gold: {
		minPrice: 5000,
		maxPrice: 99999999,
		label: 'Gold',
		color: '#F6B714',
		color10: 'rgba(246,183,20,0.1)',
		color50: 'rgba(246,183,20,0.5)',
		color75: 'rgba(246,183,20,0.75)',
		bgColor90: 'rgba(236,213,30,0.9)',
		gradient: 'linear-gradient(180deg, #ECD51E 0%, #A59514 100%)', // win box history card
		primBtnGradient: 'linear-gradient(331.41deg, #FFB800 6.78%, #FFFFFF 204.87%)',
		primBorderColor: '#FFE600',
	},
	red: {
		minPrice: 1000,
		maxPrice: 5000,
		label: 'Red',
		color: '#F62114',
		color10: 'rgba(246,33,20,0.1)',
		color50: 'rgba(246,33,20,0.5)',
		color75: 'rgba(	246,33,20,0.75)',
		bgColor90: 'rgba(212,25,27,0.9)',
		gradient: 'linear-gradient(180deg, #D4191B 0%, #921214 100%)',
		primBtnGradient: 'linear-gradient(331.41deg, #F43023 6.78%, #E5C9C9 204.87%)',
		primBorderColor: '#D73535',
	},
	pink: {
		minPrice: 250,
		maxPrice: 1000,
		label: 'Pink',
		color: '#ff0171',
		color10: 'rgba(255,1,113,0.1)',
		color50: 'rgba(255,1,113,0.5)',
		color75: 'rgba(255,1,113,0.75)',
		bgColor90: 'rgba(224,21,101,0.9)',
		gradient: 'linear-gradient(180deg, #E01565 0%, #9F124A 100%)',
		primBtnGradient: 'linear-gradient(331.41deg, #F109CC 6.78%, #FFFFFF 204.87%)',
		primBorderColor: '#980080',
	},
	purple: {
		minPrice: 50,
		maxPrice: 250,
		label: 'Purple',
		color: '#7805C6',
		color10: 'rgba(168,80,222,0.1)',
		color50: 'rgba(168,80,222,0.5)',
		color75: 'rgba(168,80,222,0.75)',
		bgColor90: 'rgba(116,10,185,0.9)',
		gradient: 'linear-gradient(180deg, #740AB9 0%, #4C0978 100%)',
		primBtnGradient: 'linear-gradient(331.41deg, #BD00FF 6.78%, #FFFFFF 204.87%)',
		primBorderColor: '#980080',
	},
	blue: {
		minPrice: 10,
		maxPrice: 50,
		label: 'Blue',
		color: '#2981E9',
		color10: 'rgba(41,129,233,0.1)',
		color50: 'rgba(41,129,233,0.5)',
		color75: 'rgba(41,129,233,0.75)',
		bgColor90: 'rgba(41,129,233,0.9)',
		gradient: 'linear-gradient(180deg, #0C81E9 0%, #0B5AA0 100%)',
		primBtnGradient: 'linear-gradient(331.41deg, #0085FF 6.78%, #AFFAFF 204.87%)',
		primBorderColor: '#110DF1',
	},
	lightBlue: {
		minPrice: 0,
		maxPrice: 10,
		label: 'Lightblue',
		color: '#26b1f8',
		color10: 'rgba(38,177,248,0.1)',
		color50: 'rgba(38,177,248,0.5)',
		color75: 'rgba(38,177,248,0.75)',
		bgColor90: 'rgba(12,156,194,0.9)',
		gradient: 'linear-gradient(180deg, #0C9CC2 0%, #08708B 100%)',
		primBtnGradient: 'linear-gradient(331.41deg, #23C2F4 6.78%, #DDFFFF 204.87%)',
		primBorderColor: '#3580D7',
	},
};

export const QUERY_KEYS = {
	boxData: 'box-data',
	userData: 'user-data',
	itemData: 'item-data',
	inventoryItemData: 'inventory-item-data',
	inventoryBoxData: 'inventory-box-data',
	boxContainer: 'box-container',
	boxHistory: 'unboxed-history',
	featureBanners: 'feature-banners',
	passwordResetCode: 'password-reset-code',
	orderData: 'order-data',
	returnOrderData: 'return-orders-data',
	hashedServerSeed: 'hashed-server-seed',
	cryptoPayment: 'crypto-payment',
	paymentTransaction: 'payment-transactions',
	popularBoxes: 'popular-boxes',
	cheapBoxes: 'cheap-boxes',
};

export const screens = {
	xxs: { width: 390, height: 610 }, // Phone, portrait orientation
	xs: { width: 480, height: 780 }, // Phone, portrait orientation
	ss: { width: 620, height: 960 }, // phone, portrait orientation
	ms: { width: 730, height: 410 }, // Small laptop
	sm: { width: 840, height: 470 }, // Small laptop
	mds: { width: 930, height: 512 },
	md: { width: 1020, height: 574 },
	lg: { width: 1200, height: 675 },
	lgx: { width: 1350, height: 870 },
	xl: { width: 1500, height: 956 },
	xxl: { width: 1800, height: 1104 },
};

export const landscapeScreens = {
	xxs: { width: 610, height: 390 }, // Phone, landscape orientation
	xs: { width: 780, height: 480 }, // Phone, landscape orientation
	ss: { width: 960, height: 620 }, // Phone, landscape orientation
	ms: { width: 730, height: 410 }, // Small laptop (unchanged)
	sm: { width: 840, height: 470 }, // Small laptop (unchanged)
	mds: { width: 930, height: 512 }, // Unchanged
	md: { width: 1020, height: 574 }, // Unchanged
	lg: { width: 1200, height: 675 }, // Unchanged
	xl: { width: 1500, height: 956 }, // Unchanged
	xxl: { width: 1800, height: 1104 }, // Unchanged
};

/**
 * set REACT_APP_ENV=production
 * $env:REACT_APP_ENV="production"
 */
export const API_DOMAIN = {
	//mrlootBackend: process.env.REACT_APP_ENV === 'production' ? 'https://mrloot.com' : 'http://192.168.178.134:3001',
	mrlootBackend: process.env.REACT_APP_ENV === 'production' ? 'https://mrloot.com' : 'http://localhost:3001',
	mrlootClient: process.env.REACT_APP_ENV === 'production' ? 'https://mrloot.com' : 'http://localhost:3000',
};

export const COLORS = {
	blue90: 'rgba(41,129,233,0.9)',
	errorRed: 'rgb(204,0,60)',
};

export const STRIPE_PUBLIC_KEY =
	'pk_live_51QNdijRoFoXEOH6sarfV8kHkFmLiGak19QK0EuhH5CHyOUcrnCCv3Kxd0PgB2DyZqsslIegDhr42Z7sSps8jYtET00Sh2HBV7Q';

export const t = '';

export const QUERY_CACHE_CONFIG = {
	shortTerm: {
		cacheTime: 5 * 60 * 1000, // 5 minutes
		staleTime: 1 * 60 * 1000, // 1 minute
	},
	longTerm: {
		cacheTime: 30 * 60 * 1000, // 30 minutes
		staleTime: 10 * 60 * 1000, // 10 minutes
	},
	veryLongTerm: {
		cacheTime: 2 * 60 * 60 * 1000, // 2 hours
		staleTime: 60 * 60 * 1000, // 1 hour
	},
	realTime: {
		cacheTime: 0, // no caching
		staleTime: 0, // always refetch
	},
};

export const dollarToLootieExchangeRate = 4;

export const CLOUDFRONT_IMG_URL = 'https://d2x2fr0vrjbq4i.cloudfront.net';

export const WIDTH_SIDEBAR_NAVIGATION = 63;
export const HORIZONTAL_APP_PADDING = 32; // padding of entire application to left and right

export const MAX_SCREEN_WIDTH = 1400;
export const DESKTOP_SIDEBAR_WIDTH = 63;

export const ONE_MINUTE_IN_MS = 1000 * 60;
export const FIVE_MINUTES_IN_MILLISECONDS = ONE_MINUTE_IN_MS * 5;
export const HALF_HOUR_IN_MILLISECONDS = 1000 * 60 * 30;

export const ITEM_CARD_HEIGHT_TO_WIDTH_RATIO = 0.667; // when height is defined
export const ITEM_CARD_WIDTH_TO_HEIGHT_RATIO = 1.51; // when height is defined

export const BOX_CARD_HEIGHT_TO_WIDTH_RATIO = 0.65; // when height is defined
export const DESKTOP_RIGHT_PADDING = 40;
export const DESKTOP_LEFT_PADDING = 104;

export const MOBILE_SIDE_PADDING = 24;
