import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MobileNavBar } from 'components';
import { MobileBackground } from 'components/layout/background/MobileBackground';
import { Footer } from 'components/layout/footer/Footer';
import { MobileTopbar } from 'components/layout/nav/mobile/MobileTopbar';
import { useUrlParamsToast } from 'hooks/events/useUrlParamsToast';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { COLORS } from '../constants';

export default function MobileMainLayout() {
	useUrlParamsToast();

	return (
		<>
			<ToastContainer
				autoClose={3500}
				stacked={true}
				hideProgressBar={true}
				draggableDirection="y"
				draggablePercent={30}
				theme="colored"
				position="top-center"
				toastStyle={{ backgroundColor: COLORS.blue90 }}
				closeButton={(props) => (
					<button onClick={props.closeToast} className="absolute right-2 top-2 text-whie text-[14px] text-white p-1">
						<FontAwesomeIcon icon={faXmark} />
					</button>
				)}
			/>
			<MobileBackground>
				<MobileTopbar />
				<MobileNavBar />
				<div className={`pt-mobile-top-padding px-mobile-side-padding`}>
					<Outlet />
				</div>

				<Footer />
			</MobileBackground>
		</>
	);
}
