import { Coin, DollarSign } from 'assets/images';
import { CSSProperties } from 'react';
import { CurrencyOptions, useAppStore } from 'store/useAppStore';
import { shallow } from 'zustand/shallow';

interface Props {
	style?: CSSProperties;
}

export function SelectedCurrencyImg({ style }: Props) {
	const selectedCurrency = useAppStore((state) => state.selectedCurrency, shallow);

	return (
		<>
			{selectedCurrency === CurrencyOptions.LOOTIES && (
				<img src={Coin} alt="lootie Coin" className="h-[15px]" style={style} />
			)}
			{selectedCurrency === CurrencyOptions.US_DOLLAR && (
				<img src={DollarSign} alt="dollar" className="h-[15px]" style={style} />
			)}
		</>
	);
}
