import { faInfoCircle, faKey, faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OrderItemReferenceInfos } from 'interfaces/OrderInterfaces';
import { KeyPopup } from 'pages/MyOrders/components/KeyPopup';
import { NotePopup } from 'pages/MyOrders/components/NotePopup';
import { useState } from 'react';

interface Props {
	referenceInfo?: {
		value: string; // to store tracking link , game key
		type: OrderItemReferenceInfos;
	};
}

const TrackingInfo = ({ value }: { value: string }) => (
	<a
		target="_blank"
		href={`https://parcelsapp.com/en/tracking/${value}`}
		className="h-[35px] rounded-full w-[95px] flex items-center justify-evenly px-[6px] border-[2px] border-white shadow-[0_4px_5px_rgba(0,0,0,0.3)] text-white font-semibold text-[14px]"
		style={{ background: 'linear-gradient(331.41deg, #236DF4 6.78%, #33F0A7 204.87%)' }}
		rel="noreferrer"
	>
		<span className="font-semibold">Track</span>
		<FontAwesomeIcon icon={faUpRightFromSquare} />
	</a>
);

const DigitalKeyButton = ({ onClick }: { onClick: () => void }) => (
	<button
		onClick={onClick}
		className="h-[35px] rounded-full w-[95px] flex items-center justify-evenly px-[6px] border-[2px] border-white shadow-[0_4px_5px_rgba(0,0,0,0.3)] text-white font-semibold text-[14px]"
		style={{ background: 'linear-gradient(331.41deg, #236DF4 6.78%, #33F0A7 204.87%)' }}
	>
		<span className="font-semibold">Key</span>
		<FontAwesomeIcon icon={faKey} />
	</button>
);

const NoteButton = ({ onClick }: { onClick: () => void }) => (
	<button
		onClick={onClick}
		className="h-[35px] rounded-full w-[95px] flex items-center justify-evenly px-[6px] border-[2px] border-white shadow-[0_4px_5px_rgba(0,0,0,0.3)] text-white font-semibold text-[14px]"
		style={{ background: 'linear-gradient(331.41deg, #236DF4 6.78%, #33F0A7 204.87%)' }}
	>
		<span className="font-semibold">Info</span>
		<FontAwesomeIcon icon={faInfoCircle} />
	</button>
);

export function ProductReferenceInfo({ referenceInfo }: Props) {
	const [showGameKeyPopup, setShowGameKeyPopup] = useState(false);
	const [showNotePopup, setShowNotePopup] = useState(false);

	if (!referenceInfo?.value || referenceInfo.value.length === 0) {
		return null;
	}

	return (
		<div className="w-full">
			{showGameKeyPopup && referenceInfo && (
				<KeyPopup value={referenceInfo.value} onClose={() => setShowGameKeyPopup(false)} />
			)}
			{showNotePopup && referenceInfo && (
				<NotePopup text={referenceInfo.value} onClose={() => setShowNotePopup(false)} />
			)}

			{referenceInfo?.type === OrderItemReferenceInfos.TRACKING && <TrackingInfo value={referenceInfo.value} />}
			{referenceInfo?.type === OrderItemReferenceInfos.DIGITAL && (
				<DigitalKeyButton onClick={() => setShowGameKeyPopup(true)} />
			)}
			{referenceInfo?.type === OrderItemReferenceInfos.NOTE && <NoteButton onClick={() => setShowNotePopup(true)} />}
		</div>
	);
}
