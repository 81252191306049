import { CSSProperties } from 'react';
import { getImageByNumber } from 'utils';

interface Props {
	num: number;
	style?: CSSProperties;
}

export function NumberImg({ num, style }: Props) {
	const numImgSrc = getImageByNumber(num);

	return (
		<>
			{numImgSrc ? (
				<img style={style} src={numImgSrc} className="h-[30px] pointer-events-auto" alt="STOP Autospin" />
			) : (
				<span style={style} className="font-bold text-inter text-[18px] text-white">
					{num}
				</span>
			)}
		</>
	);
}
