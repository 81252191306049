import { useStripeCheckoutCreator } from 'pages/TransactionManager/hooks/useStripeCheckoutCreator';

import { Header } from '../../components/Header';
import { SecureBatch } from '../../components/SecureBatch';
import { AmountSelector } from './AmountInput';
import { CustomAmountInput } from './CustomAmountInput';

interface Props {
	onBack: () => void;
}

export function PaymentAmount({ onBack }: Props) {
	const { mutate: createStripeCheckout, isLoading } = useStripeCheckoutCreator();

	function handleStripeCheckout(amount: number) {
		if (isLoading) {
			return;
		}
		createStripeCheckout(amount);
	}

	// eslint-disable-next-line no-magic-numbers

	return (
		<div className="flex flex-col items-center gap-y-[20px] h-full justify-between min-h-fit pb-[12px] w-full">
			<Header title="Choose payment amount" onBackClick={onBack} />

			<div className="flex flex-col items-center gap-y-[23px] w-full">
				<AmountSelector onCheckout={handleStripeCheckout} />
				<CustomAmountInput onCheckout={handleStripeCheckout} />
			</div>
			<SecureBatch />
		</div>
	);
}
