import { useImageLoadingProgress } from 'hooks/utility/useImageLoadingProgress';
import { PrizeInterface } from 'interfaces/ItemInterfaces';
import { MAX_IMAGE_LOAD_TIME } from 'pages/OpenBox/box-opening.constants';
import { PrizeCardList } from 'pages/OpenBox/components/PrizeCardList';

interface Props {
	transitionPrizes: PrizeInterface[];
	onCompleteLoading: () => void;
	onTotalLoadingFail: () => void;
}

export function AutospinTransitionBuffer({ transitionPrizes, onCompleteLoading, onTotalLoadingFail }: Props) {
	const { handleLoadFail, handleLoadSuccess } = useImageLoadingProgress({
		totalImages: transitionPrizes.length,
		failThreshold: 0,
		onTotalFailure: onTotalLoadingFail,
		resetAfterCompleteOrFail: true,
		onCompleteLoading: onCompleteLoading,
		timeThreshold: MAX_IMAGE_LOAD_TIME,
	});

	return <PrizeCardList prizes={transitionPrizes} onImageLoad={handleLoadSuccess} onImageError={handleLoadFail} />;
}

AutospinTransitionBuffer.displayName = 'AutospinTransitionBuffer';
