import axios from 'axios';
import { useQuery } from 'react-query';
import { CurrencyOptions } from 'store/useAppStore';

import { QUERY_CACHE_CONFIG } from '../../constants';

export const useCryptoConversion = (amountInDollars: number, targetCurrency: CurrencyOptions) => {
	const fetchConversionRate = async () => {
		const { data } = await axios.get('https://api.coingecko.com/api/v3/simple/price', {
			params: {
				ids: 'bitcoin,ethereum,solana,dogecoin',
				vs_currencies: 'usd',
			},
		});

		return {
			bitcoin: data.bitcoin.usd,
			etherium: data.ethereum.usd,
			solana: data.solana.usd,
			dogecoin: data.dogecoin.usd,
		};
	};

	const {
		data: conversionRates,
		isLoading,
		isError,
	} = useQuery({
		queryKey: ['cryptoRates'],
		queryFn: fetchConversionRate,
		cacheTime: QUERY_CACHE_CONFIG.shortTerm.cacheTime,
		staleTime: QUERY_CACHE_CONFIG.shortTerm.staleTime,
	});

	const convertCurrency = (amount: number, rate?: number) => {
		if (!rate) {
			return 0;
		}
		return amount / rate;
	};

	const convertedAmount = (() => {
		if (!conversionRates) {
			return null;
		}

		switch (targetCurrency) {
			case CurrencyOptions.BITCOIN:
				return convertCurrency(amountInDollars, conversionRates.bitcoin);
			case CurrencyOptions.ETHERIUM:
				return convertCurrency(amountInDollars, conversionRates.etherium);
			case CurrencyOptions.SOLANA:
				return convertCurrency(amountInDollars, conversionRates.solana);
			case CurrencyOptions.DOGECOIN:
				return convertCurrency(amountInDollars, conversionRates.dogecoin);
			default:
				return amountInDollars; // Default to USD
		}
	})();

	return {
		convertedAmount,
		isLoading,
		isError,
	};
};
