import { BoxCardWithItemPreview } from 'components/cards';
import { DarkScrollOverlay } from 'components/misc';
import { PopulatedBoxInterface } from 'interfaces/BoxInterface';
import { useState } from 'react';

interface Props {
	box: PopulatedBoxInterface;
	index: number;
	cardHeight: number;
}

export function BoxSlide({ box, cardHeight }: Props) {
	const [isHovering, setIsHovering] = useState(false);

	return (
		<div
			className="relative h-fit aspect-[0.65/1]"
			onMouseEnter={() => setIsHovering(true)}
			onMouseLeave={() => setIsHovering(false)}
		>
			<BoxCardWithItemPreview box={box} styles={{ height: `${cardHeight}px`, width: '100%' }} />

			<DarkScrollOverlay
				style={{
					transform: isHovering ? 'scale(1.2)' : 'scale(1)',
					translate: '0 -50%',
					height: `${cardHeight}px`,
					transition: 'transform 300ms',
					transitionDelay: `${isHovering ? '500ms' : '50ms'}`,
				}}
			/>
		</div>
	);
}
