import { Ellipse } from 'assets/images';
import { CSSProperties, forwardRef, ReactNode, useState } from 'react';

interface Props {
	renderEllipse?: boolean;
	content: ReactNode;
	onClick?: () => void;
	isDisabled?: boolean;
	type?: 'submit' | 'button' | 'reset';
	style?: CSSProperties;
	innerContentStyle?: CSSProperties;
	pressStyle?: CSSProperties;
	id?: string;
	onMouseEnter?: () => void;
	onMouseLeave?: () => void;
}

export const SecondaryBtn = forwardRef<HTMLButtonElement, Props>(
	(
		{
			content,
			onClick,
			style,
			isDisabled,
			renderEllipse = false,
			innerContentStyle,
			type = 'button',
			id,
			pressStyle = {},
			onMouseEnter,
			onMouseLeave,
			...rest // Capture any additional props
		},
		ref
	) => {
		const [isPressed, setIsPressed] = useState(false);

		function handleMouseEnter() {
			onMouseEnter && onMouseEnter();
		}

		function handleMouseLeave() {
			setIsPressed(false);
			onMouseLeave && onMouseLeave();
		}

		return (
			<button
				id={id}
				ref={ref}
				type={type}
				onClick={onClick}
				onMouseDown={() => setIsPressed(true)}
				onMouseUp={() => setIsPressed(false)}
				onMouseLeave={handleMouseLeave}
				onMouseEnter={handleMouseEnter}
				disabled={isDisabled}
				className={`secondary-btn ${isDisabled ? 'opacity-50' : ''} bg-secondary-gradient w-[100%] px-[12px] relative border-darkBlue border-[2px] outline outline-[3px] outline-white rounded-[20px] h-[49px] text-white font-bold text-[19px] overflow-hidden focus-within:shadow-secondaryInset flex items-center justify-center`}
				style={{
					transition: 'opacity 0.3s',
					...(isPressed ? pressStyle : {}),
					...style,
					...innerContentStyle,
				}}
				{...rest} // Spread the additional props here
			>
				{content}
				{renderEllipse && <img src={Ellipse} className="absolute left-[min(9px,5.5%)] top-[min(3px,4.5%)]" alt="" />}
			</button>
		);
	}
);
