import { faRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SecondaryBtn } from 'components/common/buttons/SecondaryBtn';
import { useBoxOpeningStoreDesktop } from 'pages/OpenBox/store/useBoxOpeningStoreDesktop';

interface Props {
	onClick: () => void;
	disabled: boolean;
}

export function DemoSpinBtn({ onClick, disabled }: Props) {
	const { isFullScreen } = useBoxOpeningStoreDesktop();

	return (
		<SecondaryBtn
			isDisabled={disabled}
			onClick={onClick}
			style={{ height: '100%', width: 'fit-content', borderWidth: '2px' }}
			innerContentStyle={{
				background: isFullScreen
					? 'linear-gradient(331.41deg, rgba(35, 109, 244, 0.6) 6.78%, rgba(51, 240, 167, 0.6) 204.87%)'
					: 'linear-gradient(331.41deg, rgba(35, 109, 244, 0.3) 6.78%, rgba(51, 240, 167, 0.3) 204.87%)',
			}}
			content={
				<div className="w-full flex items-center gap-x-[8px] text-[14px]">
					<FontAwesomeIcon icon={faRotateLeft} />
					<span className="hidden ss:block">Demo</span>
				</div>
			}
		/>
	);
}
