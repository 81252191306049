import { faCartShopping } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EmptyInventoryFont, EmptyInventoryMrloot } from 'assets/images';
import { CloseBtn } from 'components';
import { AmountDisplayWithCurrency } from 'components/common/AmountDisplayWithCurrency';
import { MobileCloseBtn } from 'components/common/buttons/MobileCloseBtn';
import { PrimaryBtn } from 'components/common/buttons/PrimaryBtn';
import { useOutsideClick, useScrollLock, useUserContext, useWindowDimensions } from 'hooks';
import { useRef } from 'react';
import { useNavigate } from 'react-router';
import { isMobile } from 'utils';

import { useMerchStore } from '../../MerchStore';
import { CartProductCard } from '../CartProductCard';

const EmptyCart = () => (
	<div className="flex flex-col space-y-4 items-center justify-center my-auto w-full h-full">
		<img
			alt=""
			src={EmptyInventoryMrloot}
			className="object-scale-down h-[25vh] xs:h-[29vh] ss:h-[32vh] sm:h-[35vh] max-w-full"
		/>
		<img
			alt="Inventory is empty"
			src={EmptyInventoryFont}
			className="object-scale-down h-[9vh] xs:h-[10vh] ss:h-[11vh] sm:h-[12vh] max-w-[85%]"
		/>
	</div>
);

export function DesktopCartPopup() {
	const { products, setShowCartPopup } = useMerchStore();

	useScrollLock();

	const { screenWidth } = useWindowDimensions();

	const totalValue = products.map((el) => el.quantity * el.item.price).reduce((a, b) => a + b, 0);

	const popupRef = useRef<HTMLDivElement>(null);

	useOutsideClick(popupRef, () => setShowCartPopup(false));

	const { user } = useUserContext();
	const navigate = useNavigate();

	function handleCheckout() {
		if (!user) {
			navigate('/auth/login');
			return;
		}
		const checkoutItems = products.map((product) => ({
			item: product.item,
			quantity: product.quantity,
			selectedOptions: product.options.map((option) => ({
				...option,
				values: Array(product.quantity).fill(option.value),
			})),
			availableInventoryItemIds: [],
			selectedInventoryItemIds: [],
		}));

		navigate('/checkout', {
			state: { directPurchaseItems: checkoutItems },
		});
	}

	return (
		<div className="fixed z-40 inset-0 bg-[rgba(0,0,0,0.2)] backdrop-blur-[2px] w-screen h-dvh pl-[40px] flex justify-end">
			<div ref={popupRef} className="h-full w-full flex flex-col max-w-[450px]">
				<div className="h-dvh relative bg-primary-gradient border-l-[2px] border-y-[3px] border-white rounded-l-[20px] overflow-hidden">
					{isMobile(screenWidth) && <MobileCloseBtn onClose={() => setShowCartPopup(false)} />}
					{!isMobile(screenWidth) && <CloseBtn onClose={() => setShowCartPopup(false)} />}
					<div className="flex items-center text-white text-[20px] gap-x-[15px] mx-[20px] mt-[20px] pb-[10px]">
						<FontAwesomeIcon icon={faCartShopping} />
						<h1 className="text-white font-bold ">My Cart</h1>
					</div>

					<div className="max-h-[calc(100%-193px)] h-[calc(100dvh-193px)] overflow-y-auto px-[20px] pb-[20px]">
						<div className="flex flex-col gap-y-[25px] mt-[20px]">
							{products.map((product) => (
								<CartProductCard key={product._id} product={product} />
							))}
						</div>
						{products.length === 0 && <EmptyCart />}
					</div>

					<div className="px-[20px] h-[133px] py-[10px] w-full gap-y-[8px] flex flex-col bg-white text-black">
						<div className="w-full flex justify-between">
							<h2 className="font-bold text-[18px] ">SUBTOTAL</h2>
							<AmountDisplayWithCurrency
								amount={totalValue}
								imgStyle={{ height: '17px' }}
								amountStyle={{ fontSize: '17px', color: 'black', fontWeight: 'bold' }}
							/>
						</div>
						<span className="text-[12px] font-semibold mb-[4px] ">Shipping calculated at checkout</span>
						<PrimaryBtn
							onClick={handleCheckout}
							content="Checkout"
							style={{ filter: 'drop-shadow(0 0 4px rgba(41, 129, 233, 0.5))' }}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
