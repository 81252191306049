import {
	ItemOrderStatus,
	OrderDbItemInterface,
	OrderItemInterface,
	SelectedOptionSet,
} from 'interfaces/OrderInterfaces';
import { OrderReturnOptionSet } from 'interfaces/OrderReturns.interface';

import { screens } from '../../constants';
import { ReturnCreationFormItem, ReturnFormItemOption } from './ui-order.interface';

export const isOrderItemRefunded = (orderStatus: ItemOrderStatus) =>
	orderStatus === ItemOrderStatus.REFUND_ITEM ||
	orderStatus === ItemOrderStatus.REFUND_MONEY ||
	orderStatus === ItemOrderStatus.RESHIP;

export const refundLabel = (orderStatus: ItemOrderStatus) => {
	switch (orderStatus) {
		case ItemOrderStatus.REFUND_ITEM:
			return 'Item returned to inventory';
		case ItemOrderStatus.REFUND_MONEY:
			return 'Payment refunded';
		default:
			return 'Item reshipped';
	}
};

/**
 * Generates a user-friendly estimated arrival message based on the provided items' estimated arrival dates.
 *
 * @param {OrderDbItemInterface[]} items - An array of order items, each potentially containing the earliest and latest estimated arrival dates.
 * @returns {string} A formatted string indicating the estimated arrival date or a fallback message if no dates are available.
 *
 * @example
 * const items = [
 *   { firstEstArrivalDate: '2024-10-05', lastEstArrivalDate: '2024-10-10' },
 *   { firstEstArrivalDate: '2024-10-07', lastEstArrivalDate: null }
 * ];
 */
export const getArrivalMessage = (items: OrderDbItemInterface[]): string => {
	// Helper function to find the earliest or latest date
	const findArrivalDate = (
		items: OrderDbItemInterface[],
		dateKey: 'firstEstArrivalDate' | 'lastEstArrivalDate',
		isEarliest: boolean
	): Date | null => {
		return items
			.map((item) => {
				const dateValue = item[dateKey]; // Get the date value
				return dateValue ? new Date(dateValue) : null; // Convert to Date or return null
			})
			.filter((date): date is Date => !!date && !isNaN(date.getTime())) // Filter out invalid dates
			.reduce(
				(accumulator, current) => {
					if (!accumulator) {
						return current; // Handle null case
					}
					return isEarliest
						? current < accumulator
							? current
							: accumulator
						: current > accumulator
							? current
							: accumulator;
				},
				null as Date | null
			);
	};

	// Function to format date
	const formatDate = (date: Date | null): string => {
		if (!date) {
			return 'N/A'; // Use a better placeholder if needed
		}
		return date.toLocaleDateString(undefined, {
			year: 'numeric',
			month: 'short', // Use 'short' for abbreviated month names
			day: 'numeric',
		});
	};

	// Extract the earliest and latest arrival dates
	const earliestArrival = findArrivalDate(items, 'firstEstArrivalDate', true);
	const latestArrival = findArrivalDate(items, 'lastEstArrivalDate', false);

	// Format the dates safely
	const formattedEarliestArrival = formatDate(earliestArrival);
	const formattedLatestArrival = formatDate(latestArrival);

	// Construct the arrival message
	const messageParts: string[] = [];

	// Only include the dates if they are valid (not 'N/A')
	if (formattedEarliestArrival !== 'N/A') {
		messageParts.push(formattedEarliestArrival);
	}

	if (formattedLatestArrival !== 'N/A' && formattedLatestArrival !== formattedEarliestArrival) {
		messageParts.push(formattedLatestArrival);
	}

	// Construct the final message
	if (messageParts.length > 0) {
		return `Estimated Arrival: ${messageParts.join(' - ')}`;
	}
	return 'Estimated Arrival: Date not available';
};

/**
 * Converts selected options from return creation form into an array of `SelectedOptionSet[]`, matching each option
 * with its corresponding option in the `orderItems`.
 *
 * @param {OrderItemInterface[]} orderItems - The array of order items that include all available options for each item.
 * @param {string} itemId - The ID of the item being processed to find matching options in the `orderItems`.
 * @param {ReturnFormItemOption[]} selectedOptions - The selected options from the form, each with an ID and value.
 *
 * @returns {SelectedOptionSet[]} - Returns an array of `SelectedOptionSet[]` if matching options are found; otherwise, returns `null`.
 *
 * */
export function convertReturnCreationFormItemOptionsToSelectedOptionSets(
	orderItems: OrderItemInterface[],
	itemId: string,
	selectedOptions: ReturnFormItemOption[]
): OrderReturnOptionSet[] | undefined {
	// Step 1: Find the matching item in the order based on itemId
	const matchedOrderItem = orderItems.find((orderItem) => orderItem.itemId._id.toString() === itemId.toString());

	if (!matchedOrderItem) {
		console.error(`Item with itemId ${itemId} not found or has no options.`);
		throw new Error(
			"We're sorry, but the item you're trying to return was not found in your order. Please ensure you selected the correct item and try again."
		);
	}

	// Step 2: Group selected options by their _id (i.e., selected item sets)
	const selectedOptionSets: OrderReturnOptionSet[] = selectedOptions
		.map((selectedOption) => {
			// Find the corresponding option in the matchedOrderItem's options
			const matchedOption = matchedOrderItem.options?.find((o) => o._id.toString() === selectedOption.id);
			return { optionSelections: matchedOption?.optionSelections, orderOptionId: matchedOption?._id };
		})
		.filter((optionSet): optionSet is OrderReturnOptionSet => !!optionSet); // Filter out undefined values

	// Step 3: Return the constructed array of SelectedOptionSet
	return selectedOptionSets.length > 0 ? selectedOptionSets : undefined;
}

type ReturnFormItemFieldName =
	| `items.${number}.checked`
	| `items.${number}.selectedOptions`
	| `items.${number}.quantity`
	| `items.${number}.returnReason`
	| `items.${number}.refundMethod`;

interface ValidationError {
	name: ReturnFormItemFieldName;
	type: string;
	message: string;
}

interface ValidationResult {
	isValid: boolean;
	errors: ValidationError[];
}

/**
 * Validates a single return form item and returns an object with errors.
 *
 * @param item The individual return form item to validate.
 * @param index The index of the item in the form array.
 * @param order The current order details to compare against.
 * @returns {ValidationResult} Object containing validation result and list of errors.
 */
export function validateReturnFormItem(
	item: ReturnCreationFormItem,
	index: number,
	orderItems: OrderDbItemInterface[]
): ValidationResult {
	let isValid = true;
	const errors: ValidationError[] = [];

	if (!item.checked) {
		return { isValid, errors };
	}

	const matchedOrderItem = orderItems.find((orderItem) => orderItem._id.toString() === item.orderItemId.toString());
	// Validation for selectedOptions
	if (matchedOrderItem?.options && matchedOrderItem.options.length > 0) {
		if (!item.selectedOptions || item.selectedOptions.length === 0) {
			errors.push({
				name: `items.${index}.selectedOptions`,
				type: 'manual',
				message: 'Options are required.',
			});
			isValid = false;
		}
	} else {
		// Validation for quantity
		if (item.quantity <= 0) {
			errors.push({
				name: `items.${index}.quantity`,
				type: 'manual',
				message: 'Quantity must be at least 1.',
			});
			isValid = false;
		}
	}

	// Validation for returnReason
	if (!item.returnReason) {
		errors.push({
			name: `items.${index}.returnReason`,
			type: 'manual',
			message: 'Return reason is required.',
		});
		isValid = false;
	}

	// Validation for refundMethod
	if (window.innerWidth > screens.lg.width && !item.refundMethod) {
		errors.push({
			name: `items.${index}.refundMethod`,
			type: 'manual',
			message: 'Refund method is required.',
		});
		isValid = false;
	}

	return { isValid, errors };
}

export function extractValuesFromSelectedOptionSets(selectedOptionSets: SelectedOptionSet[]) {
	return selectedOptionSets
		.filter((option) => option.isAvailable)
		.map((optionSet) => ({
			id: optionSet._id,
			value: optionSet.optionSelections.map((selection) => selection.value).join(', '),
		}));
}
