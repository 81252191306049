import axios from 'axios';
import { ToastSuccessNotification } from 'components/toasts/ToastSuccessNotification';
import { useUserContext } from 'hooks/context';
import { useGTMDepositTracker } from 'hooks/userInteractions/useGTMDepositRecorder';
import { PaymentProvider } from 'interfaces/TransactionInterface';
import { useEffect, useRef } from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { useAppStore } from 'store/useAppStore'; // Your Zustand store
import { shallow } from 'zustand/shallow';

import { API_DOMAIN } from '../../constants';

// eslint-disable-next-line no-magic-numbers
const POLLING_INTERVAL = 25 * 1000; // 25 seconds

// eslint-disable-next-line no-magic-numbers
const MAX_POLLING_DURATION = 30 * 60 * 1000; // 30 minutes

const MAX_POLLING_CALLS = 1000; // Safety mechanism to avoid endless loops

export const useCryptoDepositPolling = () => {
	const pollingCounter = useRef(0); // Counter to track the number of polling calls

	const { load: reloadUser } = useUserContext();

	const { cryptoPollingStartTime, setCryptoPollingStartTime } = useAppStore(
		(state) => ({
			cryptoPollingStartTime: state.cryptoPollingStartTime,
			setCryptoPollingStartTime: state.setCryptoPollingStartTime,
		}),
		shallow
	);

	const trackDeposit = useGTMDepositTracker();

	const {
		mutate: pollForDeposit,
		data,
		isLoading,
		isSuccess,
	} = useMutation(
		async () => {
			const response = await axios.get(`${API_DOMAIN.mrlootBackend}/api/payment/crypto/get-unsend-transactions`, {
				withCredentials: true,
			});
			if (response.data.status === 'success') {
				return response.data.data;
			}
			return false;
		},
		{
			onSuccess: (result) => {
				if (result && result.length > 0) {
					toast(<ToastSuccessNotification message="Your Crypto Deposit was succesful" />);
					reloadUser();
					setCryptoPollingStartTime(undefined);

					trackDeposit({
						depositId: result[0].paymentId,
						currency: result[0].payinCurrency,
						depositAmount: result[0].originalCurrencyAmount,
						paymentMethod: PaymentProvider.CRYPTO,
					});
				}
			},
			onError: (error) => {
				console.error('Polling error:', error);
			},
		}
	);

	useEffect(() => {
		if (!cryptoPollingStartTime) {
			return;
		}

		const startTime = new Date(cryptoPollingStartTime);
		const now = new Date();

		if (now > new Date(startTime.getTime() + MAX_POLLING_DURATION)) {
			setCryptoPollingStartTime(undefined);
			return;
		}

		pollForDeposit();

		// Polling logic
		const interval = setInterval(() => {
			if (pollingCounter.current >= MAX_POLLING_CALLS) {
				setCryptoPollingStartTime(undefined);
				clearInterval(interval);
				return;
			}

			pollingCounter.current += 1;
			pollForDeposit();
		}, POLLING_INTERVAL);

		// Cleanup on unmount
		return () => {
			clearInterval(interval);
			pollingCounter.current = 0;
		};
	}, [cryptoPollingStartTime, pollForDeposit, setCryptoPollingStartTime]);

	return { isPolling: Boolean(cryptoPollingStartTime), isLoading, isSuccess, data };
};
