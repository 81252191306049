import { faKey } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MrLootHeadLookingUp } from 'assets/images';
import { CopyBtn } from 'components/common/buttons/CopyBtn';
import { WithSecondaryPopup } from 'components/common/popups/WithSecondaryPopup';

interface Props {
	onClose: () => void;
	value: string;
}

export function KeyPopup({ value, onClose }: Props) {
	return (
		<WithSecondaryPopup
			onClose={() => onClose()}
			childrenWrapperStyle={{ overflow: 'hidden', padding: '10px 20px 30px 20px' }}
		>
			<div className="relative z-10 flex flex-col gap-y-[30px]">
				<div className="flex items-center gap-x-[8px] text-white">
					<FontAwesomeIcon icon={faKey} />
					<h2 className="text-white font-bold text-[18px]">Key:</h2>
				</div>
				<div className="flex items-center bg-[rgba(0,0,0,0.2)] px-[15px] py-[12px] rounded-[12px] w-[250px] min-h-[40px] text-white gap-x-[12px] text-[17px] font-inter font-semibold ">
					<span className="flex-grow text-left whitespace-normal break-all text-[13px] ">{value}</span>
					<CopyBtn textToCopy={value} />
				</div>
			</div>
			<img
				src={MrLootHeadLookingUp}
				className="absolute opacity-60 z-0 right-0 translate-x-[20px] bottom-0 h-[100px]"
				alt=""
			/>
		</WithSecondaryPopup>
	);
}
