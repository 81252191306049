import { useUserContext } from 'hooks';
import { useInventoryBoxes } from 'pages/inventory/hooks/useInventoryBoxes';
import { DESKTOP_NUM_PRE_SLOT_PRIZES } from 'pages/OpenBox/box-opening.constants';
import { useBoxFromParams } from 'pages/OpenBox/hooks/useBoxFromParams';
import { useBoxSlotItems } from 'pages/OpenBox/hooks/useBoxSlotItems';
import { useWonBoxItem } from 'pages/OpenBox/hooks/useWonBoxItem';
import { useCallback, useEffect } from 'react';
import { useBalanceStore } from 'store/useBalanceStore';
import { usePopupStore } from 'store/usePopupStore';
import { shallow } from 'zustand/shallow';

import { useBoxOpeningStoreDesktop } from '../../../store/useBoxOpeningStoreDesktop';
import { CenterBtns } from './btns/CenterBtns';
import { LeftBtns } from './btns/LeftBtns';
import { RightBtns } from './btns/RightBtns';
import { FullScreenBalanceDisplay } from './FullScreenBalanceDisplay';
import { SupportFriend } from './SupportFriend';

export default function Settingsbar() {
	const {
		isFullScreen,
		setIsAutoSpin,
		autoSpinCount,
		setIsDemoSpin,
		setSlotPrizesPreWon,
		setIsBoxOpening,
		clientSeed,
		setWonPrize,
		isBoxOpening,
		setSlotPrizesSurroundingWon,
		decreaseAutoSpinCount,
	} = useBoxOpeningStoreDesktop(
		(state) => ({
			setWonPrize: state.setWonPrize,
			isBoxOpening: state.isBoxOpening,
			isFullScreen: state.isFullScreen,
			isFairnessPopupVisible: state.isFairnessPopupVisible,
			setIsAutoSpin: state.setIsAutoSpin,
			autoSpinCount: state.autoSpinCount,
			setIsDemoSpin: state.setIsDemoSpin,
			setSlotPrizesPreWon: state.setSlotPrizesPreWon,
			setIsFullScreen: state.setIsFullScreen,
			setIsBoxOpening: state.setIsBoxOpening,
			clientSeed: state.clientSeed,
			setSlotPrizesSurroundingWon: state.setSlotPrizesSurroundingWon,
			decreaseAutoSpinCount: state.decreaseAutoSpinCount,
			resetStore: state.resetStore,
		}),
		shallow // Prevents re-renders if the values are shallowly equal
	);

	useEffect(() => {
		if (autoSpinCount > 1 && isBoxOpening) {
			setIsAutoSpin(true);
		}
	}, [autoSpinCount, isBoxOpening, setIsAutoSpin]);

	const { user } = useUserContext();

	const setBalance = useBalanceStore((state) => state.setBalance, shallow);

	const { data: box, isLoading: isBoxFromParamsLoading } = useBoxFromParams();

	const { requestWinningPrize, isBoxOpenRequestLoading } = useWonBoxItem({ useMobileStore: false, box });

	const { generatePreAndPostSlotItems, generateDemoSpinPrize } = useBoxSlotItems({
		numPreSlotPrizes: DESKTOP_NUM_PRE_SLOT_PRIZES,
	});

	const { data: inventoryBoxes } = useInventoryBoxes();
	const isBonusBoxToOpen = usePopupStore((state) => state.isBonusBoxToOpen, shallow);

	const handleBoxOpen = useCallback(
		(isDemoSpin: boolean) => {
			if (!box || box.name.length === 0 || box.items.length === 0) {
				return;
			}

			// TODO: balance and user session check
			const { preSlotItems, surroundingSlotItems } = generatePreAndPostSlotItems(box, !!isBonusBoxToOpen);
			setSlotPrizesPreWon(preSlotItems);
			setSlotPrizesSurroundingWon(surroundingSlotItems);

			if (isDemoSpin) {
				const demoSpinPrize = generateDemoSpinPrize(box);
				setWonPrize(demoSpinPrize);
			} else {
				if (!inventoryBoxes || !inventoryBoxes.some((inventoryBox) => inventoryBox.boxId.name === box.name)) {
					setBalance((prevBalance) => {
						const currentBalance = prevBalance !== undefined ? prevBalance : (user?.balance ?? 0);
						return Math.max(currentBalance - box.price, 0);
					});
				}
				setIsAutoSpin(autoSpinCount > 1);
				decreaseAutoSpinCount();
				requestWinningPrize({ boxName: box?.name, clientSeed });
			}

			setIsBoxOpening(true);
			setIsDemoSpin(isDemoSpin);
		},
		[
			autoSpinCount,
			box,
			clientSeed,
			decreaseAutoSpinCount,
			generateDemoSpinPrize,
			generatePreAndPostSlotItems,
			inventoryBoxes,
			isBonusBoxToOpen,
			requestWinningPrize,
			setBalance,
			setIsAutoSpin,
			setIsBoxOpening,
			setIsDemoSpin,
			setSlotPrizesPreWon,
			setSlotPrizesSurroundingWon,
			setWonPrize,
			user?.balance,
		]
	);

	const isOpenDisabled = isBoxOpening || isBoxOpenRequestLoading || isBoxFromParamsLoading;

	return (
		<div className={`${isFullScreen && 'z-[110]'} w-full flex items-center relative z-10`}>
			<div className="flex justify-between items-center w-full">
				{isFullScreen ? (
					<>
						<SupportFriend />
						<CenterBtns onBoxOpen={handleBoxOpen} isOpenFnDisabled={isOpenDisabled} />
						<FullScreenBalanceDisplay />
					</>
				) : (
					<>
						<LeftBtns />
						<CenterBtns onBoxOpen={handleBoxOpen} isOpenFnDisabled={isOpenDisabled} />
						<RightBtns />
					</>
				)}
			</div>
		</div>
	);
}
