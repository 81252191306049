import { useAutoAnimate } from '@formkit/auto-animate/react';
import { BoxIcon, FreeFont } from 'assets/images';
import { Counter } from 'components/common/Counter';
import { useWindowDimensions } from 'hooks';
import { useMemo } from 'react';
import { useLocation } from 'react-router';
import { usePopupStore } from 'store/usePopupStore';
import { isMobile } from 'utils';
import { shallow } from 'zustand/shallow';

export function StickySignupBonusTimer() {
	const { signupBonusExpiredAt, setsSignupBonusPopupVisible, isSignupBonusPopupVisible } = usePopupStore(
		(state) => ({
			signupBonusExpiredAt: state.signupBonusExpiredAt,
			setsSignupBonusPopupVisible: state.setIsSignupBonusPopupVisible,
			isSignupBonusPopupVisible: state.isSignupBonusPopupVisible,
		}),
		shallow
	);

	const location = useLocation();

	const showStickyBtn = useMemo(
		() =>
			!isSignupBonusPopupVisible &&
			signupBonusExpiredAt &&
			signupBonusExpiredAt.getTime() > new Date().getTime() &&
			!['auth', 'successful-register', 'successful-login'].some((path) => location.pathname.includes(path)),
		[isSignupBonusPopupVisible, location.pathname, signupBonusExpiredAt]
	);

	const [parent] = useAutoAnimate();
	const { screenWidth } = useWindowDimensions();

	return (
		<div
			ref={parent}
			className={`${showStickyBtn ? 'block' : 'hidden'} fixed z-30 w-[150px] h-[65px] ${isMobile(screenWidth) ? 'bottom-[90px] right-2 ' : 'bottom-6  right-3'}`}
		>
			{showStickyBtn && (
				<button
					className="w-full h-full bg-primary-gradient border-[2px] border-darkBlue outline outline-white outline-[2px] flex items-center justify-center gap-x-[8px] rounded-[20px]"
					onClick={() => setsSignupBonusPopupVisible(true)}
				>
					<div className="flex flex-col">
						<img src={FreeFont} alt="" className="h-[18px] object-contain" />
						<img src={BoxIcon} alt="" className="h-[28px] object-contain mt-[1px]" />
					</div>
					{signupBonusExpiredAt && <Counter expirationDate={signupBonusExpiredAt} />}
				</button>
			)}
		</div>
	);
}
