import { faForward, faInfinity, faSliders } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SecondaryBtn } from 'components/common/buttons/SecondaryBtn';
import { useWindowDimensions } from 'hooks';
import { useBoxOpeningStoreDesktop } from 'pages/OpenBox/store/useBoxOpeningStoreDesktop';

import { screens } from '../../../../../../constants';

export function OptionsBtn() {
	const { setShowOpenOptionsScreen, autoSpinCount, isFastSpin, isFullScreen } = useBoxOpeningStoreDesktop();

	const { screenWidth } = useWindowDimensions();

	return (
		<SecondaryBtn
			onClick={() => setShowOpenOptionsScreen(true)}
			style={{
				height: '100%',
				width: 'fit-content',
				borderWidth: '2px',

				background: isFullScreen
					? 'linear-gradient(331.41deg, rgba(35, 109, 244, 0.6) 6.78%, rgba(51, 240, 167, 0.6) 204.87%)'
					: 'linear-gradient(331.41deg, rgba(35, 109, 244, 0.3) 6.78%, rgba(51, 240, 167, 0.3) 204.87%)',
			}}
			content={
				<div className="flex items-center gap-x-[12px] text-[14px]">
					<FontAwesomeIcon icon={faSliders} />
					<span className="hidden ss:block">Options</span>
					{screenWidth > screens.lg.width && (
						<span
							className={`text-center flex items-center justify-center ${isFastSpin ? 'w-[48px]' : 'w-[39px]'} bg-[rgba(255,255,255,0.1)] rounded-[20px] text-[12px] font-semibold h-[23px]`}
						>
							{isFastSpin && <FontAwesomeIcon icon={faForward} className="mr-1 text-[10px]" />}
							{autoSpinCount === Infinity ? <FontAwesomeIcon icon={faInfinity} /> : autoSpinCount + 'x'}
						</span>
					)}
				</div>
			}
		/>
	);
}
