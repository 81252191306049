import React from 'react';

import styles from './winpopup.module.css';

// Define the type for your component props
interface BackgroundFontsProps {
	numElements: number;
	minOpacity: number;
	maxOpacity: number;
	shouldCenter: boolean;
	height: number;
}

export const BackgroundFonts: React.FC<BackgroundFontsProps> = ({
	numElements,
	minOpacity,
	maxOpacity,
	shouldCenter,
	height,
}) => {
	// Function to calculate the opacity for each element
	const calculateOpacities = (num: number, min: number, max: number): number[] => {
		const step = (max - min) / (num - 1);
		return Array.from({ length: num }, (_, i) => min + i * step);
	};

	// Generate opacities
	const opacities = calculateOpacities(numElements, minOpacity, maxOpacity);

	return (
		<div
			className={`absolute z-30 left-0 top-[50%] w-full transform translate-y-[-50%] flex items-center ${shouldCenter && 'justify-center'} ${styles['verticalText']}`}
		>
			{opacities.map((opacity, index) => (
				<h1
					key={index}
					className={`rotate-180 whitespace-nowrap ${index !== 0 && 'ml-[4vw] sm:ml-[10px]'}`}
					style={{ color: `rgba(255, 255, 255, ${opacity})`, fontSize: height }}
				>
					<span className="font-luckiestGuy leading-[15vh]">MR LOOT</span>
				</h1>
			))}
		</div>
	);
};
