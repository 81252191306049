/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import { gsap } from 'gsap';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

interface Props {
	ref: React.RefObject<HTMLDivElement>;
	contentRef?: React.RefObject<HTMLDivElement>; // Additional ref to prevent dragging when user scrolls inside of this ref
	onDismiss?: () => void;
	onInitAnimationComplete?: () => void;
	animationTime?: number;
	startOffset?: number; // Initial Y offset for the slide-up animation
	yDismissThreshold?: number;
	dragResistance?: number;
	dissmissAnimationTime?: number;
	lastDeltaChangeThreshold?: number;
	velocityThreshold?: number;
	disableInitAnimation?: boolean;
}

export const useSlideUpAndDragToDismiss = ({
	ref,
	contentRef,
	onDismiss,
	onInitAnimationComplete,
	animationTime = 0.2,
	dissmissAnimationTime = 0.25,
	startOffset = 100,
	yDismissThreshold = 250,
	dragResistance = 0,
	lastDeltaChangeThreshold = 10,
	velocityThreshold = 300,
	disableInitAnimation,
}: Props) => {
	const dragData = useRef({
		startY: 0,
		startX: 0,
		currentY: 0,
		lastDeltaChange: 0,
		lastY: 0,
		isDragging: false,
		hasDismissed: false,
		startTime: 0,
		endTime: 0,
		isWithinContentRef: false,
	});

	const [isInitAnimationDone, setIsInitAnimationDone] = useState(false);

	const playDismissAnimation = useCallback(() => {
		gsap.to(ref.current, {
			yPercent: 100,
			duration: dissmissAnimationTime,
			ease: 'power1.in',
			onComplete: () => onDismiss && onDismiss(),
		});
	}, [dissmissAnimationTime, onDismiss, ref]);

	const onDragStart = useCallback(
		(e: TouchEvent) => {
			if (!e.touches || e.touches.length === 0) {
				return;
			}

			const { clientX, clientY } = e.touches[0];
			const targetRect = contentRef?.current?.getBoundingClientRect();

			if (
				targetRect &&
				clientX >= targetRect.left &&
				clientX <= targetRect.right &&
				clientY >= targetRect.top &&
				clientY <= targetRect.bottom
			) {
				dragData.current.isWithinContentRef = true;
			} else {
				dragData.current.isWithinContentRef = false;
			}

			const y = e.touches[0].clientY;
			const x = e.touches[0].clientX;
			dragData.current.startY = y;
			dragData.current.startX = x;
			dragData.current.currentY = y;
			dragData.current.hasDismissed = false;
			dragData.current.startTime = Date.now();
		},
		[contentRef]
	);

	const debouncedOnDragMove = useMemo(
		() => (e: TouchEvent) => {
			if (dragData.current.hasDismissed || !ref.current || !e.touches || e.touches.length === 0) {
				return;
			}
			const y = e.touches[0].clientY;
			const x = e.touches[0].clientX;
			let deltaY = y - dragData.current.startY;
			const deltaX = x - dragData.current.startX;
			const isContentAtTop = contentRef?.current ? contentRef.current.scrollTop === 0 : true;
			const isParentAtTop = ref.current.scrollTop <= 0;

			// if content is specified prevent drag when user wants to scroll content
			if (
				dragData.current.isWithinContentRef &&
				(!isContentAtTop || (isContentAtTop && deltaY < 0) || Math.abs(deltaX) > Math.abs(deltaY))
			) {
				return;
			}

			dragData.current.lastDeltaChange = y - dragData.current.lastY;
			dragData.current.lastY = y;

			if (isParentAtTop && deltaY > 0 && Math.abs(deltaX) < Math.abs(deltaY)) {
				// ony draggable when at top and swipe direction is down
				e.preventDefault();
				dragData.current.isDragging = true;

				deltaY = deltaY * (1 - dragResistance);

				const proximityToThreshold = deltaY / yDismissThreshold;
				if (proximityToThreshold >= 1) {
					dragData.current.hasDismissed = true;
					playDismissAnimation();
				} else {
					gsap.set(ref.current, { y: deltaY, duration: 0.1, overwrite: 'auto' });
				}
			}
		},
		[ref, contentRef, dragResistance, yDismissThreshold, playDismissAnimation]
	);

	const onDragMove = useCallback(
		(e: TouchEvent) => {
			debouncedOnDragMove(e);
		},
		[debouncedOnDragMove]
	);

	const onDragEnd = useCallback(() => {
		if (dragData.current.hasDismissed) {
			return;
		}
		dragData.current.endTime = Date.now();
		const timeTaken = (dragData.current.endTime - dragData.current.startTime) / 1000;
		const yMovement = dragData.current.currentY - dragData.current.startY;
		const velocity = Math.abs(yMovement) / timeTaken;

		if (
			dragData.current.isDragging &&
			(dragData.current.lastDeltaChange > lastDeltaChangeThreshold || velocity > velocityThreshold)
		) {
			playDismissAnimation();
		} else {
			gsap.to(ref.current, { y: 0, duration: 0.1 });
		}

		dragData.current = {
			startY: 0,
			startX: 0,
			currentY: 0,
			lastDeltaChange: 0,
			lastY: 0,
			isDragging: false,
			hasDismissed: false,
			startTime: 0,
			endTime: 0,
			isWithinContentRef: false,
		};
	}, [lastDeltaChangeThreshold, ref, velocityThreshold, playDismissAnimation]);

	useEffect(() => {
		const setupDraggable = () => {
			element.addEventListener('touchstart', onDragStart, { passive: false });
			element.addEventListener('touchmove', onDragMove, { passive: false });
			element.addEventListener('touchend', onDragEnd);
		};

		if (!ref.current) {
			return;
		}
		const element = ref.current;

		if (disableInitAnimation) {
			gsap.set(ref.current, { yPercent: 0 });
			setIsInitAnimationDone(true);
			onInitAnimationComplete && onInitAnimationComplete();
			setupDraggable();
		} else {
			gsap.set(ref.current, { yPercent: startOffset });
			gsap.to(ref.current, {
				yPercent: 0,
				duration: animationTime,
				onComplete: () => {
					setIsInitAnimationDone(true);
					onInitAnimationComplete && onInitAnimationComplete();
					setupDraggable();
				},
			});
		}

		return () => {
			element.removeEventListener('touchstart', onDragStart);
			element.removeEventListener('touchmove', onDragMove);
			element.removeEventListener('mouseup', onDragEnd);
		};
	}, [
		onDragEnd,
		onDragMove,
		onDragStart,
		onInitAnimationComplete,
		animationTime,
		ref,
		startOffset,
		disableInitAnimation,
	]);

	return { playDismissAnimation, isInitAnimationDone, setIsInitAnimationDone };
};
