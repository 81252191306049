import { faCancel, faChevronRight, faLifeRing, faRotateRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ToastErrorNotification } from 'components/toasts/ToastErrorNotification';
import { ToastSuccessNotification } from 'components/toasts/ToastSuccessNotification';
import { OrderDbInterface, PublicOrderStatus } from 'interfaces/OrderInterfaces';
import { useCancelOrder } from 'pages/MyOrders/hooks/useCancelOrder';
import { ContactRequestType } from 'pages/policies/Contact';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

import { QUERY_KEYS } from '../../../../constants';

interface Props {
	order: OrderDbInterface;
}
export function OrderActionBtns({ order }: Props) {
	const navigate = useNavigate();

	const handleReportOrder = () => {
		const type = ContactRequestType.ORDER_ISSUES;
		const subject = `Assistance Required for Order #${order.orderNr}`;
		const description = `I am experiencing an issue with my order. Here are the details`;
		navigate(
			`/contact?type=${type}&subject=${encodeURIComponent(subject)}&description=${encodeURIComponent(description)}`
		);
	};

	const handleCreateReturn = () => {
		navigate(`/my-account/my-orders/return-items?orderNr=${order.orderNr}`);
	};

	const queryClient = useQueryClient();

	const { mutate: cancelOrder, isLoading: isCancelReqLoading } = useCancelOrder({
		onSuccess: () => {
			queryClient.invalidateQueries(QUERY_KEYS.inventoryItemData);
			queryClient.invalidateQueries(QUERY_KEYS.orderData);
			toast(<ToastSuccessNotification message="The order has been successfully canceled." />);
		},
		onError: (message) => {
			toast(<ToastErrorNotification message={message} />);
		},
	});

	const isCancelOrderActive = !isCancelReqLoading && !!order && !order.assignedBy;
	const isReturnActive =
		!isCancelReqLoading &&
		order?.publicStatus === PublicOrderStatus.DELIVERED &&
		order.items.some((item) => item.quantity > item.unavailableQuantity);

	return (
		<>
			<button
				className={`${!isCancelOrderActive && 'opacity-60'} w-full bg-mrLootBlue py-[15px] px-mobile-side-padding flex items-center justify-between text-[15px] text-white font-semibold`}
				disabled={!isCancelOrderActive}
				onClick={() => cancelOrder({ orderId: order._id })}
			>
				<span>
					<FontAwesomeIcon icon={faCancel} className="mr-[8px]" />
					Cancel Order
				</span>
				<FontAwesomeIcon icon={faChevronRight} />
			</button>

			<button
				onClick={handleCreateReturn}
				className={`${!isReturnActive && 'opacity-60'} w-full bg-mrLootBlue py-[15px] px-mobile-side-padding flex items-center justify-between text-[15px] text-white font-semibold`}
				disabled={!isReturnActive}
			>
				<span>
					<FontAwesomeIcon icon={faRotateRight} className="mr-[8px]" />
					Create Return
				</span>
				<FontAwesomeIcon icon={faChevronRight} />
			</button>

			<button className="custom-intercom-launcher w-full bg-mrLootBlue py-[15px] px-mobile-side-padding flex items-center justify-between text-[15px] text-white font-semibold">
				<span>
					<FontAwesomeIcon icon={faLifeRing} className="mr-[8px]" />
					Support
				</span>
				<FontAwesomeIcon icon={faChevronRight} />
			</button>
		</>
	);
}
