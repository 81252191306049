import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { forwardRef, CSSProperties } from 'react';

export interface StyleProps {
	labelStyle?: CSSProperties;
	checkboxStyle?: CSSProperties;
	checkIconStyle?: CSSProperties;
}

interface Props extends StyleProps {
	label?: string;
	error?: string;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onBlur?: () => void;
	checked: boolean;
	name: string;
	disabled?: boolean;
	uncheckedColor?: string;
	checkedColor?: string;
	checkIcon?: React.ReactNode;
	errorBorderColor?: string;
}

export const Checkbox = forwardRef<HTMLInputElement, Props>(
	(
		{
			label,
			error,
			onChange,
			onBlur,
			checked,
			name,
			disabled = false,
			labelStyle,
			checkboxStyle,
			checkIconStyle,
			uncheckedColor = 'transparent',
			checkedColor = 'transparent',
			checkIcon,
			errorBorderColor = '#ff0000', // Default error border color
		},
		ref
	) => {
		return (
			<label
				className={`flex items-center space-x-2 cursor-pointer ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
				style={labelStyle}
			>
				<div className="relative">
					<input
						type="checkbox"
						checked={checked}
						onChange={onChange}
						onBlur={onBlur}
						name={name}
						ref={ref}
						disabled={disabled}
						className="sr-only" // Hide the default checkbox
					/>
					<div
						className={`h-[28px] w-[28px] text-white rounded-[4px] border-[2px] flex items-center justify-center transition-colors duration-200 ease-in-out`}
						style={{
							backgroundColor: checked ? checkedColor : uncheckedColor,
							borderColor: error ? errorBorderColor : '#141414',
							...checkboxStyle,
						}}
					>
						{checked && <div style={checkIconStyle}>{checkIcon || <FontAwesomeIcon icon={faCheck} />}</div>}
					</div>
				</div>
				{label && <span className="text-sm">{label}</span>}
			</label>
		);
	}
);

Checkbox.displayName = 'Checkbox';
