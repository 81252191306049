import { UIOrderStatus } from '../../../ui-order.interface';

const ORDER_STATUS_OPTIONS = [
	{
		label: 'TO RECEIVE',
		value: UIOrderStatus.TO_RECEIVE,
	},
	{
		label: 'COMPLETED',
		value: UIOrderStatus.COMPLETED,
	},
	{
		label: 'CANCELLED',
		value: UIOrderStatus.CANCELLED,
	},
];

interface Props {
	currentStatus: UIOrderStatus;
	onStatusChange: (newStatus: UIOrderStatus) => void;
}

export function OrderStatusSwitchBtns({ currentStatus, onStatusChange }: Props) {
	return (
		<div className="flex items-center gap-x-[12px]">
			{ORDER_STATUS_OPTIONS.map((el, index) => (
				<button
					onClick={() => onStatusChange(el.value)}
					key={index}
					className={`${el.value === currentStatus ? 'bg-mrLootBlue text-white' : 'bg-white text-black'} flex items-center justify-center w-[91px] h-[34px] text-[12px] font-semibold rounded-full`}
				>
					{el.label}
				</button>
			))}
		</div>
	);
}
