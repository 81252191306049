import { faCaretLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SecondaryBtn } from 'components/common/buttons/SecondaryBtn';

export function BackBtn() {
	return (
		<SecondaryBtn
			onClick={() => window.history.back()}
			style={{ height: '100%', width: 'fit-content', borderWidth: '2px' }}
			content={
				<div className="flex items-center gap-x-[8px] text-[14px]">
					<FontAwesomeIcon icon={faCaretLeft} />
					<span className="hidden md:flex">Back</span>
				</div>
			}
		/>
	);
}
