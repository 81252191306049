import axios from 'axios';
import { PopulatedBoxInterface } from 'interfaces/BoxInterface';
import { useQuery } from 'react-query';

import { API_DOMAIN, QUERY_CACHE_CONFIG, QUERY_KEYS } from '../../../constants';

async function fetchPopularBoxes() {
	try {
		const response = await axios.get(`${API_DOMAIN.mrlootBackend}/api/box-recommendations/popular-boxes`);

		if (response.data.status === 'success') {
			return response.data.data;
		}
		throw new Error('Failed to load data');
	} catch (err) {
		throw err;
	}
}

export function usePopularBoxes() {
	return useQuery<PopulatedBoxInterface[]>({
		queryKey: [QUERY_KEYS.boxContainer, QUERY_KEYS.popularBoxes],
		queryFn: fetchPopularBoxes,
		cacheTime: QUERY_CACHE_CONFIG.longTerm.cacheTime,
		staleTime: QUERY_CACHE_CONFIG.longTerm.staleTime,
	});
}
