import axios from 'axios';
import { useUserContext } from 'hooks/context';
import { useMutation } from 'react-query';

import { API_DOMAIN } from '../../../constants';

async function handleLogout() {
	try {
		const response = await axios.post(`${API_DOMAIN.mrlootBackend}/api/logout`, {}, { withCredentials: true });
		if (response.data.status !== 'success') {
			throw new Error('Could not logout User');
		}
	} catch (err) {
		console.error(err);
		throw err;
	}
}

interface Props {
	onSuccess?: () => void;
	onError?: (error: Error) => void;
}

export function useLogout({ onError, onSuccess }: Props = {}) {
	const { load: reloadUser } = useUserContext();

	return useMutation(handleLogout, {
		onSuccess: () => {
			reloadUser();
			onSuccess && onSuccess();
		},
		onError: (error: Error) => onError && onError(error),
	});
}
