import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useState } from 'react';

import { MobileMultiSelectDropdownOverlay } from './MobileMultiSelectDropdownOverlay';

interface Option {
	id: string;
	value: string;
}

interface Props {
	heading: string;
	options: Option[];
	selectedOptions: Option[];
	onChange: (options: Option[]) => void;
	hasError?: boolean;
	placeholder?: string;
}

export function MobileMultiSelect({
	onChange,
	options = [],
	selectedOptions = [],
	hasError,
	heading,
	placeholder = 'Select',
}: Props) {
	const [isOverlayVisible, setIsOverlayVisible] = useState(false);

	const handleClose = useCallback(() => {
		setIsOverlayVisible(false);
	}, []);

	return (
		<>
			<button
				type="button"
				onClick={() => setIsOverlayVisible(true)}
				className={`relative w-[110px] h-[55px] ${hasError ? 'border-errorRed' : 'border-black'} border-[1px] rounded-[4px] flex items-center px-[12px]`}
			>
				<span
					className={`text-[15px] line-clamp-1 mx-auto font-semibold ${selectedOptions?.length === 0 ? 'text-[rgba(0,0,0,0.6)]' : 'text-black'} line-clamp-2 capitalize text-wrap`}
				>
					{selectedOptions && selectedOptions.length === 0
						? placeholder
						: selectedOptions.map((el) => el.value).join(', ')}
				</span>
				<FontAwesomeIcon icon={faChevronDown} className=" text-mrLootBlue ml-auto" />
			</button>

			{isOverlayVisible && (
				<MobileMultiSelectDropdownOverlay
					onClose={handleClose}
					onChange={onChange}
					options={options}
					selectedOptions={selectedOptions}
					heading={heading}
				/>
			)}
		</>
	);
}
