import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CSSProperties } from 'react';

interface Props {
	onClose: () => void;
	style?: CSSProperties;
}

export function CloseBtn({ onClose, style }: Props) {
	return (
		<button
			className="absolute rounded-full border-[1px] border-white text-white hover:bg-white hover:text-black right-4 top-3 text-[13px] h-[28px]  w-[28px] flex items-center justify-center"
			onClick={onClose}
			style={style}
		>
			<FontAwesomeIcon icon={faXmark} />
		</button>
	);
}
