import { faCartShopping } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MrLoot } from 'assets/images';
import { AmountDisplayWithCurrency } from 'components/common/AmountDisplayWithCurrency';
import { PrimaryBtn } from 'components/common/buttons/PrimaryBtn';
import { SecondaryBtn } from 'components/common/buttons/SecondaryBtn';
import { ToastErrorNotification } from 'components/toasts/ToastErrorNotification';
import { useUserContext, useWindowDimensions } from 'hooks';
import { useItem } from 'hooks/data/items/useItem';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

import { screens } from '../../constants';
import { CartPopup } from './components/CartPopup/CartPopup';
import { DesktopProductImgSlider } from './components/DesktopImgProductSlider';
import { ProductDescription } from './components/ProductDescription';
import { ProductImageSlider } from './components/ProductImageSlider';
import { ProductOptionInput } from './components/ProductOptionInput';
import { QuantityInput } from './components/QuantityInput';
import { RecommendedBoxes } from './components/RecommendedBoxes';
import { useProductNameFromParams } from './hooks/useProductNameFromParams';
import { useMerchStore } from './MerchStore';
import { BasketProduct } from './shop.interfaces';
import { convertItemOptionsForCheckout } from './util';

export function MerchProductView() {
	const productName = useProductNameFromParams();
	const { data: item } = useItem({ id: productName });

	const { screenWidth } = useWindowDimensions();

	const { addProduct, showCartPopup, setShowCartPopup } = useMerchStore();

	const { control, handleSubmit, reset } = useForm<BasketProduct>({
		defaultValues: {
			item: item,
			quantity: 1,
			options: [],
		},
	});

	// Effect to update the form when the item is loaded
	useEffect(() => {
		if (item) {
			reset({
				item: item,
				quantity: 1,
				options: [],
			});
		}
	}, [item, reset]); // Trigger when item changes

	const { user } = useUserContext();
	const navigate = useNavigate();

	const onSubmit = (data: BasketProduct, actionType: 'buyNow' | 'addToBasket') => {
		if (!item) {
			return;
		}

		const allOptionsFilled = data.options.every((option) => !!option && option.value.trim() !== '');

		if (!allOptionsFilled) {
			toast(<ToastErrorNotification message="Please select a value for all options before proceeding." />);
			return;
		}

		if (actionType === 'buyNow') {
			if (!user) {
				navigate('/auth/login');
				return;
			}

			// handle selectedOptions
			const itemOptions = convertItemOptionsForCheckout(data.item, data.options, data.quantity);

			const checkoutItems = [
				{
					item: item,
					quantity: data.quantity,
					selectedOptions: itemOptions,
					availableInventoryItemIds: [],
					selectedInventoryItemIds: [],
				},
			];

			navigate('/checkout', {
				state: { directPurchaseItems: checkoutItems },
			});
		} else {
			addProduct({ ...data, item: item });
			setShowCartPopup(true);
		}
	};

	useEffect(() => {
		return () => setShowCartPopup(false);
	}, [setShowCartPopup]);

	return (
		<>
			{showCartPopup && <CartPopup />}
			<form className="relative gap-y-[15px] flex flex-col ms:grid grid-cols-2 ms:gap-x-[45px]">
				{screenWidth < screens.ms.width && <ProductImageSlider item={item} />}
				{screenWidth >= screens.ms.width && <DesktopProductImgSlider item={item} />}
				<div className="relative flex flex-col gap-y-[15px]">
					<img src={MrLoot} alt="" className="absolute left-[200px] top-[50px] h-[150px] object-scale-down" />
					<h1 className="font-bold text-[26px] text-white line-clamp-2 mt-[5px]">{item?.name}</h1>
					<AmountDisplayWithCurrency
						amount={item?.price}
						amountStyle={{
							fontSize: '20px',
						}}
						imgStyle={{ height: '22px' }}
					/>
					<span className="text-[12px] -mt-2 mb-[10px] text-white font-semibold">Shipping calculated at checkout.</span>

					{/* Quantity Input */}
					<Controller
						control={control}
						name="quantity"
						render={({ field }) => <QuantityInput value={field.value} onChange={field.onChange} />}
					/>

					{/* Product Options */}
					{item?.options?.map((option, index) => (
						<div key={option._id} className="flex flex-col gap-y-[10px] mt-[12px]">
							<label className="text-white font-text text-[15px]">{option.label}</label>

							<Controller
								control={control}
								name={`options.${index}`} // Referencing the full option object
								render={({ field }) => (
									<ProductOptionInput
										option={option}
										onChange={(value: string) => {
											// Save both label, optionProperty, and the selected value
											field.onChange({
												label: option.label,
												optionProperty: option.optionProperty,
												value: value,
											});
										}}
										selectedValue={field.value?.value} // Access the selected value directly
									/>
								)}
							/>
						</div>
					))}

					{/* Action Buttons */}
					<div className="flex items-center gap-x-[15px] mt-[12px] mb-[4px]">
						<PrimaryBtn
							content="Buy Now"
							style={{ flexGrow: '1', height: '50px' }}
							onClick={handleSubmit((data) => onSubmit(data, 'buyNow'))}
						/>
						<SecondaryBtn
							style={{ width: '75px', height: '50px' }}
							content={<FontAwesomeIcon icon={faCartShopping} />}
							onClick={handleSubmit((data) => onSubmit(data, 'addToBasket'))}
						/>
					</div>

					<ProductDescription description={item?.description} />
				</div>
			</form>
			<h3 className="font-semibold text-[15px] text-white mb-[10px] mt-[30px]">Recommended</h3>
			<RecommendedBoxes />
		</>
	);
}
