import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

function decodeProductNameFromLink(encodedName: string) {
	// Decode the encoded URL string
	const decodedName = decodeURIComponent(encodedName);
	// Reverse the single hyphen to space replacement
	const tempName = decodedName.replace(/-/g, ' ');
	// Reverse double hyphen back to single hyphen
	return tempName.replace(/--/g, '-');
}

export function useProductNameFromParams() {
	const { name: productNameParams } = useParams();
	const [productName, setProductName] = useState('');

	useEffect(() => {
		if (productNameParams) {
			window.scrollTo(0, 0);
			const productName = decodeProductNameFromLink(productNameParams);
			setProductName(productName);
		}
	}, [productNameParams]);

	return productName;
}
