import axios from 'axios';
import { OrderDbInterface } from 'interfaces/OrderInterfaces';
import { useInfiniteQuery } from 'react-query';

import { API_DOMAIN, FIVE_MINUTES_IN_MILLISECONDS, QUERY_KEYS } from '../../../constants';

interface Props {
	limit: number;
}

async function fetchOrders(pageParam: number, limit: number) {
	try {
		const response = await axios.post(
			`${API_DOMAIN.mrlootBackend}/api/order/list/paginated`,
			{ page: pageParam, limit },
			{ withCredentials: true }
		);
		if (response.data.status !== 'success') {
			throw new Error(response.data.message || 'Something unexpected went wrong');
		}
		return {
			data: response.data.data, // Assuming the orders are in response.data.orders
			nextPage: pageParam + 1,
			hasMore: response.data.data.length % limit === 0,
		};
	} catch (err) {
		if (axios.isAxiosError(err)) {
			const message = err.response?.data?.message || err.message || 'Something unexpected went wrong';
			throw new Error(message);
		}
		throw err;
	}
}

interface OrdersResponse {
	data: OrderDbInterface[];
	nextPage: number;
	hasMore: boolean;
}

interface CallbackProps {
	onSuccess?: () => void;
	onError?: (message: string) => void;
}

export function useOrders({ limit }: Props, { onSuccess, onError }: CallbackProps = {}) {
	const { data, error, fetchNextPage, hasNextPage, isFetchingNextPage, status, isLoading } = useInfiniteQuery<
		OrdersResponse,
		Error
	>([QUERY_KEYS.orderData, limit], ({ pageParam = 0 }) => fetchOrders(pageParam, limit), {
		getNextPageParam: (lastPage) => (lastPage.hasMore ? lastPage.nextPage : undefined),
		onError: (err: Error) => {
			if (onError) {
				onError(err.message || 'Something unexpected went wrong');
			}
		},
		onSuccess: () => {
			if (onSuccess) {
				onSuccess();
			}
		},
		staleTime: FIVE_MINUTES_IN_MILLISECONDS,
		cacheTime: FIVE_MINUTES_IN_MILLISECONDS,
	});

	// Flatten the pages into a single array of UnboxedHistoryInterface[]
	const allData = data?.pages.flatMap((page) => page.data) ?? [];

	return {
		data: allData,
		error,
		fetchNextPage,
		hasNextPage,
		isFetchingNextPage,
		status,
		isLoading,
	};
}
