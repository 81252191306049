import { ToastErrorNotification } from 'components/toasts/ToastErrorNotification';
import { ToastSuccessNotification } from 'components/toasts/ToastSuccessNotification';
import { useUserContext } from 'hooks';
import { OrderDbItemInterface } from 'interfaces/OrderInterfaces';
import { OrderReturnItem, OrderReturnItemStatus, RefundMethod } from 'interfaces/OrderReturns.interface';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { QUERY_KEYS } from '../../../constants';
import { convertReturnCreationFormItemOptionsToSelectedOptionSets, validateReturnFormItem } from '../orderUtils';
import { ReturnCreationForm } from '../ui-order.interface';
import { useCreateReturnOrder } from './useCreateReturnOrder';

interface Props {
	orderId?: string;
	orderNr?: number;
	returnFees: number;
	returnableItems?: OrderDbItemInterface[];
	onSuccessfulCreation?: () => void;
}

export function useReturnForm({ orderId, orderNr, returnFees, returnableItems, onSuccessfulCreation }: Props) {
	const queryClient = useQueryClient();
	const { user } = useUserContext();

	const methods = useForm<ReturnCreationForm>({
		defaultValues: {
			items: returnableItems?.map((item) => ({
				checked: false,
				quantity: 1,
				selectedOptions: [],
				refundMethod: undefined,
				itemId: item.itemId._id,
				orderItemId: item._id,
				userAvgPurchaseValue: item.userAvgPurchaseValue,
			})),
		},
	});

	useEffect(() => {
		if (returnableItems) {
			// Update default values for the form when order is loaded
			methods.reset({
				items: returnableItems.map((item) => ({
					checked: false,
					quantity: 1,
					selectedOptions: [],
					refundMethod: undefined,
					itemId: item.itemId._id,
					orderItemId: item._id,
					userAvgPurchaseValue: item.userAvgPurchaseValue,
				})),
			});
		}
	}, [methods, returnableItems]);

	const { mutate: createReturnOrder, isLoading: isCreationLoading } = useCreateReturnOrder({
		onSuccess() {
			queryClient.invalidateQueries(QUERY_KEYS.orderData);
			queryClient.invalidateQueries(QUERY_KEYS.returnOrderData);
			toast(<ToastSuccessNotification message="Return Request successfully created" />);
			onSuccessfulCreation && onSuccessfulCreation();
		},
		onError(message) {
			toast(<ToastErrorNotification message={message ?? 'Something went wrong'} />);
		},
	});

	const onSubmit = (data: ReturnCreationForm) => {
		if (!returnableItems) {
			return;
		}

		let hasErrors = false; // Track if any errors are found

		methods.clearErrors();

		try {
			if (!user) {
				toast(<ToastErrorNotification message="Your session has expired. Please log in again to continue." />);
				return;
			}

			if (!orderId || !orderNr || !returnableItems || returnableItems.length === 0) {
				return;
			}

			const returnItems: OrderReturnItem[] = [];

			data.items.forEach((item, index) => {
				if (!item.checked) {
					return;
				}

				const { isValid, errors } = validateReturnFormItem(item, index, returnableItems);
				if (!isValid) {
					hasErrors = true;
					errors.forEach((error) => {
						methods.setError(error.name, {
							type: error.type,
							message: error.message,
						});
					});
				}

				const qty =
					item.selectedOptions && item.selectedOptions.length > 0 ? item.selectedOptions.length : item.quantity || 1;

				const options = convertReturnCreationFormItemOptionsToSelectedOptionSets(
					returnableItems,
					item.itemId,
					item.selectedOptions
				);

				returnItems.push({
					itemId: item.itemId,
					orderItemId: item.orderItemId,
					quantity: qty,
					status: OrderReturnItemStatus.PENDING_APPROVAL,
					options: options,
					returnReason: item.returnReason,
					refundMethod: item.refundMethod ?? RefundMethod.RETURN_TO_INVENTORY,
					userAvgPurchaseValue: item.userAvgPurchaseValue,
				});
			});

			if (hasErrors) {
				toast(<ToastErrorNotification message="Please complete all required information and try again" />);
				return;
			}

			if (returnFees > user.balance) {
				toast(
					<ToastErrorNotification message="You do not have enough balance to cover the return fees. Please add funds to proceed." />
				);
				return;
			}

			createReturnOrder({ orderId, orderNr, items: returnItems });
		} catch (err: any) {
			console.error(err);
			toast(<ToastErrorNotification message={err.message ?? 'Something went wrong'} />);
		}
	};

	return {
		methods,
		onSubmit,
		isCreationLoading,
	};
}
