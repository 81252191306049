import { Skeleton } from 'components/common';
import { CSSProperties } from 'react';
import Tilt from 'react-parallax-tilt';

interface Props {
	style: CSSProperties;
}

export function LoadingCard3D({ style }: Props) {
	return (
		<Tilt
			perspective={1000}
			glareEnable={true}
			glareColor="rgba(255,255,255,0.5)"
			glareMaxOpacity={0.6}
			glarePosition="all"
			glareBorderRadius="14px"
			className={`rounded-[14px] bg-gray-300 relative flex flex-col items-center justify-between w-full h-full cursor-pointer px-2 pt-2 pb-3`}
			style={style}
		>
			<div className="h-full w-full size-container flex flex-col items-center justify-between">
				<Skeleton
					style={{ marginRight: 'auto', aspectRatio: '1 / 1', width: 'auto', height: '10.5cqh' }}
					color="#8191a0"
				/>

				<Skeleton style={{ height: '38cqh', width: '80%' }} color="#8191a0" />

				<Skeleton style={{ width: '40%', height: '5cqh' }} color="#8191a0" />
				<Skeleton style={{ width: '70%', height: '5cqh' }} color="#8191a0" />

				<Skeleton style={{ width: '100%', height: '16cqh' }} color="#8191a0" />
			</div>
		</Tilt>
	);
}
