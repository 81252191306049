import { faSearch, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useWindowDimensions } from 'hooks/utility/layout-measurements/useWindowDimensions';
import { useEffect, useState } from 'react';

import { screens } from '../../../constants';

interface Props {
	searchInput: string;
	onSearchInputChange: (keyword: string) => void;
}

const DEBOUNCE_TIME = 250;

export function SearchInputField({ searchInput, onSearchInputChange }: Props) {
	const { screenWidth } = useWindowDimensions();

	const [localInput, setLocalInput] = useState(searchInput);

	useEffect(() => {
		console.count();
		const handler = setTimeout(() => {
			onSearchInputChange(localInput);
		}, DEBOUNCE_TIME);

		return () => {
			clearTimeout(handler);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [localInput]);

	useEffect(() => {
		setLocalInput(searchInput);
	}, [searchInput]);

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setLocalInput(e.target.value);
	};
	return (
		<div className="flex items-center w-[100%] mt-[0px] mb-[40px]">
			<div className="mx-auto relative w-[70%]">
				<FontAwesomeIcon
					icon={faSearch}
					className="text-white text-[18px] absolute left-2 top-[50%] translate-y-[-50%]"
				/>
				<input
					value={localInput}
					// eslint-disable-next-line jsx-a11y/no-autofocus
					autoFocus
					onChange={handleInputChange}
					className="text-white focus-within:placeholder-transparent text-center border-b-[2px] border-mrLootBlue w-[100%] bg-transparent py-1 pl-3 pr-8 outline-none text-[1.4rem] font-semibold"
					placeholder={screenWidth > screens.sm.width ? 'Search WinBoxes, Products & Brands' : 'Search'}
				/>
				{searchInput.length > 0 && (
					<button
						className="text-[rgba(255,255,255,0.7)] hover:text-white absolute right-2 top-[50%] translate-y-[-50%] p-1"
						onClick={() => onSearchInputChange('')}
					>
						<FontAwesomeIcon icon={faXmark} className="text-[1.4rem]" />
					</button>
				)}
			</div>
		</div>
	);
}
