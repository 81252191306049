import { faPenToSquare, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ToastErrorNotification } from 'components/toasts/ToastErrorNotification';
import { ToastSuccessNotification } from 'components/toasts/ToastSuccessNotification';
import { useUserContext } from 'hooks';
import { ShippingAddressInterface } from 'interfaces/OrderInterfaces';
import { useDeleteUserAddress } from 'pages/checkout/hooks/useDeleteAddress';
import { toast } from 'react-toastify';

interface Props {
	address: ShippingAddressInterface;
	onEdit: () => void;
}

export function AddressContainer({ address, onEdit }: Props) {
	const { load: reloadUser } = useUserContext();

	const { mutate: deleteUserAddress, isLoading: isDeleteLoadig } = useDeleteUserAddress({
		onError: () => {
			toast(<ToastErrorNotification message="Failed to delete address" />);
		},
		onSuccess: () => {
			toast(<ToastSuccessNotification message="Address was deleted" />);
			reloadUser();
		},
	});

	return (
		<div className="border-[2px] border-white rounded-[10px] w-full h-fit">
			<div
				className="relative rounded-[8px] border-darkBlue border-[2px] w-full h-fit flex flex-col gap-y-[5px] text-[14px] text-white px-[12px] py-[15px] min-h-[110px]"
				style={{
					background: 'linear-gradient(331.41deg, rgba(35, 109, 244, 0.3) 6.78%, rgba(51, 240, 167, 0.3) 204.87%)',
				}}
			>
				<button
					onClick={onEdit}
					disabled={isDeleteLoadig}
					className={`${isDeleteLoadig && 'opacity-50'} absolute right-2 top-2 rounded-full w-[32px] h-[32px] bg-white flex items-center justify-center text-black border-[1px] border-black shadow-[0_4px_4px_rgba(0,0,0,0.25)]`}
				>
					<FontAwesomeIcon icon={faPenToSquare} />
				</button>
				<button
					disabled={isDeleteLoadig}
					onClick={() => deleteUserAddress(address._id)}
					className={`${isDeleteLoadig && 'opacity-50'} absolute right-2 top-[50px] rounded-full w-[32px] h-[32px] bg-white flex items-center justify-center text-errorRed border-[1px] border-black shadow-[0_4px_4px_rgba(0,0,0,0.25)]`}
				>
					<FontAwesomeIcon icon={faTrashCan} />
				</button>

				<span className="font-bold">
					{address?.firstName} {address?.lastName}
				</span>
				<span className="">{address?.phoneNumber}</span>
				<div className="flex flex-col items-start">
					<span className="">
						{address?.street} {address?.houseNr}, {address?.aptNr}
					</span>
					<span className="">
						{address?.city}, {address?.country}
					</span>
				</div>
			</div>
		</div>
	);
}
