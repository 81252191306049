import { faDownLeftAndUpRightToCenter, faUpRightAndDownLeftFromCenter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SecondaryBtn } from 'components/common/buttons/SecondaryBtn';
import { useBoxOpeningStoreDesktop } from 'pages/OpenBox/store/useBoxOpeningStoreDesktop';

export function FullScreenSwitchBtn() {
	const { isFullScreen, toogleIsFullScreen } = useBoxOpeningStoreDesktop();

	function handleFullScreenBtn() {
		if (document.fullscreenElement) {
			document.exitFullscreen();
		} else {
			document.documentElement.requestFullscreen();
		}
		toogleIsFullScreen();
	}

	return (
		<SecondaryBtn
			onClick={handleFullScreenBtn}
			style={{
				height: '100%',
				width: 'fit-content',
				borderWidth: '2px',
				background: isFullScreen
					? 'linear-gradient(331.41deg, rgba(35, 109, 244, 0.6) 6.78%, rgba(51, 240, 167, 0.6) 204.87%)'
					: 'linear-gradient(331.41deg, rgba(35, 109, 244, 0.3) 6.78%, rgba(51, 240, 167, 0.3) 204.87%)',
			}}
			content={
				<div className="w-full flex items-center justify-center text-[14px] xl:text-[16px]">
					<FontAwesomeIcon icon={isFullScreen ? faDownLeftAndUpRightToCenter : faUpRightAndDownLeftFromCenter} />
				</div>
			}
		/>
	);
}
