import { AmountDisplayWithCurrency } from 'components/common/AmountDisplayWithCurrency';

interface Props {
	totalSellAmount: number;
	onClick: () => void;
	isDisabled: boolean;
}

export function SellBtn({ totalSellAmount, onClick, isDisabled }: Props) {
	return (
		<button
			onClick={onClick}
			disabled={isDisabled}
			style={{ background: 'linear-gradient(331.41deg, #FFB800 6.78%, #FFFFFF 204.87%)' }}
			className={`text-[14px]  border-[2px] border-[#FFE600] flex items-center justify-center rounded-full w-[170px] h-[40px] text-white font-semibold outline outline-white outline-[2px]
         ${isDisabled && 'opacity-50'}`}
		>
			<div className="flex items-center gap-x-[10px]">
				<span className="text-[17px]">Sell</span>
				<span>(</span>
				<AmountDisplayWithCurrency
					amount={totalSellAmount}
					imgStyle={{ height: '18px' }}
					amountStyle={{ fontSize: '14px' }}
				/>
				<span>)</span>
			</div>
		</button>
	);
}
