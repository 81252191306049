import { RefObject, useEffect } from 'react';

interface StylePropertyInterface {
	property: 'height' | 'maxHeight' | 'minHeight';
	value: number; // Percentage of the viewport height
}

interface Props {
	ref: RefObject<HTMLElement>;
	properties: StylePropertyInterface[];
}

export const useDvhFallback = ({ ref, properties = [{ property: 'height', value: 100 }] }: Props) => {
	useEffect(() => {
		const testDvhSupport = () => {
			const testElement = document.createElement('div');

			// Apply a style with 1dvh height to the element
			testElement.style.height = '1dvh';
			document.body.appendChild(testElement);

			// Measure the height of the element
			const elementHeight = testElement.offsetHeight;
			const viewportHeight = window.innerHeight;
			const expectedHeight = viewportHeight * 0.01;

			// Clean up by removing the test element
			document.body.removeChild(testElement);

			// Check if the element's height is approximately 1% of the viewport height
			return Math.abs(elementHeight - expectedHeight) < 1;
		};

		const applyStyles = () => {
			properties.forEach(({ property, value }) => {
				if (ref.current) {
					const calculatedHeight = `${window.innerHeight * (value / 100)}px`;
					ref.current.style[property] = calculatedHeight;
				}
			});
		};

		const isSupported = testDvhSupport();
		if (!isSupported) {
			applyStyles();
			window.addEventListener('resize', applyStyles);
			return () => window.removeEventListener('resize', applyStyles);
		}
	}, [properties, ref]);
};
