import { ItemInterface, ItemOptionProperty } from './ItemInterfaces';

export enum InternOrderStatus {
	OPEN = 'open',
	ORDERED = 'ordered',
	SHIPPED = 'shipped',
	DELIVERED = 'delivered',
	CANCELLED = 'cancelled',
}

export enum PublicOrderStatus {
	IN_PROCESS = 'in process',
	SHIPPED = 'shipped',
	DELIVERED = 'delivered',
	CANCELLED = 'cancelled',
}

export enum ItemOrderStatus {
	OPEN = 'open',
	ORDERED = 'ordered',
	SHIPPED = 'shipped',
	DELIVERED = 'delivered',
	RESHIP = 'reship',
	REFUND_ITEM = 'refund item',
	REFUND_MONEY = 'refund money',
	AWAITING_PAYMENT = 'awaiting payment',
	PAYMENT_COMPLETED = 'completed payment',
}

// item options of one or multiple itemProperties like size, color - e.g. when 2 item options (size, color) it holds selection for those options
export interface SelectedItemOption {
	_id: string;
	optionProperty: ItemOptionProperty;
	value: string;
	label: string;
}

export interface BaseSelectedOptionSet {
	_id: string;
	optionSelections: SelectedItemOption[];
}

// informations of multiple item options e.g. when qty = 3 then it holds the individual selection of 3 items
export interface SelectedOptionSet extends BaseSelectedOptionSet {
	isAvailable: boolean;
}

export enum OrderItemReferenceInfos {
	DIGITAL = 'digital',
	NOTE = 'note',
	TRACKING = 'tracking',
}

export interface ItemReferenceInfo {
	value: string; // to store tracking link , game key
	type: OrderItemReferenceInfos;
}

export interface ShippingAddressInterface {
	_id: string;
	firstName: string;
	lastName: string;
	country: string;
	city: string;
	state?: string;
	zipCode: string;
	street: string;
	houseNr: string;
	aptNr?: string;
	phoneNumber?: string;
	phonePrefix?: string; // is also in phone number
	shippingInstructions?: string;
}

export interface OrderItemInterface {
	_id?: string;
	itemId: ItemInterface;
	quantity: number;
	unavailableQuantity: number;
	status: ItemOrderStatus;
	options?: SelectedOptionSet[];
	outstandingInvoiceAmount?: number;
	referenceInfo?: ItemReferenceInfo;
	firstEstArrivalDate?: Date;
	lastEstArrivalDate?: Date;
	userAvgPurchaseValue?: number;
}

export interface OrderDbItemInterface extends OrderItemInterface {
	_id: string;
}

export interface OrderInterface {
	_id?: string;
	orderNr: number;
	userId: string;
	assignedBy: string | undefined;
	shippingAddress: ShippingAddressInterface;
	internStatus: InternOrderStatus;
	publicStatus: PublicOrderStatus;
	isExpressShipping: boolean;
	items: OrderItemInterface[];
	deliveryDate?: Date;
	createdAt: Date;
	updatedAt?: Date;
}

export interface OrderDbInterface extends OrderInterface {
	_id: string;
	items: OrderDbItemInterface[];
	createdAt: Date;
	updatedAt: Date;
}
