import { useEffect } from 'react';

export function useUTMParamsToDataLayer() {
	useEffect(() => {
		const utmParams = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];

		// Define the type of the dataLayerUTM object
		const dataLayerUTM: Record<string, string> = {};

		utmParams.forEach((param) => {
			const value = localStorage.getItem(param);
			if (value) {
				dataLayerUTM[param] = value;
			}
		});

		if (Object.keys(dataLayerUTM).length > 0) {
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({ event: 'utm_tracking', ...dataLayerUTM });
		}
	}, []);
}
