import axios from 'axios';
import { ItemInterface } from 'interfaces/ItemInterfaces';
import { useQuery } from 'react-query';

import { API_DOMAIN, QUERY_KEYS } from '../../../constants';

async function findManyItemsByIds(ids: string[]) {
	try {
		const response = await axios.post(`${API_DOMAIN.mrlootBackend}/api/items/find-many`, { ids });
		if (response.data.status === 'success') {
			return response.data.data;
		}
	} catch (err) {
		if (axios.isAxiosError(err)) {
			const message = err.response?.data?.message || err.message || 'Something unexpected went wrong';
			throw new Error(message);
		}
		throw err;
	}
}

interface Props {
	ids: string[];
}

export function useItems({ ids }: Props) {
	return useQuery<ItemInterface[]>({
		queryKey: [QUERY_KEYS.itemData, ids],
		queryFn: () => findManyItemsByIds(ids),
	});
}
