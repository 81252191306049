import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
	numSelectedItems: number;
	onClick: () => void;
	isExpanded: boolean;
}

export function ExpandBtn({ numSelectedItems, onClick, isExpanded }: Props) {
	return (
		<button
			onClick={onClick}
			className="text-[12px] grid grid-cols-[1fr_3fr_1fr] rounded-full border-[2px] border-darkBlue outline outline-[2px] outline-white bg-secondary-gradient items-center w-[170px] h-[40px] text-white font-semibold"
		>
			<FontAwesomeIcon icon={isExpanded ? faChevronDown : faChevronUp} className="mx-auto" />
			<span>Selected: {numSelectedItems}</span>
		</button>
	);
}
