import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { forwardRef, useState } from 'react';

interface Props {
	label?: string;
	error?: string;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onBlur: () => void;
	value: string;
	name: string;
	placeholder?: string;
}

export const PrimaryPasswordInput = forwardRef<HTMLInputElement, Props>(
	({ label, error, onChange, onBlur, value, name, placeholder = 'Password' }, ref) => {
		const [hidePassword, setHidePassword] = useState(true);

		return (
			<div className="flex flex-col space-y-1">
				{label && <label className="text-sm text-white">{label}</label>}
				<div
					className="relative border-[2px] border-white hover:border-darkBlue focus-within:border-darkBlue rounded-[10px]"
					style={{
						background: 'linear-gradient(331.41deg, rgba(35, 109, 244, 0.3) 6.78%, rgba(51, 240, 167, 0.3) 204.87%)',
					}}
				>
					<input
						type={hidePassword ? 'password' : 'text'}
						value={value}
						onChange={onChange}
						onBlur={onBlur}
						name={name}
						ref={ref}
						placeholder={placeholder}
						className={`rounded-md text-white bg-transparent pl-6 pr-10 py-3 text-sm w-[100%] border-[2px] border-darkBlue focus-visible:outline-none`}
					/>
					<button
						type="button"
						className="absolute p-1 right-2 top-[50%] translate-y-[-50%] text-gray-300 hover:text-gray-400"
						onClick={() => setHidePassword((prev) => !prev)}
					>
						<FontAwesomeIcon icon={hidePassword ? faEyeSlash : faEye} />
					</button>
				</div>
				{error && <span className="text-errorRed text-sm">{error}</span>}
			</div>
		);
	}
);
