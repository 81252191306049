import { Skeleton } from 'components';
import { AmountDisplayWithCurrency } from 'components/common/AmountDisplayWithCurrency';
import { Line } from 'components/common/Line';
import { OrderDbItemInterface } from 'interfaces/OrderInterfaces';

import { ProductInfoRow } from '../MyOrdersOverview/components/ProductInfoRow/ProductInfoRow';
import { ProductInfoRowSkeleton } from '../MyOrdersOverview/components/ProductInfoRow/ProductInfoRowSkeleton';
import { ProductsHeader } from './ProductsHeader';

const FooterSummary = ({
	totalOrderValue,
	colorTheme = 'white',
}: {
	totalOrderValue: number;
	colorTheme?: 'white' | 'blue';
}) => (
	<div className="px-mobile-side-padding py-[5px] flex items-center justify-between text-mrLootBlue text-[15px]">
		<h3 className="font-semibold">Order Total</h3>
		<AmountDisplayWithCurrency
			amount={totalOrderValue}
			imgStyle={{ height: '18px' }}
			amountStyle={{ fontSize: '16px', color: colorTheme === 'white' ? 'white' : 'rgb(12 38 56)' }}
		/>
	</div>
);

const FooterSummarySkeleton = () => (
	<div className="px-mobile-side-padding py-[5px] flex items-center justify-between text-mrLootBlue text-[15px]">
		<Skeleton style={{ height: '23px', width: '106px' }} color="#d1d1d6" />
		<Skeleton style={{ height: '23px', width: '50px' }} color="#d1d1d6" />
	</div>
);

interface Props {
	isLoading: boolean;
	orderItems?: OrderDbItemInterface[];
	orderId?: string;
}

export function OrderItemInfo({ orderItems, isLoading, orderId }: Props) {
	const totalItems = orderItems?.map((item) => item.quantity).reduce((a, b) => a + b);
	const totalOrderValue = orderItems
		?.map((item) => (item.userAvgPurchaseValue ?? item.itemId.price) * item.quantity)
		.reduce((a, b) => a + b, 0);

	return (
		<div className="w-full flex flex-col py-[15px] bg-white gap-y-[15px]">
			<ProductsHeader isLoading={isLoading} totalItems={totalItems} heading="Items in order" />

			<Line style={{ backgroundColor: '#DBDBDB' }} />

			<div className="flex flex-col gap-y-[20px] px-mobile-side-padding">
				{orderId &&
					orderItems &&
					orderItems.map((item) => (
						<ProductInfoRow key={item._id} orderId={orderId} orderedProduct={item} colorTheme="blue" />
					))}
				{isLoading && <ProductInfoRowSkeleton />}
			</div>

			<Line style={{ backgroundColor: '#DBDBDB' }} />

			{totalOrderValue !== undefined && <FooterSummary totalOrderValue={totalOrderValue} colorTheme="blue" />}
			{isLoading && <FooterSummarySkeleton />}
		</div>
	);
}
