import { useBackBtnInTopBar } from 'hooks/app-layout/useBackBtnInTopbar';
import { useTopbarBackBtnListener } from 'hooks/events/useTopbarBackBtnListener';
import { usePersistentState } from 'hooks/utility/usePersistentState';
import { useCallback } from 'react';

import { UIOrderStatus } from '../ui-order.interface';
import { OrderList } from './MyOrdersOverview/components/OrderList';
import { OrderStatusSwitchBtns } from './MyOrdersOverview/components/OrderStatusSwitchBtns';

export function MobileMyOrders() {
	const [filteredStatus, setFilteredStatus] = usePersistentState('filteredOrderStatus', UIOrderStatus.TO_RECEIVE);

	useTopbarBackBtnListener(
		useCallback(() => {
			window.history.back();
		}, [])
	);

	useBackBtnInTopBar();

	return (
		<div className="flex flex-col items-center gap-y-[15px]">
			<h1 className="text-white font-bold text-[20px]">My Orders</h1>
			<OrderStatusSwitchBtns
				currentStatus={filteredStatus}
				onStatusChange={(newStatus) => setFilteredStatus(newStatus)}
			/>

			<OrderList filteredStatus={filteredStatus} />
		</div>
	);
}
