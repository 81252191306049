import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import gsap from 'gsap';
import { useEffect, useRef } from 'react';

interface Props {
	textToCopy?: string;
}

export function CopyBtn({ textToCopy }: Props) {
	const copiedOverlayRef = useRef<HTMLDivElement>(null);
	const timelineRef = useRef<gsap.core.Timeline>();

	useEffect(() => {
		// Cleanup function to be called when the component unmounts
		return () => {
			if (timelineRef.current) {
				timelineRef.current.kill();
			}
		};
	}, []);

	function copyTextToClipboard() {
		animateCopyOverlay();
		if (!navigator.clipboard || !textToCopy) {
			console.error('Clipboard API not supported or text to copy is empty');
			return;
		}

		navigator.clipboard.writeText(textToCopy);
	}

	function animateCopyOverlay() {
		if (timelineRef.current) {
			timelineRef.current.kill(); // Kill the existing timeline if it exists
		}

		const tl = gsap.timeline();
		timelineRef.current = tl; // Save the new timeline in the ref

		tl.set(copiedOverlayRef.current, { display: 'block', opacity: 0 });
		tl.to(copiedOverlayRef.current, { opacity: 1, duration: 0.2 });
		tl.to(copiedOverlayRef.current, { opacity: 0, duration: 0.5, delay: 2 });
		tl.set(copiedOverlayRef.current, { display: 'none' });
	}

	return (
		<div className="relative w-fit h-fit">
			<div
				ref={copiedOverlayRef}
				className="hidden absolute left-[50%] translate-x-[-50%] top-[-3px] translate-y-[-100%] rounded-[4px] bg-white text-black text-[11px] text-center px-[5px] py-[3px]"
			>
				<span className="font-semibold">Copied!</span>
				<div className="absolute left-[50%] bottom-0 translate-x-[-50%] translate-y-[40%] bg-white rotate-45 h-[7px] w-[7px]" />
			</div>
			<button type="button" onClick={copyTextToClipboard} className="focus-within:text-[rgba(255,255,255,0.7)]">
				<FontAwesomeIcon icon={faCopy} />
			</button>
		</div>
	);
}
