import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ShippingAddressInterface } from 'interfaces/OrderInterfaces';
import { useNavigate } from 'react-router';
import { useAppStore } from 'store/useAppStore';
import { shallow } from 'zustand/shallow';

interface Props {
	address: ShippingAddressInterface;
}

export function AddressChangeLink({ address }: Props) {
	const navigate = useNavigate();

	const setShowBackBtnInTopBar = useAppStore((state) => state.setShowBackBtnInTopBar, shallow);

	function handleLinkClick() {
		setShowBackBtnInTopBar(true);
		if (address) {
			navigate(`update-address?id=${address._id}`);
		} else {
			navigate('add-address');
		}
	}

	//const [address, setAddress] = useState<ShippingAddressInterface | undefined>();

	/*useEffect(() => {
		const cookieSelectedAddress = getCookie('selectedAddress');
		if (cookieSelectedAddress) {
			const foundAddress = user?.addresses?.find((address) => address._id === cookieSelectedAddress._id);
			setAddress(foundAddress);
		} else if (user?.addresses && user.addresses.length > 0) {
			setAddress(user.addresses[0]);
		}
	}, [user?.addresses]);*/

	return (
		<button
			onClick={handleLinkClick}
			className={`relative w-full flex flex-col items-start font-semibold gap-y-[12px] pl-[18px] pr-[12px] py-[12px] text-[14px] bg-white rounded-[15px]`}
		>
			{address && (
				<>
					<span className="font-bold">
						{address?.firstName} {address?.lastName}
					</span>
					<span className="">{address?.phoneNumber}</span>
					<div className="flex flex-col items-start">
						<span className="">
							{address?.street} {address?.houseNr}, {address?.aptNr}
						</span>
						<span className="">
							{address?.city}, {address?.country}
						</span>
					</div>
				</>
			)}
			{!address && (
				<>
					<span className="text-[rgba(0,0,0,0.7)] font-semibold">Add Address</span>
					<span className="font-semibold">...</span>
				</>
			)}
			<div
				className={`absolute right-2 top-2 rounded-full flex items-center justify-center h-[36px] w-[36px] border-[1px] border-black shadow-[0_4px_4px_rgba(0,0,0,0.25)]`}
			>
				<FontAwesomeIcon icon={faPenToSquare} className="text-[18px]" />
			</div>
		</button>
	);
}
