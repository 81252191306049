import { useAutoAnimate } from '@formkit/auto-animate/react';
import { SecondaryBtn } from 'components/common/buttons/SecondaryBtn';
import { CryptoPayCurrency, currencyDisplayNames, currencyImages } from 'pages/TransactionManager/deposit.interface';
import { useState } from 'react';

// Only primary coins (exclude network-specific variants)
const primaryCurrencies: CryptoPayCurrency[] = [
	CryptoPayCurrency.BTC,
	CryptoPayCurrency.ETH,
	CryptoPayCurrency.USDT,
	CryptoPayCurrency.DOGE,
	CryptoPayCurrency.SOL,
	CryptoPayCurrency.XRP,
	CryptoPayCurrency.BNB,
	CryptoPayCurrency.MATIC,
	CryptoPayCurrency.USDC,
	CryptoPayCurrency.LTC,
	CryptoPayCurrency.TRON,
	CryptoPayCurrency.BITCOIN_CASH,
	CryptoPayCurrency.MONERO,
	CryptoPayCurrency.SHIBA,
];

// Helper function to get the primary currency for any network variant
const getPrimaryCurrency = (currency: CryptoPayCurrency) => {
	const mapping: Partial<Record<CryptoPayCurrency, CryptoPayCurrency>> = {
		[CryptoPayCurrency.USDT_TRC20]: CryptoPayCurrency.USDT, // USDT on Tron maps to USDT
		[CryptoPayCurrency.USDT_SOL]: CryptoPayCurrency.USDT, // USDT on Solana maps to USDT
		[CryptoPayCurrency.USDC_TRC20]: CryptoPayCurrency.USDC, // USDC on Tron maps to USDC
		[CryptoPayCurrency.USDC_SOL]: CryptoPayCurrency.USDC, // USDC on Solana maps to USDC
		// Add other mappings as necessary
	};
	return mapping[currency] || currency;
};

interface Props {
	selectedCurrency?: CryptoPayCurrency;
	onSelectedCurrencyChange: (newCurrency: CryptoPayCurrency) => void;
	initiallyShowAll?: boolean;
}

export function CryptoPayOptionsList({ onSelectedCurrencyChange, selectedCurrency, initiallyShowAll = false }: Props) {
	const [showAll, setShowAll] = useState(initiallyShowAll);
	// eslint-disable-next-line no-magic-numbers
	const displayedCurrencies = showAll ? primaryCurrencies : primaryCurrencies.slice(0, 7);
	const [parent] = useAutoAnimate();

	return (
		<div ref={parent} className="flex items-center justify-center flex-wrap gap-x-[10px] gap-y-[15px]">
			{displayedCurrencies.map((currency) => (
				<SecondaryBtn
					style={{
						width: '140px',
						minWidth: 'fit-content',
						...(selectedCurrency && getPrimaryCurrency(selectedCurrency) === currency
							? { boxShadow: 'inset 0px -1px 7px rgba(0, 0, 0, 0.7)' }
							: {}),
					}}
					key={currency}
					onClick={() => onSelectedCurrencyChange(currency)}
					content={
						<div className="flex items-center gap-x-[8px]">
							<img className="h-[20px] w-auto object-contain" src={currencyImages[currency]} alt={currency} />
							<span className="text-[17px] text-white font-semibold leading-[18px]">
								{currencyDisplayNames[currency]}
							</span>
						</div>
					}
				/>
			))}
			{!showAll && (
				<SecondaryBtn
					style={{ width: '135px' }}
					onClick={() => setShowAll(true)}
					content={
						<div className="flex items-center gap-x-[8px]">
							<span className="text-[18px] text-white font-semibold">...</span>
						</div>
					}
				/>
			)}
		</div>
	);
}
