import { FreeFont } from 'assets/images';
import { AmountDisplayWithCurrency } from 'components/common/AmountDisplayWithCurrency';

interface Props {
	itemName: string;
	itemBrand?: string;
	additionalCosts: number;
}

export function ProductHeader({ itemName, itemBrand, additionalCosts }: Props) {
	return (
		<div className="w-full flex flex-col items-start">
			<div className="w-full flex items-center justify-between">
				<h2 className="text-white text-[20px] font-semibold line-clamp-1 max-w-[70%]">{itemName}</h2>
				{additionalCosts === 0 && <img src={FreeFont} className="h-[22px]" alt="free cost" />}
				{additionalCosts > 0 && (
					<AmountDisplayWithCurrency
						amount={additionalCosts}
						amountStyle={{ color: 'rgb(248 113 113)', fontSize: '19px' }}
						imgStyle={{ height: '20px' }}
					/>
				)}
			</div>
			{itemBrand && <span className="text-[#868E96] text-[14px] tracking-[0.2px] line-clamp-1">{itemBrand}</span>}
		</div>
	);
}
