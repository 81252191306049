import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PrimaryBtn } from 'components/common/buttons/PrimaryBtn';
import { NumberImg } from 'components/imagesWithFallback/NumberImg';
import { CSSProperties } from 'react';

interface Props {
	style?: CSSProperties;
	numOfBuys: number;
	price: number;
	onClick: () => void;
}

export function AutoplayBuyOptionBtn({ onClick, numOfBuys, style }: Props) {
	return (
		<PrimaryBtn
			onClick={onClick}
			style={{
				background: 'linear-gradient(331.41deg, #236DF4 6.78%, #33F0A7 204.87%)',
				height: '48px',
				width: '79px',
				...style,
			}}
			innerContentStyle={{
				padding: 0,
				border: 'none',
			}}
			content={
				<div className="flex items-center">
					<FontAwesomeIcon
						icon={faPlus}
						className={`text-white font-inter text-[18px] relative ${numOfBuys < 10 ? 'right-[-7px]' : 'right-[-2px]'} `}
					/>
					<NumberImg num={numOfBuys} />
				</div>
			}
		/>
	);
}
