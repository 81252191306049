import { ArrowDown19 } from 'assets/icons/ArrowDown19';
import { ArrowDown91 } from 'assets/icons/ArrowDown91';
import { CalendarSortAscendingIcon } from 'assets/icons/CalendarSortAscendingIcon';
import { DropdownOption } from 'components/common/mobile/Dropdowns/MobileDropdown';
import { MobileDropdownOverlay } from 'components/common/mobile/MobileDropdownOverlay';
import { shallow } from 'zustand/shallow';

import { InventoryOrderOptions } from '../../components/InterfacesUI';
import { useInventoryStore } from '../../store/InventoryStore';

const OPTIONS: DropdownOption[] = [
	{
		label: 'Newest',
		value: InventoryOrderOptions.NEWEST,
		icon: CalendarSortAscendingIcon,
	},
	{
		label: 'Price High',
		value: InventoryOrderOptions.PRICE_HIGH,
		icon: ArrowDown91,
	},
	{
		label: 'Oldest',
		value: InventoryOrderOptions.OLDEST,
		icon: CalendarSortAscendingIcon,
	},
	{
		label: 'Price Low',
		value: InventoryOrderOptions.PRICE_LOW,
		icon: ArrowDown19,
	},
];

interface Props {
	onClose: () => void;
}

export function SortOptionsSlideupSheet({ onClose }: Props) {
	const { currentSortByOption, setCurrentSortByOption } = useInventoryStore(
		(state) => ({
			currentSortByOption: state.currentSortByOption,
			setCurrentSortByOption: state.setCurrentSortByOption,
		}),
		shallow
	);

	return (
		<MobileDropdownOverlay
			heading="Sort By"
			options={OPTIONS}
			currentOption={currentSortByOption}
			onChange={(option) => setCurrentSortByOption(option)}
			onClose={onClose}
		/>
	);
}
