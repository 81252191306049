import { PrimaryBtn } from 'components/common/buttons/PrimaryBtn';
import { MobileEmailInput } from 'components/common/inputs/MobileEmailInput';
import { ToastErrorNotification } from 'components/toasts/ToastErrorNotification';
import { COLORS } from '../../../../constants';
import { useResetPasswordMail } from 'pages/authentication/hooks/useResetPasswordMail';
import { useAuthenticationStore } from 'pages/authentication/store/useAuthenticationStore';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export function MobileResetPasswordEmailStep() {
	const { setEmailForPasswordReset } = useAuthenticationStore();

	const navigate = useNavigate();

	const { mutate: requestResetPasswordMail, isLoading } = useResetPasswordMail({
		onSuccess: () => {
			navigate('/auth/reset-password/verification-code');
		},
		onError: (message) => {
			toast(<ToastErrorNotification message={message} />, {
				style: { backgroundColor: COLORS.blue90 },
			});
		},
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		resetField,
		watch,
	} = useForm<{ email: string }>();

	const onSubmit: SubmitHandler<{ email: string }> = (data) => {
		setEmailForPasswordReset(data.email);
		requestResetPasswordMail(data.email);
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)} className="w-full flex flex-col items-center gap-y-[25px]">
			<div className="flex flex-col gap-y-[15px] items-center mb-[15px]">
				<h1 className="text-white font-bold text-[20px] text-center">Forgot Password?</h1>
				<span className="text-[15px] text-[rgba(255,255,255,0.7)] font-semibold">
					Enter your email to recover your password
				</span>
			</div>
			<MobileEmailInput
				errors={errors}
				register={register}
				resetField={resetField}
				watch={watch}
				style={{ height: '50px' }}
			/>
			<PrimaryBtn type="submit" content="Send" style={{ height: '46px', marginTop: '5px' }} isDisabled={isLoading} />
			<div className="flex items-center gap-x-[7px] font-semibold text-[14px]">
				<span className="text-[rgba(255,255,255,0.7)]">Remember password?</span>
				<Link to="/auth/login" className="text-mrLootBlue ">
					Sign In
				</Link>
			</div>
		</form>
	);
}
