import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ToastErrorNotification } from 'components/toasts/ToastErrorNotification';
import { useDvhFallback } from 'hooks/layout/useDvhFallback';
import { LoginHeader } from 'pages/authentication/mobile/components/LoginHeader';
import { useEffect, useRef } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

import { COLORS } from '../constants';

export default function MobileAuthLayout() {
	const location = useLocation();

	const params = new URLSearchParams(location.search);
	const errorCode = params.get('error_message');

	useEffect(() => {
		if (errorCode && errorCode.length > 0) {
			toast(<ToastErrorNotification message={errorCode} />, {
				style: { backgroundColor: COLORS.blue90 },
				autoClose: 5000,
			});
		}
	}, [errorCode]);

	const parentRef = useRef<HTMLDivElement>(null);
	useDvhFallback({
		ref: parentRef,
		properties: [{ property: 'minHeight', value: 100 }],
	});

	function handleHeaderBackClick() {
		let path = location.pathname;
		path = path.split('?')[0].split('#')[0];
		if (path.endsWith('/')) {
			path = path.slice(0, -1);
		}

		window.history.back();
	}

	return (
		<>
			<ToastContainer
				autoClose={5000}
				stacked={true}
				hideProgressBar={true}
				draggableDirection="y"
				theme="colored"
				toastStyle={{ backgroundColor: COLORS.blue90 }}
				closeButton={(props) => (
					<button onClick={props.closeToast} className="absolute right-2 top-2 text-whie text-[14px] text-white">
						<FontAwesomeIcon icon={faXmark} />
					</button>
				)}
			/>
			<div className="bg-primary-gradient">
				<div ref={parentRef} className="px-mobile-side-padding pt-[25px] space-y-[45px] flex flex-col min-h-dvh">
					<LoginHeader onBackClick={handleHeaderBackClick} />
					<div className="w-full">
						<Outlet />
					</div>
				</div>
			</div>
		</>
	);
}
