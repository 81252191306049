import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CSSProperties, useState } from 'react';
import { FieldError, UseFormRegisterReturn } from 'react-hook-form';

import { AddressInputField } from './AddressInput';

interface Props {
	register: UseFormRegisterReturn;
	value?: string;
	onChange?: (v: string) => void;
	type: 'text' | 'tel' | 'email';
	placeholder: string;
	style?: CSSProperties;
	error?: FieldError;
}

export function OptionalAddressInputField(props: Props) {
	const [showInput, setShowInput] = useState(false);
	return (
		<>
			{!showInput && (
				<button
					onClick={() => setShowInput(true)}
					style={props.style}
					className="text-mrLootBlue font-semibold text-[15px] gap-x-[10px] flex items-center"
				>
					<FontAwesomeIcon icon={faPlus} className="" /> <span>Street Address 2 (Optional)</span>
				</button>
			)}
			{showInput && <AddressInputField {...props} />}
		</>
	);
}
