import { BlueMFont } from 'assets/images';
import { formatProbability } from 'utils';

interface Props {
	showProbability: boolean;
	probability?: number;
}

export function CardHeader({ showProbability, probability }: Props) {
	return (
		<div
			className={`relative flex justify-between items-center w-full px-3  ${!showProbability ? 'h-[7cqh]' : 'h-[10.5cqh]'}`}
		>
			<img alt="" src={BlueMFont} className="h-full" style={{ transition: 'height 0.5s ease-out' }} />
			{probability && (
				<span
					className={`${showProbability ? 'opacity-100' : 'opacity-0'} absolute right-3 top-1/2 -translate-y-1/2 font-bold tracking-[0.5px] text-white text-[10px] xl:text-[13px] xxl:text-[14px]`}
					style={{ transition: 'opacity 0.2s ease-out' }}
				>
					{formatProbability(probability)}
				</span>
			)}
		</div>
	);
}
