import { NumberImg } from 'components/imagesWithFallback/NumberImg';
import { WithComponentFadeIn } from 'components/misc/WithComponentFadeIn';
import { BaseBoxInterface } from 'interfaces/BoxInterface';
import { useBoxOpeningStoreMobile } from 'pages/OpenBox/store/useBoxOpeningStoreMobile';
import { useState } from 'react';

import { AutoplayOptionsOverlay } from './AutoplayOptionsOverlay';
import { OptionsOverlayToggleBtn } from './OptionsOverlayToggleBtn';

interface Props {
	box: BaseBoxInterface;
}

export function SlotSettingsBar({ box }: Props) {
	const { autoSpinCount, isDemoSpin } = useBoxOpeningStoreMobile();

	const [isOptionsOverlayVisible, setIsOptionsOverlayVisible] = useState(false);

	return (
		<div className="relative flex items-center w-full justify-center min-h-[54px]">
			{!isDemoSpin && (
				<div className="absolute left-0 bottom-0 w-full flex items-center justify-between px-mobile-side-padding">
					<OptionsOverlayToggleBtn
						isOptionsOverlayVisible={isOptionsOverlayVisible}
						onToogle={() => setIsOptionsOverlayVisible((prev) => !prev)}
					/>
					<div
						style={{ background: ' linear-gradient(331.41deg, #236DF4 6.78%, #33F0A7 204.87%)' }}
						className="relative right-0 bottom-0 h-[54px] border-[2px] border-white rounded-[20px] w-[70px] flex items-center justify-center"
					>
						<span className="absolute left-[50%] translate-x-[-50%] top-[1px] text-[rgba(255,255,255,0.7)] text-[9px] font-semibold">
							Spins
						</span>
						<NumberImg num={autoSpinCount} style={{ height: '35px', zIndex: 10, marginTop: '-3px' }} />
						<span className="absolute left-[50%] translate-x-[-50%] bottom-[1px] text-[rgba(255,255,255,0.7)] text-[9px] font-semibold">
							left
						</span>
					</div>
				</div>
			)}
			<WithComponentFadeIn show={isOptionsOverlayVisible}>
				<AutoplayOptionsOverlay pricePerSpin={box.price} />
			</WithComponentFadeIn>
		</div>
	);
}
