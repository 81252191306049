import { FontInsufficientBalance } from 'assets/images';
import { PrimaryBtn } from 'components/common/buttons/PrimaryBtn';
import { MrLootRespin } from 'pages/OpenBox/assets';
import { usePopupStore } from 'store/usePopupStore';
import { shallow } from 'zustand/shallow';

export function ToastInsufficientBalanceNotification() {
	const setIsDepositPopupVisible = usePopupStore((state) => state.setIsDepositPopupVisible, shallow);

	return (
		<div className="w-full h-[92px] flex items-center justify-between gap-x-[10px]">
			<img src={MrLootRespin} className="h-[82px] object-contain" alt="" />
			<div className="flex flex-col items-center justify-center w-full gap-y-[4px]">
				<img src={FontInsufficientBalance} alt={'Insufficient Balance'} className="h-[55px] object-scale-down" />
				<PrimaryBtn
					onClick={() => setIsDepositPopupVisible(true)}
					style={{ height: '43px', width: '127px' }}
					content={<span className="text-white font-bold font-inter text-[11px]">Deposit</span>}
				/>
			</div>
		</div>
	);
}
