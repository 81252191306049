import { ToolTip } from 'components/common/ToolTip';
import {
	DBOrderReturnItemPopulated,
	OrderReturnItemStatus,
	orderReturnItemStatusLabels,
} from 'interfaces/OrderReturns.interface';

import { CLOUDFRONT_IMG_URL } from '../../../../../../constants';
import { ProductOptionsInfo } from '../ProductOptionsInfo';
import { ProductInfo } from './ProductInfo';

interface Props {
	returnOrderItem: DBOrderReturnItemPopulated;
}

const returnItemStatusTooltips: Record<OrderReturnItemStatus, string> = {
	[OrderReturnItemStatus.PENDING_APPROVAL]:
		'We’ve received your return request. Sit back and relax while we review your request. You will be notified shortly with the next steps.',
	[OrderReturnItemStatus.APPROVED]:
		'Great news! Your return has been approved. Please follow the provided instructions to return the item, or wait for further details on the next steps.',
	[OrderReturnItemStatus.COMPLETED]:
		'Your return process is now complete. Your refund has been processed or the item has been added back to your inventory. Thank you for your patience.',
	[OrderReturnItemStatus.CANCELLED]:
		'Your return request has been cancelled. No further action is required. If you have any questions, please feel free to contact our support team.',
};

export function ReturnProductInfoRow({ returnOrderItem }: Props) {
	return (
		<div className="flex flex-col w-full gap-y-[5px]">
			<div
				className={`flex items-center gap-x-[5px] mr-auto ${returnOrderItem.status === OrderReturnItemStatus.COMPLETED ? 'text-mrLootBlue' : returnOrderItem.status === OrderReturnItemStatus.CANCELLED ? 'text-orange-500' : 'text-black'} `}
			>
				<ToolTip message={returnItemStatusTooltips[returnOrderItem.status]} iconSize={18} position="top-left" />
				<span className="text-[14px] font-semibold">{orderReturnItemStatusLabels[returnOrderItem.status]}</span>
			</div>
			<div className={`grid grid-cols-[55px_auto] gap-x-[15px] items-center w-full`}>
				<div className="flex items-center justify-center bg-white h-[65px] w-[55px] p-[6px]">
					<img
						src={`${CLOUDFRONT_IMG_URL}/${returnOrderItem.itemId?.imageUrls?.qualityMedium}`}
						className={`h-full object-contain`}
						alt={returnOrderItem.itemId.name}
					/>
				</div>

				<div className={`w-full grid grid-cols-[auto_90px] items-center`}>
					<ProductInfo product={returnOrderItem} />
					<div className=""></div>
				</div>
			</div>

			<div className={`w-full text-black`}>
				{returnOrderItem.options && returnOrderItem.options.length > 0 && (
					<ProductOptionsInfo options={returnOrderItem.options} />
				)}
			</div>
		</div>
	);
}
