import axios from 'axios';
import { PopulatedBoxInterface } from 'interfaces/BoxInterface';
import { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { API_DOMAIN, FIVE_MINUTES_IN_MILLISECONDS, QUERY_KEYS } from '../../../constants';

async function getRandomBoxes(excludedBoxIds: string[], limit: number) {
	try {
		const response = await axios.post(
			`${API_DOMAIN.mrlootBackend}/api/box/get-random-boxes`,
			{
				limit,
				excludedBoxIds,
			},
			{ withCredentials: true }
		);
		if (response.data.status === 'success') {
			return response.data.data;
		}
		throw new Error(response.data.message || 'Failed to fetch random boxes');
	} catch (err) {
		if (axios.isAxiosError(err)) {
			const message = err.response?.data?.message || err.message || 'Something unexpected went wrong';
			throw new Error(message);
		}
		throw err;
	}
}

interface Props {
	limit: number;
	initialBoxes?: PopulatedBoxInterface[] | null;
}

export function useRandomBoxes({ limit, initialBoxes }: Props) {
	const [boxes, setBoxes] = useState<PopulatedBoxInterface[]>(initialBoxes ?? []);
	const [excludedBoxIds, setExcludedBoxIds] = useState<string[]>([]);
	const [isLoadingNextPage, setIsLoadingNextPage] = useState(false);
	const [isInitLoading, setIsInitLoading] = useState(!initialBoxes);

	useEffect(() => {
		if (initialBoxes) {
			setBoxes(initialBoxes);
		}
	}, [initialBoxes]);

	const { data, isLoading, isFetching, isError, error } = useQuery<PopulatedBoxInterface[]>(
		[QUERY_KEYS.boxData, 'randomBoxes', excludedBoxIds, limit],
		() => getRandomBoxes(excludedBoxIds, limit),
		{
			cacheTime: FIVE_MINUTES_IN_MILLISECONDS,
			staleTime: FIVE_MINUTES_IN_MILLISECONDS,
			refetchOnWindowFocus: false,
		}
	);

	useEffect(() => {
		if (data) {
			setBoxes((prevBoxes) => {
				const uniqueBoxes = new Map(prevBoxes.map((box) => [box._id, box]));
				data.forEach((box) => uniqueBoxes.set(box._id, box));
				return Array.from(uniqueBoxes.values());
			});
			setIsLoadingNextPage(false);
			setIsInitLoading(false);
		}
	}, [data]);

	const fetchNextPage = useCallback(() => {
		setIsLoadingNextPage(true);
		setExcludedBoxIds(boxes.map((el) => el._id));
	}, [boxes]);

	const hasNextPage = boxes.length % limit === 0 && boxes.length > 0;

	return {
		boxes,
		isLoading,
		isInitLoading,
		isFetching,
		isLoadingNextPage,
		fetchNextPage,
		hasNextPage,
		isError,
		error,
	};
}
