import { Skeleton } from 'components';

export function ProductInfoRowSkeleton() {
	return (
		<div className={`grid grid-cols-[55px_auto] gap-x-[15px] items-center w-full`}>
			<div className="flex items-center justify-center bg-white h-[55px] w-[55px]">
				<Skeleton style={{ height: '55px', width: '55px' }} color="#d1d1d6" />
			</div>

			<div className="flex flex-col justify-between h-[55px] ">
				<Skeleton style={{ height: '25px', width: '180px' }} color="#d1d1d6" />
				<div className="w-full flex items-center justify-between">
					<Skeleton style={{ height: '20px', width: '35px' }} color="#d1d1d6" />
					<Skeleton style={{ height: '25px', width: '50px' }} color="#d1d1d6" />
				</div>
			</div>
		</div>
	);
}
