import { useEffect } from 'react';
import { toast } from 'react-toastify';

type ElementSizeSpec = {
	ref: React.RefObject<HTMLElement>;
	proportion: number;
	cssProperty: 'height' | 'fontSize';
};

interface Props {
	parentRef: React.RefObject<HTMLDivElement>;
	refs: ElementSizeSpec[];
}

export const useFallbackIfCqhUnsupported = ({ parentRef, refs }: Props) => {
	useEffect(() => {
		const testElement = document.createElement('div');
		testElement.style.height = '10cqh';
		const supportsCQH = testElement.style.height.includes('cqh');

		if (supportsCQH) {
			return;
		}

		let frameId: number;

		const checkHeight = () => {
			if (parentRef.current && parentRef.current.clientHeight > 0) {
				const style = window.getComputedStyle(parentRef.current);
				const paddingTop = parseFloat(style.paddingTop);
				const paddingBottom = parseFloat(style.paddingBottom);
				const verticalPadding = paddingTop + paddingBottom;
				const parentHeight = parentRef.current.clientHeight - verticalPadding;

				refs.forEach(({ ref, proportion, cssProperty }) => {
					if (ref.current) {
						const calculatedValue = `${parentHeight * proportion}px`;
						ref.current.style[cssProperty] = calculatedValue;
					}
				});
			} else {
				frameId = requestAnimationFrame(checkHeight);
			}
		};

		frameId = requestAnimationFrame(checkHeight);

		return () => cancelAnimationFrame(frameId);
	}, [parentRef, refs]); // Ensure effect runs if parentRef or refs change
};
