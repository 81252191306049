import { ItemOption } from 'interfaces/ItemInterfaces';
import { SelectedOptionSet } from 'pages/checkout/InterfacesCheckout';
import { QuantityInput } from 'pages/checkout/mobile/cart-review/ProductCardContainer/QuantityInput';
import { useCheckoutStore } from 'pages/checkout/store/CheckoutStore';

import { MobileItemOptionDropdown } from '../ItemOption/MobileItemOptionDropdown';
import { ProductNoteField } from './ProductNoteField';

interface ProductOptionSectionProps {
	itemId: string;
	note: string;
	quantity: number;
	availableOptions?: ItemOption[];
	selectedOptions?: SelectedOptionSet[];
	currentOptionSetIndex: number;
}

export function ProductOptionSection({
	itemId,
	note,
	quantity,
	availableOptions,
	selectedOptions,
	currentOptionSetIndex,
}: ProductOptionSectionProps) {
	const { deleteOrderItem, updateProductQuantity, updateProductNote, itemOptionErrors } = useCheckoutStore((state) => ({
		deleteOrderItem: state.deleteOrderItem,
		updateProductQuantity: state.updateProductQuantity,
		updateProductNote: state.updateProductNote,
		itemOptionErrors: state.itemOptionErrors,
	}));

	const hasDropdownError = (optionId: string) => {
		return (
			itemOptionErrors &&
			itemOptionErrors[itemId] &&
			itemOptionErrors[itemId][currentOptionSetIndex] &&
			itemOptionErrors[itemId][currentOptionSetIndex].some((option) => option._id === optionId)
		);
	};

	return (
		<div className="w-full h-fit mt-auto flex-grow flex flex-col items-end justify-end space-y-[20px]">
			{selectedOptions &&
				selectedOptions.length >= quantity - 1 &&
				selectedOptions.length >= currentOptionSetIndex - 1 &&
				selectedOptions[currentOptionSetIndex].optionSelections?.map((option) => (
					<div key={option._id} className="w-full flex items-center justify-between">
						<h3 className="text-white text-[18px] font-semibold">{option.label}</h3>

						<MobileItemOptionDropdown
							itemId={itemId}
							availableOptions={availableOptions}
							selectedOption={option}
							index={currentOptionSetIndex}
							hasError={hasDropdownError(option._id)}
						/>
					</div>
				))}

			<div className="w-full flex items-center justify-between">
				<h3 className="text-white text-[18px] font-semibold">Qty</h3>
				<QuantityInput
					value={quantity}
					onChange={(newQty) => updateProductQuantity(itemId, newQty)}
					onItemDelete={() => deleteOrderItem(itemId)}
				/>
			</div>
			<ProductNoteField note={note} onChange={(newNote) => updateProductNote(itemId, newNote)} />
		</div>
	);
}
