import { OpenFont } from 'assets/images';
import { PrimaryBtn } from 'components/common/buttons/PrimaryBtn';
import { WithSkeletonUntilFullyLoaded } from 'components/common/WithSkeletonUntilFullyLoaded';
import { PopulatedBoxInterface } from 'interfaces/BoxInterface';
import { useTop10BoxesOverlayOpacity } from 'pages/overview/hooks/useTop10BoxesOverlayOpacity';
import { useRef } from 'react';
import { useNavigate } from 'react-router';
import { getBoxLink } from 'utils';

import { CLOUDFRONT_IMG_URL } from '../../../../constants';

interface Props {
	selectedBox?: PopulatedBoxInterface;
	parentRef: React.RefObject<HTMLDivElement>;
	scrollRef: React.RefObject<HTMLDivElement>;
}

const opacityThreshold = 0.25;

export function Top10BoxHighlightViewer({ selectedBox, parentRef, scrollRef }: Props) {
	const top10OverlayRef = useRef<HTMLDivElement>(null);

	const { opacity } = useTop10BoxesOverlayOpacity({
		parentRef: parentRef,
		scrollRef: scrollRef,
		top10OverlayRef: top10OverlayRef,
	});

	const navigate = useNavigate();

	function handleBoxNavigation() {
		if (selectedBox) {
			const boxLink = getBoxLink(selectedBox?.name);
			navigate(boxLink);
		}
	}

	return (
		<div
			ref={top10OverlayRef}
			className={`${opacity > opacityThreshold ? ' pointer-events-auto' : ' pointer-events-none'} z-20 fixed top-[14px] left-0 sm:left-[63px] w-full sm:w-[calc(100vw-62px)] h-[calc(100vh-100px)]`}
			style={{ opacity: opacity }}
		>
			<WithSkeletonUntilFullyLoaded
				containerStyle={{
					opacity: opacity,
					zIndex: 20,
					position: 'absolute',
					width: '100%',
					height: '100%',
					inset: '0px',
				}}
				skeletonHeight="100%"
				skeletonWidth="100%"
			>
				<img
					alt={`preview of ${selectedBox?.name} box`}
					src={`${CLOUDFRONT_IMG_URL}/${selectedBox?.withoutTitleLandscapeImageUrls?.qualityMedium}`}
					className={`w-full h-full object-cover object-top`}
					style={{ opacity: opacity }}
				/>
			</WithSkeletonUntilFullyLoaded>

			<div
				className="z-50 w-full h-[200px] absolute bottom-0 left-0 right-0"
				style={{ background: 'linear-gradient(0deg, rgba(26,28,30,1) 0%, rgba(26,28,30,0) 100%)' }}
			/>

			<div className={`${opacity === 0 ? 'hidden' : 'flex'} w-full h-[100vh] bg-background absolute z-10`} />
			<div
				className="absolute z-20 w-full h-full"
				style={{
					backgroundImage: 'radial-gradient(circle, rgba(28,27,30,0.1) 0%, rgba(28,27,30,0.7) 70%)',
				}}
			/>
			<div
				className={`z-20 absolute bottom-[0px] left-0 w-full h-[40%]`}
				style={{
					backgroundImage: 'linear-gradient(0deg, rgba(32,36,40,1) 0%, rgba(32,36,40,0) 100%)',
				}}
			/>
			<div
				className="absolute top-[50%] left-0 w-full h-full translate-y-[-50%]"
				style={{
					backgroundImage: 'radial-gradient(circle, rgba(32,36,40,0) 0%, rgba(32,36,40,1) 100%)',
				}}
			/>

			<div
				className={`space-y-4 absolute z-30 pl-[32px] pt-[72px] inset-0 flex flex-col justify-start items-start w-full h-full font-inter`}
			>
				{selectedBox?.titleImageUrls && (
					<img
						src={`${CLOUDFRONT_IMG_URL}/${selectedBox?.titleImageUrls.qualityMedium}`}
						alt={selectedBox?.name}
						className="object-scale-down h-[75px]"
					/>
				)}
				{!selectedBox?.titleImageUrls && (
					<h1 className="text-[#C7C6CA] font-semibold text-[2.2rem] leading-[2.2rem]">{selectedBox?.name}</h1>
				)}

				<PrimaryBtn
					onClick={handleBoxNavigation}
					renderEllipse={false}
					style={{ height: '50px', width: '150px' }}
					content={<img src={OpenFont} className="w-auto h-[23px]" alt="open" />}
				/>
			</div>
		</div>
	);
}
