import { cloneElement, CSSProperties, ReactElement, useState } from 'react';

import { Skeleton } from './Skeleton'; // Import your existing Skeleton component

interface Props {
	showSkeleton?: boolean;
	children: ReactElement;
	skeletonHeight?: number; // Optional height for the skeleton
	skeletonWidth?: number; // Optional width for the skeleton
	style?: CSSProperties;
	disableAnimation?: boolean;
}

export function WithImgSkeleton({
	children,
	showSkeleton,
	skeletonHeight,
	skeletonWidth,
	style,
	disableAnimation,
}: Props) {
	const [isLoaded, setIsLoaded] = useState(false);

	// Clone the child element to add onLoad and className props
	const imgElement = cloneElement(children, {
		onLoad: () => setIsLoaded(true),
		className: `${children.props.className} ${isLoaded && !showSkeleton ? 'block' : 'hidden'}`,
	});

	return (
		<div className="relative w-full h-full" style={style}>
			{(showSkeleton || !isLoaded) && (
				<Skeleton
					style={style ?? children.props.style}
					height={skeletonHeight}
					width={skeletonWidth}
					disableAnimation={disableAnimation}
				/>
			)}
			{imgElement}
		</div>
	);
}
