import { Ellipse } from 'assets/images';
import { CSSProperties, forwardRef, ReactNode } from 'react';

interface Props {
	renderEllipse?: boolean;
	content: ReactNode;
	isDisabled?: boolean;
	style?: CSSProperties;
	innerContentStyle?: CSSProperties;
}

export const PrimaryContainer = forwardRef<HTMLDivElement, Props>(
	(
		{
			content,
			style,
			isDisabled,
			renderEllipse = true,
			innerContentStyle,
			...rest // Capture any additional props
		},
		ref
	) => {
		return (
			<div
				ref={ref}
				className={`${isDisabled ? 'opacity-50' : ''} w-[100%] relative outline-none rounded-[20px] border-[3px] border-white h-[49px] text-white font-bold text-[19px] overflow-hidden`}
				style={{ ...style }}
				{...rest}
			>
				<div
					className={`border-[2px] border-darkBlue rounded-[17px] w-full h-full px-[12px] flex items-center justify-center relative`}
					style={{
						transition: 'opacity 0.3s',
						background: 'linear-gradient(331.41deg, #236DF4 6.78%, #33F0A7 204.87%)',
						...innerContentStyle,
					}}
				>
					{content}
					{renderEllipse && (
						<img src={Ellipse} className="absolute left-[min(9px,5.5%)] top-[min(3px,4.5%)] object-scale-down" alt="" />
					)}
				</div>
			</div>
		);
	}
);
