import { BigBox } from 'assets/images';
import { CountdownTimer } from 'components/common/CountdownTimer';
import { BoxInterface } from 'interfaces/BoxInterface';

interface Props {
	box: BoxInterface;
}

export function BoxTimeOrQuantityLimit({ box }: Props) {
	return (
		<>
			{box.quantityLimit && (
				<div className="bg-mrLootBlue rounded-[0_0_0_24px] absolute top-0 right-0 px-3 h-[38px] flex items-center space-x-2">
					<span className="font-bold text-white text-[13px]">xxx / {box.quantityLimit}</span>
					<img alt="" src={BigBox} className="h-[24px]" />
				</div>
			)}

			{box.timeLimit && (
				<div className="bg-mrLootBlue rounded-[0_0_0_24px] absolute top-0 right-0 px-3 h-[38px] flex items-center space-x-2">
					<CountdownTimer targetDate={box.timeLimit} />
					<img alt="" src={BigBox} className="h-[24px]" />
				</div>
			)}
		</>
	);
}
