import { PrizeInterface } from 'interfaces/ItemInterfaces';
import { NUM_BUFFER_PRIZES, NUM_BUFFER_TRANSITION_PRIZES } from 'pages/OpenBox/box-opening.constants';
import { PrizeCardList } from 'pages/OpenBox/components/PrizeCardList';
import { useBoxFromParams } from 'pages/OpenBox/hooks/useBoxFromParams';
import { useBoxOpeningStoreDesktop } from 'pages/OpenBox/store/useBoxOpeningStoreDesktop';
import { generateSlotItems } from 'pages/OpenBox/util';
import { useCallback, useEffect, useRef, useState } from 'react';
import { shallow } from 'zustand/shallow';

interface Props {
	onLoadingComplete: () => void;
	onTotalLoadingFail: () => void;
}

/**
 * SpinLoadingBuffer Component
 *
 * This component manages a buffer of prizes and ensures that their images are preloaded
 * before starting a box-opening animation. It uses `useImageLoadingProgress` to monitor
 * image loading and triggers callback functions based on the loading outcome.
 *
 * The buffer includes:
 * - Transition prizes (`NUM_BUFFER_TRANSITION_PRIZES`) for a seamless animation.
 * - Randomly generated prizes (`NUM_BUFFER_PRIZES`) for the spinning animation.
 *
 * Props:
 * @param {Props} param0 - Component props.
 * @param {() => void} param0.onLoadingComplete - Callback invoked when all images are successfully loaded.
 * @param {() => void} param0.onTotalLoadingFail - Callback invoked when image loading fails completely.
 *
 * When Are New Buffer Prizes Generated?
 * - New buffer prizes are generated whenever the box-opening process starts (`isBoxOpening` becomes `true`).
 * - The `box` data and `slotPrizesPreWon` are used to create a buffer of prizes
 *   consisting of transition items and randomly generated slot items.
 *
 * @returns {JSX.Element} A `PrizeCardList` component displaying the preloaded prize buffer.
 */
export const SpinLoadingBuffer = ({ onLoadingComplete, onTotalLoadingFail }: Props) => {
	const { isBoxOpening, slotPrizesPreWon } = useBoxOpeningStoreDesktop(
		(state) => ({
			isBoxOpening: state.isBoxOpening,
			slotPrizesPreWon: state.slotPrizesPreWon,
		}),
		shallow
	);

	const [prizesBuffer, setPrizesBuffer] = useState<PrizeInterface[]>([]);

	const { data: box } = useBoxFromParams();

	const bufferPrizesLoadCounter = useRef(0); // tracks loading status of every prize card

	const checkAllAssetsLoaded = useCallback(() => {
		bufferPrizesLoadCounter.current += 1;
		if (bufferPrizesLoadCounter.current < NUM_BUFFER_PRIZES) {
			return;
		}
		onLoadingComplete();
	}, [onLoadingComplete]);

	// Prepare buffer items on box opening start
	useEffect(() => {
		if (isBoxOpening && box && slotPrizesPreWon) {
			const fullPrizeList = generateSlotItems(box, NUM_BUFFER_PRIZES - NUM_BUFFER_TRANSITION_PRIZES);

			const transitionItems = slotPrizesPreWon?.slice(0, NUM_BUFFER_TRANSITION_PRIZES);

			// Concatenate the first 10 buffer items for a seamless transition
			const prizeBuffer = [...transitionItems, ...fullPrizeList];
			setPrizesBuffer([...prizeBuffer]);
		}
	}, [box, isBoxOpening, slotPrizesPreWon]);

	return <PrizeCardList prizes={prizesBuffer} onImageError={onTotalLoadingFail} onImageLoad={checkAllAssetsLoaded} />;
};

SpinLoadingBuffer.displayName = 'SpinLoadingBuffer';
