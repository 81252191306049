/* eslint-disable no-magic-numbers */
import { SpeechBubbleAnkle } from 'assets/images';
import { useEffect, useState } from 'react';

const DISPLAY_DURATION_MS = 8000; // 8 seconds for message display
const MIN_INTERVAL_MS = 3 * 60000; // Minimum 3 minutes in milliseconds
const MAX_INTERVAL_MS = 7 * 60000; // Maximum 7 minutes in milliseconds

const messages = [
	'10 Fast Spins, 10 Quick Wins',
	'MrLoot is there for you. Always.',
	"Some say 'Loot Machine' has the best odds...",
	'Man... I love the thrill of surprises!',
	"'Childhood Dreams' makes me think of the good old days",
	"'Toast' is my favourite today",
	'Just came back from watching Rick & Morty',
	"Even as an adult... it's always sparkling to play Lego",
	'I feel Gucci today',
	'When was the last time I got new shoes?',
	'I heard you can win a mansion somewhere',
	"New Shirt, iPhone or car? Can't decide...",
	'I love it when my mustache sways in the wind when driving jetski',
	"Search for Candy, you won't be disappointed!",
	'Rumors has it that yesterday someone won the Ferrari F8',
	'I love fate, it always decides that I win!',
	'I imagine myself with a new outfit',
	"Did you know you can play 'Double or nothing'?",
	"I'm trying 'Winter Escape' to get a vacation during these cold days",
	"'Billionaire' makes my jaw drop every time",
	'Did I just hear a whoop of joy?',
	'Christmas is around the corner',
	"Told my friend about MrLoot, now we're celebrating Loot Partys together",
	'Sorry I was afk, had to explain MrLoot to Donald',
	"Ronaldo or Messi? Try the 'Icons of Sports' WinBox",
];

export function SpeechBubble() {
	const [currentMessage, setCurrentMessage] = useState('');
	const [isVisible, setIsVisible] = useState(false);

	// Helper function to get a random interval between MIN_INTERVAL_MS and MAX_INTERVAL_MS
	const getRandomInterval = () => Math.random() * (MAX_INTERVAL_MS - MIN_INTERVAL_MS) + MIN_INTERVAL_MS;

	// Function to show a new random message
	const showMessage = () => {
		const randomMessage = messages[Math.floor(Math.random() * messages.length)];
		setCurrentMessage(randomMessage);
		setIsVisible(true);

		// Hide the message after DISPLAY_DURATION_MS
		setTimeout(() => setIsVisible(false), DISPLAY_DURATION_MS);
	};

	useEffect(() => {
		// Timer for subsequent messages
		const intervalId = setInterval(() => {
			showMessage();
		}, getRandomInterval());

		return () => clearInterval(intervalId);
	}, []);

	return (
		<div
			className={`relative px-[15px] py-[3px] ml-[-1px] mt-[10px] shadow-[0px_4px_4px_rgba(0,0,0,0.25)] font-semibold text-[11px] flex items-center justify-center bg-white rounded-[20px] transition-opacity duration-500 ${
				isVisible ? 'opacity-100' : 'opacity-0'
			}`}
		>
			<img
				src={SpeechBubbleAnkle}
				alt=""
				className="absolute left-[3px] bottom-[-2px] h-[15px] object-contain -translate-x-[50%]"
			/>
			{currentMessage}
		</div>
	);
}
