import { LogoBlue } from 'assets/images';
import { Line } from 'components/common/Line';

import { FooterLinkSection } from './FooterLinkSection';
import { FooterPaymentMethods } from './FooterPaymentMethods';
import { FooterSocialSection } from './FooterSocialSection';

export function Footer() {
	return (
		<footer
			className="w-full flex flex-col gap-y-[40px] pt-[32px] pb-[120px]"
			style={{ background: 'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 49.8%, #2981E9 100%)' }}
		>
			<Line />
			<div className="px-[22px] flex flex-col gap-y-[40px] sm:gap-y-[55px]">
				<img src={LogoBlue} alt="Mrloot Logo" className="h-[35px] object-contain sm:h-[41px]" />
				<FooterSocialSection />
				<FooterPaymentMethods />
				<FooterLinkSection />
				<p className="hidden sm:block text-[rgba(255,255,255,0.7)] text-[11px] text-center text-wrap max-w-[80%] mx-auto">
					MrLoot is a brand licensed to Millerdale & Kingston Ventures LTD, Reg No: HE 466179, having its registered
					address at Georgiou Karaiskaki, 11-13, Pervolia, 7560, Larnaca, Cyprus
				</p>
				<div className="sm:hidden flex flex-col text-[rgba(255,255,255,0.7)] text-[11px] mx-auto max-w-[75%] text-wrap text-center">
					<span>MrLoot is a brand licensed to Millerdale & Kingston Ventures LTD,</span>
					<span>Reg No: HE 466179, having its registered address at</span>
					<span>Georgiou Karaiskaki, 11-13</span>
					<span>Pervolia, 7560, Larnaca, Cyprus</span>
				</div>
			</div>
		</footer>
	);
}
