import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './productImageSlider.css';

import { ItemInterface } from 'interfaces/ItemInterfaces';
import Slider from 'react-slick';

import { CLOUDFRONT_IMG_URL } from '../../../constants';

interface Props {
	item: ItemInterface | undefined;
}

export function ProductImageSlider({ item }: Props) {
	const images = item?.showcaseImgUrls
		? [item?.imageUrls.qualityHigh, ...item?.showcaseImgUrls]
		: [item?.imageUrls.qualityHigh];

	const settings = {
		dots: true,
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		swipe: true,
		arrows: false,
	};

	return (
		<div className="relative -left-mobile-side-padding w-screen h-fit">
			<Slider {...settings} className=" w-full bg-white">
				{images.map((imageUrl, index) => (
					<div key={index} className="!flex justify-center items-center w-full aspect-[1/1.1] pb-[30px]">
						<img
							src={`${CLOUDFRONT_IMG_URL}/${imageUrl}`}
							alt={`Product ${index + 1}`}
							className="w-[80%] h-auto object-contain"
						/>
					</div>
				))}
			</Slider>
		</div>
	);
}
