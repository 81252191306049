import axios from 'axios';
import { API_DOMAIN, QUERY_KEYS } from '../../../constants';
import { useQuery } from 'react-query';

async function fetchExpirationDateOfCurrentResetCode(email: string) {
	try {
		const response = await axios.post(`${API_DOMAIN.mrlootBackend}/api/user/reset-code/get-expiration`, {
			email: email,
		});
		if (response.data.status !== 'success') {
			throw new Error(response.data.message || 'Something unexpected went wrong');
		}
		return response.data.expirationDate;
	} catch (err) {
		if (axios.isAxiosError(err)) {
			const message = err.response?.data?.message || err.message || 'Something unexpected went wrong';
			throw new Error(message);
		}
		throw err;
	}
}

interface Props {
	email: string;
}

export function useExpirationDateOfCurrentResetCode({ email }: Props) {
	return useQuery({
		queryKey: [QUERY_KEYS.passwordResetCode, email, 'code-expiration'],
		queryFn: () => fetchExpirationDateOfCurrentResetCode(email),
		enabled: email.length > 0,
	});
}
