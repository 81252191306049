import axios from 'axios';
import { PopulatedBoxInterface } from 'interfaces/BoxInterface';
import { useCallback, useMemo } from 'react';
import { useInfiniteQuery } from 'react-query';

import { API_DOMAIN, QUERY_KEYS } from '../../../constants';

async function searchBoxes(query: string, page?: number, limit?: number) {
	if (limit === 0) {
		return;
	}

	try {
		const response = await axios.post(
			`${API_DOMAIN.mrlootBackend}/api/boxes/find`,
			{ query, page, limit },
			{ withCredentials: true }
		);
		if (response.data.status === 'success') {
			return response.data;
		}
	} catch (err) {
		console.error(err);
		throw err;
	}
}

interface Props {
	query: string;
	limit?: number;
	initialData?: {
		pages: BoxSearchResultInterface[];
		pageParams: number[];
	};
}

export interface BoxSearchResultInterface {
	data: PopulatedBoxInterface[];
	pagination: {
		page: number;
		totalPages: number;
	};
}

export function useSearchBoxes({ query, limit = 10, initialData }: Props) {
	const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } = useInfiniteQuery<
		BoxSearchResultInterface,
		Error
	>({
		queryKey: [QUERY_KEYS.boxData, 'search', query, limit],
		queryFn: ({ pageParam = 0 }) => searchBoxes(query, pageParam, limit),
		keepPreviousData: true,
		getNextPageParam: (lastPage, allPages) => {
			const nextPage = allPages.length;
			return nextPage < lastPage.pagination.totalPages ? nextPage : undefined;
		},
		enabled: limit > 0 && query.length > 0,
		initialData: initialData
			? {
					pages: initialData.pages,
					pageParams: initialData.pageParams,
				}
			: undefined,
	});

	const loadMore = useCallback(() => {
		if (hasNextPage) {
			fetchNextPage();
		}
	}, [fetchNextPage, hasNextPage]);

	const boxes = useMemo(() => (data?.pages ? data.pages.flatMap((page) => page.data) : undefined), [data]);

	return { data: boxes, isLoading, loadMore, isFetchingNextPage, hasNextPage, rawData: data };
}
