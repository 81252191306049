import { useWindowDimensions } from 'hooks';
import { isMobile } from 'utils';

import { DesktopCookieBanner } from './DesktopCookieBanner';
import { MobileCookieBanner } from './MobileCookieBanner';

interface Props {
	onAccept: () => void;
	onDecline: () => void;
}

export function CookieBanner({ onAccept, onDecline }: Props) {
	const { screenWidth } = useWindowDimensions();

	return (
		<>
			{isMobile(screenWidth) && <MobileCookieBanner onAccept={onAccept} onDecline={onDecline} />}
			{!isMobile(screenWidth) && <DesktopCookieBanner onAccept={onAccept} onDecline={onDecline} />}
		</>
	);
}
