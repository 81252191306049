/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
import { Line } from 'components/common/Line';

export function PrivacyStatement() {
	return (
		<div className="flex flex-col gap-y-[10px]">
			<h1 className="text-white font-bold text-[22px]">Privacy Statement</h1>
			<span className="text-[rgba(255,255,255,0.7)] text-[15px] font-semibold">Updated in July 2024</span>
			<Line style={{ margin: '10px 0' }} />
			<h2 className="mt-[25px] text-white font-bold text-[20px]">INTRODUCTION</h2>
			<p className="text-[15px] leading-[22px] text-justify text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
				Thank you for choosing to be part of our community at MrLoot (Millerdale & Kingston Ventures LTD, Reg No: HE
				466179). We are committed to protecting your personal information and your right to privacy. If you have any
				questions or concerns about our policy, or our practices with regards to your personal information, please
				contact us at support@MrLoot.com. When you visit our website https://MrLoot.com ("MrLoot" or the "Site") and use
				our services, you trust us with your personal information. We take your privacy very seriously. We seek to
				explain to you in the clearest way possible what information we collect, how we use it and what rights you have
				in relation to it. We hope you take some time to read through it carefully, as it is important. If there are any
				terms in this privacy policy that you do not agree with, please discontinue use of our site and our services.
			</p>
			<h2 className="mt-[25px] text-white font-bold text-[20px]">WHAT INFORMATION DO WE COLLECT?</h2>
			<h3 className="mt-[25px] text-white font-bold text-[18px]">Personal information you disclose to us</h3>
			<p className="text-[15px] leading-[22px] text-justify text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
				<strong className="text-white">In Short:</strong> We collect personal information that you provide to us such as
				name, address, contact information, passwords and security data, payment information, and social media login
				data. We collect personal information that you voluntarily provide to us when registering at the Site,
				expressing an interest in obtaining information about us or our products and services, when participating in
				activities on the Site (such as posting messages in our online forums or entering competitions, contests or
				giveaways) or otherwise contacting us. The personal information that we collect depends on the context of your
				interactions with us and the Site, the choices you make and the products and features you use, and can include
				the following:
			</p>
			<p className="text-[15px] leading-[22px] text-justify text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
				<strong className="text-white">Name and Contact Data.</strong> We collect your first and last name, email
				address, postal address, phone number, and other similar contact data.
			</p>
			<p className="text-[15px] leading-[22px] text-justify text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
				<strong className="text-white">Credentials.</strong> We collect passwords, password hints, and similar security
				information used for authentication and account access.
			</p>
			<p className="text-[15px] leading-[22px] text-justify text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
				<strong className="text-white">Payment Data.</strong> We collect data necessary to process your payment if you
				make purchases, such as your payment instrument number (such as a credit card number), and the security code
				associated with your payment instrument. All payment data is stored by our payment processor and you should
				review its privacy policies and contact the payment processor directly if you have a question relating to
				processing of payment data.
			</p>
			<p className="text-[15px] leading-[22px] text-justify text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
				<strong className="text-white">Social Media Login Data.</strong> We provide you with the option to register
				using social media account details, like your Facebook, Twitter or other social media account. If you choose to
				register in this way, we will collect the Information described in the section called "How do we Handle your
				Social Logins" below.
			</p>
			<p className="text-[15px] leading-[22px] text-justify text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
				All personal information that you provide to us must be true, complete and accurate, and you must notify us of
				any changes to such personal information.
			</p>
			<h2 className="mt-[25px] text-white font-bold text-[20px]">Information automatically collected</h2>
			<p className="text-[15px] leading-[22px] text-justify text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
				<strong className="text-white">In Short:</strong> Some information – such as IP address and/or browser and
				device characteristics – is collected automatically when you visit our website.
			</p>
			<p className="text-[15px] leading-[22px] text-justify text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
				We automatically collect certain information when you visit, use or navigate the Site. This information does not
				reveal your specific identity (like your name or contact information) but may include device and usage
				information, such as your IP address, browser and device characteristics, operating system, language
				preferences, referring URLs, device name, country, location, information about how and when you use our Site and
				other technical information. This information is primarily needed to maintain the security and operation of our
				Site, and for our internal analytics and reporting purposes.
			</p>
			<p className="text-[15px] leading-[22px] text-justify text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
				Like many businesses, we also collect information through cookies and similar technologies. You can find out
				more about this in our Cookie Policy.
			</p>
			<h2 className="mt-[25px] text-white font-bold text-[20px]">Information collected from other Sources</h2>
			<p className="text-[15px] leading-[22px] text-justify text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
				<strong className="text-white">In Short:</strong> We may collect limited data from public databases, marketing
				partners, social media platforms, and other outside sources.
			</p>
			<p className="text-[15px] leading-[22px] text-justify text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
				We may obtain information about you from other sources, such as public databases, joint marketing partners,
				social media platforms (such as Facebook), as well as from other third parties. Examples of the information we
				receive from other sources include: social media profile information (your name, gender, birthday, email,
				current city, state and country, user identification numbers for your contacts, profile picture URL and any
				other information that you choose to make public); marketing leads and search results and links, including paid
				listings (such as sponsored links).
			</p>
			<h2 className="mt-[25px] text-white font-bold text-[20px]">HOW DO WE USE YOUR INFORMATION?</h2>
			<p className="text-[15px] leading-[22px] text-justify text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
				<strong className="text-white">In Short:</strong> We process your information for purposes based on legitimate
				business interests, the fulfilment of our contract with you, compliance with our legal obligations, and/or your
				consent.
			</p>
			<p className="text-[15px] leading-[22px] text-justify text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
				We use personal information collected via our Site for a variety of business purposes described below. We
				process your personal information for these purposes in reliance on our legitimate business interests ("Business
				Purposes"), in order to enter into or perform a contract with you ("Contractual"), with your consent
				("Consent"), and/or for compliance with our legal obligations ("Legal Reasons"). We indicate the specific
				processing grounds we rely on next to each purpose listed below.
			</p>
			<p className="text-[15px] leading-[22px] text-justify text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
				We use the information we collect or receive:
			</p>
			<ul className="list-disc list-inside text-[15px] leading-[22px] text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
				<li>
					<strong className="text-white">To facilitate account creation and logon process.</strong> If you choose to
					link your account with us to a third party account (such as your Google or Facebook account), we use the
					information you allowed us to collect from those third parties to facilitate account creation and logon
					process. See the section below headed "How do we Handle your Social Logins" for further information.
				</li>
				<li>
					<strong className="text-white">To send administrative information to you.</strong> We may use your personal
					information to send you product, service and new feature information and/or information about changes to our
					terms, conditions, and policies.
				</li>
				<li>
					<strong className="text-white">To fulfil and manage your orders.</strong> We may use your information to
					fulfil and manage your orders, payments, returns, and exchanges made through the Site.
				</li>
				<li>
					<strong className="text-white">To post testimonials.</strong> We post testimonials on our Site that may
					contain personal information. Prior to posting a testimonial, we will obtain your consent to use your name and
					the content of the testimonial. If you wish to update, or delete your testimonial, please contact us at
					support@MrLoot.com and be sure to include your name, testimonial location, and contact information.
				</li>
				<li>
					<strong className="text-white">Request Feedback.</strong> We may use your information to request feedback and
					to contact you about your use of our Site.
				</li>
				<li>
					<strong className="text-white">To enable user-to-user communications.</strong> We may use your information in
					order to enable user-to-user communications with each user's consent.
				</li>
				<li>
					<strong className="text-white">To manage user accounts.</strong> We may use your information for the purposes
					of managing our account and keeping it in working order.
				</li>
				<li>
					<strong className="text-white">To send you marketing and promotional communications.</strong> We and/or our
					third party marketing partners may use the personal information you send to us for our marketing purposes, if
					this is in accordance with your marketing preferences. You can opt-out of our marketing emails at any time
					(see the "What are your Privacy Rights" below).
				</li>
				<li>
					<strong className="text-white">Deliver targeted advertising to you.</strong> We may use your information to
					develop and display content and advertising (and work with third parties who do so) tailored to your interests
					and/or location and to measure its effectiveness.
				</li>
				<li>
					<strong className="text-white">Administer prize draws and competitions.</strong> We may use your information
					to administer prize draws and competitions when you elect to participate in competitions.
				</li>
				<li>
					<strong className="text-white">To protect our Site.</strong> We may use your information as part of our
					efforts to keep our Site safe and secure (for example, for fraud monitoring and prevention).
				</li>
				<li>
					<strong className="text-white">To enforce our terms, conditions and policies.</strong>
				</li>
				<li>
					<strong className="text-white">To respond to legal requests and prevent harm.</strong> If we receive a
					subpoena or other legal request, we may need to inspect the data we hold to determine how to respond.
				</li>
				<li>
					<strong className="text-white">For other Business Purposes.</strong> We may use your information for other
					Business Purposes, such as data analysis, identifying usage trends, determining the effectiveness of our
					promotional campaigns and to evaluate and improve our Site, products, services, marketing and your experience.
				</li>
			</ul>
			<h2 className="mt-[25px] text-white font-bold text-[20px]">WILL YOUR INFORMATION BE SHARED WITH ANYONE?</h2>
			<p className="text-[15px] leading-[22px] text-justify text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
				<strong className="text-white">In Short:</strong> We only share information with your consent, to comply with
				laws, to provide you with services, to protect your rights, or to fulfill business obligations.
			</p>
			<p className="text-[15px] leading-[22px] text-justify text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
				We may process or share data based on the following legal basis:
			</p>
			<ul className="list-disc list-inside text-[15px] leading-[22px] text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
				<li>
					<strong className="text-white">Consent:</strong> We may process your data if you have given us specific
					consent to use your personal information for a specific purpose.
				</li>
				<li>
					<strong className="text-white">Legitimate Interests:</strong> We may process your data when it is reasonably
					necessary to achieve our legitimate business interests.
				</li>
				<li>
					<strong className="text-white">Performance of a Contract:</strong> Where we have entered into a contract with
					you, we may process your personal information to fulfill the terms of our contract.
				</li>
				<li>
					<strong className="text-white">Legal Obligations:</strong> We may disclose your information where we are
					legally required to do so in order to comply with applicable law, governmental requests, a judicial
					proceeding, court order, or legal process, such as in response to a court order or a subpoena (including in
					response to public authorities to meet national security or law enforcement requirements).
				</li>
				<li>
					<strong className="text-white">Vital Interests:</strong> We may disclose your information where we believe it
					is necessary to investigate, prevent, or take action regarding potential violations of our policies, suspected
					fraud, situations involving potential threats to the safety of any person and illegal activities, or as
					evidence in litigation in which we are involved.
				</li>
			</ul>
			<p className="text-[15px] leading-[22px] text-justify text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
				More specifically, we may need to process your data or share your personal information in the following
				situations:
			</p>
			<ul className="list-disc list-inside text-[15px] leading-[22px] text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
				<li>
					<strong className="text-white">Vendors, Consultants and Other Third-Party Service Providers.</strong> We may
					share your data with third party vendors, service providers, contractors or agents who perform services for us
					or on our behalf and require access to such information to do that work. Examples include: payment processing,
					data analysis, email delivery, hosting services, customer service and marketing efforts. We may allow selected
					third parties to use tracking technology on the Site, which will enable them to collect data about how you
					interact with the Site over time. This information may be used to, among other things, analyze and track data,
					determine the popularity of certain content and better understand online activity. Unless described in this
					Policy, we do not share, sell, rent or trade any of your information with third parties for their promotional
					purposes.
				</li>
				<li>
					<strong className="text-white">Business Transfers.</strong> We may share or transfer your information in
					connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of
					all or a portion of our business to another company.
				</li>
				<li>
					<strong className="text-white">Third-Party Advertisers.</strong> We may use third-party advertising companies
					to serve ads when you visit the Site. These companies may use information about your visits to our Website(s)
					and other websites that are contained in web cookies and other tracking technologies in order to provide
					advertisements about goods and services of interest to you.
				</li>
				<li>
					<strong className="text-white">Affiliates.</strong> We may share your information with our affiliates, in
					which case we will require those affiliates to honor this privacy policy. Affiliates include our parent
					company and any subsidiaries, joint venture partners or other companies that we control or that are under
					common control with us.
				</li>
				<li>
					<strong className="text-white">Business Partners.</strong> We may share your information with our business
					partners to offer you certain products, services or promotions.
				</li>
				<li>
					<strong className="text-white">Other Users.</strong> When you share personal information (for example, by
					posting comments, contributions or other content to the Site) or otherwise interact with public areas of the
					Site, such personal information may be viewed by all users and may be publicly distributed outside the Site in
					perpetuity. If you interact with other users of our Site and register for our Site through a social network
					(such as Facebook), your contacts on the social network will see your name, profile photo, and descriptions of
					your activity. Similarly, other users will be able to view descriptions of your activity, communicate with you
					within our Site, and view your profile.
				</li>
			</ul>
			<h2 className="mt-[25px] text-white font-bold text-[20px]">DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h2>
			<p className="text-[15px] leading-[22px] text-justify text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
				<strong className="text-white">In Short:</strong> We may use cookies and other tracking technologies to collect
				and store your information.
			</p>
			<p className="text-[15px] leading-[22px] text-justify text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
				We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store
				information. Specific information about how we use such technologies and how you can refuse certain cookies is
				set out in our Cookie Policy.
			</p>
			<h2 className="mt-[25px] text-white font-bold text-[20px]">HOW DO WE HANDLE YOUR SOCIAL LOGINS?</h2>
			<p className="text-[15px] leading-[22px] text-justify text-[rgba(255,255,255,255,0.8)] tracking-[0.5px]">
				<strong className="text-white">In Short:</strong> If you choose to register or log in to our services using a
				social media account, we may have access to certain information about you.
			</p>
			<p className="text-[15px] leading-[22px] text-justify text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
				Our Site offers you the ability to register and login using your third-party social media account details (like
				your Facebook or Twitter logins). Where you choose to do this, we will receive certain profile information about
				you from your social media provider. The profile information we receive may vary depending on the social media
				provider concerned, but will often include your name, email address, friends list, profile picture as well as
				other information you choose to make public on such social media platform.
			</p>
			<p className="text-[15px] leading-[22px] text-justify text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
				We will use the information we receive only for the purposes that are described in this privacy policy or that
				are otherwise made clear to you on the Site. Please note that we do not control, and are not responsible for,
				other uses of your personal information by your third-party social media provider. We recommend that you review
				their privacy policy to understand how they collect, use and share your personal information, and how you can
				set your privacy preferences on their sites and apps.
			</p>
			<h2 className="mt-[25px] text-white font-bold text-[20px]">IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</h2>
			<p className="text-[15px] leading-[22px] text-justify text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
				<strong className="text-white">In Short:</strong> We may transfer, store, and process your information in
				countries other than your own.
			</p>
			<p className="text-[15px] leading-[22px] text-justify text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
				Our servers are located in. If you are accessing our Site from outside, please be aware that your information
				may be transferred to, stored, and processed by us in our facilities and by those third parties with whom we may
				share your personal information (see "Will Your Information Be Shared With Anyone?" above), in and other
				countries.
			</p>
			<p className="text-[15px] leading-[22px] text-justify text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
				If you are a resident in the European Economic Area (EEA) or United Kingdom (UK), then these countries may not
				necessarily have data protection laws or other similar laws as comprehensive as those in your country. We will
				however take all necessary measures to protect your personal information in accordance with this privacy policy
				and applicable law.
			</p>
			<p className="text-[15px] leading-[22px] text-justify text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
				<strong className="text-white">Binding Corporate Rules:</strong> These include, a set of Binding Corporate Rules
				("BCRs") established and implemented by. Our BCRs have been recognized by EEA data protection authorities as
				providing an adequate level of protection to the personal information we process internationally.
			</p>
			<p className="text-[15px] leading-[22px] text-justify text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
				<strong className="text-white">Standard Contractual Clauses:</strong> These include, a set of standard
				contractual clauses ("SCCs") established and implemented by. Our SCCs have been recognized by EEA data
				protection authorities as providing an adequate level of protection to the personal information we process
				internationally.
			</p>
			<h2 className="mt-[25px] text-white font-bold text-[20px]">WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?</h2>
			<p className="text-[15px] leading-[22px] text-justify text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
				<strong className="text-white">In Short:</strong> We are not responsible for the safety of any information that
				you share with third-party providers who advertise, but are not affiliated with, our Site.
			</p>
			<p className="text-[15px] leading-[22px] text-justify text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
				The Site may contain advertisements from third parties that are not affiliated with us and which may link to
				other websites, online services or mobile applications. We cannot guarantee the safety and privacy of data you
				provide to any third parties. Any data collected by third parties is not covered by this privacy policy. We are
				not responsible for the content or privacy and security practices and policies of any third parties, including
				other websites, services or applications that may be linked to or from the Site. You should review the policies
				of such third parties and contact them directly to respond to your questions.
			</p>
			<h2 className="mt-[25px] text-white font-bold text-[20px]">HOW LONG DO WE KEEP YOUR INFORMATION?</h2>
			<p className="text-[15px] leading-[22px] text-justify text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
				<strong className="text-white">In Short:</strong> We keep your information for as long as necessary to fulfill
				the purposes outlined in this privacy policy unless otherwise required by law.
			</p>
			<p className="text-[15px] leading-[22px] text-justify text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
				We will only keep your personal information for as long as it is necessary for the purposes set out in this
				privacy policy, unless a longer retention period is required or permitted by law (such as tax, accounting or
				other legal requirements). No purpose in this policy will require us keeping your personal information for
				longer than the period of time in which users have an account with us.
			</p>
			<p className="text-[15px] leading-[22px] text-justify text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
				When we have no ongoing legitimate business need to process your personal information, we will either delete or
				anonymize such information, or, if this is not possible (for example, because your personal information has been
				stored in backup archives), then we will securely store your personal information and isolate it from any
				further processing until deletion is possible.
			</p>
			<h2 className="mt-[25px] text-white font-bold text-[20px]">HOW DO WE KEEP YOUR INFORMATION SAFE?</h2>
			<p className="text-[15px] leading-[22px] text-justify text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
				<strong className="text-white">In Short:</strong> We aim to protect your personal information through a system
				of organizational and technical security measures.
			</p>
			<p className="text-[15px] leading-[22px] text-justify text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
				We have implemented appropriate technical and organizational security measures designed to protect the security
				of any personal information we process. However, despite our safeguards and efforts to secure your information,
				no electronic transmission over the Internet or information storage technology can be guaranteed to be 100%
				secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will
				not be able to defeat our security, and improperly collect, access, steal, or modify your information. Although
				we will do our best to protect your personal information, transmission of personal information to and from our
				Site is at your own risk. You should only access the Site within a secure environment.
			</p>
			<h2 className="mt-[25px] text-white font-bold text-[20px]">DO WE COLLECT INFORMATION FROM MINORS?</h2>
			<p className="text-[15px] leading-[22px] text-justify text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
				<strong className="text-white">In Short:</strong> We do not knowingly collect data from or market to children
				under 18 years of age.
			</p>
			<p className="text-[15px] leading-[22px] text-justify text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
				We do not knowingly solicit data from or market to children under 18 years of age. By using the Site, you
				represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such
				minor dependent’s use of the Site. If we learn that personal information from users less than 18 years of age
				has been collected, we will deactivate the account and take reasonable measures to promptly delete such data
				from our records. If you become aware of any data we may have collected from children under age 18, please
				contact us at support@MrLoot.com.
			</p>
			<h2 className="mt-[25px] text-white font-bold text-[20px]">WHAT ARE YOUR PRIVACY RIGHTS?</h2>
			<p className="text-[15px] leading-[22px] text-justify text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
				<strong className="text-white">In Short:</strong> You may review, change, or terminate your account at any time.
			</p>
			<p className="text-[15px] leading-[22px] text-justify text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
				If you are a resident in the European Economic Area and you believe we are unlawfully processing your personal
				information, you also have the right to complain to your local data protection supervisory authority. You can
				find their contact details here:{' '}
				<a
					href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
					className="text-white"
					target="_blank"
					rel="noreferrer"
				>
					http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
				</a>
				.
			</p>
			<p className="text-[15px] leading-[22px] text-justify text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
				If you are a resident in Switzerland, the contact details for the data protection authorities are available
				here:{' '}
				<a href="https://www.edoeb.admin.ch/edoeb/en/home.html" className="text-white" target="_blank" rel="noreferrer">
					https://www.edoeb.admin.ch/edoeb/en/home.html
				</a>
				.
			</p>
			<p className="text-[15px] leading-[22px] text-justify text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
				If you have questions or comments about your privacy rights, you may email us at support@MrLoot.com.
			</p>
			<h2 className="mt-[25px] text-white font-bold text-[20px]">ACCOUNT INFORMATION</h2>
			<p className="text-[15px] leading-[22px] text-justify text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
				If you would at any time like to review or change the information in your account or terminate your account, you
				can:
			</p>
			<ul className="list-disc list-inside text-[15px] leading-[22px] text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
				<li>Log into your account settings and update your user account.</li>
				<li>Contact us using the contact information provided.</li>
			</ul>
			<p className="text-[15px] leading-[22px] text-justify text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
				Upon your request to terminate your account, we will deactivate or delete your account and information from our
				active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems,
				assist with any investigations, enforce our Terms of Use and/or comply with legal requirements.
			</p>
			<h2 className="mt-[25px] text-white font-bold text-[20px]">COOKIES AND SIMILAR TECHNOLOGIES:</h2>
			<p className="text-[15px] leading-[22px] text-justify text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
				Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your
				browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could
				affect certain features or services of our Site. To opt-out of interest-based advertising by advertisers on our
				Site visit{' '}
				<a href="http://www.aboutads.info/choices/" className="text-white" target="_blank" rel="noreferrer">
					http://www.aboutads.info/choices/
				</a>
				.
			</p>
			<h2 className="mt-[25px] text-white font-bold text-[20px]">CONTROLS FOR DO-NOT-TRACK FEATURES</h2>
			<p className="text-[15px] leading-[22px] text-justify text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
				Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT")
				feature or setting you can activate to signal your privacy preference not to have data about your online
				browsing activities monitored and collected. No uniform technology standard for recognizing and implementing DNT
				signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism
				that automatically communicates your choice not to be tracked online. If a standard for online tracking is
				adopted that we must follow in the future, we will inform you about that practice in a revised version of this
				privacy policy.
			</p>
			<h2 className="mt-[25px] text-white font-bold text-[20px]">
				DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
			</h2>
			<p className="text-[15px] leading-[22px] text-justify text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
				<strong className="text-white">In Short:</strong> Yes, if you are a resident of California, you are granted
				specific rights regarding access to your personal information.
			</p>
			<p className="text-[15px] leading-[22px] text-justify text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
				California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our users who are
				California residents to request and obtain from us, once a year and free of charge, information about categories
				of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and
				addresses of all third parties with which we shared personal information in the immediately preceding calendar
				year. If you are a California resident and would like to make such a request, please submit your request in
				writing to us using the contact information provided below.
			</p>
			<p className="text-[15px] leading-[22px] text-justify text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
				If you are under 18 years of age, reside in California, and have a registered account with the Site, you have
				the right to request removal of unwanted data that you publicly post on the Site. To request removal of such
				data, please contact us using the contact information provided below, and include the email address associated
				with your account and a statement that you reside in California. We will make sure the data is not publicly
				displayed on the Site, but please be aware that the data may not be completely or comprehensively removed from
				our systems.
			</p>
			<h2 className="mt-[25px] text-white font-bold text-[20px]">DO WE MAKE UPDATES TO THIS POLICY?</h2>
			<p className="text-[15px] leading-[22px] text-justify text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
				<strong className="text-white">In Short:</strong> Yes, we will update this policy as necessary to stay compliant
				with relevant laws.
			</p>
			<p className="text-[15px] leading-[22px] text-justify text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
				We may update this privacy policy from time to time. The updated version will be indicated by an updated
				"Revised" date and the updated version will be effective as soon as it is accessible. If we make material
				changes to this privacy policy, we may notify you either by prominently posting a notice of such changes or by
				directly sending you a notification. We encourage you to review this privacy policy frequently to be informed of
				how we are protecting your information.
			</p>
			<h2 className="mt-[25px] text-white font-bold text-[20px]">HOW CAN YOU CONTACT US ABOUT THIS POLICY?</h2>
			<p className="text-[15px] leading-[22px] text-justify text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
				If you have questions or comments about this policy, you may email us at support@MrLoot.com or by post to:
			</p>
			<p className="text-[15px] leading-[22px] text-justify text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
				<strong className="text-white">Mr. Loot</strong>
				<br />
				Georgiou Karaiskaki, 11-13 <br />
				CARIS SALONICA, Flat/Office 102 <br />
				Pervolia, 7560, Larnaca, Cyprus
			</p>
			<p className="text-[15px] leading-[22px] text-justify text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
				If you have any further questions or comments, you may also contact us by post at the following corporate
				address:
			</p>
			<p className="text-[15px] leading-[22px] text-justify text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
				<strong className="text-white">Mr. Loot</strong>
				<br />
				Georgiou Karaiskaki, 11-13 <br />
				CARIS SALONICA, Flat/Office 102 <br />
				Pervolia, 7560, Larnaca, Cyprus
			</p>
			<p className="text-[15px] leading-[22px] text-justify text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
				<br />
			</p>
			<p className="text-[15px] leading-[22px] text-justify text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
				<br />
			</p>
		</div>
	);
}
