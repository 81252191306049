import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TruckFastIcon } from 'assets/icons';
import { CSSProperties } from 'react';
import { Link } from 'react-router-dom';

interface Props {
	style?: CSSProperties;
}

export function AddShippingAddressBtn({ style }: Props = {}) {
	return (
		<Link
			to="/my-account/edit-profile/add-address"
			className="flex items-center w-full bg-[#F5F5F5] rounded-[4px] h-[60px] shadow-btnSm justify-between space-x-[15px] px-[15px]"
			style={style}
		>
			<TruckFastIcon />
			<span className="font-bold text-[#212529] text-[16px] font-inter !mr-auto">Add Address</span>
			<FontAwesomeIcon icon={faChevronRight} className="text-mrLootBlue text-[20px]" />
		</Link>
	);
}
