import { useWindowDimensions } from 'hooks';
import { useCallback, useEffect, useRef, useState } from 'react';

import { CLOUDFRONT_IMG_URL, screens } from '../../constants';

type BoxTitleImgProps = {
	fileUrl?: string;
	boxname?: string;
};

const DEFAULT_HEIGHT = 75;
const DEFAULT_HEIGHT_XL = 85;
const MAX_ASPECT_RATIO_TWO_LINER = 2.5;
const MAX_ASPECT_RATIO_ONE_HALF_LINER = 3.5;
const MIN_NAME_LEN_MULTI_LINER = 7; // to prevent box names likes dior with small aspect ratio to be considered as two liner
const TWO_LINER_FACTOR = 1.75;
const ONE_HALF_LINER_FACTOR = 1.5;

export function BoxTitleImg({ fileUrl, boxname = '' }: BoxTitleImgProps) {
	const [imageHeight, setImageHeight] = useState<number>(0);
	const imgRef = useRef<HTMLImageElement>(null);

	const { screenWidth } = useWindowDimensions();

	const handleImgLoad = useCallback(() => {
		const imgElement = imgRef.current;

		if (imgElement) {
			const actualHeight = imgElement.naturalHeight || 0;
			const actualWidth = imgElement.naturalWidth || 0;
			const aspectRatio = actualWidth / actualHeight;

			let newHeight = DEFAULT_HEIGHT;
			if (screenWidth > screens.xl.width) {
				newHeight = DEFAULT_HEIGHT_XL;
			}

			const isTwoLiner = aspectRatio <= MAX_ASPECT_RATIO_TWO_LINER && boxname?.length > MIN_NAME_LEN_MULTI_LINER;
			const isOneHalfLiner =
				aspectRatio <= MAX_ASPECT_RATIO_ONE_HALF_LINER && boxname?.length > MIN_NAME_LEN_MULTI_LINER;

			newHeight = isTwoLiner
				? newHeight * TWO_LINER_FACTOR
				: isOneHalfLiner
					? newHeight * ONE_HALF_LINER_FACTOR
					: newHeight;
			setImageHeight(newHeight);
		}
	}, [boxname?.length, screenWidth]);

	useEffect(() => {
		const imgElement = imgRef.current;
		if (imgElement) {
			imgElement.onload = handleImgLoad;
			handleImgLoad();
			return () => {
				imgElement.onload = null;
			};
		}
	}, [fileUrl, handleImgLoad]);

	useEffect(() => {
		window.addEventListener('resize', handleImgLoad);

		return () => {
			window.removeEventListener('resize', handleImgLoad);
		};
	}, [handleImgLoad]);

	return (
		<img
			ref={imgRef}
			src={`${CLOUDFRONT_IMG_URL}/${fileUrl}`}
			className="w-auto object-contain"
			style={{ height: `${imageHeight}px` }}
			alt={boxname}
		/>
	);
}
