import React from 'react';

interface Props {
	width?: number;
	height?: number;
}

export function IconWalletMoney({ height = 24, width = 24 }: Props = {}) {
	return (
		<>
			{/* Icon used under Creative Commons Attribution 4.0 International (CC BY 4.0) License */}
			{/* No changes were made to this icon. */}
			{/* License details: https://creativecommons.org/licenses/by/4.0/ */}
			<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
				<g fill="none" stroke="currentColor">
					<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M6 10h4"></path>
					<path
						strokeWidth={1.5}
						d="M20.833 11h-2.602C16.446 11 15 12.343 15 14s1.447 3 3.23 3h2.603c.084 0 .125 0 .16-.002c.54-.033.97-.432 1.005-.933c.002-.032.002-.071.002-.148v-3.834c0-.077 0-.116-.002-.148c-.036-.501-.465-.9-1.005-.933c-.035-.002-.076-.002-.16-.002Z"
					></path>
					<path
						strokeWidth={1.5}
						d="M20.965 11c-.078-1.872-.328-3.02-1.137-3.828C18.657 6 16.771 6 13 6h-3C6.229 6 4.343 6 3.172 7.172C2 8.343 2 10.229 2 14c0 3.771 0 5.657 1.172 6.828C4.343 22 6.229 22 10 22h3c3.771 0 5.657 0 6.828-1.172c.809-.808 1.06-1.956 1.137-3.828"
					></path>
					<path strokeLinecap="round" strokeWidth={1.5} d="m6 6l3.735-2.477a3.237 3.237 0 0 1 3.53 0L17 6"></path>
					<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.991 14h.01"></path>
				</g>
			</svg>
		</>
	);
}
