/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable complexity */
/* eslint-disable import/no-unused-modules */
import './index.css';
import 'react-toastify/dist/ReactToastify.css';

import { LoadSpinner } from 'components/common/loading/LoadSpinner';
import { NotFound404 } from 'components/layout/NotFound404';
import { WithPageTitle } from 'components/misc/WithPageTitle';
import { MobileChooseCurrencyPopup } from 'components/PopupHandler/CurrencyPopup/MobileChooseCurrencyPopup';
import { MobilePopupHandler } from 'components/PopupHandler/MobilePopupHandler';
import { useUserContext } from 'hooks/context/useUserContext';
import MobileAuthLayout from 'layouts/MobileAuthLayout';
import MobileCheckoutLayout from 'layouts/MobileCheckoutLayout';
import { Community } from 'pages/Community/Community';
import { MobileInventory } from 'pages/inventory/mobile/MobileInventory';
import { MobileBoxOpen } from 'pages/OpenBox/mobile/MobileBoxOpen';
import { MobileHome } from 'pages/overview/MobileHome';
import { MobileSearch } from 'pages/search/MobileSearch';
import { MerchProductView } from 'pages/Shop/MerchProductView';
import { MerchShopGallery } from 'pages/Shop/MerchShopGallery';
import { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { mobileAccountRoutes } from 'routes/AccountRoutes';
import { mobileAuthRoutes } from 'routes/AuthRoutes';
import { mobileCheckoutRoutes } from 'routes/CheckoutRoutes';
import { policyRoutes } from 'routes/PolicyRoutes';
import { successRoutes } from 'routes/SuccessRoutes';

import MobileMainLayout from './layouts/MobileMainLayout';

// eslint-disable-next-line max-lines-per-function
export function MobileApp() {
	const location = useLocation();
	const previousURL = location.state?.prevUrl || null; // for specific behavior if prevUrl was a specific one
	const background = location.state
		? location.state.background
		: location.pathname.includes('boxes/open')
			? '/' // fallback value for box open bg
			: undefined;

	const { user, isLoading: isUserLoading } = useUserContext();

	useEffect(() => {
		if (
			!location.pathname.includes('boxes/open/') &&
			!previousURL?.includes('boxes/open/') &&
			!previousURL?.includes('transaction')
		) {
			window.scroll({ top: 0 });
		}
	}, [location, previousURL]);

	return (
		<>
			{isUserLoading && (
				<div className="flex items-center justify-center w-screen h-dvh">
					<LoadSpinner />
				</div>
			)}
			<MobileChooseCurrencyPopup />
			<MobilePopupHandler />
			{!isUserLoading && (
				<>
					<Routes location={background || location}>
						<Route path="/" element={<MobileMainLayout />}>
							<Route
								index
								element={
									<WithPageTitle title="Home | MrLoot">
										<MobileHome />
									</WithPageTitle>
								}
							/>
							{/* Account Routes */}
							{mobileAccountRoutes.map((route, index) => (
								<Route
									key={index}
									path={route.path}
									element={user ? route.element : <Navigate to="/auth/login" replace />}
								/>
							))}
							{/* Policy Routes */}
							{policyRoutes.map((route, index) => (
								<Route key={index} path={route.path} element={route.element} />
							))}

							{/* Success Routes */}
							{successRoutes.map((route, index) => (
								<Route key={index} path={route.path} element={route.element} />
							))}
							<Route
								path="community"
								element={
									<WithPageTitle title="Community | MrLoot">
										<Community />
									</WithPageTitle>
								}
							/>
							<Route
								path="boxes"
								element={
									<WithPageTitle title="Home | MrLoot">
										<MobileHome />
									</WithPageTitle>
								}
							/>
							<Route
								path="inventory"
								element={
									<WithPageTitle title="Inventory - Manage Your Loot | MrLoot">
										<MobileInventory />
									</WithPageTitle>
								}
							/>
							<Route
								path="discover"
								element={
									<WithPageTitle title="Boxes - Explore our WinBoxes | MrLoot">
										<MobileSearch />
									</WithPageTitle>
								}
							/>
							<Route
								path="shop"
								element={
									<WithPageTitle title="Merch Store | MrLoot">
										<MerchShopGallery />
									</WithPageTitle>
								}
							/>
							<Route
								path="shop/:name"
								element={
									<WithPageTitle title="Merch Store | MrLoot">
										<MerchProductView />
									</WithPageTitle>
								}
							/>
							<Route
								path="*"
								element={
									<WithPageTitle title="404 | Page not found">
										<NotFound404 />
									</WithPageTitle>
								}
							/>
						</Route>

						{/* Auth routes */}
						{!user && (
							<Route path="auth" element={<MobileAuthLayout />}>
								{mobileAuthRoutes.map((route, index) => (
									<Route key={index} path={route.path} element={route.element} />
								))}
							</Route>
						)}

						{/* Checkout routes mobile*/}
						{user && (
							<>
								<Route path="checkout" element={<MobileCheckoutLayout />}>
									{mobileCheckoutRoutes.map((route, index) => (
										<Route key={index} index={route.index} path={route.path} element={route.element} />
									))}
								</Route>
							</>
						)}
					</Routes>
					<Routes>
						<Route
							path="boxes/open/:name"
							element={
								<WithPageTitle title="Open Box | MrLoot">
									<MobileBoxOpen />
								</WithPageTitle>
							}
						/>
						<Route path="*" element={<></>} />
					</Routes>
				</>
			)}
		</>
	);
}
