import { CheckBox } from 'components';
import { AmountDisplayWithCurrency } from 'components/common/AmountDisplayWithCurrency';
import { ToolTip } from 'components/common/ToolTip';
import { OrderDbItemInterface } from 'interfaces/OrderInterfaces';
import { isOrderItemRefunded, refundLabel } from 'pages/MyOrders/orderUtils';
import { ReturnCreationForm } from 'pages/MyOrders/ui-order.interface';
import { Controller, useFormContext } from 'react-hook-form';

import { CLOUDFRONT_IMG_URL, rarities } from '../../../../constants';
import { ProductReturnOptions } from './ProductReturnOptions';

interface Props {
	orderItems: OrderDbItemInterface[];
	onItemSelectionChange: (index: number) => void;
}

export function ProductReturnSelectionList({ orderItems, onItemSelectionChange }: Props) {
	const { control, formState } = useFormContext<ReturnCreationForm>(); // Access the form context
	const { errors } = formState;

	return (
		<div className="w-full flex flex-col gap-y-[15px]">
			{orderItems.map((item, index) => (
				<div
					key={index}
					className={`flex flex-col gap-y-[10px] items-center justify-center px-[4px] py-[6px] rounded-[20px] min-h-[120px] w-full ${
						isOrderItemRefunded(item.status) && 'border-opacity-50'
					}`}
				>
					{isOrderItemRefunded(item.status) && (
						<div className="flex items-center gap-x-[6px] mr-auto text-white">
							<ToolTip message="Item has been cancelled for this order" />
							<h1 className="text-white font-semibold text-[14px]">{refundLabel(item.status)}</h1>
						</div>
					)}

					<div
						className={`${
							isOrderItemRefunded(item.status) && 'opacity-50'
						} grid grid-cols-[30px_75px_150px_auto] md:grid-cols-[40px_90px_250px_auto] items-center justify-start w-full gap-x-[10px] md:gap-x-[25px] lg:gap-x-[25px] text-white text-[13px]`}
					>
						<Controller
							name={`items.${index}.checked`}
							control={control}
							render={({ field: { onChange, value } }) => (
								<CheckBox
									checked={value}
									onClick={() => {
										onItemSelectionChange(index);
										onChange(!value);
									}}
									borderColor="white"
									error={!!errors?.items?.[index]?.checked}
								/>
							)}
						/>

						{/* Product Image */}
						<div className="relative w-fit h-fit mx-auto">
							<img
								src={`${CLOUDFRONT_IMG_URL}/${item.itemId?.imageUrls.qualityMedium}`}
								alt={item.itemId?.name}
								className="h-[40px] md:h-[55px] w-auto object-scale-down max-w-[60px] md:max-w-[75px] relative z-10"
							/>
							<div
								className="absolute z-0 left-[50%] translate-x-[-50%] top-[50%] translate-y-[-50%] h-[50px] w-[50px] blur-[25px] rounded-full"
								style={{ backgroundColor: rarities[item.itemId?.rarity]?.color }}
							/>
						</div>

						{/* Product Information */}
						<div className="flex flex-col items-start gap-y-[5px]">
							<div className="flex items-center gap-x-[4px] text-white min-w-fit">
								<AmountDisplayWithCurrency
									amount={(item.userAvgPurchaseValue ?? item.itemId.price) * item.quantity}
									imgStyle={{ height: '13px' }}
									amountStyle={{ fontSize: '13px' }}
								/>
							</div>

							<h2 className="text-white font-semibold line-clamp-1">{item.itemId?.name}</h2>
							<span className="flex items-center justify-center font-semibold h-[18px] w-[27px] rounded-full bg-white text-mrLootBlue text-[12px]">
								x{item.quantity}
							</span>
						</div>
						<ProductReturnOptions index={index} item={item} />
					</div>
				</div>
			))}
		</div>
	);
}
