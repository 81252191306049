import { MagicWandIcon } from 'assets/images';
import { Skeleton } from 'components';
import { SectionHeader } from 'components/common/SectionHeader';
import { useFindSimiliarBoxes, useWindowDimensions } from 'hooks';
import { useBoxOpeningStoreMobile } from 'pages/OpenBox/store/useBoxOpeningStoreMobile';
import { MobileBoxCard } from 'pages/overview/mobile/components/MobileBoxCard';
import { v4 as uuidv4 } from 'uuid';

import { screens } from '../../../../constants';

interface Props {
	boxName?: string;
	pageSize?: number;
	onSeeAll: () => void;
	showSkeleton?: boolean;
}

export function MobileRecommendedBoxes({ onSeeAll, boxName, pageSize = 14, showSkeleton }: Props) {
	const { boxes, isLoading } = useFindSimiliarBoxes({
		boxName: boxName,
		pageSize: pageSize,
		maxPage: 2,
	});

	const { addBoxToPopupStack } = useBoxOpeningStoreMobile();

	function handleOnBoxClick(boxName: string) {
		const id = uuidv4();
		addBoxToPopupStack({ id, boxName: boxName, isSimiliarBoxesPopupVisible: false, prizeInspectPopup: null });
	}

	const { screenWidth } = useWindowDimensions();

	const cardHeight = screenWidth > screens.sm.width ? '290px' : '225px';
	const skeltonHeight = screenWidth > screens.sm.width ? '286px' : '221px'; // minus 4px for border width

	return (
		<div className="flex flex-col h-[290px] sm:h-[360px]">
			<SectionHeader iconSrc={MagicWandIcon} title="Other WinBoxes" linkTo="" onLinkClick={onSeeAll} />
			<div className="w-[100vw] h-[260px] sm:h-[325px] no-scrollbar flex items-center space-x-[14px] overflow-x-auto relative -left-mobile-side-padding px-mobile-side-padding py-[12px]">
				{!showSkeleton &&
					boxes?.map((box) => (
						<MobileBoxCard
							style={{ height: cardHeight }}
							box={box}
							key={box._id}
							onClick={() => handleOnBoxClick(box.name)}
						/>
					))}
				{(showSkeleton || isLoading) &&
					Array.from({ length: 10 }).map((_, index) => (
						<Skeleton
							key={index}
							style={{ height: skeltonHeight, aspectRatio: '0.65 / 1', width: 'auto', borderRadius: '18px' }}
						/>
					))}
			</div>
		</div>
	);
}
