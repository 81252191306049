import { useAutoAnimate } from '@formkit/auto-animate/react';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode, useState } from 'react';

interface Props {
	isInitiallyExpanded?: boolean;
	title: string;
	children?: ReactNode;
}

export function Accordion({ title, children, isInitiallyExpanded = false }: Props) {
	const [isExpanded, setIsExpanded] = useState(isInitiallyExpanded);

	const [parent] = useAutoAnimate();

	return (
		<div ref={parent} className="flex flex-col py-[10px] gap-y-[12px] px-[14px] border-b-[3px] border-[#D7DEF0]">
			<button
				onClick={() => setIsExpanded((prev) => !prev)}
				className="flex items-center justify-between gap-x-[15px] w-full"
			>
				<h2 className="text-[20px] text-left font-bold text-white">{title}</h2>
				<FontAwesomeIcon className="text-white text-[15px]" icon={isExpanded ? faMinus : faPlus} />
			</button>
			{isExpanded && (
				<div className="w-full text-[rgba(255,255,255,0.85)] font-semibold text-[15px] text-justify">{children}</div>
			)}
		</div>
	);
}
