import { useUserContext } from 'hooks/context';
import { useEffect, useRef } from 'react';

export const useUserSessionTracking = () => {
	const { user } = useUserContext();
	const previousDataRef = useRef<{ userId: string | null; userEmail: string | null }>({
		userId: null,
		userEmail: null,
	});

	useEffect(() => {
		// Generate or retrieve session ID
		let sessionId = sessionStorage.getItem('sessionId');
		if (!sessionId) {
			// eslint-disable-next-line no-magic-numbers
			sessionId = `${Date.now()}-${Math.random().toString(36).slice(2, 11)}`;
			sessionStorage.setItem('sessionId', sessionId);
		}

		// Normalize email
		const normalizedMail = user?.contactMail?.value.trim().toLowerCase() || null;
		const userId = user?._id || null;

		// Only push to the dataLayer if user data has changed
		const previousData = previousDataRef.current;
		if (!userId || previousData.userId !== userId || previousData.userEmail !== normalizedMail) {
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				event: 'userSessionTracking',
				user_id: userId,
				user_mail: normalizedMail,
				sessionId,
			});

			// Update previousDataRef to avoid duplicate pushes
			previousDataRef.current = { userId, userEmail: normalizedMail };
		}
	}, [user]);
};
