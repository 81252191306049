import { OpenFont } from 'assets/images';
import { AmountDisplayWithCurrency } from 'components/common/AmountDisplayWithCurrency';
import { PrimaryBtn } from 'components/common/buttons/PrimaryBtn';
import { WithImgSkeleton } from 'components/common/WithImgSkeleton';
import { DBUserInventoryBoxWithBoxObj } from 'interfaces/InventoryInterface';
import { useNavigate } from 'react-router';
import { getBoxLink, getImageByNumber } from 'utils';

import { CLOUDFRONT_IMG_URL } from '../../../../constants';

interface Props {
	box: DBUserInventoryBoxWithBoxObj;
}

export function InventoryBoxCard({ box }: Props) {
	const navigate = useNavigate();

	function handleBoxClick() {
		const boxLink = getBoxLink(box.boxId.name);
		navigate(boxLink);
	}

	return (
		<div
			className={`relative cursor-pointer flex-shrink-0 border-[rgba(255,255,255,0.1)] hover:border-white hover:outline hover:outline-[2px] hover:outline-white group box-content border-[0.5px] rounded-[8px] aspect-[0.65/1] overflow-hidden w-full`}
			role="button"
			tabIndex={0}
		>
			<div className="absolute z-20 right-0 top-0 flex items-center justify-center w-[40px] h-[28px] bg-mrLootBlue rounded-[0_0_0_8px]">
				<img src={getImageByNumber(box.qty)} alt={box.qty?.toString()} className="h-[22px] object-contain" />
			</div>
			<WithImgSkeleton>
				<img
					src={`${CLOUDFRONT_IMG_URL}/${box.boxId.portraitImageUrls?.qualityMedium}`}
					alt={box.boxId.name}
					className="w-full cursor-pointer object-cover flex-grow rounded-[4px] h-full"
				/>
			</WithImgSkeleton>

			<div
				className={`group-hover:opacity-0 absolute right-0 bottom-0 bg-[#2377F4] rounded-[24px_0_4px_0] pl-4 pr-2 h-[24px] text-white flex items-center transition-opacity`}
			>
				<AmountDisplayWithCurrency amount={box.boxId.price} style={{ fontSize: '13px' }} />
			</div>

			<div className="group-hover:translate-y-0 translate-y-full absolute bottom-0 flex flex-col gap-y-[8px] w-full px-[13px] pb-[8px] transition-transform">
				<PrimaryBtn
					content={<img src={OpenFont} alt="Open" className="h-[15px]" />}
					style={{ width: '100%', height: '45px' }}
					onClick={handleBoxClick}
				/>
			</div>
		</div>
	);
}
