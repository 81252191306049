import axios from 'axios';
import { PopulatedBoxInterface } from 'interfaces/BoxInterface';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { API_DOMAIN, FIVE_MINUTES_IN_MILLISECONDS, QUERY_KEYS } from '../../../constants';

async function fetchRecommendedBoxes(boxname?: string, page = 0, pageSize = 10) {
	if (!boxname || !pageSize) {
		return;
	}

	try {
		const { data } = await axios.post(
			`${API_DOMAIN.mrlootBackend}/api/boxes/find-similiar-boxes`,
			{ identifier: boxname, page, pageSize },
			{ withCredentials: true }
		);
		return data.data;
	} catch (err) {
		console.error(err);
		throw err;
	}
}

function getUniqueBoxes(newBoxes: PopulatedBoxInterface[], prevBoxes: PopulatedBoxInterface[]) {
	const combinedBoxes = [...prevBoxes, ...newBoxes];
	const uniqueBoxesMap = new Map(combinedBoxes.map((box) => [box._id, box]));
	return Array.from(uniqueBoxesMap.values());
}

interface Props {
	boxName?: string;
	pageSize?: number;
	maxPage?: number;
}

export function useFindSimiliarBoxes({ boxName, pageSize = 10, maxPage }: Props) {
	const [page, setPage] = useState(0);
	const [boxes, setBoxes] = useState<PopulatedBoxInterface[]>([]);
	const [isAllItemsLoaded, setIsAllItemsLoaded] = useState(false);
	const [isNextPageLoading, setIsNextPageLoading] = useState(false);

	const { data, error, isLoading } = useQuery<PopulatedBoxInterface[], Error>({
		queryKey: [QUERY_KEYS.boxData, 'similiarRecommendation', boxName, page, pageSize],
		queryFn: () => fetchRecommendedBoxes(boxName, page, pageSize),
		keepPreviousData: true,
		enabled: !!boxName,
		onSuccess: () => setIsNextPageLoading(false),
		staleTime: FIVE_MINUTES_IN_MILLISECONDS,
		cacheTime: FIVE_MINUTES_IN_MILLISECONDS,
	});

	useEffect(() => {
		if (data) {
			setBoxes((prevBoxes) => (page === 0 ? data : getUniqueBoxes(data, prevBoxes)));

			if (maxPage === page || data.length < pageSize) {
				setIsAllItemsLoaded(true);
			} else {
				setIsAllItemsLoaded(false);
			}
		}
	}, [data, maxPage, page, pageSize]);

	const loadMoreBoxes = () => {
		if (maxPage && maxPage <= page) {
			setIsAllItemsLoaded(true);
			return;
		}

		setIsNextPageLoading(true);

		setPage((currentPage) => currentPage + 1);
	};

	useEffect(() => {
		return () => {
			setPage(0);
			setBoxes([]);
			setIsAllItemsLoaded(false);
			setIsNextPageLoading(false);
		};
	}, [boxName]);

	return { boxes, error, isLoading, loadMoreBoxes, setPage, isAllItemsLoaded, isNextPageLoading };
}
