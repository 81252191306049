import { useUserContext } from 'hooks';
import { useEffect, useMemo, useRef } from 'react';
import { useLocation } from 'react-router';

import { GroupedOrderItemInterface } from '../InterfacesCheckout';
import { useCheckoutStore } from '../store/CheckoutStore';
import { useGroupedCheckoutItems } from './useGroupAndPopulateInventoryItems';

export function useOnPageLoadInitOrderItems() {
	const { initCheckoutStore: initOrderItems, orderItems } = useCheckoutStore();
	const { user } = useUserContext();
	const hasInit = useRef(false);

	const location = useLocation();

	const inventoryItemIds: string[] | undefined = useMemo(
		() => location.state && location.state.inventoryItemIds,
		[location.state]
	);

	const directPurchaseItems: GroupedOrderItemInterface[] | undefined = useMemo(
		() => location.state && location.state.directPurchaseItems,
		[location.state]
	);

	const { data: groupedCheckoutItems } = useGroupedCheckoutItems({
		ids: inventoryItemIds,
	});

	useEffect(() => {
		if (!hasInit.current && user && (groupedCheckoutItems || directPurchaseItems)) {
			hasInit.current = true;
			const initItems = groupedCheckoutItems ?? directPurchaseItems ?? [];
			initOrderItems(initItems, user?.addresses);
		}
	}, [directPurchaseItems, groupedCheckoutItems, initOrderItems, user, user?.addresses]);

	const numOfProducts = inventoryItemIds?.length || directPurchaseItems?.length || (orderItems?.length ?? 0);

	return { numOfProducts };
}
