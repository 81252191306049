import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ToastErrorNotification } from 'components/toasts/ToastErrorNotification';
import AuthFormWrapper from 'layouts/desktop-components/AuthFormWrapper';
import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

import { COLORS } from '../constants';

export default function DesktopAuthLayout() {
	const location = useLocation();

	const params = new URLSearchParams(location.search);
	const errorCode = params.get('error_message');

	useEffect(() => {
		if (errorCode && errorCode.length > 0) {
			toast(<ToastErrorNotification message={errorCode} />, {
				style: { backgroundColor: COLORS.blue90 },
				autoClose: 5000,
			});
		}
	}, [errorCode]);

	return (
		<>
			<ToastContainer
				autoClose={5000}
				stacked={true}
				hideProgressBar={true}
				draggableDirection="y"
				theme="colored"
				position="top-left"
				toastStyle={{ backgroundColor: COLORS.blue90 }}
				closeButton={(props) => (
					<button onClick={props.closeToast} className="absolute right-2 top-2 text-whie text-[14px] text-white">
						<FontAwesomeIcon icon={faXmark} />
					</button>
				)}
			/>
			<AuthFormWrapper>
				<Outlet />
			</AuthFormWrapper>
		</>
	);
}
