import React, { CSSProperties } from 'react';

import style from './loadSpinner.module.css';

interface LoadSpinnerProps {
	size?: string;
	propStyle?: CSSProperties;
}

export function LoadSpinner({ size = '48px', propStyle }: LoadSpinnerProps) {
	return <span className={style.loader} style={{ '--loader-size': size, ...propStyle } as React.CSSProperties}></span>;
}
