import { IconLeftArrow } from 'assets/icons';
import { LogoBlue } from 'assets/images';

interface Props {
	onButtonClick: () => void;
}

export function WonScreenHeader({ onButtonClick }: Props) {
	return (
		<div className="flex items-center space-x-[8px]">
			<button
				onClick={onButtonClick}
				className="w-[32px] h-[32px] rounded-[12px] bg-[rgba(0,0,0,0.35)] flex items-center justify-center text-white"
			>
				<IconLeftArrow height={22} width={22} />
			</button>
			<img src={LogoBlue} className="h-[43px]" alt="" />
		</div>
	);
}
