import { IconWalletMoney } from 'assets/icons/IconWalletMoney';
import { AmountDisplayWithCurrency } from 'components/common/AmountDisplayWithCurrency';
import { useUserContext } from 'hooks';
import { useLocation } from 'react-router-dom';
import { usePopupStore } from 'store/usePopupStore';
import { shallow } from 'zustand/shallow';

interface Props {
	clickable?: boolean;
}

export function MobileWallet({ clickable = true }: Props) {
	const { user } = useUserContext();

	const setIsDepositPopupVisible = usePopupStore((state) => state.setIsDepositPopupVisible, shallow);

	const location = useLocation();

	function handleWalletClick() {
		if (!clickable) {
			return;
		}

		setIsDepositPopupVisible(true);
	}

	return (
		<button
			onClick={handleWalletClick}
			className="flex items-center justify-end rounded-[12px] bg-[rgba(0,0,0,0.25)] h-[42px] gap-x-[10px] pl-[7px] overflow-hidden"
		>
			<AmountDisplayWithCurrency
				amount={user?.balance}
				style={{ margin: 'auto', paddingLeft: '7px' }}
				amountStyle={{ lineHeight: '17px', fontWeight: 'bold', fontSize: '17px' }}
				imgStyle={{ height: '18px' }}
				abbreviationOption="none"
			/>

			<div
				className={`${location.pathname.includes('transaction') ? 'text-white' : 'text-[#999999DE]'} bg-[rgba(0,0,0,0.55)] hover:bg-[rgba(0,0,0,0,35)] focus-within:text-white rounded-[12px] flex items-center justify-center h-full w-[42px]`}
			>
				<IconWalletMoney />
			</div>
		</button>
	);
}
