import { faCartShopping, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CSSProperties, forwardRef } from 'react';

interface Props {
	style?: CSSProperties;
	isDisabled: boolean;
	onClick?: () => void;
}

export const CheckoutButton = forwardRef<HTMLButtonElement, Props>(({ isDisabled, onClick, style }, ref) => (
	<button
		ref={ref}
		disabled={isDisabled}
		onClick={onClick}
		className={`w-[145px] h-[55px] absolute bottom-0 right-[10px]  `}
	>
		<div
			className={`${isDisabled && 'opacity-50'} bg-mrLootBlue flex items-center justify-between h-full w-full px-[15px] font-inter space-x-[10px] rounded-[18px] text-white border-[2px] border-white`}
			style={style}
		>
			<span className="font-bold text-[15px] text-white">Checkout</span>
			<FontAwesomeIcon icon={faCartShopping} className="text-[16px]" />
			<FontAwesomeIcon icon={faChevronRight} className="text-[rgba(255,255,255,0.4)] text-[13px]" />
		</div>
	</button>
));
