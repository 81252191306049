import { LoadSpinner } from 'components/common/loading/LoadSpinner';
import { Breadcrumb } from 'pages/AccountSettings/desktop/components/Breadcrumb';

import { EmptyInventory } from '../components/EmptyInventory';
import { useOrders } from '../hooks/useOrders';
import { ExpandableOrderRow } from './ExpandableOrderRow/ExpandableOrderRow';

export function DesktopMyOrders() {
	const { data: orders, isLoading } = useOrders({ limit: 12 });

	return (
		<div className="w-full flex flex-col gap-y-[20px]">
			<Breadcrumb items={[{ label: 'My Orders' }]} />

			{!isLoading && (
				<div className="w-full flex flex-col gap-y-[12px]">
					{orders.map((order) => (
						<ExpandableOrderRow key={order._id} order={order} />
					))}
				</div>
			)}

			{isLoading && (
				<div className="w-[350px] flex items-center justify-center h-[150px]">
					<LoadSpinner />
				</div>
			)}

			{orders?.length === 0 && !isLoading && <EmptyInventory />}
		</div>
	);
}
