import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MagicWandIcon } from 'assets/images';
import { Skeleton } from 'components';
import { MobileBackground } from 'components/layout/background/MobileBackground';
import { MobileMenuHeader } from 'components/layout/nav/mobile/menu/MobileMenuHeader';
import { gsap } from 'gsap';
import { useSlideUpAndDragToDismiss } from 'hooks';
import { useDvhFallback } from 'hooks/layout/useDvhFallback';
import { PopulatedBoxInterface } from 'interfaces/BoxInterface';
import { useCallback, useEffect, useRef, useState } from 'react';

import { MobileBoxCard } from '../components/MobileBoxCard';

interface Props {
	title: string;
	boxes?: PopulatedBoxInterface[];
	onClose: () => void;
}

const INIT_NUM_DISPLAYED_BOXES = 6;

export function RecommendedBoxPopup({ boxes, title, onClose }: Props) {
	const blackBgRef = useRef<HTMLDivElement>(null);
	const dragRef = useRef<HTMLDivElement>(null);

	const [isSlideUpComplete, setIsSlideUpComplete] = useState(false);

	const handleDissMiss = useCallback(() => {
		onClose();
	}, [onClose]);

	const handleSlideUpComplete = useCallback(() => {
		setIsSlideUpComplete(true);
	}, []);

	useEffect(() => {
		const tl = gsap.timeline();
		tl.set(blackBgRef.current, { opacity: 0 });
		tl.to(blackBgRef.current, { opacity: 1, duration: 0.3 });
	}, []);

	const { playDismissAnimation } = useSlideUpAndDragToDismiss({
		ref: dragRef,
		onDismiss: handleDissMiss,
		onInitAnimationComplete: handleSlideUpComplete,
		animationTime: 0.3,
		dissmissAnimationTime: 0.25,
		disableInitAnimation: isSlideUpComplete, // use case: prevent slideup when user clicks on box and this view is background
	});

	useDvhFallback({
		ref: dragRef,
		properties: [{ property: 'maxHeight', value: 100 }],
	});

	return (
		<div className="z-40 fixed inset-0 h-dvh">
			<div ref={blackBgRef} className="absolute inset-0 bg-[rgba(0,0,0,0.4)]" />
			<div
				ref={dragRef}
				className="mt-[10px] w-full h-full overflow-y-auto max-h-dvh rounded-[16px_16px_0_0] overscroll-none "
			>
				<MobileBackground>
					<div className="w-full h-full px-mobile-side-padding pt-[25px] gap-y-[20px] flex flex-col items-center pb-[110px]">
						<MobileMenuHeader
							icon={<FontAwesomeIcon icon={faXmark} />}
							onIconClick={playDismissAnimation}
							onLogoClick={() => onClose()}
						/>

						<div className="w-full flex items-center space-x-[6px]">
							<img src={MagicWandIcon} alt={`${title} Icon`} className="h-[19px]" />
							<h2 className="font-bold text-white text-[19px]">{title}</h2>
						</div>

						<div className="grid grid-cols-2 xs:grid-cols-3 ss:grid-cols-4 gap-[20px] w-full">
							{isSlideUpComplete &&
								boxes?.map((box) => (
									<MobileBoxCard key={box._id} box={box} style={{ width: '100%', height: 'auto' }} />
								))}
							{!isSlideUpComplete &&
								Array.from({ length: INIT_NUM_DISPLAYED_BOXES }).map((_, index) => (
									<Skeleton
										key={index}
										style={{ width: '100%', height: 'auto', aspectRatio: '0.65 / 1', borderRadius: '18px' }}
									/>
								))}
						</div>
					</div>
				</MobileBackground>
			</div>
		</div>
	);
}
