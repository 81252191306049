import React, { forwardRef } from 'react';

interface VideoProps {
	src: string;
	boxId: string;
	isFullScreen: boolean;
}

const BoxVideoBackground = React.memo(
	forwardRef<HTMLVideoElement, VideoProps>(({ src, boxId, isFullScreen }, ref) => {
		return (
			<video
				key={boxId}
				muted
				preload="auto"
				ref={ref}
				className={`absolute transform-gpu inset-0 w-full h-full object-cover ${!isFullScreen && 'rounded-[24px]'}`}
				aria-label="Video without sound"
			>
				<source src={src} type="video/mp4" />
				Your browser does not support the video tag.
			</video>
		);
	}),
	areEqual
);

function areEqual(prevProps: VideoProps, nextProps: VideoProps) {
	// Prevent rerenders unless src or isFullScreen changes
	return (
		prevProps.src === nextProps.src &&
		prevProps.isFullScreen === nextProps.isFullScreen &&
		prevProps.boxId === nextProps.boxId
	);
}

export default BoxVideoBackground;
