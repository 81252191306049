import { ToastErrorNotification } from 'components/toasts/ToastErrorNotification';
import { ToastSuccessNotification } from 'components/toasts/ToastSuccessNotification';
import { useUserContext } from 'hooks';
import { ShippingAddressInterface } from 'interfaces/OrderInterfaces';
import { useAddUserAddress } from 'pages/checkout/hooks/useAddUserAddress';
import { useCheckoutStore } from 'pages/checkout/store/CheckoutStore';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { COLORS } from '../../../../constants';
import { AddressForm } from './AddressForm';

export function AddAddressForm() {
	const {
		register,
		setValue,
		handleSubmit,
		formState: { errors },
	} = useForm<ShippingAddressInterface>();

	const { load: reLoadUser } = useUserContext();

	const { updateSelectedAddress } = useCheckoutStore();

	const { mutate: addUserAddressToDB, isLoading: isAddRequestLoading } = useAddUserAddress({
		onAdd: (address) => {
			updateSelectedAddress(address);
			toast(<ToastSuccessNotification message="Address was added" />, {
				style: { background: COLORS.blue90 },
			});
			reLoadUser();
			window.history.back();
		},
		onError: (msg) => {
			toast(<ToastErrorNotification message={msg} />);
		},
	});

	const onSubmit = (data: ShippingAddressInterface) => {
		if (data.phonePrefix && data.phoneNumber) {
			data.phoneNumber = `${data.phonePrefix}${data.phoneNumber}`;
		}

		addUserAddressToDB(data);
	};

	return (
		<div className="flex flex-col">
			<h1 className="text-[20px] font-bold text-white">Add Address</h1>
			<form onSubmit={handleSubmit(onSubmit)} className="pt-[30px]">
				<AddressForm errors={errors} register={register} setValue={setValue} isSaveBtnDisabled={isAddRequestLoading} />
			</form>
		</div>
	);
}
