/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import { ShippingAddressInterface } from 'interfaces/OrderInterfaces';
import { getCookie, setCookie } from 'utils';
import { createJSONStorage, persist } from 'zustand/middleware'; // Import createJSONStorage
import { createWithEqualityFn } from 'zustand/traditional';

import {
	FailedItemsMap,
	GroupedOrderItemInterface,
	GroupedUserCheckoutItems,
	SelectedItemOption,
} from '../InterfacesCheckout';
import {
	adjustInventoryIds,
	adjustSelectedOptions,
	getInvalidOptions,
	hasFailedItems,
	initializeSelectedOptions,
} from '../util';

interface CheckoutStoreState {
	orderItems: GroupedOrderItemInterface[] | undefined;
	isExpressShipping: boolean | undefined;
	selectedShippingAddress: ShippingAddressInterface | undefined;
	itemOptionErrors: FailedItemsMap;

	setIsExpressShipping: (isExpress: boolean | undefined) => void;
	deleteOrderItem: (id: string) => void;
	initCheckoutStore: (items?: GroupedUserCheckoutItems[], userAddresses?: ShippingAddressInterface[]) => void;
	updateProductNote: (itemId: string, note: string) => void;

	updateProductQuantity: (itemId: string, newQty: number) => void;
	updateSelectedProductOptions: (itemId: string, updatedItemOption: SelectedItemOption, optionIndex: number) => void;
	updateSessionStorage: () => void;

	resetCheckoutStore: () => void;

	updateSelectedAddress: (newAddress: ShippingAddressInterface) => void;
	setItemOptionErrors: (error: FailedItemsMap) => void;
}

export const useCheckoutStore = createWithEqualityFn<CheckoutStoreState>()(
	persist(
		(set, get) => ({
			orderItems: undefined,
			isExpressShipping: undefined,
			selectedShippingAddress: undefined,

			itemOptionErrors: {},
			setItemOptionErrors: (errors) => set({ itemOptionErrors: errors }),

			setIsExpressShipping: (isExpress: boolean | undefined) => set({ isExpressShipping: isExpress }),

			updateProductNote: (itemId: string, note: string) => {
				const currentOrderItems = get().orderItems;
				if (!currentOrderItems) {
					return;
				}

				const updatedItems = currentOrderItems.map((orderItem) =>
					itemId === orderItem.item._id ? { ...orderItem, note: note } : orderItem
				);
				set({ orderItems: updatedItems });
			},

			deleteOrderItem: (id: string) => {
				const currentOrderItems = get().orderItems;
				if (currentOrderItems) {
					const updatedOrderItems = currentOrderItems.filter((item) => item.item._id !== id);
					set({ orderItems: updatedOrderItems });
				}
			},

			updateProductQuantity: (itemId: string, newQty: number) => {
				const currentOrderItems = get().orderItems;
				if (!currentOrderItems || newQty < 1) {
					return;
				}

				const updatedItems = currentOrderItems.map((orderItem) => {
					if (orderItem.item._id !== itemId) {
						return orderItem;
					}

					const updatedOptions = adjustSelectedOptions(orderItem, newQty);
					const updatedInventoryIds = adjustInventoryIds(orderItem, newQty);

					return {
						...orderItem,
						quantity: newQty,
						selectedOptions: updatedOptions,
						selectedInventoryItemIds: updatedInventoryIds,
					};
				});

				set({ orderItems: updatedItems });
			},

			updateSelectedProductOptions: (itemId: string, updatedItemOption: SelectedItemOption, optionIndex: number) => {
				const currentOrderItems = get().orderItems;
				const currentItemOptionErrors = get().itemOptionErrors;
				if (!currentOrderItems) {
					return;
				}

				const updatedOrderItems = currentOrderItems?.map((orderItem) => {
					if (orderItem.item._id.toString() !== itemId.toString()) {
						return orderItem;
					}
					const updatedOptions = [...(orderItem.selectedOptions ?? [])];

					// Get the specific option set that we are updating (based on the optionIndex)
					const selectedOptionSet = { ...updatedOptions[optionIndex] };

					// Find the option in optionSelections using _id and update it
					const updatedOptionSelections = selectedOptionSet.optionSelections.map((option) =>
						option._id === updatedItemOption._id ? updatedItemOption : option
					);

					// Update the specific option set with the new optionSelections
					updatedOptions[optionIndex] = {
						...selectedOptionSet,
						optionSelections: updatedOptionSelections,
					};

					// Return the updated item
					return { ...orderItem, selectedOptions: updatedOptions };
				});
				let failedItems = {};
				if (currentItemOptionErrors && hasFailedItems(currentItemOptionErrors)) {
					failedItems = getInvalidOptions(updatedOrderItems); // Use the utility function
				}

				set({ orderItems: updatedOrderItems, itemOptionErrors: failedItems });
			},

			initCheckoutStore: (fetchedItems, userAddresses) => {
				if (!fetchedItems || fetchedItems.length === 0) {
					return;
				}

				let address;
				const cookieSelectedAddress = getCookie('selectedAddress');
				if (cookieSelectedAddress) {
					address = userAddresses?.find((address) => address._id === cookieSelectedAddress._id);
				} else if (userAddresses && userAddresses.length > 0) {
					address = userAddresses[0];
				}

				const currentOrderItems = get().orderItems;

				const updatedOrderItems = fetchedItems
					.sort(
						(a, b) =>
							a.item.price * a.selectedInventoryItemIds.length - b.item.price * b.selectedInventoryItemIds.length // sort price
					)
					.map((fetchedItem) => {
						const existingOrderItem = currentOrderItems?.find(
							(orderItem) =>
								orderItem.item._id === fetchedItem.item._id &&
								orderItem.selectedInventoryItemIds.length === fetchedItem.selectedInventoryItemIds.length &&
								orderItem.selectedInventoryItemIds.every((id) => fetchedItem.selectedInventoryItemIds.includes(id))
						);

						if (existingOrderItem) {
							// determine selected option
							const selectedOptions =
								fetchedItem.selectedOptions && fetchedItem.selectedOptions?.length > 0
									? fetchedItem.selectedOptions
									: existingOrderItem.selectedOptions && existingOrderItem.selectedOptions?.length > 0
										? existingOrderItem.selectedOptions
										: fetchedItem.item.options
											? initializeSelectedOptions(fetchedItem.quantity ?? 1, fetchedItem.item.options)
											: undefined;

							return {
								...existingOrderItem,
								quantity: fetchedItem.quantity ?? existingOrderItem.quantity,
								selectedOptions,
								item: fetchedItem.item,
								availableInventoryItemIds: fetchedItem.availableInventoryItemIds,
							};
						}

						return {
							item: fetchedItem.item,
							quantity: fetchedItem.quantity ?? fetchedItem.selectedInventoryItemIds.length,
							selectedInventoryItemIds: fetchedItem.selectedInventoryItemIds,
							availableInventoryItemIds: fetchedItem.availableInventoryItemIds,
							selectedOptions: fetchedItem.item.options
								? initializeSelectedOptions(fetchedItem.quantity ?? 1, fetchedItem.item.options)
								: undefined,
							note: '',
						};
					});
				set({ orderItems: updatedOrderItems, selectedShippingAddress: address });
			},

			resetCheckoutStore: () => {
				set({ isExpressShipping: undefined, orderItems: undefined, itemOptionErrors: {} });
				sessionStorage.removeItem('zustand-checkout-store');
			},

			updateSessionStorage: () => {
				const currentState = get();
				sessionStorage.setItem('zustand-checkout-store', JSON.stringify(currentState));
			},

			updateSelectedAddress: (newAddress) => {
				set({ selectedShippingAddress: newAddress });
				setCookie('selectedAddress', newAddress, { path: '/' });
			},
		}),
		{
			name: 'checkout-store',
			storage: createJSONStorage(() => sessionStorage), // Wrap sessionStorage with createJSONStorage
			partialize: (state) => ({
				orderItems: state.orderItems,
				isExpressShipping: state.isExpressShipping,
				selectedShippingAddress: state.selectedShippingAddress,
			}),
		}
	),
	Object.is
);
