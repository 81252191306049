import { Line } from 'components/common/Line';

import { TermsOfServicePdf } from './assets';

export function TermsOfService() {
	return (
		<div className="flex flex-col gap-y-[10px] text-[15px]">
			<h1 className="text-white font-bold text-[22px]">EU Terms of Service for MrLoot</h1>
			<span className="text-[rgba(255,255,255,0.7)] text-[15px] font-semibold">Last updated in November 2024</span>
			<Line style={{ margin: '10px 0' }} />
			<p className="text-[rgba(255,255,255,0.7)] text-[15px] text-justify">
				Welcome to MrLoot. These terms of service outline the rules and regulations for the use of our website and
				services. Please read these terms carefully before using our service. By accessing this website, we assume you
				accept these terms in full. If you do not agree with the terms, please do not use MrLoot.
			</p>
			<p className="text-[rgba(255,255,255,0.7)] text-[15px] text-justify">
				For more detailed information, please refer to the complete Terms of Service document available for download
				below.
			</p>
			<a href={TermsOfServicePdf} target="_blank" className="text-mrLootBlue font-semibold underline" rel="noreferrer">
				View Terms of Service
			</a>
		</div>
	);
}
