import { gsap } from 'gsap';
import { useServerSeedReveal } from 'hooks/data/user/useServerSeedReveal';
import { useEffect, useRef } from 'react';

interface Props {
	hashedServerSeed: string;
}

export function ServerSeedRevealBtn({ hashedServerSeed }: Props) {
	const {
		mutate: revealServerSeed,
		data: serverSeed,
		isLoading: isSeedRevealLoading,
	} = useServerSeedReveal({
		onSuccess: () => {
			copyTextToClipboard();
			animateCopyOverlay();
		},
	});

	const isSeedRevealed = !!serverSeed && serverSeed?.length > 0;

	const copiedOverlayRef = useRef<HTMLDivElement>(null);
	const timelineRef = useRef<gsap.core.Timeline>();

	useEffect(() => {
		// Cleanup function to be called when the component unmounts
		return () => {
			if (timelineRef.current) {
				timelineRef.current.kill();
			}
		};
	}, []);

	function handleBtnClick() {
		if (isSeedRevealed) {
			copyTextToClipboard();
			animateCopyOverlay();
		} else {
			revealServerSeed({ hashedSeed: hashedServerSeed });
		}
	}

	function animateCopyOverlay() {
		if (timelineRef.current) {
			timelineRef.current.kill(); // Kill the existing timeline if it exists
		}

		const tl = gsap.timeline();
		timelineRef.current = tl; // Save the new timeline in the ref

		tl.set(copiedOverlayRef.current, { display: 'block', opacity: 0 });
		tl.to(copiedOverlayRef.current, { opacity: 1, duration: 0.2 });
		tl.to(copiedOverlayRef.current, { opacity: 0, duration: 0.5, delay: 2 });
		tl.set(copiedOverlayRef.current, { display: 'none' });
	}

	function copyTextToClipboard() {
		animateCopyOverlay();
		if (!navigator.clipboard || !serverSeed) {
			console.error('Clipboard API not supported or text to copy is empty');
			return;
		}

		navigator.clipboard.writeText(serverSeed);
	}

	return (
		<>
			<div className="relative w-full h-fit">
				<div
					ref={copiedOverlayRef}
					className="hidden absolute left-[50%] translate-x-[-50%] top-[-3px] translate-y-[-100%] rounded-[4px] bg-white text-black text-[11px] text-center px-[5px] py-[3px]"
				>
					<span className="font-semibold">Copied!</span>
					<div className="absolute left-[50%] bottom-0 translate-x-[-50%] translate-y-[40%] bg-white rotate-45 h-[7px] w-[7px]" />
				</div>
				<button
					disabled={isSeedRevealLoading}
					onClick={handleBtnClick}
					className={`relative px-[8px] py-[8px] text-wrap break-words rounded-[4px] w-full min-h-[30px] font-semibold text-[12px] border-[1px] shadow-btnSm ${isSeedRevealed ? 'border-mrLootBlue border-dashed text-mrLootBlue' : 'bg-mrLootBlue border-transparent text-white'} `}
				>
					{isSeedRevealed ? serverSeed : 'Reveal Seed'}
				</button>
			</div>
		</>
	);
}
