import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CSSProperties } from 'react';

interface Props {
	onClose: () => void;
	style?: CSSProperties;
}

export function MobileCloseBtn({ onClose, style }: Props) {
	return (
		<button
			className="absolute rounded-[12px] sm:rounded-[20px] right-4 top-3 text-[13px] sm:text-[23px] bg-[rgba(255,255,255,0.1)] text-[#9D9D9D] h-[32px] sm:h-[50px] w-[32px] sm:w-[50px] flex items-center justify-center"
			onClick={onClose}
			style={style}
		>
			<FontAwesomeIcon icon={faXmark} />
		</button>
	);
}
