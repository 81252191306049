import { ToastErrorNotification } from 'components/toasts/ToastErrorNotification';
import { ToastSuccessNotification } from 'components/toasts/ToastSuccessNotification';
import { useUserContext } from 'hooks';
import { ShippingAddressInterface } from 'interfaces/OrderInterfaces';
import { useInitializeAddressForm } from 'pages/checkout/hooks/useInitializeAddressForm';
import { useUpdateUserAddress } from 'pages/checkout/hooks/useUpdateUserAddress';
import { useCheckoutStore } from 'pages/checkout/store/CheckoutStore';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { COLORS } from '../../../../constants';
import { AddressForm } from './AddressForm';

export function UpdateAddressForm() {
	const {
		register,
		setValue,
		handleSubmit,
		formState: { errors },
	} = useForm<ShippingAddressInterface>();

	const { addressId } = useInitializeAddressForm(setValue);

	const { updateSelectedAddress } = useCheckoutStore();

	const { load: reLoadUser } = useUserContext();

	const { mutate: updateUserAddressToDB, isLoading: isAddRequestLoading } = useUpdateUserAddress({
		onUpdate: (address) => {
			updateSelectedAddress(address);
			toast(<ToastSuccessNotification message="'Address was updated" />, {
				style: { background: COLORS.blue90 },
			});

			reLoadUser();
			window.history.back();
		},
		onError: (msg) => {
			toast(<ToastErrorNotification message={msg} />);
		},
	});

	const onSubmit = (data: ShippingAddressInterface) => {
		if (!addressId) {
			return;
		}

		if (data.phonePrefix && data.phoneNumber) {
			data.phoneNumber = `${data.phonePrefix}${data.phoneNumber}`;
		}

		updateUserAddressToDB({ addressId: addressId, address: data });
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)} className="pt-[30px]">
			<AddressForm errors={errors} register={register} setValue={setValue} isSaveBtnDisabled={isAddRequestLoading} />
		</form>
	);
}
