import { RefObject, useEffect, useState } from 'react';
import { smoothScrollLeft } from 'utils';

const SLIDE_DURATION = 700;

interface Props {
	slidesPerGroup: number;
	carouselSwiperRef: RefObject<HTMLDivElement>;
}

export function useCarouselBtns({ carouselSwiperRef, slidesPerGroup }: Props) {
	const [isAnimating, setIsAnimating] = useState(false);
	const [showPrevBtn, setShowPrevBtn] = useState(false);
	const [showNextBtn, setShowNextBtn] = useState(true);

	useEffect(() => {
		const currentSwiperRef = carouselSwiperRef.current;

		const checkScroll = () => {
			if (currentSwiperRef) {
				const maxScrollLeft = currentSwiperRef.scrollWidth - currentSwiperRef.clientWidth;
				const currentScrollLeft = currentSwiperRef.scrollLeft;

				const thresholdSpaceLeft = 5;
				setShowPrevBtn(currentScrollLeft > thresholdSpaceLeft);
				setShowNextBtn(currentScrollLeft < maxScrollLeft - 1);
			}
		};

		if (currentSwiperRef) {
			checkScroll();
			currentSwiperRef.addEventListener('scroll', checkScroll);
		}

		return () => {
			if (currentSwiperRef) {
				currentSwiperRef.removeEventListener('scroll', checkScroll);
			}
		};
	}, [carouselSwiperRef]);

	async function handleBtnNext() {
		if (carouselSwiperRef.current && carouselSwiperRef.current.children.length > 0) {
			const slide = carouselSwiperRef.current.children[0];
			const clientRect = slide.getBoundingClientRect();
			const currentScrollLeft = carouselSwiperRef.current.scrollLeft;
			const target = currentScrollLeft + Math.round(slidesPerGroup) * clientRect.width;
			setIsAnimating(true);
			await smoothScrollLeft(carouselSwiperRef.current, target, SLIDE_DURATION);
			setIsAnimating(false);
		}
	}

	async function handleBtnPrev() {
		if (carouselSwiperRef.current && carouselSwiperRef.current.children.length > 0) {
			const slide = carouselSwiperRef.current.children[0];
			const clientRect = slide.getBoundingClientRect();
			const currentScrollLeft = carouselSwiperRef.current.scrollLeft;
			const target = Math.max(0, currentScrollLeft - Math.round(slidesPerGroup) * clientRect.width);
			setIsAnimating(true);
			await smoothScrollLeft(carouselSwiperRef.current, target, SLIDE_DURATION);
			setIsAnimating(false);
		}
	}

	return {
		handleBtnPrev,
		handleBtnNext,
		showNextBtn,
		showPrevBtn,
		isAnimating,
	};
}
