import { BigBox, Cloud, FlyingCoin, SmallBox } from 'assets/images';
import { useEffect, useState } from 'react';

const images = [
	{
		label: 'Big Box',
		imgSource: BigBox,
	},
	{
		label: 'Small Box',
		imgSource: SmallBox,
	},
	{
		label: 'Flying Coin',
		imgSource: FlyingCoin,
	},
	{
		label: 'Cloud',
		imgSource: Cloud,
	},
];

export function SupportFriend() {
	const [selectedImage, setSelectedImage] = useState<{ label: string; imgSource: string }>();

	useEffect(() => {
		const randomImage = images[Math.floor(Math.random() * images.length)];
		setSelectedImage(randomImage);
	}, []);

	return (
		<div className="fixed left-[45px] bottom-[58px] rounded-full h-[50px] w-[50px] overflow-hidden flex items-center justify-center border-[1.5px] border-white bg-[rgba(255,255,255,0.6)]">
			<img
				alt={`Lucky Charm ${selectedImage?.label}`}
				src={selectedImage?.imgSource}
				className="h-[20px] max-w-[90%] object-scale-down"
			/>
		</div>
	);
}
