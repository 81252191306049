import { useRef } from 'react';

import useScrollOverlay from './useScrollOverlay';

interface Props {
	topOff?: number;
	max_alpha?: number;
	style?: React.CSSProperties;
}

export function DarkScrollOverlay({ max_alpha, topOff, style }: Props) {
	const overlayRef = useRef<HTMLDivElement>(null);
	const overlayBackgroundColor = useScrollOverlay({
		ref: overlayRef,
		topOff: topOff,
		max_alpha,
	});

	return (
		<div
			ref={overlayRef}
			className={`pointer-events-none rounded-[5px] absolute z-30 left-[0px] top-[50%] translate-y-[-50%] h-full w-full`}
			style={{ backgroundColor: overlayBackgroundColor, ...style }}
		/>
	);
}
