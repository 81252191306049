import { Cloud, FlyingCoin, MrLoot } from 'assets/images';
import { useWindowDimensions } from 'hooks/utility/layout-measurements/useWindowDimensions';

import { screens } from '../../constants';

export function AuthBgImages() {
	const { screenWidth, screenHeight } = useWindowDimensions();

	function FlyCoinImage() {
		if (screenHeight >= screens.lg.height) {
			if (screenWidth >= screens.lgx.width) {
				return <img alt="" src={FlyingCoin} className="absolute h-[19vh] left-[80vw] top-[5vh] object-scale-down" />;
			}
			return <></>;
		}
		return <img alt="" src={FlyingCoin} className="absolute h-[19vh] left-[80vw] top-[5vh] object-scale-down" />;
	}

	function MrLootImage() {
		if (screenHeight >= screens.lg.height) {
			if (screenWidth <= screens.md.width) {
				return (
					<img
						alt=""
						src={MrLoot}
						className="absolute left-[25vw] top-[11vh] h-[77vh] min-h-[450px] object-scale-down"
					/>
				);
			} else if (screenWidth <= screens.lg.width) {
				return (
					<img
						alt=""
						src={MrLoot}
						className="absolute left-[35vw] top-[11vh] h-[77vh] min-h-[450px] object-scale-down"
					/>
				);
			}
			return (
				<img alt="" src={MrLoot} className="absolute left-[48vw] top-[11vh] h-[77vh] min-h-[450px] object-scale-down" />
			);
		}
		if (screenWidth <= screens.md.width) {
			return (
				<img alt="" src={MrLoot} className="absolute left-[35vw] top-[11vh] h-[77vh] min-h-[450px] object-scale-down" />
			);
		} else if (screenWidth <= screens.lg.width) {
			return (
				<img alt="" src={MrLoot} className="absolute left-[37vw] top-[11vh] h-[77vh] min-h-[450px] object-scale-down" />
			);
		}
		return (
			<img alt="" src={MrLoot} className="absolute left-[45vw] top-[11vh] h-[77vh] min-h-[450px] object-scale-down" />
		);
	}

	return (
		<>
			{screenWidth >= screens.md.width && <FlyCoinImage />}
			{screenWidth >= screens.md.width && (
				<img
					alt=""
					src={Cloud}
					className={`absolute h-[39vh] max-h-[320px] w-[22vw] z-20 object-scale-down left-[min(33vw,calc(350px+15vw))] top-0`}
				/>
			)}

			{screenWidth >= screens.sm.width && <MrLootImage />}
		</>
	);
}
