import { debounce } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';

export function useComponentSizes(componentRef: React.RefObject<HTMLDivElement>) {
	const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

	const debouncedSetDimensions = useRef(
		debounce(({ width, height }) => {
			setDimensions({ width, height });
		}, 100)
	);

	useEffect(() => {
		const setDebouncedDimensions = debouncedSetDimensions.current;
		if (componentRef.current) {
			const observer = new ResizeObserver((entries) => {
				for (const entry of entries) {
					const { width, height } = entry.contentRect;
					setDebouncedDimensions({ width, height });
				}
			});

			observer.observe(componentRef.current);

			return () => {
				observer.disconnect();
				setDebouncedDimensions.cancel();
			};
		}
	}, [componentRef, debouncedSetDimensions]);

	return dimensions;
}
