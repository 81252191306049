import { Ellipse } from 'assets/images';

interface LabelBadgeProps {
	labelImg: string;
	labelStyle?: React.CSSProperties;
}

export function LabelBadge({ labelImg, labelStyle }: LabelBadgeProps) {
	return (
		<div
			className="absolute z-10 right-[-10px] w-[90px] mds:w-[105px] flex items-center justify-center top-0 py-[5px] rounded-[20px] -translate-y-1/2 font-bold text-white"
			style={{
				boxShadow: '0px 0px 0px 2px #FFFFFF, 0px 4px 5px rgba(0, 0, 0, 0.3)',
				...labelStyle,
			}}
		>
			<img src={Ellipse} className="absolute left-[5px] top-[2px] h-[4px] w-auto rotate-[-10deg]" alt="" />
			<img src={labelImg} alt="" className="h-[12px] mds:h-[14px] w-auto object-scale-down" />
		</div>
	);
}
