import { FontSold } from 'assets/images';
import { AmountDisplayWithCurrency } from 'components/common/AmountDisplayWithCurrency';
import { ItemInterface } from 'interfaces/ItemInterfaces';

import { CLOUDFRONT_IMG_URL } from '../../constants';

interface Props {
	item: ItemInterface;
}

export function ToastSoldNotification({ item }: Props) {
	return (
		<div className="text-white w-full h-[62px] flex flex-col items-start justify-between gap-y-[10px]">
			<img src={FontSold} className="h-[20px]" alt="new Loot" />
			<div className="flex items-center w-full gap-x-[17px]">
				<img
					src={`${CLOUDFRONT_IMG_URL}/${item.imageUrls.qualityMedium}`}
					alt={item.name}
					className="h-[35px] object-scale-down max-w-[35%]"
				/>
				<h2 className="text-white line-clamp-1 font-bold text-[15px] max-w-[50%]">{item.name}</h2>
				<div className="text-white ml-auto flex items-center justify-center gap-x-[6px] border-[2px] border-white rounded-[24px] h-[35px] px-[17px]">
					<AmountDisplayWithCurrency
						amount={item.price}
						amountStyle={{ textWrap: 'nowrap', whiteSpace: 'nowrap', fontSize: '14px' }}
						imgStyle={{ height: '16px' }}
						useDivWrapper={false}
					/>
				</div>
			</div>
		</div>
	);
}
