import { CryptoPayCurrency, currencyDisplayNames, currencyImages } from 'pages/TransactionManager/deposit.interface';

// Only primary coins (exclude network-specific variants)
const primaryCurrencies: CryptoPayCurrency[] = [
	CryptoPayCurrency.BTC,
	CryptoPayCurrency.ETH,
	CryptoPayCurrency.USDT,
	CryptoPayCurrency.DOGE,
	CryptoPayCurrency.SOL,
	CryptoPayCurrency.XRP,
	CryptoPayCurrency.BNB,
	CryptoPayCurrency.MATIC,
	CryptoPayCurrency.USDC,
	CryptoPayCurrency.LTC,
	CryptoPayCurrency.TRON,
	CryptoPayCurrency.BITCOIN_CASH,
	CryptoPayCurrency.MONERO,
	CryptoPayCurrency.SHIBA,
];

interface Props {
	onSelectedCurrencyChange: (newCurrency: CryptoPayCurrency) => void;
}

export function CryptoPayOptionsList({ onSelectedCurrencyChange }: Props) {
	// eslint-disable-next-line no-magic-numbers

	return (
		<div className="flex items-center justify-center flex-wrap gap-x-[13px] gap-y-[17px] ms:gap-y-[22px] sm:gap-y-[25px] ms:gap-x-[16px] sm:gap-x-[20px]">
			{primaryCurrencies.map((currency) => (
				<button
					onClick={() => onSelectedCurrencyChange(currency)}
					key={currency}
					className={`min-w-[150px] xxs:min-w-[160px] sm:min-w-[220px] w-fit h-[50px] xxs:h-[55px] sm:h-[65px] bg-secondary-gradient rounded-[20px] border-[2px] border-darkBlue outline outline-[3px] outline-white flex items-center hover:shadow-primaryInset justify-center gap-x-[10px] sm:gap-x-[13px]`}
				>
					<img
						className="h-[20px] xxs:h-[23px] sm:h-[27px] w-auto object-contain"
						src={currencyImages[currency]}
						alt={currency}
					/>
					<span className="text-[17px] xxs:text-[19px] sm:text-[25px] text-white font-semibold leading-[18px]">
						{currencyDisplayNames[currency]}
					</span>
				</button>
			))}
		</div>
	);
}
