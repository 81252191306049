import { AmountDisplayWithCurrency } from 'components/common/AmountDisplayWithCurrency';
import { ItemInterface } from 'interfaces/ItemInterfaces';

import { rarities } from '../../../../constants';

interface Props {
	additionalPurchaseNum: number;
	item: ItemInterface;
}

export function AdditionalPurchaseList({ additionalPurchaseNum, item }: Props) {
	return (
		<div className="flex flex-col space-y-2 mt-[18px]">
			<h2 className="text-white text-[15px] font-semibold">Additional Purchases:</h2>
			<div
				className="ml-[9px] text-[14px] flex items-center font-semibold gap-x-[5px]"
				style={{ color: rarities[item.rarity].color }}
			>
				<span className="mr-[4px]">{additionalPurchaseNum}</span>
				<span className="mr-[4px]">x</span>
				<AmountDisplayWithCurrency amount={item.price} imgStyle={{ height: '15px' }} useDivWrapper={false} />
			</div>
		</div>
	);
}
