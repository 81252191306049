import { useEffect, useState } from 'react';
import { calcDistanceInPercentage } from 'utils';

interface Props {
	ref: React.RefObject<HTMLDivElement>;
	topOff?: number;
	max_alpha?: number;
}

const SCROLL_FADING_FACTOR = 50; // 50% -> when progress is equal/bigger than progress alpha is 1. when smaller fading starts

const useScrollOverlay = ({ ref, topOff = 0, max_alpha = 0.35 }: Props) => {
	const [overlayColor, setOverlayColor] = useState('rgba(0, 0, 0, 0)');

	useEffect(() => {
		const handleScroll = () => {
			if (ref.current === null) {
				return;
			}
			const windowHeight = window.innerHeight;
			const divPosition = ref.current.getBoundingClientRect();
			const relativeDivPosition = {
				bottom: windowHeight - divPosition.bottom,
				top: divPosition.top,
			};

			if (divPosition.top < topOff) {
				const progress = calcDistanceInPercentage(divPosition.top, topOff, -divPosition.height + topOff);
				const alpha = Math.min((progress / SCROLL_FADING_FACTOR) * max_alpha, max_alpha);
				setOverlayColor(`rgba(0, 0, 0, ${alpha})`);
			} else if (relativeDivPosition.bottom < 0) {
				const progress = calcDistanceInPercentage(-relativeDivPosition.bottom, 0, divPosition.height);
				if (progress < 0) {
					return;
				}
				const alpha = Math.min((progress / SCROLL_FADING_FACTOR) * max_alpha, max_alpha);
				setOverlayColor(`rgba(0, 0, 0, ${alpha})`);
			} else {
				setOverlayColor('rgba(0, 0, 0, 0)');
			}
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [ref, max_alpha, topOff]);

	return overlayColor;
};

export default useScrollOverlay;
