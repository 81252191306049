import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FreeFont } from 'assets/images';
import { AmountDisplayWithCurrency } from 'components/common/AmountDisplayWithCurrency';
import { useWindowDimensions } from 'hooks';
import { useInventoryBoxes } from 'pages/inventory/hooks/useInventoryBoxes';
import { useBoxNameFromParams } from 'pages/OpenBox/hooks/useBoxNameFromParams';
import { forwardRef } from 'react';

import { screens } from '../../../../../../constants';

interface Props {
	isDisabled: boolean;
	boxPrice: number;
}

export const OpenBtnPriceTag = forwardRef<HTMLDivElement, Props>(({ boxPrice, isDisabled }, ref) => {
	const { screenWidth } = useWindowDimensions();

	const { data: inventoryBoxes, isLoading: isInventoryBoxesLoading } = useInventoryBoxes();
	const boxName = useBoxNameFromParams();

	//const {} = useDynamicBalanceWidth(ref);

	return (
		<div
			ref={ref}
			style={{
				background: 'linear-gradient(331.41deg, #236DF4 6.78%, #33F0A7 204.87%)',
			}}
			className={`${isDisabled ? 'opacity-50' : ''} text-[12px] border-[1px] border-white text-white lg:text-[14px] px-[18px] xl:px-[23px] w-fit py-[6px] xl:py-[7px] font-semibold justify-center flex items-center absolute left-[50%] -translate-x-1/2 bottom-[-45px] xl:bottom-[-46px] rounded-[20px] gap-x-2`}
		>
			{isInventoryBoxesLoading ? (
				<FontAwesomeIcon icon={faSpinner} className="animate-spin" />
			) : !!inventoryBoxes && inventoryBoxes.some((box) => box.boxId.name === boxName) ? (
				<img src={FreeFont} alt="free" className="h-[14px] w-auto object-contain" />
			) : (
				<AmountDisplayWithCurrency
					amount={boxPrice}
					imgStyle={{ height: screenWidth >= screens.xxl.width ? '19px' : '16px' }}
					useDivWrapper={false}
				/>
			)}
		</div>
	);
});
