import {
	Bitcoin,
	BitcoinFont,
	Coin,
	CryptoDogecoin,
	CryptoSolana,
	DogecoinFont,
	DollarAndLootieGroup,
	DollarSign,
	Etherium,
	EtheriumFont,
	LootieFont,
	SolanaFont,
	USDollarFont,
} from 'assets/images';
import { PrimaryBtn } from 'components/common/buttons/PrimaryBtn';
import { CurrencyOptions, useAppStore } from 'store/useAppStore';
import { usePopupStore } from 'store/usePopupStore';
import { shallow } from 'zustand/shallow';

import { WithPrimaryPopup } from '../../common/popups/WithPrimaryPopup';
import { FontChooseACurrency } from './assets';

const PLAY_CURRENCY_OPTIONS = [
	{
		key: CurrencyOptions.LOOTIES,
		titleImg: LootieFont,
		logo: Coin,
	},
	{
		key: CurrencyOptions.US_DOLLAR,
		titleImg: USDollarFont,
		logo: DollarSign,
	},
	{
		key: CurrencyOptions.BITCOIN,
		titleImg: BitcoinFont,
		logo: Bitcoin,
	},
	{
		key: CurrencyOptions.ETHERIUM,
		titleImg: EtheriumFont,
		logo: Etherium,
	},
	{
		key: CurrencyOptions.SOLANA,
		titleImg: SolanaFont,
		logo: CryptoSolana,
	},
	{
		key: CurrencyOptions.DOGECOIN,
		titleImg: DogecoinFont,
		logo: CryptoDogecoin,
	},
];

export function DesktopChooseCurrencyPopup() {
	const { isCurrencyPopupVisible, setIsCurrencyPopupVisible } = usePopupStore(
		(state) => ({
			isCurrencyPopupVisible: state.isCurrencyPopupVisible,
			setIsCurrencyPopupVisible: state.setIsCurrencyPopupVisible,
		}),
		shallow
	);

	const { setSelectedCurrency, selectedCurrency } = useAppStore(
		(state) => ({
			setSelectedCurrency: state.setSelectedCurrency,
			selectedCurrency: state.selectedCurrency,
		}),
		shallow
	);

	return (
		<WithPrimaryPopup
			childrenWrapperStyle={{ maxHeight: '90vh', width: '70vw', maxWidth: '700px' }}
			onClose={() => setIsCurrencyPopupVisible(false)}
			useAnimation={true}
			isExternallyVisible={isCurrencyPopupVisible}
		>
			<div className="flex items-end w-full justify-center gap-x-[10px] pr-[20px] mb-[30px]">
				<img src={DollarAndLootieGroup} className="h-[54px]" alt="" />
				<img src={FontChooseACurrency} className="h-[65px]" alt="" />
			</div>
			<div className="flex flex-wrap gap-[25px] w-full items-center justify-center">
				{PLAY_CURRENCY_OPTIONS.map((currency) => (
					<PrimaryBtn
						key={currency.key}
						style={{
							width: '150px',
							height: '110px',
							borderWidth: '4px',
						}}
						innerContentStyle={{
							borderWidth: '2px',
							borderColor: currency.key === selectedCurrency ? '#2AD100' : '#3559d7 ',
						}}
						onClick={() => {
							setSelectedCurrency(currency.key);
							setIsCurrencyPopupVisible(false);
						}}
						content={
							<div className="flex flex-col items-center h-full justify-evenly">
								<img src={currency.logo} alt="" className="h-[29px]" />
								<img src={currency.titleImg} alt="" className="h-[25px]" />
							</div>
						}
					/>
				))}
			</div>
		</WithPrimaryPopup>
	);
}
