import { BoxInterface } from './BoxInterface';

export const FEATURE_BANNER_IMG_RESOLUTIONS = ['1.8x1', '2.2x1', '2.4x1', '3x1', '3.3x1', '3.6x1'];

export type ImagePathKeys = (typeof FEATURE_BANNER_IMG_RESOLUTIONS)[number];

type ImgPaths = {
	[key in ImagePathKeys]?: string;
};

export interface FeatureBannerInterface {
	_id: string;
	type: FeatureBannerTypes;
	boxId?: string | BoxInterface;
	redirectUrl?: string;
	imgPaths?: ImgPaths;
	position: number;
	sessionType: FeatureBannersSessionTypes;
}

export interface PopulatedFeatureBannerInterface extends FeatureBannerInterface {
	boxId: BoxInterface;
}

export enum FeatureBannersSessionTypes {
	NO_SESSION = 'no-session',
	USER_SESSION = 'user-session',
}

export enum FeatureBannerTypes {
	BOX = 'box',
	BANNER = 'banner',
}
