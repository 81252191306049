import axios from 'axios';
import { API_DOMAIN } from '../../../constants';
import { useMutation } from 'react-query';
import { usePopupStore } from 'store/usePopupStore';
import { shallow } from 'zustand/shallow';

interface RegisterCredentialsInterface {
	email: string;
	password: string;
	isFreeBoxBonusActive?: boolean;
}

async function handleRegister(loginCredentials: RegisterCredentialsInterface) {
	try {
		const response = await axios.post(
			`${API_DOMAIN.mrlootBackend}/api/user/register`,
			{
				email: loginCredentials.email,
				password: loginCredentials.password,
				isFreeBoxBonusActive: loginCredentials.isFreeBoxBonusActive,
			},
			{ withCredentials: true }
		);
		if (response.data.status !== 'success') {
			throw new Error(response.data.message ? response.data.message : 'Unexpected error: something went wrong');
		}
	} catch (err) {
		console.error(err);
		throw err;
	}
}

interface Props {
	onSuccess?: () => void;
	onError?: (message: string) => void;
}

export function useRegisterRequest({ onSuccess, onError }: Props = {}) {
	const signupBonusExpiredAt = usePopupStore((state) => state.signupBonusExpiredAt, shallow);

	const {
		mutate: callRegister,
		isLoading,
		error,
	} = useMutation(handleRegister, {
		onSuccess: () => {
			onSuccess?.();
		},
		onError: (err: any) => {
			if (onError)
				onError(err.response?.data?.message || err.message || 'Register failed due to internal server error');
		},
	});

	async function handleSignUp(credentials: RegisterCredentialsInterface) {
		const isFreeBoxBonusActive = !!signupBonusExpiredAt && signupBonusExpiredAt.getTime() > new Date().getTime();
		callRegister({ email: credentials.email, password: credentials.password, isFreeBoxBonusActive });
	}

	return { handleSignUp, error, isLoading };
}
