import { useEffect, useState } from 'react';

const setConsentInStorage = (consent: boolean) => {
	localStorage.setItem('userConsent', JSON.stringify(consent));
};

const getConsentFromStorage = (): boolean | null => {
	const consent = localStorage.getItem('userConsent');
	return consent ? JSON.parse(consent) : null;
};

export function useConsentManager() {
	const [consentGiven, setConsentGiven] = useState<boolean | null>(getConsentFromStorage());
	const [showBanner, setShowBanner] = useState(localStorage.getItem('userConsent') === null);

	useEffect(() => {
		if (process.env.REACT_APP_ENV === 'production') {
			if (getConsentFromStorage() === null) {
				setShowBanner(true);
				return;
			}

			window.dataLayer = window.dataLayer || [];

			if (consentGiven) {
				// Push consent granted event

				window.userConsent = true;
				window.ConsentStatus = true;

				window.gtag('consent', 'update', {
					ad_storage: 'granted',
					analytics_storage: 'granted',
					ad_user_data: 'granted',
					ad_personalization: 'granted',
					personalization_storage: 'granted',
					functionality_storage: 'granted',
					security_storage: 'granted',
				});
			} else if (consentGiven === false) {
				// Push consent denied event
				window.userConsent = false;
				window.ConsentStatus = false;
				window.gtag('consent', 'update', {
					ad_storage: 'denied',
					analytics_storage: 'denied',
					ad_user_data: 'denied',
					ad_personalization: 'denied',
					personalization_storage: 'denied',
					functionality_storage: 'denied',
					security_storage: 'denied',
				});
			}
		}
	}, [consentGiven, showBanner]);

	const handleConsent = (consent: boolean) => {
		setConsentInStorage(consent);
		setConsentGiven(consent);
		setShowBanner(false); // Hide the banner after consent is given
	};

	return { handleConsent, consentGiven, showBanner };
}
