import { useWindowDimensions } from 'hooks/utility/layout-measurements/useWindowDimensions';
import { ItemInterface } from 'interfaces/ItemInterfaces';
import { FailedItemsMap, SelectedItemOption, SelectedOptionSet } from 'pages/checkout/InterfacesCheckout';
import Skeleton from 'react-loading-skeleton';

import { screens } from '../../../../constants';
import { AdditionalPurchaseList } from './AdditionalPurchaseList';
import { ItemOptionsSection } from './ItemOptionsSection';
import { NoteField } from './NoteField';
import { ProductTags } from './ProductTags';

interface Props {
	note?: string;
	item?: ItemInterface;
	quantity?: number;
	isExpressShipping?: boolean;
	additionalPurchaseNum?: number;
	selectedOptions?: SelectedOptionSet[];
	onlyDisplay?: boolean;
	optionErrors?: FailedItemsMap;
	onSelectedOptionChange?: (itemId: string, updatedOptionSet: SelectedItemOption, indexOfOptionSet: number) => void;
	onNoteChange?: (note: string) => void;
}

const IMG_SKELETON_WIDTH_LG = 240;
const IMG_SKELETON_WIDTH_DEFAULT = 200;
const TAG_SKELETON_WIDTH_LG = 100;
const TAG_SKELETON_WIDTH_DEFAULT = 80;

export function ProductDetails({
	item,
	quantity = 1,
	note,
	selectedOptions,
	onlyDisplay,
	isExpressShipping = false,
	additionalPurchaseNum = 0,
	optionErrors,
	onSelectedOptionChange,
	onNoteChange,
}: Props) {
	const { screenWidth } = useWindowDimensions();

	return (
		<>
			<div className="pl-[20px] flex flex-col space-y-[8px]">
				{item && (
					<>
						<h1 className="text-[17px] md:text-[20px] text-white font-bold line-clamp-2">{item.name}</h1>
						<ProductTags isExpressShipping={isExpressShipping} item={item} />

						<NoteField note={note} onNoteChange={onNoteChange} disabled={onlyDisplay} />

						{item.options && item.options.length > 0 && (
							<ItemOptionsSection
								itemId={item._id}
								itemOptions={item.options}
								quantity={quantity}
								selectedOptions={selectedOptions}
								optionErrors={optionErrors}
								onSelectedOptionChange={(option, index) =>
									onSelectedOptionChange && onSelectedOptionChange(item._id, option, index)
								}
							/>
						)}

						{additionalPurchaseNum > 0 && (
							<AdditionalPurchaseList additionalPurchaseNum={additionalPurchaseNum} item={item} />
						)}
					</>
				)}

				{!item && (
					<>
						<Skeleton
							height={22}
							width={screenWidth >= screens.lg.width ? IMG_SKELETON_WIDTH_LG : IMG_SKELETON_WIDTH_DEFAULT}
							baseColor="#8191a0"
						/>
						<div className="flex items-center space-x-[5px] flex-wrap gap-[5px]">
							<Skeleton
								height={14}
								width={screenWidth >= screens.lg.width ? TAG_SKELETON_WIDTH_LG : TAG_SKELETON_WIDTH_DEFAULT}
								baseColor="#8191a0"
							/>
							<Skeleton
								height={14}
								width={screenWidth >= screens.lg.width ? TAG_SKELETON_WIDTH_LG : TAG_SKELETON_WIDTH_DEFAULT}
								baseColor="#8191a0"
							/>
						</div>
					</>
				)}
			</div>
		</>
	);
}
