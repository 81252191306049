import {
	AMEX,
	ApplePay,
	Bitcoin,
	Discover,
	Etherium,
	GooglePay,
	Mastercard,
	Polygon,
	USDCoin,
	Visa,
} from 'assets/images';
import { useWindowDimensions } from 'hooks';
import { PaymentMethod } from 'pages/TransactionManager/transaction.interface';

import { screens } from '../../../../constants';
import { Header } from '../components/Header';
import { SecureBatch } from '../components/SecureBatch';

const buttonConfigs = [
	{
		images: [
			{ src: ApplePay, alt: 'Apple Pay', height: 23 },
			{ src: GooglePay, alt: 'Google Pay', height: 23 },
		],
		imgGap: {
			standard: 18,
			ms: 22,
		},
		paymentMethod: PaymentMethod.APPLE_GOOGLE_PAY,
		link: '/transaction/deposits/payment-amount',
	},
	{
		images: [
			{ src: Visa, alt: 'Visa', height: 14 },
			{ src: Mastercard, alt: 'Mastercard', height: 16 },
			{ src: Discover, alt: 'Visa', height: 16 },
			{ src: AMEX, alt: 'Visa', height: 14 },
		],
		paymentMethod: PaymentMethod.CREDIT_CARD,
		imgGap: {
			standard: 10,
			ms: 14,
		},
		link: '/transaction/deposits/payment-amount',
	},
	{
		images: [
			{ src: Bitcoin, alt: 'Bitcoin', height: 23 },
			{ src: Etherium, alt: 'Etherium', height: 23 },
			{ src: USDCoin, alt: 'USD Coin', height: 23 },
			{ src: Polygon, alt: 'Polygon', height: 23 },
		],
		paymentMethod: PaymentMethod.CRYPTO,
		imgGap: {
			standard: 18,
			ms: 22,
		},
		link: '/transaction/deposits/crypto/choose',
	},
	/*{
		images: [{ src: BlueMFont, alt: 'M', height: 28 }],
		text: 'Gift Card',
		paymentMethod: PaymentMethod.GIFT_CARD,
		imgGap: {
			standard: 10,
			ms: 15,
		},
		link: '/transaction/deposits/payment-amount',
	},*/
];

interface Props {
	onMethodSelection: (method: PaymentMethod) => void;
}

export function PaymentMethodSelection({ onMethodSelection }: Props) {
	const { screenWidth } = useWindowDimensions();

	// eslint-disable-next-line no-magic-numbers
	const imgHeightScalingFactor = screenWidth > screens.ms.width ? 1.1 : 1;

	return (
		<div className="flex flex-col items-center gap-y-[35px] h-full justify-between min-h-fit pb-[12px]">
			<Header title="Choose payment method" showBackArrow={false} />
			<div className="flex flex-col items-center gap-y-[27px] w-full">
				{buttonConfigs.map((payMethod) => (
					<button
						onClick={() => onMethodSelection(payMethod.paymentMethod)}
						key={payMethod.paymentMethod}
						className="flex items-center justify-center border-[2px] border-darkBlue outline outline-[4px] outline-white rounded-[20px] h-[58px] ms:h-[72px] max-w-[380px] w-[250px]"
						style={{ gap: screenWidth > screens.ms.width ? payMethod.imgGap.ms : payMethod.imgGap.standard }}
					>
						{payMethod.images.map((img, index) => (
							<img
								key={index}
								style={{ height: `${img.height * imgHeightScalingFactor}px` }}
								src={img.src}
								className="h-[20px] w-auto"
								alt={img.alt}
							/>
						))}
						{/*payMethod.text && <span className="font-bold text-white text-[20px]">{payMethod.text}</span>*/}
					</button>
				))}
			</div>
			<SecureBatch />
		</div>
	);
}
