import { DarkScrollOverlay } from 'components';
import { AmountDisplayWithCurrency } from 'components/common/AmountDisplayWithCurrency';
import { PopulatedBoxInterface } from 'interfaces/BoxInterface';
import { useNavigate } from 'react-router';
import { getBoxLink, getImageByNumber } from 'utils';

import { CLOUDFRONT_IMG_URL } from '../../../../constants';

interface Props {
	box: PopulatedBoxInterface;
	cardHeight: number;
	isSelected?: boolean;
	position: number;
	onHover: () => void;
}

export function Top10BoxSlide({ box, cardHeight, isSelected, position, onHover }: Props) {
	const navigate = useNavigate();

	function handleBoxNavigation(boxName: string) {
		const boxLink = getBoxLink(boxName);
		navigate(boxLink);
	}

	function handleKeyDown(event: React.KeyboardEvent<HTMLDivElement>, boxName: string) {
		if (event.key === 'Enter' || event.key === ' ') {
			event.preventDefault();
			handleBoxNavigation(boxName);
		}
	}

	return (
		<div
			onMouseEnter={onHover}
			onClick={() => handleBoxNavigation(box.name)}
			onKeyDown={(event) => handleKeyDown(event, box.name)}
			role="button"
			tabIndex={0}
			className="cursor-pointer relative aspect-[3/2] py-[10px]"
			style={{ height: cardHeight + 10 }}
		>
			<div className="h-full w-full relative">
				<img
					alt={box.name}
					src={getImageByNumber(position + 1)}
					className="absolute z-30 left-[10px] -translate-x-1/2 bottom-[-10px] h-[80%]"
				/>

				<div
					className={`${isSelected ? 'border-white' : 'border-transparent'} relative h-full w-full overflow-hidden rounded-[5px] hover:border-white border-[2.5px]`}
				>
					<div className="absolute right-0 top-0 bg-mrLootBlue flex items-center justify-center rounded-[0_0px_0px_20px] px-[8px] min-w-[80px] h-[30px] text-[14px] text-white gap-x-[6px]">
						<AmountDisplayWithCurrency amount={box.price} useDivWrapper={false} imgStyle={{ height: '16px' }} />
					</div>
					<img
						alt=""
						src={`${CLOUDFRONT_IMG_URL}/${box.landscapeImageUrls?.qualityMedium}`}
						className="flex-grow w-full h-full object-cover"
					/>
					<DarkScrollOverlay topOff={30} />
				</div>
			</div>
		</div>
	);
}
