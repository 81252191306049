import { useCallback, useEffect, useState } from 'react';

export const useCountdownTimer = (expirationTime: Date | null): string => {
	const calculateTimeLeft = useCallback(() => {
		if (!expirationTime) {
			return '00:00';
		}

		const difference = new Date(expirationTime).getTime() - new Date().getTime();

		if (difference <= 0) {
			return '00:00';
		}

		// eslint-disable-next-line no-magic-numbers
		const minutes = Math.floor((difference / 1000 / 60) % 60);
		// eslint-disable-next-line no-magic-numbers
		const seconds = Math.floor((difference / 1000) % 60);

		return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
	}, [expirationTime]);

	const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

	useEffect(() => {
		const timer = setInterval(() => {
			setTimeLeft(calculateTimeLeft());
		}, 1000);

		return () => clearInterval(timer);
	}, [calculateTimeLeft]);

	return timeLeft;
};
