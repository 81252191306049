import {
	Bitcoin,
	BitcoinFont,
	Coin,
	CryptoDogecoin,
	CryptoSolana,
	DogecoinFont,
	DollarSign,
	Etherium,
	EtheriumFont,
	LootieFont,
	SolanaFont,
	USDollarFont,
} from 'assets/images';
import { PrimaryBtn } from 'components/common/buttons/PrimaryBtn';
import { FontChooseACurrency } from 'components/PopupHandler/CurrencyPopup/assets';
import { useWindowDimensions } from 'hooks';
import { useBackBtnInTopBar } from 'hooks/app-layout/useBackBtnInTopbar';
import { useTopbarBackBtnListener } from 'hooks/events/useTopbarBackBtnListener';
import { useCallback } from 'react';
import { CurrencyOptions, useAppStore } from 'store/useAppStore';
import { shallow } from 'zustand/shallow';

import { screens } from '../../../../constants';

const PLAY_CURRENCY_OPTIONS = [
	{
		key: CurrencyOptions.LOOTIES,
		titleImg: LootieFont,
		logo: Coin,
	},
	{
		key: CurrencyOptions.US_DOLLAR,
		titleImg: USDollarFont,
		logo: DollarSign,
	},
	{
		key: CurrencyOptions.BITCOIN,
		titleImg: BitcoinFont,
		logo: Bitcoin,
	},
	{
		key: CurrencyOptions.ETHERIUM,
		titleImg: EtheriumFont,
		logo: Etherium,
	},
	{
		key: CurrencyOptions.SOLANA,
		titleImg: SolanaFont,
		logo: CryptoSolana,
	},
	{
		key: CurrencyOptions.DOGECOIN,
		titleImg: DogecoinFont,
		logo: CryptoDogecoin,
	},
];

const getResponsiveSize = (
	screenWidth: number,
	screens: Record<string, { width: number }>,
	sizes: Record<string, number>
): string => {
	const sortedBreakpoints = Object.entries(screens).sort(
		(a, b) => b[1].width - a[1].width // Sort by width descending
	);

	for (const [key, { width }] of sortedBreakpoints) {
		if (screenWidth >= width && sizes[key] !== undefined) {
			return `${sizes[key]}px`;
		}
	}

	return `${sizes.xxs}px`; // Default to xxs if no match
};

export function CurrencyChooser() {
	const { selectedCurrency, setSelectedCurrency } = useAppStore(
		(state) => ({
			selectedCurrency: state.selectedCurrency,
			setSelectedCurrency: state.setSelectedCurrency,
		}),
		shallow
	);

	useTopbarBackBtnListener(
		useCallback(() => {
			window.history.back();
		}, [])
	);

	useBackBtnInTopBar();

	const { screenWidth } = useWindowDimensions();

	return (
		<div className="flex flex-col gap-y-6 sm:gap-y-8 md:gap-y-10">
			{/* Header Section */}
			<div className="flex items-end w-full justify-center gap-x-4 sm:gap-x-6 md:gap-x-8 mb-6">
				<img
					src={FontChooseACurrency}
					className="h-[55px] ms:h-[65px] sm:h-[75px] md:h-[83px]"
					alt="Choose a Currency"
				/>
			</div>

			{/* Currency Options */}
			<div className="flex flex-wrap gap-6 sm:gap-8 w-full items-center justify-center">
				{PLAY_CURRENCY_OPTIONS.map((currency) => (
					<PrimaryBtn
						key={currency.key}
						style={{
							width: getResponsiveSize(screenWidth, screens, {
								xxs: 150,
								xs: 170,
								sm: 190,
								md: 210,
								lg: 230,
							}),
							height: getResponsiveSize(screenWidth, screens, {
								xxs: 100,
								xs: 120,
								sm: 140,
								md: 160,
								lg: 180,
							}),
							borderWidth: '4px',
						}}
						innerContentStyle={{
							borderWidth: '2px',
							borderColor: currency.key === selectedCurrency ? '#2AD100' : '#3559d7',
						}}
						onClick={() => setSelectedCurrency(currency.key)}
						content={
							<div className="flex flex-col items-center h-full justify-evenly">
								<img
									src={currency.logo}
									alt={currency.key}
									className="h-[24px] ms:h-[30px] sm:h-[34px] md:h-[38px] lg:h-[44px]"
								/>
								<img
									src={currency.titleImg}
									alt={`${currency.key} Font`}
									className="h-[20px] ms:h-[24px] sm:h-[27px] md:h-[30px] lg:h-[35px]"
								/>
							</div>
						}
					/>
				))}
			</div>
		</div>
	);
}
