import { FieldValues, useController, UseControllerProps } from 'react-hook-form';

import { PrimaryPasswordInput } from './PrimaryPasswordInput';

interface PasswordInputWrapperProps<T extends FieldValues> extends UseControllerProps<T> {
	label?: string;
	placeholder?: string;
}

export function PrimaryPasswordInputWrapper<T extends FieldValues>({
	name,
	control,
	rules,
	label,
	placeholder,
}: PasswordInputWrapperProps<T>) {
	const {
		field,
		fieldState: { error },
	} = useController({
		name,
		control,
		rules,
	});

	return <PrimaryPasswordInput label={label} placeholder={placeholder} error={error?.message} {...field} />;
}
