import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { BasektProductWithId, BasketProduct } from './shop.interfaces';

interface MerchState {
	showCartPopup: boolean;
	setShowCartPopup: (isVisible: boolean) => void;
	products: BasektProductWithId[];
	changeQtyOfProduct: (_id: string, newQty: number) => void;
	addProduct: (product: BasketProduct) => void;
	removeProduct: (_id: string) => void;
	clearBasket: () => void;
}

export const useMerchStore = create<MerchState>()(
	persist(
		(set) => ({
			showCartPopup: false,
			setShowCartPopup: (isVisible: boolean) => set({ showCartPopup: isVisible }),
			products: [],
			changeQtyOfProduct: (_id, newQty) =>
				set((state) => ({
					products: state.products.map((product) => (product._id === _id ? { ...product, quantity: newQty } : product)),
				})),

			addProduct: (newProduct: BasketProduct) => {
				set((state) => {
					const existingProductIndex = state.products.findIndex(
						(product) =>
							product.item._id === newProduct.item._id &&
							JSON.stringify(product.options) === JSON.stringify(newProduct.options)
					);

					if (existingProductIndex > -1) {
						const updatedProducts = [...state.products];
						updatedProducts[existingProductIndex].quantity += newProduct.quantity;
						return { products: updatedProducts };
					}

					// eslint-disable-next-line no-magic-numbers
					const uniqueId = Date.now().toString() + Math.random().toString(36).substring(2, 9);
					return { products: [...state.products, { ...newProduct, _id: uniqueId }] };
				});
			},
			removeProduct: (_id: string) => {
				set((state) => ({
					products: state.products.filter((p) => p._id !== _id),
				}));
			},
			clearBasket: () => set({ products: [] }),
		}),
		{
			name: 'basket-storage',
			storage: {
				getItem: (name) => {
					const item = localStorage.getItem(name);
					return item ? JSON.parse(item) : null;
				},
				setItem: (name, value) => localStorage.setItem(name, JSON.stringify(value)),
				removeItem: (name) => localStorage.removeItem(name),
			},
		}
	)
);
