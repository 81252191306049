import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
	showPrices: boolean;
	onChange: () => void;
}

export function ViewSwitchBtn({ showPrices, onChange }: Props) {
	return (
		<div className="flex items-center mb-4">
			<button
				className={`${showPrices ? 'text-white' : 'text-[rgba(255,255,255,0.6)]'} font-semibold text-[16px] xl:text-[19px]`}
				onClick={onChange}
			>
				<span className="mr-2">Prizes</span>
				<FontAwesomeIcon icon={faCaretLeft} className="" />
			</button>
			<button
				className={`${showPrices ? 'text-[rgba(255,255,255,0.6)]' : 'text-white'} font-semibold text-[16px] xl:text-[19px]`}
				onClick={onChange}
			>
				<FontAwesomeIcon icon={faCaretRight} className="" />
				<span className="ml-2">Recommended Boxes</span>
			</button>
		</div>
	);
}
