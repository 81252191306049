import { FreeFont, OpenFont } from 'assets/images';
import { AmountDisplayWithCurrency } from 'components/common/AmountDisplayWithCurrency';
import { PrimaryBtn } from 'components/common/buttons/PrimaryBtn';
import { useUserContext, useWindowDimensions } from 'hooks';
import { PopulatedBoxInterface } from 'interfaces/BoxInterface';
import { useInventoryBoxes } from 'pages/inventory/hooks/useInventoryBoxes';
import { useBoxOpeningStoreMobile } from 'pages/OpenBox/store/useBoxOpeningStoreMobile';
import { CSSProperties, forwardRef, useMemo } from 'react';
import { toast } from 'react-toastify';
import { shallow } from 'zustand/shallow';

import { screens } from '../../../../constants';
import { ToastInsufficientBalanceNotification } from '../../components/ToastInsufficientBalance';

interface Props {
	box?: PopulatedBoxInterface;
	onClick: () => void;
	isDisabled: boolean;
	style?: CSSProperties;
}

export const BoxOpenBtn = forwardRef<HTMLButtonElement, Props>(({ box, onClick, isDisabled, style }, ref) => {
	const { user } = useUserContext();
	const setIsOpenWithoutSessionPopupVisible = useBoxOpeningStoreMobile(
		(state) => state.setIsOpenWithoutSessionPopupVisible,
		shallow
	);

	const { data: inventoryBoxes, isLoading: isInventoryBoxesLoading } = useInventoryBoxes();

	const handleOpenClick = () => {
		if (!box) {
			return;
		}

		if (!user) {
			setIsOpenWithoutSessionPopupVisible(true); // displayed popupHandler
		} else if (!isBoxFree && user.balance < box.price) {
			toast(<ToastInsufficientBalanceNotification />, {
				style: { backgroundColor: `rgba(41,129,233,0.9)` },
				autoClose: 5000,
			});
		} else {
			onClick();
		}
	};

	const { screenWidth } = useWindowDimensions();
	const imgHeight = screenWidth > screens.sm.width ? '18px' : '16px';

	const isBoxFree = useMemo(
		() => !!inventoryBoxes && inventoryBoxes.some((inventoryBox) => inventoryBox.boxId._id === box?._id),
		[box, inventoryBoxes]
	);

	return (
		<PrimaryBtn
			ref={ref}
			style={{ height: 'fit-content', overflow: 'visible', ...style }}
			onClick={handleOpenClick}
			isDisabled={isDisabled || isInventoryBoxesLoading}
			innerContentStyle={{ padding: '0px' }}
			content={
				<div className="h-[41px] sm:h-[48px] flex items-center">
					<img src={OpenFont} alt={`Open ${box?.name}`} className="h-[23px] sm:h-[27px]" />

					<div className="absolute right-0 top-[50%] translate-y-[-50%] flex items-center space-x-[9px] px-[9px]">
						<AmountDisplayWithCurrency
							amount={box?.price}
							amountStyle={{
								fontSize: imgHeight,
								fontWeight: 'bold',
								textDecoration: isBoxFree ? 'line-through' : '',
								textDecorationThickness: '2px',
							}}
							imgStyle={{ height: imgHeight }}
							useDivWrapper={false}
						/>
					</div>

					{isBoxFree && (
						<img src={FreeFont} alt="free" className="h-[18px] absolute top-[-9px] right-[-14px] rotate-[13deg]" />
					)}
				</div>
			}
		/>
	);
});

BoxOpenBtn.displayName = 'BoxOpenBtn';
