/**
 * Generates a random seed string.
 *
 * This function creates a random string of a specified length using alphanumeric characters.
 * The default length of the generated string is 8 characters if no length is specified.
 *
 * @param {number} seedLength - The length of the seed string to generate. Default is 8.
 * @returns {string} The generated seed string.
 */
export const generateRandomSeed = (seedLength = 8) => {
	const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

	let seed = '';
	const charactersLength = characters.length;

	for (let i = 0; i < seedLength; i++) {
		const randomIndex = Math.floor(Math.random() * charactersLength);
		const randomCharacter = characters.charAt(randomIndex);
		seed += randomCharacter;
	}

	return seed;
};

/**
 * Calculates the relative distance of a position within a range as a percentage.
 *
 * Given a position and a range defined by a minimum and maximum position, this function
 * computes how far the given position is along that range as a percentage of the total range.
 *
 * @param {number} posY - The current position to calculate the percentage for.
 * @param {number} minPos - The minimum value of the position range.
 * @param {number} maxPos - The maximum value of the position range.
 * @returns {number} The percentage (0 to 100) of the position within the range.
 */
export function calcDistanceInPercentage(posY: number, minPos: number, maxPos: number) {
	const toPercentageMultiplier = 100;
	return ((posY - minPos) / (maxPos - minPos)) * toPercentageMultiplier;
}

/**
 * Easing function that accelerates and decelerates a value within a range based on a quadratic equation.
 *
 * This easing function is useful for creating smooth animations that start slowly,
 * accelerate in the middle, and then slow down again towards the end.
 *
 * @param {number} t - The current time or position of the animation in frames or seconds.
 * @param {number} b - The starting value of the property being animated.
 * @param {number} c - The change between the initial and final value of the property being animated.
 * @param {number} d - The total duration of the animation.
 * @returns {number} The calculated value at the current time `t`.
 */
export function easeInOutQuad(t: number, b: number, c: number, d: number): number {
	const halfDuration = d / 2;
	t /= halfDuration;
	if (t < 1) {
		return (c / 2) * t * t + b;
	}
	t--;
	return (-c / 2) * (t * (t - 2) - 1) + b;
}

export const isHorizontalSwipe = (event: TouchEvent, startX: number, startY: number) => {
	if (!event || !event.touches || event.touches.length === 0) {
		return;
	}
	const touch = event.touches[0];
	const deltaX = touch.clientX - startX;
	const deltaY = touch.clientY - startY;
	const absDeltaX = Math.abs(deltaX);
	const absDeltaY = Math.abs(deltaY);

	return absDeltaX >= absDeltaY;
};

export function calculateParabola(progress: number, yStart: number, yEnd: number, yPeak: number) {
	const b = 2 * (yPeak - yStart) - (yEnd - yStart);
	const a = yEnd - yStart - b;
	const c = yStart;

	return a * progress * progress + b * progress + c;
}
