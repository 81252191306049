import { faCancel, faHeadset, faRotateRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SecondaryBtn } from 'components/common/buttons/SecondaryBtn';
import { ToastErrorNotification } from 'components/toasts/ToastErrorNotification';
import { ToastSuccessNotification } from 'components/toasts/ToastSuccessNotification';
import { OrderDbInterface, PublicOrderStatus } from 'interfaces/OrderInterfaces';
import { useCancelOrder } from 'pages/MyOrders/hooks/useCancelOrder';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { QUERY_KEYS } from '../../../../constants';
import { ReturnPopup } from '../ReturnPopup/ReturnPopup';

interface Props {
	order: OrderDbInterface;
}

export function OrderActionBtns({ order }: Props) {
	const [showReturnPopup, setShowReturnPopup] = useState(false);

	const queryClient = useQueryClient();

	const { mutate: cancelOrder, isLoading: isCancelReqLoading } = useCancelOrder({
		onSuccess: () => {
			queryClient.invalidateQueries(QUERY_KEYS.inventoryItemData);
			queryClient.invalidateQueries(QUERY_KEYS.orderData);
			toast(<ToastSuccessNotification message="The order has been successfully canceled." />);
		},
		onError: (message) => {
			toast(<ToastErrorNotification message={message} />);
		},
	});

	const isCancelBtnActive =
		!isCancelReqLoading && !order.assignedBy && order.publicStatus === PublicOrderStatus.IN_PROCESS;
	const isReturnBtnActive =
		!isCancelReqLoading &&
		order.publicStatus === PublicOrderStatus.DELIVERED &&
		order.items.some((item) => item.unavailableQuantity < item.quantity);

	return (
		<>
			{showReturnPopup && <ReturnPopup order={order} onClose={() => setShowReturnPopup(false)} />}

			<div className="flex gap-[10px] items-center mb-1 mt-1">
				<SecondaryBtn
					style={{
						height: '40px',
						flexGrow: 1,
						maxWidth: '140px',
						opacity: isCancelBtnActive ? '100%' : '60%',
					}}
					isDisabled={!isCancelBtnActive}
					onClick={() => cancelOrder({ orderId: order._id })}
					content={
						<div className="flex items-center text-[12px] gap-x-[8px]">
							<FontAwesomeIcon icon={faCancel} className="text-[13px]" />
							<span>Cancel Order</span>
						</div>
					}
				/>
				<SecondaryBtn
					style={{
						height: '40px',
						flexGrow: 1,
						maxWidth: '140px',
						opacity: isReturnBtnActive ? '100%' : '60%',
					}}
					onClick={() => setShowReturnPopup(true)}
					isDisabled={!isReturnBtnActive}
					content={
						<div className="flex items-center text-[12px] gap-x-[8px]">
							<FontAwesomeIcon icon={faRotateRight} className="text-[13px]" />
							<span>Create Return</span>
						</div>
					}
				/>

				<button className="custom-intercom-launcher text-white h-[40px] max-w-[140px] flex-grow flex items-center justify-center gap-x-[10px] border-[2px] border-darkBlue outline outline-[2px] outline-white rounded-[20px] bg-secondary-gradient ">
					<FontAwesomeIcon icon={faHeadset} className="text-[13px]" />
					<span>Support</span>
				</button>
			</div>
		</>
	);
}
