/* eslint-disable no-magic-numbers */
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconLeftArrow } from 'assets/icons';
import { useWindowDimensions } from 'hooks';
import { usePopupStore } from 'store/usePopupStore';

import { screens } from '../../../../constants';

interface Props {
	showBackArrow?: boolean;
	title: string;
	onBackClick?: () => void;
}

export function Header({ title, showBackArrow = true, onBackClick }: Props) {
	const { screenWidth } = useWindowDimensions();

	const { setIsDepositPopupVisible } = usePopupStore((state) => ({
		isDepositPopupVisible: state.isDepositPopupVisible,
		setIsDepositPopupVisible: state.setIsDepositPopupVisible,
	}));

	return (
		<div className="relative flex items-center justify-center gap-x-[15px] w-full">
			{showBackArrow && (
				<button
					onClick={onBackClick}
					className="absolute left-0 top-1/2 -translate-y-1/2 rounded-[12px] w-[37px] h-[37px] flex items-center justify-center text-white "
				>
					<IconLeftArrow
						height={screenWidth >= screens.sm.width ? 40 : screenWidth >= screens.ms.width ? 30 : 24}
						width={screenWidth >= screens.sm.width ? 40 : screenWidth >= screens.ms.width ? 30 : 24}
					/>
				</button>
			)}
			<h1 className="font-bold text-[18px] ms:text-[22px] sm:text-[26px] text-white text-center">{title}</h1>
			<button
				className="absolute right-0 text-[21px] text-white top-1/2 -translate-y-1/2"
				onClick={() => setIsDepositPopupVisible(false)}
			>
				<FontAwesomeIcon icon={faXmark} />
			</button>
		</div>
	);
}
