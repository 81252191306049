import { ToolTip } from 'components/common/ToolTip';
import { ItemOrderStatus, OrderDbItemInterface } from 'interfaces/OrderInterfaces';
import { isOrderItemRefunded, refundLabel } from 'pages/MyOrders/orderUtils';

interface Props {
	product: OrderDbItemInterface;
	colorTheme?: 'white' | 'black';
}

const RefundInfo = ({ product }: Props) => (
	<>
		<ToolTip message="Ooops! The price of this product has increased due to market changes" position="top-left" />
		<h1 className="text-[14px] font-semibold ">{refundLabel(product.status)}</h1>
	</>
);

const PaymentConfirmationInfo = () => (
	<>
		<ToolTip
			message="Due to price changes in the market, an extra payment was necessary to secure your item"
			position="top-left"
		/>
		<h1 className="text-[14px] font-semibold ">Payment Complete</h1>
	</>
);

export function ProductNoticeInfo({ product, colorTheme = 'white' }: Props) {
	return (
		<div className={`flex items-center gap-x-[5px] ${colorTheme === 'white' ? 'text-white' : 'text-black'} mb-[5px]`}>
			{isOrderItemRefunded(product.status) && <RefundInfo product={product} />}
			{product.status === ItemOrderStatus.PAYMENT_COMPLETED && <PaymentConfirmationInfo />}
		</div>
	);
}
