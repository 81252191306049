import { useUserContext } from 'hooks/context/useUserContext';
import { ItemInterface } from 'interfaces/ItemInterfaces';
import { InteractionType } from 'interfaces/UserInteractionInterface';
import { useEffect, useRef, useState } from 'react';

import { useCreateUserInteraction } from './useCreateUserInteraction';

export function useItemViewLogger(itemId: string, itemSnapshot: Partial<ItemInterface>, delay = 1200) {
	const [isHovering, setIsHovering] = useState(false);
	const hoverTimeoutRef = useRef<number | null>(null);
	const hoverStartTimeRef = useRef<number | null>(null);
	const { mutate: logInteraction } = useCreateUserInteraction();

	const { user } = useUserContext();

	const logItemView = () => {
		if (!user) {
			return;
		}

		const hoverEndTime = Date.now();
		const hoverDuration = hoverStartTimeRef.current !== null ? hoverEndTime - hoverStartTimeRef.current : 0;

		logInteraction({
			userId: user?._id,
			targetType: 'item',
			targetId: itemId,
			interactionType: InteractionType.VIEW_ITEM,
			interactionDetails: { hoverDuration },
			stateAtInteraction: itemSnapshot,
		});
	};

	// Handle mouse enter
	const handleMouseEnter = () => {
		setIsHovering(true);
		hoverStartTimeRef.current = Date.now();
		hoverTimeoutRef.current = window.setTimeout(logItemView, delay);
	};

	// Handle mouse leave
	const handleMouseLeave = () => {
		setIsHovering(false);
		if (hoverTimeoutRef.current !== null) {
			clearTimeout(hoverTimeoutRef.current);
			hoverTimeoutRef.current = null;
		}
	};

	// Cleanup
	useEffect(() => {
		return () => {
			if (hoverTimeoutRef.current !== null) {
				clearTimeout(hoverTimeoutRef.current);
			}
		};
	}, []);

	return { isHovering, handleMouseEnter, handleMouseLeave };
}
