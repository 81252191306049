import { faChevronRight, faPenToSquare, faSpinner, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useUserContext } from 'hooks';
import { ShippingAddressInterface } from 'interfaces/OrderInterfaces';
import { useDeleteUserAddress } from 'pages/checkout/hooks/useDeleteAddress';
import { CSSProperties, useRef } from 'react';
import { useNavigate } from 'react-router';

interface Props {
	address: ShippingAddressInterface;
	isSelected?: boolean;
	onClick: () => void;
	style?: CSSProperties;
	isEditMode?: boolean;
	showEditBtn?: boolean;
}

export function FilledAddressCard({ address, onClick, style, isEditMode, isSelected }: Props) {
	const addressCardRef = useRef<HTMLDivElement>(null);

	const navigate = useNavigate();
	const { load: reloadUser } = useUserContext();

	const { mutate: deleteAddress, isLoading: isDeleteRequestLoading } = useDeleteUserAddress({
		onSuccess: () => {
			reloadUser();
		},
	});

	function handleOnClick() {
		if (isEditMode) {
			navigate(`/my-account/edit-profile/update-address?id=${address._id}`);
		}

		onClick && onClick();
	}

	return (
		<div key={address._id} className="flex w-full justify-between gap-x-[15px]">
			{isEditMode && (
				<button
					disabled={isDeleteRequestLoading}
					onClick={() => deleteAddress(address._id)}
					className="text-errorRed mx-auto !ml-[15px] text-[17px] flex items-center justify-center  my-auto"
				>
					<FontAwesomeIcon icon={isDeleteRequestLoading ? faSpinner : faXmark} />
				</button>
			)}
			<div
				ref={addressCardRef}
				onClick={handleOnClick}
				className={`relative ${isSelected ? 'border-white bg-mrLootBlue text-white' : 'border-[#F5F5F5] bg-[#F5F5F5] text-black '} ${isEditMode ? 'scale-90' : ''} relative shadow- font-semibold h-fit border-[2px] w-full rounded-[4px]  flex flex-col p-[12px] text-[14px] gap-y-[7px]`}
				style={{
					boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
					transformOrigin: 'right center',
					transition: 'transform 0.3s',
					...style,
				}}
				tabIndex={0}
				role="button"
				onKeyDown={(e) => {
					if (e.key === 'Enter' || e.key === ' ') {
						handleOnClick();
					}
				}}
			>
				<span className="font-bold">
					{address.firstName} {address.lastName}
				</span>
				{address.phoneNumber && <span>{address.phoneNumber}</span>}
				<span>
					{address.street} {address.houseNr}
				</span>
				<span>
					{address.city} {address.country}
				</span>

				{isEditMode && (
					<FontAwesomeIcon
						icon={faChevronRight}
						className={`text-[19px] ${isSelected ? 'text-white' : 'text-mrLootBlue'} absolute right-3 top-[50%] translate-y-[-50%]`}
					/>
				)}
				{!isEditMode && (
					<button
						onClick={() => navigate(`/my-account/edit-profile/update-address?id=${address._id}`)}
						className="absolute right-3 top-3 shadow-sm rounded-[4px] bg-white border-[1px] border-black text-black flex items-center justify-center h-[35px] w-[35px]"
					>
						<FontAwesomeIcon icon={faPenToSquare} />
					</button>
				)}
			</div>
		</div>
	);
}
