/* eslint-disable no-magic-numbers */
import Cookies from 'js-cookie';

import { screens } from '../constants';

export * from './converterAndformatHelpers';
export * from './fullScreenHelpers';
export * from './imageHelpers';
export * from './itemHelpers';
export * from './mathOperations';
export * from './Oauth';
export * from './ScrollHelpers';

export function isMobile(screenWidth: number) {
	const userAgentCheck = /Mobi|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
	const touchCheck = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
	const orientationCheck = typeof window.orientation !== 'undefined';

	return screens.sm.width > screenWidth || userAgentCheck || touchCheck || orientationCheck;
}

interface Tag {
	[tag: string]: number;
}

export const getHighestValueTag = (tags: Tag[]) => {
	if (!Array.isArray(tags) || tags.length === 0) {
		return ''; // Return default value if tags are undefined or empty
	}

	// Find the tag with the highest value
	const highestTag = tags.reduce((max, current) => {
		const [, currentValue] = Object.entries(current)[0]; // Get the first key-value pair from current
		const [, maxValue] = Object.entries(max)[0]; // Get the first key-value pair from max

		return currentValue > maxValue ? current : max; // Compare values
	});

	return Object.keys(highestTag)[0]; // Return the tag name
};

export function generateObjectId(): string {
	// Generate a 24-character hexadecimal string
	return 'xxxxxxxxxxxx'.replace(/[x]/g, () => {
		// Generate random hex digits
		// eslint-disable-next-line no-magic-numbers
		return Math.floor(Math.random() * 16).toString(16);
	});
}
export function generateUniqueId(): string {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (char) => {
		const random = (Math.random() * 16) | 0;
		const value = char === 'x' ? random : (random & 0x3) | 0x8;
		return value.toString(16);
	});
}

export function isMobile2(screenWidth: number) {
	return screens.sm.width > screenWidth;
}

export const getCookie = (key: string) => {
	const cookie = Cookies.get(key);
	return cookie ? JSON.parse(cookie) : undefined;
};

// Set a cookie value
export const setCookie = (key: string, value: unknown, options = {}) => {
	Cookies.set(key, JSON.stringify(value), {
		path: '/',
		...options,
	});
};

// Remove a cookie
export const removeCookie = (key: string) => {
	Cookies.remove(key, { path: '/' });
};

export function clamp(value: number, min: number, max: number): number {
	return Math.max(min, Math.min(max, value));
}

export const getBoxLink = (boxName: string) => {
	const formattedName = boxName.replace(/ /g, '-');
	const encodedBoxName = encodeURIComponent(formattedName);
	return `/boxes/open/${encodedBoxName}`;
};

/**
 * Converts server time to client's local time and formats it.
 * @param serverTime - The server time in ISO string format.
 * @param options - Optional formatting options.
 * @returns The formatted local time as a string.
 */
export function convertToClientTime(serverTime: string, options?: Intl.DateTimeFormatOptions): string {
	// Convert server time to a Date object
	const date = new Date(serverTime);

	// Get the user's locale and time zone
	const locale = navigator.language || 'en-US';
	const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

	// Merge default options with user-provided options
	const formatOptions = { ...options, timeZone };

	// Format the date using the Intl.DateTimeFormat API
	const formattedDate = new Intl.DateTimeFormat(locale, formatOptions).format(date);

	return formattedDate;
}

/**
 * Formats a given date into a specified locale and format.
 * If no date is provided, returns `undefined`.
 *
 * @param {Date | undefined} date - The date to format.
 * @param {string} locale - The locale to use for formatting (default is 'en-GB').
 * @param {Intl.DateTimeFormatOptions} options - The formatting options (default is day, month, year).
 * @param {string} replacePattern - Optional pattern to replace in the formatted string (default replaces space after month with '. ').
 * @returns {string | undefined} - The formatted date string or undefined if no date is provided.
 */
export function formatDate(
	date?: Date,
	locale: string = 'en-GB',
	options: Intl.DateTimeFormatOptions = { day: '2-digit', month: 'short', year: 'numeric' },
	replacePattern: string = ' ',
	replaceWith: string = '. '
): string | undefined {
	if (!date) {
		return undefined;
	}
	return date.toLocaleDateString(locale, options).replace(replacePattern, replaceWith);
}
