import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TopbarDesktop } from 'components';
import { Footer } from 'components/layout/footer/Footer';
import { SidebarDesktop } from 'components/layout/nav/desktop/SidebarDesktop';
import { useUrlParamsToast } from 'hooks/events/useUrlParamsToast';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { COLORS } from '../constants';

export default function DesktopMainLayout() {
	useUrlParamsToast();

	return (
		<>
			<ToastContainer
				autoClose={3500}
				stacked={true}
				hideProgressBar={true}
				draggableDirection="y"
				draggablePercent={30}
				theme="colored"
				position="bottom-right"
				toastStyle={{ backgroundColor: COLORS.blue90 }}
				closeButton={(props) => (
					<button onClick={props.closeToast} className="absolute right-2 top-2 text-whie text-[14px] text-white p-1">
						<FontAwesomeIcon icon={faXmark} />
					</button>
				)}
			/>

			<div className="bg-background pl-desktop-left-padding pr-desktop-right-padding">
				<TopbarDesktop />
				<SidebarDesktop />

				<div className={`pt-desktop-top-padding mx-auto max-w-max-screen-width`}>
					<Outlet />
				</div>
			</div>
			<Footer />
		</>
	);
}
