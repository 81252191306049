/* eslint-disable max-lines */
/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import { gsap } from 'gsap';
import { CustomEase } from 'gsap/CustomEase';
import { PrizeInterface } from 'interfaces/ItemInterfaces';
import {
	DESKTOP_NUM_PRE_SLOT_PRIZES,
	DESKTOP_NUM_TRANSITION_PRIZES,
	NUM_BUFFER_PRIZES,
	NUM_SURROUNDING_PRIZES,
} from 'pages/OpenBox/box-opening.constants';
import { useWinSoundPlayer } from 'pages/OpenBox/hooks/useWinSoundPlayer';
import { useBoxOpeningStoreDesktop } from 'pages/OpenBox/store/useBoxOpeningStoreDesktop';
import { calculateOffset, getCurrentTranslateX, getScaleOfNodeEl } from 'pages/OpenBox/util';
import { RefObject, useCallback, useEffect, useLayoutEffect, useRef } from 'react';

interface SlotComponentInterface {
	wonPrize: PrizeInterface;
	surroundingWonPrizes: PrizeInterface[];
	progress?: number; // [0; 1]
}

interface CalculateInstantaneousVelocityParams {
	progress: number; // [0; 1]
	totalDistance: number; // Total distance the animation covers (e.g., in pixels)
	easingFunction: typeof CustomEase; // GSAP CustomEase instance
	rangeSteps?: number;
	initialRange?: number;
}

interface Props {
	itemsWrapperRef: RefObject<HTMLDivElement>;
	slotPickerRef: RefObject<HTMLImageElement>;
	videoRef: RefObject<HTMLVideoElement>;
	boxTitleRef: RefObject<HTMLDivElement>;
	onSpinComplete: ({ wonPrize, surroundingWonPrizes }: SlotComponentInterface) => void;
	slotSpinContainerRef: RefObject<HTMLDivElement>;
	isFastSpin: boolean;
	hasAlreadySpun: boolean;
}

const RESIZE_DEBOUNCE_TIME = 200; // ms
const BREAK_TIME_SPIN_END = 0.5; // seconds - time slot pauses after fast spin so user can see what he has won

//const CARDS_PER_SECOND = 5;
const NORMAL_BUFFER_SPIN_SPEED_IN_CARDS = 14; // px per second
const FAST_BUFFER_SPIN_SPEED_IN_CARDS = 26; // px per second

const NORMAL_WIN_SPIN_SPEED_IN_CARDS = 5.4; // first spin
const FAST_WIN_SPIN_SPEED_IN_CARDS = 10.8;
const NORMAL_AUTO_SPIN_SPEED_IN_CARDS = 5.9; // auto spin
const FAST_AUTO_SPIN_SPEED_IN_CARDS = 11.2;

gsap.registerPlugin(CustomEase);
CustomEase.create('slot-spin', 'M0,0 C0.083,0.294 0.281,0.758 0.58,0.892 0.732,0.96 0.752,1 1,1 ');
CustomEase.create('customBounceOut', 'M0,0 C0.25,0.46 0.45,0.94 0.65,0.94 0.85,0.94 1.1,1.05 1,1');
CustomEase.create('smoothEase', 'M0,0 C0.25,0.1 0.25,1 1,1');

const firstSpinEasingNormal = CustomEase.create(
	'firstSpinEasingNormal',
	'M0,0 C0.132,0.363 0.332,0.632 0.475,0.788 0.652,0.981 0.818,1.001 1,1 '
);

const firstSpinEasingFast = CustomEase.create(
	'custom',
	'M0,0 C0.144,0.36 0.3,0.556 0.406,0.716 0.55,0.934 0.818,1.001 1,1 '
);

const autoSpinEasingOutNormal = CustomEase.create(
	'autoSpinEasingOutNormal',
	'M0,0 C0.072,0.142 0.203,0.563 0.498,0.81 0.625,0.916 0.807,1 1,1 '
);

const autoSpinEasingOutFast = CustomEase.create(
	'autoSpinEasingOutFast',
	'M0,0 C0.072,0.142 0.193,0.612 0.488,0.859 0.615,0.965 0.903,1 1,1 '
);

const getCardWidth = (cardElement: HTMLElement, slotSpinContainerRef?: RefObject<HTMLDivElement>) => {
	const cardWidth = cardElement.clientWidth;

	if (slotSpinContainerRef) {
		const scale = getScaleOfNodeEl(slotSpinContainerRef);
		return Math.min(scale > 0 ? cardWidth / scale : cardWidth, cardWidth);
	}

	return cardWidth;
};

const getContainerWidth = (slotSpinContainerRef: RefObject<HTMLDivElement>) => {
	const scale = getScaleOfNodeEl(slotSpinContainerRef);
	const containerWidth = slotSpinContainerRef.current?.getBoundingClientRect().width ?? 0;
	return containerWidth / scale;
};

const calcRandomOffset = (cardWidth: number, thresholdMin = 0.1, thresholdMax = 0.4) => {
	return Math.floor((Math.random() * (thresholdMax - thresholdMin) + thresholdMin) * cardWidth);
};

const linearEase = (progress: number) => progress; // Linear: progress = time

export function useDesktopSlotSpinAnimation({
	itemsWrapperRef,
	slotPickerRef,
	slotSpinContainerRef,
	isFastSpin,
	boxTitleRef,
	hasAlreadySpun,
	videoRef,
	onSpinComplete,
}: Props) {
	const isWinningTimelineSpinning = useRef(false);
	const isBufferTimelineSpinning = useRef(false);

	const boxOpenTimeline = useRef<gsap.core.Timeline | null>(gsap.timeline());
	const bufferSpinTimeline = useRef(gsap.timeline());
	const winningSpinTimeline = useRef(gsap.timeline());

	// resizing & fullscreen changes
	const oldCardWidth = useRef(0);
	const oldContainerWidth = useRef(0);
	const oldStartingX = useRef(0);

	const { wonPrize, slotPrizesSurroundingWon, isFullScreen } = useBoxOpeningStoreDesktop((state) => ({
		wonPrize: state.wonPrize,
		slotPrizesSurroundingWon: state.slotPrizesSurroundingWon,
		isFullScreen: state.isFullScreen,
	}));

	// debug
	const currentEasing = useRef('none');
	const currentEndlessBufferStartTime = useRef<Date>();
	const currentEndlessBufferDistance = useRef(0);
	const currentEndlessBufferDuration = useRef(0);

	useEffect(() => {
		const tl = winningSpinTimeline.current;
		const bufferTl = winningSpinTimeline.current;
		return () => {
			if (bufferTl) {
				bufferTl.clear();
				bufferTl.pause();
				bufferTl.kill();
			}
			// Cleanup function that kills the timeline when the component unmounts
			if (tl) {
				tl.clear();
				tl.pause();
				tl.kill();
			}
		};
	}, []);

	const { playSound: playWinSound } = useWinSoundPlayer();

	const updateOldStoredDimensions = useCallback(
		(cardWidth: number, currentX: number) => {
			oldCardWidth.current = cardWidth;
			oldContainerWidth.current = getContainerWidth(slotSpinContainerRef);
			oldStartingX.current = currentX;
		},
		[slotSpinContainerRef]
	);

	const getEndlessSpinDurationByDistance = useCallback(
		(startX: number, targetX: number, cardWidth: number) => {
			const speed = isFastSpin ? FAST_BUFFER_SPIN_SPEED_IN_CARDS : NORMAL_BUFFER_SPIN_SPEED_IN_CARDS;
			return Math.abs(targetX - startX) / cardWidth / speed;
		},
		[isFastSpin]
	);

	const getWinSpinDurationByDistance = useCallback(
		(startX: number, targetX: number, cardWidth: number) => {
			let speed = 0;
			if (hasAlreadySpun) {
				speed = isFastSpin ? FAST_AUTO_SPIN_SPEED_IN_CARDS : NORMAL_AUTO_SPIN_SPEED_IN_CARDS;
			} else {
				speed = isFastSpin ? FAST_WIN_SPIN_SPEED_IN_CARDS : NORMAL_WIN_SPIN_SPEED_IN_CARDS;
			}
			return Math.abs(targetX - startX) / cardWidth / speed;
		},
		[hasAlreadySpun, isFastSpin]
	);

	const handleSpinEnd = useCallback(
		(wonPrize: PrizeInterface, surroundingWonPrizes: PrizeInterface[]) => {
			playWinSound(wonPrize.data.rarity);

			isBufferTimelineSpinning.current = false;
			isWinningTimelineSpinning.current = false;

			if (isFastSpin) {
				onSpinComplete({ wonPrize, surroundingWonPrizes });
			} else {
				gsap.delayedCall(BREAK_TIME_SPIN_END, () => onSpinComplete({ wonPrize, surroundingWonPrizes }));
			}
		},
		[isFastSpin, onSpinComplete, playWinSound]
	);

	useEffect(() => {
		// Initialize the timeline
		boxOpenTimeline.current = gsap.timeline();

		// Use the timeline to set initial values
		boxOpenTimeline.current.set(boxTitleRef.current, { autoAlpha: 1 });
		boxOpenTimeline.current.set(slotSpinContainerRef.current, { autoAlpha: 0, scale: 0.01 });

		// Pause the timeline after setting values to prevent it from animating immediately
		boxOpenTimeline.current.pause();

		// Define the animation sequence in the timeline
		boxOpenTimeline.current
			.to(boxTitleRef.current, {
				autoAlpha: 0,
				duration: 0.4,
				ease: 'none',
			})
			.to(
				slotSpinContainerRef.current,
				{
					autoAlpha: 1,
					scale: 1,
					delay: 0.3,
					ease: 'power2.out',
					duration: 1.1,
				},
				'<'
			);

		// Cleanup on component unmount
		return () => {
			boxOpenTimeline.current?.kill();
			boxOpenTimeline.current = null; // Ensure proper cleanup
		};
	}, [boxTitleRef, slotSpinContainerRef]);

	const animateBoxOpening = useCallback(() => {
		// Restart the timeline from the beginning and play it
		boxOpenTimeline.current?.restart();

		// Play the video
		videoRef.current?.play();
	}, [videoRef]);

	const animateEndlessBufferSpin = useCallback(
		(progress?: number) => {
			const itemsWrapperEl = itemsWrapperRef.current;
			const firstItemCardEl = itemsWrapperEl?.children[0] as HTMLElement;
			const secondItemCardEl = itemsWrapperEl?.children[1] as HTMLElement;

			if (!firstItemCardEl || !secondItemCardEl) {
				console.error('Item card elements are missing.');
				return;
			}

			getCardWidth(firstItemCardEl, slotSpinContainerRef);
			const cardWidth = getCardWidth(firstItemCardEl, slotSpinContainerRef);
			const gapBetweenItems = secondItemCardEl.offsetLeft - (firstItemCardEl.offsetLeft + firstItemCardEl.offsetWidth);

			const startX = getCurrentTranslateX(itemsWrapperEl);
			const startXEndlessSpin = hasAlreadySpun
				? DESKTOP_NUM_TRANSITION_PRIZES * gapBetweenItems + DESKTOP_NUM_TRANSITION_PRIZES * cardWidth
				: 0;
			const fullDistance = hasAlreadySpun
				? (DESKTOP_NUM_TRANSITION_PRIZES + NUM_BUFFER_PRIZES) * gapBetweenItems +
					(DESKTOP_NUM_TRANSITION_PRIZES + NUM_BUFFER_PRIZES) * cardWidth
				: NUM_BUFFER_PRIZES * gapBetweenItems + NUM_BUFFER_PRIZES * cardWidth;

			// Calculate duration based on distance
			const fullDuration = getEndlessSpinDurationByDistance(startX, fullDistance, cardWidth);
			const reducedDuration = getEndlessSpinDurationByDistance(startXEndlessSpin, fullDistance, cardWidth);

			bufferSpinTimeline.current.kill();
			bufferSpinTimeline.current = gsap.timeline();

			isBufferTimelineSpinning.current = true;
			isWinningTimelineSpinning.current = false;

			oldCardWidth.current = cardWidth;
			oldContainerWidth.current = getContainerWidth(slotSpinContainerRef);
			let currentX = Number(gsap.getProperty(itemsWrapperRef.current, 'x'));

			if (progress) {
				const newContainerWidth = slotSpinContainerRef.current?.getBoundingClientRect().width ?? 0; // Scaled wrapper width
				const wrapperDistance = oldContainerWidth.current - newContainerWidth;
				const cardScalingFactor = cardWidth / oldCardWidth.current;
				currentX = oldStartingX.current * cardScalingFactor - wrapperDistance; // scale old starting x to new dimesions

				winningSpinTimeline.current.set(itemsWrapperEl, { x: currentX });
			}

			if (hasAlreadySpun) {
				bufferSpinTimeline.current
					.to(itemsWrapperEl, {
						x: -fullDistance,
						delay: 0.75,
						onStart: () => {
							updateOldStoredDimensions(cardWidth, currentX);

							currentEasing.current = 'none';
							currentEndlessBufferDistance.current = fullDistance;
							currentEndlessBufferDuration.current = fullDuration;
							currentEndlessBufferStartTime.current = new Date();
						},
						duration: fullDuration, // Initial full duration
						ease: 'none',
					})
					.set(itemsWrapperEl, { x: -startXEndlessSpin });
			}

			bufferSpinTimeline.current.to(itemsWrapperEl, {
				x: -fullDistance,
				onStart: () => {
					updateOldStoredDimensions(cardWidth, -fullDistance);

					currentEasing.current = 'none';
					currentEndlessBufferDistance.current = Math.abs(fullDistance - startXEndlessSpin);
					currentEndlessBufferDuration.current = reducedDuration;
					currentEndlessBufferStartTime.current = new Date();
				},
				onRepeat: () => {
					currentEndlessBufferStartTime.current = new Date();
				},
				duration: reducedDuration, // Initial full duration
				ease: 'none',
				repeat: -1, // Endless loop
			});

			if (progress) {
				const totalDuration = hasAlreadySpun ? fullDuration + reducedDuration : reducedDuration; // Sum of all durations
				const adjustedStartTime = totalDuration * progress;
				bufferSpinTimeline.current.seek(adjustedStartTime);
			}
		},
		[getEndlessSpinDurationByDistance, hasAlreadySpun, itemsWrapperRef, slotSpinContainerRef, updateOldStoredDimensions]
	);

	const startWinningSpin = useCallback(
		({ wonPrize, surroundingWonPrizes, progress }: SlotComponentInterface) => {
			// Helper to update stored dimensions

			const itemsWrapperEl = itemsWrapperRef.current;

			// Initialize animation settings
			const autoSpinEasing = isFastSpin ? autoSpinEasingOutFast : autoSpinEasingOutNormal;
			const firstSpinEasing = isFastSpin ? firstSpinEasingFast : firstSpinEasingNormal;

			// Calculate target position
			const baseTargetPosInCards = NUM_BUFFER_PRIZES + DESKTOP_NUM_PRE_SLOT_PRIZES + NUM_SURROUNDING_PRIZES / 2;
			const targetPosInCards = hasAlreadySpun
				? DESKTOP_NUM_TRANSITION_PRIZES + baseTargetPosInCards
				: baseTargetPosInCards;

			const targetX = calculateOffset({
				itemsWrapperRef,
				slotPickerRef,
				targetPos: targetPosInCards,
				scaledItemContainer: slotSpinContainerRef,
			});

			if (!targetX || !itemsWrapperEl || itemsWrapperEl.children.length < 1) {
				isBufferTimelineSpinning.current = false;
				isWinningTimelineSpinning.current = false;
				onSpinComplete({ wonPrize, surroundingWonPrizes });
				return;
			}

			isBufferTimelineSpinning.current = false;
			isWinningTimelineSpinning.current = true;

			winningSpinTimeline.current.kill();
			winningSpinTimeline.current = gsap.timeline();

			/*const { momentumDuration, cardsPerSecond } = calculateMomentumDetails({
                    currentX,
                    targetX,
                    cardWidth,
                });*/

			const cardWidth = getCardWidth(itemsWrapperEl.children[0] as HTMLElement, slotSpinContainerRef);

			let currentX = Number(gsap.getProperty(itemsWrapperRef.current, 'x'));

			const randomOff = calcRandomOffset(cardWidth);
			const spinDuration = getWinSpinDurationByDistance(currentX, targetX - randomOff, cardWidth);

			if (progress) {
				const newContainerWidth = slotSpinContainerRef.current?.getBoundingClientRect().width ?? 0; // Scaled wrapper width
				const wrapperDistance = oldContainerWidth.current - newContainerWidth;
				const cardScalingFactor = cardWidth / oldCardWidth.current;
				currentX = oldStartingX.current * cardScalingFactor - wrapperDistance; // scale old starting x to new dimesions

				winningSpinTimeline.current.set(itemsWrapperEl, { x: currentX });
			}

			updateOldStoredDimensions(cardWidth, currentX);

			winningSpinTimeline.current.to(itemsWrapperEl, {
				x: targetX - randomOff,
				delay: 0.5,
				duration: spinDuration,
				overwrite: 'auto',
				ease: hasAlreadySpun ? autoSpinEasing : firstSpinEasing,
			});
			winningSpinTimeline.current.to(itemsWrapperEl, {
				overwrite: 'auto',
				x: targetX + randomOff / 2,
				duration: 0.3,
				ease: 'none',
			});
			winningSpinTimeline.current.to(itemsWrapperEl, {
				x: targetX,
				duration: 0.2,
				overwrite: 'auto',
				ease: 'power1.out',
				onComplete: () => {
					handleSpinEnd(wonPrize, surroundingWonPrizes);
					bufferSpinTimeline.current.pause().kill();
				},
			});

			if (progress) {
				// eslint-disable-next-line no-magic-numbers
				const totalDuration = spinDuration + 0.3 + 0.2; // Sum of all durations
				const adjustedStartTime = totalDuration * progress;
				winningSpinTimeline.current.seek(adjustedStartTime);
			}
		},
		[
			getWinSpinDurationByDistance,
			handleSpinEnd,
			hasAlreadySpun,
			updateOldStoredDimensions,
			isFastSpin,
			itemsWrapperRef,
			onSpinComplete,
			slotPickerRef,
			slotSpinContainerRef,
		]
	);

	useLayoutEffect(() => {
		let isFirstCall = true;
		let debounceTimer: NodeJS.Timeout | null = null;
		let immediateCallTimer: NodeJS.Timeout | null = null;

		const handleResize = () => {
			if (!isWinningTimelineSpinning.current || !isBufferTimelineSpinning.current) {
				return;
			}

			if (isFirstCall) {
				isFirstCall = false;
				executeResizeActions();

				if (immediateCallTimer) {
					clearTimeout(immediateCallTimer);
				}
				immediateCallTimer = setTimeout(() => {
					isFirstCall = true;
				}, RESIZE_DEBOUNCE_TIME);
			} else {
				if (debounceTimer) {
					clearTimeout(debounceTimer);
				}
				debounceTimer = setTimeout(() => {
					executeResizeActions();
				}, RESIZE_DEBOUNCE_TIME);
			}
		};

		const executeResizeActions = () => {
			if (isBufferTimelineSpinning.current) {
				const progress = bufferSpinTimeline.current.progress();
				bufferSpinTimeline.current.pause();
				animateEndlessBufferSpin(progress);
			} else if (isWinningTimelineSpinning.current) {
				const progress = winningSpinTimeline.current.progress();
				if (progress !== 1 && wonPrize && slotPrizesSurroundingWon) {
					winningSpinTimeline.current.pause();
					bufferSpinTimeline.current.pause();

					startWinningSpin({ wonPrize, surroundingWonPrizes: slotPrizesSurroundingWon, progress });
				}
			}
		};

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
			if (debounceTimer) {
				clearTimeout(debounceTimer);
			}
			if (immediateCallTimer) {
				clearTimeout(immediateCallTimer);
			}
		};
	}, [startWinningSpin, slotPrizesSurroundingWon, wonPrize, animateEndlessBufferSpin]);

	useLayoutEffect(() => {
		if (isBufferTimelineSpinning.current) {
			const progress = bufferSpinTimeline.current.progress();
			bufferSpinTimeline.current.pause();
			animateEndlessBufferSpin(progress);
		} else if (isWinningTimelineSpinning.current) {
			const progress = winningSpinTimeline.current.progress();
			if (progress !== 1 && wonPrize && slotPrizesSurroundingWon) {
				winningSpinTimeline.current.pause();
				bufferSpinTimeline.current.pause();

				startWinningSpin({ wonPrize, surroundingWonPrizes: slotPrizesSurroundingWon, progress });
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isFullScreen]);

	const calculateInstantaneousVelocity = ({
		progress,
		totalDistance,
		easingFunction,
		rangeSteps = 10, // Number of steps to average over the initial range
		initialRange = 0.01, // Range over which to calculate the velocities
	}: CalculateInstantaneousVelocityParams) => {
		const delta = initialRange / rangeSteps; // Step size for the range
		let cumulativeVelocity = 0;

		// Loop through the range at small intervals
		for (let i = 0; i < rangeSteps; i++) {
			const currentProgress = progress + i * delta;
			if (currentProgress > 1) {
				break;
			} // Ensure we stay within valid progress bounds

			// Compute the derivative for the current step
			const derivative = (easingFunction(currentProgress + delta) - easingFunction(currentProgress)) / delta;

			// Calculate velocity for the current step
			const velocity = (derivative * totalDistance) / currentEndlessBufferDuration.current;
			cumulativeVelocity += velocity;
		}

		// Return the average velocity across the range
		return cumulativeVelocity / rangeSteps;
	};

	const calculateBufferSpinVelocity = useCallback(() => {
		const easingFunction =
			currentEasing.current === 'none'
				? linearEase // Use the predefined linear easing
				: gsap.parseEase(currentEasing.current);

		const totalDistance = currentEndlessBufferDistance.current;

		if (typeof easingFunction !== 'function') {
			console.error('Invalid easing function:', currentEasing.current);
			return 0;
		}

		const elapsedTime = (new Date().getTime() - currentEndlessBufferStartTime.current?.getTime()!) / 1000; // Convert ms to seconds
		const progress = Math.min(elapsedTime / currentEndlessBufferDuration.current, 1); // Ensure progress stays within 0-1

		const velocity = calculateInstantaneousVelocity({
			progress,
			totalDistance,
			easingFunction,
			rangeSteps: 10, // Number of initial steps
			initialRange: 0.5, // Range over the initial 5% of progress
		});

		return velocity;
	}, []);

	interface MomentumDetails {
		momentumDuration: number;
		cardsPerSecond: number;
	}

	// debug function
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const calculateMomentumDetails = ({
		currentX,
		targetX,
		cardWidth,
	}: {
		currentX: number;
		targetX: number;
		cardWidth: number;
	}): MomentumDetails => {
		const currentVelocity = calculateBufferSpinVelocity(); // Current momentum of the spin

		if (currentVelocity === 0) {
			throw new Error('velocity = 0');
		}

		const autoSpinEasing = isFastSpin ? autoSpinEasingOutFast : autoSpinEasingOutNormal;
		const firstSpinEasing = isFastSpin ? firstSpinEasingFast : firstSpinEasingNormal;

		const initialEasingFunction = hasAlreadySpun ? gsap.parseEase(autoSpinEasing) : gsap.parseEase(firstSpinEasing);

		// Calculate derivative at start
		// eslint-disable-next-line no-magic-numbers
		const derivativeAtStart = (initialEasingFunction(0.01) - initialEasingFunction(0)) / 0.01;

		// Distance to cover
		const distanceToCover = Math.abs(targetX - currentX);

		// Momentum duration
		const momentumDuration = distanceToCover / (currentVelocity / derivativeAtStart);

		// Cards per second
		const cardsPerSecond = distanceToCover / (momentumDuration * cardWidth);

		return { momentumDuration, cardsPerSecond };
	};

	return { startWinningSpin, animateBoxOpening, animateEndlessBufferSpin };
}
