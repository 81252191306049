import { faClockRotateLeft, faRightToBracket, faSpinner, faTruck, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLogout } from 'hooks/data/user/useLogout';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';

const ACCOUNT_LINKS = [
	{ label: 'My Account', icon: faUser, link: '/my-account/edit-profile' },
	{ label: 'My Orders', icon: faTruck, link: '/my-account/my-orders' },
	//{ label: 'Invite a Friend', icon: faUserGroup, link: 'invite-a-friend' },
	{ label: 'WinBox History', icon: faClockRotateLeft, link: 'my-account/winbox-history' },
];

export default function DesktopAccountLayout() {
	const location = useLocation();
	const navigate = useNavigate();

	const { mutate: logoutUser, isLoading: isLogoutLoading } = useLogout();

	function handleLogout() {
		navigate('/');
		logoutUser();
	}

	return (
		<div className="flex items-start justify-start w-full min-h-screen gap-x-[60px] relative">
			<div className="sticky top-desktop-top-padding flex flex-col gap-y-[6px]">
				{ACCOUNT_LINKS.map((el, index) => (
					<Link
						key={index}
						to={el.link}
						className={`overflow-hidden rounded-[10px] h-[52px] w-[193px] ${location.pathname.includes(el.link) ? 'text-white border-white pointer-events-none' : 'text-[rgba(255,255,2550.8)] hover:text-[rgba(255,255,255,0.9)] border-transparent hover:border-[rgba(255,255,255,0.1)]'}  border-[3px] `}
					>
						<div
							className="w-full h-full flex items-center gap-x-[8px] rounded-[8px] text-[15px] px-[18px] border-[2px] border-darkBlue"
							style={{
								background:
									'linear-gradient(331.41deg, rgba(35, 109, 244, 0.3) 6.78%, rgba(51, 240, 167, 0.3) 204.87%)',
							}}
						>
							<FontAwesomeIcon icon={el.icon} className="" />
							<span className="font-semibold">{el.label}</span>
						</div>
					</Link>
				))}
				<button
					onClick={handleLogout}
					disabled={isLogoutLoading}
					className={`overflow-hidden rounded-[10px] h-[52px] w-[193px] text-[rgba(255,255,255,0.8)] hover:text-[rgba(255,255,255,0.9)] border-transparent  border-[3px] `}
				>
					<div
						className="w-full h-full flex items-center gap-x-[8px] rounded-[8px] text-[15px] px-[18px] border-[2px] border-darkBlue"
						style={{
							background: 'linear-gradient(331.41deg, rgba(35, 109, 244, 0.3) 6.78%, rgba(51, 240, 167, 0.3) 204.87%)',
						}}
					>
						{isLogoutLoading ? (
							<FontAwesomeIcon icon={faSpinner} className="animate-spin" />
						) : (
							<>
								<FontAwesomeIcon icon={faRightToBracket} className="" />
								<span className="font-semibold">Logout</span>
							</>
						)}
					</div>
				</button>
			</div>
			<Outlet />
		</div>
	);
}
