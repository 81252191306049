import { AmountDisplayWithCurrency } from 'components/common/AmountDisplayWithCurrency';
import { DBOrderReturnItemPopulated } from 'interfaces/OrderReturns.interface';

interface Props {
	product: DBOrderReturnItemPopulated;
}

export function ProductInfo({ product }: Props) {
	return (
		<div className="flex flex-col justify-between h-[75px] w-full">
			<span className={`text-mrLootBlue text-[14px] font-bold w-full line-clamp-1`}>{product.itemId.name}</span>

			<div
				className={`bg-[rgba(41,129,233,0.2)] text-mrLootBlue rounded-full font-semibold flex items-center justify-center w-[35px] h-[20px] text-[14px]`}
			>
				x{product.quantity}
			</div>
			<AmountDisplayWithCurrency
				amount={product.userAvgPurchaseValue ?? product.itemId.price}
				imgStyle={{ height: '18px' }}
				amountStyle={{ fontSize: '16px', color: 'rgb(12 38 56)' }}
			/>
		</div>
	);
}
