import { easeInOutQuad } from './mathOperations';

/**
 * Smoothly scrolls an element horizontally to a specified target position.
 *
 * This function initiates a smooth horizontal scrolling animation of a given DOM element towards a target scroll
 * position over a specified duration. The easing function `easeInOutQuad` is utilized to calculate the scroll
 * position at each frame, creating an ease-in-out effect for the scroll animation.
 *
 * @param {HTMLElement} element - The DOM element to be scrolled.
 * @param {number} target - The target scrollLeft position to which the element should be scrolled.
 * @param {number} duration - The duration of the scroll animation in milliseconds.
 * @returns {Promise<void>} A promise that resolves once the scrolling animation has completed.
 */

export function smoothScrollLeft(element: HTMLElement, target: number, duration: number): Promise<void> {
	const start = element.scrollLeft;
	const distance = target - start;
	const startTime = performance.now();

	return new Promise((resolve) => {
		function step(timestamp: number) {
			const currentTime = timestamp - startTime;
			const scrollAmount = easeInOutQuad(currentTime, start, distance, duration);
			element.scrollLeft = scrollAmount;

			if (currentTime < duration) {
				requestAnimationFrame(step);
			} else {
				resolve(); // Resolves the Promise when the smooth scrolling is finished.
			}
		}

		requestAnimationFrame(step);
	});
}
