/* eslint-disable no-magic-numbers */
/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
import gsap from 'gsap';
import { useEffect, useLayoutEffect, useRef } from 'react';

interface AnimationRefsInterface {
	parentRef: React.RefObject<HTMLDivElement>;
	itemPreviewContainerRef: React.RefObject<HTMLDivElement>;
	boxCardRef: React.RefObject<HTMLDivElement>;
	itemPreviewWrapperRefA: React.RefObject<HTMLDivElement>;
	smallItemsPreviewRefA: React.RefObject<HTMLDivElement>;
	itemPreviewWrapperRefB: React.RefObject<HTMLDivElement>;
	smallItemsPreviewRefB: React.RefObject<HTMLDivElement>;
}

interface Props {
	refs: AnimationRefsInterface;
	numOfPreviewItemsA: number;
	numOfPreviewItemsB: number;
	nextBuffer: 'A' | 'B';
	totalItemNumber: number;
	preloadNextItems: (buffer: 'A' | 'B') => void;
}

export function useItemPreviewAnimations({
	refs,
	numOfPreviewItemsA,
	numOfPreviewItemsB,
	nextBuffer,
	preloadNextItems,
}: Props) {
	const timelineRefA = useRef(gsap.timeline({ paused: true }));
	const timelineRefB = useRef(gsap.timeline({ paused: true }));
	const isAnimationPlaying = useRef(false);

	useLayoutEffect(() => {
		if (isAnimationPlaying.current) {
			translatePreviewItemsViaDoubleBuffering(2, nextBuffer);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [nextBuffer]);

	function startItemPreview() {
		if (isAnimationPlaying.current) {
			return;
		}

		isAnimationPlaying.current = true;
		timelineRefA.current.clear();

		refs.smallItemsPreviewRefA.current &&
			timelineRefA.current.set(refs.smallItemsPreviewRefA.current.children[0], {
				borderColor: 'white',
			});

		timelineRefA.current.set(refs.smallItemsPreviewRefB.current, {
			opacity: 0,
			position: 'absolute',
		});
		timelineRefA.current.set(refs.itemPreviewWrapperRefB.current, {
			opacity: 0,
			position: 'absolute',
		});

		timelineRefA.current.to(refs.parentRef.current, {
			scale: 1.2,
			duration: 0.45,
			ease: 'power2.out',
			zIndex: 30,
		});
		timelineRefA.current.to(refs.boxCardRef.current, { opacity: 0, duration: 0.7, ease: 'power1.out' }, '<');
		timelineRefA.current.to(
			refs.itemPreviewContainerRef.current,
			{
				opacity: 1,
				duration: 0.7,
				ease: 'power1.out',
				onComplete: () => {
					if (numOfPreviewItemsA <= 4) {
						timelineRefA.current.pause();
						timelineRefB.current.play();
					} else {
						preloadNextItems('B');
					}
				},
			},
			'<'
		);

		// double buffering strategy
		if (numOfPreviewItemsA <= 4) {
			translatePreviewItemsBackAndForth();
		} else {
			translatePreviewItemsViaDoubleBuffering(0, 'A');
		}

		timelineRefA.current.play(0);
	}

	function translatePreviewItemsBackAndForth() {
		timelineRefB.current.clear();

		const items = refs.smallItemsPreviewRefA.current?.children;
		const boxWidth = refs.parentRef.current?.clientWidth || 0;

		if (!items) {
			onyTranslateBigItemPreviewWrapperBackAndForth();
			return;
		}

		for (let i = 1; i < numOfPreviewItemsA; i++) {
			if (i > 0) {
				timelineRefB.current.set(items[i - 1], { borderColor: 'transparent' });
			}

			timelineRefB.current.to(items[i], {
				borderColor: 'white',
				duration: 0.1,
			});

			timelineRefB.current.to(
				refs.itemPreviewWrapperRefA.current,
				{
					x: -i * boxWidth,
					duration: 0.7,
					ease: 'power1.inOut',
				},
				'<'
			);
		}

		for (let i = numOfPreviewItemsA - 2; i >= 0; i--) {
			if (i < numOfPreviewItemsA - 1) {
				timelineRefB.current.set(items[i + 1], {
					borderColor: 'transparent',
					delay: 0.1,
				});
			}

			timelineRefB.current.to(items[i], {
				borderColor: 'white',
				duration: 0.1,
			});

			timelineRefB.current.to(
				refs.itemPreviewWrapperRefA.current,
				{
					x: -i * boxWidth,
					duration: 0.7,
					ease: 'power1.inOut',
					onComplete: () => {
						if (i === 0) {
							gsap.delayedCall(0.1, () => {
								timelineRefB.current.restart();
							});
						}
					},
				},
				'<'
			);
		}
	}

	function onyTranslateBigItemPreviewWrapperBackAndForth() {
		const boxWidth = refs.parentRef.current?.clientWidth || 0;

		for (let i = 1; i < numOfPreviewItemsA; i++) {
			timelineRefB.current.to(refs.itemPreviewWrapperRefA.current, {
				x: -i * boxWidth,
				duration: 0.7,
				ease: 'power1.inOut',
			});
		}

		for (let i = numOfPreviewItemsA - 2; i >= 0; i--) {
			timelineRefB.current.to(refs.itemPreviewWrapperRefA.current, {
				x: -i * boxWidth,
				duration: 0.7,
				ease: 'power1.inOut',
				onComplete: () => {
					if (i === 0) {
						gsap.delayedCall(0.1, () => {
							timelineRefB.current.restart();
						});
					}
				},
			});
		}
	}

	function onlyTranslateBigPreviewItemsViaDoubleBuffering(startIndex: number, buffer: 'A' | 'B') {
		const { currentTimeline, currentBigItemPreviewEl, numOfPreviewItems } = getRefsForBuffer(buffer);

		for (let i = startIndex + 1; i < numOfPreviewItems; i++) {
			if (i === numOfPreviewItems - 1) {
				break;
			}

			currentTimeline.to(currentBigItemPreviewEl, {
				x: () => {
					const boxWidth = refs.parentRef.current?.clientWidth;
					return boxWidth ? -i * boxWidth : 0;
				},
				duration: i === 0 ? 0.3 : 0.7,
				ease: 'power1.inOut',
				onComplete: () => {
					if (i === numOfPreviewItems - 2) {
						switchToOtherBuffer(buffer);
						preloadNextItems(buffer);
					}
				},
			});
		}
	}

	function translatePreviewItemsViaDoubleBuffering(startIndex: number, buffer: 'A' | 'B') {
		const { currentTimeline, currentSmallItemsPreviewEl, currentBigItemPreviewEl, numOfPreviewItems } =
			getRefsForBuffer(buffer);

		if (!currentSmallItemsPreviewEl) {
			onlyTranslateBigPreviewItemsViaDoubleBuffering(startIndex, buffer);
			return;
		}

		const scaledWidth = currentSmallItemsPreviewEl.children[0].getBoundingClientRect().width;
		const smallItemCardWidth = startIndex === 0 ? scaledWidth : scaledWidth / 1.2; // divide by scale of 1.2
		const spaceBetweenSmallCards = 8;

		for (let i = startIndex + 1; i < numOfPreviewItems; i++) {
			if (i === numOfPreviewItems - 1) {
				break;
			}

			if (currentSmallItemsPreviewEl.children.length < i) {
				break;
			}

			currentTimeline.set(currentSmallItemsPreviewEl.children[i - 1], {
				borderColor: 'transparent',
			});
			currentTimeline.to(currentSmallItemsPreviewEl.children[i], {
				borderColor: 'white',
				duration: 0.1,
			});

			currentTimeline.to(
				currentBigItemPreviewEl,
				{
					x: () => {
						const boxWidth = refs.parentRef.current?.clientWidth;
						return boxWidth ? -i * boxWidth : 0;
					},
					duration: i === 0 ? 0.3 : 0.7,
					ease: 'power1.inOut',
				},
				'<'
			);

			if (i > 2) {
				currentTimeline.to(
					currentSmallItemsPreviewEl,
					{
						x: (-i + 2) * (smallItemCardWidth + spaceBetweenSmallCards),
						duration: 0.7,
						ease: 'power1.inOut',
						onComplete: () => {
							if (i === numOfPreviewItems - 2) {
								gsap.set(currentSmallItemsPreviewEl.children[i], {
									borderColor: 'transparent',
								});
								switchToOtherBuffer(buffer);
								preloadNextItems(buffer);
							}
						},
					},
					'<'
				);
			}
		}
	}

	function switchToOtherBuffer(currentBuffer: 'A' | 'B') {
		const {
			currentTimeline,
			nextTimeline,
			currentSmallItemsPreviewEl,
			currentBigItemPreviewEl,
			nextBigItemPreviewEl,
			nextSmallItemsPreviewEl,
		} = getRefsForBuffer(currentBuffer);

		const boxWidth = refs.parentRef.current?.clientWidth;
		if (boxWidth) {
			currentTimeline.set(nextBigItemPreviewEl, { x: -2 * boxWidth });
			currentTimeline.set(nextSmallItemsPreviewEl, { x: 0 });
		}

		if (nextSmallItemsPreviewEl?.children) {
			currentTimeline.set(nextSmallItemsPreviewEl?.children[2], {
				borderColor: 'white',
			});
		}

		currentTimeline.set(nextBigItemPreviewEl, {
			opacity: 1,
			position: 'static',
		});
		currentTimeline.set(nextSmallItemsPreviewEl, {
			opacity: 1,
			position: 'static',
		});

		currentTimeline.set(currentBigItemPreviewEl, {
			opacity: 0,
			position: 'absolute',
		});
		currentTimeline.set(currentSmallItemsPreviewEl, {
			opacity: 0,
			position: 'absolute',
			onComplete: () => {
				currentTimeline.pause();
				nextTimeline.play();
			},
		});
	}

	function cancelItemPreview() {
		isAnimationPlaying.current = false;

		timelineRefA.current.pause();
		timelineRefA.current.clear();
		timelineRefB.current.pause();
		timelineRefB.current.clear();

		gsap.to(refs.parentRef.current, {
			scale: 1,
			duration: 0.5,
			ease: 'power1.out',
			zIndex: 10,
		});
		gsap.to(refs.itemPreviewContainerRef.current, {
			opacity: 0,
			duration: 0.3,
			ease: 'power1.out',
		});
		gsap.to(refs.boxCardRef.current, {
			opacity: 1,
			duration: 0.5,
			ease: 'power1.out',
		});

		gsap.set(refs.smallItemsPreviewRefB.current, {
			opacity: 0,
			position: 'absolute',
			delay: 0.2,
		});
		gsap.set(refs.itemPreviewWrapperRefB.current, {
			opacity: 0,
			position: 'absolute',
			delay: 0.2,
		});
		gsap.set(refs.smallItemsPreviewRefA.current, {
			clearProps: 'all',
			delay: 0.2,
		});
		gsap.set(refs.itemPreviewWrapperRefA.current, {
			clearProps: 'all',
			delay: 0.2,
		});

		Array.from({ length: numOfPreviewItemsA }).forEach((_, index) => {
			if (!refs.smallItemsPreviewRefA.current) {
				return;
			}
			gsap.set(refs.smallItemsPreviewRefA.current.children[index], {
				borderColor: index === 0 ? 'white' : 'transparent',
			});
		});

		Array.from({ length: numOfPreviewItemsB }).forEach((_, index) => {
			if (!refs.smallItemsPreviewRefB.current) {
				return;
			}
			gsap.set(refs.smallItemsPreviewRefB.current.children[index], {
				borderColor: index === 0 ? 'white' : 'transparent',
			});
		});
	}

	function getRefsForBuffer(buffer: 'A' | 'B') {
		return {
			currentTimeline: buffer === 'A' ? timelineRefA.current : timelineRefB.current,
			nextTimeline: buffer === 'A' ? timelineRefB.current : timelineRefA.current,
			currentSmallItemsPreviewEl:
				buffer === 'A' ? refs.smallItemsPreviewRefA.current : refs.smallItemsPreviewRefB.current,
			currentBigItemPreviewEl:
				buffer === 'A' ? refs.itemPreviewWrapperRefA.current : refs.itemPreviewWrapperRefB.current,
			nextSmallItemsPreviewEl: buffer === 'A' ? refs.smallItemsPreviewRefB.current : refs.smallItemsPreviewRefA.current,
			nextBigItemPreviewEl: buffer === 'A' ? refs.itemPreviewWrapperRefB.current : refs.itemPreviewWrapperRefA.current,
			numOfPreviewItems: buffer === 'A' ? numOfPreviewItemsA : numOfPreviewItemsB,
		};
	}

	useEffect(() => {
		const currentTimelineA = timelineRefA.current;
		const currentTimelineB = timelineRefB.current;
		return () => {
			currentTimelineA.kill(); // Use the copied value in the cleanup function
			currentTimelineB.kill(); // Use the copied value in the cleanup function
		};
	}, []);

	return { startItemPreview, cancelItemPreview };
}
