import { Icon } from '@iconify/react';
import { AnimatedBtn } from 'components';
import { AmountDisplayWithCurrency } from 'components/common/AmountDisplayWithCurrency';
import { PseudoImgMirror } from 'components/common/PseudoImgMirror';
import { useFadeInAnimation } from 'hooks/animations/useFadeInAnimation';
import { MrLootTurnedHead } from 'pages/OpenBox/assets';
import { useBoxOpeningStoreDesktop } from 'pages/OpenBox/store/useBoxOpeningStoreDesktop';
import { useRef } from 'react';
import { useAppStore } from 'store/useAppStore';
import { shallow } from 'zustand/shallow';

import { CLOUDFRONT_IMG_URL, rarities } from '../../../../../../../constants';
import { useAddToInventoryBtn } from './hooks/useAddToInventoryBtn';
import { useSellBtn } from './hooks/useSellBtn';
import { ItemInfoDisplay } from './ItemInfoDisplay';
import { WinScreenBackground } from './WinScreenBackground';

interface Props {
	hideSellBtn: boolean;
}

export default function WinScreen({ hideSellBtn }: Props) {
	const { wonPrize, isFullScreen } = useBoxOpeningStoreDesktop();

	const parentRef = useRef<HTMLDivElement>(null);
	const prizeImgRef = useRef<HTMLImageElement>(null);
	const contentWrapperRef = useRef<HTMLImageElement>(null);
	const pseudoImageRef = useRef<HTMLImageElement>(null);

	useFadeInAnimation({ ref: parentRef, animationTime: 0.4 });
	useFadeInAnimation({ ref: prizeImgRef, animationTime: 0.4 });

	const { handleSell, handleSellHover, handleSellLeave, isSellLoading } = useSellBtn({ parentRef, prizeImgRef });
	const { handleAddToInventory } = useAddToInventoryBtn({ contentWrapperRef, parentRef, prizeImgRef });

	const addNewWonItemId = useAppStore((state) => state.addNewWonItemId, shallow);

	return (
		<>
			<img
				ref={prizeImgRef}
				src={`${CLOUDFRONT_IMG_URL}/${wonPrize?.data.imageUrls.qualityHigh}`}
				alt={wonPrize?.data.name}
				className="absolute left-[25%] md:left-[35%] top-[50%] -translate-y-1/2 -translate-x-1/2 z-30 h-[170px] md:h-[200px] lgx:h-[220px] xl:h-[250px] w-auto max-w-[35%] object-scale-down"
			/>
			{wonPrize && (
				<div
					ref={parentRef}
					className={`bg-[#0C0F23] rounded-[20px] relative w-full h-full ${!isFullScreen && 'rounded-[21px]'} z-[25]`}
				>
					<WinScreenBackground parentRef={parentRef} />

					{/* Content */}
					<div
						ref={contentWrapperRef}
						className="relative z-30 ml-auto text-white h-full w-[75%] md:w-[65%] flex justify-end items-center pr-[20px] md:pr-[40px] overflow-hidden rounded-[24px]"
					>
						<div className="absolute w-[calc(100%-20px)] md:w-[calc(100%-40px)] top-[50%] -translate-y-1/2 left-0 bg-[rgba(26,31,63,0.50)] backdrop-blur-[5px] rounded-[24px] h-[300px] md:h-[350px] lgx:h-[400px] xl:h-[450px]" />

						<img
							alt=""
							src={MrLootTurnedHead}
							className="absolute z-10 pointer-events-none right-[0px] top-[0px] object-scale-down opacity-40 h-full translate-y-[20%] translate-x-[25%]"
						/>

						<div className="z-10 flex justify-between gap-x-[20px] w-full h-[300px] md:h-[350px] lgx:h-[400px] xl:h-[450px] py-[15px] pr-[20px]">
							<div className="h-[170px] md:h-[200px] lgx:h-[220px] xl:h-[250px]">
								<PseudoImgMirror imgRef={prizeImgRef} widthFactor={0.5} />
							</div>

							<div className="flex flex-col space-y-3 w-full items-center sm:items-start justify-between ">
								<ItemInfoDisplay item={wonPrize?.data} pseudoImageRef={pseudoImageRef} />

								<div className="flex flex-col xs:flex-row items-center space-y-2 xs:space-y-0 xs:space-x-4 ">
									{!wonPrize.data.isRedeemable && (
										<AnimatedBtn
											label="ADD TO INVENTORY"
											onClick={() => {
												addNewWonItemId(wonPrize.data.inventoryId ?? '');
												handleAddToInventory();
											}}
											borderColor={rarities[wonPrize?.data.rarity].primBorderColor}
											isDeactived={wonPrize?.data.isRedeemable}
											hoverContent={<Icon icon="material-symbols:inventory-2-outline" height={20} />}
										/>
									)}

									{(wonPrize.data.isRedeemable || !hideSellBtn) && (
										<AnimatedBtn
											label={wonPrize.data.isRedeemable ? 'REDEEM' : 'SELL'}
											onClick={() => {
												handleSell();
											}}
											isLoading={isSellLoading}
											hoverContent={
												<AmountDisplayWithCurrency
													amount={wonPrize.data.price}
													imgStyle={{ height: '16px' }}
													amountStyle={{ fontSize: '13px' }}
												/>
											}
											background={rarities[wonPrize?.data.rarity].primBtnGradient}
											borderColor={rarities[wonPrize.data.rarity].primBorderColor}
											shadowColor={rarities[wonPrize.data.rarity].color50}
											shadowHoverColor={rarities[wonPrize.data.rarity].color}
											onHover={handleSellHover}
											onLeave={handleSellLeave}
										/>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
}
