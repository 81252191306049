import { ItemOrderStatus, OrderDbItemInterface } from 'interfaces/OrderInterfaces';
import { isOrderItemRefunded } from 'pages/MyOrders/orderUtils';

import { CLOUDFRONT_IMG_URL } from '../../../../../../constants';
import { ProductOptionsInfo } from '../ProductOptionsInfo';
import { OutstandingInvoice } from './OutstandingInvoiceInfo';
import { ProductCommonInfoRow } from './ProductCommonInfoRow';
import { ProductNoticeInfo } from './ProductNoticeInfo';

interface Props {
	orderId: string;
	orderedProduct: OrderDbItemInterface;
	colorTheme?: 'blue' | 'white';
}

export function ProductInfoRow({ orderedProduct, orderId, colorTheme = 'white' }: Props) {
	const showOutstandingInvoiceInfo =
		orderedProduct.status === ItemOrderStatus.AWAITING_PAYMENT && !!orderedProduct.outstandingInvoiceAmount;

	return (
		<div className="flex flex-col w-full gap-y-[5px]">
			<ProductNoticeInfo product={orderedProduct} />
			<div
				className={`grid ${isOrderItemRefunded(orderedProduct.status) && 'opacity-50 pointer-events-none'} grid-cols-[55px_auto] gap-x-[15px] items-center w-full`}
			>
				<div className="flex items-center justify-center bg-white h-[55px] w-[55px] p-[6px]">
					<img
						src={`${CLOUDFRONT_IMG_URL}/${orderedProduct.itemId?.imageUrls?.qualityMedium}`}
						className={`${colorTheme === 'white' ? 'h-[80%]' : 'h-[100%]'} object-contain`}
						alt={orderedProduct.itemId.name}
					/>
				</div>

				<ProductCommonInfoRow product={orderedProduct} colorTheme={colorTheme} />
			</div>

			<div className={`w-full ${colorTheme === 'white' ? 'text-white' : 'text-black'}`}>
				{orderedProduct.options && orderedProduct.options.length > 0 && (
					<ProductOptionsInfo options={orderedProduct.options} />
				)}
			</div>

			{!!showOutstandingInvoiceInfo && (
				<OutstandingInvoice
					outstandingAmount={orderedProduct.outstandingInvoiceAmount!}
					orderId={orderId}
					orderItemId={orderedProduct._id}
					colorTheme={colorTheme}
				/>
			)}
		</div>
	);
}
