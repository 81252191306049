import { ImageQualityUrls } from './ImageQuali.interface';
import { UnboxedHistoryItemInterface } from './UnboxedHistoryInterface';

export enum ItemRarity {
	LIGHT_BLUE = 'lightBlue',
	BLUE = 'blue',
	PURPLE = 'purple',
	PINK = 'pink',
	RED = 'red',
	GOLD = 'gold',
}

export enum ItemOptionProperty {
	SIZE = 'size',
	COLOR = 'color',
	CUSTOM = 'custom',
}

export interface ItemOption {
	_id?: string;
	optionProperty: ItemOptionProperty;
	values: string[];
	label: string;
}

export interface DeliveryTimeInterface {
	STANDARD: string;
	EXPRESS: string;
}

export interface ItemInterface {
	_id: string;
	name: string;
	brandName?: string;
	rarity: ItemRarity;
	price: number;
	tags: string[];
	description: string;
	options?: ItemOption[];
	deliveryTime?: DeliveryTimeInterface;
	isRedeemable: boolean;
	createdAt?: Date;
	imageUrls: ImageQualityUrls;
	showcaseImgUrls?: string[];
}

export enum PrizeType {
	ITEM = 'item',
	LOOTGAMES = 'lootgame',
}

export interface PrizeInterface {
	data: UnboxedHistoryItemInterface;
	type: PrizeType;
	key: string;
}

export function isItem(item: ItemInterface | string): item is ItemInterface {
	return (item as ItemInterface) !== undefined && (item as ItemInterface).hasOwnProperty('_id');
}
