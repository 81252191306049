import { faHourglass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TruckFastIcon } from 'assets/icons';
import { gsap } from 'gsap';
import {
	EXPRESS_SHIP_DAYS_MAX,
	EXPRESS_SHIP_DAYS_MIN,
	STANDARD_SHIP_DAYS_MAX,
	STANDARD_SHIP_DAYS_MIN,
} from 'pages/checkout/checkout.constant';
import { useCheckoutStore } from 'pages/checkout/store/CheckoutStore';
import { useEffect, useRef } from 'react';

function getFormattedDate(daysToAdd: number): string {
	const today = new Date();
	today.setDate(today.getDate() + daysToAdd);

	const monthNames = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December',
	];
	const date = today.getDate();
	const month = monthNames[today.getMonth()];

	const getDateSuffix = (date: number) => {
		const j = date % 10,
			k = date % 100;
		// eslint-disable-next-line no-magic-numbers
		if (j === 1 && k !== 11) {
			return 'st';
		}
		// eslint-disable-next-line no-magic-numbers
		if (j === 2 && k !== 12) {
			return 'nd';
		}
		// eslint-disable-next-line no-magic-numbers
		if (j === 3 && k !== 13) {
			return 'rd';
		}
		return 'th';
	};

	const dateSuffix = getDateSuffix(date);

	return `${month} ${date}${dateSuffix}`;
}

export function MobileShippingTimeBanner() {
	const { isExpressShipping, orderItems } = useCheckoutStore();
	const headingRef1 = useRef<HTMLDivElement>(null);
	const headingRef2 = useRef<HTMLDivElement>(null);

	const minDays = isExpressShipping ? EXPRESS_SHIP_DAYS_MIN : STANDARD_SHIP_DAYS_MIN;
	const maxDays = isExpressShipping ? EXPRESS_SHIP_DAYS_MAX : STANDARD_SHIP_DAYS_MAX;

	useEffect(() => {
		const delay = 3; // Display time for each heading
		const duration = 1; // Transition duration

		const tl = gsap.timeline({ repeat: -1 });

		gsap.set(headingRef1.current, { opacity: 1 });
		gsap.set(headingRef2.current, { opacity: 0 });

		// First heading fades out
		tl.to(headingRef1.current, { opacity: 0, duration })
			// Second heading fades in, overlapping with the previous fade out
			.to(headingRef2.current, { opacity: 1, duration }, `-=${duration}`)
			// Second heading stays visible
			.to(headingRef2.current, { opacity: 1, duration: 0, delay })
			// Second heading fades out
			.to(headingRef2.current, { opacity: 0, duration })
			// First heading fades in, overlapping with the previous fade out
			.to(headingRef1.current, { opacity: 1, duration }, `-=${duration}`)
			// First heading stays visible
			.to(headingRef1.current, { opacity: 1, duration: 0, delay });

		return () => {
			tl.kill(); // Cleanup the GSAP timeline when the component unmounts
		};
	}, []);

	return (
		<div className="w-full h-[54px] bg-mrLootBlue text-white relative">
			<div ref={headingRef1} className="h-full flex items-center justify-center space-x-[15px]">
				{orderItems && orderItems.length > 0 ? <TruckFastIcon /> : <FontAwesomeIcon icon={faHourglass} />}
				<h3 className="text-[15px] font-semibold">
					Arrives by {getFormattedDate(minDays)} to {getFormattedDate(maxDays)}
				</h3>
			</div>

			<div
				ref={headingRef2}
				className="absolute left-0 w-full top-0 h-full flex items-center justify-center space-x-[15px]"
			>
				{orderItems && orderItems.length > 0 ? <TruckFastIcon /> : <FontAwesomeIcon icon={faHourglass} />}
				<h3 className="text-[15px] font-semibold ">Enjoy free shipping for orders over $50</h3>
			</div>
		</div>
	);
}
