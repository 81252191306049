import { gsap } from 'gsap';
import { BoxStack } from 'pages/OpenBox/store/useBoxOpeningStoreMobile';
import { MutableRefObject, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

interface UseBoxStackAnimationProps {
	boxStack: BoxStack[];
	boxRefs: MutableRefObject<(HTMLDivElement | null)[]>;
	numRenderedStacks: number;
}

const ANIMATION_DURATION = 0.4;
const STACK_OFFSET_PERCENT = 25;

export const useSwitchToNewBoxInStack = ({ boxStack, boxRefs, numRenderedStacks }: UseBoxStackAnimationProps) => {
	const [isSlideAnimationComplete, setIsSlideAnimationComplete] = useState(false);
	const previousStackLength = useRef(boxStack.length);

	const slicedBoxStack = useMemo(() => boxStack.slice(-numRenderedStacks), [boxStack, numRenderedStacks]); // because we render only 2 stacks cause of performance

	// Function to calculate the translation values for each box in the stack
	const calculateTranslation = useCallback((index: number, length: number) => {
		const isLastElement = index === length - 1;
		return length === 1 ? 0 : isLastElement ? -index * 100 : -index * 100 - STACK_OFFSET_PERCENT;
	}, []);

	// Initial setup for box positions
	const setInitialBoxPositions = useCallback(() => {
		slicedBoxStack.forEach((_, index) => {
			const xPercentTranslation = boxStack.length > previousStackLength.current ? 0 : -index * 100;
			// setting opacity back to 1 in every case but to 0 for first element when we went back in history
			// otherwise previous boxInfo overlay would be visible when user dissmisses (via drag) current overlay
			const opacity =
				boxStack.length > previousStackLength.current || slicedBoxStack.length === 1 ? 1 : index === 0 ? 0 : 1;
			gsap.set(boxRefs.current[index], {
				xPercent: xPercentTranslation,
				x: 0,
				opacity: opacity,
			});
		});
	}, [slicedBoxStack, boxStack.length, previousStackLength, boxRefs]);

	// Animate the boxes to their new positions
	const animateBoxes = useCallback(
		(stackLength: number) => {
			setIsSlideAnimationComplete(false);

			slicedBoxStack.forEach((_, index) => {
				const translation = calculateTranslation(index, stackLength);
				gsap.to(boxRefs.current[index], {
					xPercent: translation,
					duration: ANIMATION_DURATION,
					ease: index === stackLength - 1 ? 'power1.inOut' : 'none',
					onComplete: () => {
						if (index === stackLength - 1) {
							setIsSlideAnimationComplete(true);
						} else if (index === 0 && stackLength > 1) {
							gsap.set(boxRefs.current[index], { opacity: 0 });
						}
					},
				});
			});
		},
		[slicedBoxStack, boxRefs, calculateTranslation]
	);

	// Set initial positions on mount and whenever the boxStack changes
	useLayoutEffect(() => {
		setInitialBoxPositions();
	}, [setInitialBoxPositions]);

	// Run the animation whenever the boxStack length increases
	useEffect(() => {
		const stackLength = boxStack.length;

		if (stackLength > previousStackLength.current) {
			if (stackLength === 1) {
				setIsSlideAnimationComplete(true);
			} else {
				animateBoxes(slicedBoxStack.length);
			}
		}

		previousStackLength.current = stackLength;
	}, [slicedBoxStack, previousStackLength, animateBoxes, boxStack.length]);

	return { isSlideAnimationComplete };
};
