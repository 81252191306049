import { SeparatorWithText } from 'components/Separator/SeparatorWithText';
import OAuthBtnsHorizontal from './components/OAuthBtnsHorizontal';
import { MobileLoginForm } from './MobileLoginForm';
import { Link } from 'react-router-dom';

export default function MobileLogin() {
	return (
		<div className="flex flex-col gap-y-[30px] pb-[40px]">
			<MobileLoginForm />

			<SeparatorWithText
				text="Or continue"
				textLine2="with"
				propStyle={{ color: 'rgba(255,255,255,0.6)', marginTop: '15px', fontFamily: 'poppins' }}
			/>

			<OAuthBtnsHorizontal isRequestLoading={false} />

			<span className="text-white text-[15px] text-center pb-[50px] flex items-center justify-center">
				<span className="font-poppins">Don't have an account?</span>
				<Link to="/auth/register" className="text-[17px] font-bold ml-[5px] font-poppins">
					Register
				</Link>
			</span>
		</div>
	);
}
