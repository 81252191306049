import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useUserContext } from 'hooks';
import { useRef } from 'react';

interface Props {
	value: string;
	onChange: (v: string) => void;
}

export function AccountEmailInput({ onChange, value }: Props) {
	const { user } = useUserContext();
	const EmailInputRef = useRef<HTMLInputElement>(null);

	const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
		if (event.key === 'Enter' || event.key === ' ') {
			EmailInputRef.current?.focus();
			event.preventDefault();
		}
	};

	return (
		<div
			className="w-[350px] rounded-[10px] border-[2px] border-white hover:border-darkBlue focus-within:border-darkBlue overflow-hidden h-[48px] cursor-text"
			role="button"
			tabIndex={0}
			onClick={() => EmailInputRef.current?.focus()}
			onKeyDown={handleKeyDown}
		>
			<div
				className={`relative w-full h-full border-[2px] border-darkBlue flex items-center justify-between ${user?.contactMail?.value === value && user.contactMail.verified ? 'text-green-300' : user?.contactMail?.value === value ? 'text-[rgba(255,255,255,0.7)]' : 'text-white'} text-[15px] px-[15px] rounded-[8px]`}
				style={{
					background: 'linear-gradient(331.41deg, rgba(35, 109, 244, 0.3) 6.78%, rgba(51, 240, 167, 0.3) 204.87%)',
				}}
			>
				<span className="text-[rgba(255,255,255,0.7)]">E-Mail</span>
				<input
					placeholder="example@gmail.com"
					ref={EmailInputRef}
					type="email"
					className="h-full bg-transparent text-left px-0 w-max outline-none ml-auto"
					value={value}
					onChange={(e) => onChange(e.target.value)}
				/>
				{value === user?.contactMail?.value && user?.contactMail?.verified && (
					<FontAwesomeIcon icon={faCheck} className="text-green-300 px-2" />
				)}
			</div>
		</div>
	);
}
