import { RefObject, useCallback, useEffect, useState } from 'react';

interface Props {
	imgRef: RefObject<HTMLImageElement>;
	widthFactor?: number;
}

export function PseudoImgMirror({ imgRef, widthFactor = 1 }: Props) {
	const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

	const updateDimensions = useCallback(() => {
		if (imgRef.current) {
			const { height, width } = imgRef.current;
			setDimensions({ width, height });
		}
	}, [imgRef]);

	useEffect(() => {
		const img = imgRef.current;
		if (!img) {
			return;
		}

		// Use ResizeObserver to detect size changes
		const resizeObserver = new ResizeObserver(updateDimensions);
		resizeObserver.observe(img);

		// Handle image load event
		img.addEventListener('load', updateDimensions);

		updateDimensions();

		return () => {
			resizeObserver.disconnect();
			img.removeEventListener('load', updateDimensions);
		};
	}, [imgRef, updateDimensions]);

	return (
		<div
			className=""
			style={{
				height: dimensions.height,
				minHeight: dimensions.height,
				width: dimensions.width * widthFactor,
				minWidth: dimensions.width * widthFactor,
			}}
		/>
	);
}
