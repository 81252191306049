import { faChevronRight, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PrimaryBtn } from 'components/common/buttons/PrimaryBtn';
import { ToastErrorNotification } from 'components/toasts/ToastErrorNotification';
import { ToastInfoNotification } from 'components/toasts/ToastInfoNotification';
import { ToastMultipleSoldNotification } from 'components/toasts/ToastMultipleSoldNotification';
import { ToastSoldNotification } from 'components/toasts/ToastSoldNotification';
import { useSellInventoryItems, useUserContext } from 'hooks';
import { usePaymentTransactions } from 'hooks/data/transactions/usePaymentTransactions';
import { useFloatingActionBtnAnimation } from 'pages/inventory/hooks/useFloatingActionBtnAnimation';
import { useInventoryStore } from 'pages/inventory/store/InventoryStore';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

import { CheckoutButton } from './CheckoutButton';
import { SellButton } from './SellButton';

export function FloatingActionMenu() {
	const { selectedItemBalance, resetSelectedUserItems, selectedUserItems, hasRedeemableOnlyItems } = useInventoryStore(
		(state) => ({
			selectedItemBalance: state.selectedItemBalance,
			resetSelectedUserItems: state.resetSelectedUserItems,
			selectedUserItems: state.selectedUserItems,
			hasRedeemableOnlyItems: state.hasRedeemableOnlyItems,
		})
	);

	const { load: reloadUser, user } = useUserContext();
	const navigate = useNavigate();

	const { data: paymentHistory } = usePaymentTransactions();

	const {
		isActionMenuVisible,
		actionBtnRef,
		actionBtnContentRef,
		actionBtnXMarkRef,
		sellButtonRef,
		checkoutButtonRef,
		toggleButtonAnimation,
	} = useFloatingActionBtnAnimation();

	const { mutate: sellUserItems, isLoading: isSellRequestLoading } = useSellInventoryItems({
		onSuccess: () => {
			const itemsArray = Array.from(selectedUserItems.values());
			const highestPricedItem = itemsArray.reduce((prev, current) => {
				return prev.itemId.price > current.itemId.price ? prev : current;
			});
			if (selectedUserItems.size === 1) {
				toast(<ToastSoldNotification item={highestPricedItem.itemId} />);
			} else if (selectedUserItems.size > 1) {
				toast(
					<ToastMultipleSoldNotification
						displayedItem={highestPricedItem.itemId}
						totalValue={selectedItemBalance}
						itemCount={selectedUserItems.size}
					/>
				);
			}

			reloadUser();
			resetSelectedUserItems();
			window.scroll({ behavior: 'smooth', top: 0 });
			if (isActionMenuVisible.current) {
				toggleButtonAnimation();
			}
		},
		onError: () => {
			toast(
				<ToastErrorNotification message="Oops! Something went wrong while selling the items. Please try again.'" />
			);
		},
	});

	function handleCheckout() {
		if (hasRedeemableOnlyItems) {
			toast(
				<ToastErrorNotification message="Selection includes items that can't be sent home. Sell or adjust your selection." />
			);
			return;
		}

		if (!user?.contactMail?.verified) {
			toast(<ToastErrorNotification message="Please verify your mail address first" />);
			return;
		}
		navigate('/checkout', { state: { inventoryItemIds: [...selectedUserItems.keys()] } });
	}

	function handleSell() {
		if (!paymentHistory || paymentHistory.length === 0) {
			toast(<ToastInfoNotification message="Deposit to unlock all features" />);
			return;
		}
		sellUserItems([...selectedUserItems.keys()]);
	}

	const areAllBtnDisabled = selectedUserItems.size === 0 || isSellRequestLoading;

	return (
		<>
			<div className="sticky flex flex-col space-y-[15px] justify-end items-end z-30 right-2 bottom-[85px] space-x-[7px] px-[4px] pr-[10px]">
				<CheckoutButton
					ref={checkoutButtonRef}
					isDisabled={areAllBtnDisabled}
					onClick={handleCheckout}
					style={{ opacity: hasRedeemableOnlyItems ? '50%' : undefined }}
				/>

				<SellButton
					isLoading={isSellRequestLoading}
					sellPrice={selectedItemBalance}
					ref={sellButtonRef}
					isDisabled={areAllBtnDisabled}
					onClick={handleSell}
				/>

				<PrimaryBtn
					onClick={toggleButtonAnimation}
					renderEllipse={false}
					ref={actionBtnRef}
					style={{ width: '145px', height: '58px' }}
					content={
						<>
							<div
								ref={actionBtnContentRef}
								className="absolute-center px-[14px] w-full space-x-[8px] flex items-center justify-between"
							>
								<span className="px-[9px] h-[33px] flex items-center justify-center bg-[rgba(255,255,255,0.2)] text-white font-extrabold rounded-[10px] text-[15px]">
									{selectedUserItems.size}
								</span>
								<span className="font-extrabold text-[14px] text-white">Actions</span>
								<FontAwesomeIcon icon={faChevronRight} className="text-white" />
							</div>
							<FontAwesomeIcon
								ref={actionBtnXMarkRef}
								icon={faXmark}
								className="absolute-center text-white text-[18px] opacity-0"
							/>
						</>
					}
				/>
			</div>
		</>
	);
}
