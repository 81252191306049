import { IconEditNote } from 'assets/icons/IconEditNote';
import { useOutsideClick, useWindowDimensions } from 'hooks';
import { useRef, useState } from 'react';

import { screens } from '../../../../constants';

interface Props {
	note?: string;
	disabled?: boolean;
	onNoteChange?: (note: string) => void;
}

export function NoteField({ note, disabled, onNoteChange }: Props) {
	const parentRef = useRef<HTMLDivElement>(null);
	const [isExpanded, setIsExpanded] = useState(false);

	const { screenWidth } = useWindowDimensions();

	useOutsideClick(parentRef, () => {
		setIsExpanded(false);
	});

	return (
		<div className="flex items-center space-x-[8px]">
			<div
				ref={parentRef}
				className={`${isExpanded ? 'w-full' : 'w-fit'} text-white space-x-[8px] rounded-[4px] border-[1px] border-white flex items-center h-[22px]`}
			>
				<button
					disabled={disabled}
					onClick={() => setIsExpanded((prev) => !prev)}
					className={`${isExpanded ? 'border-y-[1px] border-r-[1px] border-white min-w-[22px]' : 'w-[22px]'} ${!disabled && 'hover:bg-white01'} relative top-0 box-content flex items-center justify-center h-full rounded-[4px]`}
				>
					<IconEditNote width={16} height={16} />
				</button>
				{isExpanded && (
					<input
						value={note}
						onChange={(e) => onNoteChange && onNoteChange(e.target.value)}
						max={255}
						className="text-[11px] w-full h-full outline-none bg-transparent pr-[8px]"
						placeholder={screenWidth > screens.md.width ? 'Couln’t find your size or color? Add a note' : 'add Note'}
					/>
				)}
			</div>
			{!isExpanded && note && <span className="text-[#6C6F6C] text-[11px] line-clamp-2">{note}</span>}
		</div>
	);
}
