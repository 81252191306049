import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MobileSearchInput } from 'components/common/inputs/MobileSearchInput';
import { useCallback, useState } from 'react';

import { FilterSlideupSheet } from './components/FilterSlideupSheet';
import { GridSearchBoxes } from './components/GridSearchBoxes';
import { SearchKeywordSuggestions } from './components/SearchKeywordSuggestions';
import { SearchOrderOptions } from './seach.interface';

export function MobileSearch() {
	const [searchInput, setSearchInput] = useState('');
	const [isFilterSlideupVisible, setIsFilterSlideupVisible] = useState(false);
	const [seachSortingValue, setSeachSortingValue] = useState<SearchOrderOptions>(SearchOrderOptions.POPULAR);

	const [startAmount, setStartAmount] = useState('');
	const [endAmount, setEndAmount] = useState('');

	const handleFilterSlideupClose = useCallback(() => {
		setIsFilterSlideupVisible(false);
	}, []);

	const handleSortChange = useCallback((value: SearchOrderOptions) => {
		setSeachSortingValue(value);
	}, []);

	return (
		<>
			{/*isFilterSlideupVisible && (
				<FilterSlideupSheet
					onClose={handleFilterSlideupClose}
					onSortChange={handleSortChange}
					sortOption={seachSortingValue}
					startValue={startAmount}
					endValue={endAmount}
					onEndValueChange={(v) => setEndAmount(v)}
					onStartValueChange={(v) => setStartAmount(v)}
				/>
			)*/}
			<div className="flex flex-col space-y-[15px] pb-[30px]">
				<div className="relative w-full h-[49px]">
					<MobileSearchInput
						value={searchInput}
						onChange={setSearchInput}
						placeholder="Search Products, Boxes, Brands .."
						onFilterClick={() => setIsFilterSlideupVisible(true)}
					/>
				</div>

				<SearchKeywordSuggestions searchInput={searchInput} onSearchInputChange={(v) => setSearchInput(v)} />

				<GridSearchBoxes searchInput={searchInput} />
			</div>
		</>
	);
}
