import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CSSProperties, ReactNode, useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import { WithPopup } from './WithPopup';

interface Props {
	onClose: () => void;
	children: ReactNode;
	childrenWrapperStyle?: CSSProperties;
	containerStyle?: CSSProperties;
	isDesktop?: boolean;
	isExternallyVisible?: boolean; // optional external visibility control
	useAnimation?: boolean; // optional animation control
}

export function WithPrimaryPopup({
	onClose,
	children,
	containerStyle,
	childrenWrapperStyle,
	isDesktop,
	isExternallyVisible,
	useAnimation = true,
}: Props) {
	// Internal visibility state
	const [isVisible, setIsVisible] = useState(Boolean(isExternallyVisible));
	const [isContentVisible, setIsContentVisible] = useState(false);

	// Sync external visibility if provided
	useEffect(() => {
		if (isExternallyVisible !== undefined) {
			if (isExternallyVisible) {
				setIsVisible(true);
			} else {
				setIsVisible(false);
			}
		}
	}, [isExternallyVisible, useAnimation]);

	// Handle scaling effect only when component is fully mounted
	const handleEnter = () => setIsContentVisible(true);
	const handleExited = () => setIsContentVisible(false);

	if (useAnimation) {
		return (
			<CSSTransition
				in={isVisible}
				timeout={0}
				classNames={{
					enter: 'opacity-0',
					enterActive: 'opacity-100 transition-opacity duration-[300ms] ease-in',
					exit: 'opacity-100',
					exitActive: 'opacity-0 transition-opacity duration-[300ms] ease-out',
				}}
				onEnter={handleEnter}
				onExited={handleExited}
				unmountOnExit
			>
				<WithPopup onClose={onClose} shouldCenter={true} isDesktop={isDesktop}>
					<div
						className={`${isContentVisible ? 'scale-100' : 'scale-90'} shadow-[0_4px_4px_rgba(0,0,0,0.25)] rounded-[24px] border-[3px] border-white bg-background text-white`}
						style={{
							transition: 'transform ease-out 300ms',
							...containerStyle,
						}}
					>
						<div
							className="relative flex flex-col bg-primary-gradient border-[2px] border-darkBlue w-full py-[30px] px-[27px] rounded-[20px] max-h-[calc(90vh-118px)] sm:max-h-[calc(90vh-68px)] overflow-y-auto"
							style={{
								...childrenWrapperStyle,
							}}
						>
							<button
								onClick={onClose}
								className="flex z-30 items-center justify-center absolute right-[15px] top-[15px] rounded-full border-[1.5px] w-[28px] h-[28px] text-white hover:text-black bg-transparent hover:bg-white border-white"
							>
								<FontAwesomeIcon icon={faXmark} />
							</button>
							{children}
						</div>
					</div>
				</WithPopup>
			</CSSTransition>
		);
	}

	// Render without animation if useAnimation is false
	if (isVisible || isExternallyVisible === undefined) {
		return (
			<WithPopup onClose={onClose} shouldCenter={true} isDesktop={isDesktop}>
				<div
					className="scale-100 shadow-[0_4px_4px_rgba(0,0,0,0.25)] rounded-[24px] border-[3px] border-white bg-background text-white"
					style={containerStyle}
				>
					<div
						className="relative flex flex-col bg-primary-gradient border-[2px] border-darkBlue w-full py-[30px] px-[27px] rounded-[20px] max-h-[calc(90vh-118px)] sm:max-h-[calc(90vh-68px)] overflow-y-auto"
						style={childrenWrapperStyle}
					>
						<button
							onClick={onClose}
							className="flex z-30 items-center justify-center absolute right-[15px] top-[15px] rounded-full border-[1.5px] w-[28px] h-[28px] text-white hover:text-black bg-transparent hover:bg-white border-white"
						>
							<FontAwesomeIcon icon={faXmark} />
						</button>
						{children}
					</div>
				</div>
			</WithPopup>
		);
	}

	return null; // hide if not visible and no external control
}
