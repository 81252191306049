import { Skeleton } from 'components';
import { MobileBackground } from 'components/layout/background/MobileBackground';
import { useBackBtnInTopBar } from 'hooks/app-layout/useBackBtnInTopbar';
import { useTopbarBackBtnListener } from 'hooks/events/useTopbarBackBtnListener';
import { useQueryParam } from 'hooks/utility/useQueryParam';
import { useOrderByOrderNr } from 'pages/MyOrders/hooks/useOrderByOrderNr';
import { useCallback, useRef, useState } from 'react';

import { GeneralOrderInfoSection } from './GeneralOrderInfoSection';
import { OrderActionBtns } from './OrderActionBtns';
import { OrderItemInfo } from './OrderItemsInfo';
import { OrderStatusSection } from './OrderStatusSection';
import { ReturnProductsInfo } from './ReturnProductsInfo';

export function OrderDetailsPage() {
	const parentRef = useRef<HTMLDivElement>(null);
	const [orderNr, setOrderNr] = useState<number | undefined>();

	useQueryParam('orderNr', (param) => {
		const orderNr = Number(param);
		if (!isNaN(orderNr)) {
			setOrderNr(orderNr);
		}
	});

	const { data: order, isLoading } = useOrderByOrderNr({ orderNr });

	useBackBtnInTopBar();

	useTopbarBackBtnListener(
		useCallback(() => {
			window.history.back();
		}, [])
	);

	return (
		<div ref={parentRef} className="fixed z-30 left-0 top-0 h-dvh w-screen">
			<MobileBackground>
				<div className="w-screen max-h-dvh overflow-y-auto flex flex-col items-center gap-y-[15px] pt-mobile-top-padding pb-[90px] overscroll-contain">
					<h1 className="realtive z-10 text-white font-bold text-[20px]">Order Details</h1>

					<div className="w-full flex flex-col">
						<OrderStatusSection status={order?.publicStatus} isLoading={isLoading} />
						<div className="flex flex-col items-center gap-y-[15px]">
							<GeneralOrderInfoSection
								shippingAddress={order?.shippingAddress}
								status={order?.publicStatus}
								isLoading={isLoading}
							/>
							{order && (
								<div className="w-full bg-white py-[15px] px-mobile-side-padding flex items-center justify-between text-[15px] text-black font-semibold">
									<span>Order Nr.</span>
									<span>#{order.orderNr}</span>
								</div>
							)}
							{isLoading && (
								<div className="bg-white py-[15px] px-mobile-side-padding flex items-center justify-between text-[15px] text-black font-semibold w-full">
									<Skeleton style={{ width: '60px', height: '23px' }} color="#d1d1d6" />
									<Skeleton style={{ width: '35px', height: '23px' }} color="#d1d1d6" />
								</div>
							)}
							<OrderItemInfo orderId={order?._id} orderItems={order?.items} isLoading={isLoading} />
							{order && <ReturnProductsInfo orderId={order?._id} />}

							{order && <OrderActionBtns order={order} />}
						</div>
					</div>
				</div>
			</MobileBackground>
		</div>
	);
}
