import { PrimaryDropdown } from 'components/common/buttons/PrimaryDropdown';
import { MultiSelectDropdown } from 'components/common/MultiSelectDropdown';
import { useWindowDimensions } from 'hooks';
import { OrderDbItemInterface } from 'interfaces/OrderInterfaces';
import { RefundMethod, ReturnReason } from 'interfaces/OrderReturns.interface';
import { extractValuesFromSelectedOptionSets } from 'pages/MyOrders/orderUtils';
import { ReturnCreationForm } from 'pages/MyOrders/ui-order.interface';
import { Controller, useFormContext } from 'react-hook-form';

import { screens } from '../../../../constants';

interface Props {
	item: OrderDbItemInterface;
	index: number;
}

const refundMethodLabels: Record<RefundMethod, string> = {
	[RefundMethod.STORE_CREDIT]: 'Store Credit',
	[RefundMethod.RETURN_TO_INVENTORY]: 'Return to Inventory',
};

function getReadableRefundMethod(value: RefundMethod): string {
	return refundMethodLabels[value] || value; // Fallback to the value itself if not found
}

// Convert readable label back to enum value
function getEnumFromReadableLabel(label: string): RefundMethod | undefined {
	return (Object.entries(refundMethodLabels) as [RefundMethod, string][]).find(
		([, readableLabel]) => readableLabel === label
	)?.[0];
}

export function ProductReturnOptions({ item, index }: Props) {
	const options = item.options ? extractValuesFromSelectedOptionSets(item.options) : undefined;

	const { screenWidth } = useWindowDimensions();

	const { control, formState } = useFormContext<ReturnCreationForm>();
	const { errors } = formState;

	const optionPlaceholder =
		item.options && item.options.length > 0 && item.options[0].optionSelections.length === 1
			? `Choose ${item.options[0].optionSelections[0].label}`
			: 'Choose Specification';

	return (
		<div className="w-full flex items-center justify-between gap-x-[10px]">
			{options && options?.length > 0 && (
				<Controller
					name={`items.${index}.selectedOptions`}
					control={control}
					render={({ field: { onChange, value } }) => (
						<MultiSelectDropdown
							options={options}
							values={value}
							onChange={onChange}
							placeholder={optionPlaceholder}
							hasError={!!errors?.items?.[index]?.selectedOptions} // Passing the error prop
						/>
					)}
				/>
			)}
			{(!item.options || item.options.length === 0) && item.quantity > 1 && (
				<Controller
					name={`items.${index}.quantity`}
					control={control}
					render={({ field: { onChange, value } }) => (
						<PrimaryDropdown
							options={Array.from({ length: item.quantity - item.unavailableQuantity }, (_, index) =>
								(index + 1).toString()
							)}
							onOptionSelect={onChange}
							selectedOption={value.toString()}
							placeholder="Qty"
							containerStyle={{ width: '80px' }}
							hasError={!!errors?.items?.[index]?.quantity}
						/>
					)}
				/>
			)}
			<Controller
				name={`items.${index}.returnReason`}
				control={control}
				render={({ field: { onChange, value } }) => (
					<PrimaryDropdown
						options={Object.values(ReturnReason)}
						selectedOption={value}
						onOptionSelect={onChange}
						placeholder="Return Reason"
						buttonStyle={{ fontSize: '13px' }}
						dropdownOverlayStyle={{ fontSize: '13px' }}
						hasError={!!errors?.items?.[index]?.returnReason}
					/>
				)}
			/>
			{screenWidth > screens.lg.width && (
				<Controller
					name={`items.${index}.refundMethod`}
					control={control}
					render={({ field: { onChange, value } }) => {
						const readableOptions = Object.values(RefundMethod).map(getReadableRefundMethod); // Convert enum values to readable labels
						const selectedReadableOption = getReadableRefundMethod(value as RefundMethod); // Convert selected value to readable label

						return (
							<PrimaryDropdown
								options={readableOptions}
								selectedOption={selectedReadableOption}
								onOptionSelect={(label: string) => {
									const enumValue = getEnumFromReadableLabel(label); // Convert readable label back to enum value
									if (enumValue) {
										onChange(enumValue);
									} // Call onChange with the enum value
								}}
								placeholder="Refund Method"
								buttonStyle={{ fontSize: '13px' }}
								dropdownOverlayStyle={{ fontSize: '13px' }}
								hasError={!!errors?.items?.[index]?.refundMethod}
							/>
						);
					}}
				/>
			)}
		</div>
	);
}
