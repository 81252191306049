import { PrimaryBtn } from 'components/common/buttons/PrimaryBtn';
import { ToastErrorNotification } from 'components/toasts/ToastErrorNotification';
import { COLORS } from '../../../../constants';
import { useCountdownTimer } from 'hooks/utility/useCountdownTimer';
import { useVerifyResetCode } from 'pages/authentication/hooks/useVerifyResetCode';
import { useAuthenticationStore } from 'pages/authentication/store/useAuthenticationStore';
import { useCallback, useState } from 'react';
import OtpInput from 'react-otp-input';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { useQueryParam } from 'hooks/utility/useQueryParam';
import { useResetPasswordMail } from 'pages/authentication/hooks/useResetPasswordMail';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { ToastSuccessNotification } from 'components/toasts/ToastSuccessNotification';
import { useExpirationDateOfCurrentResetCode } from 'pages/authentication/hooks/useExpirationDateOfCurrentResetCode';
import { LogoBlue } from 'assets/images';
import { Link } from 'react-router-dom';

export function DesktopPasswordCodeVerificationStep() {
	const { emailForPasswordReset, setVerificationCodeForPasswordReset, setEmailForPasswordReset } =
		useAuthenticationStore();

	const { refetch: refetchExpirationOfResetCode, data: expirationDate } = useExpirationDateOfCurrentResetCode({
		email: emailForPasswordReset ?? '',
	});

	const [verificationCode, setVerificationCode] = useState('');
	const timeLeft = useCountdownTimer(expirationDate);

	const navigate = useNavigate();

	useQueryParam(
		'code',
		useCallback((codeFromParams: string) => setVerificationCode(codeFromParams), [])
	);

	useQueryParam(
		'email',
		useCallback((emailFromParams: string) => setEmailForPasswordReset(emailFromParams), [])
	);

	function handleCodeVerification() {
		if (!emailForPasswordReset || emailForPasswordReset.length === 0) {
			toast(<ToastErrorNotification message="An error occured. Please request another verification code" />, {
				style: { backgroundColor: COLORS.blue90 },
			});
			navigate('/auth/reset-password/enter-email');
			return;
		}

		if (!verificationCode || verificationCode.length !== 5) {
			toast(<ToastErrorNotification message="Verification Code needs to be 5 characters long" />, {
				style: { backgroundColor: COLORS.blue90 },
			});
			return;
		}

		setVerificationCodeForPasswordReset(verificationCode);
		verifyResetCode({ email: emailForPasswordReset, code: verificationCode });
	}

	const { mutate: verifyResetCode, isLoading: isCodeVerificationLoading } = useVerifyResetCode({
		onSuccess: () => {
			navigate('/auth/reset-password/change-password');
		},
		onError: (message) => {
			toast(<ToastErrorNotification message={message} />, {
				style: { backgroundColor: COLORS.blue90 },
			});
		},
	});

	function handleReSendPasswordResetMail() {
		if (!emailForPasswordReset || emailForPasswordReset.length === 0) {
			toast(<ToastErrorNotification message="Some unexpected error occured. Please try again" />, {
				style: { background: COLORS.blue90 },
			});
			navigate('/auth/reset-password/enter-email');
			return;
		}
		requestResetPasswordMail(emailForPasswordReset);
	}

	const { mutate: requestResetPasswordMail, isLoading: isNewMailRequestLoading } = useResetPasswordMail({
		onSuccess: () => {
			toast(<ToastSuccessNotification message="New mail has been sent" />, {
				style: { background: COLORS.blue90 },
			});
			refetchExpirationOfResetCode();
		},
		onError: (message) => {
			toast(<ToastErrorNotification message={message} />, {
				style: { backgroundColor: COLORS.blue90 },
			});
		},
	});

	return (
		<div className="h-full w-full flex flex-col items-center justify-around gap-y-5">
			<img alt="" src={LogoBlue} className="h-[3rem]" />
			<div className="flex flex-col w-[75%] gap-y-3">
				<h1 className="text-[1.4rem] text-black font-semibold">Check Your Mails</h1>
				<OtpInput
					shouldAutoFocus={true}
					inputType="number"
					containerStyle={{ gap: '15px' }}
					inputStyle={{ height: '55px', width: '50px', borderRadius: '0px', margin: '0 auto' }}
					value={verificationCode}
					onChange={setVerificationCode}
					numInputs={5}
					renderInput={(props) => (
						<input
							{...props}
							className="outline-none border-[2px] bg-transparent border-b-white border-x-transparent border-t-transparent focus-within:border-b-mrLootBlue text-white font-bold text-[20px]"
						/>
					)}
				/>
				<div className="flex items-center justify-center gap-x-[7px] mt-[15px]">
					<span className="text-[rgba(255,255,255,0.7)] text-[13px] font-semibold">Code expires in:</span>
					<span className="text-[rgba(255,255,255,1)] text-[13px] font-bold">{timeLeft}</span>
				</div>
				<PrimaryBtn
					onClick={handleCodeVerification}
					isDisabled={isCodeVerificationLoading}
					type="button"
					content="Verify"
					style={{ height: '50px', marginTop: '10px' }}
				/>
				<button
					disabled={isNewMailRequestLoading}
					onClick={handleReSendPasswordResetMail}
					className="w-full h-[50px] text-white border-[2px] border-[rgba(255,255,255,1)] hover:bg-[rgba(255,255,255,0.1)] flex items-center justify-center rounded-[20px] font-semibold"
				>
					{isNewMailRequestLoading ? (
						<FontAwesomeIcon icon={faSpinner} className="text-white animate-spin" />
					) : (
						'Send again'
					)}
				</button>
			</div>
			<Link to="/auth/reset-password/enter-email" className="flex items-center gap-x-[9px]">
				<FontAwesomeIcon icon={faChevronLeft} />
				<span>change e-mail-address</span>
			</Link>
		</div>
	);
}
