import { createWithEqualityFn } from 'zustand/traditional';

interface BalanceState {
	balance: number | undefined; // Current balance
	isHighlighted: boolean; // Whether the balance text is highlighted (e.g., green)
	setBalance: (newBalance: number | ((prevBalance: number | undefined) => number)) => void; // Accepts value or updater function
	resetBalance: () => void; // Revert to undefined
	setHighlight: (highlight: boolean) => void; // Update highlight status
}

export const useBalanceStore = createWithEqualityFn<BalanceState>(
	(set) => ({
		balance: undefined,
		isHighlighted: false,
		setBalance: (newBalance) =>
			set((state) => ({
				balance: typeof newBalance === 'function' ? newBalance(state.balance) : newBalance, // Calculate based on prev state or set directly
			})),
		resetBalance: () => set({ balance: undefined, isHighlighted: false }),
		setHighlight: (highlight) => set({ isHighlighted: highlight }),
	}),
	Object.is
);
