import { FontLoading, LoadingBgBoxOpening } from 'assets/images';
import { MobileCloseBtn } from 'components/common/buttons/MobileCloseBtn';
import { ProgressBar } from 'components/common/ProgressBar';
import { gsap } from 'gsap';
import { useDvhFallback } from 'hooks/layout/useDvhFallback';
import { ReactNode, useEffect, useRef } from 'react';

interface Props {
	children: ReactNode;
	successProgress: number; // progress of only successful loaded images
	progress: number; // totla progress of all progressed images
	onManualExit: () => void;
	onLoadingComplete: () => void;
}

export function WithLoadingScreen({ children, progress, onManualExit, successProgress, onLoadingComplete }: Props) {
	const childRef = useRef<HTMLDivElement>(null);
	const blackScreenRef = useRef<HTMLDivElement>(null);

	const timelineRef = useRef(gsap.timeline());
	const isDoneLoading = useRef(false);

	useEffect(() => {
		if (!blackScreenRef.current || !childRef.current) {
			return;
		}

		if (isDoneLoading.current) {
			return;
		}

		if (progress < 100) {
			timelineRef.current.to(blackScreenRef.current, {
				autoAlpha: 1,
				duration: 0.4,
				ease: 'power2.in',
			});
		} else {
			isDoneLoading.current = true;
			onLoadingComplete();
			timelineRef.current.set(childRef.current, { opacity: 1 });
			timelineRef.current.to(blackScreenRef.current, {
				delay: 0.2,
				autoAlpha: 0,
				duration: 0.3,
				ease: 'power2.in',
			});
			//timelineRef.current.set(blackScreenRef.current, { display: 'none' });
		}
	}, [onLoadingComplete, progress]);

	useDvhFallback({
		ref: blackScreenRef,
		properties: [{ property: 'height', value: 100 }],
	});

	return (
		<div className="relative w-full h-full">
			<div
				ref={blackScreenRef}
				className="opacity-0 absolute z-20 inset-0 bg-background px-[35px] h-dvh flex flex-col items-center justify-center gap-y-[20px] bg-bottom bg-cover"
				style={{ backgroundImage: `url(${LoadingBgBoxOpening})` }}
			>
				<MobileCloseBtn onClose={onManualExit} />
				<img alt="Loading..." src={FontLoading} className="h-[26px]" />
				<ProgressBar progress={progress === 100 ? progress : successProgress} />
			</div>
			<div ref={childRef} className="opacity-0 relative z-10 lex flex-col items-center w-full h-full">
				{children}
			</div>
		</div>
	);
}
