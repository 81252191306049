import { faPlus, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';

interface Props {
	value: number;
	onChange: (newValue: number) => void;
	onItemDelete: () => void;
}

export function QuantityInput({ value, onChange, onItemDelete }: Props) {
	const [localInputValue, setLocalInputValue] = useState(value.toString());

	function handleQtyPlus() {
		const newQty = Number(localInputValue) + 1;
		setLocalInputValue(newQty.toString());
		onChange(newQty);
	}

	useEffect(() => {
		setLocalInputValue(value.toString());
	}, [value]);

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = e.target.value;
		// Check if the new value is a valid number or empty (for backspace functionality)
		if (newValue === '' || !isNaN(Number(newValue))) {
			setLocalInputValue(newValue);
		}
	};

	const handleBlur = () => {
		const numValue = Number(localInputValue);
		if (localInputValue === '' || isNaN(numValue) || numValue <= 0) {
			setLocalInputValue(value.toString()); // Revert to last valid value if current is invalid
		} else {
			onChange(Number(localInputValue));
		}
	};

	return (
		<>
			<div className="relative w-[110px] h-[55px] border-[1px] border-white rounded-[2px] flex items-center justify-center">
				<button
					className="text-errorRed text-[15px] p-1 absolute left-[12px] top-[50%] translate-y-[-50%]"
					onClick={onItemDelete}
				>
					<FontAwesomeIcon icon={faTrashCan} />
				</button>
				<input
					value={localInputValue}
					onChange={handleInputChange}
					onBlur={handleBlur}
					type="text"
					inputMode="numeric"
					className="text-[16px] font-semibold text-white w-full h-full bg-transparent outline-none text-center"
				/>
				<button
					onClick={handleQtyPlus}
					className="absolute right-[12px] top-[50%] translate-y-[-50%] text-[rgba(255,255,255,0.6)]"
				>
					<FontAwesomeIcon icon={faPlus} />
				</button>
			</div>
		</>
	);
}
