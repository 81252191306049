import axios from 'axios';
import { FeatureBannersSessionTypes, PopulatedFeatureBannerInterface } from 'interfaces/FeatureBannerInterface';
import { useQuery } from 'react-query';

import { API_DOMAIN, QUERY_KEYS } from '../../../constants';

async function loadPopulatedOverviewFeatureBanners(sessionTypeToShow: FeatureBannersSessionTypes) {
	try {
		const response = await axios.post(
			`${API_DOMAIN.mrlootBackend}/api/feature-banners/load-by-filter`,
			{ sessionTypeToShow: sessionTypeToShow },
			{ withCredentials: true }
		);
		if (response.data.status === 'success') {
			return response.data.data;
		}
	} catch (err) {
		console.error(err);
		throw err;
	}
}

interface Props {
	sessionTypeToShow: FeatureBannersSessionTypes;
}

export function useLoadFeatureBanners({ sessionTypeToShow }: Props) {
	return useQuery<Props, Error, PopulatedFeatureBannerInterface[]>({
		queryKey: [QUERY_KEYS.featureBanners, sessionTypeToShow],
		queryFn: () => loadPopulatedOverviewFeatureBanners(sessionTypeToShow),
	});
}
