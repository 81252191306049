/* eslint-disable no-magic-numbers */
/* eslint-disable max-lines-per-function */
import { useBoxViewLogger } from 'hooks';
import { useEnterOrSpacePress } from 'hooks/utility/event-listener/useEnterOrSpacePress';
import { useComponentSizes } from 'hooks/utility/layout-measurements/useComponentSizes';
import { PopulatedBoxInterface } from 'interfaces/BoxInterface';
import { ItemInterface } from 'interfaces/ItemInterfaces';
import { debounce } from 'lodash';
import { useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { getBoxLink } from 'utils';

import { CLOUDFRONT_IMG_URL } from '../../../constants';
import { BoxCard } from './BoxCard';
import { useItemPreviewAnimations } from './hooks/useItemPreviewAnimation';
import { ItemPreview } from './ItemPreview';

interface Props {
	box: PopulatedBoxInterface;
	styles?: React.CSSProperties;
	onClick?: () => void;
}

const ITEM_LOADING_SIZE = 6;
const NUM_ITEMS_OF_LAST_LOAD = 4;

export function BoxCardWithItemPreview({ box, styles, onClick }: Props) {
	const refs = {
		parentRef: useRef<HTMLDivElement>(null),
		itemPreviewContainerRef: useRef<HTMLDivElement>(null),
		boxCardRef: useRef<HTMLDivElement>(null),
		itemPreviewWrapperRefA: useRef<HTMLDivElement>(null),
		smallItemsPreviewRefA: useRef<HTMLDivElement>(null),
		itemPreviewWrapperRefB: useRef<HTMLDivElement>(null),
		smallItemsPreviewRefB: useRef<HTMLDivElement>(null),
	};

	const boxWidth = refs.parentRef.current?.clientWidth;

	const navigate = useNavigate();

	function handleBoxNavigation(boxName: string) {
		if (onClick) {
			onClick();
		}
		const boxLink = getBoxLink(boxName);
		navigate(boxLink);
	}

	const sortedItems = useMemo(
		() => box.items.map((item) => item.itemId).sort((a, b) => b.price - a.price),
		[box.items]
	);

	const [visibleItemsA, setVisibleItemsA] = useState(
		sortedItems.slice(0, Math.min(ITEM_LOADING_SIZE, sortedItems.length))
	);
	const [visibleItemsB, setVisibleItemsB] = useState<ItemInterface[]>([]);
	const itemLoadingIndex = useRef(Math.min(ITEM_LOADING_SIZE, sortedItems.length));
	const [nextBuffer, setNextBuffer] = useState<'A' | 'B'>('A');

	const { startItemPreview, cancelItemPreview } = useItemPreviewAnimations({
		refs,
		numOfPreviewItemsA: visibleItemsA.length,
		numOfPreviewItemsB: visibleItemsB.length,
		preloadNextItems: loadNextItems,
		nextBuffer: nextBuffer,
		totalItemNumber: sortedItems.length,
	});

	/**
	 * Loads the next set of items into a specified buffer ('A' or 'B') from a sorted list of items.
	 * This function calculates the next set of items to be loaded based on a global item loading index,
	 * and updates the visible items in the specified buffer accordingly. It supports wrap-around logic
	 * when reaching the end of the items list and continues loading items from the beginning.
	 *
	 * @param buffer The buffer identifier ('A' or 'B') where the new set of items will be loaded.
	 *               This determines which set of visible items will be updated.
	 *
	 */
	function loadNextItems(buffer: 'A' | 'B') {
		const totalItems = sortedItems.length;
		const adjustedIndex = itemLoadingIndex.current % totalItems;

		let start = adjustedIndex - NUM_ITEMS_OF_LAST_LOAD;
		if (start < 0) {
			start += totalItems;
		} // Adjust for negative start index

		const newItems = [];
		const itemsToLoad = NUM_ITEMS_OF_LAST_LOAD + ITEM_LOADING_SIZE;

		for (let i = 0; i < itemsToLoad; i++) {
			const currentIndex = (start + i) % totalItems;
			newItems.push(sortedItems[currentIndex]);
		}

		if (buffer === 'A') {
			setVisibleItemsA(newItems);
		} else {
			setVisibleItemsB(newItems);
		}

		itemLoadingIndex.current = (itemLoadingIndex.current + ITEM_LOADING_SIZE) % totalItems;
		setNextBuffer(buffer);
	}

	const { handleMouseEnter: startBoxViewTracking, handleMouseLeave: endBoxViewTracking } = useBoxViewLogger({
		box,
		delay: 1000,
	});

	const itemContainerDimensions = useComponentSizes(refs.itemPreviewContainerRef);

	const debouncedStartBoxViewTrackingRef = useRef(
		debounce(() => {
			startBoxViewTracking();
		}, 200)
	);

	const debouncedStartItemPreviewRef = useRef(
		debounce(() => {
			startItemPreview();
		}, 500)
	);

	function handleMouseEnter() {
		debouncedStartBoxViewTrackingRef.current();
		debouncedStartItemPreviewRef.current();
	}

	function handleMouseLeave() {
		const effectiveLoadingIndex = Math.min(ITEM_LOADING_SIZE, sortedItems.length);
		itemLoadingIndex.current = effectiveLoadingIndex;

		debouncedStartBoxViewTrackingRef.current.cancel();
		debouncedStartItemPreviewRef.current.cancel();

		cancelItemPreview();
		setVisibleItemsA(sortedItems.slice(0, effectiveLoadingIndex));
		setVisibleItemsB([]);

		endBoxViewTracking();
		setNextBuffer('A');
	}

	const handleKeyPress = useEnterOrSpacePress(() => handleBoxNavigation(box.name));

	return (
		<div
			ref={refs.parentRef}
			className="will-change-transform relative cursor-pointer flex-shrink-0 h-full "
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			style={{ ...styles }}
		>
			<div
				onKeyDown={(event) => handleKeyPress(event)}
				role="button"
				tabIndex={0}
				onClick={() => handleBoxNavigation(box.name)}
				className={`border-transparent hover:border-white border-[3px] absolute left-0 z-50 rounded-[7px] w-[calc(100%+2px)] top-0 h-[calc(100%+2px)]`}
			>
				<div
					ref={refs.itemPreviewContainerRef}
					className="overflow-hidden opacity-0 flex flex-col items-start justify-evenly absolute w-full h-full inset-0 z-30 bg-black hover:bg-white bg-cover bg-center rounded-[5px]"
				>
					<img
						alt={`${box.name} box`}
						src={`${CLOUDFRONT_IMG_URL}/${box.withoutTitlePortraitImageUrls?.qualityLow}`}
						className="absolute inset-0 z-[-1] h-full w-full object-cover blur-[1px] grayscale-[30%]"
					/>
					<ItemPreview
						parentDimensions={itemContainerDimensions}
						items={visibleItemsA}
						boxWidth={boxWidth}
						bigItemWrapperRef={refs.itemPreviewWrapperRefA}
						smallItemWrapperRef={refs.smallItemsPreviewRefA}
					/>
					<ItemPreview
						parentDimensions={itemContainerDimensions}
						items={visibleItemsB}
						boxWidth={boxWidth}
						bigItemWrapperRef={refs.itemPreviewWrapperRefB}
						smallItemWrapperRef={refs.smallItemsPreviewRefB}
					/>
				</div>
			</div>
			<BoxCard box={box} styles={{ height: '100%', ...styles }} ref={refs.boxCardRef} trackEvents={false} />
		</div>
	);
}
