import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AmountDisplayWithCurrency } from 'components/common/AmountDisplayWithCurrency';
import { ItemInterface } from 'interfaces/ItemInterfaces';
import Skeleton from 'react-loading-skeleton';

import { QuantityInputWithButtons } from './QuantityInputWithButtons';

export function ContainerManagementSection({
	item,
	quantity,
	onlyDisplay,
	onRemove,
	onQuantityChange,
}: {
	item?: ItemInterface;
	quantity?: number;
	onlyDisplay?: boolean;
	onRemove?: () => void;
	onQuantityChange?: (v: number) => void;
}) {
	return (
		<div className="space-y-[15px] ml-auto flex flex-col items-end">
			{!onlyDisplay && (
				<button
					onClick={() => onRemove && onRemove()}
					className="px-[19px]  font-semibold rounded-[24px] py-[10px] bg-mrLootBlue hover:bg-mrLootBlue700 text-white text-[13px] flex items-center space-x-2"
				>
					<span>Remove</span>
					<FontAwesomeIcon icon={faXmark} />
				</button>
			)}

			{quantity && item && (
				<>
					<div className="text-white text-[14px] md:text-[16px] flex items-center space-x-[15px]">
						<span className="font-semibold">Quantity</span>
						<QuantityInputWithButtons
							disabled={!!onlyDisplay}
							quantity={quantity}
							onQuantityChange={onQuantityChange}
						/>
					</div>
					<div className="flex items-center text-[15px] md:text-[16px] space-x-[10px] text-white">
						<span className="font-semibold">Value</span>
						<AmountDisplayWithCurrency amount={item.price * quantity} imgStyle={{ height: '17px' }} />
					</div>
				</>
			)}

			{(!quantity || !item) && (
				<>
					<Skeleton height={20} width={90} baseColor="#8191a0" />
					<Skeleton height={20} width={110} baseColor="#8191a0" />
				</>
			)}
		</div>
	);
}
