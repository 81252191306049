import { BaseSelectedOptionSet } from 'interfaces/OrderInterfaces';

interface Props {
	options: BaseSelectedOptionSet[];
}

export function ItemOptionsDisplay({ options }: Props) {
	if (options.length === 0) {
		return null;
	}

	return (
		<div className="w-full">
			{options[0].optionSelections.length > 1 && (
				<div
					className="grid gap-x-[20px]"
					style={{
						gridTemplateColumns: `min-content repeat(${options[0].optionSelections.length - 1}, min-content)`,
					}}
				>
					{options[0]?.optionSelections.map(({ label }, index) => (
						<div key={index} className="font-semibold text-center whitespace-nowrap">
							{label}:
						</div>
					))}

					{options.map(({ optionSelections }) =>
						optionSelections.map(({ value }, index) => (
							<div key={index} className="text-center whitespace-nowrap">
								{value}
							</div>
						))
					)}
				</div>
			)}
			{options[0].optionSelections.length === 1 && (
				<div className="flex flex-wrap gap-[5px] items-center">
					<span className="font-semibold capitalize">{options[0]?.optionSelections[0].label}:</span>
					<span>
						{options
							.map((el) => el.optionSelections)
							.flat()
							.map((option) => option.value)
							.flat()
							.join(', ')}
					</span>
				</div>
			)}
		</div>
	);
}
