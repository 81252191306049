import { PrimaryBtn } from 'components/common/buttons/PrimaryBtn';
import { PrimaryPasswordInputWrapper } from 'components/common/inputs/PrimaryPasswordInputWrapper';
import { ToastErrorNotification } from 'components/toasts/ToastErrorNotification';
import { ToastSuccessNotification } from 'components/toasts/ToastSuccessNotification';
import { useUserContext } from 'hooks';
import { useUserPasswordUpdate } from 'pages/AccountSettings/hooks/useUserPasswordUpdate';
import { FieldValues, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

import { Breadcrumb } from '../components/Breadcrumb';

interface PasswordFormValues extends FieldValues {
	currentPassword: string;
	newPassword: string;
	confirmPassword: string;
}

export function DesktopChangePassword() {
	const { load: reloadUser } = useUserContext();
	const navigate = useNavigate();

	const { control, handleSubmit, setError } = useForm<PasswordFormValues>();

	const { mutate: updatePassword, isLoading } = useUserPasswordUpdate({
		onSuccess() {
			toast(<ToastSuccessNotification message="Your Password has been updated" />);
			reloadUser();
			navigate('/my-account/edit-profile');
		},
		onError(message) {
			toast(<ToastErrorNotification message={message} />);
		},
	});

	const onSubmit = (data: PasswordFormValues) => {
		if (data.newPassword !== data.confirmPassword) {
			setError('confirmPassword', { type: 'manual', message: 'New Password and Confirm Password do not match' });
			return;
		}
		updatePassword(data);
	};

	return (
		<div className="flex flex-col gap-y-[20px]">
			<Breadcrumb items={[{ label: 'Account', link: '/my-account/edit-profile' }, { label: 'Change Password' }]} />

			<form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-y-[15px]">
				<PrimaryPasswordInputWrapper
					name="currentPassword"
					label="Current Password"
					control={control}
					rules={{
						required: 'Password is required',
					}}
				/>
				<PrimaryPasswordInputWrapper
					name="newPassword"
					label="New Password"
					control={control}
					rules={{
						required: 'Password is required',
						minLength: {
							value: 8,
							message: 'Password must be at least 8 characters long',
						},
					}}
				/>
				<PrimaryPasswordInputWrapper
					name="confirmPassword"
					label="Repeat Password"
					control={control}
					rules={{
						required: 'Password is required',
						minLength: {
							value: 8,
							message: 'Password must be at least 8 characters long',
						},
					}}
				/>
				<PrimaryBtn isDisabled={isLoading} content="Save Changes" type="submit" style={{ marginTop: '20px' }} />
			</form>
		</div>
	);
}
