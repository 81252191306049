import { ToastSuccessNotification } from 'components/toasts/ToastSuccessNotification';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

export const useUrlParamsToast = () => {
	const location = useLocation();

	useEffect(() => {
		const params = new URLSearchParams(location.search);

		// Map query parameters to toast actions
		const toastActions: Record<string, (_: string | null) => void> = {
			depositSuccess: () =>
				toast(<ToastSuccessNotification message="Success! Your deposit has been added to your balance" />),

			// Add more actions as needed
		};

		params.forEach((value, key) => {
			const action = toastActions[key];
			if (action) {
				action(value);
			}
		});
	}, [location.search]);
};
