import { ToastErrorNotification } from 'components/toasts/ToastErrorNotification';
import { ToastInfoNotification } from 'components/toasts/ToastInfoNotification';
import { ToastMultipleSoldNotification } from 'components/toasts/ToastMultipleSoldNotification';
import { ToastSoldNotification } from 'components/toasts/ToastSoldNotification';
import gsap from 'gsap';
import { useUserContext } from 'hooks/context/useUserContext';
import { useSellInventoryItems } from 'hooks/data/inventory/useSellInventoryItems';
import { usePaymentTransactions } from 'hooks/data/transactions/usePaymentTransactions';
import { usePopulatedInventoryItems } from 'pages/inventory/hooks/usePopulateInventoryItems';
import { useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { InventoryOrderOptions } from '../InterfacesUI';
import { ExpandBtn } from './ExpandBtn';
import { ExpandedSellSummary } from './ExpandedSellSummary';
import { SellBtn } from './SellBtn';
import { ShipHomeBtn } from './ShipHomeBtn';

interface Props {
	selectedItemIds: Set<string>;
	onItemIdsChange: (ids: Set<string>) => void;
}

const NUM_OF_ITEMS_TO_LOAD = 15;

export function FloatingBottomBar({ selectedItemIds, onItemIdsChange }: Props) {
	const navigate = useNavigate();
	const { load: reloadUser } = useUserContext();

	const expandingRef = useRef<HTMLDivElement>(null);

	const { user } = useUserContext();

	const itemIdsArray = useMemo(() => Array.from(selectedItemIds), [selectedItemIds]);

	const {
		data: populatedSelectedItems,
		totalItemAmount,
		isFetchingNextPage,
		loadMore: loadMorePopulatedInventoryItems,
	} = usePopulatedInventoryItems({
		inventoryItemIds: itemIdsArray,
		limit: NUM_OF_ITEMS_TO_LOAD,
		order: InventoryOrderOptions.PRICE_HIGH,
	});

	const hasRedeemableOnlyItems = populatedSelectedItems?.some((el) => el.itemId.isRedeemable);

	const [isExpanded, setIsExpanded] = useState(false);

	const { mutate: sellItemsMutate } = useSellInventoryItems({
		onSuccess: () => {
			if (populatedSelectedItems?.length === 1) {
				toast(<ToastSoldNotification item={populatedSelectedItems[0].itemId} />);
			} else if (populatedSelectedItems && populatedSelectedItems.length > 1) {
				toast(
					<ToastMultipleSoldNotification
						displayedItem={populatedSelectedItems[0].itemId}
						totalValue={totalItemAmount}
						itemCount={selectedItemIds.size}
					/>
				);
			}

			window.scroll({ behavior: 'smooth', top: 0 });
			onItemIdsChange(new Set());
			reloadUser();
		},
	});

	function handleExpandChange() {
		if (isExpanded) {
			gsap.to(expandingRef.current, {
				height: 0,
				duration: 0.5,
				onComplete: () => setIsExpanded(false),
			});
		} else {
			setIsExpanded(true);
		}
	}

	function handleShipHome() {
		if (hasRedeemableOnlyItems) {
			toast(
				<ToastErrorNotification message="Selection includes items that can't be sent home. Sell or adjust your selection." />
			);
			return;
		}

		if (!user?.contactMail?.verified) {
			toast(<ToastErrorNotification message="Please verify your mail address first" />);
			return;
		}
		const itemIdArray = Array.from(selectedItemIds);
		navigate('/checkout', { state: { inventoryItemIds: [...itemIdArray] } });
	}

	const { data: paymentHistory } = usePaymentTransactions();

	function handleSell() {
		if (!paymentHistory || paymentHistory.length === 0) {
			toast(<ToastInfoNotification message="Deposit to unlock all features" />);
			return;
		}
		sellItemsMutate(itemIdsArray);
	}

	return (
		<>
			{isExpanded && (
				<ExpandedSellSummary
					ref={expandingRef}
					isLoading={isFetchingNextPage}
					isCompleteLoaded={selectedItemIds.size === populatedSelectedItems?.length}
					onLoadMore={loadMorePopulatedInventoryItems}
					onItemIdsChange={onItemIdsChange}
					populatedSelectedItems={populatedSelectedItems}
				/>
			)}

			<div className={`flex items-center justify-between w-full h-[40px] z-30`}>
				<ExpandBtn numSelectedItems={selectedItemIds.size} isExpanded={isExpanded} onClick={handleExpandChange} />
				<SellBtn totalSellAmount={totalItemAmount} onClick={handleSell} isDisabled={selectedItemIds.size === 0} />
				<ShipHomeBtn onClick={handleShipHome} isDisabled={selectedItemIds.size === 0} />
			</div>
		</>
	);
}
