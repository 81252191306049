/* eslint-disable no-magic-numbers */
import { ItemOption } from 'interfaces/ItemInterfaces';
import { generateObjectId } from 'utils';

import { MIN_ORDER_VALUE, SHIPPING_COSTS_PER_ITEM } from './checkout.constant';
import { FailedItemsMap, GroupedOrderItemInterface, SelectedItemOption, SelectedOptionSet } from './InterfacesCheckout';

export function ensureItemOptionsAreSelected(orderItems: GroupedOrderItemInterface[]): boolean {
	return orderItems.every((el) => {
		// If there are no options for the item, we consider it valid
		if (!el.item.options || el.item.options.length === 0) {
			return true;
		}

		// Ensure that for every selected option set, there are enough optionSelections matching the quantity
		return (
			el.selectedOptions?.length === el.quantity &&
			el.selectedOptions.every(
				(selectedOption) =>
					selectedOption.optionSelections.length > 0 &&
					selectedOption.optionSelections.every((o) => !!o.value && o.value.length > 0)
			)
		);
	});
}

/**
 * Returns a map of order items that have unselected options.
 *
 * The map will contain the item ID as the key and a nested object
 * where the key is the index of the option set, and the value is an
 * array of `SelectedItemOption` objects that have unselected values.
 *
 * @param {GroupedOrderItemInterface[]} orderItems - Array of order items to validate.
 * @returns {FailedItemsMap} A map where the keys are item IDs, and the values are objects mapping option set indices to arrays of invalid `SelectedItemOption`s.
 */
export function getInvalidOptions(orderItems: GroupedOrderItemInterface[]): FailedItemsMap {
	const failedItems: FailedItemsMap = {};

	orderItems.forEach((item) => {
		const failedOptionSets: {
			[index: number]: SelectedItemOption[]; // Map index of option set to array of failed options
		} = {};

		item.selectedOptions?.forEach((selectedOptionSet, index) => {
			const failedOptions = selectedOptionSet.optionSelections.filter(
				(option) => !option.value || option.value.length === 0
			);

			if (failedOptions.length > 0) {
				failedOptionSets[index] = failedOptions;
			}
		});

		if (Object.keys(failedOptionSets).length > 0) {
			failedItems[item.item._id] = failedOptionSets;
		}
	});

	return failedItems;
}

export function hasFailedItems(failedItemsMap: FailedItemsMap): boolean {
	// Check if there are any item IDs in the map
	return Object.keys(failedItemsMap).some((itemId) => {
		// Check if any option set index in this item has failed options
		return Object.keys(failedItemsMap[itemId]).some((index) => failedItemsMap[itemId][parseInt(index)]?.length > 0);
	});
}

export function initializeSelectedOptions(quantity: number, itemOptions: ItemOption[]): SelectedOptionSet[] {
	// Create an array for each quantity
	const optionSets: SelectedOptionSet[] = [];

	for (let i = 0; i < quantity; i++) {
		const optionSelections: SelectedItemOption[] = itemOptions.map((option) => {
			return {
				_id: generateObjectId(),
				optionProperty: option.optionProperty,
				value: '',
				label: option.label,
			};
		});

		optionSets.push({
			_id: generateObjectId(), // This will be an ID for each option set (can be generated later)
			optionSelections,
		});
	}

	return optionSets;
}

// Helper function to adjust the selected options when quantity changes
export function adjustSelectedOptions(
	item: GroupedOrderItemInterface,
	quantity: number
): SelectedOptionSet[] | undefined {
	if (!item.item.options || item.item.options.length === 0) {
		return undefined;
	}
	let updatedOptions = [...(item.selectedOptions ?? [])];

	// If quantity increases, add new empty option sets
	if (quantity > updatedOptions.length) {
		const additionalOptions = createAdditionalOptions(item.item.options!, quantity - updatedOptions.length);
		updatedOptions = [...updatedOptions, ...additionalOptions];
	}

	return updatedOptions;
}

// Helper function to create additional options when quantity increases
function createAdditionalOptions(itemOptions: ItemOption[], additionalCount: number): SelectedOptionSet[] {
	return Array.from({ length: additionalCount }, () => ({
		_id: generateObjectId(),
		optionSelections: itemOptions.map((option) => ({
			_id: generateObjectId(),
			optionProperty: option.optionProperty,
			value: '', // Empty string for unselected value
			label: option.label,
		})),
	}));
}

// Helper function to adjust inventory item IDs when quantity changes
export function adjustInventoryIds(item: GroupedOrderItemInterface, quantity: number): string[] {
	return item.selectedInventoryItemIds.length > quantity
		? item.selectedInventoryItemIds.slice(0, quantity)
		: (item.availableInventoryItemIds?.slice(0, quantity) ?? item.selectedInventoryItemIds);
}

export function calculateTotalAdditionalPurchaseValue(
	products: GroupedOrderItemInterface[],
	isExpressShipping: boolean
) {
	if (!products || products.length === 0) {
		return { shippingCosts: 0, totalAdditionalPurchaseValue: 0, totalProductCosts: 0, totalProductValue: 0 };
	}

	// costs user has to pay in order to fulfill order
	const totalProductCosts = products
		.map((item) => Math.max(item.quantity - item.selectedInventoryItemIds.length) * item.item.price)
		.reduce((a, b) => a + b);

	// total value of products - used to check if min order value satisfied
	const totalProductValue = products.map((item) => item.quantity * item.item.price).reduce((a, b) => a + b, 0);

	const nonDigitalProducts = products.filter(
		(product) => !product.item.tags.some((tag) => tag.toLowerCase() === 'digital')
	);

	const baseShippingCosts =
		totalProductValue < MIN_ORDER_VALUE ? nonDigitalProducts.length * SHIPPING_COSTS_PER_ITEM : 0;

	const shippingCosts = isExpressShipping
		? calcExpressShippingCosts(totalProductValue) + baseShippingCosts
		: baseShippingCosts;

	const totalAdditionalPurchaseValue = shippingCosts + totalProductCosts;

	return { shippingCosts, totalAdditionalPurchaseValue, totalProductCosts, totalProductValue };
}

export const calcExpressShippingCosts = (baseOrderValue: number) => {
	let dynamicFactor = 0.15;
	if (baseOrderValue > 5000) {
		dynamicFactor = 0.05;
	} else if (baseOrderValue > 2501) {
		dynamicFactor = 0.065;
	} else if (baseOrderValue > 1001) {
		dynamicFactor = 0.1;
	} else if (baseOrderValue > 251) {
		dynamicFactor = 0.125;
	}
	return Math.round(dynamicFactor * baseOrderValue);
};
