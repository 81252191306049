/* eslint-disable max-lines-per-function */
import { faMagnifyingGlass, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import gsap from 'gsap';
import { useOutsideClick } from 'hooks';
import { useCallback, useEffect, useRef } from 'react';

interface Props {
	searchInput: string;
	onSearchInputChange: (value: string) => void;
}

export function SearchBtn({ searchInput, onSearchInputChange }: Props) {
	const searchIconRef = useRef(null);
	const searchTextRef = useRef(null);
	const searchInputRef = useRef<HTMLInputElement>(null);
	const searchBtnRef = useRef<HTMLDivElement>(null);
	const closeIconRef = useRef<HTMLButtonElement>(null);

	const timelineRef = useRef(gsap.timeline({ paused: true }));

	const closeSearchInput = useCallback(() => {
		searchInputRef.current?.blur();
		onSearchInputChange('');
		timelineRef.current.reverse();
	}, [onSearchInputChange]);

	function openSearchInput() {
		timelineRef.current.play();
	}

	useOutsideClick(searchBtnRef, () => {
		if (searchInput.length === 0) {
			closeSearchInput();
		}
	});

	useEffect(() => {
		const handleEscape = (e: KeyboardEvent) => {
			if (e.key === 'Escape') {
				closeSearchInput();
			}
		};

		window.addEventListener('keydown', handleEscape);

		return () => {
			window.removeEventListener('keydown', handleEscape);
		};
	}, [closeSearchInput]);

	useEffect(() => {
		timelineRef.current.set(searchInputRef.current, {
			pointerEvents: 'auto',
		});
		timelineRef.current.set(searchBtnRef.current, {
			minWidth: '170px',
			maxWidth: '170px',
		});

		timelineRef.current.set(searchIconRef.current, {
			right: '143px',
		});

		timelineRef.current.set(searchTextRef.current, {
			opacity: 0,
		});
		timelineRef.current.to(searchIconRef.current, {
			right: 12,
			duration: 0.4,
		});
		timelineRef.current.to(
			searchBtnRef.current,
			{
				minWidth: 'calc(100% + 6px)',
				maxWidth: 'calc(100% + 6px)',
				x: -4,
				height: 38,
				duration: 0.4,
			},
			'<'
		);
		timelineRef.current.to(
			searchInputRef.current,
			{
				opacity: 1,
				duration: 0.4,
				onComplete: () => {
					if (searchInputRef.current) {
						searchInputRef.current.focus();
					}
				},
			},
			'<'
		);
		timelineRef.current.to(closeIconRef.current, {
			opacity: 1,
			pointerEvents: 'auto',
			duration: 0.3,
		});
	}, []);

	function handleSearchClose(e: React.MouseEvent) {
		e.stopPropagation();
		closeSearchInput();
		onSearchInputChange('');
	}

	return (
		<div
			onClick={openSearchInput}
			onKeyDown={(e) => {
				if (e.key === 'Enter' || e.code === 'Space') {
					openSearchInput();
				}
			}}
			ref={searchBtnRef}
			role="button"
			tabIndex={0}
			className="relative bg-primary-gradient translate-x-[4px] box-border left-0 z-40 flex items-center border-[2px] border-darkBlue outline outline-[2px] outline-white rounded-[20px] h-[28px] text-white w-[170px]"
		>
			<FontAwesomeIcon
				ref={searchIconRef}
				icon={faMagnifyingGlass}
				className="z-20 absolute right-[143px] top-[50%] translate-y-[-50%] text-[15px]"
			/>
			<span ref={searchTextRef} className="absolute right-3 top-[50%] translate-y-[-50%] text-[15px] font-semibold">
				Search Products
			</span>
			<input
				spellCheck={false}
				value={searchInput}
				onChange={(e) => onSearchInputChange(e.target.value)}
				ref={searchInputRef}
				className={`rounded-[3px] font-semibold text-white relative outline-none text-center pointer-events-none z-10 py-1 pl-3 pr-8 text-[13px] w-full h-full bg-transparent`}
				type="text"
			/>
			<button
				onClick={handleSearchClose}
				ref={closeIconRef}
				className={`z-20 opacity-0 pointer-events-none hover:scale-110 text-white text-[17px] left-3 absolute top-[50%] translate-y-[-50%]`}
			>
				<FontAwesomeIcon icon={faXmark} />
			</button>
		</div>
	);
}
