import { DeliveryTruck } from 'assets/images';
import { Skeleton } from 'components';
import { PublicOrderStatus } from 'interfaces/OrderInterfaces';

interface Props {
	status?: PublicOrderStatus;
	isLoading: boolean;
}

export function OrderStatusSection({ status, isLoading }: Props) {
	const infoText =
		status === PublicOrderStatus.DELIVERED
			? 'Order delivered!'
			: status === PublicOrderStatus.CANCELLED
				? 'Order cancelled!'
				: status === PublicOrderStatus.IN_PROCESS
					? 'Order in progress'
					: 'Order on its way';

	return (
		<div className="px-mobile-side-padding relative w-full h-[120px] bg-mrLootBlue text-white flex flex-col pt-[15px]">
			{status && <h2 className="text-[24px] font-bold">{infoText}</h2>}
			{isLoading && <Skeleton style={{ height: '36px', width: '190px' }} color="#d1d1d6" />}
			<img src={DeliveryTruck} className="absolute bottom-0 right-[12px] h-[53px] w-auto" alt="" />
		</div>
	);
}
