import { OpenFont } from 'assets/images';
import { AmountDisplayWithCurrency } from 'components/common/AmountDisplayWithCurrency';
import { PrimaryBtn } from 'components/common/buttons/PrimaryBtn';
import { WithImgSkeleton } from 'components/common/WithImgSkeleton';
import { DBUserInventoryBoxWithBoxObj } from 'interfaces/InventoryInterface';
import { useNavigate } from 'react-router';
import { getBoxLink, getImageByNumber } from 'utils';

import { CLOUDFRONT_IMG_URL } from '../../../../constants';

interface Props {
	box: DBUserInventoryBoxWithBoxObj;
}

export function MobileInventoryBoxCard({ box }: Props) {
	const navigate = useNavigate();

	function handleBoxClick() {
		const boxLink = getBoxLink(box.boxId.name);
		navigate(boxLink);
	}

	return (
		<div
			className="relative group size-container flex flex-col flex-shrink-0 rounded-[18px] h-[222px] aspect-[0.65/1] overflow-hidden outline-white outline-[1px] outline hover:outline-[2px]"
			role="button"
			tabIndex={0}
			aria-label={`Open box ${box.boxId.name}`}
		>
			<div className="absolute z-20 right-0 top-0 flex items-center justify-center w-[50px] h-[35px] bg-mrLootBlue rounded-[0_0_0_8px]">
				<img src={getImageByNumber(box.qty)} alt={box.qty?.toString()} className="h-[28px] object-contain" />
			</div>

			<WithImgSkeleton>
				<img
					src={`${CLOUDFRONT_IMG_URL}/${box.boxId.portraitImageUrls?.qualityHigh}`}
					alt={'image of box ' + box.boxId.name}
					className="w-full h-full object-cover"
				/>
			</WithImgSkeleton>

			<div className="group-hover:translate-y-full absolute px-[18px] h-[11cqh] min-w-[69px] w-fit rounded-[24px_24px_0_0] left-[50%] bottom-0 translate-x-[-50%] bg-[#2377F4] flex items-center pt-[1cqh] justify-center space-x-[5px] text-white font-semibold transition-all">
				<AmountDisplayWithCurrency
					amount={box.boxId.price}
					amountStyle={{ letterSpacing: '0.5px', fontSize: '5.5cqh', fontWeight: 'bold' }}
					imgStyle={{ height: '6.5cqh' }}
					useDivWrapper={false}
				/>
			</div>

			<div className="group-hover:translate-y-0 translate-y-full absolute bottom-0 flex flex-col gap-y-[8px] w-full px-[13px] pb-[8px] transition-all">
				<PrimaryBtn
					content={<img src={OpenFont} alt="Open" className="h-[15px]" />}
					style={{ width: '100%', height: '45px' }}
					onClick={handleBoxClick}
				/>
			</div>
		</div>
	);
}
