import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StarsIcon } from 'assets/images';
import { Line } from 'components/common/Line';
import { SectionHeader } from 'components/common/SectionHeader';
import { MobileBackground } from 'components/layout/background/MobileBackground';
import { useSlideUpAndDragToDismiss } from 'hooks';
import { UnboxedHistoryInterface } from 'interfaces/UnboxedHistoryInterface';
import { ServerSeedRevealBtn } from 'pages/AccountSettings/desktop/winbox-history/ServerSeedRevealBtn';
import { Fragment, useRef } from 'react';

interface Props {
	onClose: () => void;
	unboxedBox: UnboxedHistoryInterface;
}

export function WinBoxHistoryDetailsOverlay({ onClose, unboxedBox }: Props) {
	const parentRef = useRef<HTMLDivElement>(null);
	const contentRef = useRef<HTMLDivElement>(null);

	const { playDismissAnimation } = useSlideUpAndDragToDismiss({
		ref: parentRef,
		contentRef,
		onDismiss: onClose,
		animationTime: 0.3,
		dissmissAnimationTime: 0.25,
	});

	return (
		<>
			<div className="bg-[rgba(0,0,0,0.35)] left-0 top-0 w-full fixed z-30 h-dvh" />
			<div
				className="rounded-[16px_16px_0_0] flex flex-col items-center space-y-[7px] will-change-transform absolute z-40 inset-0 mt-[10px] overflow-hidden min-h-dvh"
				ref={parentRef}
			>
				<div className="w-[50px] min-h-[5px] bg-[rgba(255,255,255,0.6)] z-40 rounded-full" />
				<div
					ref={contentRef}
					className="relative bg-background overflow-y-scroll rounded-[16px_16px_0_0] overflow-hidden overscroll-none w-full"
				>
					<MobileBackground style={{ paddingBottom: '150px' }}>
						<div className="z-10 flex flex-col space-y-[24px] px-mobile-side-padding pt-[25px]">
							<SectionHeader
								title="WinBox History Details"
								iconSrc={StarsIcon}
								rightContent={
									<button
										type="button"
										onClick={playDismissAnimation}
										className="rounded-[12px] text-[15px] text-white bg-[rgba(0,0,0,0.25)] flex items-center justify-center h-[35px] w-[35px]"
									>
										<FontAwesomeIcon icon={faXmark} />
									</button>
								}
							/>
							<div className="grid grid-cols-2 gap-y-[10px] text-[13px] text-white">
								<span>BoxName:</span>
								<h2 className="font-bold">{unboxedBox.box.name}</h2>

								<span>Won Item:</span>
								<h2 className="font-bold line-clamp-1">{unboxedBox.wonItems[0].name}</h2>
							</div>
							<Line />
							<div className="grid grid-cols-2 gap-y-[10px] text-[13px] text-white items-center">
								<span>Client Seed:</span>
								<h2 className="font-bold">{unboxedBox.clientSeed}</h2>

								<span>Nonce:</span>
								<h2 className="font-bold">{unboxedBox.nonce}</h2>

								<span>Hashed Server Seed:</span>
								<h2 className="font-bold text-wrap break-all">{unboxedBox.hashedServerSeed}</h2>

								<span>Server Seed:</span>
								<ServerSeedRevealBtn hashedServerSeed={unboxedBox.hashedServerSeed} />
							</div>
							<Line />
							<div className="flex items-center gap-x-[10px] text-white font-semibold text-[15px]">
								<span>Dice Number:</span>
								<h2 className="font-bold">{Math.round(unboxedBox.diceNumber).toLocaleString('Fr-fr')}</h2>
							</div>
							<div className="flex flex-col gap-y-[10px] text-white font-semibold text-[18px]">
								<div className="grid grid-cols-2 gap-y-[10px] text-[13px]">
									<span className="text-[15px]">Item Distribution:</span>
									<span className="text-[15px] text-right">Dice Range</span>
									{unboxedBox.itemsDistribution.map((el) => (
										<Fragment key={el._id}>
											<span className={`${el.itemId === unboxedBox.wonItems[0]._id && 'text-mrLootBlue'}`}>
												{el.name}
											</span>
											<span className={`text-right ${el.itemId === unboxedBox.wonItems[0]._id && 'text-mrLootBlue'}`}>
												{Math.round(el.startRange).toLocaleString('Fr-fr')}-
												{Math.round(el.endRange).toLocaleString('Fr-fr')}
											</span>
										</Fragment>
									))}
								</div>
							</div>
						</div>
					</MobileBackground>
				</div>
			</div>
		</>
	);
}
