interface Props {
	numSlides: number;
	activeIndex: number;
	onActiveIndexChange: (index: number) => void;
}

export function SlidePagination({ numSlides, activeIndex, onActiveIndexChange }: Props) {
	return (
		<div className="absolute z-20 right-[25px] bottom-[30px] flex items-center">
			{Array.from({ length: numSlides }, (_, index) => (
				<button onClick={() => onActiveIndexChange(index)} key={index} className="p-1 cursor-pointer outline-none">
					<div
						style={{ transition: 'all 0.4s' }}
						className={`rounded-[6px] h-[10px] ${activeIndex === index ? 'bg-white w-[20px]' : ' w-[10px] bg-[rgba(255,255,255,0.4)] hover:scale-110 hover:bg-[rgba(255,255,255,0.6)]'}`}
					/>
				</button>
			))}
		</div>
	);
}
