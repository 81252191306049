import { cloneElement, CSSProperties, ReactElement, useEffect, useRef, useState } from 'react';

import { Skeleton } from './Skeleton';

interface Props {
	children: ReactElement;
	skeletonHeight?: number | string;
	skeletonWidth?: number | string;
	style?: CSSProperties;
	containerStyle?: CSSProperties;
}

export function WithSkeletonUntilFullyLoaded({
	children,
	containerStyle,
	skeletonHeight,
	skeletonWidth,
	style,
}: Props) {
	const [isLoaded, setIsLoaded] = useState(false);
	const containerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const imgs = containerRef.current ? Array.from(containerRef.current.getElementsByTagName('img')) : [];
		if (imgs.length === 0) {
			setIsLoaded(true); // No images to load, set loaded to true immediately
			return;
		}

		const handleLoads = imgs.map((img) => {
			const handleLoad = () => {
				const allLoaded = imgs.every((img) => img.complete && img.naturalHeight !== 0);
				if (allLoaded) {
					setIsLoaded(true);
				}
			};

			if (img.complete && img.naturalHeight !== 0) {
				handleLoad();
			} else {
				img.addEventListener('load', handleLoad);
				img.addEventListener('error', handleLoad);
			}

			return handleLoad; // Return this function for later removal
		});

		// Cleanup function
		return () => {
			imgs.forEach((img, index) => {
				img.removeEventListener('load', handleLoads[index]);
				img.removeEventListener('error', handleLoads[index]);
			});
		};
	}, []);

	const visibilityStyle: CSSProperties = {
		visibility: isLoaded ? 'visible' : 'hidden',
		position: isLoaded ? 'static' : 'absolute',
		top: isLoaded ? '' : '50%',
		transform: isLoaded ? '' : 'translateY(-50%)',
	};
	const avisibilityStyle: CSSProperties = isLoaded ? {} : { display: 'none' };

	const childElement = cloneElement(children, {
		style: { ...children.props.style, ...visibilityStyle },
	});

	return (
		<div ref={containerRef} className="relative h-fit" style={containerStyle}>
			{!isLoaded && <Skeleton style={style ?? children.props.style} height={skeletonHeight} width={skeletonWidth} />}
			{childElement}
		</div>
	);
}
