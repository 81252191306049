import { gsap } from 'gsap';
import { useEffect } from 'react';

export function useGsapConfig() {
	useEffect(() => {
		const defaultFps = 60;
		const threshold = 100;
		const lagSmoothing = 33;

		gsap.ticker.fps(defaultFps);
		gsap.ticker.lagSmoothing(threshold, lagSmoothing);
	}, []);
}
