import { AmountDisplayWithCurrency } from 'components/common/AmountDisplayWithCurrency';
import { ItemInterface } from 'interfaces/ItemInterfaces';
import { getBgCardUrl } from 'utils';

import { CLOUDFRONT_IMG_URL, rarities } from '../../../constants';

interface Props {
	item: ItemInterface;
	selected?: boolean;
}

export function SimpleItemCard({ item, selected }: Props) {
	return (
		<div
			className={`${selected ? 'border-white' : 'border-transparent'} border-[1.5px] relative flex flex-col items-center justify-evenly flex-shrink-0 rounded-[5px]
         h-[45px] w-[31px] xs:h-[42px] xs:w-[27px] ss:h-[45px] ss:w-[31px] lg:h-[45px] lg:w-[30px] xl:h-[65px] xl:w-[43px]`}
		>
			<img
				alt={`Card background color is ${rarities[item.rarity].label}`}
				src={getBgCardUrl(item.rarity)}
				className="z-[-1] h-full w-full object-fill absolute inset-0"
			/>
			<img
				src={`${CLOUDFRONT_IMG_URL}/${item.imageUrls.qualityMedium}`}
				alt={item.name}
				className="h-[35%] max-w-[85%] object-contain"
			/>

			<AmountDisplayWithCurrency
				amount={item.price}
				imgStyle={{ height: '5px' }}
				amountStyle={{ fontSize: '4px' }}
				style={{ width: '90%', display: 'flex', justifyContent: 'center', gap: '2px' }}
			/>
		</div>
	);
}
