import { EmptyInventoryFont, EmptyInventoryMrloot } from 'assets/images';
import { PrimaryBtn } from 'components/common/buttons/PrimaryBtn';
import { useNavigate } from 'react-router-dom';
import { usePopupStore } from 'store/usePopupStore';
import { shallow } from 'zustand/shallow';

import { FontStartOpening } from '../assets';

interface Props {
	needToDeposit: boolean;
}

export default function EmptyInventory({ needToDeposit }: Props) {
	const navigate = useNavigate();
	const setIsDepositPopupVisible = usePopupStore((state) => state.setIsDepositPopupVisible, shallow);

	function handleClick() {
		if (needToDeposit) {
			setIsDepositPopupVisible(true);
		} else {
			navigate('/');
		}
	}
	return (
		<div className="flex flex-col gap-y-[30px] items-center justify-center my-auto w-full h-[calc(100vh-230px)] md:h-[calc(100vh-165px)]">
			<img
				alt=""
				src={EmptyInventoryMrloot}
				className="object-scale-down h-[25vh] xs:h-[29vh] ss:h-[32vh] sm:h-[35vh] max-w-full"
			/>
			<img
				alt="Inventory is empty"
				src={EmptyInventoryFont}
				className="object-scale-down h-[9vh] xs:h-[10vh] ss:h-[11vh] sm:h-[12vh] max-w-full"
			/>
			<PrimaryBtn
				content={<img src={FontStartOpening} alt="Start Opening now!" className="h-[24px] object-contain" />}
				onClick={handleClick}
				style={{ height: '54px', width: '250px', minHeight: '44px' }}
			/>
		</div>
	);
}
