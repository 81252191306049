import { Icon } from '@iconify/react';

export function SecureBatch() {
	return (
		<div className="flex items-center justify-center rounded-full h-[30px] gap-x-[8px] px-[12px] text-green-400 font-bold text-[12px] ms:text-[15px] sm:text-[16px]">
			<Icon icon="gravity-ui:shield-check" className="text-[13px] ms:text-[16px] sm:text-[17px] font-extrabold" />
			<span>GUARANTEED SAFE CHECKOUT</span>
		</div>
	);
}
