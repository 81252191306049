import { LoadSpinner } from 'components/common/loading/LoadSpinner';
import { CryptoPayCurrency } from 'pages/TransactionManager/deposit.interface';
import { useCryptoPaymentInitiator } from 'pages/TransactionManager/hooks/useCryptoPaymentInitiator';
import { currencyFullNames } from 'pages/TransactionManager/transaction.interface';
import { useEffect } from 'react';
import { useAppStore } from 'store/useAppStore';
import { shallow } from 'zustand/shallow';

import { Header } from '../../components/Header';
import { WalletInformation } from './WalletInformation';

interface Props {
	selectedCurrency: CryptoPayCurrency;
	onSelectedNetworkChange: (network: CryptoPayCurrency) => void;
	onBack: () => void;
}

export function CryptoDepositView({ selectedCurrency, onBack, onSelectedNetworkChange }: Props) {
	const { data: walletData, isLoading } = useCryptoPaymentInitiator({ currency: selectedCurrency });

	const setCryptoPollingStartTime = useAppStore((state) => state.setCryptoPollingStartTime, shallow);

	useEffect(() => {
		// starts crypto deposit polling
		setCryptoPollingStartTime(new Date());
	}, [setCryptoPollingStartTime]);

	const title = `Deposit ${currencyFullNames[selectedCurrency]} (${selectedCurrency.split('.')[0].toUpperCase()})`;

	return (
		<div className="flex flex-col items-center gap-y-[30px] h-full justify-between min-h-fit pb-[12px]">
			<Header title={title} onBackClick={onBack} />
			{walletData && (
				<WalletInformation
					onSelectedNetworkChange={onSelectedNetworkChange}
					selectedCurrency={selectedCurrency}
					walletAddress={walletData?.payAddress}
					memo={walletData.memo}
				/>
			)}
			{isLoading && (
				<div className="flex items-center justify-center min-h-[calc(100svh-125px)]">
					<LoadSpinner />
				</div>
			)}
		</div>
	);
}
