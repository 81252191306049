export function useEnterOrSpacePress(onPress: (value?: string) => void) {
	const handleKeyPress = (event: React.KeyboardEvent, value?: string) => {
		if (event.key === 'Enter' || event.code === 'Space') {
			event.preventDefault();
			onPress(value);
		}
	};

	return handleKeyPress;
}
