import { Skeleton } from 'components';

export function SkeletonMobileBoxOpenSettingsBar() {
	return (
		<div className="w-full flex items-center justify-between space-x-[7px]">
			<Skeleton style={{ height: '40px', width: 'calc(33vw - 27px)', borderRadius: '20px' }} />
			<Skeleton style={{ height: '40px', width: 'calc(33vw - 27px)', borderRadius: '20px' }} />
			<Skeleton style={{ height: '40px', width: 'calc(33vw - 27px)', borderRadius: '20px' }} />
		</div>
	);
}
