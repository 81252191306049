/* eslint-disable max-lines-per-function */
import { DeliveryTruckDriving, DeliveryTruckParking, FastCarDriving, FastCarParking } from 'assets/images';
import gsap from 'gsap';
import { useEffect, useRef } from 'react';

interface Props {
	isExpressShipping: boolean;
}

const TRANSITION_SMOOTH_UP_DELAY = 0.1;
const HEIGHT_DELIVERY_TRUCK = 44;
const HEIGHT_FAST_CAR = 63;
const FAST_CAR_OFF_X = -43;
const FAST_CAR_OFF_Y = 2;
const DELIVERY_TRUCK_OFF_Y = -2;
const DURATION_INIT_TO_RIGHT_END = 0.5;
const DURATION_LEFT_START_TO_INIT = 1.2;

const RACE_PROGRESS_STEPS = {
	INIT_START: 0,
	RIGHT_END: 1,
	LEFT_START: 2,
	EXTRA_ROUND: 3,
};

export function ExpressShippingTruckAnimation({ isExpressShipping }: Props) {
	const videoRefParking = useRef<HTMLVideoElement>(null);
	const videoRefDriving = useRef<HTMLVideoElement>(null);

	const isFirstTime = useRef(true);

	const timeline = useRef(gsap.timeline({ paused: true }));

	const raceProgress = useRef(0);
	const help = useRef(0);

	const debounceDelay = 250; // Adjust this value as needed
	const debounceTimeoutRef = useRef<ReturnType<typeof setTimeout>>();

	function changeDrivingVehicle(isExpressShipping: boolean) {
		if (videoRefDriving.current) {
			const newSource = isExpressShipping ? FastCarDriving : DeliveryTruckDriving;
			videoRefDriving.current.src = newSource;
			videoRefDriving.current.load();
		}
	}

	function changeParkingVehicle(isExpressShipping: boolean) {
		if (videoRefParking.current) {
			const newSource = isExpressShipping ? FastCarParking : DeliveryTruckParking;
			videoRefParking.current.src = newSource;
			videoRefParking.current.load();
		}
	}

	useEffect(() => {
		function animateFromStartToDestination(isExpressShipping: boolean) {
			// step 1 -> 2 | vehicle back to left start
			timeline.current.set(videoRefDriving.current, {
				x: -300,
				height: isExpressShipping ? HEIGHT_FAST_CAR : HEIGHT_DELIVERY_TRUCK,
				y: isExpressShipping ? FAST_CAR_OFF_Y : DELIVERY_TRUCK_OFF_Y,
				onComplete: () => {
					raceProgress.current = RACE_PROGRESS_STEPS.LEFT_START;
					changeParkingVehicle(isExpressShipping);
				},
			});

			// step 2 -> 0 | vehicle to destination
			timeline.current.to(videoRefDriving.current, {
				delay: 0.4,
				x: isExpressShipping ? FAST_CAR_OFF_X : 0,
				duration: DURATION_LEFT_START_TO_INIT,
			});

			timeline.current.set(videoRefParking.current, {
				opacity: 1,
				x: isExpressShipping ? FAST_CAR_OFF_X : 0,
				y: isExpressShipping ? FAST_CAR_OFF_Y : DELIVERY_TRUCK_OFF_Y,
				height: isExpressShipping ? HEIGHT_FAST_CAR : HEIGHT_DELIVERY_TRUCK,
				onComplete: () => {
					raceProgress.current = RACE_PROGRESS_STEPS.INIT_START;
				},
			});
			timeline.current.set(videoRefDriving.current, {
				opacity: 0,
				height: isExpressShipping ? HEIGHT_FAST_CAR : HEIGHT_DELIVERY_TRUCK,
				delay: TRANSITION_SMOOTH_UP_DELAY,
			});
		}

		if (isFirstTime.current) {
			isFirstTime.current = false;
			return;
		}

		if (debounceTimeoutRef.current) {
			clearTimeout(debounceTimeoutRef.current);
		}

		debounceTimeoutRef.current = setTimeout(() => {
			if (!videoRefParking.current || !videoRefParking) {
				return;
			}

			const progress = timeline.current.progress();
			const totalDuration = timeline.current.duration();
			const currentTime = timeline.current.time();
			timeline.current.clear();

			timeline.current.set(videoRefParking.current, { opacity: 0 });
			timeline.current.set(videoRefDriving.current, { opacity: 1 });

			if (raceProgress.current < RACE_PROGRESS_STEPS.LEFT_START) {
				help.current = 0;
				// 0 -> 1 | vehicle to right end
				timeline.current.to(videoRefDriving.current, {
					x: 100,
					duration: DURATION_INIT_TO_RIGHT_END,
					onComplete: () => {
						raceProgress.current = RACE_PROGRESS_STEPS.RIGHT_END;
						changeDrivingVehicle(isExpressShipping);
					},
				});

				animateFromStartToDestination(isExpressShipping);

				const threshold = TRANSITION_SMOOTH_UP_DELAY / totalDuration; // because 0.1 second delay to smooth up transition
				if (progress < 1 - threshold) {
					timeline.current.progress(progress);
				}
			} else {
				help.current =
					raceProgress.current === RACE_PROGRESS_STEPS.EXTRA_ROUND
						? help.current + currentTime
						: totalDuration - currentTime;

				const remainingTimeFirstStage =
					raceProgress.current === RACE_PROGRESS_STEPS.EXTRA_ROUND
						? DURATION_INIT_TO_RIGHT_END + DURATION_INIT_TO_RIGHT_END - help.current
						: DURATION_INIT_TO_RIGHT_END + (totalDuration - currentTime);

				raceProgress.current = RACE_PROGRESS_STEPS.EXTRA_ROUND;
				timeline.current.to(videoRefDriving.current, {
					x: 100,
					duration: remainingTimeFirstStage,
					onComplete: () => {
						raceProgress.current = RACE_PROGRESS_STEPS.RIGHT_END;
						changeDrivingVehicle(isExpressShipping);
					},
				});
				animateFromStartToDestination(isExpressShipping);
			}

			timeline.current.play();
		}, debounceDelay);

		return () => {
			if (debounceTimeoutRef.current) {
				clearTimeout(debounceTimeoutRef.current);
			}
		};
	}, [isExpressShipping]);

	return (
		<div className="absolute h-[65px] bottom-0 rounded-br-[12px] left-[-2px] overflow-hidden w-[calc(100%+4.5px)]">
			<video
				ref={videoRefParking}
				preload="auto"
				muted
				autoPlay
				loop
				className="absolute left-[165px] bottom-0"
				style={{ height: `${HEIGHT_DELIVERY_TRUCK}px` }}
			>
				<source src={DeliveryTruckParking} type="video/mp4" />
				Your browser does not support the video tag.
			</video>

			<video
				ref={videoRefDriving}
				preload="auto"
				muted
				autoPlay
				loop
				className="absolute opacity-0 left-[165px] bottom-0"
				style={{ height: `${HEIGHT_DELIVERY_TRUCK}px` }}
			>
				<source src={DeliveryTruckDriving} type="video/mp4" />
				Your browser does not support the video tag.
			</video>
		</div>
	);
}
