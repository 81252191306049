import { useBoxOpeningStoreDesktop } from 'pages/OpenBox/store/useBoxOpeningStoreDesktop';
import { useBoxOpeningStoreMobile } from 'pages/OpenBox/store/useBoxOpeningStoreMobile';
import { useCallback, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { generateRandomSeed } from 'utils';
import { shallow } from 'zustand/shallow';

interface Props {
	isMobile: boolean;
	initOnMount?: boolean;
}

/**
 * manages the client seed, which is stored in cookies, for a box-opening experience.
 * Depending on whether the device is mobile or desktop, the hook sets the seed in the respective store.
 * It also provides a way to manually update the seed, generating a new one if none exists.
 *
 * @param {Object} params - Parameters for the hook.
 * @param {boolean} params.isMobile - Specifies if the device is mobile. Determines which store (mobile or desktop) to use for the client seed.
 * @param {boolean} [params.initOnMount=true] - Optional flag to initialize the client seed automatically on hook mount.
 *
 * @returns {Object} - Returns an object containing:
 * - `updateClientSeed`: A function to update the client seed. If a seed is provided, it will set it as the new seed.
 *   If none is provided, it generates a random seed and stores it in cookies.
 */
export function useBoxClientSeed({ isMobile, initOnMount = true }: Props) {
	const { setClientSeedMobile } = useBoxOpeningStoreMobile((state) => ({
		setClientSeedMobile: state.setClientSeed,
	}));

	const { setClientSeedDesktop } = useBoxOpeningStoreDesktop(
		(state) => ({
			setClientSeedDesktop: state.setClientSeed,
		}),
		shallow
	);
	const [clientSeedCookie, setClientSeedCookie] = useCookies(['client_seed']);

	const updateClientSeed = useCallback(
		(updatedClientSeed?: string) => {
			let clientSeedFromCookies = clientSeedCookie.client_seed; // getting seed from cookies

			if (updatedClientSeed) {
				setClientSeedCookie('client_seed', updatedClientSeed, { path: '/' });
				clientSeedFromCookies = updatedClientSeed;
				return;
			} else if (!clientSeedFromCookies) {
				const newClientSeed = generateRandomSeed();
				setClientSeedCookie('client_seed', newClientSeed, { path: '/' });
				clientSeedFromCookies = newClientSeed;
			}

			if (isMobile) {
				setClientSeedMobile(clientSeedFromCookies);
			} else {
				setClientSeedDesktop(clientSeedFromCookies);
			}
			return clientSeedFromCookies;
		},
		[clientSeedCookie.client_seed, isMobile, setClientSeedCookie, setClientSeedDesktop, setClientSeedMobile]
	);

	useEffect(() => {
		if (initOnMount) {
			updateClientSeed();
		}
	}, [updateClientSeed, initOnMount]);

	return { updateClientSeed };
}
