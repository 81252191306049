import axios from 'axios';
import { PopulatedBoxInterface } from 'interfaces/BoxInterface';
import { useQuery } from 'react-query';

import { API_DOMAIN, QUERY_CACHE_CONFIG, QUERY_KEYS } from '../../../constants';

const fetchBoxFromDb = async (boxname?: string) => {
	if (!boxname) {
		return;
	}

	const { data } = await axios.post(
		`${API_DOMAIN.mrlootBackend}/api/box/get-populated`,
		{ boxName: boxname },
		{ withCredentials: true }
	);

	if (data.status !== 'success') {
		throw new Error('Fetching box failed');
	}

	return data.data;
};

export function useBoxFromDb(boxname?: string) {
	return useQuery<PopulatedBoxInterface, Error>({
		queryKey: [QUERY_KEYS.boxData, boxname],
		queryFn: () => fetchBoxFromDb(boxname),
		staleTime: QUERY_CACHE_CONFIG.shortTerm.staleTime,
		cacheTime: QUERY_CACHE_CONFIG.shortTerm.cacheTime,
		enabled: !!boxname && boxname?.length > 0,
	});
}
