import { useBackBtnInTopBar } from 'hooks/app-layout/useBackBtnInTopbar';
import { useTopbarBackBtnListener } from 'hooks/events/useTopbarBackBtnListener';
import { ShippingAddressInterface } from 'interfaces/OrderInterfaces';
import { useCallback } from 'react';
import { FieldErrors, UseFormRegister, UseFormSetValue } from 'react-hook-form';

import { AddressInputField } from './AddressInput';
import { OptionalAddressInputField } from './OptionalInputField';
import { OptionalShippingInstructions } from './OptionalShippingInstructions';
import { PhoneInputs } from './PhoneInput';
import { StepHeading } from './StepHeading';

interface Props {
	isSaveBtnDisabled: boolean;
	errors: FieldErrors<ShippingAddressInterface>;
	setValue: UseFormSetValue<ShippingAddressInterface>;
	register: UseFormRegister<ShippingAddressInterface>;
}

export function AddressForm({ isSaveBtnDisabled, errors, register, setValue }: Props) {
	useTopbarBackBtnListener(
		useCallback(() => {
			window.history.back();
		}, [])
	);

	useBackBtnInTopBar();

	return (
		<div className="space-y-[16px]">
			{/* STEP 1 **/}
			<div className="w-full flex flex-col space-y-[8px]">
				<span className="text-[#868E96] text-[12px]">*Required fields.</span>
				<StepHeading stepNumber={1} heading="Recipents Information" />
			</div>
			<div className="flex flex-col space-y-[10px]">
				<AddressInputField
					register={register('firstName', { required: 'First name is required' })}
					placeholder="First Name*"
					type="text"
					error={errors.firstName}
				/>
				<AddressInputField
					register={register('lastName', { required: 'Surname is required' })}
					placeholder="Surname*"
					type="text"
					error={errors.lastName}
				/>
			</div>
			<div className="flex flex-col gap-y-[10px] !mt-[30px]">
				<PhoneInputs register={register} setValue={setValue} error={errors.phoneNumber} />
				<span className="text-[#868E96] text-[12px]">For shipping related questions only.</span>
			</div>

			{/* STEP 2 **/}
			<div className="w-full flex flex-col items-start space-y-[10px] !mt-[25px]">
				<StepHeading stepNumber={2} heading="Shipping Address" />

				<div className="flex justify-between !mt-[16px]">
					<AddressInputField
						register={register('street', { required: 'Street is required' })}
						error={errors.street}
						placeholder="Street*"
						type="text"
						style={{ width: 'calc(65% - 5px)' }}
					/>
					<AddressInputField
						register={register('houseNr', { required: 'House number is required' })}
						error={errors.houseNr}
						placeholder="House Nr*"
						type="text"
						style={{ width: 'calc(35% - 5px)' }}
					/>
				</div>

				<OptionalAddressInputField placeholder="Address 2 (Optional)" register={register('aptNr')} type="text" />

				<AddressInputField
					register={register('country', { required: 'Country is required' })}
					error={errors.country}
					placeholder="Country*"
					type="text"
					style={{ marginTop: '35px' }}
				/>
				<AddressInputField register={register('state')} placeholder="State (Optional)" type="text" />
				<div className="flex justify-between">
					<AddressInputField
						register={register('city', { required: 'City is required' })}
						error={errors.city}
						placeholder="City*"
						type="text"
						style={{ width: 'calc(60% - 5px)' }}
					/>
					<AddressInputField
						register={register('zipCode', { required: 'ZIP Code is required' })}
						error={errors.zipCode}
						placeholder="ZIP Code*"
						type="text"
						style={{ width: 'calc(40% - 5px)' }}
					/>
				</div>
				<OptionalShippingInstructions register={register('shippingInstructions')} />
			</div>

			{/* Buttons */}
			<div className="flex items-center justify-between !mt-[40px]">
				<button
					type="button"
					onClick={() => window.history.back()}
					className="rounded-[4px] border-white border-[2px] h-[60px] text-white font-semibold w-[calc(50%-5px)] flex items-center justify-center"
				>
					Cancel
				</button>
				<button
					disabled={isSaveBtnDisabled}
					type="submit"
					className={`${isSaveBtnDisabled && 'opacity-70'} rounded-[4px] bg-mrLootBlue h-[60px] text-white font-semibold w-[calc(50%-5px)] flex items-center justify-center`}
				>
					Save
				</button>
			</div>
		</div>
	);
}
