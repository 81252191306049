import { AmountDisplayWithCurrency } from 'components/common/AmountDisplayWithCurrency';
import { useUserContext } from 'hooks/context/useUserContext';
import { useDynamicBalanceWidth } from 'hooks/utility/layout-measurements/useDynamicBalanceWidth';
import { useRef } from 'react';
import { CurrencyOptions, useAppStore } from 'store/useAppStore';
import { useBalanceStore } from 'store/useBalanceStore';
import { shallow } from 'zustand/shallow';

export function FullScreenBalanceDisplay() {
	const selectedCurrency = useAppStore((state) => state.selectedCurrency, shallow);
	const { balance, isHighlighted } = useBalanceStore(
		(state) => ({ balance: state.balance, isHighlighted: state.isHighlighted }),
		shallow
	);

	const balanceRef = useRef<HTMLDivElement>(null);
	const { user } = useUserContext();
	useDynamicBalanceWidth({ balanceRef, baseWidth: 70, currency: selectedCurrency });

	const displayedBalance = balance !== undefined ? balance : (user?.balance ?? 0);

	return (
		<>
			{user && (
				<div
					ref={balanceRef}
					className="fixed grid grid-cols-[1fr_2fr_1fr] right-[25px] top-[35px] rounded-[24px] drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)] border-[1.5px] text-white border-white bg-[rgba(255,255,255,0.3)] h-[38px] px-[5px]"
				>
					<AmountDisplayWithCurrency
						amount={displayedBalance}
						useDivWrapper={false}
						amountStyle={{
							color: isHighlighted ? '#52ff58' : 'white', // Highlight when necessary
							textShadow: isHighlighted ? '0 1px 2px #3c394275' : 'none', // Highlight when necessary
							fontSize: '15px',
							textAlign: 'center',
						}}
						imgStyle={{ height: '15px' }}
						FirstElementWrapper={({ children }) => (
							<div
								className={`${selectedCurrency === CurrencyOptions.LOOTIES ? 'col-start-2 col-end-3 ' : 'col-start-1 col-end-2 pl-2'} flex items-center justify-center `}
							>
								{children}
							</div>
						)}
						SecondElementWrapper={({ children }) => (
							<span
								className={`${selectedCurrency === CurrencyOptions.LOOTIES ? 'col-start-3 col-end-4' : 'col-start-2 col-end-3 '} flex  items-center justify-center`}
							>
								{children}
							</span>
						)}
					/>
				</div>
			)}
		</>
	);
}
