import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Icon } from '@iconify/react';
import { MobileBackground } from 'components/layout/background/MobileBackground';
import { useScrollLock, useUserContext } from 'hooks';
import { useLogout } from 'hooks/data/user/useLogout';
import { useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { usePopupStore } from 'store/usePopupStore';
import { shallow } from 'zustand/shallow';

import { MobileMenuHeader } from './MobileMenuHeader';

interface Props {
	onClose: () => void;
}

const NO_SESSION_MENU_ITEMS = [
	{
		label: 'Home',
		icon: 'ant-design:home-filled',
		link: '/',
	},
	{
		label: 'Discovery',
		icon: 'mdi:compass-outline',
		link: '/discover',
	},
	{
		label: 'Login',
		icon: 'grommet-icons:login',
		link: '/auth/login',
	},
	{
		label: 'Support',
		icon: 'streamline:customer-support-1',
		link: '/contact',
	},
];

export function MobileMenu({ onClose }: Props) {
	useScrollLock();

	const { user, load: reloadUser } = useUserContext();

	const { mutate: logoutUser } = useLogout({
		onSuccess: () => {
			reloadUser();
		},
	});

	function handleLogout() {
		logoutUser();
		onClose();
	}

	const setIsDepositPopupVisible = usePopupStore((state) => state.setIsDepositPopupVisible, shallow);

	const SESSION_MENU_ITEMS = useMemo(
		() => [
			{
				label: 'Home',
				icon: 'ant-design:home-filled',
				link: '/',
			},
			{
				label: 'Discovery',
				icon: 'mdi:compass-outline',
				link: '/discover',
			},
			{
				label: 'Inventory',
				icon: 'material-symbols:inventory-2-outline',
				link: '/inventory',
			},
			{
				label: 'Wallet',
				icon: 'solar:wallet-linear',
				onClick: () => {
					setIsDepositPopupVisible(true);
					onClose();
				},
			},
			{
				label: 'My Orders',
				icon: 'lets-icons:order',
				link: '/my-account/my-orders',
			},
			{
				label: 'My Account',
				icon: 'ph:user-bold',
				link: '/my-account',
			},
		],
		[onClose, setIsDepositPopupVisible]
	);

	const navigate = useNavigate();

	function handleMenuElementClick(event: string | (() => void)) {
		if (typeof event === 'string') {
			navigate(event);
		} else {
			event();
		}
	}

	return (
		<MobileBackground>
			<div className="px-mobile-side-padding max-h-dvh h-dvh overflow-y-auto pt-[25px]">
				<MobileMenuHeader onLogoClick={onClose} onIconClick={onClose} />

				<div className="grid grid-cols-2 xs:grid-cols-3 ms:grid-cols-4 gap-y-[26px] gap-x-[37px] px-[15px] mt-[30px] pb-mobile-bottom-padding">
					{user && (
						<>
							{SESSION_MENU_ITEMS.map((el) => (
								<button
									key={el.label}
									onClick={() => handleMenuElementClick(el.onClick ?? el.link)}
									className="w-full aspect-square bg-[rgba(0,0,0,0.35)] rounded-[12px] grid grid-rows-3 items-center justify-center justify-items-center text-white pt-[15px] gap-y-[15px]"
								>
									<h2 className="text-[18px] font-bold self-end">{el.label}</h2>
									<Icon icon={el.icon} height={26} width={26} />
								</button>
							))}
							<button className="custom-intercom-launcher w-full aspect-square bg-[rgba(0,0,0,0.35)] rounded-[12px] grid grid-rows-3 items-center justify-center justify-items-center text-white pt-[15px] gap-y-[15px]">
								<h2 className="text-[18px] font-bold self-end">Support</h2>
								<Icon icon="streamline:customer-support-1" height={26} width={26} />
							</button>
							<button
								onClick={handleLogout}
								className="w-full aspect-square bg-[rgba(0,0,0,0.35)] rounded-[12px] grid grid-rows-3 items-center justify-center justify-items-center text-white pt-[15px] gap-y-[15px]"
							>
								<h2 className="text-[18px] font-bold self-end">Logout</h2>
								<FontAwesomeIcon icon={faRightFromBracket} className="text-[26px]" />
							</button>
						</>
					)}
					{!user &&
						NO_SESSION_MENU_ITEMS.map((el) => (
							<Link
								key={el.label}
								onClick={onClose}
								to={el.link}
								className="w-full aspect-square bg-[rgba(0,0,0,0.35)] rounded-[12px] grid grid-rows-3 items-center justify-center justify-items-center  text-white pt-[15px] gap-y-[15px]"
							>
								<h2 className="text-[18px] text-center font-bold self-end">{el.label}</h2>
								<Icon icon={el.icon} height={26} width={26} />
							</Link>
						))}
				</div>
			</div>
		</MobileBackground>
	);
}
