import { ErrorMrloot } from 'assets/images';
import { ReactNode } from 'react';

interface Props {
	message?: string;
	content?: ReactNode;
}

export function ToastErrorNotification({ message, content }: Props) {
	return (
		<div className="flex items-center">
			<img src={ErrorMrloot} alt="Error" className="min-h-[70px] h-[75px] flex-grow max-w-[25%] object-contain" />
			{content ? (
				content
			) : (
				<span className="text-center text-white font-bold text-[15px] mx-auto max-w-[70%]">{message}</span>
			)}
		</div>
	);
}
