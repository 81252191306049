import { PopulatedBoxItemInterface } from 'interfaces/BoxInterface';
import { useMemo, useState } from 'react';

interface Props {
	items?: PopulatedBoxItemInterface[];
	limit: number;
}

export function useItemPagination({ items = [], limit }: Props) {
	const [loadedEndIndex, setLoadedEndIndex] = useState(limit);
	const sortedItems = useMemo(
		() => [...items].sort((a, b) => b.itemId.price - a.itemId.price).slice(0, loadedEndIndex),
		[items, loadedEndIndex]
	);
	const hasMore = loadedEndIndex < items.length;
	const loadMore = () => {
		setLoadedEndIndex((prev) => Math.min(items.length, prev + limit));
	};

	return { displayedItems: sortedItems, loadMore, hasMore };
}
