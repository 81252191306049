import { ReactNode, useEffect } from 'react';

interface Props {
	title: string;
	children: ReactNode;
}

export function WithPageTitle({ title, children }: Props): JSX.Element {
	useEffect(() => {
		document.title = title;
	}, [title]);

	return <>{children}</>;
}
