import { gsap } from 'gsap';
import { useEffect, useMemo, useRef, useState } from 'react';

import { useBoxOpeningStoreMobile } from '../store/useBoxOpeningStoreMobile';
import { useSwitchToNewBoxInStack } from './hooks/useSwitchToNewBoxInStack';
import { MobileBoxInfo } from './mobileBoxInfo/MobileBoxInfo';
import { PopupPrizeInspect } from './popups/PopupPrizeInspect';
import { PopupSimiliarBoxes } from './popups/PopupSimiliarBoxes';

interface Props {
	isBoxOpenRequestLoading: boolean;
	onBoxOpen: (isDemoSpin: boolean) => void;
}

const MAX_RENDERED_BOX_STACKS = 2;

export function MobileBoxStack({ isBoxOpenRequestLoading, onBoxOpen }: Props) {
	const { boxPopupStack, isBoxOpening, setBoxPopupStack } = useBoxOpeningStoreMobile();
	const slicedBoxPopupStack = useMemo(() => boxPopupStack.slice(-MAX_RENDERED_BOX_STACKS), [boxPopupStack]);

	const [isInitSlideUpAnimationDeactivated, setIsInitSlideUpAnimationDeactivated] = useState(false);

	const boxRefs = useRef<(HTMLDivElement | null)[]>([]);

	const { isSlideAnimationComplete } = useSwitchToNewBoxInStack({
		boxStack: boxPopupStack,
		numRenderedStacks: MAX_RENDERED_BOX_STACKS,
		boxRefs,
	});

	// handles when user goes back to prev box in stack
	useEffect(() => {
		const handlePopState = (event: PopStateEvent) => {
			if (event.state && event.state.boxPopupStack) {
				setBoxPopupStack(event.state.boxPopupStack);
			}
		};

		window.addEventListener('popstate', handlePopState);

		return () => {
			window.removeEventListener('popstate', handlePopState);
		};
	}, [setBoxPopupStack]);

	useEffect(() => {
		if (boxPopupStack.length > 1) {
			setIsInitSlideUpAnimationDeactivated(true); // prevent slide up when boxstack goes from 2 to back to 1
		}
	}, [boxPopupStack]);

	function handleSwitchToPrevBoxInStack() {
		if (boxRefs.current.length > 1) {
			const currPage = boxRefs.current[1];
			const prevPage = boxRefs.current[0];

			gsap.set(prevPage, { opacity: 1 });

			gsap.to(currPage, { xPercent: 0, duration: 0.4, ease: 'power1.inOut' });
			gsap.to(prevPage, {
				xPercent: 0,
				duration: 0.4,
				onComplete: () => {
					const currentState = window.history.state;
					const currentTitle = document.title;
					const currentUrl = window.location.href;

					// Navigate back
					window.history.back();

					// Replace the previous state with the saved current state after a slight delay
					setTimeout(() => {
						window.history.replaceState(currentState, currentTitle, currentUrl);
					}, 100);
				},
			});
		} else {
			window.history.back();
		}
	}

	return (
		<div className="flex h-full w-full">
			{slicedBoxPopupStack.map((boxStack, index) => (
				<div
					key={boxStack.id}
					ref={(el) => (boxRefs.current[index] = el)}
					className="relative flex-shrink-0 w-full h-full"
				>
					{boxStack.isSimiliarBoxesPopupVisible && <PopupSimiliarBoxes boxName={boxStack.boxName} />}
					{boxStack.prizeInspectPopup && <PopupPrizeInspect item={boxStack.prizeInspectPopup} />}
					<MobileBoxInfo
						isInitSlideUpAnimationDeactivated={isInitSlideUpAnimationDeactivated || boxPopupStack.length > 1}
						showSkeleton={index === slicedBoxPopupStack.length - 1 && !isSlideAnimationComplete}
						boxName={boxStack.boxName}
						isBoxOpenDisabled={isBoxOpenRequestLoading || isBoxOpening}
						onBoxOpen={onBoxOpen}
						headerIcon={boxStack.id === boxPopupStack[0].id ? 'close' : 'back'}
						onHeaderIconClick={boxStack.id !== boxPopupStack[0].id ? handleSwitchToPrevBoxInStack : undefined}
					/>
				</div>
			))}
		</div>
	);
}
