import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Ellipse } from 'assets/images';
import gsap from 'gsap';
import { CSSProperties, useRef } from 'react';

interface Props {
	style?: CSSProperties;
	innerContentStyle?: CSSProperties;
	borderColor: string;
	background?: string;
	label: string;
	shadowColor?: string;
	shadowHoverColor?: string;
	hoverContent?: React.ReactNode;
	isLoading?: boolean;
	isDeactived?: boolean;
	onHover?: () => void;
	onLeave?: () => void;
	onClick?: () => void;
}

export function AnimatedBtn({
	borderColor,
	label,
	background,
	hoverContent,
	isDeactived,
	isLoading,
	onClick,
	onHover,
	onLeave,
	shadowColor,
	shadowHoverColor,
}: Props) {
	const contentWrapperRef = useRef<HTMLDivElement>(null);
	const buttonRef = useRef<HTMLButtonElement>(null);

	function handleHover() {
		gsap.to(contentWrapperRef.current, { translateY: '-50%', duration: 0.25 });
		gsap.to(buttonRef.current, {
			boxShadow: `0px 0px 12px ${shadowHoverColor || shadowColor}`,
			duration: 0.25,
		});
		if (onHover) {
			onHover();
		}
	}

	function handleLeave() {
		gsap.set(buttonRef.current, { boxShadow: `0px 0px 10px ${shadowColor}` });
		gsap.to(contentWrapperRef.current, { translateY: 'none', duration: 0.25 });
		if (onLeave) {
			onLeave();
		}
	}

	return (
		<button
			ref={buttonRef}
			className="w-[160px] h-[42px] bg-transparent rounded-[20px]"
			disabled={isDeactived || isLoading}
			onClick={onClick}
			onMouseEnter={handleHover}
			onMouseLeave={handleLeave}
		>
			<div className="overflow-hidden w-full h-full border-[3px] border-white rounded-full">
				<div
					className="size-container relative rounded-full h-full w-full "
					style={{ background: background, border: `2px solid ${borderColor}` }}
				>
					<img src={Ellipse} className="absolute left-[min(9px,5.5%)] top-[min(3px,4.5%)]" alt="" />

					<div className="absolute inset-0 flex items-center justify-center">
						{isLoading && <FontAwesomeIcon icon={faSpinner} className="animate-spin" />}

						{!isLoading && (
							<div className="overflow-hidden relative w-full h-full rounded-full text-white">
								<div
									ref={contentWrapperRef}
									className="flex flex-col items-center text-white font-bold text-[11px] md:text-[12px]"
								>
									<span
										className={`w-full leading-[15px] pointer-events-none flex items-center justify-center h-[100cqh]`}
									>
										{label}
									</span>
									<span className={`w-full pointer-events-none flex items-center justify-center h-[100cqh]`}>
										{hoverContent}
									</span>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</button>
	);
}

/*

<button
			ref={buttonRef}
			className={`${isDeactived && 'pointer-events-none opacity-50'}  w-[126px] sm:w-[155px] h-[42px]  rounded-[52px] flex items-center justify-center border-[1.5px]`}
			style={{
				borderColor: borderColor,
				background: background,
			}}
			disabled={isDeactived || isLoading}
			onClick={onClick}
			onMouseEnter={handleHover}
			onMouseLeave={handleLeave}
		>
            <div className='text-white'>

			{isLoading && <FontAwesomeIcon icon={faSpinner} className="animate-spin" />}

			{!isLoading && (
				<div className="overflow-hidden relative w-full h-full rounded-full">
					<div
						ref={contentWrapperRef}
						className="flex flex-col items-center text-white font-bold text-[11px] md:text-[12px]"
					>
						<span className={`w-full min-h-full pointer-events-none p-2 flex items-center justify-center`}>
							{label}
						</span>
						<span className={`w-full min-h-full pointer-events-none p-2 flex items-center justify-center`}>
							{hoverContent}
						</span>
					</div>
				</div>
			)}

            </div>
		</button>
*/
