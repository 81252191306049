/* eslint-disable max-lines */
import axios from 'axios';
import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { API_DOMAIN, QUERY_KEYS, screens } from '../../../constants';

const popularKeywords = [
	'MacBook',
	'iPhone',
	'Samsung Galaxy',
	'Xbox',
	'PlayStation',
	'Google Pixel',
	'Nintendo Switch',
	'Amazon Echo',
	'Kindle',
	'Sony Bravia',
	'Bose Headphones',
	'Instant Pot',
	'AirPods',
	'Crock-Pot',
	'Le Creuset',
	'North Face Backpack',
	'Patagonia Jacket',
	'Oakley Sunglasses',
	'Ray-Ban Sunglasses',
	'Converse Chuck Taylor',
	'Adidas Ultraboost',
	'Vans Slip-On',
	'Under Armour Hoodie',
	'Tommy Hilfiger Polo',
	'Coach Handbag',
	'Ralph Lauren Shirt',
	'Fossil Watch',
	'Garmin Watch',
	'Apple Watch',
	'TAG Heuer Watch',
	'Rolex Watch',
	'Omega Watch',
	'Sony Mirrorless Camera',
	'Polaroid Camera',
	'Ring Doorbell',
	'Sonos Speaker',
	'JBL Speaker',
	'Beats by Dre',
	'Sennheiser Headphones',
	'Logitech Mouse',
	'Razer Keyboard',
	'Microsoft Surface',
	'Dell XPS',
	'Calvin Klein Underwear',
	'Asus ROG',
	'Alienware Laptop',
	'Acer Predator',
	'Herman Miller Chair',
	'Steelcase Chair',
	'Ikea Desk',
	'West Elm Sofa',
	'Crate & Barrel Table',
	'Pottery Barn Bed',
	'Purple Mattress',
	'Sleep Number Bed',
	'Dyson Hair Dryer',
	'Parachute Home Towels',
	'GHD Hair Straightener',
	'Philips Electric Shaver',
	'Braun Electric Toothbrush',
	'Clarisonic Face Brush',
	'Foreo Luna',
	'La Roche-Posay Sunscreen',
	"Burt's Bees Lip Balm",
	'MAC Lipstick',
	'Urban Decay Palette',
	'Fenty Beauty',
	'IT Cosmetics',
	'Bobbi Brown',
	'Clinique',
	'Estee Lauder',
	'Lancome',
	'Dior',
	'Chanel',
	'Yves Saint Laurent',
	'Prada',
	'Hermes',
	'Louis Vuitton',
	'Gucci',
	'Burberry',
	'Balenciaga',
	'Armani',
	'Versace',
	'Givenchy',
	'Valentino',
	'Dolce & Gabbana',
	'Moncler',
	'Saint Laurent',
	'Canada Goose',
	'Dr. Martens Boots',
	'UGG Boots',
	'Hunter Boots',
	'Sperry Top-Sider',
	'Crocs',
	'Birkenstock Sandals',
	'Havaianas Flip Flops',
	'Carmex Lip Balm',
	"Arc'teryx Jacket",
	'Patagonia Fleece',
	'Marmot Jacket',
	'Black Diamond Gear',
	'Osprey Backpack',
	'Deuter Backpack',
	'CamelBak Hydration Pack',
	'GoPro HERO',
	'TomTom GPS',
	'Fitbit Charge',
	'Fitbit Versa',
	'Bose QuietComfort Earbuds',
	'Sony WF-1000XM4',
	'Apple AirPods Pro',
	'Jabra Elite',
	'Samsung Galaxy Buds',
	'JBL Flip',
	'Ultimate Ears Boom',
	'Google Chromecast',
	'Roku Streaming Stick',
	'Sonos Beam',
	'Klipsch Speaker',
	'Amazon Fire TV',
	'Marshall Speaker',
	'Apple TV',
	'Oculus Quest',
	'Nintendo Switch Lite',
	'HTC Vive',
	'Razer Blade',
	'Dell Inspiron',
	'Asus ZenBook',
	'PlayStation 5',
	'Samsung Odyssey',
	'Dell G5',
	'Microsoft Surface Pro',
	'Asus TUF',
	'Apple MacBook Pro',
	'MSI Stealth',
	'Razer Naga',
	'Corsair Scimitar',
	'Apple MacBook Air',
	'Razer BlackWidow',
	'Corsair K95',
	'Logitech G910',
	'SteelSeries Apex',
	'Secretlab Titan',
	'DXRacer Chair',
	'Noblechairs Hero',
	'West Elm Mid-Century',
	'Article Sofa',
	'Joybird Sofa',
	'AllModern Sofa',
	'Wayfair Sofa',
	'Burrow Sofa',
	'Floyd Sofa',
	'Ikea Markus',
	'Casper Pillow',
	'Purple Pillow',
	'Tempur-Pedic Pillow',
	'Sleep Number Pillow',
	'Coop Home Goods Pillow',
	'Parachute Home Comforter',
	'Nvidia Shield',
	'UGG Bedding',
	'Ralph Lauren Bedding',
	'Calvin Klein Bedding',
	'AKRacing Chair',
	'LL Bean Sheets',
	'Under Armour HeatGear',
	'Puma DryCell',
	'Adidas Climalite',
	"Arc'teryx Atom",
	'Mountain Hardwear Ghost Whisperer',
	'Outdoor Research Helium',
	'Black Diamond StormLine',
	'The North Face ThermoBall',
	"Levi's Denim Jacket",
	'Uniqlo Ultra Light',
	'Duluth Trading',
	'Red Wing Boots',
	'Timberland Pro',
	'Crocs Work',
	'La Sportiva Boots',
	'JanSport Backpack',
	'North Face Backpack',
	'CamelBak Backpack',
	'Patagonia Black Hole',
	'Tenba Messenger',
	'Puma Sneakers',
	'Lowepro Camera Bag',
	'Corsair Gaming Chair',
	'Secretlab Gaming Chair',
	'Skechers Sneakers',
	'TOMS Shoes',
	"Dr. Scholl's Shoes",
	'Rockport Shoes',
	'Ecco Shoes',
	'Alegria Shoes',
	'MSI Gaming Laptop',
	'Arozzi Gaming Chair',
	'Clarks Shoes',
	'New Balance Sneakers',
	'ASICS Running Shoes',
	'Brooks Running Shoes',
	'Xbox Series X',
	'Oral-B Toothbrush',
	'Herschel Supply Co.',
	'Peak Design Everyday',
	'Logitech G Pro',
	'Nike Air Max',
	'Nike Dri-FIT',
];

function getRandomKeyword(excludeIndices: Set<number>): { keyword: string; index: number } {
	let randomIndex;
	do {
		randomIndex = Math.floor(Math.random() * popularKeywords.length);
	} while (excludeIndices.has(randomIndex));
	return { keyword: popularKeywords[randomIndex], index: randomIndex };
}

function calculateTotalLength(keywords: string[]): number {
	return keywords.map((k) => k.length + 1).reduce((a, b) => a + b, 0) - 1; // include space for each keyword and remove the last added space
}

async function findSuggestions(query: string, characterLimit: number) {
	try {
		if (query.length === 0) {
			const pulledKeywords: string[] = [];
			const usedIndices = new Set<number>();
			let currentLength = 0;

			while (true) {
				const { keyword, index } = getRandomKeyword(usedIndices);

				if (currentLength + keyword.length + 1 > characterLimit) {
					break;
				} // Exit if adding this keyword exceeds the limit

				pulledKeywords.push(keyword);
				currentLength += keyword.length + 1; // update current length with the new keyword and a space
				usedIndices.add(index); // track used keywords to avoid repetition

				if (calculateTotalLength(pulledKeywords) >= characterLimit) {
					break;
				} // Check if adding any more keywords is possible
			}

			return pulledKeywords;
		}

		const response = await axios.post(
			`${API_DOMAIN.mrlootBackend}/api/boxes/find/keyword-suggestions`,
			{ query: query, characterLimit: characterLimit },
			{ withCredentials: true }
		);
		if (response.data.status === 'success') {
			return response.data.data;
		}
	} catch (err) {
		console.error(err);
		throw err;
	}
}

interface Props {
	query: string;
	debounceTime?: number;
}

const MAX_CHAR_LEN_XXL = 250;
const MAX_CHAR_LEN_XL = 200;
const MAX_CHAR_LEN_LG = 150;
const MAX_CHAR_LEN_MDS = 120;
const MAX_CHAR_LEN_SM = 100;
const MAX_CHAR_LEN_DEFAULT = 60;

export function useKeywordSuggestionBoxSearch({ query, debounceTime = 250 }: Props) {
	const [characterLimit, setCharacterLimit] = useState(calculateCharacterLimit());
	const [debouncedQuery, setDebouncedQuery] = useState(query);

	useEffect(() => {
		const debouncedSetQuery = debounce(() => setDebouncedQuery(query), debounceTime);

		debouncedSetQuery();

		return () => {
			debouncedSetQuery.cancel();
		};
	}, [debounceTime, query]);

	function calculateCharacterLimit() {
		const width = window.innerWidth;
		if (width > screens.xxl.width) {
			return MAX_CHAR_LEN_XXL;
		}
		if (width > screens.xl.width) {
			return MAX_CHAR_LEN_XL;
		} else if (width > screens.lg.width) {
			return MAX_CHAR_LEN_LG;
		} else if (width > screens.mds.width) {
			return MAX_CHAR_LEN_MDS;
		} else if (width > screens.sm.width) {
			return MAX_CHAR_LEN_SM;
		}
		return MAX_CHAR_LEN_DEFAULT;
	}

	useEffect(() => {
		const handleResize = () => {
			setCharacterLimit(calculateCharacterLimit());
		};

		window.addEventListener('resize', handleResize);

		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return useQuery<Props, Error, string[]>({
		queryKey: [QUERY_KEYS.boxData, QUERY_KEYS.itemData, 'keyword-suggestions', debouncedQuery, characterLimit],
		queryFn: () => findSuggestions(debouncedQuery, characterLimit),
	});
}
