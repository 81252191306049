import { AmountDisplayWithCurrency } from 'components/common/AmountDisplayWithCurrency';
import { BoxInterface, PopulatedBoxInterface } from 'interfaces/BoxInterface';
import React, { CSSProperties, forwardRef } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { getBoxLink } from 'utils';

import { CLOUDFRONT_IMG_URL } from '../../../../constants';

interface Props {
	box: PopulatedBoxInterface | BoxInterface;
	style?: CSSProperties;
	onClick?: (link: string) => void;
}

export const MobileBoxCard = forwardRef<HTMLDivElement, Props>(({ box, style, onClick }, ref) => {
	const navigate = useNavigate();
	const location = useLocation();

	const handleBoxNavigation = (event: React.MouseEvent | React.KeyboardEvent) => {
		if (event.type === 'click' || (event.type === 'keydown' && (event as React.KeyboardEvent).key === 'Enter')) {
			const boxLink = getBoxLink(box.name);
			if (onClick) {
				onClick(boxLink);
				return;
			}

			const scrollY = window.scrollY;

			navigate(boxLink, {
				state: {
					background: location,
					scrollY: scrollY,
				},
			});
		}
	};

	return (
		<div
			ref={ref}
			className="relative size-container flex flex-col flex-shrink-0 rounded-[18px] h-[222px] aspect-[0.65/1] overflow-hidden border-[1px] border-white"
			onClick={handleBoxNavigation}
			onKeyDown={handleBoxNavigation}
			role="button"
			tabIndex={0}
			aria-label={`Open box ${box.name}`}
			style={style}
		>
			<img
				src={`${CLOUDFRONT_IMG_URL}/${box.portraitImageUrls?.qualityHigh}`}
				alt={'image of box ' + box.name}
				className="w-full h-full object-cover"
			/>

			<div className="absolute px-[18px] h-[11cqh] min-w-[69px] w-fit rounded-[24px_24px_0_0] left-[50%] bottom-0 translate-x-[-50%] bg-[#2377F4] flex items-center pt-[1cqh] justify-center space-x-[5px] text-white font-semibold">
				<AmountDisplayWithCurrency
					amount={box.price}
					amountStyle={{ letterSpacing: '0.5px', fontSize: '5.5cqh', fontWeight: 'bold' }}
					imgStyle={{ height: '6.5cqh' }}
					useDivWrapper={false}
				/>
			</div>
		</div>
	);
});
