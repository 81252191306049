interface Props {
	text: string;
}

export function NotificationLabel({ text }: Props) {
	return (
		<div className="relative w-full text-center font-semibold text-mrLootBlue">
			<span className="relative">
				{text}
				<span className="absolute right-[-10px] top-[50%] translate-y-[-50%] bg-errorRed rounded-full h-[6px] w-[6px] animate-pulse" />
			</span>
		</div>
	);
}
