/* eslint-disable complexity */
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AmountDisplayWithCurrency } from 'components/common/AmountDisplayWithCurrency';
import { ItemOrderStatus, OrderInterface, PublicOrderStatus } from 'interfaces/OrderInterfaces';
import {
	EXPRESS_SHIP_DAYS_MAX,
	EXPRESS_SHIP_DAYS_MIN,
	STANDARD_SHIP_DAYS_MAX,
	STANDARD_SHIP_DAYS_MIN,
} from 'pages/checkout/checkout.constant';
import { NotificationLabel } from 'pages/MyOrders/components/NotificationLabel';

interface Props {
	order: OrderInterface;
	isExpanded: boolean;
	onExpandedToogle: () => void;
}

const formatEstimatedArrival = (createdAt: Date, isExpressShipping: boolean) => {
	const creationDate = new Date(createdAt);
	const oneWeekLater = new Date(creationDate);
	oneWeekLater.setDate(creationDate.getDate() + (isExpressShipping ? EXPRESS_SHIP_DAYS_MIN : STANDARD_SHIP_DAYS_MIN));
	const twoWeeksLater = new Date(creationDate);
	twoWeeksLater.setDate(creationDate.getDate() + (isExpressShipping ? EXPRESS_SHIP_DAYS_MAX : STANDARD_SHIP_DAYS_MAX));

	const formatDate = (date: Date) => date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });

	return `${formatDate(oneWeekLater)} - ${formatDate(twoWeeksLater)}`;
};

export function OrderRowHeader({ order, isExpanded, onExpandedToogle }: Props) {
	const totalValue = order.items.map((item) => item.itemId?.price * item.quantity).reduce((a, b) => a + b);

	const hasOrderNotification = order.items.some(
		(item) =>
			item.outstandingInvoiceAmount &&
			item.outstandingInvoiceAmount > 0 &&
			![ItemOrderStatus.REFUND_ITEM, ItemOrderStatus.REFUND_MONEY, ItemOrderStatus.RESHIP].includes(item.status)
	);

	const infoText =
		order.publicStatus === PublicOrderStatus.IN_PROCESS
			? 'In Progress'
			: order.publicStatus === PublicOrderStatus.CANCELLED
				? 'Canceled'
				: order.publicStatus === PublicOrderStatus.DELIVERED
					? 'Delivered'
					: `Est. Arrival: ${formatEstimatedArrival(order.createdAt, order.isExpressShipping)}`;

	const statusColorClass =
		order.publicStatus === PublicOrderStatus.IN_PROCESS
			? 'text-yellow-500'
			: order.publicStatus === PublicOrderStatus.CANCELLED
				? 'text-red-500'
				: order.publicStatus === PublicOrderStatus.DELIVERED
					? 'text-green-500'
					: 'text-white';

	const formattedOrderDate = order.createdAt ? new Date(order.createdAt).toLocaleDateString('en-US') : 'Invalid date';

	return (
		<button
			className="grid grid-cols-[20px_50px_auto_auto_auto_110px] min-h-[25px] items-center text-white text-[12px] md:text-[14px] w-full"
			onClick={onExpandedToogle}
		>
			<FontAwesomeIcon icon={isExpanded ? faChevronDown : faChevronRight} className="text-[rgba(255,255,255,0.7)]" />
			<span>#{order.orderNr}</span>
			<span>{formattedOrderDate}</span>
			<span>{order.items.length > 1 ? order.items.length + ' Products' : order.items.length + ' Product'}</span>
			<AmountDisplayWithCurrency
				amount={totalValue}
				imgStyle={{ height: '15px' }}
				amountStyle={{ fontSize: '14px', fontWeight: 400 }}
			/>

			{!hasOrderNotification && <span className={`${statusColorClass} font-semibold text-wrap`}>{infoText}</span>}
			{hasOrderNotification && <NotificationLabel text="Notice" />}
		</button>
	);
}
