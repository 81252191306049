import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef } from 'react';

interface Props {
	id?: string; // Optional ID to link with a label
	focus?: boolean;
	error?: boolean;
	checked?: boolean;

	size?: { width: string; height: string };
	borderRadius?: string;
	borderWidth?: string;
	borderColor?: string;
	backgroundColor?: string;

	strokeColor?: string;
	checkSize?: string;

	onClick?: () => void;
}

export function CheckBox(props: Props) {
	const {
		id,
		focus = false,
		error = false,
		checked = false,
		size = { width: '1.5rem', height: '1.5rem' },
		borderWidth = '2px',
		borderColor = '#141414',
		backgroundColor = 'transparent',
		onClick,
		strokeColor,
		checkSize,
		borderRadius = '2px',
	} = props;

	const checkBoxRef = useRef<HTMLSpanElement>(null);

	useEffect(() => {
		if (focus && checkBoxRef.current) {
			checkBoxRef.current.focus();
		}
	});

	// Simulate native checkbox behavior
	const handleKeyDown = (e: React.KeyboardEvent) => {
		if (e.key === ' ' || e.key === 'Enter') {
			e.preventDefault();
			onClick && onClick();
		}
	};

	return (
		<div className={`cursor-pointer flex items-center justify-start text-white text-sm`}>
			<span
				role="checkbox"
				aria-checked={checked}
				tabIndex={0} // Make it focusable
				onKeyDown={handleKeyDown}
				ref={checkBoxRef}
				onClick={onClick}
				className={`cursor-pointer relative bg-transparent outline`}
				style={{
					width: `${size.width}`,
					height: `${size.height}`,
					backgroundColor,
					outlineWidth: borderWidth,
					outlineColor: error ? 'rgb(204,0,60)' : borderColor,
					borderRadius,
				}}
				aria-labelledby={id}
			>
				<div
					className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] text-white text-[20px]"
					style={{ color: strokeColor, fontSize: checkSize }}
				>
					{checked && <FontAwesomeIcon icon={faXmark} />}
				</div>
			</span>
		</div>
	);
}
