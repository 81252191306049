import { faSearch, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SortIcon } from 'assets/icons/SortIcon';
import { CSSProperties } from 'react';

interface Props {
	value: string;
	onChange: (value: string) => void;
	isDisabled?: boolean;
	placeholder?: string;
	style?: CSSProperties;
	showFilterBtn?: boolean;
	onFilterClick?: () => void;
}

export function MobileSearchInput({
	onChange,
	value,
	placeholder,
	isDisabled,
	style,
	onFilterClick,
	showFilterBtn = false,
}: Props) {
	return (
		<div className="relative w-full h-fit">
			<FontAwesomeIcon icon={faSearch} className="text-white absolute left-3 top-[50%] translate-y-[-50%]" />
			<input
				value={value}
				onChange={(e) => onChange(e.target.value)}
				disabled={isDisabled}
				placeholder={placeholder}
				className={`border-[2px] border-[#3559D7] text-white rounded-full w-full h-[49px] pl-[34px] ${showFilterBtn ? 'pr-[95px]' : 'pr-[50px]'}  flex items-center bg-transparent text-[16px] font-bold outline-none`}
				style={{
					background: 'linear-gradient(331.41deg, rgba(35, 109, 244, 0.3) 6.78%, rgba(51, 240, 167, 0.3) 204.87%)',
					boxShadow: '0px 0px 0px 4px #FFFFFF, 0px 4px 5px rgba(0, 0, 0, 0.3)',
					...style,
				}}
			/>

			{value.length > 0 && (
				<button
					onClick={() => onChange('')}
					className={`absolute top-[50%] translate-y-[-50%] ${showFilterBtn ? 'right-[73px]' : 'right-[18px]'} p-1`}
				>
					<FontAwesomeIcon icon={faXmark} className="text-[rgba(255,255,255,1)]" />
				</button>
			)}
			{showFilterBtn && (
				<button
					className="absolute right-0 box-content top-1/2 -translate-y-1/2 h-full w-[56px] pr-[6px] bg-darkBlue flex items-center justify-center text-white rounded-[0_25px_25px_0]"
					onClick={onFilterClick}
				>
					<SortIcon />
				</button>
			)}
		</div>
	);
}
