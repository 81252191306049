import { AmountDisplayWithCurrency } from 'components/common/AmountDisplayWithCurrency';
import { ItemInterface } from 'interfaces/ItemInterfaces';
import { CurrencyOptions, useAppStore } from 'store/useAppStore';
import { getBgCardUrl } from 'utils';
import { shallow } from 'zustand/shallow';

import { rarities } from '../../../../constants';

interface Props {
	item: ItemInterface;
}

export function PriceContainer({ item }: Props) {
	const selectedCurrency = useAppStore((state) => state.selectedCurrency, shallow);

	return (
		<div
			className="relative grid font-semibold w-full mx-auto text-white items-center h-[16cqh]"
			style={{ gridTemplateColumns: '1fr 2fr 1fr' }}
		>
			<img
				alt={`Card background color is ${rarities[item.rarity].label}`}
				src={getBgCardUrl(item.rarity)}
				className="z-[-1] absolute w-full h-full object-fill left-0 top-0 rotate-180 rounded-[8px]"
			/>
			<AmountDisplayWithCurrency
				amount={item.price}
				useDivWrapper={false}
				amountStyle={{ fontSize: '5.8cqh', textAlign: 'center' }}
				imgStyle={{ height: '6.8cqh' }}
				FirstElementWrapper={({ children }) => (
					<div
						className={`${selectedCurrency === CurrencyOptions.LOOTIES ? 'col-start-2 col-end-3' : 'col-start-1 col-end-2 pl-2'} flex items-center justify-center`}
					>
						{children}
					</div>
				)}
				SecondElementWrapper={({ children }) => (
					<span
						className={`${selectedCurrency === CurrencyOptions.LOOTIES ? 'col-start-3 col-end-4 pr-2' : 'col-start-2 col-end-3'} flex justify-center items-center`}
					>
						{children}
					</span>
				)}
			/>
		</div>
	);
}
