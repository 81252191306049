import axios from 'axios';
import { useUserContext } from 'hooks/context';
import { useQuery } from 'react-query';

import { API_DOMAIN, FIVE_MINUTES_IN_MILLISECONDS, QUERY_KEYS } from '../../../constants';

async function fetchHashedServerSeed(userId?: string) {
	try {
		if (!userId) {
			return;
		}
		const response = await axios.get(`${API_DOMAIN.mrlootBackend}/api/user/get/active-hashed-serverseed`, {
			withCredentials: true,
		});
		if (response.data.status === 'success') {
			return response.data.data;
		}
	} catch (err) {
		if (axios.isAxiosError(err)) {
			const message = err.response?.data?.message || err.message || 'Something unexpected went wrong';
			throw new Error(message);
		}
		throw err;
	}
}

export function useHashedServerSeed() {
	const { user } = useUserContext();

	return useQuery<string>({
		queryKey: [QUERY_KEYS.hashedServerSeed, user?._id],
		queryFn: () => fetchHashedServerSeed(user?._id),
		enabled: !!user,
		cacheTime: FIVE_MINUTES_IN_MILLISECONDS,
		staleTime: FIVE_MINUTES_IN_MILLISECONDS,
	});
}
