import {
	CryptoBitcoin,
	CryptoBitcoinCash,
	CryptoBNB,
	CryptoDogecoin,
	CryptoEthereum,
	CryptoLitecoin,
	CryptoMonero,
	CryptoPolygon,
	CryptoShiba,
	CryptoSolana,
	CryptoTron,
	CryptoUSDC,
	CryptoUSDT,
	CryptoXRP,
} from 'assets/images';

export enum CryptoPayCurrency {
	BTC = 'btc', // Bitcoin (Mainnet)
	ETH = 'eth', // Ethereum
	USDT = 'usdt', // Tether (Omni Layer)
	USDT_SOL = 'usdt.sol', // Tether on Solana
	USDT_TRC20 = 'usdt.trc20', // Tether on Tron
	DOGE = 'doge', // Dogecoin
	SOL = 'sol', // Solana
	BNB = 'bnb.bsc', // Binance Coin on BSC
	XRP = 'xrp', // Ripple
	MATIC = 'pol', // Polygon
	USDC = 'usdc', // USD Coin on Ethereum (ERC20)
	USDC_SOL = 'usdc.sol', // USD Coin on Solana
	USDC_TRC20 = 'usdc.trc20', // USD Coin on Tron
	LTC = 'ltc', // Litecoin
	TRON = 'trx', // Tron
	BITCOIN_CASH = 'bch', // Bitcoin Cash
	MONERO = 'xmr', // Monero
	SHIBA = 'shib', // Shiba Inu
}

export const currencyDisplayNames: Record<CryptoPayCurrency, string> = {
	[CryptoPayCurrency.BTC]: 'Bitcoin',
	[CryptoPayCurrency.ETH]: 'Ethereum',
	[CryptoPayCurrency.USDT]: 'Tether',
	[CryptoPayCurrency.USDT_TRC20]: 'Tether (TRC20)', // Tether on Tron
	[CryptoPayCurrency.USDT_SOL]: 'Tether (SOL)', // Tether on Solana
	[CryptoPayCurrency.DOGE]: 'Dogecoin',
	[CryptoPayCurrency.SOL]: 'Solana',
	[CryptoPayCurrency.BNB]: 'BNB', // Binance Mainnet
	[CryptoPayCurrency.XRP]: 'Ripple',
	[CryptoPayCurrency.MATIC]: 'Polygon',
	[CryptoPayCurrency.USDC]: 'USD Coin',
	[CryptoPayCurrency.USDC_TRC20]: 'USD Coin (TRC20)', // USD Coin on Tron
	[CryptoPayCurrency.USDC_SOL]: 'USD Coin (SOL)', // USD Coin on Solana
	[CryptoPayCurrency.LTC]: 'Litecoin',
	[CryptoPayCurrency.TRON]: 'Tron',
	[CryptoPayCurrency.MONERO]: 'Monero',
	[CryptoPayCurrency.SHIBA]: 'Shiba Inu',
	[CryptoPayCurrency.BITCOIN_CASH]: 'Bitcoin Cash',
};

export const currencyImages: Record<CryptoPayCurrency, string> = {
	[CryptoPayCurrency.BTC]: CryptoBitcoin,
	[CryptoPayCurrency.ETH]: CryptoEthereum,
	[CryptoPayCurrency.USDT]: CryptoUSDT, // Default to Omni Layer image if needed
	[CryptoPayCurrency.USDT_TRC20]: CryptoUSDT, // Use same image for Tether on Tron
	[CryptoPayCurrency.USDT_SOL]: CryptoUSDT, // Use same image for Tether on Solana
	[CryptoPayCurrency.DOGE]: CryptoDogecoin,
	[CryptoPayCurrency.SOL]: CryptoSolana,
	[CryptoPayCurrency.BNB]: CryptoBNB, // Same image for Binance Smart Chain
	[CryptoPayCurrency.XRP]: CryptoXRP,
	[CryptoPayCurrency.MATIC]: CryptoPolygon,
	[CryptoPayCurrency.USDC]: CryptoUSDC, // Default to ERC20 image if needed
	[CryptoPayCurrency.USDC_TRC20]: CryptoUSDC, // Use same image for USDC on Tron
	[CryptoPayCurrency.USDC_SOL]: CryptoUSDC, // Use same image for USDC on Solana
	[CryptoPayCurrency.LTC]: CryptoLitecoin,
	[CryptoPayCurrency.TRON]: CryptoTron,
	[CryptoPayCurrency.MONERO]: CryptoMonero,
	[CryptoPayCurrency.SHIBA]: CryptoShiba,
	[CryptoPayCurrency.BITCOIN_CASH]: CryptoBitcoinCash,
};
