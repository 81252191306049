import { useWindowDimensions } from 'hooks/utility/layout-measurements/useWindowDimensions';
import { useEffect, useRef } from 'react';

import { screens } from '../../../constants';

/**
 * A custom hook to calculate dynamic padding based on scroll position. Used for Sidebar in Mobile View. Smaller when floating and full width when reached sticky Pos.
 * @param {React.RefObject} mainContentRef - Ref to the main content element.
 * @param {React.RefObject} sidebarRef - Ref to the sidebar element.
 * @returns {number} The calculated padding value.
 */
export function useDynamicPaddingSidebar(
	mainContentRef: React.RefObject<HTMLDivElement>,
	sidebarRef: React.RefObject<HTMLDivElement>
) {
	const { screenWidth } = useWindowDimensions();
	const resizeObserver = useRef<ResizeObserver | null>(null);

	const maxPadding = 20;

	useEffect(() => {
		const checkPosition = () => {
			if (mainContentRef.current && sidebarRef.current) {
				if (screenWidth >= screens.md.width) {
					sidebarRef.current.style.paddingLeft = '0px';
					sidebarRef.current.style.paddingRight = '0px';
					return;
				}

				const stickyPosition = sidebarRef.current.getBoundingClientRect();
				const mainContentHeight = mainContentRef.current.clientHeight;
				const scrollY = window.scrollY;

				const targetCondition =
					mainContentHeight - (scrollY + stickyPosition.top - (stickyPosition.height + maxPadding) / 2);

				if (targetCondition <= 0) {
					sidebarRef.current.style.paddingLeft = '0px';
					sidebarRef.current.style.paddingRight = '0px';
				} else if (targetCondition < 100) {
					const paddingValue = maxPadding * (targetCondition / 100);
					sidebarRef.current.style.paddingLeft = `${paddingValue}px`;
					sidebarRef.current.style.paddingRight = `${paddingValue}px`;
				} else {
					sidebarRef.current.style.paddingLeft = `${maxPadding}px`;
					sidebarRef.current.style.paddingRight = `${maxPadding}px`;
				}
			}
		};

		checkPosition();
		window.addEventListener('scroll', checkPosition);

		resizeObserver.current = new ResizeObserver((entries) => {
			for (const entry of entries) {
				if (entry.target === mainContentRef.current) {
					checkPosition();
				}
			}
		});

		if (mainContentRef.current) {
			resizeObserver.current.observe(mainContentRef.current);
		}

		return () => {
			window.removeEventListener('scroll', checkPosition);
			if (resizeObserver.current) {
				resizeObserver.current.disconnect();
			}
		};
	}, [mainContentRef, screenWidth, sidebarRef]);
}
