import { PrimaryBtn } from 'components/common/buttons/PrimaryBtn';
import { MobileEmailInput } from 'components/common/inputs/MobileEmailInput';
import { SimpleTextInput } from 'components/common/inputs/MobileSimpleTextInput';
import { TextareaInput } from 'components/common/inputs/TextareaInput';
import { ToastErrorNotification } from 'components/toasts/ToastErrorNotification';
import { ToastSuccessNotification } from 'components/toasts/ToastSuccessNotification';
import { useUserContext } from 'hooks';
import { useQueryParam } from 'hooks/utility/useQueryParam';
import { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { ContactTypeDropdown } from './components/ContactTypeDropdown';
import { useSupportContact } from './hooks/useSupportContact';

export enum ContactRequestType {
	GENERAL_INQUIRY = 'General Inquiry',
	ACCOUNT_ISSUES = 'Account Issues',
	ORDER_ISSUES = 'Order Issues',
	SHIPPING_AND_DELIVERY = 'Shipping and Delivery',
	BILLING_AND_PAYMENTS = 'Billing and Payments',
	TECHNICAL_SUPPORT = 'Technical Support',
	RETURNS_AND_EXCHANGES = 'Returns and Exchanges',
	PROMOTIONS_AND_DISCOUNTS = 'Promotions and Discounts',
	PRIVACY_AND_SECURITY = 'Privacy and Security',
	FEEDBACK_AND_SUGGESTIONS = 'Feedback and Suggestions',
	LEGAL_AND_COMPLIANCE = 'Legal and Compliance',
	PARTNERSHIPS_AND_COLLABORATIONS = 'Partnerships and Collaborations',
	CANCELLATION_OF_ACCOUNT = 'Cancellation of Account',
}

const TYPE_OPTIONS = [
	{
		value: ContactRequestType.GENERAL_INQUIRY,
		label: 'General Inquiry',
		needsUserSession: false,
	},
	{
		value: ContactRequestType.ACCOUNT_ISSUES,
		label: 'Account Issues',
		needsUserSession: true,
	},
	{
		value: ContactRequestType.ORDER_ISSUES,
		label: 'Order Issues',
		needsUserSession: true,
	},
	{
		value: ContactRequestType.SHIPPING_AND_DELIVERY,
		label: 'Shipping and Delivery',
		needsUserSession: true,
	},
	{
		value: ContactRequestType.BILLING_AND_PAYMENTS,
		label: 'Billing and Payments',
		needsUserSession: true,
	},
	{
		value: ContactRequestType.TECHNICAL_SUPPORT,
		label: 'Technical Support',
		needsUserSession: false,
	},
	{
		value: ContactRequestType.RETURNS_AND_EXCHANGES,
		label: 'Returns and Exchanges',
		needsUserSession: true,
	},
	{
		value: ContactRequestType.PROMOTIONS_AND_DISCOUNTS,
		label: 'Promotions and Discounts',
		needsUserSession: false,
	},
	{
		value: ContactRequestType.PRIVACY_AND_SECURITY,
		label: 'Privacy and Security',
		needsUserSession: false,
	},
	{
		value: ContactRequestType.FEEDBACK_AND_SUGGESTIONS,
		label: 'Feedback and Suggestions',
		needsUserSession: false,
	},
	{
		value: ContactRequestType.LEGAL_AND_COMPLIANCE,
		label: 'Legal and Compliance',
		needsUserSession: false,
	},
	{
		value: ContactRequestType.PARTNERSHIPS_AND_COLLABORATIONS,
		label: 'Partnerships and Collaborations',
		needsUserSession: false,
	},
	{
		value: ContactRequestType.CANCELLATION_OF_ACCOUNT,
		label: 'Cancellation of Account',
		needsUserSession: true,
	},
];

function getContactRequestType(value: string | undefined): ContactRequestType | undefined {
	if (!value) {
		return undefined;
	}

	const matchingType = Object.values(ContactRequestType).find((type) => type === value);

	return matchingType as ContactRequestType | undefined;
}

interface ContactForm {
	name: string;
	email: string;
	type: ContactRequestType;
	subject: string;
	description: string;
}

export function Contact() {
	const { user } = useUserContext();

	const typeParam = useQueryParam('type');

	const subjectParam = useQueryParam('subject');

	const descriptionParam = useQueryParam('description');

	const {
		control,
		register,
		handleSubmit,
		formState: { errors },
		resetField,
		reset,
		watch,
	} = useForm<ContactForm>({
		defaultValues: {
			email: user?.contactMail?.value ?? '',
			type: getContactRequestType(typeParam) ?? ContactRequestType.GENERAL_INQUIRY,
			subject: subjectParam,
			description: descriptionParam,
		},
	});

	useEffect(() => {
		reset({
			email: user?.contactMail?.value ?? '',
			type: getContactRequestType(typeParam),
			subject: subjectParam || '',
			description: descriptionParam || '',
		});
	}, [user, typeParam, subjectParam, descriptionParam, reset]);

	const { mutate: sendSupportRequest } = useSupportContact({
		onSuccess: () => {
			reset();
			toast(<ToastSuccessNotification message="Support Request was send. We will contact you soon." />);
			window.scroll({ behavior: 'smooth', top: 0 });
		},
		onError: (message) => {
			toast(<ToastErrorNotification message={message} />);
		},
	});

	const onSubmit: SubmitHandler<ContactForm> = (data) => {
		sendSupportRequest(data);
	};

	return (
		<div className="w-full min-h-svh flex flex-col gap-y-[15px] ">
			<h1 className="text-white font-bold text-[22px]">Support</h1>

			<p className="text-[rgba(255,255,255,0.7)] font-semibold text-[12px] tracking-[0.5px]">
				Before submitting a support ticket, please check our{' '}
				<Link to="/faq" className="underline">
					Frequently Asked Questions
				</Link>{' '}
				for a quick answer.
			</p>

			<p className="text-[rgba(255,255,255,0.7)] font-semibold text-[12px] tracking-[0.5px]">
				Fill out the contact form below with your correct email address and a detailed description of your request.
			</p>

			<p className="text-[rgba(255,255,255,0.7)] font-semibold text-[12px] tracking-[0.5px]">
				If your question is related to your account, please log in first.
			</p>

			<form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-y-[20px] mt-[20px]">
				<h1 className="text-white font-bold text-[18px]">Contact us</h1>

				<MobileEmailInput errors={errors} register={register} resetField={resetField} watch={watch} />
				<SimpleTextInput errors={errors} register={register} name="name" placeholder="Enter your name" />
				<ContactTypeDropdown
					control={control} // Add this line
					errors={errors}
					name="type"
					options={TYPE_OPTIONS}
					placeholder="Select request type"
					hasUserSession={!!user}
				/>
				<SimpleTextInput errors={errors} register={register} name="subject" placeholder="Enter subject" />
				<TextareaInput errors={errors} register={register} name="description" placeholder="Enter your message" />
				<PrimaryBtn type="submit" content="Send" />
			</form>
		</div>
	);
}
