import axios from 'axios';
import { ItemInterface } from 'interfaces/ItemInterfaces';
import { useQuery } from 'react-query';

import { API_DOMAIN, FIVE_MINUTES_IN_MILLISECONDS, QUERY_KEYS } from '../../../constants';

async function findOneItemById(id?: string) {
	if (!id) {
		return;
	}
	try {
		const response = await axios.post(`${API_DOMAIN.mrlootBackend}/api/items/find`, { id });
		if (response.data.status === 'success') {
			return response.data.data;
		}
	} catch (err) {
		if (axios.isAxiosError(err)) {
			const message = err.response?.data?.message || err.message || 'Something unexpected went wrong';
			throw new Error(message);
		}
		throw err;
	}
}

interface Props {
	id?: string;
}

export function useItem({ id }: Props) {
	return useQuery<ItemInterface>({
		queryKey: [QUERY_KEYS.itemData, id],
		queryFn: () => findOneItemById(id),
		cacheTime: FIVE_MINUTES_IN_MILLISECONDS,
		staleTime: FIVE_MINUTES_IN_MILLISECONDS,
		enabled: !!id,
	});
}
