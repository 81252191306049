import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';

interface BreadcrumbItem {
	label: string;
	link?: string;
}

interface BreadcrumbProps {
	items: BreadcrumbItem[];
}

export const Breadcrumb: React.FC<BreadcrumbProps> = ({ items }) => {
	return (
		<div className="flex items-center">
			{items.map((item, index) => (
				<React.Fragment key={index}>
					{item.link ? (
						<Link to={item.link} className="hover:underline text-[23px] font-semibold  text-[rgba(255,255,255,0.5)]">
							{item.label}
						</Link>
					) : (
						<span className="text-[#C7C6CA] text-[23px] font-semibold">{item.label}</span>
					)}
					{index < items.length - 1 && (
						<FontAwesomeIcon icon={faChevronRight} className="text-[rgba(255,255,255,0.5)] mx-2" />
					)}
				</React.Fragment>
			))}
		</div>
	);
};
