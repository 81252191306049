// Functions specifically designed for dealing with items.
// =======================================================

import {
	ItemCardBlue,
	ItemCardBlueLandscape,
	ItemCardGold,
	ItemCardGoldLandscape,
	ItemCardLightBlue,
	ItemCardLightBlueLandscape,
	ItemCardPink,
	ItemCardPinkLandscape,
	ItemCardPurple,
	ItemCardPurpleLandscape,
	ItemCardRed,
	ItemCardRedLandscape,
} from 'assets/images';
import { LootGamesBoxPopulatedInterface } from 'interfaces/BoxInterface';
import { ItemRarity } from 'interfaces/ItemInterfaces';
import { LootGameInterface } from 'interfaces/LootGameInterface';

export function getNextHigherRarity(rarity: ItemRarity): ItemRarity | undefined {
	switch (rarity) {
		case ItemRarity.LIGHT_BLUE:
			return ItemRarity.BLUE;
		case ItemRarity.BLUE:
			return ItemRarity.PURPLE;
		case ItemRarity.PURPLE:
			return ItemRarity.PINK;
		case ItemRarity.PINK:
			return ItemRarity.RED;
		case ItemRarity.RED:
			return ItemRarity.GOLD;
		case ItemRarity.GOLD:
			return undefined;
		default:
			return undefined;
	}
}

export function getBgCardUrl(rarity: ItemRarity) {
	switch (rarity) {
		case ItemRarity.GOLD:
			return ItemCardGold;
		case ItemRarity.RED:
			return ItemCardRed;
		case ItemRarity.PINK:
			return ItemCardPink;
		case ItemRarity.PURPLE:
			return ItemCardPurple;
		case ItemRarity.BLUE:
			return ItemCardBlue;
		case ItemRarity.LIGHT_BLUE:
			return ItemCardLightBlue;
	}
}

export function getBgCardUrlLandscape(rarity: ItemRarity) {
	switch (rarity) {
		case ItemRarity.GOLD:
			return ItemCardGoldLandscape;
		case ItemRarity.RED:
			return ItemCardRedLandscape;
		case ItemRarity.PINK:
			return ItemCardPinkLandscape;
		case ItemRarity.PURPLE:
			return ItemCardPurpleLandscape;
		case ItemRarity.BLUE:
			return ItemCardBlueLandscape;
		case ItemRarity.LIGHT_BLUE:
			return ItemCardLightBlueLandscape;
	}
}

export function sortLootGames(lootGames: LootGameInterface[]): LootGameInterface[];
export function sortLootGames(lootGames: LootGamesBoxPopulatedInterface[]): LootGamesBoxPopulatedInterface[];
export function sortLootGames(
	lootGames: LootGameInterface[] | LootGamesBoxPopulatedInterface[]
): LootGameInterface[] | LootGamesBoxPopulatedInterface[] {
	const namePriority: { [key: string]: number } = {
		ultimate: 1,
		deluxe: 2,
		standard: 3,
		mini: 4,
	};

	return lootGames.sort((a, b) => {
		let nameA, nameB;

		if ('lootGameId' in a && 'lootGameId' in b) {
			nameA = a.lootGameId.name.toLowerCase();
			nameB = b.lootGameId.name.toLowerCase();
		} else {
			nameA = (a as LootGameInterface).name.toLowerCase();
			nameB = (b as LootGameInterface).name.toLowerCase();
		}

		const defaultPrio = 5;
		const priorityA = namePriority[nameA] || defaultPrio;
		const priorityB = namePriority[nameB] || defaultPrio;

		return priorityA - priorityB;
	});
}
