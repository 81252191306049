import axios from 'axios';
import { useUserContext } from 'hooks/context';
import { useMutation, useQueryClient } from 'react-query';

import { API_DOMAIN, QUERY_KEYS } from '../../../constants';

async function generateNewHashedServerSeed(userId?: string) {
	try {
		if (!userId) {
			return;
		}
		const response = await axios.post(
			`${API_DOMAIN.mrlootBackend}/api/user/generate-new-hashed-serverseed`,
			{},
			{
				withCredentials: true,
			}
		);
		if (response.data.status !== 'success') {
			throw new Error(response.data.message || 'Failed to generate new hashed server seed');
		}
	} catch (err) {
		if (axios.isAxiosError(err)) {
			const message = err.response?.data?.message || err.message || 'Something unexpected went wrong';
			throw new Error(message);
		}
		throw err;
	}
}

export function useGenerateNewHashedServerSeed() {
	const queryClient = useQueryClient();
	const { user } = useUserContext();

	return useMutation({
		mutationFn: () => generateNewHashedServerSeed(user?._id),
		onSuccess: () => {
			queryClient.invalidateQueries([QUERY_KEYS.hashedServerSeed]);
		},
	});
}
