import { IconCircleInfo } from 'assets/icons/IconCircleInfo';
import { useState } from 'react';

export function ToolTipExpressShipping() {
	const [showToolTip, setShowToolTip] = useState(false);

	return (
		<div
			className="relative w-fit h-fit"
			onMouseEnter={() => setShowToolTip(true)}
			onMouseLeave={() => setShowToolTip(false)}
		>
			{showToolTip && (
				<div className="w-[155px] absolute left-[50%] top-[-10px] translate-x-[-50%] translate-y-[-100%] bg-mrLootBlue text-white text-[8px] border-[2px] border-white rounded-[8px]">
					<div className="relative z-10 bg-mrLootBlue h-full w-full p-[6px] rounded-[8px]">
						Faster Processing & Delivery! Enjoy shorter wait times with expedited processing and quicker delivery for
						your orders.
					</div>
					<div className="absolute left-[50%] translate-x-[-50%] bottom-[-5px] rotate-45 w-[16px] h-[16px] border-[2px] border-white bg-mrLootBlue"></div>
				</div>
			)}
			<IconCircleInfo width={13} height={13} />
		</div>
	);
}
