import { useBoxOpeningStoreMobile } from 'pages/OpenBox/store/useBoxOpeningStoreMobile';
import { shallow } from 'zustand/shallow';

import { PopupBoxPrizes } from './PopupBoxPrizes';
import { PopupOpenWithoutUserSession } from './PopupOpenWithoutUserSession';
import { PopupSpinAmountChooser } from './PopupSpinAmountChooser';

interface Props {
	onDemoBoxOpen: () => void;
}

export function PopupHandler({ onDemoBoxOpen }: Props) {
	const { isSpinAmountChooserPopupVisible, isBoxPrizesPopupVisible, isOpenWithoutSessionPopupVisible } =
		useBoxOpeningStoreMobile(
			(state) => ({
				isSpinAmountChooserPopupVisible: state.isSpinAmountChooserPopupVisible,
				isBoxPrizesPopupVisible: state.isBoxPrizesPopupVisible,
				isOpenWithoutSessionPopupVisible: state.isOpenWithoutSessionPopupVisible,
			}),
			shallow
		);

	return (
		<>
			{isSpinAmountChooserPopupVisible && <PopupSpinAmountChooser />}
			{isBoxPrizesPopupVisible && <PopupBoxPrizes />}
			{isOpenWithoutSessionPopupVisible && <PopupOpenWithoutUserSession onDemoBoxOpen={onDemoBoxOpen} />}
		</>
	);
}
