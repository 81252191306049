import { useController, UseControllerProps, FieldValues } from 'react-hook-form';
import { PasswordInput } from './PasswordInput';

interface PasswordInputWrapperProps<T extends FieldValues> extends UseControllerProps<T> {
	label?: string;
	placeholder?: string;
}

export function PasswordInputWrapper<T extends FieldValues>({
	name,
	control,
	rules,
	label,
	placeholder,
}: PasswordInputWrapperProps<T>) {
	const {
		field,
		fieldState: { error },
	} = useController({
		name,
		control,
		rules,
	});

	return <PasswordInput label={label} placeholder={placeholder} error={error?.message} {...field} />;
}
