import { gsap } from 'gsap';
import { useEffect, useRef } from 'react';

const COVERED_PRIZE_RATIO = 0.25; // percentage of won prize hidden behind item info container

interface Props {
	onPageLoadComplete: () => void;
}

export function useInitialWonScreenAnimation({ onPageLoadComplete }: Props) {
	const parentRef = useRef<HTMLDivElement>(null);
	const wonImgRef = useRef<HTMLImageElement>(null);
	const wonPrizeContainerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (!wonImgRef.current) {
			return;
		}
		const tl = gsap.timeline();
		tl.set(parentRef.current, { opacity: 0 });
		tl.set(wonImgRef.current, { y: window.innerHeight });
		tl.set(wonPrizeContainerRef.current, { opacity: 0 });
		tl.to(parentRef.current, {
			opacity: 1,
			duration: 0.45,
			ease: 'power2.in',
			onComplete: () => {
				onPageLoadComplete();
			},
		});
		tl.to(wonImgRef.current, {
			y: 0,
			duration: 0.7,
			ease: 'power3out',
		});
		tl.to(wonPrizeContainerRef.current, { opacity: 1, duration: 0.6, ease: 'power3.out' });
	}, [onPageLoadComplete]);

	return { parentRef, wonImgRef, wonPrizeContainerRef };
}
