import axios from 'axios';
import { UnboxedHistoryInterface } from 'interfaces/UnboxedHistoryInterface';
import { useInfiniteQuery } from 'react-query';

import { API_DOMAIN, QUERY_KEYS } from '../../../constants';

interface FetchWinBoxHistoryResponse {
	data: UnboxedHistoryInterface[];
	nextPage: number;
	hasMore: boolean;
}

interface FetchWinBoxHistoryProps {
	limit: number;
	pageParam: number;
}

async function fetchWinBoxHistory({
	limit,
	pageParam = 1,
}: FetchWinBoxHistoryProps): Promise<FetchWinBoxHistoryResponse> {
	try {
		const response = await axios.post(
			`${API_DOMAIN.mrlootBackend}/api/user/winbox-history`,
			{
				limit,
				page: pageParam,
			},
			{ withCredentials: true }
		);
		if (response.data.status === 'success') {
			return {
				data: response.data.data,
				nextPage: pageParam + 1,
				hasMore: response.data.data.length === limit,
			};
		}
		throw new Error('Failed to fetch data');
	} catch (err) {
		if (axios.isAxiosError(err)) {
			const message = err.response?.data?.message || err.message || 'Something unexpected went wrong';
			throw new Error(message);
		}
		throw err;
	}
}

interface Props {
	limit: number;
}

interface FetchWinBoxHistoryResponse {
	data: UnboxedHistoryInterface[];
	nextPage: number;
	hasMore: boolean;
}

interface CallbackProps {
	onSuccess?: () => void;
	onError?: (message: string) => void;
}

export function useWinBoxHistory({ limit }: Props, { onSuccess, onError }: CallbackProps = {}) {
	const { data, error, fetchNextPage, hasNextPage, isFetchingNextPage, status, isLoading } = useInfiniteQuery<
		FetchWinBoxHistoryResponse,
		Error
	>([QUERY_KEYS.boxHistory, limit], ({ pageParam = 1 }) => fetchWinBoxHistory({ limit, pageParam }), {
		getNextPageParam: (lastPage) => (lastPage.hasMore ? lastPage.nextPage : undefined),
		onError: (err: Error) => {
			if (onError) {
				onError(err.message || 'Something unexpected went wrong');
			}
		},
		onSuccess: () => {
			if (onSuccess) {
				onSuccess();
			}
		},
	});

	// Flatten the pages into a single array of UnboxedHistoryInterface[]
	const allData = data?.pages.flatMap((page) => page.data) ?? [];

	return {
		data: allData,
		error,
		fetchNextPage,
		hasNextPage,
		isFetchingNextPage,
		status,
		isLoading,
	};
}
