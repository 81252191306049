import { LoadingScreen } from 'components/layout/LoadingScreen';
import { useUserContext, useWindowDimensions } from 'hooks';
import { useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router';
import { useAppStore } from 'store/useAppStore';
import { usePopupStore } from 'store/usePopupStore';
import { generateUniqueId, isMobile } from 'utils';
import { shallow } from 'zustand/shallow';
import { useInventoryBoxes } from 'pages/inventory/hooks/useInventoryBoxes';

export function SuccesfulRegister() {
	const navigate = useNavigate();

	const { setIsCurrencyPopupVisible, setSignupBonusExpiredAt, signupBonusExpiredAt, setIsBonusBoxToOpen } =
		usePopupStore(
			(state) => ({
				signupBonusExpiredAt: state.signupBonusExpiredAt,
				setSignupBonusExpiredAt: state.setSignupBonusExpiredAt,
				setIsCurrencyPopupVisible: state.setIsCurrencyPopupVisible,
				setIsBonusBoxToOpen: state.setIsBonusBoxToOpen,
			}),
			shallow
		);

	const lastLocationBeforeLogin = useAppStore((state) => state.lastLocationBeforeLogin, shallow);
	const { screenWidth } = useWindowDimensions();

	const { user, load: reloadUser } = useUserContext();

	const { data: inventoryBoxes, isLoading: isInventoryBoxesLoading } = useInventoryBoxes();

	// Handle Signup Bonus
	const handleSignupBonus = useCallback(() => {
		// check signup bonus active
		const isBonusTimeActive = signupBonusExpiredAt && signupBonusExpiredAt.getTime() > new Date().getTime();
		const doesUserOwnBox = inventoryBoxes && inventoryBoxes.length > 0;
		if (isBonusTimeActive && doesUserOwnBox) {
			setIsBonusBoxToOpen(true);
		}
		setIsCurrencyPopupVisible(false);
		setSignupBonusExpiredAt(new Date(0));
	}, [inventoryBoxes, setIsBonusBoxToOpen, setIsCurrencyPopupVisible, setSignupBonusExpiredAt, signupBonusExpiredAt]);

	// Navigate After Login
	const navigateAfterLogin = useCallback(() => {
		if (lastLocationBeforeLogin) {
			let state = undefined;
			const fakeHomeLocation = {
				hash: '',
				key: '',
				pathname: '/',
				search: '',
				state: null,
			};

			if (lastLocationBeforeLogin.includes('boxes/open') && isMobile(screenWidth)) {
				state = { background: fakeHomeLocation };
			}

			navigate(lastLocationBeforeLogin, { state });
		} else {
			navigate('/');
		}
	}, [lastLocationBeforeLogin, navigate, screenWidth]);

	useEffect(() => {
		if (!user) return;

		const pushRegisterEventToDataLayer = () => {
			if (window.dataLayer && user) {
				// Retrieve UTM parameters from localStorage
				const utmSource = localStorage.getItem('utm_source') || 'direct'; // Default to 'direct' if not available
				const utmMedium = localStorage.getItem('utm_medium') || 'none';
				const utmCampaign = localStorage.getItem('utm_campaign') || 'none';

				// Push the register event with UTM parameters
				window.dataLayer.push({
					event: 'register',
					user_mail: user?.contactMail?.value ?? user?.email ?? '',
					register_id: generateUniqueId(),
					utm_source: utmSource,
					utm_medium: utmMedium,
					utm_campaign: utmCampaign,
				});
			}
		};

		pushRegisterEventToDataLayer();
	}, [user]);

	const alreadyHandledSignup = useRef(false);

	useEffect(() => {
		if (!user || isInventoryBoxesLoading || alreadyHandledSignup.current) return;

		alreadyHandledSignup.current = true;
		console.count('register useeffect');

		handleSignupBonus();
		navigateAfterLogin();

		// Reload user after 5 seconds
		const reloadTimeout = setTimeout(reloadUser, 5000);

		return () => clearTimeout(reloadTimeout);
	}, [user, isInventoryBoxesLoading, handleSignupBonus, navigateAfterLogin, reloadUser]);

	return <LoadingScreen />;
}
