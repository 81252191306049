import { useUserContext } from 'hooks/context/useUserContext';
import { BoxInterface, PopulatedBoxInterface } from 'interfaces/BoxInterface';
import { InteractionType } from 'interfaces/UserInteractionInterface';
import { useCallback, useEffect, useRef, useState } from 'react';

import { useCreateUserInteraction } from './useCreateUserInteraction';

interface Props {
	box: BoxInterface | PopulatedBoxInterface;
	disableTracking?: boolean;
	delay?: number;
}

export function useBoxViewLogger({ box, delay = 1200, disableTracking }: Props) {
	const [isHovering, setIsHovering] = useState(false);
	const hoverTimeoutRef = useRef<number | null>(null);
	const hoverStartTimeRef = useRef<number | null>(null);

	const { mutate: logInteraction } = useCreateUserInteraction();
	const { user } = useUserContext();

	const logBoxView = useCallback(() => {
		if (!user || disableTracking) {
			return;
		}

		const hoverEndTime = Date.now();
		const hoverDuration = hoverStartTimeRef.current !== null ? hoverEndTime - hoverStartTimeRef.current : 0;

		logInteraction({
			userId: user?._id,
			targetType: 'box',
			targetId: box._id,
			interactionType: InteractionType.VIEW_BOX,
			interactionDetails: { hoverDuration },
			stateAtInteraction: {
				price: box.price,
				name: box.name,
				tags: box.tags,
			},
		});
	}, [user, disableTracking, logInteraction, box]);

	// Handle mouse enter
	const handleMouseEnter = useCallback(() => {
		setIsHovering(true);
		hoverStartTimeRef.current = Date.now();
		hoverTimeoutRef.current = window.setTimeout(logBoxView, delay);
	}, [delay, logBoxView]);

	// Handle mouse leave
	const handleMouseLeave = useCallback(() => {
		setIsHovering(false);
		if (hoverTimeoutRef.current !== null) {
			clearTimeout(hoverTimeoutRef.current);
			hoverTimeoutRef.current = null;
		}
	}, []);

	// Cleanup
	useEffect(() => {
		return () => {
			if (hoverTimeoutRef.current !== null) {
				clearTimeout(hoverTimeoutRef.current);
			}
		};
	}, []);

	return { isHovering, handleMouseEnter, handleMouseLeave };
}
