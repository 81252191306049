import { useAutoAnimate } from '@formkit/auto-animate/react';
import { ItemInterface } from 'interfaces/ItemInterfaces';
import {
	LoadingProductContainer,
	ProductContainer,
} from 'pages/checkout/components/ProductsContainer/ProductsContainer';
import { FailedItemsMap, GroupedOrderItemInterface, SelectedItemOption } from 'pages/checkout/InterfacesCheckout';
import { Link } from 'react-router-dom';

import { ShippingFeeBannerInfo } from './ShippingFeeBannerInfo';

interface Props {
	sortedOrderItems?: GroupedOrderItemInterface[];
	numPlaceholder: number;
	isExpressShipping: boolean;
	showShippingFeeInfoBanner: boolean;
	onQuantityChange: (itemId: string, quantity: number) => void;
	onNoteChange: (itemId: string, note: string) => void;
	onSelectedOptionChange: (itemId: string, updatedOptionSet: SelectedItemOption, indexOfOptionSet: number) => void;
	onRemove: (item: ItemInterface) => void;
	optionErrors: FailedItemsMap;
}

export function Step0({
	sortedOrderItems,
	isExpressShipping,
	numPlaceholder,
	showShippingFeeInfoBanner,
	onQuantityChange,
	onNoteChange,
	onRemove,
	onSelectedOptionChange,
	optionErrors,
}: Props) {
	const [parent] = useAutoAnimate();

	return (
		<div ref={parent} className="flex-grow space-y-4 flex flex-col">
			{showShippingFeeInfoBanner && <ShippingFeeBannerInfo />}
			{sortedOrderItems &&
				sortedOrderItems.map((el) => (
					<ProductContainer
						key={el.item._id}
						item={el.item}
						quantity={el.quantity}
						onQuantityChange={(q) => onQuantityChange(el.item._id, q)}
						onRemove={() => onRemove(el.item)}
						additionalPurchaseNum={el.quantity - (el.selectedInventoryItemIds && el.selectedInventoryItemIds.length)}
						isExpressShipping={isExpressShipping}
						note={el.note}
						onNoteChange={(note) => onNoteChange(el.item._id, note)}
						selectedOptions={el.selectedOptions}
						onSelectedOptionChange={onSelectedOptionChange}
						optionErrors={optionErrors}
					/>
				))}
			{!sortedOrderItems &&
				Array.from({ length: numPlaceholder }).map((_, index) => <LoadingProductContainer key={index} />)}
			{(!sortedOrderItems || sortedOrderItems.length === 0) && (
				<div className="text-white w-full flex flex-col items-center justify-center space-y-[20px] h-[55vh]">
					<h1 className="text-[20px] text-center">Please Select Products from your Inventory to continue</h1>
					<Link
						className="rounded-[4px] flex items-center justify-center bg-mrLootBlue700 hover:bg-mrLootBlue h-[35px] w-[160px] font-semibold text-[14px]"
						to="/inventory"
					>
						Back To Inventory
					</Link>
				</div>
			)}
			<div className="text-[11px] text-white flex-grow px-[20px] py-[9px] border-[3px] rounded-[24px] shadow-[0_4px_4px_rgba(0,0,0,0.25)] border-white01">
				* We proudly offer international shipping to a selected list of countries where there are no legal restrictions
				against receiving our products. To see if your country is on our list and to learn more about shipping rates and
				delivery times, please refer to our{' '}
				<Link className="underline" to="shipping-information">
					Shipping Information
				</Link>{' '}
				page.
			</div>
			<div className="text-[11px] text-white flex-grow px-[20px] py-[9px] border-[3px] rounded-[24px] shadow-[0_4px_4px_rgba(0,0,0,0.25)] border-white01">
				* No shipping fees will be charged for digital products
			</div>
		</div>
	);
}
