import { faCartShopping } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PrimaryBtn } from 'components/common/buttons/PrimaryBtn';

import { useMerchStore } from '../MerchStore';

export function FloatingCartBtn() {
	const { setShowCartPopup, products } = useMerchStore();

	return (
		<div className="sticky flex flex-col space-y-[15px] justify-end items-end z-30 right-2 bottom-[85px] sm:bottom-[10px] space-x-[7px] px-[4px] pr-[10px] mt-[30px]">
			<PrimaryBtn
				onClick={() => setShowCartPopup(true)}
				renderEllipse={false}
				style={{ width: '145px', height: '58px' }}
				content={
					<div className="absolute-center px-[14px] w-full space-x-[8px] flex items-center justify-between">
						<FontAwesomeIcon icon={faCartShopping} className="text-white" />
						<span className="font-extrabold text-[14px] text-white">Cart</span>
						<span className="px-[9px] h-[33px] flex items-center justify-center bg-[rgba(255,255,255,0.2)] text-white font-extrabold rounded-[10px] text-[15px]">
							{products.length}
						</span>
					</div>
				}
			/>
		</div>
	);
}
