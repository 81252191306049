import { ShippingAddressInterface } from 'interfaces/OrderInterfaces';
import { FieldError, UseFormRegister } from 'react-hook-form';

interface Props {
	register: UseFormRegister<ShippingAddressInterface>;
	error?: FieldError;
}

export function PhoneInputField({ register, error }: Props) {
	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		const numericValue = value.replace(/[^\d+ ]/g, '');
		event.target.value = numericValue;
	};

	return (
		<div className="flex flex-col gap-y-[5px] w-full">
			<input
				{...register('phoneNumber', {
					required: 'Phone number is required',
					pattern: {
						value: /^[0-9]+$/,
						message: 'Invalid phone number format',
					},
				})}
				inputMode="tel"
				onChange={handleInputChange}
				placeholder="Phone Number*"
				className={`w-full rounded-[4px] bg-white text-black h-[60px] px-[20px] border-[2px] outline-none ${error ? 'border-errorRed' : 'border-white'}`}
			/>
			{error && <span className="text-errorRed text-[14px]">{error.message}</span>}
		</div>
	);
}
