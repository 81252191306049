import { PrimaryBtn } from 'components/common/buttons/PrimaryBtn';

interface Props {
	showSeedPage: boolean;
	onShowSeedPageChange: (show: boolean) => void;
}

export function VerifiedFairnessSwitch({ onShowSeedPageChange, showSeedPage }: Props) {
	return (
		<div className="h-[70px] min-h-[70px] xl:h-[80px] xxl:[100px] xl:min-h-[80px] xxl:min-h-[100px] flex items-center">
			<div className="flex items-center justify-center gap-x-[15px] w-full">
				<PrimaryBtn
					renderEllipse={false}
					onClick={() => onShowSeedPageChange(true)}
					style={{
						width: '110px',
						height: '40px',
						background: ' linear-gradient(331.41deg, rgba(35, 109, 244, 0.7) 6.78%, rgba(51, 240, 167, 0.7) 204.87%)',
						boxShadow: showSeedPage ? '0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 4px 5px rgba(0, 0, 0, 0.6)' : '',
					}}
					content={<span className="text-[15px] font-semibold">Seeds</span>}
				/>
				<PrimaryBtn
					renderEllipse={false}
					onClick={() => onShowSeedPageChange(false)}
					style={{
						width: '110px',
						height: '40px',
						background: ' linear-gradient(331.41deg, rgba(35, 109, 244, 0.7) 6.78%, rgba(51, 240, 167, 0.7) 204.87%)',
						boxShadow: !showSeedPage ? '0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 4px 5px rgba(0, 0, 0, 0.6)' : '',
					}}
					content={<span className="text-[15px] font-semibold">Info</span>}
				/>
			</div>
		</div>
	);
}
