import { RefObject, useEffect, useState } from 'react';

interface Props {
	top10Ref: RefObject<HTMLDivElement>;
}

/**
 * Custom hook to determine if the window scroll position is above or below a reference element.
 * This is useful for triggering UI changes when the reference element reaches a certain scroll position.
 *
 * @param top10Ref - A reference to the target HTMLDivElement.
 * @returns A boolean indicating whether the window scroll position is above the reference element.
 */
export function useScrollPositionAboveRef({ top10Ref }: Props): boolean | null {
	const [isAbove, setIsAbove] = useState<boolean | null>(true);

	useEffect(() => {
		const handleScroll = () => {
			if (top10Ref.current) {
				const { top, height } = top10Ref.current.getBoundingClientRect();
				const newIsAbove = top > height / 2;

				if (newIsAbove !== isAbove) {
					setIsAbove(newIsAbove);
				}
			}
		};

		handleScroll(); // Call on mount to set initial state

		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [isAbove, top10Ref]);

	return isAbove;
}
