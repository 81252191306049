interface Props {
	width?: number;
	height?: number;
}

export function IconPassword({ height = 24, width = 24 }: Props = {}) {
	return (
		<>
			{/* Icon used under CC By 4.0 License */}
			{/* No changes were made to this icon. */}
			{/* solar:lock-password-linear */}
			{/* attribution: https://icon-sets.iconify.design/solar/lock-password-linear/ */}
			<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
				<g fill="none" stroke="currentColor">
					<path
						strokeWidth={1.5}
						d="M2 16c0-2.828 0-4.243.879-5.121C3.757 10 5.172 10 8 10h8c2.828 0 4.243 0 5.121.879C22 11.757 22 13.172 22 16c0 2.828 0 4.243-.879 5.121C20.243 22 18.828 22 16 22H8c-2.828 0-4.243 0-5.121-.879C2 20.243 2 18.828 2 16Z"
					></path>
					<path strokeLinecap="round" strokeWidth={1.5} d="M6 10V8a6 6 0 1 1 12 0v2"></path>
					<path
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth={2}
						d="M8 16h.009m3.982 0H12m3.991 0H16"
					></path>
				</g>
			</svg>
		</>
	);
}
