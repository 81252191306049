import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AmountDisplayWithCurrency } from 'components/common/AmountDisplayWithCurrency';
import { useUserContext } from 'hooks';

interface Props {
	disabled?: boolean;
	onClick?: () => void;
}

const DEFAULT_VALUE = 421;

export function Wallet({ disabled = true, onClick }: Props) {
	const { user } = useUserContext();
	const displayedValue = user?.balance ?? DEFAULT_VALUE;

	return (
		<div className="rounded-[24px] bg-mrLootBlue flex items-center justify-center pl-6 font-semibold text-[13px] pr-2 w-[123px] shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)] text-white h-[28px]">
			<AmountDisplayWithCurrency
				amount={displayedValue}
				abbreviationOption="none"
				style={{ margin: '0 auto', fontSize: '13px' }}
			/>
			<button
				onClick={() => !disabled && onClick && onClick()}
				className="ml-auto rounded-[24px] flex items-center justify-center w-[25px] h-[20px] text-black bg-white shadow-[0_4px_4px_rgba(0,0,0,0.25)]
                hover:drop-shadow-[0_0_1px_rgb(255,255,255)] active:shadow-inner active:drop-shadow-none active:bg-[rgba(255,255,255,0.9)]"
			>
				<FontAwesomeIcon icon={faPlus} className="text-[13px]" />
			</button>
		</div>
	);
}
