import axios from 'axios';
import { useQuery } from 'react-query';

import { API_DOMAIN, QUERY_CACHE_CONFIG, QUERY_KEYS } from '../../../constants';

async function requestCryptoPaymentInit({ currency }: Props) {
	try {
		const response = await axios.post(
			`${API_DOMAIN.mrlootBackend}/api/payment/crypto/initiate-transaction`,
			{
				currency,
			},
			{ withCredentials: true }
		);

		if (response.data.status === 'success') {
			return response.data.data;
		}
	} catch (err) {
		if (axios.isAxiosError(err)) {
			const message = err.response?.data?.message || err.message || 'Something unexpected went wrong';
			throw new Error(message);
		}
		throw err;
	}
}

interface Props {
	currency: any;
}

interface ReturnInterface {
	memo?: string;
	payAddress: string;
}

export function useCryptoPaymentInitiator({ currency }: Props) {
	return useQuery<ReturnInterface>({
		queryFn: () => requestCryptoPaymentInit({ currency }),
		queryKey: [QUERY_KEYS.cryptoPayment, currency],
		staleTime: QUERY_CACHE_CONFIG.veryLongTerm.staleTime, // TODO: dont forget to invalidate data when amount has been paid
		cacheTime: QUERY_CACHE_CONFIG.veryLongTerm.cacheTime,
	});
}
