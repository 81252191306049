import { useAutoAnimate } from '@formkit/auto-animate/react';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';

interface Props {
	note: string;
	onChange: (note: string) => void;
}

export function ProductNoteField({ note, onChange }: Props) {
	const [isNoteFieldVisible, setIsNoteFieldVisible] = useState(false);
	const [animationParent] = useAutoAnimate();

	const [localNoteState, setLocalNoteState] = useState(note);

	useEffect(() => {
		setLocalNoteState(note);
	}, [note]);

	const handleNoteChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		const newNote = e.target.value;
		setLocalNoteState(newNote);
	};

	const handleNoteFieldLeave = () => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(document.body.style as any).zoom = '1.0'; // Resets zoom level to default
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(document.body.style as any).maximumScale = '10.0'; // safari - Resets zoom level to default
		onChange(localNoteState);
	};

	return (
		<div ref={animationParent} className="flex flex-col w-full justify-center space-y-[5px]">
			<button
				onClick={() => setIsNoteFieldVisible((prev) => !prev)}
				className={`${isNoteFieldVisible ? 'justify-start text-[rgba(255,255,255,0.4)]' : 'justify-center text-mrLootBlue'} flex items-center space-x-[9px] `}
			>
				<FontAwesomeIcon
					icon={isNoteFieldVisible ? faMinus : faPlus}
					className={`${isNoteFieldVisible ? 'text-[14px]' : 'text-[18px]'}`}
				/>
				<span className="text-[14px] font-bold">{isNoteFieldVisible ? 'hide Note Field' : 'Add Note'}</span>
			</button>
			{isNoteFieldVisible && (
				<textarea
					value={localNoteState}
					onBlur={handleNoteFieldLeave}
					onChange={handleNoteChange}
					className="p-2 text-[rgba(255,255,255,0.6)] text-[16px] border-[2px] bg-[rgba(0,0,0,0.2)] outline-none border-[rgba(255,255,255,0.1)] rounded-[4px] h-[90px] w-full"
				/>
			)}
		</div>
	);
}
