import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Icon } from '@iconify/react';
import { Ellipse, FlyingCoin, LogoBlue, MrLootCharacter } from 'assets/images';
import { AmountDisplayWithCurrency } from 'components/common/AmountDisplayWithCurrency';
import { SecondaryBtn } from 'components/common/buttons/SecondaryBtn';
import { useUserContext } from 'hooks/context/useUserContext';
import { useWindowDimensions } from 'hooks/utility/layout-measurements/useWindowDimensions';
import { useLayoutEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useBalanceStore } from 'store/useBalanceStore';
import { usePopupStore } from 'store/usePopupStore';
import { shallow } from 'zustand/shallow';

import { screens } from '../../../../constants';
import { LoginBtn } from '../components/LoginBtn';
import { RegisterBtn } from '../components/RegisterBtn';
import { CurrencyChangeBtn } from './CurrencyChangeBtn';
import { SpeechBubble } from './SpeechBubble';

export function TopbarDesktop() {
	const { user } = useUserContext();
	const { balance, isHighlighted } = useBalanceStore(
		(state) => ({ balance: state.balance, isHighlighted: state.isHighlighted }),
		shallow
	);

	const maxBalanceRef = useRef<HTMLButtonElement>(null);

	//useDynamicBalanceWidth({ balanceRef: maxBalanceRef, baseWidth: 110, currency: selectedCurrency });

	const { screenWidth } = useWindowDimensions();

	const navigate = useNavigate();
	const setIsDepositPopupVisible = usePopupStore((state) => state.setIsDepositPopupVisible, shallow);

	const [scrollbarWidth, setScrollbarWidth] = useState(window.innerWidth - document.documentElement.clientWidth);
	useLayoutEffect(() => {
		const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
		setScrollbarWidth(scrollbarWidth);
	}, [screenWidth]);

	const displayedBalance = balance !== undefined ? balance : (user?.balance ?? 0);

	return (
		<>
			<div
				className={`fixed left-0 top-0 z-40 h-[63px] bg-mrLootGray flex items-center justify-center pl-desktop-left-padding pr-desktop-right-padding `}
				style={{
					width: `calc(100vw - ${scrollbarWidth}px)`,
					background: 'linear-gradient(89.69deg, #2981E9 8.56%, #000000 99.74%)',
				}}
			>
				<div className="flex items-center justify-between w-full max-w-max-screen-width h-full">
					<div className="flex items-center h-full">
						<Link to="/" className="flex h-full items-center overflow-hidden">
							<img src={FlyingCoin} alt="" className="h-[21px] mt-[3px] w-auto mb-auto realtive" />
							<img src={LogoBlue} alt="MrLoot Logo" className="h-[32px]" />
							<img src={MrLootCharacter} alt="" className="mt-auto h-[70px] w-auto relative top-[23px] left-[-16px]" />
						</Link>
						{screenWidth >= screens.md.width && <SpeechBubble />}
					</div>
					<div className="flex space-x-4 font-inter text-sm text-white">
						<CurrencyChangeBtn />
						{user && (
							<>
								<button
									onClick={() => setIsDepositPopupVisible(true)}
									ref={maxBalanceRef}
									className="relative rounded-[24px] flex items-center justify-end pl-[20px] pr-2 h-[34px] border-[2px] border-darkBlue overflow-hidden bg-primary-gradient outline outline-[3px] outline-white"
								>
									<img src={Ellipse} className="absolute left-[7px] top-[2px]" alt="" />

									<AmountDisplayWithCurrency
										amount={displayedBalance}
										style={{ margin: '0 auto', paddingRight: '9px' }}
										amountStyle={{
											color: isHighlighted ? '#52ff58' : 'white', // Highlight when necessary
											textShadow: isHighlighted ? '0 1px 2px #3c394275' : 'none', // Highlight when necessary
											fontSize: '14px',
										}}
										imgStyle={{ height: '18px' }}
										abbreviationOption="none"
									/>

									<div
										className="rounded-[24px] flex items-center justify-center w-[30px] h-[23px] text-black bg-white
                                        hover:drop-shadow-[0_0_1px_rgb(255,255,255)] active:shadow-inner active:drop-shadow-none active:bg-[rgba(255,255,255,0.9)]"
									>
										<FontAwesomeIcon icon={faPlus} className="text-[15px]" />
									</div>
								</button>
								<SecondaryBtn
									id="inventory-topbar-link"
									onClick={() => navigate('/inventory')}
									style={{ width: '120px', height: '34px' }}
									content={
										<div className="flex items-center justify-center w-full h-full gap-x-[8px] text-[14px] font-semibold">
											<Icon icon="material-symbols:inventory-2-outline" />
											<span className="font-semibold">Inventory</span>
										</div>
									}
								/>
							</>
						)}

						{!user && (
							<>
								<LoginBtn />
								<RegisterBtn />
							</>
						)}
					</div>
				</div>
			</div>
		</>
	);
}
