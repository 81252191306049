import { Skeleton } from 'components';
import { useItemsByTags } from 'hooks/data/items/useItemsByTags';

import { useProductNameFromParams } from '../hooks/useProductNameFromParams';
import { ProductCard } from './ProductCard';

const NUM_OF_PLACEHOLDER = 10;

export function RecommendedBoxes() {
	const productName = useProductNameFromParams();

	const { data: merchItems, isLoading: isMerchItemsLoading } = useItemsByTags(['lootie-merch']);
	const recommendedItems = merchItems?.filter(
		(item) => item.name.toLocaleLowerCase() !== productName.toLocaleLowerCase()
	);

	return (
		<div className="flex items-center max-w-full overflow-y-auto gap-x-[15px] no-scrollbar">
			{recommendedItems?.map((item) => <ProductCard key={item._id} item={item} style={{ minWidth: '165px' }} />)}
			{isMerchItemsLoading &&
				Array.from({ length: NUM_OF_PLACEHOLDER }).map((_, index) => (
					<Skeleton key={index} style={{ height: '240px', minWidth: '165px', width: '165px' }} />
				))}
		</div>
	);
}
