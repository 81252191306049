import { WithPageTitle } from 'components/misc/WithPageTitle';
import { AMLPolicy } from 'pages/policies/AMLPolicy';
import { CookiePolicy } from 'pages/policies/CookiePolicy';
import { FAQ } from 'pages/policies/FAQ';
import { PrivacyStatement } from 'pages/policies/PrivacyStatement';
import { TermsOfService } from 'pages/policies/TermsOfService';

export const policyRoutes = [
	{
		path: 'cookie-policy',
		element: (
			<WithPageTitle title="Cookie Policy - Understand Our Cookies | MrLoot">
				<CookiePolicy />
			</WithPageTitle>
		),
	},
	{
		path: 'aml-policy',
		element: (
			<WithPageTitle title="AML Policy - Anti-Money Laundering | MrLoot">
				<AMLPolicy />
			</WithPageTitle>
		),
	},
	{
		path: 'terms-of-service',
		element: (
			<WithPageTitle title="Terms of Service - User Agreement | MrLoot">
				<TermsOfService />
			</WithPageTitle>
		),
	},
	{
		path: 'faq',
		element: (
			<WithPageTitle title="FAQ - Frequently Asked Questions | MrLoot">
				<FAQ />
			</WithPageTitle>
		),
	},
	{
		path: 'privacy',
		element: (
			<WithPageTitle title="Privacy Statement - Your Data Privacy | MrLoot">
				<PrivacyStatement />
			</WithPageTitle>
		),
	},
	{
		/*
		path: 'contact',
		element: (
			<WithPageTitle title="Contact Us - Get in Touch | MrLoot">
				<Contact />
			</WithPageTitle>
		),
	*/
	},
];
