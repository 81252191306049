import { ItemRarity } from 'interfaces/ItemInterfaces';
import { useBoxOpeningStoreDesktop } from 'pages/OpenBox/store/useBoxOpeningStoreDesktop';
import { RefObject } from 'react';

import { BackgroundCircles } from './BackgroundCircles';
import { BackgroundFonts } from './BackgroundFonts';
import { useResponsiveFontStyling } from './hooks/useResponsiveFontStyling';

interface Props {
	parentRef: RefObject<HTMLDivElement>;
}

export function WinScreenBackground({ parentRef }: Props) {
	const { isFullScreen, wonPrize } = useBoxOpeningStoreDesktop();

	const { fontProps } = useResponsiveFontStyling(parentRef);
	const rarity = wonPrize?.data.rarity ?? ItemRarity.LIGHT_BLUE;

	return (
		<>
			<div
				className={`absolute rounded-[20px] left-0 top-0 z-10 bg-[rgba(12,15,35,0.30)] w-full h-full backdrop-blur-[100px] ${!isFullScreen && 'rounded-[21px]'} `}
			/>
			<div
				className={`absolute left-0 top-0 z-20 backdrop-blur-[22px] w-full h-full ${!isFullScreen && 'rounded-[21px]'} `}
			/>

			<div
				className={`absolute inset-0 rounded-[20px] w-full h-full ${!isFullScreen && 'rounded-[21px]'} overflow-hidden`}
			>
				<BackgroundCircles rarity={rarity} />
				<BackgroundFonts {...fontProps} />
			</div>
		</>
	);
}
