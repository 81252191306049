import { gsap } from 'gsap';
import React, { CSSProperties, useEffect, useRef } from 'react';

interface FadeWrapperProps {
	show: boolean;
	children: React.ReactNode;
	duration?: number;
	style?: CSSProperties;
}

export const WithComponentFadeIn: React.FC<FadeWrapperProps> = ({ show, children, duration = 0.15, style }) => {
	const containerRef = useRef<HTMLDivElement>(null);

	const timelineRef = useRef(gsap.timeline());

	useEffect(() => {
		const tl = timelineRef.current;
		tl.clear();
		if (show) {
			tl.to(containerRef.current, { autoAlpha: 1, duration: duration, ease: 'power1.out' });
		} else {
			tl.to(containerRef.current, { autoAlpha: 0, duration: duration, ease: 'power1.out' });
		}

		return () => {
			tl.kill();
		};
	}, [duration, show]);

	return (
		<div ref={containerRef} className="opacity-0" style={style}>
			{children}
		</div>
	);
};
