import { ItemInterface } from 'interfaces/ItemInterfaces';
import { FailedItemsMap, SelectedItemOption, SelectedOptionSet } from 'pages/checkout/InterfacesCheckout';

import { ContainerManagementSection } from './ContainerManagementSection';
import { ImageSection } from './ImageSection';
import { ProductDetails } from './ProductDetails';

interface Props {
	item: ItemInterface;
	quantity: number;
	selectedOptions?: SelectedOptionSet[];
	additionalPurchaseNum: number;
	onlyDisplay?: boolean;
	isExpressShipping: boolean;
	note: string;
	optionErrors?: FailedItemsMap;
	onQuantityChange?: (quantity: number) => void;
	onSelectedOptionChange?: (itemId: string, updatedOptionSet: SelectedItemOption, indexOfOptionSet: number) => void;
	onRemove?: () => void;
	onNoteChange?: (note: string) => void;
}

export function ProductContainer({
	item,
	quantity,
	selectedOptions,
	additionalPurchaseNum,
	onlyDisplay,
	isExpressShipping,
	note,
	optionErrors,
	onQuantityChange,
	onRemove,
	onSelectedOptionChange,
	onNoteChange,
}: Props) {
	return (
		<div className="flex-grow py-[18px] min-h-[120px] h-fit border-[3px] rounded-[24px] shadow-[0_4px_4px_rgba(0,0,0,0.25)] border-white01 grid grid-cols-[140px_auto_150px] sm:grid-cols-[140px_auto_170px] lg:grid-cols-[170px_auto_200px] px-[25px]">
			<ImageSection item={item} />
			<ProductDetails
				isExpressShipping={isExpressShipping}
				item={item}
				additionalPurchaseNum={additionalPurchaseNum}
				onSelectedOptionChange={onSelectedOptionChange}
				selectedOptions={selectedOptions}
				note={note}
				onNoteChange={onNoteChange}
				onlyDisplay={onlyDisplay}
				quantity={quantity}
				optionErrors={optionErrors}
			/>
			<ContainerManagementSection
				item={item}
				quantity={quantity}
				onRemove={onRemove}
				onQuantityChange={onQuantityChange}
				onlyDisplay={onlyDisplay}
			/>
		</div>
	);
}

export function LoadingProductContainer() {
	return (
		<div className="flex-grow py-[18px] min-h-[120px] h-fit border-[3px] rounded-[24px] shadow-[0_4px_4px_rgba(0,0,0,0.25)] border-white01 flex justify-between px-[25px]">
			<ImageSection />
			<ProductDetails />
			<ContainerManagementSection />
		</div>
	);
}
