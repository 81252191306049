import { NumOneNoPadding, NumThreeNoPadding, NumTwoNoPadding } from 'assets/images';
import { ReturnGroupType } from 'interfaces/OrderReturns.interface';

interface Step {
	imgSrc: string;
	title: string;
	description: string;
}

interface Props {
	type: ReturnGroupType;
}

export function InstructionSteps({ type }: Props) {
	// Define steps in an array
	const steps: Step[] = [
		{
			imgSrc: NumOneNoPadding,
			title: 'Prepare Your Package',
			description:
				'Use the original shipping box if possible. Make sure to remove any old addresses, labels, or barcodes. If you’re using a printed return label, ensure it’s securely attached to the outside of the box.',
		},
		{
			imgSrc: NumTwoNoPadding,
			title: type === ReturnGroupType.QR_CODE ? 'Show the QR Code' : 'Attach the Return Label',
			description:
				type === ReturnGroupType.QR_CODE
					? 'Present the QR code at the nearest shipping location, and they will handle the rest for you.'
					: 'Affix the printed return label to your package and drop it off at your nearest shipping facility.',
		},
		{
			imgSrc: NumThreeNoPadding,
			title: 'Track Your Shipment',
			description:
				'Once sent, use the provided tracking number to follow your package’s progress. Allow a few days for processing after the return is received.',
		},
	];

	return (
		<div className="flex flex-col w-full items-center gap-y-[25px] mt-[50px] max-w-[700px] mx-auto">
			{steps.map((step, index) => (
				<div key={index} className="flex flex-col gap-y-[15px] items-center justify-items-center w-full">
					<img src={step.imgSrc} alt={`Step ${index + 1}`} className="h-[60px]" />
					<div className="flex flex-col gap-y-[5px] mb-[20px] items-center">
						<h2 className="font-semibold text-[18px] text-center">{step.title}</h2>
						<p className="text-center text-[rgba(255,255,255,0.8)]">{step.description}</p>
					</div>
				</div>
			))}
		</div>
	);
}
