import { MobileEmailInput } from 'components/common/inputs/MobileEmailInput';
import { MobilePasswordInput } from 'components/common/inputs/MobilePasswordInput';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useUserContext } from 'hooks';
import { PrimaryBtn } from 'components/common/buttons/PrimaryBtn';
import { MobileCheckBox } from 'components/common/inputs/MobileCheckbox';
import { useRegisterRequest } from '../hooks/useRegisterRequest';
import { ToastErrorNotification } from 'components/toasts/ToastErrorNotification';
import { FontRegister } from 'assets/images';

interface IFormInputInterface {
	email: string;
	password: string;
	passwordRepeat: string;
	hasAcceptedTerms: string;
}

export function MobileRegisterForm() {
	const navigate = useNavigate();
	const { load: loadUser } = useUserContext();

	const { handleSignUp, isLoading } = useRegisterRequest({
		onSuccess: () => {
			loadUser();
			navigate('/successful-register');
		},
		onError: (message) => {
			toast(<ToastErrorNotification message={message ?? 'Something went wrong'} />);
		},
	});

	const {
		register,
		setError,
		clearErrors,
		handleSubmit,
		formState: { errors },
		resetField,
		control,
		watch,
	} = useForm<IFormInputInterface>();

	const onSubmit: SubmitHandler<IFormInputInterface> = (data) => {
		if (data.password !== data.passwordRepeat) {
			setError('passwordRepeat', {
				type: 'manual',
				message: 'Passwords do not match',
			});
			return;
		}
		if (!data.hasAcceptedTerms) {
			setError('hasAcceptedTerms', {
				type: 'manual',
				message: 'You need to accept Terms and Conditions',
			});
			return;
		}
		clearErrors('passwordRepeat');
		handleSignUp(data);
	};

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)} className="w-full space-y-[40px]">
				<div className="space-y-[16px] flex flex-col">
					<h1 className="text-white font-bold text-[26px] mb-[10px] font-poppins">Register</h1>
					<div className="flex flex-col gap-y-[5px]">
						<span className="text-white text-[14px] font-poppins">Email</span>
						<MobileEmailInput<IFormInputInterface>
							register={register}
							resetField={resetField}
							watch={watch}
							errors={errors}
						/>
					</div>
					<div className="flex flex-col gap-y-[5px]">
						<span className="text-white text-[14px] font-font-poppins">Password</span>
						<MobilePasswordInput<IFormInputInterface> register={register} errors={errors} />
					</div>
					<div className="flex flex-col gap-y-[5px]">
						<span className="text-white text-[14px] font-font-poppins">Repeat Password</span>
						<MobilePasswordInput<IFormInputInterface>
							registerFieldName="passwordRepeat"
							register={register}
							errors={errors}
							placeholder="Repeat Password"
						/>
					</div>

					<div className="flex items-center gap-x-[12px]">
						<MobileCheckBox<IFormInputInterface>
							register={register}
							control={control}
							errors={errors}
							fieldName="hasAcceptedTerms"
							strokeColor="white"
						/>
						<label className="text-[13px] text-white flex items-center justify-center">
							<span className="font-poppins">I accept the </span>
							<Link className="text-white text-[15px] ml-[5px] font-poppins font-semibold" to="/terms-of-service">
								Terms of Service
							</Link>
						</label>
					</div>
				</div>
				<PrimaryBtn
					content={<img src={FontRegister} alt="register" className="h-[24px] object-contain" />}
					type="submit"
					isDisabled={isLoading}
					style={{ borderRadius: '40px' }}
					innerContentStyle={{ borderRadius: '40px' }}
				/>
			</form>
		</>
	);
}
