import { WithPageTitle } from 'components/misc/WithPageTitle';
import DesktopLogin from 'pages/authentication/desktop/DesktopLogin';
import { DesktopRegister } from 'pages/authentication/desktop/DesktopRegister';
import { DesktopPasswordCodeVerificationStep } from 'pages/authentication/desktop/reset-password/DesktopResetPasswordCodeVerificationStep';
import { DesktopResetPasswordEmailStep } from 'pages/authentication/desktop/reset-password/DesktopResetPasswordEmailStep';
import { DesktopResetPasswordFinalStep } from 'pages/authentication/desktop/reset-password/DesktopResetPasswordFinalStep';
import MobileLogin from 'pages/authentication/mobile/MobileLogin';
import { MobileRegister } from 'pages/authentication/mobile/MobileRegister';
import { MobileResetPasswordCodeVerificationStep } from 'pages/authentication/mobile/reset-password/MobileResetPasswordCodeVerificationStep';
import { MobileResetPasswordEmailStep } from 'pages/authentication/mobile/reset-password/MobileResetPasswordEmailStep';
import { MobileResetPasswordFinalStep } from 'pages/authentication/mobile/reset-password/MobileResetPasswordFinalStep';
import { RouteObject } from 'react-router-dom';

export const mobileAuthRoutes: RouteObject[] = [
	{
		path: 'login',
		element: (
			<WithPageTitle title="Login - Access Your Account | MrLoot">
				<MobileLogin />
			</WithPageTitle>
		),
	},
	{
		path: 'register',
		element: (
			<WithPageTitle title="Register - Join MrLoot Today">
				<MobileRegister />
			</WithPageTitle>
		),
	},
	{
		path: 'reset-password/enter-email',
		element: (
			<WithPageTitle title="Reset Password - Enter Your Email | MrLoot">
				<MobileResetPasswordEmailStep />
			</WithPageTitle>
		),
	},
	{
		path: 'reset-password/verification-code',
		element: (
			<WithPageTitle title="Verify Code - Reset Your Password | MrLoot">
				<MobileResetPasswordCodeVerificationStep />
			</WithPageTitle>
		),
	},
	{
		path: 'reset-password/change-password',
		element: (
			<WithPageTitle title="Change Password - Secure Your Account | MrLoot">
				<MobileResetPasswordFinalStep />
			</WithPageTitle>
		),
	},
];

export const desktopAuthRoutes: RouteObject[] = [
	{
		path: 'login',
		element: (
			<WithPageTitle title="Login - Access Your Account | MrLoot">
				<DesktopLogin />
			</WithPageTitle>
		),
	},
	{
		path: 'register',
		element: (
			<WithPageTitle title="Register - Join MrLoot Today">
				<DesktopRegister />
			</WithPageTitle>
		),
	},
	{
		path: 'reset-password/enter-email',
		element: (
			<WithPageTitle title="Reset Password - Enter Your Email | MrLoot">
				<DesktopResetPasswordEmailStep />
			</WithPageTitle>
		),
	},
	{
		path: 'reset-password/verification-code',
		element: (
			<WithPageTitle title="Verify Code - Reset Your Password | MrLoot">
				<DesktopPasswordCodeVerificationStep />
			</WithPageTitle>
		),
	},
	{
		path: 'reset-password/change-password',
		element: (
			<WithPageTitle title="Change Password - Secure Your Account | MrLoot">
				<DesktopResetPasswordFinalStep />
			</WithPageTitle>
		),
	},
];
