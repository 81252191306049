import { MobileEmailInput } from 'components/common/inputs/MobileEmailInput';
import { MobilePasswordInput } from 'components/common/inputs/MobilePasswordInput';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { useLoginRequest } from '../hooks/useLoginRequest';
import { toast } from 'react-toastify';
import { useUserContext } from 'hooks';
import { PrimaryBtn } from 'components/common/buttons/PrimaryBtn';
import { FontLogin } from 'assets/images';

interface IFormInputInterface {
	email: string;
	password: string;
}

export function MobileLoginForm() {
	const navigate = useNavigate();
	const { load: loadUser } = useUserContext();

	const { handleSignIn, isLoading } = useLoginRequest({
		onSuccess: () => {
			loadUser();
			navigate('/successful-login');
		},
		onError: (message) => {
			toast.error('Incorrect email or password. Please try again or reset your password.', {
				icon: false,
			});
		},
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		resetField,
		watch,
	} = useForm<IFormInputInterface>();

	return (
		<>
			<form onSubmit={handleSubmit(handleSignIn)} className="w-full space-y-[40px]">
				<div className="space-y-[16px] flex flex-col">
					<h1 className="text-white font-bold text-[26px] font-poppins mb-[10px]">Login</h1>

					<div className="flex flex-col gap-y-[5px]">
						<span className="text-white text-[14px] font-poppins">Email</span>
						<MobileEmailInput register={register} resetField={resetField} watch={watch} errors={errors} />
					</div>
					<div className="flex flex-col gap-y-[5px]">
						<span className="text-white text-[14px] font-poppins">Password</span>
						<MobilePasswordInput register={register} errors={errors} />
					</div>

					<Link
						to="/auth/reset-password/enter-email"
						className="ml-auto text-white text-[14px] tracking-[0.09em] font-poppins"
					>
						Forgot Password?
					</Link>
				</div>
				<PrimaryBtn
					content={<img src={FontLogin} alt="register" className="h-[24px] object-contain" />}
					type="submit"
					isDisabled={isLoading}
					style={{ borderRadius: '40px' }}
					innerContentStyle={{ borderRadius: '40px' }}
				/>
			</form>
		</>
	);
}
