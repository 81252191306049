import axios from 'axios';
import { OrderDbInterface } from 'interfaces/OrderInterfaces';
import { useQuery } from 'react-query';

import { API_DOMAIN, QUERY_KEYS } from '../../../constants';

async function getOrderByNr(orderNr?: number) {
	try {
		if (!orderNr) {
			return;
		}
		const response = await axios.post(
			`${API_DOMAIN.mrlootBackend}/api/order/get-by-orderNr`,
			{ orderNr: orderNr },
			{ withCredentials: true }
		);

		if (response.data.status === 'success') {
			return response.data.data;
		}
	} catch (err) {
		console.error(err);
		throw err;
	}
}

interface Props {
	orderNr?: number;
}

export function useOrderByOrderNr({ orderNr }: Props) {
	return useQuery<OrderDbInterface>({
		queryKey: [QUERY_KEYS.orderData, orderNr, 'order-by-orderNr'],
		queryFn: () => getOrderByNr(orderNr),
		enabled: !!orderNr,
	});
}
