import { AmountDisplayWithCurrency } from 'components/common/AmountDisplayWithCurrency';
import { PrimaryBtn } from 'components/common/buttons/PrimaryBtn';
import { useBackBtnInTopBar } from 'hooks/app-layout/useBackBtnInTopbar';
import { useTopbarBackBtnListener } from 'hooks/events/useTopbarBackBtnListener';
import { useQueryParam } from 'hooks/utility/useQueryParam';
import { useOrderByOrderNr } from 'pages/MyOrders/hooks/useOrderByOrderNr';
import { useReturnForm } from 'pages/MyOrders/hooks/useReturnForm';
import { RETURN_COSTS_PER_ITEM } from 'pages/MyOrders/order.checkout.constant';
import { useCallback, useMemo, useState } from 'react';
import { FormProvider } from 'react-hook-form';

import { ProductRow } from './ProductRow';

export function OrderReturn() {
	const [orderNr, setOrderNr] = useState<number | undefined>();

	useQueryParam('orderNr', (param) => {
		const orderNr = Number(param);
		if (!isNaN(orderNr)) {
			setOrderNr(orderNr);
		}
	});

	const { data: order } = useOrderByOrderNr({ orderNr });

	useBackBtnInTopBar();

	useTopbarBackBtnListener(
		useCallback(() => {
			window.history.back();
		}, [])
	);

	const returnableItems = useMemo(
		() => order?.items.filter((item) => item.quantity > item.unavailableQuantity),
		[order?.items]
	);
	const [selectedItemIndices, setSelectedItemIndices] = useState<number[]>([]);
	const returnFees = RETURN_COSTS_PER_ITEM * selectedItemIndices.length;

	const {
		isCreationLoading,
		methods: formMethods,
		onSubmit,
	} = useReturnForm({
		orderId: order?._id,
		orderNr: orderNr,
		returnFees,
		returnableItems,
		onSuccessfulCreation: () => window.history.back(),
	});

	const toggleSelectedItemIndex = (index: number) => {
		setSelectedItemIndices((prev) => {
			if (prev.includes(index)) {
				// If the index is already in the array, remove it
				return prev.filter((i) => i !== index);
			}
			// Otherwise, add it to the array
			return [...prev, index];
		});
	};

	return (
		<FormProvider {...formMethods}>
			<div className="flex flex-col gap-y-[15px]">
				<h1 className="text-white font-bold text-[22px] text-center">Select items you want to return</h1>
				<form onSubmit={formMethods.handleSubmit(onSubmit)}>
					<div className="relative w-screen -left-mobile-side-padding max-h-dvh overflow-y-auto flex flex-col items-center gap-y-[25px] px-[15px] py-[30px] bg-white">
						{returnableItems?.map((item, index) => (
							<ProductRow
								key={index}
								orderedProduct={item}
								index={index}
								onItemSelectionChange={toggleSelectedItemIndex}
							/>
						))}
						<PrimaryBtn
							type="submit"
							isDisabled={isCreationLoading}
							style={{ height: '55px' }}
							content={
								<div className="flex items-center gap-x-[10px] text-[16px]">
									<span>Create Return</span>
									<AmountDisplayWithCurrency
										amount={returnFees}
										style={{ fontSize: '16px' }}
										imgStyle={{ height: '16px' }}
									/>
								</div>
							}
						/>
					</div>
				</form>
			</div>
		</FormProvider>
	);
}
