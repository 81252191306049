import { FontMissedLoot } from 'assets/images';
import { ItemInterface } from 'interfaces/ItemInterfaces';

import { CLOUDFRONT_IMG_URL } from '../../../constants';

interface Props {
	item: ItemInterface;
}

export function ToastMissedLootNotification({ item }: Props) {
	return (
		<div className="text-white w-full h-[62px] flex flex-col items-start justify-between space-y-[10px]">
			<img src={FontMissedLoot} className="h-[20px]" alt="new Loot" />
			<div className="flex items-center w-full space-x-[17px]">
				<img
					src={`${CLOUDFRONT_IMG_URL}/${item.imageUrls.qualityMedium}`}
					alt={item.name}
					className="h-[35px] object-scale-down max-w-[35%]"
				/>
				<h2 className="text-white line-clamp-1 font-bold text-[15px]">{item.name}</h2>
			</div>
		</div>
	);
}
