import { EmptyInventoryFont, EmptyInventoryMrloot } from 'assets/images';

export function EmptyInventory() {
	return (
		<div className="px-mobile-side-padding flex flex-col space-y-4 items-center justify-center w-full pb-[100px] my-auto">
			<img
				alt=""
				src={EmptyInventoryMrloot}
				className="object-scale-down h-[25vh] xs:h-[29vh] ss:h-[32vh] sm:h-[35vh] max-h-[300px] max-w-full"
			/>
			<img
				alt="Inventory is empty"
				src={EmptyInventoryFont}
				className="object-scale-down h-[9vh] xs:h-[10vh] ss:h-[11vh]  sm:h-[12vh] max-w-full max-h-[80px]"
			/>
		</div>
	);
}
