/* eslint-disable max-lines-per-function */
import { Accordion } from 'components/common/Accordion';

export function FAQ() {
	return (
		<div className="flex flex-col gap-y-[10px] text-[15px]">
			<h1 className="text-white font-bold text-[22px] pb-[30px]">Frequently Asked Questions</h1>
			<Accordion isInitiallyExpanded={true} title="What are WinBoxes?">
				WinBoxes are virtual Boxes that users can purchase or earn through activities on our website. Each WinBox
				contains a surprise physical and digital products, as well as vacations and similar items, which winners can
				have shipped to their homes.
			</Accordion>
			<Accordion title="How do WinBoxes work? ">
				Users can purchase WinBoxes using credits or participate in activities on our platform to earn them for free.
				Each WinBox contains a set of products and experiences which users can win instantly upon opening.
			</Accordion>
			<Accordion title="What kind of products can I win in a WinBox?">
				The specific products that can be won in each WinBox are displayed on the Box Info page once the user clicks on
				the WinBox. Each WinBox contains a mix of physical and digital products, vacations, and similar surprises.
			</Accordion>
			<Accordion title="How do I open a WinBox?">
				Once you have a WinBox, simply click on it to reveal the products and experiences inside. The outcome is
				randomized, and the items displayed upon opening are what you win. You open the WinBox by simply clicking on the
				"Open"-Button.
			</Accordion>
			<Accordion title="Is there a guaranteed value or type of product in each WinBox?">
				Each WinBox contains products and experiences with a minimum guaranteed value. The specific type and value vary
				and are part of the excitement of the WinBox experience.
			</Accordion>
			<Accordion title="How do I receive my prize?">
				If you win a physical product or experience from a WinBox, it will be stored in your Inventory. From there you
				can choose to either sell it or ship it to your home. If you wish to receive it then you will be prompted to
				provide shipping information. We will then arrange for the delivery or fulfillment of your prize.
			</Accordion>
			<Accordion title="Are there any costs involved besides purchasing the WinBox?">
				Once you have purchased or earned a WinBox, there are no additional costs besides shipping (if the order value
				is below the minimum of $50 or you chose Express Shipping).
			</Accordion>
			<Accordion title="Is there a limit to how many WinBoxes I can purchase?">
				Users can purchase as many WinBoxes as they like, subject to availability and any limits specified on the
				website.
			</Accordion>
			<Accordion title="What happens if I receive a damaged or incorrect product?">
				We strive to ensure all products and experiences are delivered as advertised. In the unlikely event of an issue,
				please contact our support team immediately for assistance.
			</Accordion>
			<Accordion title="Can I return or exchange the product or experience I receive?">
				Due to the nature of the WinBoxes and the surprise element, we do not accept returns or exchanges on the
				products or experiences received.
			</Accordion>
			<Accordion title="How can I contact customer support if I have further questions?">
				For any other questions or assistance, please contact our customer support team through our contact form.
			</Accordion>
			<Accordion title="Is MrLoot fair?">
				Absolutely! We launched MrLoot in 2018 to establish a new benchmark for integrity in the market. We’re committed
				to continuing our WinBoxes, but it’s crucial to protect customers from any misconduct. Our focus is on promoting
				fairness and authenticity, ensuring the industry remains enjoyable for everyone involved. What makes MrLoot
				uniquely fair is our Fair Packing Policy. We’ve got a detailed explanation on this, but in short, we’re very
				transparent about our algorithm and how it determines your prize. Unlike many other “Mystery Box” retailers who
				can’t explain their packing methods or provide proof of including high-value items, we offer 100% assurance that
				our boxes are packed fairly, and we can prove it. While it can be complex, we’ve simplified the process so
				everyone can understand how it works.
			</Accordion>
			<Accordion title="Ordering a product">
				When you're ready to receive your item, go to your inventory and select it to reveal the order button. Make sure
				to follow the prompts to enter your shipping details and confirm delivery. Please fill in all the information
				correctly. Missing details, such as an email for the gift card or shoe sizes, will result in automatic
				cancellation. If this happens, we’ll email you (provided you’ve given a valid email) to inform you.
			</Accordion>
			<Accordion title="Exchanges">
				Another unique feature of MrLoot is our exchange option. If you're not thrilled with your unboxed item, you can
				instantly swap it for something you love or store credit. We have plenty of cool stuff, so you’re sure to find
				something great. The process is simple: just open the page “My Orders”, click the exchange button on the
				specific product in the order you previously made. Our streetwear products mainly use US sizing.
			</Accordion>
			<Accordion title="Shipping">
				Once you've redeemed an item and entered your shipping details, our team will process your order within three
				business days. Shipping typically takes seven to fourteen days, and we'll provide a tracking link for you to
				monitor your item's journey. If you don’t receive this immediately, it’s likely because we’re authenticating
				your goods first. Please be aware of international customs, as each country has its own taxes and fees. We
				declare the item's actual value plus shipping, but you'll be responsible for any local taxes and fees. We
				currently only ship to the following countries: United States; Canada; Austria; Belgium; Bulgaria; Croatia;
				Czech Republic; Denmark; Norway; Estonia; Finland; France; Germany; Greece; Hungary; Ireland; Italy; Latvia;
				Lithuania; Luxembourg; Malta; Netherlands; Norway; Poland; Portugal; Romania; Slovenia; Spain; and Sweden.
			</Accordion>
			<Accordion title="Returns">
				If you receive a damaged or faulty item, we're happy to refund your account with in-store credit. Just contact
				support@MrLoot.com, and we'll assist you with the return process. In this case, the Return is free of charge.
			</Accordion>
			<Accordion title="Payment">
				All boxes are purchased with in-store credit, which you can easily top up through various options. Click the
				‘Deposit’ button on desktop or tap your balance on mobile to view all purchase options. If you top up with a
				gift card, you’ll receive an email confirmation with activation instructions. If you encounter any payment
				issues, contact us at support@MrLoot.com, and we’ll address it promptly.
			</Accordion>
			<Accordion title="Remove data from MrLoot">
				Contact customer service via LiveChat or the form on the page, and provide details about the data you wish to
				delete from MrLoot.com.
			</Accordion>
			<Accordion title="Contact">
				We aim to make the process as easy and fun as possible and welcome your questions or comments. Email us at
				support@MrLoot.com or contact us through live chat, and our team will get back to you shortly.
			</Accordion>
			<Accordion title="How do I track my item?">
				Once our shipping team processes your item and the status changes to "ordered," we’ll share a tracking link on
				your "My Orders" page. You can then follow your item’s journey. Processing typically takes three business days,
				but it may occasionally take up to a couple of weeks. We know you're eager to receive your item, so we work as
				quickly as possible!
			</Accordion>
			<Accordion title="Are all your branded products authentic?">
				Oh yes. Always. 100%. This is our promise to you. Every item included in our WinBoxes undergoes a meticulous
				verification process to ensure authenticity and pristine condition before it is shipped to you. We guarantee
				that each item is from the exact brand as stated, providing you with confidence that they are genuine and not
				simply inspired by the brand. Our products are authenticated by reputable resellers like StockX, Amazon, and
				Goat before they are sent out. While this comprehensive verification may result in slightly longer delivery
				times, it is well worth the assurance that your product is genuine. As for electronics (such as hardware,
				accessories, peripherals, etc.) and a few miscellaneous items, they are always brand new. In the unlikely event
				that you receive something in less-than-perfect condition, rest assured that you can initiate a return free of
				charge.
			</Accordion>
			<Accordion title="How do you ship luxury items?">
				Our luxury products, including watches, jewelry, and more, are shipped just like any other item in our
				inventory. However, it's important to note that due to their limited availability, the timing of your order may
				be affected. In rare cases, we may need to cancel your order to offer you the choice to exchange it or opt for
				an alternative withdrawal method, such as cryptocurrency. We are committed to offering only the finest products
				in stock, and each item description will clearly outline its specifications. Please also note that while we
				cover most shipping costs, any customs fees incurred upon receiving the product, particularly for international
				shipments, will be your responsibility.
			</Accordion>
			<Accordion title="Why was my order cancelled?">
				We make every effort to minimize order cancellations, but occasionally, circumstances beyond our control may
				lead to them. This can include issues such as missing shipping information, unavailable stock of products, or
				other unavoidable factors. If your order has been cancelled and you would like to understand the specific
				reason, please reach out to us via email at support@MrLoot.com or through live chat. Our team will promptly
				assist you so you can either reorder the same product or choose an alternative.
			</Accordion>
			<Accordion title="How do I get promo codes?">
				WinBox promotional codes are offered on special occasions. The most reliable way to stay informed is by
				following our social media platforms: X, Instagram, Facebook, and TikTok. It's important to note that requesting
				free codes is strictly prohibited on MrLoot and may result in the revocation of your chat privileges.
			</Accordion>
			<Accordion title="Can I swap my item for that of a different size?">
				Yes, you can! If you're requesting a different size within the same price range, we're happy to accommodate your
				request. Streetwear products often vary in price depending on size, so there may be an additional cost involved.
				You can view available options by selecting ‘View’ above the item image on your profile. If the prices are the
				same, simply add a note specifying the size you prefer when making your request. Our streetwear items generally
				follow US sizing standards, so please keep that in mind. Need further assistance? Contact us at
				support@MrLoot.com, and we'll take care of it for you!
			</Accordion>
			<Accordion title="Do you offer refunds?">
				Your satisfaction is our utmost priority, and we strive to be as accommodating as possible. If you receive a
				faulty or damaged item, we are happy to provide a refund. Additionally, if you unbox an item from a WinBox that
				doesn’t meet your expectations, we offer an exchange option. Please note, however, that refunds for change of
				mind or for virtual WinBox purchases are not available. If you have an unspent balance in your MrLoot account
				acquired solely from deposits (excluding bonuses, item sales, gift cards, rewards, or affiliates), you may
				request a refund at any time by contacting support, particularly if you are a first-time depositor who is
				dissatisfied and wishes to withdraw their deposit. You can request a refund without providing a reason, and we
				will address your inquiry within 24 hours.
			</Accordion>
			<Accordion title="How can I see the product odds?">
				You can see the probability of unboxing specific items in your chosen WinBox by checking the percentage
				displayed above each product image. Additionally, you can review all outcomes from your opened WinBoxes in your
				unboxing history. Our advanced algorithm calculates these odds, which you can learn more about in our 'Provably
				Fair' section.
			</Accordion>
		</div>
	);
}
