import axios from 'axios';
import { RecommendedContainerInterface } from 'interfaces/RecommendedContainerInterfaces';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';

import { API_DOMAIN, QUERY_KEYS } from '../../../constants';

interface ReturnType {
	total: number;
	limit: number;
	containers: RecommendedContainerInterface[];
}

async function loadRecommendedContainer(limit: number = 10, exclude: string[] = []): Promise<ReturnType> {
	try {
		const response = await axios.get(
			`${API_DOMAIN.mrlootBackend}/api/box-recommendations/box-container/load-recommendations`,
			{
				params: {
					limit,
					exclude,
				},
				withCredentials: true,
			}
		);

		if (response.data.status === 'success') {
			return response.data.data;
		}
		throw new Error('Failed to load data');
	} catch (err) {
		throw err;
	}
}

interface Props {
	limit?: number;
}

// eslint-disable-next-line no-magic-numbers
const FIVE_MINUTES = 5 * 60 * 1000;

export function useRecommendationContainerLoader({ limit = 5 }: Props) {
	const [data, setData] = useState<RecommendedContainerInterface[]>([]);
	const total = useRef(0);
	const [excludeIds, setExcludeIds] = useState<string[]>([]);
	const hasNextPage = data.length < total.current;
	const hasLoadedAll = useRef(false);

	const { isLoading, data: newPageData } = useQuery<ReturnType, Error>(
		[QUERY_KEYS.boxContainer, excludeIds, limit],
		() => loadRecommendedContainer(limit, excludeIds),
		{
			staleTime: FIVE_MINUTES,
			cacheTime: FIVE_MINUTES,
		}
	);

	const loadMore = useCallback(async () => {
		if (data && hasNextPage && !isLoading) {
			hasLoadedAll.current = false;
			setExcludeIds(data.map((el) => el._id)); // excluding already fetched data and triggering new fetch
		}
	}, [data, hasNextPage, isLoading]);

	useEffect(() => {
		if (!hasLoadedAll.current && newPageData && newPageData.containers.length > 0) {
			hasLoadedAll.current = true;
			setData((prev) => [...prev, ...newPageData.containers]);
			total.current = newPageData.total;
		}
	}, [newPageData]);

	return { data, isLoading, loadMore, hasNextPage };
}
