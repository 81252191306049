import { faForward, faRotateRight, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PrimaryBtn } from 'components/common/buttons/PrimaryBtn';
import { useBoxOpeningStoreMobile } from 'pages/OpenBox/store/useBoxOpeningStoreMobile';

interface Props {
	isOptionsOverlayVisible: boolean;
	onToogle: () => void;
}

export const OptionsOverlayToggleBtn = ({ isOptionsOverlayVisible, onToogle }: Props) => {
	const { isAutoSpin } = useBoxOpeningStoreMobile();

	return (
		<PrimaryBtn
			renderEllipse={!isOptionsOverlayVisible}
			onClick={onToogle}
			style={{
				transition: 'width 0.1s ease-out',
				width: isOptionsOverlayVisible ? '70px' : '145px',
				height: '54px',
				zIndex: 10,
				background: !isOptionsOverlayVisible
					? 'linear-gradient(331.41deg, #236DF4 6.78%, #33F0A7 204.87%)'
					: 'linear-gradient(331.41deg, rgba(83, 83, 83, 1) 6.78%, rgba(70, 70, 70, 1) 204.87%)',
			}}
			innerContentStyle={{ borderColor: isOptionsOverlayVisible ? 'transparent' : undefined }}
			content={
				<>
					{!isOptionsOverlayVisible && (
						<div className="flex items-center w-full justify-between">
							<FontAwesomeIcon icon={faForward} className="text-[12px] mt-auto text-[rgba(255,255,255,0.9)]" />
							<span className="font-semibold text-[18px] text-white">Options</span>
							<FontAwesomeIcon
								icon={faRotateRight}
								className="-rotate-45 text-[12px] mb-auto text-[rgba(255,255,255,0.9)]"
							/>
						</div>
					)}
					{isOptionsOverlayVisible && <FontAwesomeIcon icon={faXmark} className="text-whie" />}
				</>
			}
		/>
	);
};
