import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useOutsideClick, useScrollLock, useSlideUpAndDragToDismiss } from 'hooks';
import { useCallback, useRef } from 'react';

import { DropdownOption } from './Dropdowns/MobileDropdown';

interface Props {
	heading: string;
	currentOption: DropdownOption;
	options?: DropdownOption[];
	onChange: (option: DropdownOption) => void;
	onClose: () => void;
}

export function MobileDropdownOverlay({ heading, currentOption, options, onClose, onChange }: Props) {
	const overlayRef = useRef<HTMLDivElement>(null);

	useScrollLock();

	useOutsideClick(overlayRef, () => {
		playDismissAnimation();
	});

	const { playDismissAnimation } = useSlideUpAndDragToDismiss({
		ref: overlayRef,
		animationTime: 0.4,
		onDismiss: useCallback(() => onClose(), [onClose]),
	});

	function handleOptionClick(option: DropdownOption) {
		onChange(option);
		playDismissAnimation();
	}

	return (
		<div className="fixed inset-0 z-50 bg-[rgba(0,0,0,0.35)] flex items-end">
			<div ref={overlayRef} className="fixed z-0 w-screen h-[300px] left-0 right-0 bottom-0 flex flex-col">
				<div className="py-[5px] w-full flex items-center justify-center">
					<div className="w-[80px] h-[5px] rounded-full bg-white shadow-[4px_4px_4px_rgba(0,0,0,0.35)]" />
				</div>

				<div className="w-screen h-full bg-mobileBackground rounded-[12px_12px_0_0] flex flex-col space-y-[30px] pt-[20px]">
					<h1 className="text-[22px] text-white font-bold px-mobile-side-padding">{heading}</h1>

					<div className="flex flex-col justify-between flex-grow pb-[15px] max-full overflow-y-auto">
						{options?.map((option, index) => (
							<button
								key={index}
								onClick={() => handleOptionClick(option)}
								className="z-10 flex items-center justify-between w-full text-white py-[10px] px-mobile-side-padding"
							>
								<div className="flex items-center space-x-[30px]">
									{option.icon && <option.icon />}
									<span className="text-[14px] font-bold">{option.label}</span>
								</div>
								{option.value === currentOption.value && <FontAwesomeIcon icon={faCheck} className="" />}
							</button>
						))}
					</div>
				</div>
			</div>

			<div className="absolute z-10 bottom-0 left-0 right-0 w-full h-[200px] bg-mobileBackground translate-y-[100%]" />
		</div>
	);
}
