import { useOutsideClick, useScrollLock } from 'hooks';
import { ReactNode, useRef } from 'react';

interface Props {
	children: ReactNode;
	onClose?: () => void;
	shouldCenter?: boolean;
	isDesktop?: boolean; // to move it from left and top depending on topbar and sidebar width
}

export function WithPopup({ children, onClose, shouldCenter, isDesktop }: Props) {
	const childrenWrapperRef = useRef<HTMLDivElement>(null);

	useOutsideClick(childrenWrapperRef, onClose);
	const parentRef = useRef<HTMLDivElement>(null);

	useScrollLock();

	return (
		<div
			ref={parentRef}
			className={`${shouldCenter && 'flex items-center justify-center'} ${isDesktop ? 'pl-desktop-sidebar-width pt-desktop-topbar-width' : ''} fixed inset-0 h-dvh z-50 bg-[rgba(0,0,0,0.35)]`}
		>
			<div ref={childrenWrapperRef} className="relative w-fit h-fit ">
				{children}
			</div>
		</div>
	);
}
