import { Skeleton } from 'components/common';

interface Props {
	label?: string;
	isLoading?: boolean;
}

export function CarouselLabel({ label, isLoading }: Props) {
	return (
		<div className="ml-[40px] absolute top-0 z-0 flex items-center mb-[2px]">
			{label && <span className="text-white text-[18px] font-semibold">{label}</span>}
			{!label && isLoading && <Skeleton style={{ width: '140px', height: '26px' }} />}
		</div>
	);
}
