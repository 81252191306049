import { Ellipse } from 'assets/images';
import { CSSProperties, forwardRef, ReactNode } from 'react';

interface Props {
	renderEllipse?: boolean;
	content: ReactNode;
	onClick?: () => void;
	isDisabled?: boolean;
	type?: 'submit' | 'button' | 'reset';
	style?: CSSProperties;
	onMouseEnter?: () => void;
	onMouseLeave?: () => void;
	innerContentStyle?: CSSProperties;
}

export const PrimaryBtn = forwardRef<HTMLButtonElement, Props>(
	(
		{
			content,
			onClick,
			style,
			isDisabled,
			renderEllipse = true,
			innerContentStyle,
			type = 'button',
			onMouseEnter,
			onMouseLeave,
			...rest // Capture any additional props
		},
		ref
	) => {
		function handleMouseEnter() {
			onMouseEnter && onMouseEnter();
		}

		function handleMouseLeave() {
			onMouseLeave && onMouseLeave();
		}

		return (
			<button
				ref={ref}
				type={type}
				onMouseLeave={handleMouseLeave}
				onMouseEnter={handleMouseEnter}
				onClick={onClick}
				disabled={isDisabled}
				className={`${isDisabled ? 'opacity-50' : ''} w-[100%] bg-primary-gradient flex items-center justify-center px-[12px] relative rounded-[20px] border-[2px] border-darkBlue outline outline-[3px] outline-white h-[49px] text-white font-bold text-[19px] overflow-hidden focus-within:shadow-primaryInset`}
				style={{ ...style, ...innerContentStyle }}
				{...rest}
			>
				{content}
				{renderEllipse && (
					<img src={Ellipse} className="absolute left-[min(9px,5.5%)] top-[min(3px,4.5%)] object-scale-down" alt="" />
				)}
			</button>
		);
	}
);
