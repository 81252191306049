import { LoadSpinner } from 'components/common/loading/LoadSpinner';
import { useUserContext } from 'hooks';
import { usePaymentTransactions } from 'hooks/data/transactions/usePaymentTransactions';
import { useGTMDepositTracker } from 'hooks/userInteractions/useGTMDepositRecorder';
import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';

import { QUERY_KEYS } from '../../constants';

export function SuccessfulDeposit() {
	const navigate = useNavigate();
	const { load: reloadUser } = useUserContext();

	const { data: transactionData, isLoading: arePaymentTransactionsLoading } = usePaymentTransactions();

	const queryClient = useQueryClient();

	const trackDeposit = useGTMDepositTracker();

	useEffect(() => {
		// Function to push data to Data Layer
		const pushToDataLayer = async () => {
			if (transactionData && transactionData.length > 0 && !arePaymentTransactionsLoading) {
				// Extract necessary data for Snap Pixel
				const transaction = transactionData[0];

				trackDeposit({
					depositId: transaction.paymentId,
					currency: transaction.payinCurrency,
					depositAmount: transaction.originalCurrencyAmount,
					paymentMethod: transaction.paymentMethod,
				});

				// Invalidate React Query data
				queryClient.invalidateQueries(QUERY_KEYS.cryptoPayment);
				queryClient.invalidateQueries(QUERY_KEYS.paymentTransaction);

				// Reload user info
				reloadUser();

				// Navigate to the next page
				navigate('/?depositSuccess=true');
			}
		};

		// Call the function
		pushToDataLayer();
	}, [transactionData, arePaymentTransactionsLoading, queryClient, reloadUser, navigate, trackDeposit]);

	return (
		<div className="h-svh w-full flex items-center justify-center">
			<LoadSpinner />
		</div>
	);
}
