import axios from 'axios';
import { UserInterface } from 'interfaces/UserInterface';
import { createContext, PropsWithChildren, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useBalanceStore } from 'store/useBalanceStore';
import { shallow } from 'zustand/shallow';

import { API_DOMAIN, QUERY_CACHE_CONFIG, QUERY_KEYS } from '../constants';

interface UserContextProps {
	user: UserInterface | undefined;
	load: () => void;
	isLoading: boolean;
}

export const myUserContext = createContext<UserContextProps>({
	user: undefined,
	load: () => undefined,
	isLoading: true,
});

async function getUser() {
	try {
		const response = await axios.get(`${API_DOMAIN.mrlootBackend}/api/user`, {
			withCredentials: true,
		});
		return response.data;
	} catch (err) {
		throw err;
	}
}

export const UserContext: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
	const {
		data: user,
		refetch: load,
		isLoading,
	} = useQuery<UserInterface>({
		queryKey: QUERY_KEYS.userData,
		queryFn: getUser,
		cacheTime: QUERY_CACHE_CONFIG.longTerm.cacheTime,
		staleTime: QUERY_CACHE_CONFIG.longTerm.staleTime,
	});

	const { resetBalance } = useBalanceStore((state) => ({ resetBalance: state.resetBalance }), shallow);

	useEffect(() => {
		if (user) {
			resetBalance(); // reset UI optimistic balance
		}
	}, [resetBalance, user]);

	return <myUserContext.Provider value={{ user, load, isLoading }}>{children}</myUserContext.Provider>;
};
