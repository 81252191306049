import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PrimaryBtn } from 'components/common/buttons/PrimaryBtn';
import { AddAddressPopup } from 'components/common/popups/address-popup/AddAddressPopup';
import { EditAddressPopup } from 'components/common/popups/address-popup/EditAddressPopup';
import { useUserContext } from 'hooks';
import { ShippingAddressInterface } from 'interfaces/OrderInterfaces';
import { useState } from 'react';

import { Breadcrumb } from '../components/Breadcrumb';
import { AddressContainer } from './AddressContainer';

export function ManageAddresses() {
	const { user } = useUserContext();

	const [showAddressPopup, setShowAddressPopup] = useState(false);
	const [editAddressPopup, setEditAddressPopup] = useState<ShippingAddressInterface | undefined>();

	return (
		<>
			{showAddressPopup && <AddAddressPopup onClose={() => setShowAddressPopup(false)} />}
			{editAddressPopup && (
				<EditAddressPopup address={editAddressPopup} onClose={() => setEditAddressPopup(undefined)} />
			)}

			<div className="flex flex-col gap-y-[20px]">
				<Breadcrumb
					items={[{ label: 'Account', link: '/my-account/edit-profile' }, { label: 'Manage Shipping Addresses' }]}
				/>

				<div className="flex flex-col gap-y-[12px] items-center w-[350px]">
					{user?.addresses?.map((address) => (
						<AddressContainer key={address._id} address={address} onEdit={() => setEditAddressPopup(address)} />
					))}

					<PrimaryBtn
						onClick={() => setShowAddressPopup(true)}
						renderEllipse={false}
						style={{ borderRadius: '100%', width: '40px', height: '40px' }}
						innerContentStyle={{ borderRadius: '100%' }}
						content={<FontAwesomeIcon icon={faPlus} className="text-[15px]" />}
					/>
				</div>
			</div>
		</>
	);
}
