import axios from 'axios';
import { useMutation } from 'react-query';

import { API_DOMAIN } from '../../../constants';

async function deleteAddressFromUser(addressId?: string) {
	if (!addressId) {
		return;
	}
	try {
		const response = await axios.post(
			`${API_DOMAIN.mrlootBackend}/api/user/delete-address`,
			{ addressId },
			{ withCredentials: true }
		);
		if (response.data.status === 'success') {
			return response.data.data;
		}
		throw new Error('Could not add Address');
	} catch (err) {
		throw err;
	}
}

interface Props {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onSuccess?: () => void;
	onError?: (errMessage: string) => void;
}

export function useDeleteUserAddress({ onSuccess, onError }: Props = {}) {
	return useMutation((addressId?: string) => deleteAddressFromUser(addressId), {
		onSuccess: () => onSuccess && onSuccess(),
		onError: (err: Error) => onError && onError(err.message || 'Something went wrong'),
	});
}
