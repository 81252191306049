/* eslint-disable complexity */
import { faRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useOutsideClick } from 'hooks';
import { useBoxOpeningStoreDesktop } from 'pages/OpenBox/store/useBoxOpeningStoreDesktop';
import { useRef, useState } from 'react';

const SELECTIONS = [
	{ value: 1, label: '1x' },
	{ value: 3, label: '3x' },
	{ value: 5, label: '5x' },
	{ value: 10, label: '10x' },
	{ value: Infinity, label: 'Endless' },
];

export function AutoPlayDropdown() {
	const { autoSpinCount, setAutoSpinCount, isAutoSpin, isFullScreen } = useBoxOpeningStoreDesktop();

	const [isExpanded, setIsExpanded] = useState(false);
	const dropdownRef = useRef<HTMLDivElement>(null);

	useOutsideClick(dropdownRef, () => {
		setIsExpanded(false);
	});

	function handleSelection(value: number) {
		setAutoSpinCount(value);
		setIsExpanded(false);
	}

	function renderDropdownItem(item: (typeof SELECTIONS)[0]) {
		return (
			<div
				key={item.value}
				style={{
					background: isFullScreen
						? 'linear-gradient(331.41deg, rgba(35, 109, 244, 0.6) 6.78%, rgba(51, 240, 167, 0.6) 204.87%)'
						: 'linear-gradient(331.41deg, rgba(35, 109, 244, 0.3) 6.78%, rgba(51, 240, 167, 0.3) 204.87%)',
				}}
			>
				<button
					onClick={() => handleSelection(item.value)}
					className={`${!isExpanded && 'opacity-0 pointer-events-none'} w-full ${isFullScreen ? 'hover:bg-[rgba(0,0,0,0.1)]' : 'hover:bg-[rgba(255,255,255,0.1)]'}  py-2`}
				>
					<span>{item.label}</span>
				</button>
			</div>
		);
	}

	function handleBtnClick() {
		setIsExpanded((prev) => !prev);
	}

	return (
		<div className={`w-[135px] relative z-10 font-semibold text-[14px] text-white`} ref={dropdownRef}>
			<button
				onClick={handleBtnClick}
				style={{
					background: isFullScreen
						? 'linear-gradient(331.41deg, rgba(35, 109, 244, 0.6) 6.78%, rgba(51, 240, 167, 0.6) 204.87%)'
						: 'linear-gradient(331.41deg, rgba(35, 109, 244, 0.3) 6.78%, rgba(51, 240, 167, 0.3) 204.87%)',
				}}
				className={`border-[2px] flex items-center justify-center w-full h-full
             ${isExpanded ? 'rounded-[0_0_20px_20px] ' : 'rounded-[20px] '}
             ${isExpanded ? 'border-x-white border-t-transparent border-b-white' : autoSpinCount ? 'border-white' : 'border-border-white'}`}
			>
				<div
					className={`flex border-[2px] px-[12px] ${isExpanded ? 'border-x-[#3559D7] border-t-transparent border-b-[#3559D7] rounded-[0_0_17px_17px]' : 'border-[#3559D7] rounded-[17px]'} items-center justify-center gap-x-[8px] h-full w-full`}
				>
					{autoSpinCount > 1 || isAutoSpin ? (
						<span className="font-bold text-white text-nowrap">
							Playing {autoSpinCount === Infinity ? 'Endless' : autoSpinCount + 'x'}
						</span>
					) : (
						<>
							<FontAwesomeIcon
								icon={faRotateLeft}
								className={`${isExpanded && 'rotate-90'}`}
								style={{ transition: 'all 0.3s' }}
							/>
							<span className="text-nowrap">Auto Open</span>
						</>
					)}
				</div>
			</button>

			<div
				className={`${isExpanded ? 'h-[187px]' : 'h-0 opacity-0'} ${!isFullScreen && 'bg-background'}  w-full border-t-[2px] border-x-[2px] overflow-hidden absolute z-10 left-0 top-[5px] translate-y-[-100%] rounded-[20px_20px_0_0]`}
				style={{
					transition: 'height 0.3s',
				}}
			>
				<div className="border-t-[2px] border-[#3559D7] border-x-[2px] flex flex-col justify-end rounded-[17px_17px_0_0] overflow-hidden">
					{SELECTIONS.map(renderDropdownItem)}
				</div>
			</div>
		</div>
	);
}
