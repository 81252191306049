import { faRotate } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PrimaryBtn } from 'components/common/buttons/PrimaryBtn';

interface Props {
	value?: string;
	onUpdate: () => void;
	isDisabled: boolean;
	isLoading: boolean;
}

export function ServerSeedInput({ value, onUpdate, isDisabled, isLoading }: Props) {
	return (
		<div className="flex flex-col space-y-1">
			<span className="text-white text-[14px] xl:text-[16px] xxl:text-[19px]">Active Hashed Server Seed:</span>
			<div
				className={`${isDisabled && 'opacity-50 pointer-events-none'} h-[45px] xl:h-[50px] xxl:h-[58px] border-white border-[2px] rounded-[20px]`}
			>
				<div
					className="h-full w-full relative flex items-center border-[2px] border-darkBlue rounded-[18px]"
					style={{
						background: ' linear-gradient(331.41deg, rgba(35, 109, 244, 0.7) 6.78%, rgba(51, 240, 167, 0.7) 204.87%)',
					}}
				>
					<input
						placeholder="Please login to generate a value"
						disabled={true}
						value={value}
						type="text"
						className={`text-white bg-transparent font-semibold text-[15px] opacity-70 xxl:text-[19px] pl-3 pr-[52px] h-full w-full rounded-[20px] focus-visible:outline-none focus-visible:border-[#767778]`}
					/>
					<div className="absolute right-[-4px] top-[50%] translate-y-[-50%] h-[calc(100%+6px)]">
						<PrimaryBtn
							onClick={onUpdate}
							renderEllipse={false}
							style={{ height: '100%', width: 'auto', borderWidth: '2px' }}
							content={<FontAwesomeIcon icon={faRotate} className={`${isLoading ? 'animate-spin' : 'none'} `} />}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
