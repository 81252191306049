import { ItemInterface, ItemOptionProperty } from 'interfaces/ItemInterfaces';

export enum FilterOptions {
	NEW_ARRIVALS = 'NEW ARRIVALS',
	TSHIRT = 'TSHIRT',
	HOODIES = 'HOODIES',
	ACCESSOIRES = 'ACCESSOIRES',
}

export const FilterOptionTags: Record<FilterOptions, string[]> = {
	[FilterOptions.NEW_ARRIVALS]: ['lootie-merch'],
	[FilterOptions.TSHIRT]: ['lootie-merch', 't-shirt'],
	[FilterOptions.HOODIES]: ['lootie-merch', 'hoodies'],
	[FilterOptions.ACCESSOIRES]: ['lootie-merch', 'accessoires'],
};

export interface BasketItemOption {
	label: string;
	optionProperty: ItemOptionProperty;
	value: string;
}

export interface BasketProduct {
	_id?: string;
	item: ItemInterface;
	quantity: number;
	options: BasketItemOption[];
}

export interface BasektProductWithId extends BasketProduct {
	_id: string;
}
