import React, { useEffect, useState } from 'react';

interface Props {
	expirationDate: Date; // The target date for countdown
	formatTime?: (seconds: number) => string; // Custom function to format the time
	style?: React.CSSProperties; // Optional custom styles
}

const ONE_MIN_IN_SEC = 60;

export function Counter({ expirationDate, formatTime, style }: Props) {
	const [timeLeft, setTimeLeft] = useState<number>(
		Math.max(0, Math.floor((expirationDate.getTime() - Date.now()) / 1000))
	);

	// Update the countdown every second
	useEffect(() => {
		const timer = setInterval(() => {
			const remainingTime = Math.max(0, Math.floor((expirationDate.getTime() - Date.now()) / 1000));
			setTimeLeft(remainingTime);

			if (remainingTime <= 0) {
				clearInterval(timer); // Stop the timer when it reaches 0
			}
		}, 1000);

		return () => clearInterval(timer); // Cleanup on unmount
	}, [expirationDate]);

	// Default formatting function (minutes:seconds)
	const defaultFormatTime = (seconds: number) => {
		const minutes = Math.floor(seconds / ONE_MIN_IN_SEC);
		const remainingSeconds = seconds % ONE_MIN_IN_SEC;
		return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
	};

	// Use provided `formatTime` or fallback to `defaultFormatTime`
	const formattedTime = (formatTime || defaultFormatTime)(timeLeft);

	// Dynamically set gridTemplateColumns based on the formatted time length
	const dynamicStyle: React.CSSProperties = {
		gridTemplateColumns: `repeat(${formattedTime.length}, 1ch)`,
		...style,
	};

	return (
		<span
			className="text-[20px] font-bold text-white text-center grid items-center justify-center"
			style={dynamicStyle}
		>
			{formattedTime.split('').map((char, index) => (
				<span key={index} className="w-[1ch] flex justify-center font-inter">
					{char}
				</span>
			))}
		</span>
	);
}
