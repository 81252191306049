import { DeliveryTruck, FontThanksForOrdering } from 'assets/images';
import { Link } from 'react-router-dom';

export function ThankYouForOrdering() {
	return (
		<div className="flex-grow flex flex-col items-center space-y-[35px] pt-[40px] border-white01 text-white">
			<img src={DeliveryTruck} className="h-[90px]" alt="Delivery Truck" />
			<img alt="Thank You For Ordering" src={FontThanksForOrdering} className="h-[65px] object-scale-down max-w-full" />
			<h3 className="text-white font-semibold text-[20px] text-center">
				Your order is being processed now. You can view it under
				<Link to="/my-account/my-orders" className="text-mrLootBlue ml-[7px] mr-[3px] hover:underline text-center">
					“My Orders”
				</Link>
				.
			</h3>
		</div>
	);
}
