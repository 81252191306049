import { AmountDisplayWithCurrency } from 'components/common/AmountDisplayWithCurrency';
import { ToolTip } from 'components/common/ToolTip';
import { OrderDbItemInterface } from 'interfaces/OrderInterfaces';

import { CLOUDFRONT_IMG_URL, rarities } from '../../../../constants';
import { isOrderItemRefunded, refundLabel } from '../../orderUtils';
import { ItemOptionsDisplay } from './ItemOptionsDisplay';

interface Props {
	item: OrderDbItemInterface;
}

export function OrderItemDetails({ item }: Props) {
	return (
		<div
			className={`flex flex-col gap-y-[10px] items-center justify-center px-[18px] py-[6px] rounded-[20px] min-h-[120px] w-full border-[1px] border-white ${isOrderItemRefunded(item.status) && 'border-opacity-50'}`}
		>
			{isOrderItemRefunded(item.status) && (
				<div className="flex items-center gap-x-[6px] mr-auto text-white">
					<ToolTip message="Item has been cancelled for this order" />
					<h1 className="text-white font-semibold text-[14px]">{refundLabel(item.status)}</h1>
				</div>
			)}

			<div
				className={`${isOrderItemRefunded(item.status) && 'opacity-50'} grid grid-cols-[75px_auto] md:grid-cols-[90px_auto] items-center justify-start w-full gap-x-[10px] md:gap-x-[25px] lg:gap-x-[25px] text-white text-[13px]`}
			>
				<div className="relative w-fit h-fit mx-auto">
					<img
						src={`${CLOUDFRONT_IMG_URL}/${item.itemId?.imageUrls.qualityMedium}`}
						alt={item.itemId?.name}
						className="h-[40px] md:h-[55px] w-auto object-scale-down max-w-[60px] md:max-w-[75px] relative z-10"
					/>
					<div
						className="absolute z-0 left-[50%] translate-x-[-50%] top-[50%] translate-y-[-50%] h-[50px] w-[50px] blur-[25px] rounded-full"
						style={{ backgroundColor: rarities[item.itemId?.rarity]?.color }}
					/>
				</div>
				<div className="flex flex-col items-start gap-y-[5px]">
					<div className="flex items-center gap-x-[4px] text-white min-w-fit">
						<AmountDisplayWithCurrency
							amount={(item.userAvgPurchaseValue ?? item.itemId.price) * item.quantity}
							imgStyle={{ height: '13px' }}
							amountStyle={{ fontSize: '13px' }}
						/>
					</div>

					<h2 className="text-white font-semibold line-clamp-1">{item.itemId?.name}</h2>
					<span className="flex items-center justify-center font-semibold h-[18px] w-[27px] rounded-full bg-white text-mrLootBlue text-[12px]">
						x{item.quantity}
					</span>

					{item.options && item.options.length > 0 && <ItemOptionsDisplay options={item.options} />}
				</div>
			</div>
		</div>
	);
}
