import { ItemInterface, ItemRarity } from 'interfaces/ItemInterfaces';

function getTags(item: ItemInterface, isExpressShipping: boolean) {
	const tags: string[] = [];
	tags.push('International Shipping *');
	if (item.deliveryTime) {
		tags.push(isExpressShipping ? item.deliveryTime.EXPRESS : item.deliveryTime.STANDARD);
	} else if (item.rarity === ItemRarity.GOLD) {
		tags.push('Custom Delivery');
	} else {
		tags.push(isExpressShipping ? '3-7 Days Delivery' : '7-14 Days Delivery');
	}

	return tags;
}

interface Props {
	item: ItemInterface;
	isExpressShipping: boolean;
}

export function ProductTags({ isExpressShipping, item }: Props) {
	const tags = item && getTags(item, isExpressShipping);

	return (
		<div className="flex flex-wrap mt-[8px]">
			{tags.map((el, index) => (
				<span
					key={index}
					className="text-[#6C6F6C] h-fit first:mr-[5px] first:mb-[6px] line-clamp-1 text-[9px] ss:text-[11px] font-semibold bg-[#F6F6F6] px-3 rounded-[4px] flex items-center justify-center"
				>
					{el}
				</span>
			))}
		</div>
	);
}
