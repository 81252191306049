import axios from 'axios';
import { ShippingAddressInterface } from 'interfaces/OrderInterfaces';
import { useMutation } from 'react-query';

import { API_DOMAIN } from '../../../constants';

async function updateAddressForUser(addressId: string, address: ShippingAddressInterface) {
	try {
		const response = await axios.post(
			`${API_DOMAIN.mrlootBackend}/api/user/update-address`,
			{ addressId, address: address },
			{ withCredentials: true }
		);
		if (response.data.status === 'success') {
			return response.data.data;
		}
		throw new Error('Could not add Address');
	} catch (err) {
		throw err;
	}
}

interface Props {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onUpdate?: (data: ShippingAddressInterface) => void;
	onError?: (errMessage: string) => void;
}

interface MutationProps {
	addressId: string;
	address: ShippingAddressInterface;
}

export function useUpdateUserAddress({ onUpdate, onError }: Props = {}) {
	return useMutation(({ address, addressId }: MutationProps) => updateAddressForUser(addressId, address), {
		onSuccess: (data) => onUpdate && onUpdate(data),
		onError: (err: Error) => onError && onError(err.message || 'Something went wrong'),
	});
}
