import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SecondaryBtn } from 'components/common/buttons/SecondaryBtn';
import { useOutsideClick, useWindowDimensions } from 'hooks';
import { PopulatedBoxInterface } from 'interfaces/BoxInterface';
import { useRef, useState } from 'react';

import { screens } from '../../../../../../constants';
import { BoxInfoPopup } from '../../../../desktop/components/Settingsbar/BoxInfoPopup';

interface Props {
	box?: PopulatedBoxInterface;
}

export function BoxInfoBtn({ box }: Props) {
	const [showBoxInfo, setShowBoxInfo] = useState(false);
	const parentRef = useRef(null);

	function handleClick() {
		setShowBoxInfo((prev) => !prev);
	}

	useOutsideClick(parentRef, () => setShowBoxInfo(false));

	const { screenWidth } = useWindowDimensions();

	return (
		<div ref={parentRef} className="relative">
			<SecondaryBtn
				onClick={handleClick}
				style={{ height: '100%', width: 'fit-content', borderWidth: '2px' }}
				content={
					<div className="flex items-center gap-x-[8px] text-[14px]">
						<FontAwesomeIcon icon={faCircleInfo} />
						{screenWidth >= screens.md.width && <span>Box Info</span>}
					</div>
				}
			/>

			{showBoxInfo && (
				<BoxInfoPopup description={box?.description} imageName={box?.portraitImageUrls?.qualityHigh} name={box?.name} />
			)}
		</div>
	);
}
