import { useEffect, useRef, useState } from 'react';

interface DynamicTextSizeOptions {
	maxFontSize?: number;
	minFontSize?: number;
	maxLineHeight?: number;
	minLineHeight?: number;
}

const LINE_HEIGHT_FACTOR = 1.2;

export function useDynamicTextSize(text: string, options: DynamicTextSizeOptions = {}) {
	const { maxFontSize = 48, minFontSize = 25, maxLineHeight = 60, minLineHeight = 30 } = options;

	const containerRef = useRef<HTMLDivElement>(null);
	const [fontSize, setFontSize] = useState(maxFontSize);
	const [lineHeight, setLineHeight] = useState(maxLineHeight);

	useEffect(() => {
		const adjustTextSize = () => {
			if (containerRef.current) {
				const { offsetWidth } = containerRef.current;
				const textLength = text.length;

				// Calculate font size based on container width and text length
				const dynamicFontSize = Math.max(minFontSize, Math.min(maxFontSize, offsetWidth / textLength));
				const dynamicLineHeight = Math.max(
					minLineHeight,
					Math.min(maxLineHeight, dynamicFontSize * LINE_HEIGHT_FACTOR)
				);

				setFontSize(dynamicFontSize);
				setLineHeight(dynamicLineHeight);
			}
		};

		adjustTextSize();
		window.addEventListener('resize', adjustTextSize);
		return () => {
			window.removeEventListener('resize', adjustTextSize);
		};
	}, [text, maxFontSize, minFontSize, maxLineHeight, minLineHeight]);

	return {
		containerRef,
		fontSize,
		lineHeight,
	};
}
