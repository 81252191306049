import axios from 'axios';
import { useUserContext } from 'hooks';
import { DBUserInventoryBoxWithBoxObj } from 'interfaces/InventoryInterface';
import { useCallback, useMemo } from 'react';
import { useInfiniteQuery } from 'react-query';

import { API_DOMAIN, QUERY_CACHE_CONFIG, QUERY_KEYS } from '../../../constants';
import { InventoryOrderOptions } from '../components/InterfacesUI';

async function fetchInventoryBoxes({ pageParam = 0 }, limit: number, order: InventoryOrderOptions, query?: string) {
	try {
		const response = await axios.post(
			`${API_DOMAIN.mrlootBackend}/api/user/inventory/load-partial-boxes`,
			{
				page: pageParam,
				n: limit,
				order: order,
				query,
			},
			{ withCredentials: true }
		);
		if (response.data.status === 'success') {
			return response.data;
		}
	} catch (err) {
		console.error(err);
		throw err;
	}
}

interface InventoryLoadResultInterface {
	data: DBUserInventoryBoxWithBoxObj[];
	pagination: {
		page: number;
		totalPages: number;
	};
}

interface Props {
	isDisabled?: boolean;
	query?: string;
	limit?: number;
	order?: InventoryOrderOptions;
}

export function useInventoryBoxes({ limit = 20, order = InventoryOrderOptions.NEWEST, query, isDisabled }: Props = {}) {
	const { user } = useUserContext();

	const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } = useInfiniteQuery<
		InventoryLoadResultInterface,
		Error
	>([QUERY_KEYS.inventoryBoxData, limit, order, query], (params) => fetchInventoryBoxes(params, limit, order, query), {
		keepPreviousData: true,
		getNextPageParam: (lastPage) => {
			const nextPage = lastPage.pagination.page + 1;
			return nextPage <= lastPage.pagination.totalPages ? nextPage : undefined;
		},
		cacheTime: QUERY_CACHE_CONFIG.shortTerm.cacheTime,
		staleTime: QUERY_CACHE_CONFIG.shortTerm.staleTime,
		enabled: !!user && !isDisabled && limit > 0,
	});

	const loadMore = useCallback(() => {
		if (hasNextPage) {
			fetchNextPage();
		}
	}, [fetchNextPage, hasNextPage]);

	const boxes = useMemo(() => (data?.pages ? data.pages.flatMap((page) => page.data) : undefined), [data]);

	return { data: boxes, isLoading, loadMore, isFetchingNextPage, hasNextPage };
}
