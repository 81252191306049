import { CheckBox } from 'components';

import { ExpressShippingTruckAnimation } from './ExpressShippingTruckAnimation';
import { ToolTipExpressShipping } from './ToolTipExpressShipping';

interface Props {
	isExpressShipping: boolean;
	onIsExpressShippingChange: () => void;
}

/*<div className="w-full border-[3px] rounded-[24px] border-white01 py-[15px] px-[20px] flex flex-col space-y-[4px]">
    <div className="flex w-full justify-left items-center space-x-[12px]">
        <CheckBox checked={isExpressShipping} onClick={onIsExpressShippingChange} borderColor="rgba(255,255,255,0.2)" borderWidth="2px" backgroundColor="white" borderRadius="4px" strokeColor="#1A1C1E" size={{height: '19px', width: '19px'}} />
        <span className="text-white text-[15px] font-semibold">Express Shipping</span>
    </div>
    <p className="text-[#797979] text-[11px] relative">Additional fees will apply</p>
</div>*/

export function ExpressShippingSidebar({ isExpressShipping, onIsExpressShippingChange }: Props) {
	return (
		<div
			className={`relative w-full flex flex-col justify-between h-[115px] pb-[5px] border-[3px] rounded-[24px] ${isExpressShipping ? 'border-white' : 'border-white01'} bg-mrLootBlue`}
		>
			<div className="pt-[15px] pl-[15px] grid items-center grid-cols-[20px_auto] w-full space-x-[12px]">
				<CheckBox
					checked={isExpressShipping}
					onClick={onIsExpressShippingChange}
					borderColor="transparent"
					borderWidth="2px"
					backgroundColor="white"
					borderRadius="4px"
					strokeColor="rgb(41 129 233)"
					size={{ height: '19px', width: '19px' }}
				/>
				<div className="flex flex-col">
					<div className="text-white text-[17px] font-semibold flex items-center space-x-[3px]">
						<h2 className="">Express Shipping</h2>
						<ToolTipExpressShipping />
					</div>
				</div>
				<p className="text-white text-[10px] relative col-start-2">Additional fees will apply</p>
			</div>

			<ExpressShippingTruckAnimation isExpressShipping={isExpressShipping} />
		</div>
	);
}
