import { AmountDisplayWithCurrency } from 'components/common/AmountDisplayWithCurrency';
import { PrimaryBtn } from 'components/common/buttons/PrimaryBtn';
import { PrimaryContainer } from 'components/PrimaryContainer';
import { useWindowDimensions } from 'hooks';
import { usePaymentTransactions } from 'hooks/data/transactions/usePaymentTransactions';
import { useFormContext } from 'react-hook-form';
import { useAppStore } from 'store/useAppStore';
import { displayUsdAmountInCurrency } from 'utils';
import { shallow } from 'zustand/shallow';

import { screens } from '../../../../../constants';
import { LabelBadge } from './LabelBadge';

interface AmountOptionCardProps {
	option: {
		amount: number;
		amountImg: string;
		bonus: number;
		image: string;
		mainImgHeightSmall: string;
		mainImgHeightBig: string;
		labelImg?: string;
		labelStyle?: React.CSSProperties;
	};
}

export function AmountOptionCard({ option }: AmountOptionCardProps) {
	const { setValue } = useFormContext<{ amount: number }>();

	const { screenWidth } = useWindowDimensions();
	const selectedCurrency = useAppStore((state) => state.selectedCurrency, shallow);

	const mainImgHeight = screenWidth > screens.mds.width ? option.mainImgHeightBig : option.mainImgHeightSmall;

	const { data: transactions, isLoading: areTransactionsLoading } = usePaymentTransactions();

	const showBonus = !areTransactionsLoading && (!transactions || transactions.length === 0);

	return (
		<div className="relative w-fit h-fit">
			{/* Label Badge */}
			{(showBonus || option.amount !== 1000) && option.labelImg && (
				<LabelBadge labelImg={option.labelImg} labelStyle={option.labelStyle} />
			)}

			<PrimaryBtn
				type="submit"
				style={{ height: 'fit-content', width: 'fit-content' }}
				onClick={() => setValue('amount', option.amount)}
				renderEllipse={true}
				content={
					<div className="w-[80px] h-[135px] mds:w-[120px] mds:h-[155px] lg:w-[160px] lg:h-[160px] pt-[5px] pb-[3px] lg:pt-[13px] lg:pb-[7px] flex flex-col items-center justify-between relative">
						{/* Main image */}
						<img src={option.image} alt="" className="my-auto" style={{ height: mainImgHeight }} />

						{/* Coin count and bonus display */}
						{showBonus && (
							<AmountDisplayWithCurrency
								amount={option.amount * (1 + option.bonus)}
								// eslint-disable-next-line no-magic-numbers
								fractionDigits={option.amount === 25 ? 2 : 0}
							/>
						)}

						{/* Bonus section */}
						{showBonus && option.bonus && (
							<div className="flex items-center text-[7px] lg:text-[10px] gap-x-[3px] mb-1 mt-[2px]">
								<AmountDisplayWithCurrency amount={option.amount} useDivWrapper={false} fractionDigits={0} />

								<span className="text-[#00DB00]">
									+ {displayUsdAmountInCurrency(option.amount * option.bonus, selectedCurrency)} BONUS
								</span>
							</div>
						)}

						{/* Amount Label */}
						{
							<PrimaryContainer
								renderEllipse={false}
								style={{ width: 'fit-content', height: 'fit-content', borderWidth: '2px' }}
								content={
									<div className="w-[40px] h-[22px] mds:h-[25px] mds:w-[80px] lg:h-[33px] lg:w-[126px] flex items-center justify-center">
										<img
											src={option.amountImg}
											className="w-auto h-[17px] lg:h-[20px] object-contain"
											alt={`$${option.amount}`}
										/>
									</div>
								}
							/>
						}
					</div>
				}
			/>
		</div>
	);
}
