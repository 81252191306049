// Styles
import 'react-loading-skeleton/dist/skeleton.css';

import { Skeleton } from 'components';
import { useBoxFromDb, useUserContext } from 'hooks';
import { useKeyListener } from 'hooks/utility/event-listener/useKeyListener';
import { useCallback, useEffect, useState } from 'react';
import { shallow } from 'zustand/shallow';

import { useBoxClientSeed } from '../hooks/useBoxClientSeed';
// Hooks
import { useBoxNameFromParams } from '../hooks/useBoxNameFromParams';
import { useBoxOpeningStoreDesktop } from '../store/useBoxOpeningStoreDesktop';
import ItemsOfBox from './components/ItemsOfBox';
import RecommendedBoxes from './components/RecommendedBoxes';
// Components
import Settingsbar from './components/Settingsbar/Settingsbar';
import { Slot } from './components/Slot/Slot';
import VerifiedFairnessPopup from './components/VerifiedFairnessPopup/VerifiedFairnessPopup';
import { ViewSwitchBtn } from './components/ViewSwitchBtn';

export function DesktopBoxOpen() {
	const [showPrices, setShowPrices] = useState(true);
	const { load: reloadUser } = useUserContext();

	const { isFairnessPopupVisible, setIsFullScreen, resetStore } = useBoxOpeningStoreDesktop(
		(state) => ({
			isFairnessPopupVisible: state.isFairnessPopupVisible,
			setIsFullScreen: state.setIsFullScreen,
			resetStore: state.resetStore,
		}),
		shallow // Prevents re-renders if the values are shallowly equal
	);

	const boxName = useBoxNameFromParams();
	const { data: box } = useBoxFromDb(boxName);

	useEffect(() => {
		return () => {
			if (boxName) {
				resetStore(['clientSeed', 'hashedServerSeed']);
			}
		};
	}, [boxName, resetStore]);

	const handleFullScreenExitViaESC = useCallback(() => setIsFullScreen(false), [setIsFullScreen]);
	useKeyListener('Escape', handleFullScreenExitViaESC);

	useBoxClientSeed({ isMobile: false });

	const handleRecommendedBoxClick = useCallback(() => {
		setShowPrices(true);
	}, []);

	useEffect(() => {
		return () => {
			reloadUser();
		};
	}, [reloadUser]);

	return (
		<>
			{isFairnessPopupVisible && <VerifiedFairnessPopup />}

			<div className="">
				{box ? (
					<Slot box={box} />
				) : (
					<div className="rounded-[24px] aspect-[1.8/1] mds:aspect-[2/1] lg:aspect-[2.6/1] lgx:aspect-[2.5/1] w-full overflow-hidden border-[3px] border-[rgba(255,255,255,0.1)]">
						<Skeleton height="100%" width="100%" />
					</div>
				)}

				<div className="mt-4">
					<Settingsbar />
				</div>
				<div className="mt-[65px]">
					<ViewSwitchBtn showPrices={showPrices} onChange={() => setShowPrices((prev) => !prev)} />
					{showPrices && <ItemsOfBox items={box?.items} boxId={box?._id} />}
					{!showPrices && <RecommendedBoxes boxname={boxName} onBoxClick={handleRecommendedBoxClick} />}
				</div>
			</div>
		</>
	);
}
