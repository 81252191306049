import { ModernPrimaryBtn } from 'components/common/buttons/ModernPrimaryBtn';
import gsap from 'gsap';
import { useOutsideClick, useScrollLock, useSlideUpAndDragToDismiss } from 'hooks';
import { useRef, useState } from 'react';

function OptionButton({
	option,
	selectedOption,
	onClick,
}: {
	option: string;
	selectedOption?: string;
	onClick: () => void;
}) {
	const isSelected = selectedOption === option;

	return (
		<button
			type="button"
			onClick={onClick}
			className={`relative text-[14px] font-bold rounded-full h-[55px] px-[14px] ${
				isSelected ? 'bg-blue-500 text-white' : 'bg-[rgba(255,255,255,0.2)] text-[#c2baba99]'
			} shadow-btnSm min-w-fit`}
		>
			{option}
		</button>
	);
}

interface Props {
	heading: string;
	options: string[];
	value: string;
	onChange: (selectedOption: string) => void;
	onClose: () => void;
}

export function MobileDropdownOverlay({ heading, value, options, onClose, onChange }: Props) {
	const overlayRef = useRef<HTMLDivElement>(null);
	const optionsWrapperRef = useRef<HTMLDivElement>(null);
	const [isExpanded, setIsExpanded] = useState(false);

	useScrollLock();

	useOutsideClick(overlayRef, () => {
		playDismissAnimation();
	});

	const { playDismissAnimation } = useSlideUpAndDragToDismiss({
		ref: overlayRef,
		contentRef: optionsWrapperRef,
		animationTime: 0.4,
		onDismiss: onClose,
	});

	function handleExpand() {
		if (isExpanded) {
			gsap.to(overlayRef.current, {
				height: '290px',
				duration: 0.2,
				ease: 'power1.out',
			});
			setIsExpanded((prev) => !prev);
		} else {
			gsap.to(overlayRef.current, {
				height: '500px',
				duration: 0.2,
				ease: 'power1.out',
				onComplete: () => {
					setIsExpanded((prev) => !prev);
				},
			});
		}
	}

	return (
		<div className="fixed inset-0 z-50 bg-[rgba(0,0,0,0.35)] flex items-end">
			<div ref={overlayRef} className={`h-[290px] fixed z-0 w-screen left-0 right-0 bottom-0 flex flex-col`}>
				<div className="py-[5px] w-full flex items-center justify-center">
					<div className="w-[80px] h-[5px] rounded-full bg-white shadow-[4px_4px_4px_rgba(0,0,0,0.35)]" />
				</div>

				<div className="w-screen h-full bg-background rounded-[12px_12px_0_0] flex flex-col justify-between items-start gap-y-[20px] pt-[25px]">
					<div className="w-full flex items-center justify-between  px-mobile-side-padding">
						<h1 className="text-[22px] text-white font-bold">{heading}</h1>
						<button type="button" onClick={handleExpand} className="text-[14px] text-mrLootBlue font-semibold">
							{isExpanded ? 'Minimize' : 'Expand'}
						</button>
					</div>

					<div
						ref={optionsWrapperRef}
						className={`${isExpanded ? 'flex-wrap items-start gap-[12px] max-h-[285px] justify-center px-mobile-side-padding pb-[20px]' : 'gap-x-[12px] pl-mobile-side-padding py-[10px]'} overflow-x-auto no-scrollbar w-full flex items-center`}
					>
						{options?.map((option, index) => (
							<OptionButton key={index} option={option} selectedOption={value} onClick={() => onChange(option)} />
						))}
					</div>

					<div className="w-full px-mobile-side-padding flex-shrink-0 pb-[25px]">
						<ModernPrimaryBtn text="Apply" onClick={playDismissAnimation} style={{ height: '55px' }} />
					</div>
				</div>
			</div>

			<div className="absolute z-10 bottom-0 left-0 right-0 w-full h-[200px] bg-mobileBackground translate-y-[100%]" />
		</div>
	);
}
