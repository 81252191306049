import { gsap } from 'gsap';
import { NUM_SURROUNDING_PRIZES } from 'pages/OpenBox/box-opening.constants';
import { calculateOffset } from 'pages/OpenBox/util';
import { RefObject, useCallback } from 'react';

interface Props {
	itemsWrapperRef: RefObject<HTMLDivElement>;
	slotPickerRef: RefObject<HTMLImageElement>;
}

export function useSlotSpinReset({ itemsWrapperRef, slotPickerRef }: Props) {
	const resetSlotSpin = useCallback(() => {
		if (!itemsWrapperRef || !slotPickerRef) {
			return;
		}
		const targetX = calculateOffset({ itemsWrapperRef, slotPickerRef, targetPos: NUM_SURROUNDING_PRIZES / 2 });
		if (targetX) {
			gsap.set(itemsWrapperRef.current, { x: targetX });
		}
	}, [itemsWrapperRef, slotPickerRef]);

	return { resetSlotSpin };
}
