import { faForward, faInfinity, faRotateLeft, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PrimaryBtn } from 'components/common/buttons/PrimaryBtn';
import { useOutsideClick } from 'hooks';
import { useBoxOpeningStoreDesktop } from 'pages/OpenBox/store/useBoxOpeningStoreDesktop';
import { useRef } from 'react';

const SELECTIONS = [
	{ value: 1, label: '1x' },
	{ value: 3, label: '3x' },
	{ value: 5, label: '5x' },
	{ value: 10, label: '10x' },
	{ value: Infinity, label: 'Endless' },
];

export function OpenOptionsScreen() {
	const modalRef = useRef<HTMLDivElement>(null);

	useOutsideClick(modalRef, () => setShowOpenOptionsScreen(false));

	const { setShowOpenOptionsScreen, setAutoSpinCount, isFastSpin, toogleIsFastSpin, autoSpinCount } =
		useBoxOpeningStoreDesktop();

	return (
		<div className="bg-black25 z-30 w-full h-full absolute left-0 top-0 flex items-center justify-center rounded-[20px]">
			<div ref={modalRef} className="z-40 border-[3px] border-white rounded-[20px] w-[430px] h-[240px]">
				<div
					className="h-full w-full rounded-[18px] pt-2 px-3 pb-4 flex flex-col space-y-2 border-[2px] border-darkBlue relative items-center justify-between"
					style={{
						background: 'linear-gradient(331.41deg, rgba(35, 109, 244, 0.3) 6.78%, rgba(51, 240, 167, 0.3) 204.87%)',
					}}
				>
					<div className="w-full flex flex-col gap-y-[15px]">
						<div className="flex w-full justify-between items-center">
							<div className="flex items-center gap-x-[8px] text-white">
								<FontAwesomeIcon icon={faRotateLeft} className="rotate-90" />
								<h3 className="font-semibold mx-auto text-[16px]">Auto-Open</h3>
							</div>
							<button
								className="hover:bg-white ml-auto hover:text-black h-[30px] w-[30px] text-[12px] border-[2px] text-white border-white rounded-[50%] flex justify-center items-center"
								onClick={() => setShowOpenOptionsScreen(false)}
							>
								<FontAwesomeIcon icon={faXmark} />
							</button>
						</div>
						<div className="flex items-center w-full justify-center flex-wrap gap-[12px]">
							{SELECTIONS.map((el) => (
								<PrimaryBtn
									key={el.value}
									onClick={() => setAutoSpinCount(el.value)}
									renderEllipse={false}
									innerContentStyle={{ padding: '0px', overflow: 'hidden' }}
									style={{
										height: '37px',
										width: '101px',
									}}
									content={
										<div
											className={`text-white w-full h-full rounded-[15px] flex items-center justify-center text-[15px] font-semibold ${el.value === autoSpinCount && 'shadow-[inset_0px_-1px_7px_rgba(0,0,0,0.85)]'}`}
										>
											{el.value === Infinity ? <FontAwesomeIcon icon={faInfinity} /> : el.label}
										</div>
									}
								/>
							))}
						</div>
					</div>

					<PrimaryBtn
						onClick={toogleIsFastSpin}
						renderEllipse={false}
						style={{
							width: '145px',
							height: '41px',
						}}
						innerContentStyle={{ padding: '0px', overflow: 'hidden' }}
						content={
							<div
								className={`${isFastSpin && 'shadow-[inset_0px_-1px_7px_rgba(0,0,0,0.85)]'} rounded-[15px] w-full h-full flex justify-center gap-[12px] items-center text-[15px]`}
							>
								<FontAwesomeIcon icon={faForward} />
								<span className="text-[15px] text-white font-semibold">Fast Open</span>
							</div>
						}
					/>
				</div>
			</div>
		</div>
	);
}
