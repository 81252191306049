import { Line } from 'components/common/Line';

export function CookiePolicy() {
	return (
		<div className="flex flex-col gap-y-[10px]">
			<h1 className="text-white font-bold text-[22px]">Cookie Policy</h1>
			<span className="text-[rgba(255,255,255,0.7)] text-[15px] font-semibold">Updated in July 2024</span>
			<Line style={{ margin: '10px 0' }} />
			<h2 className="mt-[25px] text-white font-bold text-[20px]">DETAILS ON OUR COOKIE USAGE</h2>
			<p className="text-[15px] leading-[22px] text-justify text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
				Our site https://MrLoot.com uses cookies to differentiate you from other visitors. This aids in providing an
				enhanced browsing experience and helps us improve our website. By continuing to use our site, you consent to our
				cookie usage.
			</p>
			<p className="text-[15px] leading-[22px] text-justify text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
				Cookies are small text files that we place on your browser or computer's hard drive if you permit. These files
				contain data that is transferred to your computer's storage.
			</p>
			<p className="text-[15px] leading-[22px] text-justify text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
				We use several types of cookies:
			</p>

			<ul className="space-y-[10px]">
				<li className="ml-[25px] text-[15px] leading-[22px] list-disc text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
					<strong className="text-white">Essential cookies.</strong> These cookies are necessary for the functionality
					of our website. They enable features such as logging into secure areas, using a shopping cart, or accessing
					e-billing services.
				</li>
				<li className="ml-[25px] text-[15px] leading-[22px] list-disc text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
					<strong className="text-white">Performance cookies.</strong> These cookies allow us to count visitors and see
					how they navigate our site, helping us enhance the site's functionality and user experience.
				</li>
				<li className="ml-[25px] text-[15px] leading-[22px] list-disc text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
					<strong className="text-white">Functionality cookies.</strong> These cookies recognize you upon return visits,
					enabling us to personalize content, greet you by name, and remember your preferences.
				</li>
				<li className="ml-[25px] text-[15px] leading-[22px] list-disc text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
					<strong className="text-white">Targeting cookies.</strong> These cookies track your site visits, pages viewed,
					and links followed. We use this data to tailor our site and advertisements to your interests, and we may share
					this information with third parties for this purpose.
				</li>
			</ul>
			<p className="text-[15px] leading-[22px] text-justify text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
				Be aware that third parties (such as ad networks and providers of external services like web traffic analysis)
				might also use cookies over which we have no control. These cookies are generally for performance or targeting
				purposes.
			</p>
			<p className="text-[15px] leading-[22px] text-justify text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
				You can block cookies through your browser settings, which allows you to refuse some or all cookies. However,
				blocking all cookies (including essential ones) may result in limited access to parts of our site. We might
				update this cookie policy periodically, so please review this page regularly for changes, as they are legally
				binding. Some provisions in this policy may be overridden by specific notices elsewhere on our site.
			</p>
			<h2 className="mt-[25px] text-white font-bold text-[20px]">Cookies Origin</h2>
			<p className="text-[15px] leading-[22px] text-justify text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
				<strong className="text-white">First-party cookies:</strong> These are cookies placed on your device by our
				website.
			</p>
			<p className="text-[15px] leading-[22px] text-justify text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
				<strong className="text-white">Third-party cookies:</strong> External parties might also use cookies via our
				site, collecting data directly from your browser, governed by their privacy policies. Below is a list of these
				third parties.
			</p>
			<p className="text-[15px] leading-[22px] text-justify text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
				<strong className="text-white">Information on third-party cookies:</strong> MrLoot aims to keep this list
				current, but it may not always reflect the latest changes.
			</p>
			<p className="text-[15px] leading-[22px] text-justify text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
				Third-party Networks:
			</p>
			<ul className="space-y-[10px]">
				<li className="ml-[25px] text-[15px] leading-[22px] list-disc text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
					Google
				</li>
				<li className="ml-[25px] text-[15px] leading-[22px] list-disc text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
					Facebook
				</li>
				<li className="ml-[25px] text-[15px] leading-[22px] list-disc text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
					Snapchat
				</li>
				<li className="ml-[25px] text-[15px] leading-[22px] list-disc text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
					TikTok
				</li>
				<li className="ml-[25px] text-[15px] leading-[22px] list-disc text-[rgba(255,255,255,0.8)] tracking-[0.5px]">
					Bing
				</li>
			</ul>
		</div>
	);
}
