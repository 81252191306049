interface Props {
	width?: number;
	height?: number;
}
export function ArrowDown19({ height = 24, width = 24 }: Props = {}) {
	return (
		/* Icon used under CC BY 4.0 License */
		/* create by Dave Gandy */
		<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 576 512">
			<path
				fill="currentColor"
				d="M450.7 38c-8.3-6-19.1-7.7-28.8-4.4l-48 16c-16.8 5.6-25.8 23.7-20.2 40.5s23.7 25.8 40.5 20.2l5.9-2V160H384c-17.7 0-32 14.3-32 32s14.3 32 32 32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32h-16V64c0-10.3-4.9-19.9-13.3-26M160 480c9 0 17.5-3.8 23.6-10.4l88-96c11.9-13 11.1-33.3-2-45.2s-33.3-11.1-45.2 2L192 365.7V64c0-17.7-14.3-32-32-32s-32 14.3-32 32v301.7l-32.4-35.3c-11.9-13-32.2-13.9-45.2-2s-13.9 32.2-2 45.2l88 96c6.1 6.6 14.6 10.4 23.6 10.4m258.3-173a32 32 0 1 1 27.4 57.9a32 32 0 1 1-27.4-57.9m-13.2 112.8l-6.8 9.2c-10.5 14.2-7.5 34.2 6.7 44.8s34.2 7.5 44.8-6.7l48.8-65.8c14-18.9 21.5-41.7 21.5-65.2c0-48.6-39.4-88-88-88s-88 39.4-88 88c0 39.2 25.6 72.4 61.1 83.8z"
			></path>
		</svg>
	);
}
