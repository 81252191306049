import React, { forwardRef } from 'react';

interface Props {
	style?: React.CSSProperties;
	children?: React.ReactNode;
}

export const MobileBackground = forwardRef<HTMLDivElement, Props>(({ children, style }, ref) => {
	const svgPattern = encodeURIComponent(`
    <svg width="100%" height="100%" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg" version="1.1">
        <defs>
            <radialGradient id="grad1" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                <stop offset="0%" style="stop-color:#2981E9; stop-opacity:1" />
                <stop offset="100%" style="stop-color:#2981E9; stop-opacity:0" />
            </radialGradient>
            <filter id="blurFilter">
                <feGaussianBlur in="SourceGraphic" stdDeviation="50" />
            </filter>
        </defs>
        <rect x="0" y="0" width="200" height="200" fill="url(#grad1)" filter="url(#blurFilter)" />
    </svg>
  `);

	return (
		<div
			ref={ref}
			className="w-full min-h-screen z-0 flex-grow h-fit bg-background"
			style={{
				backgroundImage: `url('data:image/svg+xml;utf8,${svgPattern}')`,
				backgroundPosition: 'top center',
				backgroundRepeat: 'repeat-y',
				backgroundSize: '100%',
				...style,
			}}
		>
			{children && <div className="z-10">{children}</div>}
		</div>
	);
});
