import { PickSlotBorder, PickSlotInlay, SlotLeft, SlotRight } from 'assets/images';
import { useImageLoadingProgress } from 'hooks/utility/useImageLoadingProgress';
import { useBoxOpeningStoreDesktop } from 'pages/OpenBox/store/useBoxOpeningStoreDesktop';
import React, { forwardRef, ReactNode } from 'react';
import { shallow } from 'zustand/shallow';

interface Props {
	children: ReactNode;
	onCompleteLoading: () => void;
	onTotalFailure: () => void;
}

// Define component
const WithSlotImagesComponent = forwardRef<HTMLImageElement, Props>(
	({ children, onTotalFailure, onCompleteLoading }, ref) => {
		const { handleLoadFail, handleLoadSuccess } = useImageLoadingProgress({
			totalImages: 4,
			resetAfterCompleteOrFail: false,
			onCompleteLoading: onCompleteLoading,
			onTotalFailure: onTotalFailure,
			failThreshold: 0,
		});

		const isFullScreen = useBoxOpeningStoreDesktop((state) => state.isFullScreen, shallow);

		return (
			<div
				className={`size-container relative grid grid-cols-[1fr_auto_1fr] items-start w-full ${
					isFullScreen ? 'h-[380px]' : 'h-[300px]'
				}`}
			>
				<img
					src={SlotLeft}
					alt="slot left"
					className="h-[87.5cqh] w-full z-10 self-center"
					onLoad={handleLoadSuccess}
					onError={handleLoadFail}
				/>

				<img
					ref={ref}
					alt="slot picker"
					src={PickSlotBorder}
					className="h-[100cqh] relative top-[6px] z-20 self-center"
					onLoad={handleLoadSuccess}
					onError={handleLoadFail}
				/>
				<img
					src={PickSlotInlay}
					alt="slot picker inlay"
					className="absolute left-[50%] translate-x-[-50%] top-[50%] translate-y-[-50%] z-10 h-[90cqh]"
					onLoad={handleLoadSuccess}
					onError={handleLoadFail}
				/>
				<img
					src={SlotRight}
					alt="slot right"
					className="h-[87.5cqh] z-10 self-center w-full"
					onLoad={handleLoadSuccess}
					onError={handleLoadFail}
				/>

				<div className="h-[78.125cqh] absolute z-10 top-[50%] translate-y-[-50%] left-0 w-full">{children}</div>
			</div>
		);
	}
);

// Memoize with React.memo
export const WithSlotImages = React.memo(WithSlotImagesComponent);
