import { faTruckFast } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { VerifiedTrustIcon } from 'assets/icons';
import { SecondaryBtn } from 'components/common/buttons/SecondaryBtn';
import { useWindowDimensions } from 'hooks/utility/layout-measurements/useWindowDimensions';
import { useBoxOpeningStoreDesktop } from 'pages/OpenBox/store/useBoxOpeningStoreDesktop';

import { screens } from '../../../../../../constants';

export function FairnessAssuredBtn() {
	const { screenWidth } = useWindowDimensions();
	// eslint-disable-next-line no-magic-numbers
	const iconSize = screenWidth > screens.xxl.width ? 20 : 18;

	const { setIsFairnessPopupVisible } = useBoxOpeningStoreDesktop();

	return (
		<SecondaryBtn
			onClick={() => setIsFairnessPopupVisible(true)}
			style={{ height: '100%', width: 'fit-content', borderWidth: '2px' }}
			content={
				<div className="w-full flex items-center justify-center gap-x-[8px] text-[12px] lg:text-[14px] text-white">
					<div className="hidden lgx:flex items-center space-x-2 mr-4 xxl:space-x-[12px]">
						<FontAwesomeIcon icon={faTruckFast} />
						<span>24/7 Shipping</span>
					</div>
					<div className="flex items-center space-x-2 xxl:space-x-[12px]">
						<VerifiedTrustIcon height={iconSize} width={iconSize} />
						<span className="hidden md:block">Fairness Assured</span>
					</div>
				</div>
			}
		/>
	);
}
