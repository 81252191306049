import { useEffect } from 'react';

const getScrollbarWidth = () => {
	const outer = document.createElement('div');
	outer.style.visibility = 'hidden';
	outer.style.overflow = 'scroll';
	document.body.appendChild(outer);

	const inner = document.createElement('div');
	outer.appendChild(inner);

	const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

	outer.parentNode?.removeChild(outer);

	return scrollbarWidth;
};

interface Props {
	isDisabled?: boolean;
}

export const useScrollLock = ({ isDisabled = false }: Props = {}) => {
	useEffect(() => {
		const isMobile = /Mobi|Android/i.test(navigator.userAgent);

		if (!isDisabled && !isMobile) {
			document.body.style.overflow = 'hidden';
			const scrollbarWidth = getScrollbarWidth();
			document.body.style.paddingRight = `${scrollbarWidth}px`;
		} else if (!isDisabled && isMobile) {
			// For mobile devices, you might decide to skip setting paddingRight
			document.body.style.overflow = 'hidden';
		}

		return () => {
			document.body.style.overflow = 'unset';
			document.body.style.paddingRight = '0px';
		};
	}, [isDisabled]);
};
