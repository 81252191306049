import { MobileBannerSection } from 'components/common/MobileBanners';

import { RecommendedBoxCarousels } from './mobile/RecommendedBoxCarousels/RecommendedBoxCarousels';

export function MobileHome() {
	return (
		<div className="flex flex-col gap-y-[35px]">
			<MobileBannerSection />
			<RecommendedBoxCarousels />
		</div>
	);
}
