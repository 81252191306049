import axios from 'axios';
import { BaseInteractionInterface } from 'interfaces/UserInteractionInterface';
import { useMutation } from 'react-query';

import { API_DOMAIN } from '../../constants';

async function createUserInteraction({
	userId,
	targetType,
	targetId,
	interactionType,
	stateAtInteraction,
	interactionDetails,
}: BaseInteractionInterface) {
	try {
		if (!userId) {
			return;
		}

		const response = await axios.post(
			`${API_DOMAIN.mrlootBackend}/api/user-interaction/create`,
			{
				userId,
				targetType,
				targetId,
				interactionType,
				interactionDetails,
				stateAtInteraction,
			},
			{ withCredentials: true }
		);

		if (response.data.status !== 'success') {
			throw new Error(response.data.message || 'Something went wrong');
		}
	} catch (err) {
		console.error(err);
		throw err;
	}
}

export function useCreateUserInteraction() {
	return useMutation(
		({
			userId,
			targetType,
			targetId,
			interactionType,
			stateAtInteraction,
			interactionDetails,
		}: BaseInteractionInterface) =>
			createUserInteraction({
				userId,
				targetType,
				targetId,
				interactionType,
				interactionDetails,
				stateAtInteraction,
			})
	);
}
