import { faCheck, faEnvelope, faEnvelopeOpen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AmountDisplayWithCurrency } from 'components/common/AmountDisplayWithCurrency';
import { PrimaryBtn } from 'components/common/buttons/PrimaryBtn';
import { SecondaryBtn } from 'components/common/buttons/SecondaryBtn';
import { WithComponentFadeIn } from 'components/misc/WithComponentFadeIn';
import { ToastErrorNotification } from 'components/toasts/ToastErrorNotification';
import { ToastSuccessNotification } from 'components/toasts/ToastSuccessNotification';
import { useUserContext } from 'hooks';
import { usePayOutstandingOrderItemAmount } from 'pages/MyOrders/hooks/usePayOutstandingOrderItemAmount';
import { useRefundOrderItem } from 'pages/MyOrders/hooks/useRefundOrderItem';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { Tooltip } from 'react-tooltip';

import { QUERY_KEYS } from '../../../../constants';

interface Props {
	outstandingAmount: number;
	orderId: string;
	itemId: string;
}

export function OutstandingAmountInfo({ outstandingAmount, itemId, orderId }: Props) {
	const [isMsgOpened, setIsMsgOpened] = useState(false);

	const queryClient = useQueryClient();
	const { user, load: reloadUser } = useUserContext();

	const { mutate: refundOrderItem, isLoading: isRefundItemLoading } = useRefundOrderItem({
		onSuccess: () => {
			queryClient.invalidateQueries(QUERY_KEYS.orderData);
			queryClient.invalidateQueries(QUERY_KEYS.inventoryItemData);
			toast(<ToastSuccessNotification message="Item has been refunded" />);
		},
		onError: (error) => {
			toast(<ToastErrorNotification message={error} />);
		},
	});

	const { mutate: payOutstandingAmount, isLoading: isPayOutstandingAmountLoading } = usePayOutstandingOrderItemAmount({
		onSuccess: () => {
			queryClient.invalidateQueries(QUERY_KEYS.orderData);
			reloadUser();
			toast(<ToastSuccessNotification message="Payment was successful. Product is going to be shipped soon" />);
		},
		onError: (error) => {
			toast(<ToastErrorNotification message={error} />);
		},
	});

	function handlePayOutstandingAmount() {
		if (!user || user.balance < outstandingAmount) {
			toast(<ToastErrorNotification message="Balance is too low" />);
			return;
		}

		payOutstandingAmount({ orderId, orderItemId: itemId });
	}

	return (
		<>
			{outstandingAmount === 0 && (
				<span className="text-[14px] text-green-500 font-semibold text-center flex flex-col justify-center items-center">
					Price <br /> Difference <br />
					<span className="flex items-center relative">
						Paid <FontAwesomeIcon icon={faCheck} className="absolute right-[-18px] ml-1" />
					</span>
				</span>
			)}
			{!!outstandingAmount && (
				<div className="flex lg:flex-col gap-[15px] text-white">
					<div className="relative focus-within:z-20 flex items-center lg:flex-col">
						<button
							className="flex whitespace-nowrap lg:whitespace-normal items-center gap-x-[7px] text-[12px] font-semibold hover:underline"
							onClick={() => setIsMsgOpened((prev) => !prev)}
						>
							<FontAwesomeIcon icon={isMsgOpened ? faEnvelopeOpen : faEnvelope} className="" />
							<span>Updated Price</span>
						</button>
						<WithComponentFadeIn
							show={isMsgOpened}
							duration={0.4}
							style={{
								position: 'absolute',
								zIndex: '10',
								bottom: '-8px',
								left: '50%',
								transform: 'translate(-50%, 100%)',
								width: '170px',
								border: '1px solid white',
								backgroundColor: '#2981e9 ',
								borderRadius: '16px',
								padding: '8px 12px',
							}}
						>
							<span className="text-[11px] w-full font-semibold  ">
								<span className="absolute w-[12px] h-[12px] rotate-45 left-1/2 top-0 bg-mrLootBlue z-[-1] -translate-x-1/2 -translate-y-1/2 border-l-[1px] border-t-[1px] border-white"></span>
								Ooops! The price of this product has increased due to market changes
							</span>
						</WithComponentFadeIn>
					</div>

					<PrimaryBtn
						onClick={handlePayOutstandingAmount}
						isDisabled={isRefundItemLoading || isPayOutstandingAmountLoading}
						renderEllipse={false}
						style={{ height: '30px' }}
						content={
							<div className="flex items-center text-[12px] gap-x-[3px]">
								<span className="mr-[2px]">Pay</span>
								<AmountDisplayWithCurrency
									amount={outstandingAmount}
									useDivWrapper={false}
									imgStyle={{ height: '12px' }}
								/>
							</div>
						}
						data-tooltip-id="pay-btn-tooltip"
						data-tooltip-content="This settles the difference and ships you product home"
						data-tooltip-place="left"
					/>
					<SecondaryBtn
						content="Cancel"
						onClick={() => refundOrderItem({ orderId, orderItemId: itemId })}
						isDisabled={isRefundItemLoading || isPayOutstandingAmountLoading}
						style={{ height: '30px', fontSize: '12px' }}
						data-tooltip-id="pay-btn-tooltip"
						data-tooltip-content="This puts your product back to inventory"
						data-tooltip-place="left"
					/>

					<Tooltip
						id="pay-btn-tooltip"
						arrowColor="#2981e9 "
						border="1px solid white"
						className="!bg-mrLootBlue !rounded-[8px] z-10"
					/>
				</div>
			)}
		</>
	);
}
