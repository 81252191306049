import { forwardRef, useState } from 'react';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
	label?: string;
	error?: string;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onBlur: () => void;
	value: string;
	name: string;
	placeholder?: string;
}

export const PasswordInput = forwardRef<HTMLInputElement, Props>(
	({ label = 'Password', error, onChange, onBlur, value, name, placeholder = 'Password' }, ref) => {
		const [hidePassword, setHidePassword] = useState(true);

		return (
			<div className="flex flex-col space-y-1">
				<label className="text-sm">{label}</label>
				<div className="relative">
					<input
						type={hidePassword ? 'password' : 'text'}
						value={value}
						onChange={onChange}
						onBlur={onBlur}
						name={name}
						ref={ref}
						placeholder={placeholder}
						className={`rounded-md bg-white pl-6 pr-8 py-3 text-sm w-[100%] border-[2px] ${
							error ? 'border-errorRed' : 'border-white hover:border-blue300 focus-visible:border-blue400'
						} focus-visible:outline-none`}
					/>
					<button
						type="button"
						className="absolute p-1 right-1 top-[50%] translate-y-[-50%] text-gray-300 hover:text-gray-400"
						onClick={() => setHidePassword((prev) => !prev)}
					>
						<FontAwesomeIcon icon={hidePassword ? faEyeSlash : faEye} />
					</button>
				</div>
				{error && <span className="text-errorRed text-sm">{error}</span>}
			</div>
		);
	}
);

PasswordInput.displayName = 'PasswordInput';
