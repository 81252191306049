import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './productImageSlider.css';

import { ItemInterface } from 'interfaces/ItemInterfaces';
import { useRef, useState } from 'react';
import Slider from 'react-slick';

import { CLOUDFRONT_IMG_URL } from '../../../constants';

interface Props {
	item: ItemInterface | undefined;
}

export function DesktopProductImgSlider({ item }: Props) {
	const images = item?.showcaseImgUrls
		? [item?.imageUrls.qualityHigh, ...item?.showcaseImgUrls]
		: [item?.imageUrls.qualityHigh];

	const [activeIndex, setActiveIndex] = useState(0);
	const sliderRef = useRef<Slider | null>(null);

	const settings = {
		dots: false,
		infinite: images.length > 1,
		slidesToShow: 1,
		slidesToScroll: 1,
		swipe: true,
		arrows: true,
		beforeChange: (current: number, next: number) => setActiveIndex(next),
	};

	const handleThumbnailClick = (index: number) => {
		setActiveIndex(index);
		sliderRef.current?.slickGoTo(index);
	};

	const handleKeyDown = (event: React.KeyboardEvent, index: number) => {
		if (event.key === 'Enter' || event.key === ' ') {
			handleThumbnailClick(index);
		}
	};

	return (
		<div className="sticky top-[80px] h-fit flex flex-col gap-y-[15px]">
			<Slider {...settings} ref={sliderRef} className="w-full aspect-[0.5/1] bg-white max-h-[400px] p-[15px]">
				{images.map((imageUrl, index) => (
					<div key={index} className="!flex justify-center items-center h-full w-full">
						{imageUrl && (
							<img
								src={`${CLOUDFRONT_IMG_URL}/${imageUrl}`}
								alt={`Product ${index + 1}`}
								className="w-[80%] h-auto object-contain max-h-[350px]"
							/>
						)}
					</div>
				))}
			</Slider>

			{/* Thumbnail section */}
			<div className="flex justify-left gap-x-[10px] mt-5">
				{images.map((imageUrl, index) => (
					<div
						key={index}
						role="button" // Add a role to make it clear that this is an interactive element.
						tabIndex={0} // Ensure that the div is focusable.
						onClick={() => handleThumbnailClick(index)} // Click event for mouse interaction.
						onKeyDown={(e) => handleKeyDown(e, index)} // KeyDown event for keyboard interaction.
						className={`cursor-pointer border-2 ${activeIndex === index ? 'border-blue-500' : 'border-transparent'}`}
					>
						<img
							src={`${CLOUDFRONT_IMG_URL}/${imageUrl}`}
							alt={`Thumbnail ${index + 1}`}
							className="w-[60px] h-[60px] object-cover"
						/>
					</div>
				))}
			</div>
		</div>
	);
}
