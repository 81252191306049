import { useUserContext } from 'hooks';
import { ShippingAddressInterface } from 'interfaces/OrderInterfaces';
import { useEffect } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

export const useInitializeAddressForm = (setValue: UseFormSetValue<ShippingAddressInterface>) => {
	const { user } = useUserContext();
	const [searchParams] = useSearchParams();
	const addressId = searchParams.get('id');

	useEffect(() => {
		if (user && addressId) {
			const foundAddress = user.addresses.find((address) => address._id === addressId);
			if (foundAddress) {
				// init Values
				Object.keys(foundAddress).forEach((key) =>
					setValue(key as keyof ShippingAddressInterface, foundAddress[key as keyof ShippingAddressInterface])
				);
			}
		}
	}, [addressId, setValue, user]);

	return { addressId };
};
