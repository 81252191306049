import { offTopbarBackButtonClicked, onTopbarBackButtonClicked } from 'events/BackBtnTopbarEmitter';
import { useEffect } from 'react';

export const useTopbarBackBtnListener = (callback: () => void) => {
	useEffect(() => {
		// Subscribe to the back button event
		onTopbarBackButtonClicked(callback);

		// Cleanup listener on component unmount
		return () => {
			offTopbarBackButtonClicked(callback);
		};
	}, [callback]);
};
