import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FreeFont } from 'assets/images';
import { AmountDisplayWithCurrency } from 'components/common/AmountDisplayWithCurrency';
import { PrimaryBtn } from 'components/common/buttons/PrimaryBtn';
import { useWindowDimensions } from 'hooks/utility/layout-measurements/useWindowDimensions';

import { screens } from '../../../../constants';

interface Props {
	productCosts: number;
	shippingCosts: number;
	onCheckout: () => void;
	isCheckoutDisabled: boolean;
	isLoading: boolean;
	step: number;
}

const LAST_STEP = 3;

export function Sidebar({ productCosts, shippingCosts, onCheckout, isCheckoutDisabled, isLoading, step }: Props) {
	const { screenWidth } = useWindowDimensions();

	const totalCosts = productCosts + shippingCosts;

	const CheckoutBtn = () => (
		<PrimaryBtn
			content={
				isLoading ? (
					<FontAwesomeIcon icon={faSpinner} className="animate-spin" />
				) : step < LAST_STEP ? (
					'Next'
				) : (
					'Checkout'
				)
			}
			style={{ height: '40px' }}
			onClick={onCheckout}
			isDisabled={isCheckoutDisabled}
		/>
	);

	const CostDisplay = ({ label, amount }: { label: string; amount: number }) => (
		<div className="flex justify-between w-[145px] items-center text-white">
			<h2 className="text-[17px] md:text-[16px] font-semibold">{label}:</h2>
			{amount === 0 ? (
				<img src={FreeFont} className="h-[20px] md:h-[21px] object-scale-down md:rotate-[-15deg]" alt="free" />
			) : (
				<AmountDisplayWithCurrency amount={amount} amountStyle={{ fontSize: '18px' }} imgStyle={{ height: '18px' }} />
			)}
		</div>
	);

	const TabletSidebar = () => (
		<div className="z-40 w-full flex items-center justify-between bottom-[15px] right-[32px] border-[3px] border-[rgba(255,255,255,0.1)] bg-[#1a1c1e] px-[22px] py-[18px] rounded-[24px]">
			<div className="flex flex-col gap-y-[10px]">
				<CostDisplay label="Subtotal" amount={productCosts} />
				<CostDisplay label="Shipping" amount={shippingCosts} />
				<CostDisplay label="Total" amount={totalCosts} />
			</div>
			<div className="w-[140px] flex items-center">
				<CheckoutBtn />
			</div>
		</div>
	);

	const DesktopSidebar = () => (
		<div className="text-white space-y-[14px] w-[270px] border-[3px] rounded-[24px] border-white01 py-[10px] px-[20px]">
			<h1 className="text-[14px]">SUMMARY</h1>
			<CostDisplay label="Subtotal" amount={productCosts} />
			<CostDisplay label="Shipping" amount={shippingCosts} />
			<CostDisplay label="Total" amount={totalCosts} />
			<p className="text-[11px] tracking-[0.25px]">Additional shipping & handling charges may apply.</p>
			<CheckoutBtn />
		</div>
	);

	return <>{screenWidth >= screens.md.width ? <DesktopSidebar /> : <TabletSidebar />}</>;
}
