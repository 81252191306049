import { BaseSelectedOptionSet } from 'interfaces/OrderInterfaces';

interface Props {
	options: BaseSelectedOptionSet[];
}

export function ProductOptionsInfo({ options }: Props) {
	return (
		<>
			{options[0].optionSelections.length === 1 ? (
				<div className="flex items-center flex-wrap gap-x-[10px]">
					<span className="capitalize font-semibold">{options[0].optionSelections[0].label}:</span>
					<span>{options.map((el) => el.optionSelections[0].value).join(', ')}</span>
				</div>
			) : (
				<div
					className="grid gap-x-[20px]"
					style={{
						gridTemplateColumns: `min-content repeat(${options[0].optionSelections.length - 1}, min-content)`,
					}}
				>
					{options[0]?.optionSelections.map(({ label }, index) => (
						<div key={index} className="font-semibold text-center whitespace-nowrap">
							{label}:
						</div>
					))}

					{options.map(({ optionSelections }) =>
						optionSelections.map(({ value }, index) => (
							<div key={index} className="text-center whitespace-nowrap">
								{value}
							</div>
						))
					)}
				</div>
			)}
		</>
	);
}
