import { ItemCard } from 'components';
import { useBoxOpeningStoreDesktop } from 'pages/OpenBox/store/useBoxOpeningStoreDesktop';
import { shallow } from 'zustand/shallow';

interface Props {
	onImageLoad?: () => void;
	onImageError?: () => void;
}

export function WonPrizeCard({ onImageError, onImageLoad }: Props) {
	const wonPrize = useBoxOpeningStoreDesktop((state) => state.wonPrize, shallow);

	if (!wonPrize) {
		return null;
	}

	return (
		<ItemCard
			key={wonPrize.key}
			item={wonPrize.data}
			style={{ border: 'none', height: '100%' }}
			onImageLoad={onImageLoad}
			onImageError={onImageError}
		/>
	);
}
