import axios from 'axios'; // Assuming you're using axios for HTTP requests
import { DBUserItemWithItemObj } from 'interfaces/InventoryInterface';
import { useMemo } from 'react';
import { useInfiniteQuery } from 'react-query';

import { API_DOMAIN, QUERY_KEYS } from '../../../constants';
import { InventoryOrderOptions } from '../components/InterfacesUI';

const fetchPopulatedInventoryItems = async (
	inventoryItemIds: string[],
	order: InventoryOrderOptions,
	pageSize = 10,
	page = 0
) => {
	if (inventoryItemIds && inventoryItemIds.length === 0) {
		return;
	}

	try {
		const response = await axios.post(
			`${API_DOMAIN.mrlootBackend}/api/user/inventory/populate-items`,
			{ ids: inventoryItemIds, n: pageSize, page, order },
			{ withCredentials: true }
		);
		if (response.data.status === 'success') {
			return response.data;
		}
	} catch (err) {
		console.error(err);
		throw err;
	}
};

interface InventoryLoadResultInterface {
	data: DBUserItemWithItemObj[];
	pagination: {
		page: number;
		totalPages: number;
	};
	totalItemAmount: number;
}

interface Props {
	inventoryItemIds: string[];
	limit: number;
	order: InventoryOrderOptions;
}

export function usePopulatedInventoryItems({ inventoryItemIds, limit, order }: Props) {
	const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } = useInfiniteQuery<
		InventoryLoadResultInterface,
		Error
	>({
		queryKey: [QUERY_KEYS.inventoryItemData, 'populate-by-ids', inventoryItemIds, order],
		queryFn: ({ pageParam = 0 }) => fetchPopulatedInventoryItems(inventoryItemIds, order, limit, pageParam),
		keepPreviousData: true,
		getNextPageParam: (lastPage) => {
			if (inventoryItemIds.length === 0) {
				return undefined;
			}
			const nextPage = lastPage.pagination.page + 1;
			return nextPage <= lastPage.pagination.totalPages ? nextPage : undefined;
		},
		enabled: limit > 0,
	});

	const loadMore = () => {
		if (hasNextPage) {
			fetchNextPage();
		}
	};

	const items = useMemo(() => {
		if (inventoryItemIds.length === 0) {
			return undefined;
		}
		return data && data.pages && data.pages.length > 0 && data.pages.every((el) => el !== undefined)
			? data.pages.flatMap((page) => page.data)
			: undefined;
	}, [data, inventoryItemIds.length]);

	const totalItemAmount =
		data && data.pages && data.pages.length > 0 && data.pages[0] ? data?.pages[0].totalItemAmount : 0;

	return {
		data: items,
		isLoading,
		loadMore,
		isFetchingNextPage,
		hasNextPage,
		totalItemAmount,
	};
}
