/* eslint-disable @typescript-eslint/no-explicit-any */
export function updateHistoryState(newState: any) {
	const currentState = window.history.state || {};
	const updatedState = { ...currentState, ...newState };
	const currentTitle = document.title;
	const currentUrl = window.location.href;

	window.history.replaceState(updatedState, currentTitle, currentUrl);
}

export function pushHistoryState(newState: any) {
	const currentState = window.history.state || {};
	const updateState = { ...currentState, ...newState };

	window.history.pushState(updateState, document.title, window.location.href);
}
