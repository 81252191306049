import { IconCircleInfo } from 'assets/icons/IconCircleInfo';
import { CSSProperties, useState } from 'react';

interface Props {
	message: string;
	iconSize?: number;
	position?: 'top-center' | 'top-left' | 'top-right';
	style?: CSSProperties;
}

export function ToolTip({ message, iconSize = 15, position = 'top-center', style }: Props) {
	const [showToolTip, setShowToolTip] = useState(false);

	// Determine tooltip and triangle positions based on the `position` prop
	const tooltipPositionClasses = {
		'top-center': 'left-[50%] translate-x-[-50%]',
		'top-left': 'left-[-13px] translate-x-0',
		'top-right': 'right-[-13px] translate-x-0',
	}[position];

	const trianglePositionClasses = {
		'top-center': 'left-[50%] translate-x-[-50%]',
		'top-left': 'left-[10px] translate-x-0',
		'top-right': 'right-[10px] translate-x-0',
	}[position];

	return (
		<div
			className="relative w-fit h-fit"
			style={style}
			onMouseEnter={() => setShowToolTip(true)}
			onMouseLeave={() => setShowToolTip(false)}
		>
			{showToolTip && (
				<div
					className={`min-w-[100px] text-center w-fit absolute z-10 top-[-10px] translate-y-[-100%] bg-mrLootBlue text-white text-[9px] border-[2px] border-white rounded-[8px] ${tooltipPositionClasses}`}
				>
					<div className="relative z-10 bg-mrLootBlue h-full w-full p-[6px] rounded-[8px]">{message}</div>
					<div
						className={`absolute bottom-[-5px] rotate-45 w-[16px] h-[16px] border-[2px] border-white bg-mrLootBlue ${trianglePositionClasses}`}
					></div>
				</div>
			)}
			<IconCircleInfo width={iconSize} height={iconSize} />
		</div>
	);
}
