import React, { forwardRef } from 'react';

interface Props {
	label?: string;
	error?: string;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onBlur: () => void;
	value: string;
	name: string;
}

export const EmailInput = forwardRef<HTMLInputElement, Props>(
	({ label = 'Email', error, onChange, onBlur, value, name }, ref) => {
		return (
			<div className="flex flex-col space-y-1">
				<label className="text-sm">{label}</label>
				<input
					placeholder="example@gmail.com"
					type="email"
					onChange={onChange}
					onBlur={onBlur}
					value={value || ''}
					name={name}
					ref={ref}
					className={`rounded-md bg-white px-6 py-3 text-sm border-[2px] ${
						error ? 'border-errorRed' : 'border-white hover:border-blue300 focus-visible:border-blue400'
					} focus-visible:outline-none`}
				/>
				{error && <span className="text-errorRed text-sm">{error}</span>}
			</div>
		);
	}
);
