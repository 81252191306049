import { LoadingScreen } from 'components/layout/LoadingScreen';
import { useUserContext, useWindowDimensions } from 'hooks';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useAppStore } from 'store/useAppStore';
import { generateUniqueId, isMobile } from 'utils';
import { shallow } from 'zustand/shallow';

export function SuccesfulLogin() {
	const navigate = useNavigate();

	const lastLocationBeforeLogin = useAppStore((state) => state.lastLocationBeforeLogin, shallow);
	const { screenWidth } = useWindowDimensions();

	const { user, load: reloadUser } = useUserContext();

	useEffect(() => {
		if (!user) {
			return;
		}

		// GTM Tracking: Push the login event into the dataLayer
		if (window.dataLayer) {
			// Retrieve UTM parameters from localStorage
			const utmSource = localStorage.getItem('utm_source') || 'direct'; // Default to 'direct' if not available
			const utmMedium = localStorage.getItem('utm_medium') || 'none';
			const utmCampaign = localStorage.getItem('utm_campaign') || 'none';

			// Push the login event with UTM parameters
			window.dataLayer.push({
				event: 'login',
				user_mail: user?.contactMail?.value ?? user?.email ?? '',
				login_id: generateUniqueId(),
				utm_source: utmSource,
				utm_medium: utmMedium,
				utm_campaign: utmCampaign,
			});
		}

		// Navigate to the last location or home
		if (lastLocationBeforeLogin) {
			let state = undefined;
			const fakeHomelocation = {
				hash: '',
				key: '',
				pathname: '/',
				search: '',
				state: null,
			};

			if (lastLocationBeforeLogin.includes('boxes/open') && isMobile(screenWidth)) {
				state = {
					background: fakeHomelocation,
				};
			}
			navigate(lastLocationBeforeLogin, { state: state });
		} else {
			navigate('/');
		}

		// Reload user after 5 seconds
		const reloadTimeout = setTimeout(() => {
			reloadUser();
		}, 5000);

		// Clear timeout if the component unmounts
		return () => clearTimeout(reloadTimeout);
	}, [lastLocationBeforeLogin, navigate, screenWidth, user, reloadUser]);

	return <LoadingScreen />;
}

/*
# login check if oauth account or not and corresponding error msg
# error msg on oauth fail
# password reset check
# mail verifizierung
# facebook login
# 
*/
