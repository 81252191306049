import { CSSProperties } from 'react';

const CIRCLE_LIST = [
	{
		top: '-6vh',
		right: '-15vw',
		height: '20vh',
		width: '20vh',
	},
	{
		top: '32vh',
		right: '-2.5vh',
		height: '4vh',
		width: '4vh',
	},
	{
		top: '9vh',
		left: '-4vh',
		height: '9vh',
		width: '9vh',
	},
	{
		bottom: '-3vh',
		left: '-7vh',
		height: '17vh',
		width: '17vh',
	},
];

export function BackgroundWhiteCircles() {
	return (
		<>
			{CIRCLE_LIST.map((style, index) => (
				<WhiteCircle key={index} style={style} />
			))}
			<WhiteCircle style={{}} />
		</>
	);
}

interface Props {
	style: CSSProperties;
}

const WhiteCircle = ({ style }: Props) => (
	<div
		className="absolute rounded-full opacity-30 border-[1px] border-white flex items-center justify-center"
		style={style}
	>
		<div className="w-[80%] h-[80%] bg-white rounded-full" />
	</div>
);
