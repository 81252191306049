import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
	onClick: () => void;
}

export function PrevBtn({ onClick }: Props) {
	return (
		<button
			onClick={onClick}
			className="rounded-full z-30 bg-[#28262D] w-[40px] h-[40px] absolute left-2 top-1/2 -translate-y-1/2 text-[rgba(255,255,255,0.7)] hover:text-white hover:scale-110"
		>
			<FontAwesomeIcon icon={faChevronLeft} />
		</button>
	);
}
