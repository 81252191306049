import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface Props {
	text?: string;
	style?: React.CSSProperties;
	type?: 'button' | 'submit' | 'reset';
	disabled?: boolean;
	onClick?: () => void;
	isLoading?: boolean;
}

export function ModernPrimaryBtn({ text, style, type = 'button', disabled = false, onClick, isLoading }: Props) {
	return (
		<button
			onClick={onClick}
			disabled={disabled || isLoading}
			type={type}
			className={`${disabled && 'opacity-60'} flex-shrink-0 flex items-center justify-center w-full h-[47px] bg-mrLootBlue shadow-btnSmInset text-white font-bold text-[19px] tracking-[0.09rem] rounded-[10px]`}
			style={{ boxShadow: 'inset 2px 2px 2px #2981E9', ...style }}
		>
			{isLoading && <FontAwesomeIcon icon={faSpinner} />}
			{!isLoading && text}
		</button>
	);
}
