import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ItemOption } from 'interfaces/ItemInterfaces';
import { SelectedItemOption } from 'pages/checkout/InterfacesCheckout';
import { useCheckoutStore } from 'pages/checkout/store/CheckoutStore';
import { useCallback, useState } from 'react';

import { ItemOptionDropdownOverlay } from './ItemOptionDropdownOverlay';

interface Props {
	itemId: string;
	selectedOption: SelectedItemOption;
	availableOptions?: ItemOption[];
	hasError?: boolean;
	index: number;
}

export function MobileItemOptionDropdown({ availableOptions, selectedOption, hasError, itemId, index }: Props) {
	const matchingAvailableOption = availableOptions?.find(
		(option) => option.optionProperty === selectedOption.optionProperty
	);

	const { updateSelectedProductOptions } = useCheckoutStore();

	const [isOverlayVisible, setIsOverlayVisible] = useState(false);

	const handleClose = useCallback(() => {
		setIsOverlayVisible(false);
	}, []);

	return (
		<>
			<button
				onClick={() => setIsOverlayVisible(true)}
				className={`relative w-[110px] h-[55px] ${hasError ? 'border-errorRed' : 'border-white'} border-[1px] rounded-[4px] flex items-center px-[12px]`}
			>
				<span
					className={`text-[15px] line-clamp-1 mx-auto font-semibold ${selectedOption.value.length === 0 ? 'text-[rgba(255,255,255,0.6)]' : 'text-white'}`}
				>
					{selectedOption.value.length === 0 ? 'Select' : selectedOption.value}
				</span>
				<FontAwesomeIcon icon={faChevronDown} className=" text-mrLootBlue ml-auto" />
			</button>

			{isOverlayVisible && (
				<ItemOptionDropdownOverlay
					selectedOption={selectedOption.value}
					heading={selectedOption.label}
					availableOptions={matchingAvailableOption?.values}
					onChange={(newValue) => updateSelectedProductOptions(itemId, { ...selectedOption, value: newValue }, index)}
					onClose={handleClose}
				/>
			)}
		</>
	);
}
