import { ItemOption } from 'interfaces/ItemInterfaces';

interface Props {
	option: ItemOption;
	onChange: (value: string) => void; // Added onChange prop to handle selection
	selectedValue?: string; // To track which value is currently selected
}

export function ProductOptionInput({ option, onChange, selectedValue }: Props) {
	return (
		<div className="flex gap-[12px] flex-wrap">
			{option.values.map((optionValue) => (
				<button
					type="button"
					key={optionValue}
					onClick={() => onChange(optionValue)} // Call onChange when a value is clicked
					className={`min-w-[45px] relative text-[14px] font-bold rounded-full h-[45px] px-[14px] ${
						selectedValue === optionValue
							? 'bg-mrLootBlue text-white' // Highlight selected option
							: 'bg-[rgba(255,255,255,0.2)] text-[#ece2e299]'
					} shadow-btnSm`}
				>
					{optionValue}
				</button>
			))}
		</div>
	);
}
