import { ItemRarity } from 'interfaces/ItemInterfaces';
import { useInventorySummary } from 'pages/inventory/hooks/useInventorySummary';

import OwnedRarities from './OwnedRarities';
import { SelectedItemsList } from './SelectedItemsList';

interface Props {
	excludedRarities: ItemRarity[];
	selectedItemIds: Set<string>;

	onExcludedRarityChange: (rarities: ItemRarity[]) => void;
	onSelectedItemIdsChange: (id: Set<string>) => void;
}

export default function Sidebar({
	excludedRarities,
	selectedItemIds,

	onExcludedRarityChange,
	onSelectedItemIdsChange,
}: Props) {
	const { data: inventorySummary } = useInventorySummary();

	return (
		<div className="sticky flex flex-col right-0 top-[91px] w-[275px] h-[calc(100vh-85px)] gap-y-3">
			<div className="border-[2px] border-darkBlue outline outline-white outline-[2px] bg-secondary-gradient flex items-center justify-between w-full h-[38px] px-3 text-white rounded-[20px] shadow-[0px_4px_4px_rgba(0,0,0,0.25)]">
				<h3 className="font-semibold text-[14px]">Total Products</h3>
				<span className="font-semibold text-[14px]">{(inventorySummary?.totalItems ?? 0).toLocaleString('fr-FR')}</span>
			</div>

			<OwnedRarities
				excludedRarities={excludedRarities}
				onExcludedRarityChange={onExcludedRarityChange}
				totalOwnedRarities={inventorySummary?.totalRarities}
			/>

			<SelectedItemsList itemIds={selectedItemIds} onItemIdsChange={onSelectedItemIdsChange} />
		</div>
	);
}
