import { CryptoPayCurrency } from './deposit.interface';

export enum PaymentMethod {
	APPLE_GOOGLE_PAY = 'AppleGooglePay',
	CREDIT_CARD = 'CreditCard',
	CRYPTO = 'Crypto',
	GIFT_CARD = 'GiftCard',
}

export const networkOptions: Record<string, CryptoPayCurrency[]> = {
	usdt: [CryptoPayCurrency.USDT, CryptoPayCurrency.USDT_TRC20, CryptoPayCurrency.USDT_SOL], // Tether on Omni, Tron, and Solana
	usdc: [CryptoPayCurrency.USDC, CryptoPayCurrency.USDC_TRC20, CryptoPayCurrency.USDC_SOL], // USD Coin on Ethereum, Tron, and Solana
};

export const networkLabels: Partial<Record<CryptoPayCurrency, string>> = {
	[CryptoPayCurrency.USDT]: 'Omni Layer (Bitcoin)',
	[CryptoPayCurrency.USDT_TRC20]: 'TRC20 (Tron)',
	[CryptoPayCurrency.USDT_SOL]: 'Solana',
	[CryptoPayCurrency.USDC]: 'ERC20 (Ethereum)',
	[CryptoPayCurrency.USDC_TRC20]: 'TRC20 (Tron)',
	[CryptoPayCurrency.USDC_SOL]: 'Solana',
};

export const networkNames: Record<CryptoPayCurrency, string> = {
	[CryptoPayCurrency.BTC]: 'Bitcoin Network',
	[CryptoPayCurrency.ETH]: 'Ethereum Network',
	[CryptoPayCurrency.USDT]: 'Omni Layer (Bitcoin)', // Default for Omni Layer
	[CryptoPayCurrency.USDT_TRC20]: 'TRC20 Network (Tron)',
	[CryptoPayCurrency.USDT_SOL]: 'Solana Network',
	[CryptoPayCurrency.DOGE]: 'Dogecoin Network',
	[CryptoPayCurrency.SOL]: 'Solana Network',
	[CryptoPayCurrency.BNB]: 'Binance Smart Chain (BSC)',
	[CryptoPayCurrency.XRP]: 'Ripple Network',
	[CryptoPayCurrency.MATIC]: 'Polygon Network',
	[CryptoPayCurrency.USDC]: 'ERC20 Network (Ethereum)',
	[CryptoPayCurrency.USDC_TRC20]: 'TRC20 Network (Tron)',
	[CryptoPayCurrency.USDC_SOL]: 'Solana Network',
	[CryptoPayCurrency.LTC]: 'Litecoin Network',
	[CryptoPayCurrency.BITCOIN_CASH]: 'Bitcoin Cash Network',
	[CryptoPayCurrency.SHIBA]: 'Ethereum Network (ERC20)',
	[CryptoPayCurrency.TRON]: 'Tron Network',
	[CryptoPayCurrency.MONERO]: 'Monero Network',
};

// Mapping for full cryptocurrency names
export const currencyFullNames: Record<CryptoPayCurrency, string> = {
	[CryptoPayCurrency.BTC]: 'Bitcoin',
	[CryptoPayCurrency.ETH]: 'Ethereum',
	[CryptoPayCurrency.USDT]: 'Tether',
	[CryptoPayCurrency.USDT_TRC20]: 'Tether',
	[CryptoPayCurrency.USDT_SOL]: 'Tether',
	[CryptoPayCurrency.DOGE]: 'Dogecoin',
	[CryptoPayCurrency.SOL]: 'Solana',
	[CryptoPayCurrency.BNB]: 'Binance Coin',
	[CryptoPayCurrency.XRP]: 'Ripple',
	[CryptoPayCurrency.MATIC]: 'Polygon',
	[CryptoPayCurrency.USDC]: 'USD Coin',
	[CryptoPayCurrency.USDC_TRC20]: 'USD Coin',
	[CryptoPayCurrency.USDC_SOL]: 'USD Coin',
	[CryptoPayCurrency.LTC]: 'Litecoin',
	[CryptoPayCurrency.BITCOIN_CASH]: 'Bitcoin Cash',
	[CryptoPayCurrency.SHIBA]: 'Shiba Inu',
	[CryptoPayCurrency.TRON]: 'Tron',
	[CryptoPayCurrency.MONERO]: 'Monero',
};
