import { useWindowDimensions } from 'hooks';
import { isMobile } from 'utils';

import { DesktopCartPopup } from './DesktopCartPopup';
import { MobileCartPopup } from './MobileCartPopup';

export function CartPopup() {
	const { screenWidth } = useWindowDimensions();

	return (
		<>
			{isMobile(screenWidth) && <MobileCartPopup />}
			{!isMobile(screenWidth) && <DesktopCartPopup />}
		</>
	);
}
