import axios from 'axios';
import { DBOrderReturnsWithPopulatedItems } from 'interfaces/OrderReturns.interface';
import { useQuery } from 'react-query';

import { API_DOMAIN, QUERY_KEYS } from '../../../constants';

async function fetchOrders(orderId: string) {
	try {
		const response = await axios.post(
			`${API_DOMAIN.mrlootBackend}/api/order-returns/find-by-order`,
			{ orderId },
			{ withCredentials: true }
		);

		if (response.data.status === 'success') {
			return response.data.data;
		}
	} catch (err) {
		console.error(err);
		throw err;
	}
}

interface Props {
	orderId: string;
}

export function useReturnOrdersByOrder({ orderId }: Props) {
	return useQuery<DBOrderReturnsWithPopulatedItems[]>({
		queryKey: [QUERY_KEYS.returnOrderData, orderId],
		queryFn: () => fetchOrders(orderId),
	});
}
