import { ErrorMrloot } from 'assets/images';
import { SecondaryBtn } from 'components/common/buttons/SecondaryBtn';
import { FallbackProps } from 'react-error-boundary';

export function ErrorFallback({ error }: FallbackProps) {
	const navigateHome = () => {
		window.location.href = '/';
	};

	return (
		<div className="w-screen h-dvh flex flex-col gap-y-[20px] text-white items-center justify-center">
			<img src={ErrorMrloot} className="h-[200px] object-contain w-auto" alt="" />
			<h1 className="font-bold">Oops! Something went wrong.</h1>
			<p>{error.message}</p>
			<div>
				<SecondaryBtn onClick={navigateHome} content="Go To Home" />
			</div>
		</div>
	);
}
