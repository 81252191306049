import { ItemCard } from 'components';
import { PrizeInterface } from 'interfaces/ItemInterfaces';
import React, { memo } from 'react';

interface Props {
	prizes?: PrizeInterface[] | null;
	onImageLoad: () => void;
	onImageError: () => void;
}

const PrizeCardListComponent: React.FC<Props> = ({ prizes, onImageError, onImageLoad }) => {
	return (
		<>
			{prizes?.map((prize) => (
				<ItemCard
					key={prize.key}
					item={prize.data}
					style={{ border: 'none', height: '100%' }}
					onImageLoad={onImageLoad}
					onImageError={onImageError}
				/>
			))}
		</>
	);
};

export const PrizeCardList = memo(PrizeCardListComponent);

PrizeCardList.displayName = 'PrizeCardList';
