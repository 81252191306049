import axios from 'axios';
import { OrderDbInterface, PublicOrderStatus } from 'interfaces/OrderInterfaces';
import { useQuery } from 'react-query';

import { API_DOMAIN, QUERY_KEYS } from '../../../constants';
import { UIOrderStatus } from '../ui-order.interface';

const statusMapping: Record<UIOrderStatus, PublicOrderStatus[]> = {
	[UIOrderStatus.TO_RECEIVE]: [PublicOrderStatus.IN_PROCESS, PublicOrderStatus.SHIPPED],
	[UIOrderStatus.COMPLETED]: [PublicOrderStatus.DELIVERED],
	[UIOrderStatus.CANCELLED]: [PublicOrderStatus.CANCELLED],
};

async function fetchOrders(status: UIOrderStatus) {
	try {
		const publicStatuses = statusMapping[status];

		const response = await axios.post(
			`${API_DOMAIN.mrlootBackend}/api/order/list/by-public-statuses`,
			{ statuses: publicStatuses },
			{ withCredentials: true }
		);

		if (response.data.status === 'success') {
			return response.data.data;
		}
	} catch (err) {
		console.error(err);
		throw err;
	}
}

interface Props {
	status: UIOrderStatus;
}

export function useOrdersByStatus({ status }: Props) {
	return useQuery<OrderDbInterface[]>({
		queryKey: [QUERY_KEYS.orderData, status, 'orders-by-status'],
		queryFn: () => fetchOrders(status),
	});
}
