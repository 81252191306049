import { WithPageTitle } from 'components/misc/WithPageTitle';
import { DesktopCheckout } from 'pages/checkout/DesktopCheckout';
import { MobileCartReview } from 'pages/checkout/mobile/cart-review/MobileCartReview';
import { ShippingDetails } from 'pages/checkout/mobile/shipping-details/ShippingDetails';
import { RouteObject } from 'react-router-dom';

export const mobileCheckoutRoutes: RouteObject[] = [
	{
		index: true,
		element: (
			<WithPageTitle title="Mobile Checkout - Complete Your Purchase | MrLoot">
				<MobileCartReview />
			</WithPageTitle>
		),
	},
	{
		path: 'cart-review',
		element: (
			<WithPageTitle title="Cart Review - Review Your Items | MrLoot">
				<MobileCartReview />
			</WithPageTitle>
		),
	},
	{
		path: 'shipping-details',
		element: (
			<WithPageTitle title="Shipping Details - Enter Your Information | MrLoot">
				<ShippingDetails />
			</WithPageTitle>
		),
	},
];

export const desktopCheckoutRoutes: RouteObject[] = [
	{
		index: true,
		element: (
			<WithPageTitle title="Desktop Checkout - Complete Your Purchase | MrLoot">
				<DesktopCheckout />
			</WithPageTitle>
		),
	},
];
