import { create } from 'zustand';
import { persist, PersistOptions } from 'zustand/middleware';

interface AuthenticationStore {
	emailForPasswordReset: string | null;
	verificationCodeForPasswordReset: string | null;
	setEmailForPasswordReset: (value: string) => void;
	setVerificationCodeForPasswordReset: (value: string) => void;
}

const storage: PersistOptions<AuthenticationStore>['storage'] = {
	getItem: (name) => {
		const item = sessionStorage.getItem(name);
		return item ? JSON.parse(item) : null;
	},
	setItem: (name, value) => {
		sessionStorage.setItem(name, JSON.stringify(value));
	},
	removeItem: (name) => {
		sessionStorage.removeItem(name);
	},
};

export const useAuthenticationStore = create<AuthenticationStore>()(
	persist(
		(set) => ({
			emailForPasswordReset: null,
			verificationCodeForPasswordReset: null,
			setEmailForPasswordReset: (value: string) => set({ emailForPasswordReset: value }),
			setVerificationCodeForPasswordReset: (value: string) => set({ verificationCodeForPasswordReset: value }),
		}),
		{
			name: 'authentication-store',
			storage,
		}
	)
);
