import axios from 'axios';
import { BoxInterface } from 'interfaces/BoxInterface';
import { useQuery } from 'react-query';

import { API_DOMAIN, FIVE_MINUTES_IN_MILLISECONDS, QUERY_KEYS } from '../../../constants';

const fetchBoxes = async (itemId: string, excludedBoxId?: string) => {
	try {
		const response = await axios.post(
			`${API_DOMAIN.mrlootBackend}/api/boxes/find-boxes-containing-item`,
			{ itemId: itemId, boxIdentifier: excludedBoxId, boxlimit: 3 },
			{ withCredentials: true }
		);
		return response.data.data;
	} catch (err) {
		console.error(err);
	}
};

interface Props {
	itemId: string;
	excludedBoxId?: string;
}

export function useFindBoxesByItem({ itemId, excludedBoxId }: Props) {
	return useQuery<BoxInterface[]>({
		queryKey: [QUERY_KEYS.boxData, itemId],
		queryFn: () => fetchBoxes(itemId, excludedBoxId),
		cacheTime: FIVE_MINUTES_IN_MILLISECONDS,
		staleTime: FIVE_MINUTES_IN_MILLISECONDS,
	});
}
