import axios from 'axios';
import { API_DOMAIN } from '../../../constants';
import { useMutation } from 'react-query';

interface Props {
	email: string;
	password: string;
	code: string;
}

async function requestPasswordReset({ email, code, password }: Props) {
	try {
		const response = await axios.post(`${API_DOMAIN.mrlootBackend}/api/user/reset-password`, {
			email,
			code,
			password,
		});
		if (response.data.status !== 'success') {
			throw new Error(response.data.message || 'Something unexpected went wrong');
		}
		return response.data.codeExpirationTime;
	} catch (err) {
		if (axios.isAxiosError(err)) {
			const message = err.response?.data?.message || err.message || 'Something unexpected went wrong';
			throw new Error(message);
		}
		throw err;
	}
}

interface CallbackProps {
	onSuccess?: (codeExpirationTime: Date) => void;
	onError?: (message: string) => void;
}

export function useResetPassword({ onSuccess, onError }: CallbackProps = {}) {
	return useMutation((props: Props) => requestPasswordReset(props), {
		onSuccess: onSuccess,
		onError: (err: Error) => {
			onError && onError(err.message || 'Something unexpected went wrong');
		},
	});
}
