import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconPassword } from 'assets/icons/IconPassword';
import { useNavigate } from 'react-router-dom';
import { useAppStore } from 'store/useAppStore';
import { shallow } from 'zustand/shallow';

export function PasswordChangeLink() {
	const navigate = useNavigate();

	const setShowBackBtnInTopBar = useAppStore((state) => state.setShowBackBtnInTopBar, shallow);

	function handleLinkClick() {
		setShowBackBtnInTopBar(true);
		navigate('change-password');
	}

	return (
		<button
			onClick={handleLinkClick}
			className={`text-black w-full h-[53px] flex items-center justify-between pl-[18px] pr-[8px] text-[14px] bg-white rounded-[15px]`}
		>
			<div className="flex items-center gap-x-[12px]">
				<IconPassword />
				<span className="font-bold">Change Password</span>
			</div>
			<div className="rounded-full flex items-center justify-center h-[36px] w-[36px]">
				<FontAwesomeIcon icon={faChevronRight} className="text-[20px]" />
			</div>
		</button>
	);
}
