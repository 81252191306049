import { faCartShopping } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AmountDisplayWithCurrency } from 'components/common/AmountDisplayWithCurrency';
import { MobileCloseBtn } from 'components/common/buttons/MobileCloseBtn';
import { PrimaryBtn } from 'components/common/buttons/PrimaryBtn';
import { MobileBackground } from 'components/layout/background/MobileBackground';
import { useOutsideClick, useScrollLock, useUserContext } from 'hooks';
import { convertItemOptionsForCheckout } from 'pages/Shop/util';
import { useRef } from 'react';
import { useNavigate } from 'react-router';

import { useMerchStore } from '../../MerchStore';
import { CartProductCard } from '../CartProductCard';
import { EmptyCart } from './EmptyCart';

export function MobileCartPopup() {
	const { products, setShowCartPopup } = useMerchStore();

	useScrollLock();

	const totalValue = products.map((el) => el.quantity * el.item.price).reduce((a, b) => a + b, 0);

	const popupRef = useRef<HTMLDivElement>(null);

	useOutsideClick(popupRef, () => setShowCartPopup(false));

	const { user } = useUserContext();
	const navigate = useNavigate();

	function handleCheckout() {
		if (!user) {
			navigate('/auth/login');
			return;
		}
		const checkoutItems = products.map((product) => {
			const itemOptions = convertItemOptionsForCheckout(product.item, product.options, product.quantity);
			return {
				item: product.item,
				quantity: product.quantity,
				selectedOptions: itemOptions,
				availableInventoryItemIds: [],
				selectedInventoryItemIds: [],
			};
		});

		navigate('/checkout', {
			state: { directPurchaseItems: checkoutItems },
		});
	}

	return (
		<div className="fixed z-40 inset-0 bg-[rgba(0,0,0,0.2)] backdrop-blur-[2px] w-screen h-dvh pl-[40px] flex justify-end">
			<div ref={popupRef} className="h-full w-full flex flex-col max-w-[450px]">
				<MobileBackground style={{ position: 'relative', height: '100dvh' }}>
					<MobileCloseBtn onClose={() => setShowCartPopup(false)} />
					<div className="flex items-center text-white text-[20px] gap-x-[15px] mx-[20px] mt-[20px] pb-[10px]">
						<FontAwesomeIcon icon={faCartShopping} />
						<h1 className="text-white font-bold ">My Cart</h1>
					</div>

					<div className="max-h-[calc(100%-193px)] h-[calc(100dvh-193px)] overflow-y-auto px-[20px] pb-[20px]">
						<div className="flex flex-col gap-y-[25px] mt-[20px]">
							{products.map((product) => (
								<CartProductCard key={product._id} product={product} />
							))}
						</div>
						{products.length === 0 && <EmptyCart />}
					</div>

					<div className="px-[20px] h-[132px] py-[10px] w-full gap-y-[8px] flex flex-col bg-white text-black">
						<div className="w-full flex justify-between">
							<h2 className="font-bold text-[18px] ">SUBTOTAL</h2>
							<AmountDisplayWithCurrency
								amount={totalValue}
								imgStyle={{ height: '17px' }}
								amountStyle={{ fontSize: '17px', color: 'black', fontWeight: 'bold' }}
							/>
						</div>
						<span className=" text-[12px] font-semibold mb-[4px] ">Shipping calculated at checkout</span>
						<PrimaryBtn
							onClick={handleCheckout}
							content="Checkout"
							style={{ filter: 'drop-shadow(0 0 4px rgba(41, 129, 233, 0.5))' }}
						/>
					</div>
				</MobileBackground>
			</div>
		</div>
	);
}
