import { WithPopup } from 'components/common/popups/WithPopup';
import { useBoxOpeningStoreMobile } from 'pages/OpenBox/store/useBoxOpeningStoreMobile';

// eslint-disable-next-line no-magic-numbers
const OPTIONS = [1, 3, 5, 10, 25, 50];

export function PopupSpinAmountChooser() {
	const { setIsSpinAmountChooserPopupVisible, setAutoSpinCount } = useBoxOpeningStoreMobile();

	function handleClick(amount: number) {
		setAutoSpinCount(amount);
		setIsSpinAmountChooserPopupVisible(false);
	}

	return (
		<WithPopup onClose={() => setIsSpinAmountChooserPopupVisible(false)} shouldCenter={true}>
			<div className="mx-mobile-side-padding grid grid-cols-3 border-[2px] border-mrLootBlue rounded-[42px] bg-[#101010] p-[18px] gap-y-[20px]">
				{OPTIONS.map((amount, index) => (
					<button
						onClick={() => handleClick(amount)}
						key={index}
						className="mx-auto text-white self-center text-[21px] xxs:text-[24px] font-bold rounded-[42px] border-[2px] border-white bg-mrLootBlue flex items-center justify-center h-[58px] w-[71px] xxs:h-[70px] xxs:w-[88px]"
						style={{
							boxShadow:
								' 3px 24px 88px rgba(168, 2, 11, 0.42), inset -4px -4px 4px rgba(255, 255, 255, 0.25), inset 4px 4px 4px rgba(0, 0, 0, 0.25)',
						}}
					>
						{amount}x
					</button>
				))}
			</div>
		</WithPopup>
	);
}
