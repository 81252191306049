import { DeliveryTruck, FastCar } from 'assets/images';
import { useCheckoutStore } from 'pages/checkout/store/CheckoutStore';

export function ShippingTimeSwitch() {
	const { isExpressShipping, setIsExpressShipping } = useCheckoutStore();

	return (
		<div className="flex items-center justify-between gap-[10px]">
			<button
				onClick={() => setIsExpressShipping(false)}
				className={`w-[calc(50%-5px)] h-[60px] flex items-center px-[8px] rounded-[4px] gap-[7px] border-[2px] ${isExpressShipping === false ? 'bg-mrLootBlue border-white' : 'bg-white border-mrLootBlue'}`}
			>
				<img src={DeliveryTruck} alt="" className="h-[30px] object-scale-down" />
				<span className={`text-[15px] ${isExpressShipping === false ? 'text-white' : 'text-mrLootBlue'} font-semibold`}>
					Free Shipping
				</span>
			</button>

			<button
				onClick={() => setIsExpressShipping(true)}
				className={`w-[calc(50%-5px)] h-[60px] flex items-center px-[8px] rounded-[4px] gap-[7px] border-[2px] ${isExpressShipping === true ? 'bg-mrLootBlue border-white' : 'bg-white border-mrLootBlue'}`}
			>
				<img src={FastCar} alt="" className="h-[32px] object-scale-down" />
				<span className={`text-[15px] ${isExpressShipping === true ? 'text-white' : 'text-mrLootBlue'} font-semibold`}>
					Express Shipping
				</span>
			</button>
		</div>
	);
}
