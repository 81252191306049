import { AmountDisplayWithCurrency } from 'components/common/AmountDisplayWithCurrency';
import { ToolTip } from 'components/common/ToolTip';
import {
	DBOrderReturnItemPopulated,
	OrderReturnItemStatus,
	orderReturnItemStatusLabels,
} from 'interfaces/OrderReturns.interface';
import { CSSProperties } from 'react';

import { CLOUDFRONT_IMG_URL, rarities } from '../../../../constants';
import { ItemOptionsDisplay } from '../OrderProductRow/ItemOptionsDisplay';

interface Props {
	item: DBOrderReturnItemPopulated;
	style?: CSSProperties;
}

const returnItemStatusTooltips: Record<OrderReturnItemStatus, string> = {
	[OrderReturnItemStatus.PENDING_APPROVAL]:
		'We’ve received your return request. Sit back and relax while we review your request. You will be notified shortly with the next steps.',
	[OrderReturnItemStatus.APPROVED]:
		'Great news! Your return has been approved. Please follow the provided instructions to return the item, or wait for further details on the next steps.',
	[OrderReturnItemStatus.COMPLETED]:
		'Your return process is now complete. Your refund has been processed or the item has been added back to your inventory. Thank you for your patience.',
	[OrderReturnItemStatus.CANCELLED]:
		'Your return request has been cancelled. No further action is required. If you have any questions, please feel free to contact our support team.',
};

export function ReturnProductRow({ item, style }: Props) {
	return (
		<div
			className={`flex flex-col gap-y-[10px] items-center justify-center px-[18px] py-[6px] rounded-[20px] min-h-[120px] w-full border-[1px] border-orange-500 `}
			style={style}
		>
			<div
				className={`flex items-center gap-x-[5px] mr-auto ${item.status === OrderReturnItemStatus.COMPLETED ? 'text-green-500' : item.status === OrderReturnItemStatus.CANCELLED ? 'text-orange-500' : 'text-white'}`}
			>
				<ToolTip message={returnItemStatusTooltips[item.status]} iconSize={18} />
				<span className=" text-[14px] font-semibold">{orderReturnItemStatusLabels[item.status]}</span>
			</div>
			<div
				className={`grid grid-cols-[75px_auto] md:grid-cols-[90px_auto] items-center justify-start w-full gap-x-[10px] md:gap-x-[25px] lg:gap-x-[25px] text-white text-[13px] `}
			>
				<div className="relative w-fit h-fit mx-auto">
					<img
						src={`${CLOUDFRONT_IMG_URL}/${item.itemId?.imageUrls.qualityMedium}`}
						alt={item.itemId.name}
						className="h-[40px] md:h-[55px] w-auto object-scale-down max-w-[60px] md:max-w-[75px] relative z-10"
					/>
					<div
						className="absolute z-0 left-[50%] translate-x-[-50%] top-[50%] translate-y-[-50%] h-[50px] w-[50px] blur-[25px] rounded-full"
						style={{ backgroundColor: rarities[item.itemId?.rarity]?.color }}
					/>
				</div>
				<div className="flex flex-col items-start gap-y-[5px]">
					<div className="flex items-center gap-x-[4px] text-white min-w-fit">
						<AmountDisplayWithCurrency
							amount={(item.userAvgPurchaseValue ?? item.itemId.price) * item.quantity}
							imgStyle={{ height: '13px' }}
							amountStyle={{ fontSize: '13px' }}
						/>
					</div>

					<h2 className="text-white font-semibold line-clamp-1">{item.itemId?.name}</h2>
					<span className="flex items-center justify-center font-semibold h-[18px] w-[27px] rounded-full bg-white text-mrLootBlue text-[12px]">
						x{item.quantity}
					</span>

					{item.options && item.options.length > 0 && <ItemOptionsDisplay options={item.options} />}
				</div>
			</div>
		</div>
	);
}
