import { PickSlotBorder, PickSlotInlay, SlotLeft, SlotRight } from 'assets/images';
import { useWindowDimensions } from 'hooks';
import { useFallbackIfCqhUnsupported } from 'hooks/utility/useFallbackIfCqhUnsupported';
import { useImageLoadingProgress } from 'hooks/utility/useImageLoadingProgress';
import { CSSProperties, forwardRef, ReactNode, useImperativeHandle, useRef } from 'react';

import { screens } from '../../../../constants';

interface Props {
	children: ReactNode;
	onCompleteLoading: () => void;
	onTotalFailure: () => void;
	isSpinFrozen: boolean;
	style?: CSSProperties;
}

const getSlotHeight = (screenWidth: number) => {
	if (screenWidth > screens.lg.width) {
		return '530px';
	} else if (screenWidth > screens.sm.width) {
		return '380px';
	} else if (screenWidth > screens.xs.width) {
		return '320px';
	}
	return '250px';
};

export const WithSlotImages = forwardRef<HTMLImageElement, Props>(
	({ children, onTotalFailure, onCompleteLoading, isSpinFrozen, style }, forwardRef) => {
		const parentRef = useRef<HTMLDivElement>(null);
		const slotLeftRef = useRef<HTMLImageElement>(null);
		const slotRightRef = useRef<HTMLImageElement>(null);
		const slotPickerRef = useRef<HTMLImageElement>(null);
		const slotPickerInlayRef = useRef<HTMLImageElement>(null);
		const prizeContainerRef = useRef<HTMLDivElement>(null);

		useImperativeHandle(forwardRef, () => slotPickerRef.current!, [slotPickerRef]);

		useFallbackIfCqhUnsupported({
			parentRef,
			refs: [
				{ ref: slotLeftRef, proportion: 0.875, cssProperty: 'height' },
				{ ref: slotRightRef, proportion: 0.875, cssProperty: 'height' },
				{ ref: slotPickerRef, proportion: 1, cssProperty: 'height' },
				{ ref: slotPickerInlayRef, proportion: 0.9, cssProperty: 'height' },
				{ ref: prizeContainerRef, proportion: 0.78125, cssProperty: 'height' },
			],
		});

		const { screenHeight } = useWindowDimensions();
		const slotHeight = getSlotHeight(screenHeight);

		const { handleLoadFail, handleLoadSuccess } = useImageLoadingProgress({
			totalImages: 4,
			onCompleteLoading: onCompleteLoading,
			onTotalFailure: onTotalFailure,
			failThreshold: 0,
		});

		return (
			<div
				style={{ height: slotHeight, ...style }}
				ref={parentRef}
				className={`size-container relative grid grid-cols-[1fr_auto_1fr] items-start w-full `}
			>
				<img
					ref={slotLeftRef}
					src={SlotLeft}
					alt=""
					className={`h-[87.5cqh] z-10 self-center ${isSpinFrozen ? 'opacity-70' : 'opacity-100'}'`}
					style={{ transition: 'opacity 0.25s' }}
					onLoad={handleLoadSuccess}
					onError={handleLoadFail}
				/>
				<img
					ref={slotPickerRef}
					alt="slot picker"
					src={PickSlotBorder}
					onLoad={handleLoadSuccess}
					onError={handleLoadFail}
					className={`h-[100cqh] relative top-[7px] z-20 self-center ${isSpinFrozen ? 'opacity-60' : 'opacity-100'}`}
					style={{ transition: 'opacity 0.25s' }}
				/>
				<img
					ref={slotRightRef}
					src={SlotRight}
					alt="slot right"
					onLoad={handleLoadSuccess}
					onError={handleLoadFail}
					className={`h-[87.5cqh] z-10 self-center ${isSpinFrozen ? 'opacity-70' : 'opacity-100'}`}
					style={{ transition: 'opacity 0.25s' }}
				/>
				<img
					ref={slotPickerInlayRef}
					src={PickSlotInlay}
					onLoad={handleLoadSuccess}
					onError={handleLoadFail}
					alt="slot picker inlay"
					className="absolute left-[50%] translate-x-[-50%] top-[50%] translate-y-[-50%] z-10 h-[90cqh]"
				/>
				<div
					ref={prizeContainerRef}
					className="h-[78.125cqh] absolute z-10 top-[50%] translate-y-[-50%] left-0  w-full"
				>
					{children}
				</div>
			</div>
		);
	}
);
