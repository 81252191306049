import { Link } from 'react-router-dom';

export function RegisterBtn() {
	return (
		<Link
			to="/register"
			className="flex items-center justify-center py-[5px] px-[40px] text-white bg-mrLootBlue hover:bg-mrLootBlue700 text-[12px] font-[700] rounded-[24px] shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)]"
		>
			Register
		</Link>
	);
}
