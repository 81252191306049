import { MrLoot } from 'assets/images';
import { LoadSpinner } from 'components/common/loading/LoadSpinner';
import { useItemsByTags } from 'hooks/data/items/useItemsByTags';
import { ItemInterface } from 'interfaces/ItemInterfaces';
import { useEffect, useState } from 'react';

import { MerchStoreBg } from './assets';
import { CartPopup } from './components/CartPopup/CartPopup';
import { FilterOptionList } from './components/FilterOptionList';
import { FloatingCartBtn } from './components/FloatingCartBtn';
import { ProductCard } from './components/ProductCard';
import { useMerchStore } from './MerchStore';
import { FilterOptions, FilterOptionTags } from './shop.interfaces';

export function MerchShopGallery() {
	const [selectedFilter, setSelectedFilter] = useState<FilterOptions>(FilterOptions.NEW_ARRIVALS);

	const { data: merchItems, isLoading: isMerchItemsLoading } = useItemsByTags(FilterOptionTags[selectedFilter]);

	const { showCartPopup, products, setShowCartPopup } = useMerchStore();

	useEffect(() => {
		return () => setShowCartPopup(false);
	}, [setShowCartPopup]);

	const sortedMerchItems = merchItems
		? [...merchItems].sort((a, b) => {
				const getPriority = (item: ItemInterface) => {
					if (item.tags.includes('T-Shirt')) {
						return 1;
					}
					if (item.tags.includes('poster')) {
						return 2;
					}
					if (item.tags.includes('Sticker')) {
						return 2;
					}
					if (item.tags.includes('Cap')) {
						// eslint-disable-next-line no-magic-numbers
						return 3;
					}
					// eslint-disable-next-line no-magic-numbers
					return 4; // Default for other items
				};
				return getPriority(a) - getPriority(b);
			})
		: [];

	return (
		<>
			{showCartPopup && <CartPopup />}

			<div className="hidden absolute z-0 left-0 top-0 w-full h-fit">
				<img
					src={MerchStoreBg}
					alt=""
					className="w-full h-auto max-h-[400px] min-h-[320px] object-bottom object-cover"
				/>

				<div className="z-10 absolute bottom-0 left-0 w-full h-[50%] bg-gradient-to-t from-background to-transparent pointer-events-none"></div>
			</div>
			<div className="relative flex w-full">
				<div className="relative z-10 flex flex-col items-center text-white min-h-svh w-full">
					<h2 className="text-[13px] text-white font-semibold mb-[30px]">MrLoot Originals</h2>
					<h1 className="text-[34px] text-white font-bold mb-[20px]">Our Selection</h1>
					<img src={MrLoot} alt="" className="h-[130px] w-auto mb-[30px]" />
					<p className="text-[13px] text-[rgba(255,255,255,0.7] mb-[25px]">
						Discover our pieces crafted by MrLoot himself.
					</p>

					<FilterOptionList selectedFilter={selectedFilter} onChange={(filter) => setSelectedFilter(filter)} />

					<div className="grid grid-cols-2 ss:grid-cols-3 ms:grid-cols-4 md:grid-cols-5 xl:grid-cols-6 w-full gap-[30px] xs:gap-x-[50px] ss:gap-x-[30px] mt-[40px]">
						{sortedMerchItems?.map((el) => <ProductCard key={el._id} item={el} />)}
					</div>
					{isMerchItemsLoading && (
						<div className="mx-auto mt-[50px]">
							<LoadSpinner />
						</div>
					)}
					{products.length > 0 && <FloatingCartBtn />}
				</div>
			</div>
		</>
	);
}
