import { ItemRarity } from 'interfaces/ItemInterfaces';

import { rarities } from '../../../../../../../constants';

interface Props {
	rarity: ItemRarity;
}

export function BackgroundCircles({ rarity }: Props) {
	return (
		<>
			<div
				className={`absolute top-[-13vh] left-[-42vh] rounded-[50%] w-[79vh] h-[95vh] `}
				style={{ backgroundColor: rarities[rarity].color }}
			/>
			<div
				className={`absolute top-[-23vw] right-[-13vw] rounded-[50%] w-[60vw] h-[30vw] `}
				style={{ backgroundColor: rarities[rarity].color }}
			/>
			<div
				className={`absolute bottom-[-10vh] right-[-10vh] rounded-[50%] w-[45vh] h-[45vh] `}
				style={{ backgroundColor: rarities[rarity].color }}
			/>
		</>
	);
}
