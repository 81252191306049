import { faCircleInfo, faKey, faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OrderItemReferenceInfos } from 'interfaces/OrderInterfaces';

interface Props {
	referenceInfo: {
		value: string; // to store tracking link , game key
		type: OrderItemReferenceInfos;
	};
	onShowKeyPopup: (key: string) => void;
	onNotePopup: (text: string) => void;
}

export function ItemReferenceInfo({ referenceInfo, onShowKeyPopup, onNotePopup }: Props) {
	return (
		<>
			{referenceInfo.type === OrderItemReferenceInfos.DIGITAL && (
				<button
					onClick={() => referenceInfo && onShowKeyPopup(referenceInfo.value)}
					className="h-[35px] rounded-full w-[95px] flex items-center justify-evenly px-[6px] border-[2px] border-white shadow-[0_4px_5px_rgba(0,0,0,0.3)] text-white font-semibold text-[14px]"
					style={{ background: 'linear-gradient(331.41deg, #236DF4 6.78%, #33F0A7 204.87%)' }}
				>
					<FontAwesomeIcon icon={faKey} />
					<span className="font-semibold">Key</span>
				</button>
			)}
			{referenceInfo.type === OrderItemReferenceInfos.NOTE && (
				<button
					onClick={() => referenceInfo && onNotePopup(referenceInfo.value)}
					className="h-[35px] rounded-full w-[95px] flex items-center justify-evenly px-[6px] border-[2px] border-white shadow-[0_4px_5px_rgba(0,0,0,0.3)] text-white font-semibold text-[14px]"
					style={{ background: 'linear-gradient(331.41deg, #236DF4 6.78%, #33F0A7 204.87%)' }}
				>
					<FontAwesomeIcon icon={faCircleInfo} />
					<span className="font-semibold">Note</span>
				</button>
			)}
			{referenceInfo?.type === OrderItemReferenceInfos.TRACKING && referenceInfo.value && (
				<a
					target="_blank"
					href={`https://parcelsapp.com/en/tracking/${referenceInfo.value}`}
					className="h-[35px] rounded-full w-[95px] flex items-center justify-evenly px-[6px] border-[2px] border-white shadow-[0_4px_5px_rgba(0,0,0,0.3)] text-white font-semibold text-[14px]"
					style={{ background: 'linear-gradient(331.41deg, #236DF4 6.78%, #33F0A7 204.87%)' }}
					rel="noreferrer noopener"
				>
					<span className="font-semibold">Track</span>
					<FontAwesomeIcon icon={faUpRightFromSquare} />
				</a>
			)}
		</>
	);
}
