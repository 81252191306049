import { CheckBox } from 'components';
import { useBoxOpeningStoreDesktop } from 'pages/OpenBox/store/useBoxOpeningStoreDesktop';

export function FastOpenCheckbox() {
	const { isFastSpin, toogleIsFastSpin, isFullScreen } = useBoxOpeningStoreDesktop();

	return (
		<button
			onClick={toogleIsFastSpin}
			className={`font-semibold h-full text-[14px] overflow-hidden flex items-center justify-between rounded-[20px] text-white border-[2px] border-white space-x-2`}
			style={{
				background: isFullScreen
					? 'linear-gradient(331.41deg, rgba(35, 109, 244, 0.6) 6.78%, rgba(51, 240, 167, 0.6) 204.87%)'
					: 'linear-gradient(331.41deg, rgba(35, 109, 244, 0.3) 6.78%, rgba(51, 240, 167, 0.3) 204.87%)',
			}}
		>
			<div className="w-full h-full flex items-center justify-center gap-x-[8px] px-[12px] border-[2px] rounded-[17px] border-[#3559D7]">
				<CheckBox
					size={{ width: '15px', height: '15px' }}
					backgroundColor="#D9D9D9"
					borderWidth="0px"
					checked={isFastSpin}
					strokeColor="black"
					checkSize="14px"
				/>
				<span className="text-nowrap">Fast Open</span>
			</div>
		</button>
	);
}
