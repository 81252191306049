import { useUserContext } from 'hooks/context';
import { useEffect, useState } from 'react';
import { CurrencyOptions } from 'store/useAppStore';
import { convertToLooties } from 'utils';

interface Props {
	balanceRef: React.RefObject<HTMLButtonElement | HTMLDivElement>;
	baseWidth: number;
	currency: CurrencyOptions;
}

const CHARACTER_WIDTH = 9;

export function useDynamicBalanceWidth({ balanceRef, baseWidth, currency }: Props) {
	const { user } = useUserContext();
	const [lastBalance, setLastBalance] = useState(user?.balance || 0);

	const extraSpace = (() => {
		switch (currency) {
			case CurrencyOptions.BITCOIN:
				// eslint-disable-next-line no-magic-numbers
				return 25;
			case CurrencyOptions.ETHERIUM:
				// eslint-disable-next-line no-magic-numbers
				return 18;
			case CurrencyOptions.SOLANA:
				// eslint-disable-next-line no-magic-numbers
				return 12;
			case CurrencyOptions.DOGECOIN:
				// eslint-disable-next-line no-magic-numbers
				return 12;
			case CurrencyOptions.US_DOLLAR:
				// eslint-disable-next-line no-magic-numbers
				return 5;
			case CurrencyOptions.LOOTIES:
			default:
				return 0;
		}
	})();

	useEffect(() => {
		if (user && balanceRef.current) {
			const balanceToCalcWith = convertToLooties(Math.max(user.balance, lastBalance));
			const width = baseWidth + balanceToCalcWith.toString().length * CHARACTER_WIDTH + extraSpace;
			setLastBalance(user.balance);
			balanceRef.current.style.width = `${width}px`;
		}
	}, [balanceRef, baseWidth, lastBalance, user, extraSpace]);
}
