import { OrderDbInterface, OrderDbItemInterface } from 'interfaces/OrderInterfaces';

import { OrderItemActions } from './OrderItemActions';
import { OrderItemDetails } from './OrderItemDetails';

interface Props {
	item: OrderDbItemInterface;
	order: OrderDbInterface;
	onKeyPopup: (key: string) => void;
	onNotePopup: (text: string) => void;
}

export function OrderProductRow({ item, onKeyPopup, onNotePopup, order }: Props) {
	return (
		<div className="flex flex-col lg:grid lg:grid-cols-[auto_110px] gap-x-[20px] md:gap-x-[50px]">
			<OrderItemDetails item={item} />
			<OrderItemActions item={item} orderId={order._id} onKeyPopup={onKeyPopup} onNotePopup={onNotePopup} />
		</div>
	);
}
