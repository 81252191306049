import { IconEye } from 'assets/icons/IconEye';
import { IconEyeSlash } from 'assets/icons/IconEyeSlash';
import { useState } from 'react';
import { FieldErrors, FieldValues, Path, UseFormRegister } from 'react-hook-form';

interface Props<T extends FieldValues> {
	register: UseFormRegister<T>;
	errors: FieldErrors<T>;
	placeholder?: string;
	registerFieldName?: Path<T>;
}

export function MobilePasswordInput<T extends FieldValues>({
	register,
	errors,
	placeholder,
	registerFieldName,
}: Props<T>) {
	const [showPassword, setShowPassword] = useState(false);

	const usedRegisterFieldName = (registerFieldName ?? 'password') as Path<T>;

	return (
		<div className="w-full h-fit relative space-y-[8px]">
			<div className="relative">
				<input
					{...register(usedRegisterFieldName, {
						required: 'Password is required',
						minLength: {
							value: 8,
							message: 'Password must be at least 8 characters long',
						},
					})}
					placeholder={placeholder ?? '••••••••'}
					type={showPassword ? 'text' : 'password'}
					className="flex items-center font-semibold rounded-[10px] font-poppins bg-[#EAF0F7] pl-[12px] pr-[40px] text-[16px] text-[#4F555A] outline-none w-full h-[48px]"
				/>
				<button
					onClick={() => setShowPassword((prev) => !prev)}
					type="button"
					className="text-[#667085] hover:text-[#505868] absolute right-[12px] top-0 h-full flex items-center"
				>
					{showPassword ? <IconEyeSlash height={20} width={20} /> : <IconEye height={20} width={20} />}
				</button>
			</div>
			{(errors[usedRegisterFieldName as keyof T] as { message?: string })?.message && (
				<p className="text-mrLootBlue tracking-[0.02rem] text-[11px]">
					{(errors[usedRegisterFieldName as keyof T] as { message?: string })?.message}
				</p>
			)}
		</div>
	);
}
