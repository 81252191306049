import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AddAddressPopup } from 'components/common/popups/address-popup/AddAddressPopup';
import { useUserContext } from 'hooks';
import { ShippingAddressInterface } from 'interfaces/OrderInterfaces';
import { useState } from 'react';

import { DeliveryAddressDropdown } from './DeliveryAddressDropdown';

interface Props {
	selectedAddress?: ShippingAddressInterface;
	onSelectedAddressChange: (address: ShippingAddressInterface) => void;
}

export function ManageShippingAddress({ selectedAddress, onSelectedAddressChange }: Props) {
	const { user } = useUserContext();

	const [isAddAddressPopupVisible, setIsAddAddressPopupVisible] = useState(false);

	return (
		<>
			{isAddAddressPopupVisible && <AddAddressPopup onClose={() => setIsAddAddressPopupVisible(false)} />}

			<div className="text-white w-full py-[18px] border-[3px] rounded-[24px] shadow-[0_4px_4px_rgba(0,0,0,0.25)] border-white01 px-[25px]">
				<h1 className="mb-[40px] text-[24px]">1. Shipping</h1>
				<h2 className="text-[20px] mb-[24px] font-semibold">Delivery Adress</h2>
				<div className="flex flex-col gap-y-[20px]">
					<DeliveryAddressDropdown
						addresses={user?.addresses}
						selectedAddress={selectedAddress}
						onSelectedAddressChange={onSelectedAddressChange}
					/>
					<button
						onClick={() => setIsAddAddressPopupVisible(true)}
						className="h-[37px] w-[110px] text-[15px] border-[2px] border-darkBlue outline outline-white outline-[3px] bg-primary-gradient text-white font-bold grid grid-cols-[30px_auto_30px] px-[4px] items-center justify-items-center  rounded-[20px] focus-within:shadow-primaryInset"
					>
						<FontAwesomeIcon icon={faPlus} />
						<span className="text-center">Add</span>
					</button>
				</div>
			</div>
		</>
	);
}
