import { faShuffle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FontDesktop404, FontDontWorry, FontMobile404, MrLoot404 } from 'assets/images';
import { PrimaryBtn } from 'components/common/buttons/PrimaryBtn';
import { useWindowDimensions } from 'hooks';
import { useLoadTopBoxes } from 'pages/overview/hooks/useLoadTopBoxes';
import { useNavigate } from 'react-router';
import { getBoxLink } from 'utils';

import { screens } from '../../constants';

export function NotFound404() {
	const navigate = useNavigate();
	const { screenWidth } = useWindowDimensions();

	const { data: topBoxes } = useLoadTopBoxes();

	function handleRandomBoxNavigate() {
		if (topBoxes) {
			const randomIndex = Math.floor(Math.random() * topBoxes.length);
			const selectedBox = topBoxes[randomIndex];
			const randomBoxLink = getBoxLink(selectedBox.name);
			navigate(randomBoxLink);
		} else {
			navigate('/');
		}
	}

	return (
		<div className="flex flex-col items-center gap-y-[25px] sm:gap-[35px] px-mobile-side-padding pb-[40px] sm:pt-[40px]">
			<img src={MrLoot404} alt="" className="h-[180px] sm:h-[240px] lg:h-[280px] pointer-events-none" />
			<img
				src={screenWidth < screens.sm.width ? FontMobile404 : FontDesktop404}
				alt=""
				className="w-full max-h-[130px] object-contain pointer-events-none"
			/>
			{screenWidth >= screens.sm.width && (
				<img src={FontDontWorry} alt="" className="w-full max-h-[130px] object-contain pointer-events-none" />
			)}

			<div className="flex flex-col items-stretch w-full px-[15px] gap-y-[18px] sm:gap-y-[24px]">
				<PrimaryBtn
					style={{ height: screenWidth < screens.sm.width ? '34px' : '45px' }}
					onClick={() => navigate('/')}
					content={<span className="font-bold text-[15px] text-white">Home</span>}
				/>
				<PrimaryBtn
					style={{ height: screenWidth < screens.sm.width ? '34px' : '45px' }}
					onClick={handleRandomBoxNavigate}
					content={
						<div className="flex items-center gap-x-[5px] sm:gap-[10px]">
							<FontAwesomeIcon icon={faShuffle} className="text-white" />
							<span className="font-bold text-[15px] text-white">Random WinBoxes</span>
						</div>
					}
				/>
			</div>
		</div>
	);
}
