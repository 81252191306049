import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

export function useBoxNameFromParams() {
	const { name: boxNameParams } = useParams();
	const [boxname, setBoxName] = useState('');

	useEffect(() => {
		if (boxNameParams) {
			window.scrollTo(0, 0);
			setBoxName(boxNameParams.replace(/-/g, ' '));
		}
	}, [boxNameParams]);

	return boxname;
}
