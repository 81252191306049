import { Icon } from '@iconify/react';

export function ShippingFeeBannerInfo() {
	// todo: je nachdem was user ausgewählt hat wird dollar oder looties angezeigt

	return (
		<div
			className={`relative w-full flex px-[15px] justify-between items-center h-[40px] border-[3px] rounded-[24px] border-white01 bg-mrLootBlue text-white`}
		>
			<Icon icon="prime:info-circle" height={25} />
			<span className="font-semibold mx-auto text-[17px]">Enjoy free standard shipping on orders over $50 *</span>
		</div>
	);
}
