export const POS_WON_ITEM = 10;
export const MOBILE_POS_WON_ITEM = 5;
export const NUM_OF_ITEMS_TO_CARRY_IN_NEXT_SPIN = 5; // num of items to carry into next spin. only when box spin items are not resetet and need last items to make smooth transition to new items

export const NUM_OF_SLOT_ITEMS = 40;

// BOX SPIN Time
export const FAST_SPIN_TIME = 5;
export const NORMAL_SPIN_TIME = 11; // in seconds
export const DESKTOP_FAST_SPIN_TIME = 2.4;
export const DESKTOP_NORMAL_SPIN_TIME = 3.6; // in seconds
export const DESKTOP_BUFFER_FAST_SPIN_TIME = 2;
export const DESKTOP_BUFFER_NORMAL_SPIN_TIME = 3; // in seconds

export const DICE_SIDES = 1000000; // 1 million
export const MAX_NEAR_MISSES_CANDIDATES = 4;

export const DEFAULT_AUTOPLAY_VALUE = 3;

export const POS_WON_ITEM_LOOTGAMES = 21;

// Mobile

export const NUM_BUFFER_PRIZES = 30; // num of buffer items - loaded before actual spin;
export const NUM_BUFFER_TRANSITION_PRIZES = 10; // Number of pre-won prizes for smooth transition from buffer loop to real spin; must be less than NUM_BUFFER_PRIZES

export const NUM_PRE_SLOT_PRIZES = 35; // Items show before won item is shown [0; 35];
export const DESKTOP_NUM_PRE_SLOT_PRIZES = 10; // Items show before won item is shown [0; 10];
export const NUM_SURROUNDING_PRIZES = 10; // in both directions -> so divided by 2 in each direction 5 <- won -> 5

export const IMAGE_FAIL_LOAD_THRESHOLD = 0.05; // in percentage -> percentage of images that can fail to load;
export const MAX_IMAGE_LOAD_TIME = 10; // in seconds - time to cancel box opening if images are not loaded

export const DELAY_BOX_OPENING_CLOSE_ON_TOTAL_LOADING_FAIL = 1500; // in ms

export const MAX_AUTO_SPIN_NUM = 50;

export const TOTAL_SLOT_PRIZES = DESKTOP_NUM_PRE_SLOT_PRIZES + NUM_SURROUNDING_PRIZES + 1;
export const DESKTOP_NUM_TRANSITION_PRIZES = NUM_SURROUNDING_PRIZES + 1; // +1: win prize

export const FADE_OUT_DURATION_BG_SPIN_SOUND = 1500;
export const MOBILE_FADE_IN_DURATION_BG_SPIN_SOUND = 2000;
export const DESKTOP_FADE_IN_DURATION_BG_SPIN_SOUND = 1000;
