import { LoadSpinner } from 'components/common/loading/LoadSpinner';
import { useInfiniteScroll, useWindowDimensions } from 'hooks';
import { UnboxedHistoryInterface } from 'interfaces/UnboxedHistoryInterface';
import { useWinBoxHistory } from 'pages/AccountSettings/hooks/useWinBoxHistory';
import WinBoxHistoryCard from 'pages/AccountSettings/mobile/WinboxHistory/WinBoxHistoryCard';
import { useCallback, useRef, useState } from 'react';

import { screens } from '../../../../constants';
import { Breadcrumb } from '../components/Breadcrumb';
import { BoxOpenDetailsPopup } from './BoxOpenDetailsPopup';

export function WinBoxHistory() {
	const { screenWidth } = useWindowDimensions();
	const boxesPerRow =
		// eslint-disable-next-line no-magic-numbers
		screenWidth > screens.xl.width ? 6 : screenWidth > screens.lg.width ? 5 : screenWidth > screens.md.width ? 4 : 3;
	const rowsPerPage = 3;

	const {
		fetchNextPage,
		hasNextPage,
		data: winBoxHistory,
		isLoading,
		isFetchingNextPage,
	} = useWinBoxHistory({ limit: boxesPerRow * rowsPerPage });

	const handleLoadOnInfiniteScroll = useCallback(() => {
		if (hasNextPage && !isLoading) {
			fetchNextPage();
		}
	}, [fetchNextPage, hasNextPage, isLoading]);

	const lastBoxRef = useRef<HTMLDivElement>(null);
	useInfiniteScroll(lastBoxRef, handleLoadOnInfiniteScroll, winBoxHistory?.length);

	const [boxDetailsPopup, setBoxDetailsPopup] = useState<UnboxedHistoryInterface | null>(null);

	return (
		<>
			{boxDetailsPopup && (
				<BoxOpenDetailsPopup unboxedDetails={boxDetailsPopup} onClose={() => setBoxDetailsPopup(null)} />
			)}

			<div className="flex flex-col gap-y-[20px] w-full">
				<Breadcrumb items={[{ label: 'WinBox History' }]} />

				{!isLoading && winBoxHistory && (
					<div className="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-x-[40px] gap-y-[45px] mt-[20px]">
						{winBoxHistory?.map((boxHistoryEl, index) => (
							<WinBoxHistoryCard
								key={boxHistoryEl._id}
								onClick={() => setBoxDetailsPopup(boxHistoryEl)}
								unboxedDetails={boxHistoryEl}
								ref={index === winBoxHistory.length - 1 ? lastBoxRef : null}
							/>
						))}
					</div>
				)}

				{(isLoading || isFetchingNextPage) && (
					<div className="flex items-center justify-center w-full">
						<LoadSpinner size="35px" />
					</div>
				)}
			</div>
		</>
	);
}
