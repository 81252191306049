import {
	faChevronRight,
	faClockRotateLeft,
	faDollar,
	faRightToBracket,
	faTruck,
	faUser,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StarsIcon } from 'assets/images';
import { SecondaryBtn } from 'components/common/buttons/SecondaryBtn';
import { SectionHeader } from 'components/common/SectionHeader';
import { useLogout } from 'hooks/data/user/useLogout';
import { ProfilePicMrloot } from 'pages/AccountSettings/assets';
import { Link, useNavigate } from 'react-router-dom';
import { useAppStore } from 'store/useAppStore';
import { shallow } from 'zustand/shallow';

const ACCOUNT_LINKS = [
	{ label: 'Edit Profile', icon: faUser, link: 'edit-profile' },
	{ label: 'My Orders', icon: faTruck, link: '/my-account/my-orders' },
	//{ label: 'Invite a Friend', icon: faUserGroup, link: 'invite-a-friend' },
	{ label: 'WinBox History', icon: faClockRotateLeft, link: 'winbox-history' },
	{ label: 'Choose Currency', icon: faDollar, link: 'choose-currency' },
];

export function MobileAccountSettings() {
	const navigate = useNavigate();

	const { mutate: logoutUser } = useLogout({
		onSuccess: () => {
			navigate('/');
		},
	});
	const setShowBackBtnInTopBar = useAppStore((state) => state.setShowBackBtnInTopBar, shallow);

	return (
		<div className="flex flex-col items-center gap-y-[15px]">
			<SectionHeader title="My Account" iconSrc={StarsIcon} style={{ width: '100%' }} rightContent={<></>} />
			<img src={ProfilePicMrloot} alt="profile" className="w-[100px] h-[100px]" />
			<h2 className="font-bold text-[20px] text-white">Welcome back!</h2>
			<div className="flex flex-col items-stretch gap-y-[18px] w-full">
				{ACCOUNT_LINKS.map((el) => (
					<Link
						key={el.label}
						to={el.link}
						onClick={() => setShowBackBtnInTopBar(true)}
						className="relative w-full h-[52px] flex items-center justify-between outline outline-[3px] outline-white px-[18px] border-[2px] border-darkBlue rounded-[15px] bg-secondary-gradient text-white focus-within:shadow-[inset_0px_-1px_7px_rgba(0,0,0,0.7)]"
					>
						<div className="flex items-center gap-x-[15px]">
							<FontAwesomeIcon icon={el.icon} className="text-[19px]" />
							<span className="font-semibold">{el.label}</span>
						</div>
						<FontAwesomeIcon icon={faChevronRight} className="text-[20px]" />
					</Link>
				))}
				<SecondaryBtn
					style={{ height: '55px' }}
					onClick={() => logoutUser()}
					content={
						<div className="w-full h-full flex items-center justify-between text-white">
							<div className="flex items-center gap-x-[15px]">
								<FontAwesomeIcon icon={faRightToBracket} className="text-[19px]" />
								<span className="font-semibold">Logout</span>
							</div>
							<FontAwesomeIcon icon={faChevronRight} className="text-[20px]" />
						</div>
					}
				/>
			</div>
		</div>
	);
}
