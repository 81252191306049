import axios from 'axios';
import { API_DOMAIN } from '../../../constants';
import { useMutation } from 'react-query';

async function verifyResetCode(email: string, code: string) {
	try {
		const response = await axios.post(
			`${API_DOMAIN.mrlootBackend}/api/user/validate-reset-code`,
			{ email, code },
			{ withCredentials: true }
		);
		if (response.data.status !== 'success') {
			throw new Error(response.data.message || 'Something unexpected went wrong');
		}
		return response.data;
	} catch (err) {
		if (axios.isAxiosError(err)) {
			const message = err.response?.data?.message || err.message || 'Something unexpected went wrong';
			throw new Error(message);
		}
		throw err;
	}
}

interface Props {
	email: string;
	code: string;
}

interface CallbackProps {
	onSuccess?: () => void;
	onError?: (message: string) => void;
}

export function useVerifyResetCode({ onError, onSuccess }: CallbackProps = {}) {
	return useMutation(({ email, code }: Props) => verifyResetCode(email, code), {
		onSuccess: onSuccess,
		onError: (err: Error) => {
			onError && onError(err.message || 'Something unexpected went wrong');
		},
	});
}
