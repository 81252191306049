import { PrimaryBtn } from 'components/common/buttons/PrimaryBtn';
import { COLORS } from '../../../../constants';
import { useResetPassword } from 'pages/authentication/hooks/useResetPassword';
import { useAuthenticationStore } from 'pages/authentication/store/useAuthenticationStore';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { ToastErrorNotification } from 'components/toasts/ToastErrorNotification';
import { toast } from 'react-toastify';
import { ToastSuccessNotification } from 'components/toasts/ToastSuccessNotification';
import { LogoBlue } from 'assets/images';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { PasswordInputWrapper } from '../components/PasswordInputWrapper';

interface PasswordResetInterface {
	password: string;
	passwordRepeat: string;
}

export function DesktopResetPasswordFinalStep() {
	const { emailForPasswordReset, verificationCodeForPasswordReset } = useAuthenticationStore();

	const navigate = useNavigate();

	const { control, handleSubmit, setError } = useForm<PasswordResetInterface>();

	const { isLoading: isPasswordResetLoading, mutate: requestPasswordReset } = useResetPassword({
		onSuccess: () => {
			toast(<ToastSuccessNotification message="Your password has been updated successfully" />, {
				style: { background: COLORS.blue90 },
			});
			navigate('/auth/login');
		},
		onError: (message) => {
			toast(<ToastErrorNotification message={message} />, {
				style: { backgroundColor: COLORS.blue90 },
			});
		},
	});

	const onSubmit: SubmitHandler<PasswordResetInterface> = (data) => {
		if (data.password !== data.passwordRepeat) {
			setError('passwordRepeat', {
				type: 'manual',
				message: 'Passwords do not match',
			});
			return;
		}
		if (!emailForPasswordReset || emailForPasswordReset.length === 0) {
			toast(<ToastErrorNotification message="An error occured. Please request another verification code" />, {
				style: { backgroundColor: COLORS.blue90 },
			});
			navigate('/auth/reset-password/enter-email');
			return;
		}
		if (!verificationCodeForPasswordReset || verificationCodeForPasswordReset.length !== 5) {
			toast(<ToastErrorNotification message="An error occured. Please re-enter verification code" />, {
				style: { backgroundColor: COLORS.blue90 },
			});
			navigate('/auth/reset-password/verification-code');
			return;
		}
		requestPasswordReset({
			email: emailForPasswordReset,
			code: verificationCodeForPasswordReset,
			password: data.password,
		});
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)} className="h-full w-full flex flex-col items-center justify-around gap-y-5">
			<img alt="" src={LogoBlue} className="h-[3rem]" />
			<div className="flex flex-col w-[75%] gap-y-3">
				<h1 className="text-[1.4rem] text-black font-semibold mb-[15px]">Password Reset</h1>
				<PasswordInputWrapper
					name="password"
					control={control}
					rules={{
						required: 'Password is required',
						minLength: {
							value: 8,
							message: 'Password must be at least 8 characters long',
						},
					}}
					label="Password"
					placeholder="Enter your password"
				/>
				<PasswordInputWrapper
					name="passwordRepeat"
					control={control}
					rules={{
						required: 'Password is required',
						minLength: {
							value: 8,
							message: 'Password must be at least 8 characters long',
						},
					}}
					label="Repeat Password"
					placeholder="Enter your password"
				/>

				<PrimaryBtn
					isDisabled={isPasswordResetLoading}
					type="submit"
					content="Reset Password"
					style={{ height: '50px', marginTop: '15px' }}
				/>
			</div>
			<Link to="/auth/login" className="flex items-center gap-x-[9px]">
				<FontAwesomeIcon icon={faChevronLeft} />
				<span>back to login</span>
			</Link>
		</form>
	);
}
