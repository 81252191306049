import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export function useQueryParam(paramKey: string, callback?: (paramValue: string) => void) {
	const [searchParams] = useSearchParams();
	const [value, setValue] = useState<string | undefined>();
	const valueFromParams = searchParams.get(paramKey);

	useEffect(() => {
		if (valueFromParams && valueFromParams.length > 0) {
			callback && callback(decodeURI(valueFromParams));
			setValue(decodeURI(valueFromParams));
		}
	}, [callback, valueFromParams]);

	return value;
}
