import 'react-spring-bottom-sheet/dist/style.css';

import { useWindowDimensions } from 'hooks';
import { useCallback, useEffect, useState } from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet';
import { usePopupStore } from 'store/usePopupStore';

import { screens } from '../../../constants';
import { CryptoPayCurrency } from '../deposit.interface';
import { PaymentMethod } from '../transaction.interface';
import { CryptoDepositView } from './views/CryptoDepositView.tsx/CryptoDepositView';
import { CryptoDepositSelection } from './views/PaymentAmount/CryptoDepositSelection/CryptoDepositSelection';
import { PaymentAmount } from './views/PaymentAmount/PaymentAmountSelection';
import { PaymentMethodSelection } from './views/PaymentMethodSelection';

const METHOD_SELECTION_SNAP_POINT = 425;

const AMOUNT_SELECTION_SNAP_POINT = 760;
const METHOD_SELECTION_SNAP_POINT_MS = 490;

const CRYPTO_CURRENCY_SELECTION_SNAP_POINT = 650;
const CRYPTO_CURRENCY_SELECTION_SNAP_POINT_XXS = 690;
const CRYPTO_CURRENCY_SELECTION_SNAP_POINT_MS = 480;

const CRYPTO_DEPOSIT_VIEW_SNAP_POINT = 690;
const CRYPTO_DEPOSIT_VIEW_SNAP_POINT_MS = 750;

const getMethodSelectionSnapPoint = (screenWidth: number) => {
	return screenWidth >= screens.ms.width ? METHOD_SELECTION_SNAP_POINT_MS : METHOD_SELECTION_SNAP_POINT;
};

const getCryptoSelectionSnapPoints = (screenWidth: number, maxSnapPoint: number) => {
	const cryptoSnapPoint =
		screenWidth >= screens.ms.width
			? CRYPTO_CURRENCY_SELECTION_SNAP_POINT_MS
			: screenWidth >= screens.xxs.width
				? CRYPTO_CURRENCY_SELECTION_SNAP_POINT_XXS
				: CRYPTO_CURRENCY_SELECTION_SNAP_POINT;

	if (maxSnapPoint <= CRYPTO_CURRENCY_SELECTION_SNAP_POINT) {
		return [maxSnapPoint];
	}

	return [cryptoSnapPoint, maxSnapPoint];
};

const getDepositViewSnapPoints = (screenWidth: number, maxSnapPoint: number) => {
	const cryptoSnapPoint =
		screenWidth >= screens.ms.width ? CRYPTO_DEPOSIT_VIEW_SNAP_POINT_MS : CRYPTO_DEPOSIT_VIEW_SNAP_POINT;

	if (maxSnapPoint <= CRYPTO_DEPOSIT_VIEW_SNAP_POINT) {
		return [maxSnapPoint];
	}

	return [cryptoSnapPoint, maxSnapPoint];
};

const getAmountSelectionSnapPoints = (maxSnapPoint: number) => {
	if (maxSnapPoint <= AMOUNT_SELECTION_SNAP_POINT) {
		return [maxSnapPoint];
	}
	return [AMOUNT_SELECTION_SNAP_POINT, maxSnapPoint];
};

export function MobileDepositBottomSheet() {
	const { isDepositPopupVisible, setIsDepositPopupVisible } = usePopupStore((state) => ({
		isDepositPopupVisible: state.isDepositPopupVisible,
		setIsDepositPopupVisible: state.setIsDepositPopupVisible,
	}));

	const [selectedPayMethod, setSelectedPayMethod] = useState<PaymentMethod | undefined>();
	const [selectedCrypto, setSelectedCrypto] = useState<CryptoPayCurrency | undefined>();

	const handleDissMiss = useCallback(() => {
		setIsDepositPopupVisible(false);
		setSelectedPayMethod(undefined);
	}, [setIsDepositPopupVisible]);

	const { screenWidth } = useWindowDimensions();

	const snapPoints = ({ maxHeight }: { maxHeight: number }) => {
		// eslint-disable-next-line no-magic-numbers
		const maxSnapPoint = 0.98 * maxHeight;

		// Handle method selection snap points
		if (!selectedPayMethod) {
			return [getMethodSelectionSnapPoint(screenWidth)];
		}

		// Handle crypto-specific snap points
		if (selectedPayMethod === PaymentMethod.CRYPTO) {
			if (!selectedCrypto) {
				return getCryptoSelectionSnapPoints(screenWidth, maxSnapPoint);
			}
			return getDepositViewSnapPoints(screenWidth, maxSnapPoint);
		}

		// Handle general amount selection snap points
		return getAmountSelectionSnapPoints(maxSnapPoint);
	};

	const showCryptoSelection = selectedPayMethod && selectedPayMethod === PaymentMethod.CRYPTO && !selectedCrypto;
	const showCryptoWallet = selectedPayMethod && selectedPayMethod === PaymentMethod.CRYPTO && !!selectedCrypto;
	const showAmountSelection = selectedPayMethod && selectedPayMethod !== PaymentMethod.CRYPTO;

	const [showBackground, setShowBackground] = useState(false);

	useEffect(() => {
		if (isDepositPopupVisible) {
			setShowBackground(true);
		} else {
			window.setTimeout(() => setShowBackground(false), 100);
		}
	}, [isDepositPopupVisible]);

	// need to use a wrapper to prevent click propagination to elements below
	return (
		<div className={`fixed z-40 inset-0 w-screen h-screen ${showBackground ? 'block' : 'hidden'}`}>
			<BottomSheet
				style={{ zIndex: 40, position: 'absolute' }}
				open={isDepositPopupVisible}
				onDismiss={handleDissMiss}
				snapPoints={snapPoints}
				header={<div className="h-[7px]"></div>}
			>
				<div className="px-[20px] pt-[25px] relative">
					{!selectedPayMethod && (
						<PaymentMethodSelection onMethodSelection={(method) => setSelectedPayMethod(method)} />
					)}
					{showAmountSelection && <PaymentAmount onBack={() => setSelectedPayMethod(undefined)} />}
					{showCryptoSelection && (
						<CryptoDepositSelection
							onCryptoSelection={(crypto) => setSelectedCrypto(crypto)}
							onBack={() => setSelectedPayMethod(undefined)}
						/>
					)}
					{showCryptoWallet && (
						<CryptoDepositView
							onBack={() => setSelectedCrypto(undefined)}
							selectedCurrency={selectedCrypto}
							onSelectedNetworkChange={(crypto) => setSelectedCrypto(crypto)}
						/>
					)}
				</div>
			</BottomSheet>
		</div>
	);
}
