import { IconBadConnection } from 'assets/images';
import { gsap } from 'gsap';
import { useEffect, useRef } from 'react';

export function BadConnectionIconWithFadeIn() {
	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const tl = gsap.timeline();
		tl.to(ref.current, { opacity: 1, duration: 0.55 });
	}, []);

	return (
		<div ref={ref} className="z-40 opacity-0 absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] ">
			<img
				src={IconBadConnection}
				className="h-[30cqh] object-contain drop-shadow-[4px_4px_4px_rgba(0,0,0,0.25)] animate-breath"
				alt="bad internet connection"
			/>
		</div>
	);
}
