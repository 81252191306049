import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { CSSProperties, useEffect, useRef } from 'react';
import { Control, FieldErrors, FieldValues, Path, UseFormRegister, useWatch } from 'react-hook-form';

import { COLORS } from '../../../constants';

interface Props<T extends FieldValues> {
	id?: string; // Optional ID to link with a label
	focus?: boolean;
	style?: CSSProperties;
	backgroundColor?: string;
	strokeColor?: string;
	checkSize?: string;
	register: UseFormRegister<T>;
	errors: FieldErrors<T>;
	fieldName: Path<T>;
	control: Control<T>;
	onClick?: () => void;
}

export function MobileCheckBox<T extends FieldValues>({
	id,
	focus = false,
	style,
	strokeColor,
	checkSize,
	register,
	errors,
	fieldName,
	control,
	onClick,
}: Props<T>) {
	const checkBoxRef = useRef<HTMLSpanElement>(null);

	const checked = useWatch({ control, name: fieldName });

	useEffect(() => {
		if (focus && checkBoxRef.current) {
			checkBoxRef.current.focus();
		}
	}, [focus]);

	const handleKeyDown = (e: React.KeyboardEvent) => {
		if (e.key === ' ' || e.key === 'Enter') {
			e.preventDefault();
			onClick && onClick();
		}
	};

	return (
		<div className="cursor-pointer flex items-center text-sm">
			<span
				role="checkbox"
				aria-checked={checked}
				tabIndex={0}
				onKeyDown={handleKeyDown}
				ref={checkBoxRef}
				className="cursor-pointer relative outline-none h-[20px] w-[20px] rounded-[4px] border-[2px] border-white"
				style={{ ...style, borderColor: errors[fieldName] && COLORS.errorRed }}
				aria-labelledby={id}
				onClick={onClick}
			>
				<input
					type="checkbox"
					{...register(fieldName)}
					className="opacity-0 absolute inset-0 w-full h-full cursor-pointer"
				/>
				{checked && (
					<FontAwesomeIcon
						icon={faXmark}
						className="pointer-events-none absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]"
						style={{
							color: strokeColor,
							fontSize: checkSize,
						}}
					/>
				)}
			</span>
		</div>
	);
}
