import Intercom from '@intercom/messenger-js-sdk';
import axios from 'axios';
import { useUserContext, useWindowDimensions } from 'hooks';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { usePopupStore } from 'store/usePopupStore';
import { isMobile } from 'utils';
import { shallow } from 'zustand/shallow';

import { API_DOMAIN, QUERY_CACHE_CONFIG, QUERY_KEYS } from '../../constants';

async function fetchIntercomHash(userId?: string): Promise<string | undefined> {
	if (!userId) {
		console.warn('fetchIntercomHash: userId is undefined');
		return undefined;
	}

	try {
		const response = await axios.get(`${API_DOMAIN.mrlootBackend}/api/intercom-hash`, { withCredentials: true });
		if (response.data.status === 'success') {
			return response.data.data;
		}
	} catch (err) {
		console.error(err);
		throw err;
	}
}

export function IntercomChat() {
	const { user } = useUserContext();
	const { screenWidth } = useWindowDimensions();
	const location = useLocation(); // React Router hook for current location

	const isDepositPopupVisible = usePopupStore((state) => state.isDepositPopupVisible, shallow);

	const { data: hash, isLoading: isHashLoading } = useQuery({
		queryKey: [QUERY_KEYS, user?._id],
		queryFn: () => fetchIntercomHash(user?._id),
		enabled: !!user?._id,
		cacheTime: QUERY_CACHE_CONFIG.longTerm.cacheTime,
		staleTime: QUERY_CACHE_CONFIG.longTerm.staleTime,
	});

	useEffect(() => {
		const excludedPaths = [/^\/auth/, /^\/boxes\/open/];
		const currentPath = location.pathname;

		const shouldHideIntercom = excludedPaths.some((pattern) => pattern.test(currentPath)) || isDepositPopupVisible;

		if (window.Intercom) {
			if (shouldHideIntercom) {
				window.Intercom('update', { hide_default_launcher: true }); // Hide launcher
			} else {
				window.Intercom('update', { hide_default_launcher: !!isMobile(screenWidth) }); // Show launcher based on device
			}
		}
	}, [location.pathname, screenWidth, isDepositPopupVisible]); // Trigger effect on path or screen size changes

	useEffect(() => {
		if (user && isHashLoading) {
			return;
		}

		Intercom({
			app_id: 'jabfwn1l',
			user_id: user?._id,
			name: user?.firstName ? `${user.firstName} ${user.lastName}` : user?.email,
			email: user?.email,
			created_at: user && Math.floor(new Date(user.createdAt).getTime() / 1000),
			user_hash: hash,
			custom_launcher_selector: '.custom-intercom-launcher',
			hide_default_launcher: !!isMobile(screenWidth),
		});
	}, [user, isHashLoading, hash, screenWidth]);

	return <></>;
}
