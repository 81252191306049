import { faForward, faRotateRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode } from 'react';

import { useBoxOpeningStoreMobile } from '../../store/useBoxOpeningStoreMobile';

interface Props {
	onDemoSpin: () => void;
}

export function MobileBoxOpenSettingsBar({ onDemoSpin }: Props) {
	const { autoSpinCount, setIsSpinAmountChooserPopupVisible, isFastSpin, setIsFastSpin } = useBoxOpeningStoreMobile();

	const buttonConfigs = [
		{
			child:
				autoSpinCount > 1 ? (
					<div className="rounded-[4px] px-1 border-[1px] border-white text-white text-[10px]">{autoSpinCount}</div>
				) : (
					<FontAwesomeIcon
						icon={faRotateRight}
						className="text-white text-[11px] sm:text-[13px] rotate-180 scale-x-[-1] relative"
					/>
				),
			label: 'Auto Open',
			isActive: autoSpinCount > 1,
			onClick: () => setIsSpinAmountChooserPopupVisible(true),
		},
		{
			child: <FontAwesomeIcon icon={faForward} className="text-white text-[11px] sm:text-[13px] relative" />,
			label: 'Fast Open',
			isActive: isFastSpin,
			onClick: () => setIsFastSpin(!isFastSpin),
		},
		{
			child: (
				<FontAwesomeIcon
					icon={faRotateRight}
					className="text-white text-[11px] sm:text-[13px] rotate-180 scale-x-[-1] relative"
				/>
			),
			label: 'Demo',
			isActive: false,
			onClick: onDemoSpin,
		},
	];

	return (
		<>
			<div className="w-full flex items-center justify-between space-x-[7px]">
				{buttonConfigs.map((config, index) => (
					<StyledButton
						key={index}
						child={config.child}
						label={config.label}
						onClick={config.onClick}
						isActive={config.isActive}
					/>
				))}
			</div>
		</>
	);
}

interface StyledButtonProps {
	child: ReactNode;
	label: string;
	isActive: boolean;
	onClick: () => void;
}

const StyledButton = ({ child, label, isActive, onClick }: StyledButtonProps) => (
	<button
		className={`relative flex-1 h-[40px] sm:h-[45px] border-[1px]  ${isActive ? 'border-white drop-shadow-[0_0_2px_rgba(255,255,255,0.5)]' : ' border-mrLootBlue'} px-[8px] space-x-[5px] flex items-center justify-center rounded-[23px]`}
		onClick={onClick}
	>
		{child}
		<span className="text-white font-bold text-[12px] sm:text-[14px]">{label}</span>
	</button>
);
