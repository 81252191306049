import { AmountDisplayWithCurrency } from 'components/common/AmountDisplayWithCurrency';
import { ItemInterface } from 'interfaces/ItemInterfaces';
import { ItemReferenceInfo } from 'interfaces/OrderInterfaces';

import { ProductReferenceInfo } from './ProductReferenceInfo';

interface ProductProps {
	itemId: ItemInterface;
	quantity: number;
	userAvgPurchaseValue?: number;
	referenceInfo?: ItemReferenceInfo;
}

interface Props {
	product: ProductProps;
	colorTheme?: 'blue' | 'white';
}

export function ProductCommonInfoRow({ product, colorTheme = 'white' }: Props) {
	const showGrid = !!product.referenceInfo?.value;

	return (
		<div className={`w-full ${showGrid ? 'grid grid-cols-[auto_90px]' : 'flex'} items-center`}>
			<div className="flex flex-col justify-between h-[55px] w-full">
				<span
					className={`${colorTheme === 'white' ? 'text-white' : 'text-mrLootBlue'} text-[14px] font-bold w-full line-clamp-1`}
				>
					{product.itemId.name}
				</span>
				<div className={`${showGrid ? 'w-fit' : 'w-full'} flex items-center justify-between gap-x-[15px]`}>
					<div
						className={`${colorTheme === 'white' ? 'bg-white text-mrLootBlue' : 'bg-[rgba(41,129,233,0.2)] text-mrLootBlue'} rounded-full font-semibold flex items-center justify-center w-[35px] h-[20px] text-[14px]`}
					>
						x{product.quantity}
					</div>
					<AmountDisplayWithCurrency
						amount={product.userAvgPurchaseValue ?? product.itemId.price}
						imgStyle={{ height: '18px' }}
						amountStyle={{ fontSize: '16px', color: colorTheme === 'white' ? 'white' : 'rgb(12 38 56)' }}
					/>
				</div>
			</div>

			<ProductReferenceInfo referenceInfo={product.referenceInfo} />
		</div>
	);
}
