import { ShippingAddressList } from './ShippingAddressList';
import { ShippingTimeSwitch } from './ShippingTimeSwitch';

export function ShippingDetails() {
	return (
		<div className="flex flex-col space-y-[20px] px-[10px]">
			<h2 className="text-white font-semibold text-[18px] mt-[15px]">Shipping Time</h2>
			<ShippingTimeSwitch />
			<ShippingAddressList />
		</div>
	);
}
