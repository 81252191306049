import { ItemOrderStatus, OrderDbItemInterface } from 'interfaces/OrderInterfaces';

import { isOrderItemRefunded } from '../../orderUtils';
import { ItemReferenceInfo } from './ItemReferenceInfo';
import { OutstandingAmountInfo } from './OutstandingAmountInfo';

interface Props {
	item: OrderDbItemInterface;
	orderId: string;
	onKeyPopup: (key: string) => void;
	onNotePopup: (text: string) => void;
}

export function OrderItemActions({ item, orderId, onKeyPopup, onNotePopup }: Props) {
	const showReferenceInfo = !isOrderItemRefunded(item.status) && item.referenceInfo && item.referenceInfo.value; // shows tracking link, key popup button
	const showOutstandingInvoiceInfo =
		!isOrderItemRefunded(item.status) &&
		item.status === ItemOrderStatus.AWAITING_PAYMENT &&
		item.outstandingInvoiceAmount !== undefined;

	return (
		<div
			className={`flex flex-col items-center justify-center gap-y-[10px] ${(!!showOutstandingInvoiceInfo || !!showReferenceInfo) && 'my-[10px] lg:mt-0 mr-auto '}`}
		>
			{showOutstandingInvoiceInfo && (
				<OutstandingAmountInfo
					outstandingAmount={item.outstandingInvoiceAmount! * item.quantity}
					orderId={orderId}
					itemId={item._id}
				/>
			)}
			{showReferenceInfo && (
				<ItemReferenceInfo onNotePopup={onNotePopup} onShowKeyPopup={onKeyPopup} referenceInfo={item.referenceInfo!} />
			)}
		</div>
	);
}
