import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useWindowDimensions } from 'hooks/utility/layout-measurements/useWindowDimensions';
import { Link } from 'react-router-dom';

import { screens } from '../../../constants';

interface Props {
	step: number;
	onStepChange: (step: number) => void;
}

export function BackToPrevSite({ step, onStepChange }: Props) {
	const { screenWidth } = useWindowDimensions();

	return (
		<>
			{step > 0 && (
				<button
					onClick={() => onStepChange(0)}
					className="flex items-center justify-center px-[14px] h-[30px] hover:border-[rgba(255,255,255,0.2)] text-[13px] rounded-[24px] space-x-[12px] border-[3px] border-[rgba(255,255,255,0.1)]"
				>
					<FontAwesomeIcon icon={faArrowLeft} className="text-[12px] text-[#6C6F6C]" />
					<span className="text-white font-semibold">Back {screenWidth > screens.ms.width && 'To Overview'}</span>
				</button>
			)}
			{step === 0 && (
				<Link
					to="/inventory"
					className="flex items-center justify-center px-[14px] h-[30px] hover:border-[rgba(255,255,255,0.2)] text-[13px] rounded-[24px] space-x-[12px] border-[3px] border-[rgba(255,255,255,0.1)]"
				>
					<FontAwesomeIcon icon={faArrowLeft} className="text-[12px] text-[#6C6F6C]" />
					<span className="text-white font-semibold">Back {screenWidth > screens.ms.width && 'To Inventory'}</span>
				</Link>
			)}
		</>
	);
}
