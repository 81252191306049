import { faCheck, faChevronDown, faChevronUp, faSpinner, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useOutsideClick } from 'hooks';
import React, { useLayoutEffect, useRef, useState } from 'react';

interface OptionInterface {
	id: string;
	value: string;
	icon?: IconDefinition;
}

interface DropdownProps {
	options: OptionInterface[];
	values: OptionInterface[]; // Controlled value passed from react-hook-form
	onChange: (selectedObjects?: OptionInterface[]) => void; // onChange from Controller
	isLoading?: boolean;
	placeholder?: string;
	buttonStyle?: React.CSSProperties;
	containerStyle?: React.CSSProperties;
	hasError?: boolean;
}

export function MultiSelectDropdown({
	options,
	values = [], // Controlled value from react-hook-form
	onChange,
	placeholder,
	isLoading,
	buttonStyle,
	containerStyle,
	hasError,
}: DropdownProps) {
	const [isOpen, setIsOpen] = useState(false);
	const [isOverflowing, setIsOverflowing] = useState(false); // Track if content is overflowing
	const dropdownRef = useRef<HTMLDivElement>(null);
	const buttonContentRef = useRef<HTMLDivElement>(null); // Ref for the content inside the button
	const buttonRef = useRef<HTMLButtonElement>(null); // Ref for the content inside the button

	useOutsideClick(dropdownRef, () => {
		setIsOpen(false);
	});

	// Function to handle option selection
	function handleOptionSelect(option: OptionInterface) {
		const isAlreadySelected = values.some((v) => v.id === option.id);

		const updatedSelectedOptions = isAlreadySelected
			? values.filter((v) => v.id !== option.id) // Deselect if already selected
			: [...values, option]; // Add new selection

		// Call onChange with the updated OptionInterface[] array
		onChange(updatedSelectedOptions);
	}

	// Effect to check if the button content is overflowing
	useLayoutEffect(() => {
		const checkOverflow = () => {
			if (buttonContentRef.current && buttonRef.current) {
				setIsOverflowing(buttonContentRef.current.clientHeight > buttonRef.current.clientHeight);
			}
		};
		// Check for overflow when component mounts and when values change
		checkOverflow();
	}, [values]);

	return (
		<div
			className="focus-within:z-40 relative z-30 h-[55px] w-[150px] rounded-[4px]"
			ref={dropdownRef}
			style={{ ...containerStyle }}
		>
			<button
				type="button"
				ref={buttonRef}
				className={`w-full relative gap-x-2 font-semibold border-[2px] ${hasError ? 'border-errorRed' : 'border-white'} flex items-center bg-secondary-gradient px-3 h-full py-[6px] rounded-[20px] text-[13px] text-white`}
				style={{
					...buttonStyle,
				}}
				onClick={() => setIsOpen(!isOpen)}
			>
				{isLoading ? (
					<FontAwesomeIcon icon={faSpinner} className="animate-spin" />
				) : (
					<>
						<div
							ref={buttonContentRef}
							className={`flex flex-wrap gap-1 mx-auto ${isOverflowing ? 'opacity-0' : 'opacity-100'}`}
						>
							{values.map((v) => v.value).join(', ')}
						</div>
						<div
							className={`${!isOverflowing && 'hidden'} w-fit whitespace-nowrap absolute left-1/2 -translate-x-[calc(50%+11px)] top-1/2 -translate-y-1/2`}
						>
							{`${values.length} selected`}
						</div>
					</>
				)}
				{values.length === 0 && (
					<span className={`mx-auto flex-grow capitalize ${values.length === 0 && 'text-[rgba(255,255,255,1)]'}`}>
						{placeholder || 'Choose'}
					</span>
				)}
				<FontAwesomeIcon
					icon={isOpen ? faChevronUp : faChevronDown}
					className="text-[13px] text-[rgba(255,255,255,0.7)] ml-auto"
				/>
			</button>
			{isOpen && (
				<ul className="absolute -bottom-3 translate-y-[100%] left-0 right-0 border-[2px] bg-secondary-gradient border-white shadow-[0px_3px_8px_rgba(0,0,0,0.24)] rounded-[20px] text-white text-[13px] overflow-hidden backdrop-blur-[2px]">
					<div className="z-10 max-h-[200px] overflow-auto">
						{options.map((option) => {
							const isSelected = values.some((v) => v.id === option.id);

							return (
								<li key={option.id} className="w-full">
									<button
										type="button"
										onClick={() => handleOptionSelect(option)}
										className={`relative flex items-center py-[6px] px-[21px] text-center w-full
                                            ${isSelected ? 'bg-[rgba(255,255,255,0.2)]' : 'hover:bg-[rgba(255,255,255,0.1)]'}`}
									>
										{option.icon && <FontAwesomeIcon icon={option.icon} className="mr-4" />}
										<span className="text-center w-full">{option.value}</span>
										{isSelected && (
											<FontAwesomeIcon icon={faCheck} className="absolute right-3 top-1/2 -translate-y-1/2" />
										)}
									</button>
								</li>
							);
						})}
					</div>
				</ul>
			)}
		</div>
	);
}
