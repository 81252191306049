import { useAutoAnimate } from '@formkit/auto-animate/react';
import { Skeleton } from 'components/common';
import { AmountDisplayWithCurrency } from 'components/common/AmountDisplayWithCurrency';
import { useFindBoxesByItem } from 'hooks';
import { ItemInterface } from 'interfaces/ItemInterfaces';
import { useLayoutEffect } from 'react';
import { useNavigate } from 'react-router';
import { CurrencyOptions, useAppStore } from 'store/useAppStore';
import { getBgCardUrl, getBoxLink } from 'utils';
import { shallow } from 'zustand/shallow';

import { CLOUDFRONT_IMG_URL, rarities } from '../../../../constants';

interface Props {
	showPrice: boolean;
	item: ItemInterface;
	excludedBoxId?: string;
}

/**
 * This component displays the price of an item and related boxes in an auto-animated container.
 * When the item is not expanded, it shows the item's price. When expanded, it displays additional
 * boxes that include the item, allowing users to navigate to those boxes.
 *
 * @param {boolean} props.showPrice - A flag indicating whether to show the price or the related boxes.
 * @param {ItemInterface} props.item - The item object containing details such as price and rarity.
 * @param {string} [props.excludedBoxId] - (Optional) The ID of a box to exclude from the displayed boxes.
 *
 * @returns {JSX.Element} The rendered PriceAndBoxDisplay component.
 */
export function PriceAndBoxDisplay({ showPrice, excludedBoxId, item }: Props) {
	const [parent, enable] = useAutoAnimate();

	useLayoutEffect(() => {
		enable(showPrice);
	}, [enable, showPrice]);

	const selectedCurrency = useAppStore((state) => state.selectedCurrency, shallow);

	const { data: includedBoxes, isFetching } = useFindBoxesByItem({
		itemId: item._id,
		excludedBoxId,
	});

	const navigate = useNavigate();

	function handleBoxNavigation(boxName: string) {
		const boxLink = getBoxLink(boxName);
		navigate(boxLink);
	}

	const isIncludedInOtherBoxes = includedBoxes && includedBoxes.length > 0;

	return (
		<div ref={parent}>
			{!showPrice && (
				<div
					className="relative grid font-semibold w-full mx-auto text-white items-center h-[16cqh]"
					style={{ gridTemplateColumns: '1fr 2fr 1fr' }}
				>
					<img
						alt={`Card background color is ${rarities[item.rarity].label}`}
						src={getBgCardUrl(item.rarity)}
						className="z-[-1] absolute w-full h-full object-fill left-0 top-0 rotate-180 rounded-[8px]"
					/>
					<AmountDisplayWithCurrency
						amount={item.price}
						useDivWrapper={false}
						amountStyle={{ fontSize: '5.8cqh', textAlign: 'center' }}
						imgStyle={{ height: '6.8cqh' }}
						FirstElementWrapper={({ children }) => (
							<div
								className={`${selectedCurrency === CurrencyOptions.LOOTIES ? 'col-start-2 col-end-3' : 'col-start-1 col-end-2 pl-2'} flex items-center justify-center`}
							>
								{children}
							</div>
						)}
						SecondElementWrapper={({ children }) => (
							<span
								className={`${selectedCurrency === CurrencyOptions.LOOTIES ? 'col-start-3 col-end-4 pr-2' : 'col-start-2 col-end-3'} flex justify-center items-center`}
							>
								{children}
							</span>
						)}
					/>
				</div>
			)}
			{showPrice && (
				<div
					className={`grid ${!isIncludedInOtherBoxes && !isFetching ? 'grid-cols-1' : 'grid-cols-3'} items-center w-full h-[31cqh]`}
				>
					{includedBoxes &&
						includedBoxes.map((el) => (
							<button
								onClick={() => handleBoxNavigation(el.name)}
								key={el._id}
								className="w-full h-full hover:border-white border-[2px] border-transparent overflow-hidden rounded-[8px]"
							>
								<img
									alt=""
									src={`${CLOUDFRONT_IMG_URL}/${el.portraitImageUrls?.qualityMedium}`}
									className="w-full h-full object-cover"
								/>
							</button>
						))}
					{isFetching && <Skeleton style={{ width: '100%', height: '31cqh' }} />}
					{!isIncludedInOtherBoxes && !isFetching && (
						<span className="text-[4cqh] text-[rgba(255,255,255,0.7)] font-bold text-center mt-auto mb-[5px]">
							UNIQUE TO THIS BOX
						</span>
					)}
				</div>
			)}
		</div>
	);
}
