import { useEffect } from 'react';
import { useLocation } from 'react-router';

/**
 * maintains scroll position of background if scrollY is in location state
 * prefer use: overlays with own url
 */
export function useKeepBackgroundScrollPos() {
	const location = useLocation();

	useEffect(() => {
		// maintain scroll pos when box overlay is opened
		const scrollY = location.state && location.state.scrollY;
		if (scrollY) {
			window.scrollTo(0, scrollY);
		}
	}, [location]);
}
