import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AmountDisplayWithCurrency } from 'components/common/AmountDisplayWithCurrency';
import { ItemRarity } from 'interfaces/ItemInterfaces';

import { rarities } from '../../../../constants';

interface Props {
	isSellLoading: boolean;
	rarity: ItemRarity;
	price: number;
	isRedeemable: boolean;
	onClick: () => void;
}

export function SellBtn({ isSellLoading, price, rarity, isRedeemable, onClick }: Props) {
	return (
		<button
			disabled={isSellLoading}
			className="w-[calc(50%-12px)] h-[61px] border-[2px] text-whie font-bold text-[17px] text-white  border-white rounded-[31px] flex items-center justify-center drop-shadow-[0px_4px_4px_rgba(0,0,0,0.25)]"
			style={{ background: rarities[rarity].gradient }}
			onClick={onClick}
		>
			{isSellLoading ? (
				<FontAwesomeIcon icon={faSpinner} className="animate-spin" />
			) : (
				<>
					<span className="mr-[8px]">{isRedeemable ? 'Redeem' : 'Sell'}</span>
					<AmountDisplayWithCurrency amount={price} imgStyle={{ height: '18px' }} amountStyle={{ fontSize: '16px' }} />
				</>
			)}
		</button>
	);
}
