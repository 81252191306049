import { useAutoAnimate } from '@formkit/auto-animate/react';
import { ShippingAddressInterface } from 'interfaces/OrderInterfaces';
import { Control, Controller, FieldError, FieldErrorsImpl, Merge, RegisterOptions } from 'react-hook-form';

interface Props {
	name: keyof ShippingAddressInterface;
	control: Control<ShippingAddressInterface>;
	style?: React.CSSProperties;
	placeholder?: string;
	rules?: Omit<
		RegisterOptions<ShippingAddressInterface, keyof ShippingAddressInterface>,
		'setValueAs' | 'disabled' | 'valueAsNumber' | 'valueAsDate'
	>;
	error?: FieldError | Merge<FieldError, FieldErrorsImpl<Error>>;
}

export function AddressInputArea({ name, control, error, style, placeholder, rules }: Props) {
	const [parent] = useAutoAnimate();

	return (
		<div ref={parent} className="relative w-fit">
			<Controller
				name={name}
				control={control}
				rules={rules}
				render={({ field }) => (
					<textarea
						{...field}
						style={{
							background: 'linear-gradient(331.41deg, rgba(35, 109, 244, 0.3) 6.78%, rgba(51, 240, 167, 0.3) 204.87%)',
							...style,
						}}
						placeholder={placeholder}
						className={`border-white focus-within:border-darkBlue rounded-[8px] border-[2px] px-[10px] py-[9px] text-[13px] w-[210px] outline-none -mb-[7px]`}
					/>
				)}
			/>
			{error && typeof error.message === 'string' && (
				<p className="text-errorRed text-[13px] absolute top-[67%] left-0">{error.message}</p>
			)}
		</div>
	);
}
