import { ShippingAddressInterface } from 'interfaces/OrderInterfaces';
import React from 'react';

interface Props {
	address: ShippingAddressInterface;
}

export function OrderShippingAddress({ address }: Props) {
	return (
		<div className="flex flex-col pl-4 pt-2 rounded-[20px] text-white">
			<h2 className="text-[16px] font-semibold mb-2">Shipping Address</h2>
			<div className="flex flex-col gap-y-[3px] mb-2 text-[13px]">
				<span className="font-semibold">
					{address.firstName} {address.lastName}
				</span>
				<span>
					{address.street} {address.houseNr} {address.aptNr ? `, Apt ${address.aptNr}` : ''}
				</span>
				<span>
					{address.city}, {address.state ? `${address.state}, ` : ''}
					{address.zipCode} {address.country}
				</span>
				{address.phoneNumber && (
					<span>
						Phone: {address.phonePrefix ? `${address.phonePrefix} ${address.phoneNumber}` : address.phoneNumber}
					</span>
				)}
			</div>
			{address.shippingInstructions && (
				<div className="mt-2 p-2 border-l-4 border-blue-500 bg-blue-50">
					<strong>Shipping Instructions:</strong>
					<p>{address.shippingInstructions}</p>
				</div>
			)}
		</div>
	);
}
