import { CopyBtn } from 'components/common/buttons/CopyBtn';
import { PrimaryDropdown } from 'components/common/buttons/PrimaryDropdown';
import {
	currencyFullNames,
	networkLabels,
	networkNames,
	networkOptions,
} from 'pages/TransactionManager/transaction.interface';
import { QRCodeSVG } from 'qrcode.react';

import { CryptoPayCurrency } from '../../deposit.interface';

interface Props {
	walletAddress: string;
	onSelectedNetworkChange: (network: CryptoPayCurrency) => void;
	selectedCurrency: CryptoPayCurrency;
	memo?: string;
}

export function WalletInformation({ walletAddress, onSelectedNetworkChange, selectedCurrency, memo }: Props) {
	const networks = networkOptions[selectedCurrency.split('.')[0]] || [selectedCurrency];

	const title = `Deposit ${currencyFullNames[selectedCurrency]} (${selectedCurrency.split('.')[0].toUpperCase()})`;

	return (
		<>
			<h2 className="font-semibold text-[18px] text-center text-white">{title}</h2>

			{networks.length > 1 && (
				<div className="flex items-center justify-center gap-x-[10px] mx-auto my-[10px]">
					<span className="text-white">Choose Network:</span>
					<PrimaryDropdown
						options={networks.map((network) => ({
							name: networkLabels[network] || networkNames[network],
							value: network,
						}))}
						onOptionSelect={(value) => onSelectedNetworkChange(value as CryptoPayCurrency)}
						selectedOption={networkLabels[selectedCurrency] || networkNames[selectedCurrency]}
					/>
				</div>
			)}

			<span className="text-center text-white">{networkNames[selectedCurrency]}</span>

			<div className="mx-auto p-4 bg-white w-fit rounded-[8px] shadow-sm mb-[15px] mt-[15px]">
				<QRCodeSVG value={walletAddress} />
			</div>
			{memo && (
				<div className="flex flex-col gap-y-[5px] mb-[15px] text-white">
					<span className="text-white text-center font-semibold">Deposit Destination Tag (Memo)</span>
					<div className="relative py-[4px] mx-auto flex items-center justify-center w-fit h-[44px] border-[1px] border-[#3559D7] outline outline-[2px] outline-white bg-secondary-gradient pl-[15px] pr-[60px] rounded-[20px]">
						<span>{memo}</span>
						<div className="absolute right-0 top-0 flex items-center justify-center h-full w-[45px] rounded-r-[20px] hover:bg-[rgba(255,255,255,0.1)] border-l-[1px] border-white">
							<CopyBtn textToCopy={memo} />
						</div>
					</div>
				</div>
			)}
			<div className="flex flex-col gap-y-[10px] text-white">
				<span className="text-white text-center font-semibold">Deposit Address</span>
				<div className="relative mx-auto flex py-[4px] items-center justify-center w-fit max-w-[90%] min-h-[44px] border-[1px] border-[#3559D7] outline outline-[2px] outline-white bg-secondary-gradient pl-[15px] pr-[60px] rounded-[20px] break-words">
					<span className="break-words text-center max-w-full">{walletAddress}</span>
					<div className="absolute right-0 top-0 flex items-center justify-center h-full w-[45px] rounded-r-[20px] hover:bg-[rgba(255,255,255,0.1)] border-l-[1px] border-white">
						<CopyBtn textToCopy={walletAddress} />
					</div>
				</div>
			</div>

			<span className="text-[rgba(255,255,255,0.9)] mt-[15px] text-center">
				Only send <strong className="text-white">{currencyFullNames[selectedCurrency]}</strong> via the{' '}
				<strong className="text-white">{networkNames[selectedCurrency]}</strong> to this address
			</span>
			<span className="text-center mt-[5px] text-[rgba(255,255,255,0.9)]">
				Only deposits of <strong className="text-white">$10</strong> or more will be credited to your account
			</span>
		</>
	);
}
