import { FontArrow } from 'assets/images';
import { PrimaryBtn } from 'components/common/buttons/PrimaryBtn';
import { PrimaryContainer } from 'components/PrimaryContainer';
import { usePaymentTransactions } from 'hooks/data/transactions/usePaymentTransactions';
import { useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';

// Amount and bonus thresholds
const BONUS_THRESHOLD_LOW = 1;
const BONUS_THRESHOLD_HIGH = 100;
const BONUS_PERCENTAGE_LOW = 10;
const BONUS_PERCENTAGE_HIGH = 20;
const MAX_AMOUNT = 100000;
const MIN_AMOUNT = 10;

// Increment and delay settings
const INCREMENT_DELAY = 450; // Initial delay before continuous increment starts
const INCREMENT_INTERVAL = 100; // Speed of continuous increment
const DEFAULT_AMOUNT_DISPLAY = '$ 50';
const DEFAULT_AMOUNT = 50;

export function CustomAmountInput() {
	const { register, setValue } = useFormContext<{ amount: number }>();
	const [inputValue, setInputValue] = useState(DEFAULT_AMOUNT_DISPLAY);
	const amountRef = useRef(DEFAULT_AMOUNT); // Ref to store the current numeric value
	const timeoutRef = useRef<NodeJS.Timeout | null>(null);

	const bonusPercentage = calculateBonusPercentage();

	const { data: transactions, isLoading: areTransactionsLoading } = usePaymentTransactions();

	const showBonus = !areTransactionsLoading && (!transactions || transactions.length === 0);

	const updateDisplayValue = (value: number) => {
		// Update the display value and sync with form context
		setInputValue(`$ ${value}`);
		setValue('amount', value);
	};

	function calculateBonusPercentage() {
		if (amountRef.current >= BONUS_THRESHOLD_HIGH) {
			return BONUS_PERCENTAGE_HIGH;
		}
		if (amountRef.current >= BONUS_THRESHOLD_LOW) {
			return BONUS_PERCENTAGE_LOW;
		}
		return 0;
	}

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;

		// Only allow numbers after the dollar sign and space
		if (/^\$ [0-9]*$/.test(value)) {
			const numericValue = parseInt(value.replace('$ ', ''), 10) || 0;
			amountRef.current = numericValue; // Update the ref with the latest numeric value
			updateDisplayValue(numericValue);
		}
	};

	const handleBlur = () => {
		const numericValue = parseInt(inputValue.replace('$ ', ''), 10) || 0;
		if (numericValue < MIN_AMOUNT) {
			amountRef.current = MIN_AMOUNT; // Reset ref value
			updateDisplayValue(MIN_AMOUNT);
		}
	};

	const incrementAmount = () => {
		if (amountRef.current < MAX_AMOUNT) {
			amountRef.current += 1;
			updateDisplayValue(amountRef.current);
		}
	};

	const decrementAmount = () => {
		if (amountRef.current > MIN_AMOUNT) {
			amountRef.current -= 1;
			updateDisplayValue(amountRef.current);
		}
	};

	const startContinuousIncrement = () => {
		incrementAmount();
		timeoutRef.current = setTimeout(startContinuousIncrement, INCREMENT_INTERVAL);
	};

	const startContinuousDecrement = () => {
		decrementAmount();
		timeoutRef.current = setTimeout(startContinuousDecrement, INCREMENT_INTERVAL);
	};

	const handleIncrementPress = () => {
		// Start the initial increment and set a delay for continuous increments
		incrementAmount();
		timeoutRef.current = setTimeout(startContinuousIncrement, INCREMENT_DELAY);
	};

	const handleDecrementPress = () => {
		// Start the initial decrement and set a delay for continuous decrements
		decrementAmount();
		timeoutRef.current = setTimeout(startContinuousDecrement, INCREMENT_DELAY);
	};

	const stopInterval = () => {
		// Clear any ongoing timeout
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
			timeoutRef.current = null;
		}
	};

	return (
		<PrimaryContainer
			style={{ width: 'fit-content', height: 'fit-content' }}
			content={
				<div className="w-[300px] lg:w-[353px] h-[94px] lg:h-[104px] pt-[5px] lg:pt-[10px] pb-[3px] flex flex-col text-white text-[13px] lg:text-[14px] font-semibold">
					<span className="">Custom Amount</span>
					<div className="flex items-end justify-between w-full">
						<div className="flex flex-col items-center gap-y-[3px]">
							<div className="flex items-center w-full gap-x-[4px]">
								<button
									type="button"
									onMouseDown={handleDecrementPress}
									onMouseUp={stopInterval}
									onMouseLeave={stopInterval}
									className="rounded-full w-[22px] h-[22px] lg:w-[25px] lg:h-[25px] bg-secondary-gradient flex items-center justify-center border-[1px] border-white font-extrabold"
								>
									<span className="leading-[14px] lg:leading-[16px]">-</span>
								</button>
								<div className="relative w-[130px]">
									<input
										{...register('amount', { valueAsNumber: true })}
										className="border-b-[3px] text-[18px] lg:text-[20px] font-extrabold border-b-white text-center bg-transparent w-full outline-none"
										type="text"
										value={inputValue}
										onChange={handleInputChange}
										onBlur={handleBlur}
										placeholder="$ 0"
									/>
								</div>
								<button
									type="button"
									onMouseDown={handleIncrementPress}
									onMouseUp={stopInterval}
									onMouseLeave={stopInterval}
									className="rounded-full  w-[22px] h-[22px] lg:w-[25px] lg:h-[25px] bg-secondary-gradient flex items-center justify-center border-[1px] border-white font-extrabold"
								>
									<span className="leading-[14px] lg:leading-[16px]">+</span>
								</button>
							</div>
							<span className="text-[12px] text-[rgba(255,255,255,0.8)]">max. ${MAX_AMOUNT.toLocaleString()} </span>
						</div>
						<div className="flex flex-col items-center gap-y-[3px]">
							<PrimaryBtn
								type="submit"
								style={{ width: 'fit-content' }}
								content={
									<div className="flex items-center justify-center w-[65px] lg:w-[100px]">
										<img src={FontArrow} alt="->" className="h-[15px] w-auto" />
									</div>
								}
							/>
							{/* Dynamic Bonus Percentage */}
							{showBonus && (
								<span className={`text-[#00DB00] font-bold text-[10px] ${bonusPercentage === 0 && 'opacity-0'}`}>
									+{bonusPercentage}% BONUS
								</span>
							)}
						</div>
					</div>
				</div>
			}
		/>
	);
}
