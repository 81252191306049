import { faCheck, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PrimaryBtn } from 'components/common/buttons/PrimaryBtn';
import { ToastErrorNotification } from 'components/toasts/ToastErrorNotification';
import { ToastSuccessNotification } from 'components/toasts/ToastSuccessNotification';
import { useUserContext } from 'hooks';
import { useResendVerificationMail } from 'pages/AccountSettings/hooks/useResendVerificationMail';
import { useUserMailUpdate } from 'pages/AccountSettings/hooks/useUserMailUpdate';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

import { AccountEmailInput } from './AccountEmailInput';

export function EmailSettingsRow() {
	const { user, load: reloadUser } = useUserContext();
	const navigate = useNavigate();

	const [email, setEmail] = useState('');

	useEffect(() => {
		if (user && user.contactMail) {
			setEmail(user.contactMail.value);
		}
	}, [user]);

	const { mutate: updateMail, isLoading: isMailUpdateLoading } = useUserMailUpdate({
		onSuccess() {
			toast(<ToastSuccessNotification message="Your E-Mail has been updated" />);
			reloadUser();
			navigate('/my-account/edit-profile');
		},
		onError(message) {
			toast(<ToastErrorNotification message={message} />);
		},
	});

	const { mutate: resendVerificationMail, isLoading: isResendMailReqLoading } = useResendVerificationMail({
		onSuccess: () => {
			toast(<ToastSuccessNotification message="Verification e-mail has been send to your mail address" />);
		},
		onError: () => {
			toast(<ToastErrorNotification message="Could not send mail to your mail address" />);
		},
	});

	function handleBtnClick() {
		if (!email || email.length === 0) {
			return;
		}

		// Basic email validation regex
		const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
		const isMailValid = emailRegex.test(email);

		if (!isMailValid) {
			toast(<ToastErrorNotification message="Invalid e-mail address" />);
			return;
		}

		if (!user?.contactMail || user.contactMail.value !== email) {
			updateMail({ mail: email });
		} else {
			resendVerificationMail();
		}
	}

	const btnIcon =
		!user?.contactMail || user.contactMail.value !== email || user.contactMail.verified ? faCheck : faChevronRight;
	const btnText = !user?.contactMail || user.contactMail.value !== email ? 'Save' : 'Verify';

	return (
		<div className="flex items-center gap-x-[12px]">
			<AccountEmailInput value={email} onChange={(v) => setEmail(v)} />
			<PrimaryBtn
				onClick={handleBtnClick}
				isDisabled={
					(email === user?.contactMail?.value && user?.contactMail?.verified) ||
					isResendMailReqLoading ||
					isMailUpdateLoading ||
					email.length === 0
				}
				renderEllipse={false}
				style={{
					height: '44px',
					width: '150px',
					borderRadius: '10px',
					outlineWidth: '2px',
				}}
				content={
					<div className="w-full flex items-center text-white justify-between text-[15px] font-semibold">
						<span>{btnText}</span>
						<FontAwesomeIcon icon={btnIcon} className="text-[19px]" />
					</div>
				}
			/>
		</div>
	);
}
