import React from 'react';
import { useController, UseControllerProps, FieldValues } from 'react-hook-form';
import { Checkbox, StyleProps } from './Checkbox';

interface CheckboxWrapperProps<T extends FieldValues> extends UseControllerProps<T>, StyleProps {
	label?: string;
	disabled?: boolean;
}

export function CheckboxWrapper<T extends FieldValues>({
	name,
	control,
	rules,
	label,
	disabled,
	labelStyle,
	checkboxStyle,
}: CheckboxWrapperProps<T>) {
	const {
		field: { onChange, onBlur, value, ref },
		fieldState: { error },
	} = useController({
		name,
		control,
		rules,
	});

	return (
		<Checkbox
			label={label}
			error={error?.message}
			onChange={onChange}
			onBlur={onBlur}
			checked={value}
			name={name}
			ref={ref}
			disabled={disabled}
			labelStyle={labelStyle}
			checkboxStyle={checkboxStyle}
		/>
	);
}
