/* eslint-disable complexity */
import { Bitcoin, Coin, CryptoDogecoin, CryptoSolana, DollarSign, Etherium } from 'assets/images';
import { useCryptoConversion } from 'hooks/currency/useCryptoConversion';
import { CSSProperties, FC, ReactNode } from 'react';
import { CurrencyOptions, useAppStore } from 'store/useAppStore';
import { convertToLootiesAndFormatAsAbbreviated, formatAmountAsAbbreviated } from 'utils';
import { shallow } from 'zustand/shallow';

// Constants
const ICON_HEIGHT = '15px';
const GAP_SIZE = '6px';
const DEFAULT_FRACTION_DIGITS = {
	US_DOLLAR: 2,
	BITCOIN: 5,
	ETHERIUM: 4,
	SOLANA: 3,
	DOGECOIN: 2,
};

interface WrapperProps {
	children: ReactNode;
}

interface Props {
	amount?: number;
	style?: CSSProperties;
	imgStyle?: CSSProperties;
	amountStyle?: CSSProperties;
	currency?: CurrencyOptions;
	useDivWrapper?: boolean;
	FirstElementWrapper?: FC<WrapperProps>; // Optional wrapper for the first element
	SecondElementWrapper?: FC<WrapperProps>; // Optional wrapper for the second element
	abbreviationOption?: '10K' | '100K' | '1M' | 'default' | 'none';
	fractionDigits?: number;
	Prefix?: ReactNode; // Optional ReactNode displayed directly before the amount
}

export function AmountDisplayWithCurrency({
	amount = 0,
	style,
	amountStyle,
	imgStyle,
	currency,
	useDivWrapper = true,
	FirstElementWrapper,
	SecondElementWrapper,
	abbreviationOption,
	fractionDigits,
	Prefix,
}: Props) {
	const selectedCurrency = useAppStore((state) => state.selectedCurrency, shallow);

	const displayedCurrency = currency ?? selectedCurrency;
	const { convertedAmount } = useCryptoConversion(amount, displayedCurrency);

	const wrapWith = (Wrapper: FC<WrapperProps> | undefined, content: ReactNode) => {
		return Wrapper ? <Wrapper>{content}</Wrapper> : content;
	};

	const createContent = (icon: string, defaultDigits: number) => (
		<>
			{wrapWith(
				FirstElementWrapper,
				<img src={icon} alt={displayedCurrency.toLowerCase()} className={`h-[${ICON_HEIGHT}]`} style={imgStyle} />
			)}
			{wrapWith(
				SecondElementWrapper,
				<span className="font-inter font-semibold text-nowrap" style={amountStyle}>
					{Prefix}
					{formatAmountAsAbbreviated(
						convertedAmount ?? 0,
						abbreviationOption,
						fractionDigits ?? defaultDigits,
						fractionDigits ?? 2
					)}
				</span>
			)}
		</>
	);

	const Content = (() => {
		switch (displayedCurrency) {
			case CurrencyOptions.LOOTIES:
				return (
					<>
						{wrapWith(
							FirstElementWrapper,
							<span className="font-inter font-semibold" style={amountStyle}>
								{Prefix}
								{convertToLootiesAndFormatAsAbbreviated(convertedAmount ?? 0, abbreviationOption)}
							</span>
						)}
						{wrapWith(
							SecondElementWrapper,
							<img src={Coin} alt="lootie coin" className={`h-[${ICON_HEIGHT}]`} style={imgStyle} />
						)}
					</>
				);
			case CurrencyOptions.US_DOLLAR:
				return createContent(DollarSign, DEFAULT_FRACTION_DIGITS.US_DOLLAR);
			case CurrencyOptions.BITCOIN:
				return createContent(Bitcoin, DEFAULT_FRACTION_DIGITS.BITCOIN);
			case CurrencyOptions.ETHERIUM:
				return createContent(Etherium, DEFAULT_FRACTION_DIGITS.ETHERIUM);
			case CurrencyOptions.SOLANA:
				return createContent(CryptoSolana, DEFAULT_FRACTION_DIGITS.SOLANA);
			case CurrencyOptions.DOGECOIN:
				return createContent(CryptoDogecoin, DEFAULT_FRACTION_DIGITS.DOGECOIN);
			default:
				return createContent(DollarSign, DEFAULT_FRACTION_DIGITS.US_DOLLAR);
		}
	})();

	return useDivWrapper ? (
		<div className={`flex items-center gap-x-[${GAP_SIZE}] text-[15px] text-white`} style={style}>
			{Content}
		</div>
	) : (
		<>{Content}</>
	);
}
