import { CheckBox } from 'components';
import { PrimaryDropdown } from 'components/common/buttons/PrimaryDropdown';
import { OrderDbItemInterface } from 'interfaces/OrderInterfaces';
import { ReturnReason } from 'interfaces/OrderReturns.interface';
import { MobileDropdown } from 'pages/MyOrders/components/MobileDropdown';
import { MobileMultiSelect } from 'pages/MyOrders/components/MobileMultiSelect';
import { extractValuesFromSelectedOptionSets } from 'pages/MyOrders/orderUtils';
import { ReturnCreationForm } from 'pages/MyOrders/ui-order.interface';
import { Controller, useFormContext } from 'react-hook-form';

import { CLOUDFRONT_IMG_URL } from '../../../../constants';

interface Props {
	orderedProduct: OrderDbItemInterface;
	index: number;
	onItemSelectionChange: (index: number) => void;
}

export function ProductRow({ orderedProduct, index, onItemSelectionChange }: Props) {
	const { control, formState } = useFormContext<ReturnCreationForm>(); // Access the form context
	const { errors } = formState;

	const options = orderedProduct.options ? extractValuesFromSelectedOptionSets(orderedProduct.options) : undefined;

	const productReturnOptionLabels =
		orderedProduct.options &&
		orderedProduct.options.length > 0 &&
		orderedProduct.options[0].optionSelections.length === 1
			? {
					placeholder: `${orderedProduct.options[0].optionSelections[0].label}s`,
					heading: `Choose ${orderedProduct.options[0].optionSelections[0].label}s you want return`,
				}
			: { placeholder: 'Specifications', heading: 'Choose Specifications you want return' };

	return (
		<div className="grid grid-cols-[30px_60px_auto] gap-x-[5px] items-center w-full">
			<Controller
				name={`items.${index}.checked`}
				control={control}
				render={({ field: { onChange, value } }) => (
					<CheckBox
						checked={value}
						onClick={() => {
							onItemSelectionChange(index);
							onChange(!value);
						}}
						strokeColor="black"
					/>
				)}
			/>

			<img
				src={`${CLOUDFRONT_IMG_URL}/${orderedProduct.itemId?.imageUrls.qualityMedium}`}
				alt={orderedProduct.itemId?.name}
				className="h-[70px] md:h-[75px] max-w-[70px] w-auto object-scale-down md:max-w-[75px] relative z-10 mx-auto"
			/>

			<div className="flex flex-col gap-y-[10px] ml-[15px]">
				<span className="font-semibold text-[15px] text-mrLootBlue line-clamp-1">{orderedProduct.itemId.name}</span>

				<div className="flex items-center gap-[10px]">
					{(!orderedProduct.options || orderedProduct.options.length === 0) && orderedProduct.quantity > 1 && (
						<Controller
							name={`items.${index}.quantity`}
							control={control}
							render={({ field: { onChange, value } }) => (
								<PrimaryDropdown
									options={Array.from(
										{ length: orderedProduct.quantity - orderedProduct.unavailableQuantity },
										(_, index) => (index + 1).toString()
									)}
									onOptionSelect={onChange}
									selectedOption={value.toString()}
									placeholder="Qty"
									containerStyle={{ width: '80px' }}
									hasError={!!errors?.items?.[index]?.quantity}
								/>
							)}
						/>
					)}

					{options && options?.length > 0 && (
						<Controller
							name={`items.${index}.selectedOptions`}
							control={control}
							render={({ field: { onChange, value } }) => (
								<MobileMultiSelect
									onChange={onChange}
									options={options}
									selectedOptions={value}
									heading={productReturnOptionLabels.heading}
									placeholder={productReturnOptionLabels.placeholder}
									hasError={!!errors?.items?.[index]?.selectedOptions}
								/>
							)}
						/>
					)}
					<Controller
						name={`items.${index}.returnReason`}
						control={control}
						render={({ field: { onChange, value } }) => (
							<MobileDropdown
								onChange={onChange}
								options={Object.values(ReturnReason)}
								value={value}
								heading="Choose a return reason"
								placeholder="Return Reason"
								hasError={!!errors?.items?.[index]?.returnReason}
							/>
						)}
					/>
				</div>
			</div>
		</div>
	);
}
