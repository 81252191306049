import { StarsIcon } from 'assets/images';

import { FeatureBanners } from './FeatureBanners';

export function MobileBannerSection() {
	return (
		<div className={`relative w-screen h-fit -left-mobile-side-padding mb-[-10px] `}>
			<div className="flex items-center gap-x-[8px] sm:gap-x-[12px] mr-auto mb-[15px] ml-mobile-side-padding">
				<img src={StarsIcon} alt={`Exclusive`} className="h-[20px] sm:h-[25px]" />
				<h2 className="font-extrabold text-white text-lg sm:text-[23px]">Exclusive</h2>
			</div>

			<FeatureBanners />
		</div>
	);
}
