/* eslint-disable max-lines-per-function */
import { faArrowLeft, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MobileBackground } from 'components/layout/background/MobileBackground';
import { MobileMenuHeader } from 'components/layout/nav/mobile/menu/MobileMenuHeader';
import { gsap } from 'gsap';
import { useBoxFromDb } from 'hooks';
import { useSlideUpAndDragToDismiss } from 'hooks/animations/useSlideUpAndDragToDismiss';
import { useDvhFallback } from 'hooks/layout/useDvhFallback';
import { useCallback, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { shallow } from 'zustand/shallow';

import { CLOUDFRONT_IMG_URL } from '../../../../constants';
import { useBoxOpeningStoreMobile } from '../../store/useBoxOpeningStoreMobile';
import { BoxOpenBtn } from './BoxOpenBtn';
import { MobileBoxImg } from './MobileBoxImg';
import { MobileBoxOpenSettingsBar } from './MobileBoxOpenSettingsBar';
import MobileItemsOfBox from './MobileItemsOfBox';
import { MobileRecommendedBoxes } from './MobileSimiliarBoxes';
import { SkeletonBoxOpenBtn } from './SkeletonBoxOpenBtn';
import { SkeletonMobileBoxOpenSettingsBar } from './SkeletonMobileBoxOpenSettingsBar';

interface Props {
	isInitSlideUpAnimationDeactivated?: boolean;
	showSkeleton?: boolean;
	boxName?: string;
	onBoxOpen: (isDemoSpin: boolean) => void;
	isBoxOpenDisabled: boolean;
	onHeaderIconClick?: () => void;
	headerIcon?: 'close' | 'back';
}

export function MobileBoxInfo({
	boxName,
	isBoxOpenDisabled,
	onBoxOpen,
	isInitSlideUpAnimationDeactivated,
	showSkeleton,
	onHeaderIconClick,
	headerIcon = 'close',
}: Props) {
	const parentRef = useRef<HTMLDivElement>(null);
	const contentRef = useRef<HTMLDivElement>(null);
	const prizeBackgroundImgRef = useRef<HTMLImageElement>(null);

	const { data: box } = useBoxFromDb(boxName);

	const navigate = useNavigate();
	const location = useLocation();

	const { setIsBoxPrizesPopupVisible, setShowSimiliarBoxesPopupOfCurrentBoxStack } = useBoxOpeningStoreMobile(
		(state) => ({
			setIsBoxPrizesPopupVisible: state.setIsBoxPrizesPopupVisible,
			setShowSimiliarBoxesPopupOfCurrentBoxStack: state.setShowSimiliarBoxesPopupOfCurrentBoxStack,
		}),
		shallow
	);

	const handleDissMiss = useCallback(() => {
		const background = location.state && location.state.background;
		if (background) {
			const prevUrl = background.pathname;
			navigate(prevUrl, { state: { prevUrl: location.pathname }, replace: true }); // Use replace to avoid stacking states
		} else {
			navigate('/', { replace: true });
		}
	}, [location, navigate]);

	const { playDismissAnimation, isInitAnimationDone } = useSlideUpAndDragToDismiss({
		ref: parentRef,
		contentRef,
		onDismiss: handleDissMiss,
		animationTime: 0.3,
		dissmissAnimationTime: 0.25,
		disableInitAnimation: isInitSlideUpAnimationDeactivated,
	});

	useEffect(() => {
		if (!showSkeleton && isInitAnimationDone && box) {
			gsap.set(prizeBackgroundImgRef.current, { opacity: 0, display: 'inline-block' });
			gsap.to(prizeBackgroundImgRef.current, { opacity: 0.2, duration: 1, ease: 'power1.inOut' });
		}
	}, [isInitAnimationDone, showSkeleton, box]);

	function handleHeaderIconClick() {
		if (onHeaderIconClick) {
			onHeaderIconClick();
		} else {
			playDismissAnimation();
		}
	}

	useDvhFallback({
		ref: parentRef,
		properties: [{ property: 'minHeight', value: 100 }],
	});

	return (
		<div
			className="flex flex-col items-center space-y-[7px] will-change-transform absolute z-40 inset-0 mt-[10px] overflow-hidden min-h-dvh"
			ref={parentRef}
		>
			<div className="w-[50px] min-h-[5px] bg-[rgba(255,255,255,0.6)] z-40 rounded-full" />
			<div
				ref={contentRef}
				className="relative bg-background overflow-y-scroll rounded-[16px_16px_0_0] overflow-hidden overscroll-none w-full"
			>
				<MobileBackground style={{ paddingBottom: '150px' }}>
					{box && (
						<img
							ref={prizeBackgroundImgRef}
							src={`${CLOUDFRONT_IMG_URL}/${box?.withoutTitlePortraitImageUrls?.qualityLow}`}
							className="hidden absolute left-0 top-0 h-[55vh] w-[115%] opacity-20 object-cover"
							alt=""
							style={{
								maskImage:
									'linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 70%, rgba(0, 0, 0, 0) 100%)',
							}}
						/>
					)}

					<div className="flex justify-center w-full px-mobile-side-padding pt-[25px]">
						<div className="max-w-[700px] w-full relative z-10 flex flex-col space-y-[24px]">
							<MobileMenuHeader
								displayLogin={true}
								onLogoClick={() => navigate('/')}
								onIconClick={handleHeaderIconClick}
								icon={<FontAwesomeIcon icon={headerIcon === 'close' ? faXmark : faArrowLeft} className="text-[17px]" />}
							/>

							<MobileBoxImg
								showSkeleton={showSkeleton || !isInitAnimationDone}
								boxName={box?.name}
								landscapeImg={box?.landscapeImageUrls?.qualityMedium}
							/>

							<div className="h-[130px] ss:h-[150px] sm:h-[155px] w-full">
								{!showSkeleton && isInitAnimationDone && (
									<div className="flex-col space-y-[17px] w-full">
										<BoxOpenBtn box={box} onClick={() => onBoxOpen(false)} isDisabled={isBoxOpenDisabled} />
										<MobileBoxOpenSettingsBar onDemoSpin={() => onBoxOpen(true)} />
									</div>
								)}

								{(showSkeleton || !isInitAnimationDone) && (
									<div className="flex items-center flex-col space-y-[17px] w-full">
										<SkeletonBoxOpenBtn />
										<SkeletonMobileBoxOpenSettingsBar />
									</div>
								)}
							</div>

							<MobileItemsOfBox
								showSkeleton={showSkeleton || !isInitAnimationDone || !box}
								items={box?.items}
								onSeeAll={() => setIsBoxPrizesPopupVisible(true)}
							/>
							<MobileRecommendedBoxes
								showSkeleton={showSkeleton || !isInitAnimationDone}
								boxName={box?.name}
								onSeeAll={() => setShowSimiliarBoxesPopupOfCurrentBoxStack(true)}
							/>
							{/*<MobileBoxDescription description={box?.description} />*/}
						</div>
					</div>
				</MobileBackground>
			</div>
		</div>
	);
}
