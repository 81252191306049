import { CopyBtn } from 'components/common/buttons/CopyBtn';
import { PrimaryDropdown } from 'components/common/buttons/PrimaryDropdown';
import { QRCodeSVG } from 'qrcode.react';

import { CryptoPayCurrency } from '../../../deposit.interface';

const networkOptions: Record<string, CryptoPayCurrency[]> = {
	usdt: [CryptoPayCurrency.USDT, CryptoPayCurrency.USDT_TRC20, CryptoPayCurrency.USDT_SOL], // Tether on Omni, Tron, and Solana
	usdc: [CryptoPayCurrency.USDC, CryptoPayCurrency.USDC_TRC20, CryptoPayCurrency.USDC_SOL], // USD Coin on Ethereum, Tron, and Solana
};

const networkLabels: Partial<Record<CryptoPayCurrency, string>> = {
	[CryptoPayCurrency.USDT]: 'Omni Layer (Bitcoin)',
	[CryptoPayCurrency.USDT_TRC20]: 'TRC20 (Tron)',
	[CryptoPayCurrency.USDT_SOL]: 'Solana',
	[CryptoPayCurrency.USDC]: 'ERC20 (Ethereum)',
	[CryptoPayCurrency.USDC_TRC20]: 'TRC20 (Tron)',
	[CryptoPayCurrency.USDC_SOL]: 'Solana',
};

const networkNames: Record<CryptoPayCurrency, string> = {
	[CryptoPayCurrency.BTC]: 'Bitcoin Network',
	[CryptoPayCurrency.ETH]: 'Ethereum Network',
	[CryptoPayCurrency.USDT]: 'Omni Layer (Bitcoin)', // Default for Omni Layer
	[CryptoPayCurrency.USDT_TRC20]: 'TRC20 Network (Tron)',
	[CryptoPayCurrency.USDT_SOL]: 'Solana Network',
	[CryptoPayCurrency.DOGE]: 'Dogecoin Network',
	[CryptoPayCurrency.SOL]: 'Solana Network',
	[CryptoPayCurrency.BNB]: 'Binance Smart Chain (BSC)',
	[CryptoPayCurrency.XRP]: 'Ripple Network',
	[CryptoPayCurrency.MATIC]: 'Polygon Network',
	[CryptoPayCurrency.USDC]: 'ERC20 Network (Ethereum)',
	[CryptoPayCurrency.USDC_TRC20]: 'TRC20 Network (Tron)',
	[CryptoPayCurrency.USDC_SOL]: 'Solana Network',
	[CryptoPayCurrency.LTC]: 'Litecoin Network',
	[CryptoPayCurrency.BITCOIN_CASH]: 'Bitcoin Cash Network',
	[CryptoPayCurrency.SHIBA]: 'Ethereum Network (ERC20)',
	[CryptoPayCurrency.TRON]: 'Tron Network',
	[CryptoPayCurrency.MONERO]: 'Monero Network',
};

// Mapping for full cryptocurrency names
const currencyFullNames: Record<CryptoPayCurrency, string> = {
	[CryptoPayCurrency.BTC]: 'Bitcoin',
	[CryptoPayCurrency.ETH]: 'Ethereum',
	[CryptoPayCurrency.USDT]: 'Tether',
	[CryptoPayCurrency.USDT_TRC20]: 'Tether',
	[CryptoPayCurrency.USDT_SOL]: 'Tether',
	[CryptoPayCurrency.DOGE]: 'Dogecoin',
	[CryptoPayCurrency.SOL]: 'Solana',
	[CryptoPayCurrency.BNB]: 'Binance Coin',
	[CryptoPayCurrency.XRP]: 'Ripple',
	[CryptoPayCurrency.MATIC]: 'Polygon',
	[CryptoPayCurrency.USDC]: 'USD Coin',
	[CryptoPayCurrency.USDC_TRC20]: 'USD Coin',
	[CryptoPayCurrency.USDC_SOL]: 'USD Coin',
	[CryptoPayCurrency.LTC]: 'Litecoin',
	[CryptoPayCurrency.BITCOIN_CASH]: 'Bitcoin Cash',
	[CryptoPayCurrency.SHIBA]: 'Shiba Inu',
	[CryptoPayCurrency.TRON]: 'Tron',
	[CryptoPayCurrency.MONERO]: 'Monero',
};

interface Props {
	walletAddress: string;
	onSelectedNetworkChange: (network: CryptoPayCurrency) => void;
	selectedCurrency: CryptoPayCurrency;
	memo?: string;
}

export function WalletInformation({ walletAddress, onSelectedNetworkChange, selectedCurrency, memo }: Props) {
	const networks = networkOptions[selectedCurrency.split('.')[0]] || [selectedCurrency];

	return (
		<div className="flex flex-col items-center gap-y-[15px] w-full">
			{networks.length > 1 && (
				<div className="flex items-center justify-center gap-x-[10px] mx-auto my-[10px]">
					<span className="text-white ms:text-[19px]">Choose Network:</span>
					<PrimaryDropdown
						options={networks.map((network) => ({
							name: networkLabels[network] || networkNames[network],
							value: network,
						}))}
						onOptionSelect={(value) => onSelectedNetworkChange(value as CryptoPayCurrency)}
						selectedOption={networkLabels[selectedCurrency] || networkNames[selectedCurrency]}
					/>
				</div>
			)}

			<span className="text-center text-white ms:text-[19px]">{networkNames[selectedCurrency]}</span>

			<div className="mx-auto p-4 bg-white w-fit rounded-[8px] shadow-sm mb-[15px] mt-[15px]">
				<QRCodeSVG className="h-[140px] w-[140px] ms:h-[160px] ms:w-[160px]" value={walletAddress} />
			</div>
			{memo && (
				<div className="flex flex-col gap-y-[5px] ms:gap-y-[8px] mb-[15px] text-white ms:text-[20px]">
					<span className="text-white text-center font-semibold ms:text-[19px]">Deposit Destination Tag (Memo)</span>
					<div className="relative py-[4px] mx-auto flex items-center justify-center w-fit h-[44px] ms:h-[55px] border-[1px] border-[#3559D7] outline outline-[2px] outline-white bg-secondary-gradient pl-[15px] pr-[60px] rounded-[20px]">
						<span>{memo}</span>
						<div className="absolute right-0 top-0 flex items-center justify-center h-full w-[45px] ms:w-[55px] rounded-r-[20px] hover:bg-[rgba(255,255,255,0.1)] border-l-[1px] border-white">
							<CopyBtn textToCopy={memo} />
						</div>
					</div>
				</div>
			)}
			<div className="flex flex-col gap-y-[10px] text-white max-w-full w-full ms:text-[20px]">
				<span className="text-white text-center font-semibold">Deposit Address</span>
				<div className="relative mx-auto flex py-[4px] ms:py-[8px] items-center justify-center w-fit max-w-[90%] min-h-[44px] ms:min-h-[55px] border-[1px] border-[#3559D7] outline outline-[2px] outline-white bg-secondary-gradient pl-[15px] pr-[60px] rounded-[20px] break-words">
					<span className="break-words text-center max-w-full">{walletAddress}</span>
					<div className="absolute right-0 top-0 flex items-center justify-center h-full w-[45px] ms:w-[55px] rounded-r-[20px] hover:bg-[rgba(255,255,255,0.1)] border-l-[1px] border-white">
						<CopyBtn textToCopy={walletAddress} />
					</div>
				</div>
			</div>

			<span className="text-[rgba(255,255,255,0.9)] mt-[15px] text-center ms:text-[18px]">
				Only send <strong className="text-white">{currencyFullNames[selectedCurrency]}</strong> via the{' '}
				<strong className="text-white">{networkNames[selectedCurrency]}</strong> to this address
			</span>
			<span className="text-center mt-[5px] text-[rgba(255,255,255,0.9)] ms:text-[18px]">
				Only deposits of <strong className="text-white">$10</strong> or more will be credited to your account
			</span>

			<span className="text-center mt-[5px] text-[rgba(255,255,255,0.9)] ms:text-[18px]">
				Transactions are typically confirmed within 5 minutes. If your transaction takes longer, please remain patient
				as it may take up to 30 minutes to complete.
			</span>
		</div>
	);
}
