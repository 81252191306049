import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useWindowDimensions } from 'hooks/utility/layout-measurements/useWindowDimensions';

import { screens } from '../../../constants';

interface Props {
	currentStep: number;
	onStepChange: (step: number) => void;
}

const STEP_HEADINGS = ['1. Shipping', '2. Order Assignment', '3. Review'];
const MEDIUM_STEP_HEADINGS = ['1. Ship.', '2. Ord. Assign.', '3. Review'];
const SHORT_STEP_HEADINGS = ['1.', '2.', '3.'];
export function StepPagination({ currentStep, onStepChange }: Props) {
	const { screenWidth } = useWindowDimensions();
	const displayedHeadings =
		screenWidth >= screens.mds.width
			? STEP_HEADINGS
			: screenWidth >= screens.ss.width
				? MEDIUM_STEP_HEADINGS
				: SHORT_STEP_HEADINGS;

	return (
		<div className="flex items-center space-x-[12px] text-white text-[13px]">
			{displayedHeadings.map((el, index) => (
				<button
					key={el}
					disabled={currentStep <= index + 1}
					onClick={() => onStepChange(index + 1)}
					className={`${index + 1 === currentStep ? 'bg-mrLootBlue text-white' : 'bg-white text-black'} ${currentStep > index + 1 && 'hover:bg-[rgba(255,255,255,0.75)]'} flex items-center justify-center space-x-[10px] rounded-full px-[15px] h-[30px]`}
				>
					<span className="font-semibold">{el}</span>
					{index + 1 !== STEP_HEADINGS.length && <FontAwesomeIcon icon={faAngleRight} className="" />}
				</button>
			))}
		</div>
	);
}
