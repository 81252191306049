import {
	BigLootieBag,
	Font25Dollar,
	Font50Dollar,
	Font100Dollar,
	Font250Dollar,
	Font500Dollar,
	FontBestDeal,
	FontPopular,
	Icon1000Dollarz,
	LootieChest,
	LootiePile,
	LootieUniverse,
	MagnificientLootieChest,
	SmallLootieBag,
} from 'assets/images';
import { PaymentMethod } from 'pages/TransactionManager/transaction.interface';
import { useFormContext } from 'react-hook-form';

import { FormData } from '../../DepositPopup';
import { AmountOptionCard } from './AmountOptionCard';
import { CustomAmountInput } from './CustomAmountInput';

const depositOptions = [
	{
		amount: 25,
		amountImg: Font25Dollar,
		bonus: 0.1,
		image: LootiePile,
		mainImgHeightBig: '52px',
		mainImgHeightSmall: '42px',
		label: '',
	},
	{
		amount: 50,

		amountImg: Font50Dollar,
		bonus: 0.1,
		image: SmallLootieBag,
		mainImgHeightBig: '60px',
		mainImgHeightSmall: '50px',
	},
	{
		amount: 100,

		amountImg: Font100Dollar,
		bonus: 0.2,
		image: BigLootieBag,
		mainImgHeightBig: '60px',
		mainImgHeightSmall: '50px',
		labelImg: FontPopular,
		labelStyle: { background: 'linear-gradient(331.41deg, #FFBA4B 6.78%, #F46702 204.87%)' },
	},
	{
		amount: 250,
		amountImg: Font250Dollar,
		bonus: 0.2,
		image: LootieChest,
		mainImgHeightBig: '60px',
		mainImgHeightSmall: '50px',
		labelImg: FontPopular,
		labelStyle: { background: 'linear-gradient(331.41deg, #FFBA4B 6.78%, #F46702 204.87%)' },
	},
	{
		amount: 500,
		amountImg: Font500Dollar,
		bonus: 0.2,
		image: MagnificientLootieChest,
		mainImgHeightBig: '60px',
		mainImgHeightSmall: '50px',
		label: '',
	},
	{
		amount: 1000,
		amountImg: Icon1000Dollarz,
		bonus: 0.2,
		image: LootieUniverse,
		mainImgHeightBig: '52px',
		mainImgHeightSmall: '42px',
		labelImg: FontBestDeal,
		labelStyle: { background: 'linear-gradient(331.41deg, #CC00FF 6.78%, #F46702 204.87%)' },
	},
];

export function DepositAmountPanel() {
	const { watch } = useFormContext<FormData>();
	const selectedPaymentMethod = watch('paymentMethod');

	return (
		<div
			className={`border-[2px] border-white rounded-[20px] w-full flex flex-col items-center gap-y-[15px] px-4 pt-[25px] pb-[16px] `}
		>
			<div className="w-full grid grid-cols-3 gap-x-4 gap-y-5">
				{depositOptions.map((option, index) => (
					<AmountOptionCard key={index} option={option} />
				))}
			</div>
			{selectedPaymentMethod !== PaymentMethod.GIFT_CARD && <CustomAmountInput />}
		</div>
	);
}
