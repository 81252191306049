import { ItemCard } from 'components';
import { useItems } from 'hooks/data/items/useItems';

const DEMO_ITEMS = [
	{
		_id: '65a1fa9d9af22845fe784a71',
		name: 'Heart Love Crossbody Bag (2022)',
	},
	{
		_id: '64a6e15edfad8e9692a4d0b0',
		name: 'Playstation 5 Controller',
	},
	{
		_id: '657649623b168a983ed74de3',
		name: 'Playstation 5 Disc Edition',
	},
	{
		_id: '659f0b45a148cccb2a0f3053',
		name: 'Jordan 4 Retro (W)',
	},

	{
		_id: '6599d173723a5c869ee75d23',
		name: 'Grand Theft Auto 5 - Playstation',
	},
	{
		_id: '6692365285dc8ef7345a20d9',
		name: 'Unisex Defender 4 Large Duffel Bag',
	},
	{
		_id: '6679fa7358fed145cbf0cbc3',
		name: 'C3 Series 65-Inch Class OLED evo 4K Processor Smart Flat Screen TV',
	},
	{
		_id: '6679b4f658fed145cbf0a422',
		name: '$100 Netflix eGift Card',
	},
	{
		_id: '669fc4925dccd58ed9280020',
		name: 'Instax Mini 11 Instant Camera - Sky Blue',
	},
	{
		_id: '65b2feaf5304a7cf5380cf5e',
		name: 'LeBron James Los Angeles Lakers 2021 Christmas Day Game Worn Jersey',
	},
	{
		_id: '6667179cb00db9ea006fb75d',
		name: '1999 Pokemon Base Set Shadowless 1st Edition Holo Poliwrath #13 PSA 10 GEM MINT',
	},
	{
		_id: '65b553385304a7cf53816286',
		name: 'Medusa 95 Mid Slingback Pumps',
	},
	{
		_id: '6665c47389bcb4cf5621cda0',
		name: 'VENGEANCE i7500 Gaming PC: Intel Core i7-14700KF, NVIDIA RTX 4080 Super, 32GB DDR5 6000MT/s Memory, 2TB NVMe SSD',
	},
	{
		_id: '6698536185dc8ef7345fa188',
		name: 'Nintendo Switch Deluxe Minecraft Bundle',
	},
	{
		_id: '65764ca4f8b224d6b8a84396',
		name: 'Technic Sián FKP 37',
	},
	{
		_id: '65b3f1d65304a7cf5380e03a',
		name: 'Red Bull Energy Drink, 8.4 Fl Oz, 24 Cans',
	},
	{
		_id: '65b99a175304a7cf53829ae9',
		name: 'Google Pixel 8 Pro',
	},
	{
		_id: '66741caedbda1c208169916a',
		name: "Men's Sport Big Croc Semi Fancy Microfiber Cap",
	},
	{
		_id: '65b7ed385304a7cf5381e96d',
		name: 'Muse Eau De Parfum Spray 3.4 oz',
	},
	{
		_id: '669feb5f5dccd58ed9289437',
		name: 'Freestyle Edge RGB Split Mechanical USB Keyboard MX Brown',
	},
];

export function NoSessionDemoItems() {
	const { data: items } = useItems({ ids: DEMO_ITEMS.map((item) => item._id) });
	return (
		<div className="sm:py-[15px] sm:px-[15px] lg:px-[25px] lg:py-[25px] grid grid-cols-2 xs:grid-cols-3 ss:grid-cols-4 sm:grid-cols-5 mds:grid-cols-6 md:grid-cols-4 lg:grid-cols-5 lgx:grid-colds-6 xl:grid-cols-7 sm:gap-[15px] lg:gap-[25px] gap-x-[30px] gap-y-[30px] pb-[40px]">
			{items?.map((item, index) => <ItemCard item={item} key={index} style={{ width: '100%' }} showBrandname={true} />)}
		</div>
	);
}
