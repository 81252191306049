/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { faRightFromBracket, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Icon } from '@iconify/react';
import { BlueMFont } from 'assets/images';
import { Line } from 'components/common/Line';
import { useUserContext } from 'hooks';
import { useLogout } from 'hooks/data/user/useLogout';
import { useWindowDimensions } from 'hooks/utility/layout-measurements/useWindowDimensions';
import { useSearchStore } from 'pages/search/store/SearchStore';
import { useCallback, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAppStore } from 'store/useAppStore';
import { usePopupStore } from 'store/usePopupStore';
import { shallow } from 'zustand/shallow';

import { screens } from '../../../../constants';

const NAVIGATION_ICONS = [
	{ iconName: 'mdi:compass-outline', pathname: '/discover', label: 'Discover' },
	{
		iconName: 'fluent:people-community-20-filled',
		pathname: '/community',
		label: 'Community',
	},
	{
		iconName: 'tabler:shirt-filled',
		pathname: '/shop',
		label: 'Merch Store',
	},
];

export function SidebarDesktop() {
	const location = useLocation();

	const { load: loadUser, user } = useUserContext();
	const { mutate: logoutUser, isLoading: isLogoutLoading } = useLogout({
		onSuccess: () => {
			loadUser();
		},
	});

	const [isMenuExpanded, setIsMenuExpanded] = useState(false);

	const { resetStore } = useSearchStore();
	const resetScrollPosition = useAppStore((state) => state.resetScrollPosition, shallow);

	const { screenWidth } = useWindowDimensions();

	// eslint-disable-next-line no-magic-numbers
	const ICONHEIGHT = screenWidth >= screens.xl.width ? 24 : 22;
	// eslint-disable-next-line no-magic-numbers
	const EXPAND_MENU_ICON_HEIGHT = screenWidth >= screens.xl.width ? 28 : 24;

	function resetNavigationIcons() {
		resetStore();
		resetScrollPosition();
	}

	const newWonItemIds = useAppStore((state) => state.newWonItemIds, shallow);
	const setIsDepositPopupVisible = usePopupStore((state) => state.setIsDepositPopupVisible, shallow);

	const ACCOUNT_ICONS = useMemo(
		() => [
			{
				iconName: 'material-symbols:inventory-2-outline',
				pathname: '/inventory',
				label: 'Inventory',
			},
			{
				iconName: 'solar:wallet-money-outline',

				label: 'Wallet',
				onClick: () => setIsDepositPopupVisible(true),
			},
			{ iconName: 'carbon:delivery-parcel', pathname: '/my-account/my-orders', label: 'Orders' },
		],
		[setIsDepositPopupVisible]
	);

	const navigate = useNavigate();

	const handleAccountElClick = useCallback(
		(event: string | (() => void)) => {
			if (typeof event === 'string') {
				navigate(event);
			} else {
				event();
			}
		},
		[navigate]
	);

	return (
		<div className="fixed left-0 top-0 z-40 flex">
			{/** not expanded menu */}
			<div
				className={`h-[100vh] w-[63px] bg-black flex flex-col overflow-y-auto items-center pb-6 space-y-6 xl:space-y-7`}
				style={{
					background: 'linear-gradient(180deg, #2981E9 10%, #000000 65.37%)',
				}}
			>
				<div className="flex flex-col items-center space-y-4 xl:space-y-5">
					<button
						onClick={() => setIsMenuExpanded((prev) => !prev)}
						className="text-[rgba(255,255,255,0.7)] hover:text-white h-[63px]"
					>
						<Icon
							icon={isMenuExpanded ? 'ri:expand-left-line' : 'ri:expand-right-line'}
							height={EXPAND_MENU_ICON_HEIGHT}
						/>
					</button>
					{!isMenuExpanded && (
						<>
							<Line style={{ width: '22px', borderColor: 'rgba(255, 255, 255, 0.55)', marginTop: '0px' }} />
							<Link
								to="/"
								className={`h-[30px] flex items-center ${location.pathname === '/' ? 'opacity-100' : 'opacity-70 hover:opacity-100'} `}
							>
								<img src={BlueMFont} className="h-[19px] w-auto" alt="Home" />
							</Link>
							{NAVIGATION_ICONS.map((el, index) => (
								<Link
									key={index}
									to={el.pathname}
									onClick={resetNavigationIcons}
									className={`${location.pathname === el.pathname ? 'text-white' : 'text-[rgba(255,255,255,0.7)]'}  hover:text-white relative h-[30px]`}
								>
									<Icon icon={el.iconName} height={ICONHEIGHT} />
								</Link>
							))}

							{user && (
								<>
									<Line style={{ width: '22px', borderColor: 'rgba(255, 255, 255, 0.55)' }} />
									<div className="flex flex-col space-y-4 xl:space-y-5 items-center">
										{ACCOUNT_ICONS.map((el, index) => (
											<button
												key={index}
												onClick={() => handleAccountElClick(el.onClick ?? el.pathname)}
												className={`relative ${location.pathname === el.pathname ? 'text-white' : 'text-[rgba(255,255,255,0.7)]'} hover:text-white h-[30px]`}
											>
												{newWonItemIds && newWonItemIds.length > 0 && el.label === 'Inventory' && (
													<div className="absolute -right-[2px] -top-[3px] w-[8px] h-[8px] bg-mrLootBlue animate-pulse rounded-full" />
												)}
												<Icon icon={el.iconName} height={ICONHEIGHT} />
											</button>
										))}
									</div>
								</>
							)}

							{user && (
								<>
									<Line style={{ width: '22px', borderColor: 'rgba(255, 255, 255, 0.55)' }} />
									<div className="flex flex-col space-y-4 xl:space-y-5 items-center">
										<Link
											to="my-account/edit-profile"
											className={`${location.pathname === 'settings' ? 'text-white' : 'text-[rgba(255,255,255,0.7)]'} hover:text-white h-[30px]`}
										>
											<FontAwesomeIcon icon={faUser} className="text-[20px] xl:text-[22px] mx-auto" />
										</Link>
										<button
											className="relative text-[rgba(255,255,255,0.7)] hover:text-white h-[30px]"
											onClick={() => logoutUser()}
											disabled={isLogoutLoading}
										>
											<FontAwesomeIcon icon={faRightFromBracket} className="mx-auto text-[20px] xl:text-[22px]" />
											{isLogoutLoading && (
												<FontAwesomeIcon
													icon={faSpinner}
													className={`absolute text-[12px] right-0 top-[2px] animate-spin`}
												/>
											)}
										</button>
									</div>
								</>
							)}
						</>
					)}
				</div>
			</div>

			{/** expaned menu */}
			{isMenuExpanded && (
				<div
					className="mt-[63px] h-[calc(100vh-60px)] w-[214px] xl:w-[256px] text-[0.9rem] xl:text-[1.1rem] bg-black flex flex-col py-4 px-5 overflow-y-auto space-y-10"
					style={{
						background: 'linear-gradient(180deg, #2981E9 10%, #000000 65.37%)',
					}}
				>
					<div className="flex flex-col space-y-2 xl:space-y-5">
						<h2 className="text-[1.1rem] xl:text-[1.3rem] text-[rgba(255,255,255,0.5)]">Menu</h2>
						<Link
							to="/"
							className={` ${location.pathname === '/' ? 'opacity-100' : 'opacity-70 hover:opacity-100'} text-white h-[30px] grid grid-cols-[25px_auto] gap-x-[8px] items-center`}
						>
							<img src={BlueMFont} className="h-[19px] w-auto mx-auto" alt="Home" />
							<span className="">Home</span>
						</Link>
						{NAVIGATION_ICONS.map((el, index) => (
							<Link
								key={index}
								to={el.pathname}
								className={`${location.pathname === el.pathname ? 'text-white' : 'text-[rgba(255,255,255,0.7)]'} hover:text-white  h-[30px] grid grid-cols-[25px_auto] gap-x-[8px] items-center`}
							>
								<Icon icon={el.iconName} height={ICONHEIGHT} className="mx-auto" />
								<span className="">{el.label}</span>
							</Link>
						))}
					</div>
					{user && (
						<div className="flex flex-col space-y-2 xl:space-y-5">
							<h2 className="text-[1.1rem] xl:text-[1.3rem] text-[rgba(255,255,255,0.5)]">Account</h2>
							{ACCOUNT_ICONS.map((el, index) => (
								<button
									key={index}
									onClick={() => handleAccountElClick(el.onClick ?? el.pathname)}
									className={`${location.pathname === el.pathname ? 'text-white' : 'text-[rgba(255,255,255,0.7)]'} hover:text-white h-[30px] grid grid-cols-[25px_auto] gap-x-[8px] items-center`}
								>
									<Icon icon={el.iconName} height={ICONHEIGHT} className="mx-auto" />
									<span className="">{el.label}</span>
								</button>
							))}
						</div>
					)}
					{user && (
						<div className="flex flex-col space-y-2 xl:space-y-5">
							<h2 className="text-[1.1rem] xl:text-[1.3rem] text-[rgba(255,255,255,0.5)]">General</h2>
							<Link
								to="settings"
								className={`${location.pathname === 'settings' ? 'text-white' : 'text-[rgba(255,255,255,0.7)]'} hover:text-white h-[30px] grid grid-cols-[25px_auto] gap-x-[8px] items-center`}
							>
								<FontAwesomeIcon icon={faUser} className="text-[22px] xl:text-[24px] mx-auto" />
								<span className="text-left ">Settings</span>
							</Link>
							<button
								className="relative text-[rgba(255,255,255,0.7)] hover:text-white h-[30px] grid grid-cols-[25px_auto] gap-x-[8px] items-center"
								onClick={() => logoutUser()}
								disabled={isLogoutLoading}
							>
								<FontAwesomeIcon icon={faRightFromBracket} className="mx-auto text-[22px] xl:text-[24px]" />
								<span className="text-left">Logout</span>
								{isLogoutLoading && (
									<FontAwesomeIcon icon={faSpinner} className={`absolute text-[12px] right-0 top-[2px] animate-spin`} />
								)}
							</button>
						</div>
					)}
				</div>
			)}
		</div>
	);
}
