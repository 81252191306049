import { Line } from 'components/common/Line';
import { LoadSpinner } from 'components/common/loading/LoadSpinner';
import { EmptyInventory } from 'pages/MyOrders/components/EmptyInventory';
import { Fragment } from 'react/jsx-runtime';

import { useOrdersByStatus } from '../../../hooks/useOrdersByStatus';
import { UIOrderStatus } from '../../../ui-order.interface';
import { OrderInfoBlock } from '../OrderInfoBlock/OrderInfoBlock';

interface Props {
	filteredStatus: UIOrderStatus;
}

export function OrderList({ filteredStatus }: Props) {
	const { data: orders, isLoading } = useOrdersByStatus({ status: filteredStatus });

	return (
		<div className="flex flex-col gap-y-[12px] w-screen relative min-h-[calc(100svh-230px)]">
			{orders &&
				orders?.map((order, index) => (
					<Fragment key={order._id}>
						<OrderInfoBlock order={order} />
						{index !== orders.length - 1 && <Line />}
					</Fragment>
				))}
			{isLoading && (
				<div className="absolute left-0 top-0 pb-[50px] h-full w-full flex items-center justify-center">
					<LoadSpinner />
				</div>
			)}
			{orders?.length === 0 && !isLoading && <EmptyInventory />}
		</div>
	);
}
