import { MobileSignupBoxBonusPopup } from 'components/PopupHandler/signupBonus/MobileSignupBoxBonusPopup';
import { useUserContext } from 'hooks';
import { MobileDepositBottomSheet } from 'pages/TransactionManager/mobile/MobileDepositBottomSheet';
import { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { SIGNUP_OFFER_TIME, usePopupStore } from 'store/usePopupStore';
import { shallow } from 'zustand/shallow';

import { StickySignupBonusBoxLink } from './signupBonus/StickySignupBonusBoxLink';
import { StickySignupBonusTimer } from './signupBonus/StickySignupBonusTimer';

const SHOW_SIGNUP_BONUS_DELAY = 5000; // 5 seconds in ms

export function MobilePopupHandler() {
	const { setSignupBonusExpiredAt, setsSignupBonusPopupVisible, signupBonusExpiredAt } = usePopupStore(
		(state) => ({
			setsSignupBonusPopupVisible: state.setIsSignupBonusPopupVisible,
			signupBonusExpiredAt: state.signupBonusExpiredAt,
			setSignupBonusExpiredAt: state.setSignupBonusExpiredAt,
		}),
		shallow
	);

	const { user } = useUserContext();

	// create initial Signup bonus offer
	useEffect(() => {
		if (signupBonusExpiredAt === null) {
			setTimeout(() => {
				setsSignupBonusPopupVisible(true);
				const expirationDate = new Date(new Date().getTime() + SIGNUP_OFFER_TIME);
				setSignupBonusExpiredAt(expirationDate);
			}, SHOW_SIGNUP_BONUS_DELAY);
		}
	}, [setSignupBonusExpiredAt, setsSignupBonusPopupVisible, signupBonusExpiredAt]);

	const portalRoot = document.getElementById('portal-popup-root');

	if (!portalRoot) {
		console.error('Portal root not found. Make sure the element with id "portal-popup-root" exists in your HTML.');
		return null;
	}

	return ReactDOM.createPortal(
		<>
			{!user && <MobileSignupBoxBonusPopup />}
			{!user && <StickySignupBonusTimer />}
			{user && <StickySignupBonusBoxLink />}

			<MobileDepositBottomSheet />
		</>,
		portalRoot
	);
}
