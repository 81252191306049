import axios from 'axios';
import { ShippingAddressInterface } from 'interfaces/OrderInterfaces';
import { useMutation } from 'react-query';

import { API_DOMAIN } from '../../../constants';
import { GroupedOrderItemInterface } from '../InterfacesCheckout';

async function createOrderRequest(
	isExpressShipping: boolean,
	shippingAddress?: ShippingAddressInterface,
	items?: GroupedOrderItemInterface[]
) {
	if (!items || !shippingAddress) {
		throw new Error('Properties of Order are undefined');
	}

	try {
		const transformedItems = items.map((el) => {
			const options = el.selectedOptions?.slice(0, el.quantity).map((itemOption) => ({
				optionSelections: itemOption.optionSelections.map((singleItemOption) => ({
					label: singleItemOption.label,
					optionProperty: singleItemOption.optionProperty,
					value: singleItemOption.value,
				})),
			}));

			return {
				inventoryItemIds: el.selectedInventoryItemIds,
				itemId: el.item._id,
				quantity: el.quantity,
				options: options,
				note: el.note,
			};
		});
		const response = await axios.post(
			`${API_DOMAIN.mrlootBackend}/api/order/create`,
			{ shippingAddress, isExpressShipping, items: transformedItems },
			{ withCredentials: true }
		);
		if (response.data.status !== 'success') {
			if (response.data.message) {
				throw new Error(response.data.message);
			} else {
				throw new Error('An unexpected error occurred');
			}
		}
	} catch (err) {
		console.error(err);
		throw err;
	}
}

interface Props {
	shippingAddress?: ShippingAddressInterface;
	isExpressShipping: boolean;
	items?: GroupedOrderItemInterface[];
}

interface CallbackProps {
	onError: (message: string) => void;
	onSuccess: () => void;
}

export function useCreateOrder(
	{ shippingAddress, isExpressShipping, items }: Props,
	{ onError, onSuccess }: CallbackProps
) {
	return useMutation(() => createOrderRequest(isExpressShipping, shippingAddress, items), {
		onError: (err: Error) => onError(err.message || 'Something went wrong'),
		onSuccess: () => onSuccess(),
	});
}
