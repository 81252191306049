/* eslint-disable max-lines */
/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import { ToastErrorNotification } from 'components/toasts/ToastErrorNotification';
import { useUserContext } from 'hooks';
import { useWindowDimensions } from 'hooks/utility/layout-measurements/useWindowDimensions';
import { ItemInterface } from 'interfaces/ItemInterfaces';
import { ShippingAddressInterface } from 'interfaces/OrderInterfaces';
import { StepPagination } from 'pages/checkout/components/StepPagination';
import { useMerchStore } from 'pages/Shop/MerchStore';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useQueryClient } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { QUERY_KEYS, screens } from '../../constants';
import { MIN_ORDER_VALUE } from './checkout.constant';
import { BackToPrevSite } from './components/BackToPrevSite';
import { ExpressShippingBtn } from './components/ExpressShippingBtn';
import { ManageShippingAddress } from './components/ManageShippingAddress/ManageShippingAddress';
import { ExpressShippingSidebar } from './components/Sidebar/ExpressShippingSidebar';
import { Sidebar } from './components/Sidebar/Sidebar';
import { Step0 } from './components/step0/Step0';
import { Step2 } from './components/step2/Step2';
import { Step3 } from './components/step3/Step3';
import { useCreateOrder } from './hooks/useCreateOrder';
import { useDynamicPaddingSidebar } from './hooks/useDynamicPaddingSidebar';
import { useGroupedCheckoutItems } from './hooks/useGroupAndPopulateInventoryItems';
import { FailedItemsMap, GroupedOrderItemInterface, SelectedItemOption } from './InterfacesCheckout';
import {
	adjustInventoryIds,
	adjustSelectedOptions,
	calculateTotalAdditionalPurchaseValue,
	getInvalidOptions,
	hasFailedItems,
	initializeSelectedOptions,
} from './util';

const STEPS = {
	OVERVIEW: 0,
	ADDRESS: 1,
	ASSIGNMENT: 2,
	REVIEW: 3,
};

export function DesktopCheckout() {
	const location = useLocation();
	const navigate = useNavigate();
	const [cookies, setCookie] = useCookies(['selectedAddress']);

	const { user, load: reloadUser } = useUserContext();

	const [currentStep, setCurrentStep] = useState(0);

	const [selectedAddress, setSelectedAddress] = useState<ShippingAddressInterface | undefined>();
	const [isExpressShipping, setIsExpressShipping] = useState(false);
	const [isShippingPolicyChecked, setIsShippingPolicyChecked] = useState(false);
	const [selectedOrderItems, setSelectedOrderItems] = useState<GroupedOrderItemInterface[] | undefined>();

	const [optionErrors, setOptionErrors] = useState<FailedItemsMap>({});

	const sidebarRef = useRef<HTMLDivElement>(null);
	const mainContentRef = useRef<HTMLDivElement>(null);

	const inventoryItemIds: string[] | undefined = useMemo(
		() => location.state && location.state.inventoryItemIds,
		[location.state]
	);

	const directPurchaseItems: GroupedOrderItemInterface[] | undefined = useMemo(
		() => location.state && location.state.directPurchaseItems,
		[location.state]
	);

	const sortedSelectedOrderItems = selectedOrderItems?.sort(
		(a, b) => b.item.price * b.quantity - a.item.price * a.quantity
	);

	const { totalProductValue, shippingCosts, totalAdditionalPurchaseValue, totalProductCosts } =
		calculateTotalAdditionalPurchaseValue(selectedOrderItems ?? [], isExpressShipping);

	const isCheckoutDisabled =
		currentStep === 1 ? !selectedAddress : currentStep === 2 ? !isShippingPolicyChecked : false;

	const { data: groupedInventoryItems } = useGroupedCheckoutItems({
		ids: inventoryItemIds,
	});

	const queryClient = useQueryClient();
	const { clearBasket } = useMerchStore();

	const { mutate: createOrderMutate, isLoading: isCreateOrderRequestLoading } = useCreateOrder(
		{
			shippingAddress: selectedAddress,
			isExpressShipping,
			items: selectedOrderItems,
		},
		{
			onError: (err) => {
				toast(<ToastErrorNotification message={err} />);
			},
			onSuccess: () => {
				reloadUser();
				clearBasket();
				queryClient.invalidateQueries(QUERY_KEYS.orderData);
				navigate('/order/thank-you');
			},
		}
	);

	const { screenWidth } = useWindowDimensions();
	useDynamicPaddingSidebar(mainContentRef, sidebarRef);

	// just for Init of selected Address
	useEffect(() => {
		if (!user || user.addresses?.length === 0) {
			return;
		}

		const newSelectedAddress = user.addresses.find(
			(userAddress) => cookies.selectedAddress && userAddress._id === cookies.selectedAddress._id
		);

		setSelectedAddress(newSelectedAddress ?? user.addresses[0]);
	}, [cookies, user]);

	// init selectedOrderItems
	useEffect(() => {
		if (
			(groupedInventoryItems && groupedInventoryItems.length > 0) ||
			(directPurchaseItems && directPurchaseItems.length > 0)
		) {
			const checkoutItems = directPurchaseItems ?? groupedInventoryItems ?? [];
			const initialSelectedOrderItems = checkoutItems.map((el) => {
				const quantity = el.quantity ?? el.selectedInventoryItemIds?.length ?? 0;
				return {
					item: el.item,
					quantity: quantity,
					selectedInventoryItemIds: el.selectedInventoryItemIds,
					availableInventoryItemIds: el.availableInventoryItemIds,
					selectedOptions: el.item.options ? initializeSelectedOptions(quantity, el.item.options) : undefined,
					note: '',
				};
			});
			setSelectedOrderItems(initialSelectedOrderItems);
		}
	}, [directPurchaseItems, groupedInventoryItems]);

	function handleQuantityChange(itemId: string, quantity: number) {
		setSelectedOrderItems((currentItems) =>
			(currentItems || []).map((item) => {
				if (item.item._id !== itemId) {
					return item;
				}

				const updatedOptions = adjustSelectedOptions(item, quantity);
				const updatedInventoryIds = adjustInventoryIds(item, quantity);

				return {
					...item,
					quantity,
					selectedOptions: updatedOptions,
					selectedInventoryItemIds: updatedInventoryIds,
				};
			})
		);
	}

	function handleSelectedOptionChange(itemId: string, updatedItemOption: SelectedItemOption, optionIndex: number) {
		setSelectedOrderItems((currentItems) => {
			const updatedItems = (currentItems || []).map((item) => {
				// Check if this is the item that needs its selected options updated
				if (item.item._id.toString() === itemId.toString()) {
					const updatedOptions = [...(item.selectedOptions ?? [])];

					// Get the specific option set that we are updating (based on the optionIndex)
					const selectedOptionSet = { ...updatedOptions[optionIndex] };

					// Find the option in optionSelections using _id and update it
					const updatedOptionSelections = selectedOptionSet.optionSelections.map((option) =>
						option._id === updatedItemOption._id ? updatedItemOption : option
					);

					// Update the specific option set with the new optionSelections
					updatedOptions[optionIndex] = {
						...selectedOptionSet,
						optionSelections: updatedOptionSelections,
					};

					// Return the updated item
					return { ...item, selectedOptions: updatedOptions };
				}
				return item;
			});

			if (optionErrors && hasFailedItems(optionErrors)) {
				const failedItems = getInvalidOptions(updatedItems); // Use the utility function
				setOptionErrors(failedItems);
			}

			return updatedItems;
		});
	}

	function handleRemove(item: ItemInterface) {
		const newSelectedOrderItems = selectedOrderItems?.filter((order) => order.item._id !== item._id);
		setSelectedOrderItems(newSelectedOrderItems);
	}

	function handleNoteChange(itemId: string, note: string) {
		const newSelectedOrderItems = selectedOrderItems?.map((order) => {
			return order.item._id !== itemId ? order : { ...order, note: note };
		});
		setSelectedOrderItems(newSelectedOrderItems);
	}

	function handleCheckout() {
		if (!selectedOrderItems) {
			return toast(<ToastErrorNotification message="There are no items in your cart to proceed with checkout" />);
		}

		const failedItems = getInvalidOptions(selectedOrderItems); // Use the utility function
		setOptionErrors(failedItems);

		// Check if any items have unselected options
		if (Object.keys(failedItems).length > 0) {
			return toast(<ToastErrorNotification message="Please ensure all required options are selected for each item" />);
		}

		if (user && totalAdditionalPurchaseValue > user.balance) {
			const errorMsg = isExpressShipping
				? 'Top-Up your Balance to proceed with Express-Shipping'
				: 'Top-Up your Balance to purchase additional Products';
			toast(<ToastErrorNotification message={errorMsg} />);
			return;
		}

		if (currentStep === STEPS.ADDRESS) {
			setCookie('selectedAddress', selectedAddress, { path: '/' });
		} else if (currentStep === STEPS.REVIEW) {
			createOrderMutate();
			return;
		}
		window.scroll({ top: 0 });
		setCurrentStep((prev) => prev + 1);
	}

	function handleStepChange(step: number) {
		setCurrentStep(step);
		window.scroll({ top: 0 });
	}

	return (
		<>
			<div className="flex flex-col w-[100%] pt-[25px] relative min-h-[500px] space-y-[20px] px-[35px]">
				<div className="flex items-center justify-between">
					{currentStep > 0 && <StepPagination currentStep={currentStep} onStepChange={handleStepChange} />}
					<BackToPrevSite step={currentStep} onStepChange={handleStepChange} />
					{currentStep === 0 && (
						<div className="">
							{screenWidth < screens.md.width && (
								<ExpressShippingBtn
									isExpressShipping={isExpressShipping}
									onIsExpressShippingChange={() => setIsExpressShipping((prev) => !prev)}
								/>
							)}
						</div>
					)}
				</div>

				<div
					ref={mainContentRef}
					className="flex flex-col md:flex-row relative items-start md:space-x-[30px] justify-between w-full"
				>
					<div className="w-full md:w-[calc(100%-30px-270px)]">
						{currentStep === STEPS.OVERVIEW && (
							<Step0
								showShippingFeeInfoBanner={totalProductValue < MIN_ORDER_VALUE}
								sortedOrderItems={sortedSelectedOrderItems}
								onRemove={handleRemove}
								onNoteChange={handleNoteChange}
								onQuantityChange={handleQuantityChange}
								isExpressShipping={isExpressShipping}
								numPlaceholder={inventoryItemIds?.length ?? directPurchaseItems?.length ?? 0}
								onSelectedOptionChange={handleSelectedOptionChange}
								optionErrors={optionErrors}
							/>
						)}
						{currentStep === STEPS.ADDRESS && (
							<ManageShippingAddress
								selectedAddress={selectedAddress}
								onSelectedAddressChange={(address) => setSelectedAddress(address)}
							/>
						)}
						{currentStep === STEPS.ASSIGNMENT && (
							<Step2
								isShippingPolicyChecked={isShippingPolicyChecked}
								onIsShippingPolicyCheckedChange={() => setIsShippingPolicyChecked((prev) => !prev)}
							/>
						)}
						{currentStep === STEPS.REVIEW && (
							<Step3
								address={selectedAddress}
								sortedOrderItems={sortedSelectedOrderItems}
								isExpressShipping={isExpressShipping}
							/>
						)}
					</div>

					<div
						ref={sidebarRef}
						className={`z-20 sticky bottom-[74px] sm:bottom-[15px] mt-[20px] space-y-[20px] flex-grow px-[20px] w-full md:w-[270px] md:top-[85px] md:px-0 md:mt-0`}
					>
						{currentStep === 0 && screenWidth >= screens.md.width && (
							<ExpressShippingSidebar
								isExpressShipping={isExpressShipping}
								onIsExpressShippingChange={() => setIsExpressShipping((prev) => !prev)}
							/>
						)}
						<Sidebar
							shippingCosts={shippingCosts}
							productCosts={totalProductCosts}
							onCheckout={handleCheckout}
							isCheckoutDisabled={isCheckoutDisabled}
							isLoading={isCreateOrderRequestLoading}
							step={currentStep}
						/>
					</div>
				</div>
			</div>
		</>
	);
}
