import { CommunityConstruction } from './assets';

export function Community() {
	return (
		<div className="w-full h-[calc(100svh-120px)] flex items-center justify-center ">
			<div className="h-[70svh] max-w-full border-[2px] border-[rgba(255,255,255,0.2)] rounded-[20px] overflow-hidden mb-[70px] sm:mb-0">
				<img src={CommunityConstruction} alt="comming soon" className="h-full w-full object-cover" />
			</div>
		</div>
	);
}
