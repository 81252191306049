import { useBoxOpeningStoreMobile } from 'pages/OpenBox/store/useBoxOpeningStoreMobile';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

export function useBoxScreenLeaveCleanUp() {
	const { resetStore } = useBoxOpeningStoreMobile();

	useEffect(() => {
		return () => {
			resetStore();
			toast.dismiss();
		};
	}, [resetStore]);
}
