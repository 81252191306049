import { useWindowDimensions } from 'hooks';
import { FeatureBannerInterface } from 'interfaces/FeatureBannerInterface';
import { Link } from 'react-router-dom';

import { CLOUDFRONT_IMG_URL, screens } from '../../../../constants';

interface Props {
	featureBanner: FeatureBannerInterface;
}

export function FeatureBannerCard({ featureBanner }: Props) {
	const { screenWidth } = useWindowDimensions();

	const imgSrc =
		screenWidth > screens.xl.width
			? `${CLOUDFRONT_IMG_URL}/${encodeURIComponent(featureBanner.imgPaths?.['3.6x1'] ?? '')}`
			: screenWidth > screens.lg.width
				? `${CLOUDFRONT_IMG_URL}/${encodeURIComponent(featureBanner.imgPaths?.['3.3x1'] ?? '')}`
				: `${CLOUDFRONT_IMG_URL}/${encodeURIComponent(featureBanner.imgPaths?.['3x1'] ?? '')}`;

	return (
		<Link
			to={featureBanner.redirectUrl || '/'}
			className={`${featureBanner.redirectUrl ? 'cursor-pointer' : 'cursor-default'} w-full h-auto min-w-full`}
		>
			<img src={imgSrc} alt="Feature Banner" className="h-full w-full object-contain" />
		</Link>
	);
}
