/* eslint-disable complexity */
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faChevronDown, faChevronUp, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useOutsideClick } from 'hooks';
import React, { useRef, useState } from 'react';

interface OptionInterface {
	name: string;
	value: string;
	icon?: IconDefinition;
}

interface DropdownProps {
	options: OptionInterface[] | string[];
	selectedOption?: string;
	onOptionSelect: (optionValue: string) => void;
	isLoading?: boolean;
	placeholder?: string;
	buttonStyle?: React.CSSProperties;
	containerStyle?: React.CSSProperties;
	dropdownOverlayStyle?: React.CSSProperties;
	hasError?: boolean;
}

export function PrimaryDropdown({
	options,
	selectedOption,
	onOptionSelect,
	placeholder,
	isLoading,
	buttonStyle,
	containerStyle,
	dropdownOverlayStyle,
	hasError,
}: DropdownProps) {
	const [isOpen, setIsOpen] = useState(false);
	const dropdownRef = useRef<HTMLDivElement>(null);

	// Type guard to check if option is an object with 'name' and 'value'
	const isOptionObject = (option: OptionInterface | string): option is OptionInterface => {
		return typeof option === 'object' && 'name' in option && 'value' in option;
	};

	// Type guard to check if options are OptionInterface[]
	const isOptionInterfaceArray = (arr: any[]): arr is OptionInterface[] => {
		return arr.length > 0 && typeof arr[0] === 'object' && 'value' in arr[0];
	};

	// Determine the selected option based on the type of options
	const selectedOpt = isOptionInterfaceArray(options)
		? options.find((opt) => opt.value === selectedOption)
		: options.find((opt) => opt === selectedOption);

	// Get the icon for the selected option, if available and defined
	const selectedIcon = selectedOpt && isOptionObject(selectedOpt) ? selectedOpt.icon : undefined;

	// Handle outside clicks to close dropdown
	useOutsideClick(dropdownRef, () => {
		setIsOpen(false);
	});

	// Update handleOptionSelect to pass the value
	function handleOptionSelect(value: string) {
		setIsOpen(false);
		onOptionSelect(value);
	}

	return (
		<div
			className="focus-within:z-40 relative z-30 h-[55px] w-[150px] rounded-[4px]"
			ref={dropdownRef}
			style={{ ...containerStyle }}
		>
			<button
				type="button"
				className={`w-full space-x-2 font-semibold border-[2px] ${hasError ? 'border-errorRed' : 'border-white'} flex items-center px-3 h-full py-[6px] bg-secondary-gradient rounded-[20px] text-[15px] text-white`}
				onClick={() => setIsOpen(!isOpen)}
				style={{ ...buttonStyle }}
			>
				{isLoading ? (
					<FontAwesomeIcon icon={faSpinner} className="animate-spin" />
				) : selectedIcon ? (
					<FontAwesomeIcon icon={selectedIcon} />
				) : null}
				<span className={`mx-auto flex-grow ${!selectedOption && 'text-[rgba(255,255,255,1)]'}`}>
					{selectedOpt && isOptionObject(selectedOpt) ? selectedOpt.name : selectedOption || placeholder || 'Choose'}
				</span>

				<FontAwesomeIcon
					icon={isOpen ? faChevronUp : faChevronDown}
					className="text-[14px] text-[rgba(255,255,255,0.7)]"
				/>
			</button>
			{isOpen && (
				<ul
					className="absolute -bottom-3 translate-y-[100%] bg-mrLootBlue left-0 right-0 bg-secondary-gradient border-[2px] border-white shadow-[0px_3px_8px_rgba(0,0,0,0.24)] rounded-[20px] text-white text-[15px] overflow-hidden"
					style={dropdownOverlayStyle}
				>
					<div className="z-10 max-h-[200px] overflow-auto bg-[rgba(0,0,0,0.1)] backdrop-blur-[2px] rounded-[20px]">
						{options.map((option) => (
							<li key={isOptionObject(option) ? option.value : option} className="w-full">
								<button
									type="button"
									onClick={() => handleOptionSelect(isOptionObject(option) ? option.value : option)}
									className={`flex items-center py-[6px] px-[21px] text-center w-full text-white
                                 ${isOptionObject(option) && (selectedOption === option.value ? 'bg-[rgba(255,255,255,0.2)]' : 'hover:bg-[rgba(255,255,255,0.1)]')} ${!isOptionObject(option) && (selectedOption === option ? 'bg-[rgba(255,255,255,0.2)]' : 'hover:bg-[rgba(255,255,255,0.1)]')}`}
								>
									{isOptionObject(option) && option.icon && <FontAwesomeIcon icon={option.icon} className="mr-4" />}
									<span className="text-center w-full">{isOptionObject(option) ? option.name : option}</span>
								</button>
							</li>
						))}
					</div>
				</ul>
			)}
		</div>
	);
}
