import { Amazon, AMEX, Bitcoin, BlueMFont, Etherium, Mastercard, Paypal, Stripe, Visa } from 'assets/images';

const PAYMENT_LOGOS = [
	{ imgSrc: Visa, label: 'Visa pay', bgColor: 'white' },
	{ imgSrc: AMEX, label: 'AMEX pay', bgColor: '#1F72CD' },
	{ imgSrc: Mastercard, label: 'Mastercard pay', bgColor: 'white' },
	{ imgSrc: Stripe, label: 'Stripe', bgColor: 'white' },
	{ imgSrc: Paypal, label: 'Paypal pay', bgColor: 'white' },
	{ imgSrc: Bitcoin, label: 'Bitcoin pay', bgColor: 'white' },
	{ imgSrc: Etherium, label: 'Etherium pay', bgColor: 'white' },
	{ imgSrc: Amazon, label: 'Amazon pay', bgColor: 'white' },
];

export function FooterPaymentMethods() {
	return (
		<div className="w-full flex items-center justify-center flex-wrap gap-[17px]">
			{PAYMENT_LOGOS.map((el) => (
				<div
					key={el.label}
					className={` rounded-[4px] border-[1px] border-[#D9D9D9] h-[32px] sm:h-[36px] lg:h-[40px] w-[50px] sm:w-[55px] lg:w-[64px] flex items-center justify-center`}
					style={{ backgroundColor: el.bgColor }}
				>
					<img src={el.imgSrc} alt={el.label} className={`h-[22px] object-contain max-w-[90%]`} />
				</div>
			))}
			<div
				className={` rounded-[4px] border-[1px] border-[#D9D9D9] h-[32px] sm:h-[36px] lg:h-[40px] w-[50px] sm:w-[55px] lg:w-[64px] flex flex-col items-center justify-center bg-white`}
			>
				<img src={BlueMFont} alt="MrLoot" className={`h-[14px] object-contain max-w-[90%]`} />
				<span className="text-mrLootBlue text-[8px] font-bold">Gift Card</span>
			</div>
		</div>
	);
}
