import { FacebookLogoWhite, InstagramLogoWhite, TikTokLogoWhite, XLogoWhite } from 'assets/images';

const SOCIAL_LOGOS = [
	{
		imgSrc: FacebookLogoWhite,
		label: 'Facebook',
		gradient: 'linear-gradient(0deg, #0062E0 2.92%, #19AFFF 100%)',
		link: '',
	},
	{
		imgSrc: InstagramLogoWhite,
		label: 'Instagram',
		gradient: 'linear-gradient(180deg, #FCA759 -3.12%, #E82D56 17.47%, #A22DB4 77.07%, #643DCE 105.24%)',
		link: 'https://www.instagram.com/mrloot_com/',
	},
	{
		imgSrc: TikTokLogoWhite,
		label: 'TikTok',
		gradient: 'linear-gradient(141.38deg, #000000 0.9%, #323232 99%)',
		link: 'https://www.tiktok.com/@mrloot.com',
	},
	{
		imgSrc: XLogoWhite,
		label: 'X',
		gradient: ' linear-gradient(141.38deg, #000000 0.9%, #323232 99%)',
		link: 'https://x.com/mrloot_com',
	},
];

export function FooterSocialSection() {
	return (
		<div className="w-full sm:w-[550px] md:w-[750px] lg:w-[850px] flex items-center justify-around space-x-[30px] mx-auto">
			{SOCIAL_LOGOS.map((el, index) => (
				<a
					key={el.label}
					href={el.link}
					target="_blank"
					className={`rounded-full bg-white h-[45px] lg:h-[50px] w-[45px] lg:w-[50px] flex items-center justify-center ${(index === 0 || index === SOCIAL_LOGOS.length - 1) && 'translate-y-[-70%]'} `}
					style={{ background: ' linear-gradient(141.54deg, #FBFBFC 7.37%, #DBDDE8 92.32%)' }}
					rel="noreferrer"
				>
					<div
						className="h-[80%] w-[80%] flex items-center justify-center rounded-full"
						style={{ background: el.gradient }}
					>
						<img key={el.label} src={el.imgSrc} alt={el.label} className={`h-[20px] object-contain max-w-[90%] `} />
					</div>
				</a>
			))}
		</div>
	);
}
