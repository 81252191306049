import { myUserContext } from 'context/UserContext';
import { useContext } from 'react';

export const useUserContext = () => {
	const context = useContext(myUserContext);
	if (context === undefined) {
		throw new Error('useUserContext must be used within an UserProvider');
	}

	return { ...context, user: context.user };
};
