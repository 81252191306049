import { faCheck, faCircleCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Skeleton } from 'components';
import { AmountDisplayWithCurrency } from 'components/common/AmountDisplayWithCurrency';
import { MobileBackground } from 'components/layout/background/MobileBackground';
import { MobileMenuHeader } from 'components/layout/nav/mobile/menu/MobileMenuHeader';
import { gsap } from 'gsap';
import { useFindBoxesByItem, useScrollLock } from 'hooks';
import { useSlideUpAndDragToDismiss } from 'hooks/animations/useSlideUpAndDragToDismiss';
import { ItemInterface } from 'interfaces/ItemInterfaces';
import { useInventoryStore } from 'pages/inventory/store/InventoryStore';
import { MobileBoxCard } from 'pages/overview/mobile/components/MobileBoxCard';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { shallow } from 'zustand/shallow';

import { CLOUDFRONT_IMG_URL, rarities } from '../../../../constants';

interface Props {
	item: ItemInterface;
}

const NUM_SKELETON = 3;

const rarityGradients = {
	gold: 'linear-gradient(145deg, #FFD700 -60%, rgba(255, 215, 0, 0.2) 45%, rgba(255, 215, 0, 0.2) 65%, #FFD700 160%)',
	red: 'linear-gradient(145deg, #FF0000 -40%, rgba(255, 0, 0, 0.2) 45%, rgba(255, 0, 0, 0.2) 65%, #FF0000 110%)',
	pink: 'linear-gradient(145deg, #FF1493 -50%, rgba(255, 20, 147, 0.2) 45%, rgba(255, 20, 147, 0.2) 65%, #FF1493 130%)',
	purple:
		'linear-gradient(145deg, #8A2BE2 -60%, rgba(138, 43, 226, 0.2) 45%, rgba(138, 43, 226, 0.2) 65%, #8A2BE2 160%)',
	blue: 'linear-gradient(145deg, #1E90FF -60%, rgba(30, 144, 255, 0.2) 45%, rgba(30, 144, 255, 0.2) 65%, #1E90FF 160%)',
	lightBlue:
		'linear-gradient(145deg, #00BFFF -60%, rgba(0, 191, 255, 0.2) 45%, rgba(0, 191, 255, 0.2) 65%, #00BFFF 160%)',
};

export function ItemInspectPopup({ item }: Props) {
	const { setItemOfInspectPopup } = useInventoryStore(
		(state) => ({
			setItemOfInspectPopup: state.setItemOfInspectPopup,
		}),
		shallow
	);

	const [showFullDescription, setShowFullDescription] = useState(false);

	const [isSlideUpComplete, setIsSlideUpComplete] = useState(false);

	const navigate = useNavigate();
	const blackBgRef = useRef<HTMLDivElement>(null);
	const dragRef = useRef<HTMLDivElement>(null);

	const handleDissMiss = useCallback(() => {
		setItemOfInspectPopup(null);
	}, [setItemOfInspectPopup]);

	const handleSlideUpComplete = useCallback(() => {
		setIsSlideUpComplete(true);
	}, []);

	useEffect(() => {
		const tl = gsap.timeline();
		tl.set(blackBgRef.current, { opacity: 0 });
		tl.to(blackBgRef.current, { opacity: 1, duration: 0.3 });
	}, []);

	const { playDismissAnimation } = useSlideUpAndDragToDismiss({
		ref: dragRef,
		onDismiss: handleDissMiss,
		onInitAnimationComplete: handleSlideUpComplete,
		animationTime: 0.3,
		dissmissAnimationTime: 0.25,
	});

	const { data: includedBoxes, isFetching } = useFindBoxesByItem({
		itemId: item._id,
	});

	useScrollLock();

	const isIncludedInOtherBoxes = includedBoxes && includedBoxes.length > 0;

	return (
		<div className="z-50 fixed inset-0 h-dvh">
			<div ref={blackBgRef} className="absolute inset-0 bg-[rgba(0,0,0,0.4)]" />

			<div
				ref={dragRef}
				className="mt-[10px] w-full h-full overflow-y-auto max-h-dvh rounded-[16px_16px_0_0] overscroll-none"
			>
				<div className="w-[50px] min-h-[5px] bg-[rgba(255,255,255,0.6)] z-40 rounded-full mb-[10px] mx-auto" />
				<MobileBackground>
					<div className="flex flex-col w-full h-full px-mobile-side-padding pt-[25px] gap-y-[20px] pb-[30px]">
						<MobileMenuHeader
							icon={<FontAwesomeIcon icon={faXmark} />}
							onIconClick={playDismissAnimation}
							onLogoClick={() => navigate('/')}
						/>
						<div className={`rounded-[20px] overflow-hidden w-full aspect-square max-w-[500px] bg-white mx-auto`}>
							<div
								className="relative w-full h-full flex items-center justify-center"
								style={{
									background: rarityGradients[item.rarity],
								}}
							>
								<div
									className="absolute left-0 top-0 py-[10px] px-[18px] text-[17px] rounded-[0_0_16px_0] font-bold text-white space-x-[8px]"
									style={{ background: rarities[item.rarity].color }}
								>
									<FontAwesomeIcon icon={faCheck} />
									<span>Unboxed</span>
								</div>
								<img
									src={`${CLOUDFRONT_IMG_URL}/${item.imageUrls.qualityHigh}`}
									alt={item.name}
									className="h-[70%] max-w-[80%] object-contain"
								/>
							</div>
						</div>

						<div className="flex items-center gap-x-[8px]">
							<div className="border-[1px] border-green-400 text-green-400 font-bold gap-x-[5px] flex items-center text-[13px] px-[6px] rounded-[4px] py-[2px]">
								<FontAwesomeIcon icon={faCheck} />
								<span>In Stock</span>
							</div>
							<div className="border-[1px] border-white text-white font-bold gap-x-[5px] flex items-center text-[13px] px-[6px] rounded-[4px] py-[2px]">
								<FontAwesomeIcon icon={faCircleCheck} />
								<span>100% Authentic</span>
							</div>
						</div>

						<div className="flex flex-col gap-y-[5px]">
							<h1 className="text-white font-bold text-[22px]">{item.name}</h1>
							<h1 className="text-[rgba(255,255,255,0.7)] font-semibold text-[17px]">{item.brandName}</h1>
							<AmountDisplayWithCurrency
								amount={item.price}
								style={{ fontSize: '17px' }}
								imgStyle={{ height: '17px' }}
							/>
						</div>

						{item.description && (
							<span
								className={`text-white ${!showFullDescription && 'line-clamp-3'} text-[15px] text-justify leading-6`}
								onClick={() => setShowFullDescription(true)}
								onKeyDown={(e) => {
									if (e.key === 'Enter' || e.key === ' ') {
										setShowFullDescription(true);
									}
								}}
								tabIndex={0}
								role="button"
							>
								{item.description}
							</span>
						)}

						{isIncludedInOtherBoxes && (
							<>
								<span className="text-white font-bold text-[18px]">Discover in WinBoxes:</span>
								<div className={`flex items-start gap-x-[15px] w-full overflow-x-auto pb-[15px]`}>
									{isSlideUpComplete &&
										includedBoxes &&
										includedBoxes.map((el) => <MobileBoxCard key={el._id} box={el} />)}
									{!isFetching && !isSlideUpComplete && (
										<div className="flex gap-4">
											{Array(NUM_SKELETON)
												.fill(0)
												.map((_, index) => (
													<Skeleton
														key={index}
														style={{
															width: 'auto',
															height: '222px',
															aspectRatio: '0.65 / 1',
															borderRadius: '18px',
														}}
													/>
												))}
										</div>
									)}
								</div>
							</>
						)}
					</div>
				</MobileBackground>
			</div>
		</div>
	);
}
