import { UnboxedHistoryInterface } from 'interfaces/UnboxedHistoryInterface';
import { MobileBoxCard } from 'pages/overview/mobile/components/MobileBoxCard';
import React, { forwardRef } from 'react';
import { convertToClientTime } from 'utils';

import { CLOUDFRONT_IMG_URL, rarities } from '../../../../constants';

interface Props {
	unboxedDetails: UnboxedHistoryInterface;
	onClick: () => void;
}

const WinBoxHistoryCard = forwardRef<HTMLDivElement, Props>(({ unboxedDetails, onClick }, ref) => {
	const formattedDate = convertToClientTime(unboxedDetails.createdAt.toString(), {
		year: '2-digit',
		month: '2-digit',
		day: '2-digit',
	});

	const formattedTime = convertToClientTime(unboxedDetails.createdAt.toString(), {
		hour: 'numeric',
		minute: 'numeric',
		hour12: true,
	});

	return (
		<>
			<div className="relative w-full h-auto flex flex-col gap-y-[4px]" key={unboxedDetails._id} ref={ref}>
				<div
					className="border-white flex items-center justify-center border-[1px] translate-x-[50%] translate-y-[-50%] z-10 absolute right-1 top-1 rounded-full h-[55px] ss:h-[75px] w-[55px] ss:w-[75px] "
					style={{
						background: `${rarities[unboxedDetails.wonItems[0].rarity]?.gradient}`,
					}}
				>
					<img
						src={`${CLOUDFRONT_IMG_URL}/${unboxedDetails.wonItems[0]?.imageUrls?.qualityMedium}`}
						alt={unboxedDetails.wonItems[0].name}
						className="object-scale-down h-[65%] w-[65%]"
					/>
				</div>
				<MobileBoxCard onClick={onClick} box={unboxedDetails.box} style={{ width: '100%', height: 'auto' }} />
				<span className="text-white text-[12px] ss:text-[15px] sm:text-[16px] font-semibold text-center">
					{formattedDate}
				</span>
				<span className="text-white text-[12px] ss:text-[15px] sm:text-[16px] font-semibold text-center">
					{formattedTime}
				</span>
			</div>
		</>
	);
});

export default WinBoxHistoryCard;
