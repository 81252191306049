import { gsap } from 'gsap';
import { useEffect, useRef } from 'react';

export function useFloatingActionBtnAnimation() {
	const timelineRef = useRef(gsap.timeline({ paused: true }));
	const isActionMenuVisible = useRef(false);

	const actionBtnRef = useRef<HTMLButtonElement>(null);
	const actionBtnContentRef = useRef<HTMLDivElement>(null);
	const actionBtnXMarkRef = useRef<SVGSVGElement>(null);

	const sellButtonRef = useRef<HTMLButtonElement>(null);
	const checkoutButtonRef = useRef<HTMLButtonElement>(null);

	function toggleButtonAnimation() {
		const tl = timelineRef.current;

		if (isActionMenuVisible.current) {
			tl.reverse();
		} else {
			if (tl.progress() !== 0) {
				tl.reverse();
			} else {
				const actionBtnChild =
					actionBtnRef.current?.children && actionBtnRef.current?.children.length > 0
						? actionBtnRef.current?.children[0]
						: null;
				tl.clear()
					.to(actionBtnRef.current, {
						background: 'rgba(0,0,0,0.8)',
						borderColor: 'transparent',
						width: '65px',
						duration: 0.35,
						ease: 'power1.out',
					})
					.to(
						actionBtnChild,
						{
							borderColor: 'transparent',
							background: 'transparent',
							duration: 0.35,
							ease: 'power1.out',
						},
						'<'
					)
					.set(actionBtnContentRef.current, { autoAlpha: 0 }, '<')
					.to(
						actionBtnXMarkRef.current,
						{
							rotate: '180deg',
							opacity: 1,
							duration: 0.35,
						},
						'<'
					)
					.to(
						sellButtonRef.current,
						{
							yPercent: '-100',
							y: -20,
							opacity: 1,
							duration: 0.35,
							ease: 'power1.out',
						},
						'<'
					)
					.to(
						checkoutButtonRef.current,
						{
							yPercent: '-200',
							y: -40,
							opacity: 1,
							duration: 0.35,
							ease: 'power1.out',
						},
						'<'
					);
			}
			tl.play();
		}

		isActionMenuVisible.current = !isActionMenuVisible.current;
	}

	useEffect(() => {
		const tl = timelineRef.current;
		return () => {
			tl.pause();
			tl.clear();
			tl.kill();
		};
	}, []);

	return {
		timelineRef,
		isActionMenuVisible,
		actionBtnRef,
		actionBtnContentRef,
		actionBtnXMarkRef,
		sellButtonRef,
		checkoutButtonRef,
		toggleButtonAnimation,
	};
}
