import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StarsIcon } from 'assets/images';
import { SectionHeader } from 'components/common/SectionHeader';
import { ToastErrorNotification } from 'components/toasts/ToastErrorNotification';
import { ToastSuccessNotification } from 'components/toasts/ToastSuccessNotification';
import { useUserContext } from 'hooks';
import { useBackBtnInTopBar } from 'hooks/app-layout/useBackBtnInTopbar';
import { useTopbarBackBtnListener } from 'hooks/events/useTopbarBackBtnListener';
import { useUserMailUpdate } from 'pages/AccountSettings/hooks/useUserMailUpdate';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

export function MobileChangeMail() {
	const { user, load: reloadUser } = useUserContext();
	const [email, setEmail] = useState<string>('');

	const navigate = useNavigate();

	useBackBtnInTopBar();

	useTopbarBackBtnListener(
		useCallback(() => {
			window.history.back();
		}, [])
	);

	useEffect(() => {
		if (user?.contactMail?.value) {
			setEmail(user.contactMail.value);
		}
	}, [user]);

	const { mutate: updateMail, isLoading } = useUserMailUpdate({
		onSuccess() {
			toast(<ToastSuccessNotification message="Your E-Mail has been updated" />);
			reloadUser();
			navigate('/my-account/edit-profile');
		},
		onError(message) {
			toast(<ToastErrorNotification message={message} />);
		},
	});

	const isBtnDisabled = isLoading || (user?.contactMail?.value !== undefined && email === user?.contactMail?.value);

	return (
		<div className="flex flex-col justify-between h-[calc(100dvh-100px)] pb-[80px]">
			<div className="flex flex-col gap-y-[25px]">
				<SectionHeader title="Edit Profile" iconSrc={StarsIcon} rightContent={<></>} />
				<div className="flex flex-col gap-y-[12px]">
					<span className="font-bold text-[16px] text-white">Change E-Mail Address</span>
					<input
						className="rounded-[15px] w-full h-[53px] px-[12px] text-[15px] font-semibold"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
					/>
				</div>
			</div>
			<button
				onClick={() => updateMail({ mail: email })}
				disabled={isBtnDisabled}
				className={`${isBtnDisabled && 'opacity-50'} rounded-full bg-mrLootBlue flex items-center justify-center text-white font-bold h-[56px]`}
			>
				{!isLoading && <span>Save Changes</span>}
				{isLoading && <FontAwesomeIcon icon={faSpinner} className="animate-spin" />}
			</button>
		</div>
	);
}
