import {
	NumEightNoPadding,
	NumFiveNoPadding,
	NumFourNoPadding,
	NumNineNoPadding,
	NumOneNoPadding,
	NumSevenNoPadding,
	NumSixNoPadding,
	NumTenNoPadding,
	NumThreeNoPadding,
	NumTwoNoPadding,
	TrophyIcon,
} from 'assets/images';
import { SectionHeader } from 'components/common/SectionHeader';
import { useLoadTopBoxes } from 'pages/overview/hooks/useLoadTopBoxes';
import { useState } from 'react';

import { MobileBoxCard } from '../components/MobileBoxCard';
import { RecommendedBoxPopup } from './RecommendedBoxPopup';

function getTop10Numbers(num: number): string | undefined {
	const imageMap: { [key: number]: string } = {
		1: NumOneNoPadding,
		2: NumTwoNoPadding,
		3: NumThreeNoPadding,
		4: NumFourNoPadding,
		5: NumFiveNoPadding,
		6: NumSixNoPadding,
		7: NumSevenNoPadding,
		8: NumEightNoPadding,
		9: NumNineNoPadding,
		10: NumTenNoPadding,
	};

	return imageMap[num];
}

export function MobileTop10BoxCarousel() {
	const [isOverlayVisible, setIsOverlayVisible] = useState(false);

	const { data: boxes, isLoading: isTopBoxesLoading } = useLoadTopBoxes();

	return (
		<>
			{boxes && isOverlayVisible && (
				<RecommendedBoxPopup title="Top 10" onClose={() => setIsOverlayVisible(false)} boxes={boxes} />
			)}
			{!isTopBoxesLoading && (
				<div className="flex flex-col gap-y-[5px] !mb-auto ">
					<SectionHeader
						iconSrc={TrophyIcon}
						title="Top 10"
						linkTo=""
						linkText={isOverlayVisible ? 'See Less' : 'See All'}
						onLinkClick={() => setIsOverlayVisible((prev) => !prev)}
					/>
					<div className="w-screen pt-[12px] pb-[20px] no-scrollbar gap-x-[15px] flex items-stretch overflow-x-auto relative -left-mobile-side-padding px-mobile-side-padding">
						{boxes?.map((box, index) => (
							<div key={box._id} className={`ml-[35px] flex-shrink-0 relative w-fit h-fit`}>
								<img
									src={getTop10Numbers(index + 1)}
									alt="index"
									className="absolute min-w-fit left-[0px] bottom-[-10px] z-10 translate-x-[-50%] h-[85px] w-auto object-contain drop-shadow-[0_4px_4px_#000000]"
								/>
								<MobileBoxCard key={box._id} box={box} />
							</div>
						))}
					</div>
				</div>
			)}
		</>
	);
}
