/* eslint-disable max-lines-per-function */
import { gsap } from 'gsap';
import { CustomEase } from 'gsap/CustomEase';
import { PrizeInterface } from 'interfaces/ItemInterfaces';
import {
	FAST_SPIN_TIME,
	NORMAL_SPIN_TIME,
	NUM_PRE_SLOT_PRIZES,
	NUM_SURROUNDING_PRIZES,
} from 'pages/OpenBox/box-opening.constants';
import { useWinSoundPlayer } from 'pages/OpenBox/hooks/useWinSoundPlayer';
import { useBoxOpeningStoreMobile } from 'pages/OpenBox/store/useBoxOpeningStoreMobile';
import { calculateOffset } from 'pages/OpenBox/util';
import { RefObject, useCallback, useEffect, useRef } from 'react';
import { shallow } from 'zustand/shallow';

interface SlotComponentInterface {
	wonPrize: PrizeInterface;
	surroundingWonPrizes: PrizeInterface[];
}

interface Props {
	itemsWrapperRef: RefObject<HTMLDivElement>;
	slotPickerRef: RefObject<HTMLImageElement>;
	onSpinComplete: ({ wonPrize, surroundingWonPrizes }: SlotComponentInterface) => void;
	isFastSpin: boolean;
}

const RESIZE_DEBOUNCE_TIME = 200;

gsap.registerPlugin(CustomEase);
CustomEase.create('slot-spin', 'M0,0 C0.083,0.294 0.281,0.758 0.58,0.892 0.732,0.96 0.752,1 1,1 ');
CustomEase.create('customBounceOut', 'M0,0 C0.25,0.46 0.45,0.94 0.65,0.94 0.85,0.94 1.1,1.05 1,1');

export function useMobileSlotSpinAnimation({ itemsWrapperRef, slotPickerRef, isFastSpin, onSpinComplete }: Props) {
	const hasAlreadySpun = useRef(false);
	const isSlotSpinning = useRef(false);

	const { wonPrize, slotPrizesSurroundingWon } = useBoxOpeningStoreMobile((state) => ({
		wonPrize: state.wonPrize,
		slotPrizesSurroundingWon: state.slotPrizesSurroundingWon,
		shallow,
	}));

	const timeline = useRef(gsap.timeline({ paused: false }));

	const { playSound: playWinSound } = useWinSoundPlayer();

	const animateSlot = useCallback(
		({ wonPrize, surroundingWonPrizes }: SlotComponentInterface) => {
			timeline.current.clear();
			const itemsWrapperEl = itemsWrapperRef.current;

			isSlotSpinning.current = true;

			const targetX = calculateOffset({
				itemsWrapperRef,
				slotPickerRef,
				targetPos: NUM_PRE_SLOT_PRIZES + NUM_SURROUNDING_PRIZES / 2,
			});
			if (!targetX) {
				return;
			}
			const animationTime = isFastSpin ? FAST_SPIN_TIME : NORMAL_SPIN_TIME;
			if (!itemsWrapperEl || itemsWrapperEl.children.length === 0) {
				isSlotSpinning.current = false;
				onSpinComplete({ wonPrize, surroundingWonPrizes });
				return;
			}
			const cardWidth = itemsWrapperEl.children[0].clientWidth;
			const threshold = 0.25;

			const randomOff = Math.floor(Math.random() * (1 + 2 * cardWidth * threshold)) - cardWidth * threshold; // random number between [-25% of card width; +25% of card]
			timeline.current.to(itemsWrapperEl, {
				x: isFastSpin ? targetX : targetX + randomOff,
				duration: animationTime,
				ease: hasAlreadySpun.current ? 'power2.inOut' : 'power2.out',
				onComplete: () => {
					if (isFastSpin) {
						playWinSound(wonPrize.data.rarity);
						isSlotSpinning.current = false;
						hasAlreadySpun.current = true;
						gsap.delayedCall(1, () => onSpinComplete({ wonPrize, surroundingWonPrizes }));
					}
				},
			});
			if (!isFastSpin) {
				timeline.current.to(itemsWrapperEl, {
					x: targetX,
					duration: 0.5,
					ease: 'sine.out',
					onComplete: () => {
						playWinSound(wonPrize.data.rarity);
						isSlotSpinning.current = false;
						hasAlreadySpun.current = true;
						gsap.delayedCall(1, () => onSpinComplete({ wonPrize, surroundingWonPrizes }));
					},
				});
			}
		},
		[isFastSpin, itemsWrapperRef, onSpinComplete, playWinSound, slotPickerRef]
	);

	useEffect(() => {
		let isFirstCall = true; // Track if the call is the first
		let debounceTimer: NodeJS.Timeout | null = null;
		let immediateCallTimer: NodeJS.Timeout | null = null;

		const firstSlotCardEl =
			itemsWrapperRef.current && itemsWrapperRef.current.children.length > 0
				? itemsWrapperRef.current?.children[0]
				: null;

		const handleResize = () => {
			if (!isSlotSpinning.current) {
				return;
			}
			// Execute the first call immediately
			if (isFirstCall) {
				isFirstCall = false;
				executeResizeActions();

				if (immediateCallTimer) {
					clearTimeout(immediateCallTimer);
				}
				immediateCallTimer = setTimeout(() => {
					isFirstCall = true;
				}, RESIZE_DEBOUNCE_TIME);
			} else {
				// For subsequent calls, use debounce logic
				if (debounceTimer) {
					clearTimeout(debounceTimer);
				}
				debounceTimer = setTimeout(() => {
					executeResizeActions();
				}, RESIZE_DEBOUNCE_TIME);
			}
		};

		const executeResizeActions = () => {
			const progress = timeline.current.progress();
			timeline.current.pause();
			timeline.current.clear();

			const delay = 500; // to make sure dom has updated fully
			window.setTimeout(() => {
				if (progress !== 1 && wonPrize && slotPrizesSurroundingWon) {
					animateSlot({ wonPrize, surroundingWonPrizes: slotPrizesSurroundingWon });
				}

				timeline.current.progress(progress);
				timeline.current.play();
			}, delay);
		};

		const resizeObserver = new ResizeObserver(() => {
			handleResize();
		});

		if (firstSlotCardEl) {
			resizeObserver.observe(firstSlotCardEl);
		}

		return () => {
			if (firstSlotCardEl) {
				resizeObserver.unobserve(firstSlotCardEl);
			}
			if (debounceTimer) {
				clearTimeout(debounceTimer);
			}
		};
	}, [animateSlot, itemsWrapperRef, slotPickerRef, slotPrizesSurroundingWon, wonPrize]);

	useEffect(() => {
		const tl = timeline.current;
		return () => {
			// Cleanup function that kills the timeline when the component unmounts
			if (tl) {
				tl.clear();
				tl.pause();
				tl.kill();
			}
		};
	}, []);

	return { animateSlot };
}
