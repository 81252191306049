import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';

interface Props {
	value: number;
	onChange: (newValue: number) => void;
}

export function QuantityInput({ value, onChange }: Props) {
	const [localInputValue, setLocalInputValue] = useState(value.toString());

	useEffect(() => {
		setLocalInputValue(value.toString());
	}, [value]);

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = e.target.value;
		// Check if the new value is a valid number or empty (for backspace functionality)
		if (newValue === '' || !isNaN(Number(newValue))) {
			setLocalInputValue(newValue);
		}
	};

	const handleBlur = () => {
		const numValue = Number(localInputValue);
		if (localInputValue === '' || isNaN(numValue) || numValue <= 0) {
			setLocalInputValue(value.toString()); // Revert to last valid value if current is invalid
		} else {
			onChange(Number(localInputValue));
		}
	};

	return (
		<>
			<div className="relative w-[110px] h-[55px] border-[1px] border-white flex items-center justify-center rounded-[2px] ">
				<button
					type="button"
					className="text-[rgba(255,255,255,0.6)] text-[15px] p-1 absolute left-[12px] top-[50%] translate-y-[-50%]"
					onClick={() => onChange(Math.max(value - 1, 1))}
				>
					<FontAwesomeIcon icon={faMinus} />
				</button>
				<input
					value={localInputValue}
					onChange={handleInputChange}
					onBlur={handleBlur}
					type="text"
					inputMode="numeric"
					className="text-[16px] font-semibold text-white w-full h-full bg-transparent outline-none text-center"
				/>
				<button
					type="button"
					className="absolute right-[12px] top-[50%] translate-y-[-50%] text-[rgba(255,255,255,0.6)]"
					onClick={() => onChange(value + 1)}
				>
					<FontAwesomeIcon icon={faPlus} />
				</button>
			</div>
		</>
	);
}
