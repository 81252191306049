import { CSSProperties } from 'react';
import { FieldError, UseFormRegisterReturn } from 'react-hook-form';

interface Props {
	register: UseFormRegisterReturn;
	value?: string;
	onChange?: (v: string) => void;
	type: 'text' | 'tel' | 'email';
	placeholder: string;
	style?: CSSProperties;
	error?: FieldError;
}

export function AddressInputField({ register, error, style, value, onChange, placeholder, type }: Props) {
	return (
		<div className="flex flex-col gap-y-[5px] w-full" style={style}>
			<input
				{...register}
				value={value}
				onChange={(e) => onChange && onChange(e.target.value)}
				placeholder={placeholder}
				type={type}
				className={`w-full rounded-[4px] bg-white text-black h-[60px] px-[20px] outline-none border-[2px] ${error ? 'border-errorRed' : 'border-white'}`}
			/>
			{error && <span className="text-errorRed text-[14px]">{error.message}</span>}
		</div>
	);
}
