import { StarsIcon } from 'assets/images';
import { SectionHeader } from 'components/common/SectionHeader';
import { useUserContext } from 'hooks';
import { useBackBtnInTopBar } from 'hooks/app-layout/useBackBtnInTopbar';
import { useTopbarBackBtnListener } from 'hooks/events/useTopbarBackBtnListener';
import { useCallback, useState } from 'react';

import { AddressChangeLink } from './components/AddressChangeLink';
import { EmailAddressChangeLink } from './components/EmailAddressChangeLink';
import { PasswordChangeLink } from './components/PasswordChangeLink';

const INIT_NUM_DISPLAYED_ADDRESSES = 3;

export function MobileEditProfile() {
	const { user } = useUserContext();
	const [numOfDisplayedAddresses, setNumOfDisplayedAddresses] = useState(INIT_NUM_DISPLAYED_ADDRESSES);

	useTopbarBackBtnListener(
		useCallback(() => {
			window.history.back();
		}, [])
	);

	useBackBtnInTopBar();

	return (
		<div className="flex flex-col gap-y-[22px]">
			<SectionHeader title="Edit Profile" iconSrc={StarsIcon} rightContent={<></>} />
			<div className="flex flex-col gap-y-[8px]">
				<span className="font-bold text-white">E-Mail Address</span>
				<EmailAddressChangeLink value={user?.contactMail?.value} isVerified={user?.contactMail?.verified} />
			</div>
			<div className="flex flex-col gap-y-[8px]">
				<span className="font-bold text-white">Address</span>
				<div className="flex flex-col gap-y-[10px]">
					{user?.addresses
						.slice(0, numOfDisplayedAddresses)
						.map((address) => <AddressChangeLink key={address._id} address={address} />)}
					{user && user.addresses.length > numOfDisplayedAddresses && (
						<button
							onClick={() => setNumOfDisplayedAddresses((prev) => prev + INIT_NUM_DISPLAYED_ADDRESSES)}
							className="text-mrLootBlue text-[15px] font-bold"
						>
							See more
						</button>
					)}
				</div>
			</div>
			{user?.oAuthId === undefined && (
				<div className="flex flex-col gap-y-[8px]">
					<span className="font-bold text-white">Security</span>
					<PasswordChangeLink />
				</div>
			)}
		</div>
	);
}
