/* eslint-disable @typescript-eslint/no-explicit-any */
import { BoxInterface } from './BoxInterface';
import { ItemInterface } from './ItemInterfaces';

export enum InteractionType {
	VIEW = 'view',
	INSPECT = 'inspect',
	SELL = 'sell',
	SHIP_HOME = 'ship-home',
	ADD_TO_INVENTORY = 'add_to_inventory',
	OPEN_BOX = 'open_box',
	DEMO_OPEN_BOX = 'demo_open_box',
	VIEW_BOX = 'view_box',
	VIEW_ITEM = 'view_item',
	PAGE_VISIT = 'visit_page',
	PAGE_LEAVE = 'leave_page',
	SEARCH = 'search',
}

export enum InteractionTarget {
	ITEM = 'item',
	BOX = 'box',
}

type TargetType = ItemInterface | BoxInterface | string;

export interface BaseInteractionInterface {
	_id?: string;
	userId?: string;
	targetType: InteractionTarget | string;
	targetId: TargetType;
	interactionType: InteractionType;
	interactionDetails?: Record<string, any>;
	stateAtInteraction?: Record<string, any>;
	createdAt?: Date;
	updatedAt?: Date;
}

// eslint-disable-next-line import/no-unused-modules
export interface UserInteractionInterface<T extends TargetType> extends BaseInteractionInterface {
	targetId: T;
}
