import { useEffect } from 'react';

/**
 * A custom React hook that listens for a specific keyboard key press and optionally
 * a fullscreen change event (specifically for the 'Escape' key to handle a Chrome
 * exception).
 *
 * @param key A string representing the key to listen for. If this key is pressed, the
 *            `onKeyPress` callback is invoked. Additionally, if the key is 'Escape',
 *            the hook will also listen for the 'fullscreenchange' event to handle a
 *            Chrome-specific behavior where the Escape key event is not fired when
 *            exiting fullscreen mode.
 * @param onKeyPress A callback function that is called when the specified key is pressed
 *                   or when exiting fullscreen mode in Chrome with the Escape key.
 *
 */
export function useKeyListener(key: string, onKeyPress: () => void) {
	useEffect(() => {
		const handleEsc = (event: KeyboardEvent) => {
			if (event.key === key) {
				onKeyPress();
			}
		};

		const handleFullscreenChange = () => {
			if (!document.fullscreenElement) {
				onKeyPress();
			}
		};

		window.addEventListener('keydown', handleEsc);
		if (key === 'Escape') {
			// Chrome does not fire a escape key event in fullscreen
			document.addEventListener('fullscreenchange', handleFullscreenChange);
		}

		return () => {
			window.removeEventListener('keydown', handleEsc);
			if (key === 'Escape') {
				document.removeEventListener('fullscreenchange', handleFullscreenChange);
			}
		};
	}, [key, onKeyPress]);
}
