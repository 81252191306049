import { Skeleton } from 'components/common';
import { CSSProperties } from 'react';

interface Props {
	style: CSSProperties;
}

export function ItemCardSkeleton({ style }: Props) {
	return <Skeleton style={{ ...style, width: 'auto', aspectRatio: '0.64 / 1', borderRadius: '16px' }} />;
}
