import { CurrencyChange } from 'assets/images';
import { SecondaryBtn } from 'components/common/buttons/SecondaryBtn';
import { usePopupStore } from 'store/usePopupStore';
import { shallow } from 'zustand/shallow';

export function CurrencyChangeBtn() {
	const { setIsCurrencyChooserPopupVisible, isCurrencyChooserPopupVisible } = usePopupStore(
		(state) => ({
			setIsCurrencyChooserPopupVisible: state.setIsCurrencyPopupVisible,
			isCurrencyChooserPopupVisible: state.isCurrencyPopupVisible,
		}),
		shallow
	);

	return (
		<SecondaryBtn
			isDisabled={!!isCurrencyChooserPopupVisible}
			onClick={() => setIsCurrencyChooserPopupVisible(true)}
			style={{ width: '50px', height: '34px' }}
			innerContentStyle={{ padding: '0px' }}
			content={<img src={CurrencyChange} alt="change currency" className="h-[25px] object-contain" />}
		/>
	);
}
