import { LogoBlue } from 'assets/images';
import { Link, useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';
import { ToastErrorNotification } from 'components/toasts/ToastErrorNotification';
import { PasswordInputWrapper } from './components/PasswordInputWrapper';
import { useForm } from 'react-hook-form';
import { EmailInputWrapper } from './components/EmailInputWrapper';
import { useLoginRequest } from '../hooks/useLoginRequest';
import { useUserContext } from 'hooks';
import { useState } from 'react';
import { SeparatorWithText } from 'components/Separator/SeparatorWithText';
import { PrimaryBtn } from 'components/common/buttons/PrimaryBtn';
import OAuthBtnsHorizontal from './components/OAuthBtnsHorizontal';

interface LoginFormState {
	email: string;
	password: string;
}

export default function DesktopLogin() {
	const navigate = useNavigate();

	const [hasAgreedToTerms, setHasAgreedToTerms] = useState(false);

	const { control, handleSubmit } = useForm<LoginFormState>();

	const { load: loadUser } = useUserContext();

	const { handleSignIn, isLoading: isLoginLoading } = useLoginRequest({
		onSuccess: () => {
			loadUser();
			navigate('/successful-login');
		},
		onError: (message) => {
			toast(<ToastErrorNotification message={message || 'Something went wrong'} />);
		},
	});

	return (
		<form
			className={`flex flex-col items-center justify-around w-full h-full overflow-y-scroll py-[15px] `}
			onSubmit={handleSubmit(handleSignIn)}
		>
			<img src={LogoBlue} className="h-[3rem]" />
			<div className="w-[75%] flex flex-col gap-y-[20px] mt-[40px] mb-[5px]">
				<div className={`flex justify-between items-center w-full`} style={{ transition: 'opacity 0.3s' }}>
					<OAuthBtnsHorizontal
						isRequestLoading={isLoginLoading}
						textStyle={{ color: 'black' }}
						checkboxStyle={{ color: 'black', borderColor: 'black' }}
						useConsent={false}
					/>
				</div>
			</div>
			<SeparatorWithText
				text="Or"
				propStyle={{ color: 'rgba(0,0,0,0.5)', fontWeight: 400, margin: '25px 0' }}
				width={120}
				lineColor="rgba(0, 0, 0, 0.4)"
			/>

			<div className="flex flex-col w-[75%] gap-y-[14px]">
				<h1 className="text-[1.4rem] text-black font-semibold">Login</h1>
				<EmailInputWrapper
					name="email"
					control={control}
					rules={{
						required: 'Email is required',
						pattern: {
							value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
							message: 'Invalid email address',
						},
					}}
					label="Email Address"
				/>
				<PasswordInputWrapper
					name="password"
					control={control}
					rules={{
						required: 'Password is required',
					}}
					label="Password"
					placeholder="Enter your password"
				/>

				<Link to="/auth/reset-password/enter-email" className="text-xs mr-auto hover:underline">
					Forgot Password?
				</Link>
			</div>
			<PrimaryBtn
				type="submit"
				isDisabled={isLoginLoading}
				content="Sign up"
				style={{ minHeight: '50px', width: '75%', margin: '20px 0' }}
			/>

			<div className="flex items-center text-xs">
				<span>Dont have an account yet?</span>
				<Link to="/auth/register" className="font-semibold ml-1 hover:underline">
					Register now
				</Link>
			</div>
		</form>
	);
}
