import { FontArrow } from 'assets/images';
import { PrimaryBtn } from 'components/common/buttons/PrimaryBtn';
import { PrimaryContainer } from 'components/PrimaryContainer';
import { usePaymentTransactions } from 'hooks/data/transactions/usePaymentTransactions';
import { useRef, useState } from 'react';

const BONUS_THRESHOLD_LOW = 1;
const BONUS_THRESHOLD_HIGH = 100;
const BONUS_PERCENTAGE_LOW = 10;
const BONUS_PERCENTAGE_HIGH = 20;
const MAX_AMOUNT = 100000;
const MIN_AMOUNT = 10;

const INCREMENT_DELAY = 450;
const INCREMENT_INTERVAL = 100;

interface CustomAmountInputProps {
	onCheckout: (amount: number) => void;
}

const INIT_VALUE = 50;

export function CustomAmountInput({ onCheckout }: CustomAmountInputProps) {
	const [amount, setAmount] = useState(INIT_VALUE);
	const amountRef = useRef(INIT_VALUE);
	const timeoutRef = useRef<NodeJS.Timeout | null>(null);

	const bonusPercentage = calculateBonusPercentage();

	const { data: transactions, isLoading: areTransactionsLoading } = usePaymentTransactions();

	const showBonus = !areTransactionsLoading && (!transactions || transactions.length === 0);

	function calculateBonusPercentage() {
		if (amountRef.current >= BONUS_THRESHOLD_HIGH) {
			return BONUS_PERCENTAGE_HIGH;
		}
		if (amountRef.current >= BONUS_THRESHOLD_LOW) {
			return BONUS_PERCENTAGE_LOW;
		}
		return 0;
	}

	const updateDisplayValue = (newValue: number) => {
		amountRef.current = newValue;
		setAmount(newValue);
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;

		if (/^\$ [0-9]*$/.test(value)) {
			const numericValue = parseInt(value.replace('$ ', ''), 10) || 0;
			updateDisplayValue(numericValue);
		}
	};

	const handleBlur = () => {
		if (amount < MIN_AMOUNT) {
			updateDisplayValue(MIN_AMOUNT);
		}
	};

	const incrementAmount = () => {
		if (amountRef.current < MAX_AMOUNT) {
			updateDisplayValue(amountRef.current + 1);
		}
	};

	const decrementAmount = () => {
		if (amountRef.current > MIN_AMOUNT) {
			updateDisplayValue(amountRef.current - 1);
		}
	};

	const startContinuousIncrement = () => {
		incrementAmount();
		timeoutRef.current = setTimeout(startContinuousIncrement, INCREMENT_INTERVAL);
	};

	const startContinuousDecrement = () => {
		decrementAmount();
		timeoutRef.current = setTimeout(startContinuousDecrement, INCREMENT_INTERVAL);
	};

	const handleIncrementPress = () => {
		incrementAmount();
		timeoutRef.current = setTimeout(startContinuousIncrement, INCREMENT_DELAY);
	};

	const handleDecrementPress = () => {
		decrementAmount();
		timeoutRef.current = setTimeout(startContinuousDecrement, INCREMENT_DELAY);
	};

	const stopInterval = () => {
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
			timeoutRef.current = null;
		}
	};

	return (
		<PrimaryContainer
			style={{ width: 'fit-content', height: 'fit-content' }}
			renderEllipse={true}
			content={
				<div className="w-[300px] sm:w-[340px] ms:w-[380px] mds:w-[420px] h-[110px] sm:h-[120px] ms:h-[130px] mds:h-[140px] pt-[5px] pb-[3px] flex flex-col justify-center text-white text-[14px] sm:text-[15px] ms:text-[16px] mds:text-[18px] font-semibold">
					<span className="mb-[10px]">Custom Amount</span>
					<div className="flex items-end justify-between w-full">
						<div className="flex flex-col items-center gap-y-[3px]">
							<div className="flex items-center w-full gap-x-[4px]">
								<button
									type="button"
									onMouseDown={handleDecrementPress}
									onMouseUp={stopInterval}
									onMouseLeave={stopInterval}
									className="rounded-full w-[25px] sm:w-[30px] ms:w-[35px] mds:w-[40px] h-[25px] sm:h-[30px] ms:h-[35px] mds:h-[40px] bg-secondary-gradient flex items-center justify-center border-[1px] border-white font-extrabold"
								>
									<span className="leading-[14px] sm:leading-[16px] ms:leading-[18px] mds:leading-[20px]">-</span>
								</button>
								<div className="relative w-[130px] sm:w-[150px] ms:w-[170px] mds:w-[200px]">
									<input
										className="border-b-[3px] text-[18px] sm:text-[20px] ms:text-[22px] mds:text-[24px] font-extrabold border-b-white text-center bg-transparent w-full outline-none"
										type="text"
										value={`$ ${amount}`}
										onChange={handleInputChange}
										onBlur={handleBlur}
										placeholder="$ 10"
									/>
								</div>
								<button
									type="button"
									onMouseDown={handleIncrementPress}
									onMouseUp={stopInterval}
									onMouseLeave={stopInterval}
									className="rounded-full w-[25px] sm:w-[30px] ms:w-[35px] mds:w-[40px] h-[25px] sm:h-[30px] ms:h-[35px] mds:h-[40px] bg-secondary-gradient flex items-center justify-center border-[1px] border-white font-extrabold"
								>
									<span className="leading-[14px] sm:leading-[16px] ms:leading-[18px] mds:leading-[20px]">+</span>
								</button>
							</div>
							<span className="text-[12px] sm:text-[13px] ms:text-[14px] mds:text-[15px] text-[rgba(255,255,255,0.8)]">
								max. ${MAX_AMOUNT.toLocaleString()}
							</span>
						</div>
						<div className="flex flex-col items-center gap-y-[3px]">
							<PrimaryBtn
								type="submit"
								style={{ width: 'fit-content' }}
								onClick={() => onCheckout(amount)}
								content={
									<div className="flex items-center justify-center w-[65px] sm:w-[75px] ms:w-[85px] mds:w-[95px]">
										<img src={FontArrow} alt="->" className="h-[15px] sm:h-[17px] ms:h-[19px] mds:h-[21px] w-auto" />
									</div>
								}
							/>
							{showBonus && (
								<span
									className={`text-[#00DB00] font-bold text-[10px] sm:text-[12px] ms:text-[14px] mds:text-[16px] ${
										bonusPercentage === 0 && 'opacity-0'
									}`}
								>
									+{bonusPercentage}% BONUS
								</span>
							)}
						</div>
					</div>
				</div>
			}
		/>
	);
}
