import { FreeFont } from 'assets/images';
import { AmountDisplayWithCurrency } from 'components/common/AmountDisplayWithCurrency';
import { GroupedOrderItemInterface } from 'pages/checkout/InterfacesCheckout';

import { CLOUDFRONT_IMG_URL } from '../../../../constants';

interface Props {
	orderItem: GroupedOrderItemInterface;
}

export function OrderReviewProductRow({ orderItem }: Props) {
	const additionalCost = orderItem.item.price * (orderItem.quantity - orderItem.selectedInventoryItemIds.length);

	return (
		<div className="w-full h-fit grid grid-cols-[2fr_5fr] gap-[20px] items-stretch font-inter">
			<div className="aspect-square bg-[rgba(0,0,0,0.3)] w-full rounded-[4px] p-[10px] ">
				<img
					src={`${CLOUDFRONT_IMG_URL}/${orderItem.item.imageUrls.qualityMedium}`}
					alt={orderItem.item.name}
					className="object-scale-down w-full aspect-square pointer-events-none"
				/>
			</div>

			<div className="flex flex-col w-full justify-between space-y-[5px]">
				<h3 className="text-[17px] font-semibold line-clamp-1">{orderItem.item.name}</h3>
				{/*orderItem.selectedOptions?.map((option, index) => (
					<div key={index} className="flex items-center space-x-[8px] text-[#868E96] text-[15px]">
						<span>{option.label}: </span>
						{orderItem.selectedOptions?.map((option, index) => (
							<span className="line-clamp-1" key={index}>
								{option.values.map((value) => value).join(', ')}
							</span>
						))}
					</div>
				))*/}

				<div className="flex w-full items-center justify-between">
					<span className="text-[#868E96] text-[15px] font-inter">Qty: {orderItem.quantity}</span>
					{additionalCost > 0 && (
						<div className="flex items-center space-x-[4px]">
							<AmountDisplayWithCurrency
								amount={additionalCost}
								imgStyle={{ height: '19px' }}
								amountStyle={{ fontSize: '17px', color: '#212529' }}
							/>
						</div>
					)}
					{additionalCost === 0 && <img src={FreeFont} className="h-[20px]" alt="free costs" />}
				</div>
			</div>
		</div>
	);
}
