import { CSSProperties, ReactNode } from 'react';
import { Link } from 'react-router-dom';

interface Props {
	style?: CSSProperties;
	title: string;
	iconSrc: string;
	linkTo?: string;
	linkText?: string;
	onLinkClick?: () => void;
	rightContent?: ReactNode; // overwrites link so you have to choose between this and linkText | linkTo Property
}

export function SectionHeader({
	title,
	style,
	iconSrc,
	rightContent,
	linkTo,
	linkText = 'See All',
	onLinkClick,
}: Props) {
	return (
		<div className="z-10 flex items-center justify-between" style={style}>
			<div className="flex items-center gap-x-[8px] sm:gap-x-[12px] mr-auto">
				<img src={iconSrc} alt={`${title} Icon`} className="h-[20px] sm:h-[25px]" />
				<h2 className="font-bold text-white text-lg sm:text-[23px]">{title}</h2>
			</div>
			{rightContent}
			{!rightContent && (
				<>
					{linkTo && (
						<Link to={linkTo} onClick={onLinkClick} className="text-sm text-mrLootBlue">
							{linkText}
						</Link>
					)}
					{!linkTo && (
						<button onClick={onLinkClick} className="text-sm sm:text-[16px] text-mrLootBlue">
							{linkText}
						</button>
					)}
				</>
			)}
		</div>
	);
}
