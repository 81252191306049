/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import { calcDistanceInPercentage } from 'utils';

interface Props {
	scrollRef: React.RefObject<HTMLDivElement>;
	top10OverlayRef: React.RefObject<HTMLDivElement>;
	parentRef: React.RefObject<HTMLDivElement>;
}

export function useTop10BoxesOverlayOpacity({ scrollRef, top10OverlayRef, parentRef }: Props) {
	const [opacity, setOpacity] = useState(0);
	const [showAnimation, setShowAnimation] = useState(true);

	useEffect(() => {
		function handleOverlayOpacity() {
			const topOffset = 0;
			const threshold = 100;

			if (!scrollRef.current || !top10OverlayRef.current || !parentRef.current) {
				return;
			}

			const windowHeight = window.innerHeight;
			const scrollPosition = scrollRef.current.getBoundingClientRect();
			const overlayPosition = top10OverlayRef.current.getBoundingClientRect();

			// Calculate relative positions taking into account offsets and thresholds
			const relativeScrollPosition = calculateRelativeScrollPosition(scrollPosition, windowHeight, topOffset);
			const { underZoneStart, underZoneEnd, upperZoneStart, upperZoneEnd } = calculateZoneBoundaries(
				overlayPosition,
				scrollPosition,
				threshold,
				topOffset
			);

			// Determine and set new opacity based on position
			updateOpacityAndAnimation(
				relativeScrollPosition,
				{
					underZoneStart,
					underZoneEnd,
					upperZoneStart,
					upperZoneEnd,
				},
				scrollPosition.height
			);
		}

		const updateOpacityAndAnimation = (
			relativePosition: { top: number; bottom: number },
			visibilityZones: {
				underZoneStart: number;
				underZoneEnd: number;
				upperZoneStart: number;
				upperZoneEnd: number;
			},
			divHeight: number
		) => {
			// Check if the element is within the full visibility zone
			if (relativePosition.top < visibilityZones.underZoneEnd && relativePosition.top > visibilityZones.upperZoneEnd) {
				setFullOpacity();
			}
			// Check if the element is within the under visibility zone
			else if (
				relativePosition.top < visibilityZones.underZoneStart &&
				relativePosition.top > visibilityZones.underZoneEnd
			) {
				setOpacityBasedOnProgress(relativePosition.top, visibilityZones.underZoneStart, visibilityZones.underZoneEnd);
			}
			// Check if the element is within the upper visibility zone
			else if (
				relativePosition.top < visibilityZones.upperZoneEnd &&
				relativePosition.top > visibilityZones.upperZoneStart
			) {
				setOpacityWithPossibleAnimation(
					relativePosition.top,
					visibilityZones.upperZoneStart,
					visibilityZones.upperZoneEnd
				);
			}
			// Set the opacity to zero if the element is outside the defined visibility zones
			else {
				setOpacity(0);
			}
			adjustShowAnimationBasedOnPosition(relativePosition, window.innerHeight, divHeight);
		};

		const setOpacityWithPossibleAnimation = (positionTop: number, zoneStart: number, zoneEnd: number) => {
			const progress = calcDistanceInPercentage(positionTop, zoneStart, zoneEnd);
			setOpacity(progress / 100);
		};

		const handleScroll = () => {
			handleOverlayOpacity();
		};

		window.addEventListener('scroll', handleScroll);

		return () => window.removeEventListener('scroll', handleScroll);
	}, [parentRef, scrollRef, top10OverlayRef]);

	const calculateRelativeScrollPosition = (scrollPosition: DOMRect, windowHeight: number, topOffset: number) => ({
		bottom: windowHeight - scrollPosition.bottom,
		top: scrollPosition.top - topOffset,
	});

	const calculateZoneBoundaries = (
		overlayPosition: DOMRect,
		divPosition: DOMRect,
		threshold: number,
		topOffset: number
	) => {
		const positionMaxOpacity = overlayPosition.height - (divPosition.height - topOffset) / 2;

		return {
			underZoneStart: overlayPosition.height,
			underZoneEnd: positionMaxOpacity,
			upperZoneStart: overlayPosition.height - divPosition.height - threshold,
			upperZoneEnd: positionMaxOpacity - threshold,
		};
	};

	const setFullOpacity = () => {
		setOpacity(1);
	};

	const setOpacityBasedOnProgress = (positionTop: number, zoneStart: number, zoneEnd: number) => {
		const progress = calcDistanceInPercentage(positionTop, zoneStart, zoneEnd);
		setOpacity(progress / 100);
	};

	const adjustShowAnimationBasedOnPosition = (
		relativePosition: { top: number; bottom: number },
		windowHeight: number,
		divHeight: number
	) => {
		if (relativePosition.bottom + divHeight < 0) {
			setShowAnimation(true);
		} else if (relativePosition.bottom > windowHeight) {
			setShowAnimation(false);
		}
	};

	return { opacity, showAnimation };
}
