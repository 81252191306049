/* eslint-disable complexity */
import {
	BigLootieBag,
	Ellipse,
	Font25Dollar,
	Font50Dollar,
	Font100Dollar,
	Font250Dollar,
	Font500Dollar,
	FontBestDeal,
	FontPopular,
	Icon1000Dollarz,
	LootieChest,
	LootiePile,
	LootieUniverse,
	MagnificientLootieChest,
	SmallLootieBag,
} from 'assets/images';
import { AmountDisplayWithCurrency } from 'components/common/AmountDisplayWithCurrency';
import { PrimaryBtn } from 'components/common/buttons/PrimaryBtn';
import { PrimaryContainer } from 'components/PrimaryContainer';
import { useWindowDimensions } from 'hooks';
import { usePaymentTransactions } from 'hooks/data/transactions/usePaymentTransactions';
import { CurrencyOptions } from 'store/useAppStore';

import { screens } from '../../../../../constants';

const AMOUNT_OPTIONS = [
	{
		amount: 25,
		amountImg: Font25Dollar,
		bonus: 0.1,
		image: LootiePile,
		mainImgHeightBig: '62px',
		mainImgHeightSmall: '60px',
		mainImgHeightLarger: '77px',
		label: '',
	},
	{
		amount: 50,
		amountImg: Font50Dollar,
		bonus: 0.1,
		image: SmallLootieBag,
		mainImgHeightBig: '70px',
		mainImgHeightSmall: '70px',
		mainImgHeightLarger: '85px',
	},
	{
		amount: 100,
		amountImg: Font100Dollar,
		bonus: 0.2,
		image: BigLootieBag,
		mainImgHeightBig: '70px',
		mainImgHeightSmall: '70px',
		mainImgHeightLarger: '85px',
		labelImg: FontPopular,
		labelStyle: { background: 'linear-gradient(331.41deg, #FFBA4B 6.78%, #F46702 204.87%)' },
	},
	{
		amount: 250,
		amountImg: Font250Dollar,
		bonus: 0.2,
		image: LootieChest,
		mainImgHeightBig: '70px',
		mainImgHeightSmall: '70px',
		mainImgHeightLarger: '85px',
		label: '',
		labelImg: FontPopular,
		labelStyle: { background: 'linear-gradient(331.41deg, #FFBA4B 6.78%, #F46702 204.87%)' },
	},
	{
		amount: 500,
		amountImg: Font500Dollar,
		bonus: 0.2,
		image: MagnificientLootieChest,
		mainImgHeightBig: '70px',
		mainImgHeightSmall: '70px',
		mainImgHeightLarger: '85px',
		label: '',
	},
	{
		amount: 1000,
		amountImg: Icon1000Dollarz,
		bonus: 0.2,
		image: LootieUniverse,
		mainImgHeightBig: '70px',
		mainImgHeightSmall: '70px',
		mainImgHeightLarger: '85px',
		labelImg: FontBestDeal,
		labelStyle: { background: 'linear-gradient(331.41deg, #CC00FF 6.78%, #F46702 204.87%)' },
	},
];

function getSize(screenWidth: number) {
	if (screenWidth >= screens.mds.width) {
		return {
			width: '240px',
			height: '260px',
			totalBonusImgHeight: '24px',
			totalBonusFontSize: '22px',
			bonusImgHeight: '21px',
			bonusFontSize: '20px',
		};
	} else if (screenWidth >= screens.sm.width) {
		return {
			width: '220px',
			height: '240px',
			totalBonusImgHeight: '22px',
			totalBonusFontSize: '20px',
			bonusImgHeight: '19px',
			bonusFontSize: '18px',
		};
	} else if (screenWidth >= screens.ms.width) {
		return {
			width: '200px',
			height: '220px',
			totalBonusImgHeight: '19px',
			totalBonusFontSize: '19px',
			bonusImgHeight: '17px',
			bonusFontSize: '17px',
		};
	} else if (screenWidth >= screens.xxs.width) {
		return {
			width: '160px',
			height: '172px',
			totalBonusImgHeight: '16px',
			totalBonusFontSize: '16px',
			bonusImgHeight: '14px',
			bonusFontSize: '14px',
		};
	}
	return {
		width: '150px',
		height: '165px',
		totalBonusImgHeight: '14px',
		totalBonusFontSize: '14px',
		bonusImgHeight: '11px',
		bonusFontSize: '11px',
	};
}

interface Props {
	onCheckout: (amount: number) => void;
}

const AMOUNT_WITH_SMALLER_IMG = 25;

export function AmountSelector({ onCheckout }: Props) {
	const { screenWidth } = useWindowDimensions();

	// Get sizes dynamically
	const sizes = getSize(screenWidth);

	const { data: transactions, isLoading: areTransactionsLoading } = usePaymentTransactions();

	const showBonus = !areTransactionsLoading && (!transactions || transactions.length === 0);
	//const showBonus = false;
	return (
		<div className="w-full flex flex-col space-y-[15px]">
			<div className="w-full flex flex-wrap justify-center gap-y-[35px] gap-x-[15px]">
				{AMOUNT_OPTIONS.map((option) => (
					<div key={option.amount} className="relative w-fit h-fit">
						{(showBonus || option.amount !== 1000) && option.labelImg && (
							<div
								className="absolute z-10 right-[-10px] w-[96px] h-[30px] mds:w-[110px] flex items-center justify-center top-0 py-[5px] rounded-[20px] -translate-y-1/2 font-bold text-white"
								style={{
									boxShadow: '0px 0px 0px 2px #FFFFFF, 0px 4px 5px rgba(0, 0, 0, 0.3)',
									...option.labelStyle,
								}}
							>
								<img src={Ellipse} className="absolute left-[5px] top-[2px] h-[4px] w-auto rotate-[-10deg]" alt="" />
								<img src={option.labelImg} alt="" className="h-[14px] mds:h-[16px] w-auto object-scale-down" />
							</div>
						)}

						<PrimaryBtn
							renderEllipse={true}
							onClick={() => onCheckout(option.amount)}
							style={{ height: 'fit-content', width: sizes.width, padding: '0px' }}
							innerContentStyle={{ padding: showBonus ? '5px' : '0px' }}
							content={
								<div
									className={`${option.amount === AMOUNT_WITH_SMALLER_IMG ? 'py-[11px]' : 'py-[7px]'} w-full h-full flex flex-col gap-y-[5px] items-center justify-between relative px-[5px] ms:px-[8px]`}
								>
									{/* Main image */}
									<img
										src={option.image}
										alt=""
										className="my-auto"
										style={{
											height:
												screenWidth >= screens.mds.width
													? option.mainImgHeightLarger
													: screenWidth >= screens.ms.width
														? option.mainImgHeightBig
														: option.mainImgHeightSmall,
										}}
									/>

									{/* Coin count and bonus display */}
									{showBonus && (
										<AmountDisplayWithCurrency
											currency={CurrencyOptions.US_DOLLAR}
											amount={option.amount * (1 + option.bonus)}
											imgStyle={{ height: sizes.totalBonusImgHeight }}
											amountStyle={{ fontSize: sizes.totalBonusFontSize }}
											fractionDigits={0}
											// eslint-disable-next-line no-magic-numbers
											style={{ marginTop: option.amount === 25 ? '10px' : '5px' }}
										/>
									)}

									{/* Bonus section */}
									{showBonus && (
										<div className="flex items-center text-[11px] ms:text-[12px] gap-x-[3px] mb-1 mt-[2px]">
											<AmountDisplayWithCurrency
												currency={CurrencyOptions.US_DOLLAR}
												amount={option.amount}
												useDivWrapper={false}
												imgStyle={{ height: sizes.bonusImgHeight }}
												amountStyle={{ fontSize: sizes.bonusFontSize }}
												fractionDigits={0}
											/>

											<span className="text-[#00DB00]">+ {option.amount * option.bonus} BONUS</span>
										</div>
									)}

									{/* Amount Label */}
									{
										<PrimaryContainer
											renderEllipse={false}
											style={{
												width: 'fit-content',
												height: 'fit-content',
												borderWidth: '2px',
												marginTop: showBonus ? '0px' : '10px',
											}}
											innerContentStyle={{ padding: '4px 12px' }}
											content={
												<div className="w-full h-[28px] flex items-center justify-center">
													<img
														src={option.amountImg}
														className="w-auto h-[20px] ms:h-[23px] object-contain"
														alt={`$${option.amount}`}
													/>
												</div>
											}
										/>
									}
								</div>
							}
						/>
					</div>
				))}
			</div>
		</div>
	);
}
