import { ItemInterface } from 'interfaces/ItemInterfaces';
import Skeleton from 'react-loading-skeleton';

import { CLOUDFRONT_IMG_URL, rarities } from '../../../../constants';

export function ImageSection({ item }: { item?: ItemInterface }) {
	return (
		<div className="relative flex items-center justify-center">
			{item && (
				<>
					<img
						alt={`${item.name}`}
						src={`${CLOUDFRONT_IMG_URL}/${item.imageUrls.qualityMedium}`}
						className="h-[100px] object-scale-down"
					/>
					<div
						className="absolute left-[50%] translate-x-[-50%] top-[50%] translate-y-[-50%] h-[90px] w-[90px] blur-[25px] rounded-full z-[-1]"
						style={{ backgroundColor: rarities[item.rarity].color }}
					/>
				</>
			)}
			{!item && (
				<Skeleton height={110} width={90} baseColor="#8191a0" containerClassName="flex justify-center items-center" />
			)}
		</div>
	);
}
