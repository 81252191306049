import axios from 'axios';
import { PopulatedBoxInterface } from 'interfaces/BoxInterface';
import { useQuery } from 'react-query';

import { API_DOMAIN, FIVE_MINUTES_IN_MILLISECONDS, QUERY_KEYS } from '../../../constants';

async function loadTop10Boxes() {
	try {
		const response = await axios.get(`${API_DOMAIN.mrlootBackend}/api/box-recommendations/boxes/load-top-of-week`, {
			withCredentials: true,
		});
		if (response.data.status === 'success') {
			return response.data.data;
		}
	} catch (err) {
		console.error(err);
		throw err;
	}
}

export function useLoadTopBoxes() {
	return useQuery<PopulatedBoxInterface[], Error>({
		queryKey: QUERY_KEYS.boxHistory,
		queryFn: loadTop10Boxes,
		staleTime: FIVE_MINUTES_IN_MILLISECONDS,
		cacheTime: FIVE_MINUTES_IN_MILLISECONDS,
	});
}
