import { gsap } from 'gsap';
import { useEffect, useRef } from 'react';

interface Props {
	progress: number; // Progress should be a number between 0 and 100
}

const MIN_VISIBLE_PROGRESS = 10;

export function ProgressBar({ progress }: Props) {
	const circle1Ref = useRef<HTMLDivElement>(null);
	const circle2Ref = useRef<HTMLDivElement>(null);
	const progressBarRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const progressBarWidth = progressBarRef.current?.offsetWidth || 0;
		const circleWidth = circle1Ref.current?.offsetWidth || 0;

		const tl = gsap.timeline({ repeat: -1 });

		tl.to(circle1Ref.current, {
			duration: 2,
			x: progressBarWidth + 200,
			ease: 'linear',
			clearProps: 'x',
		});

		tl.to(
			circle2Ref.current,
			{
				duration: 2,
				x: progressBarWidth + 200,
				ease: 'linear',
				clearProps: 'x',
			},
			'<'
		);

		tl.set(circle1Ref.current, { x: -circleWidth });
		tl.set(circle2Ref.current, { x: -circleWidth });

		return () => {
			tl.kill();
		};
	}, []);

	return (
		<div
			ref={progressBarRef}
			className="relative w-full shadow-[4px_4px_4px_rgba(0,0,0,0.25)] bg-white rounded-[32px] h-[35px] px-[10px] py-[5px]"
		>
			<div
				className="absolute left-0 bottom-0 translate-y-[50%] rounded-[20px] z-[-1] blur-[20px] w-full h-full"
				style={{
					background:
						'radial-gradient(100% 182.65% at 100% 0%, #224BDA 0%, #5949DB 29.69%, #AD33B8 54.17%, #C42EA3 77.08%, #E93AB8 100%)',
				}}
			/>
			<div
				className="overflow-hidden relative rounded-[32px] h-full transition-width duration-500 ease-in-out drop-shadow-[0px_1px_4px_rgb(41,129,233)]"
				style={{
					width: `${Math.max(MIN_VISIBLE_PROGRESS, progress)}%`,
					background:
						'linear-gradient(331.41deg, #236DF4 6.78%, #33F0A7 204.87%), linear-gradient(260.08deg, #236DF4 24.98%, #33F0A7 59.79%)',
				}}
			>
				<div
					className="w-[47px] h-[47px] absolute left-[-50px] top-[50%] translate-y-[-50%] bg-white blur-[25px]"
					style={{ mixBlendMode: 'overlay' }}
					ref={circle1Ref}
				/>
				<div
					className="w-[47px] h-[47px] absolute left-[-200px] top-[50%] translate-y-[-50%] bg-white blur-[25px]"
					style={{ mixBlendMode: 'overlay' }}
					ref={circle2Ref}
				/>
			</div>
		</div>
	);
}

export default ProgressBar;
