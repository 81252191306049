import axios from 'axios';
import { useQuery } from 'react-query';

import { API_DOMAIN, HALF_HOUR_IN_MILLISECONDS, QUERY_KEYS } from '../../../constants';
import { GroupedUserCheckoutItems } from '../InterfacesCheckout';

interface Props {
	ids?: string[];
}

async function groupCheckoutItemsByUserItemId(ids?: string[]) {
	try {
		if (!ids) {
			return;
		}
		const response = await axios.post(
			`${API_DOMAIN.mrlootBackend}/api/user/inventory/group-and-populate-items`,
			{ ids: ids },
			{ withCredentials: true }
		);
		if (response.data.status === 'success') {
			return response.data.data;
		}
		throw new Error(`Something went wrong : ${response.data.message}`);
	} catch (err) {
		throw err;
	}
}

export function useGroupedCheckoutItems({ ids }: Props) {
	return useQuery<GroupedUserCheckoutItems[]>(
		[QUERY_KEYS.inventoryItemData, 'grouped-and-populated', ids],
		() => groupCheckoutItemsByUserItemId(ids),
		{
			staleTime: HALF_HOUR_IN_MILLISECONDS,
			cacheTime: HALF_HOUR_IN_MILLISECONDS,
			enabled: !!ids,
		}
	);
}
