import { CSSProperties } from 'react';

import cssStyle from './separatorWithText.module.css';

interface Props {
	text?: string;
	textLine2?: string;
	propStyle?: CSSProperties;
	width?: string | number;
	lineColor?: string;
}

export function SeparatorWithText({ text, textLine2, propStyle, width, lineColor }: Props) {
	const separatorStyle: CSSProperties = {
		...propStyle,
		'--separator-color': lineColor || '#ACADAC',
		'--separator-width': typeof width === 'number' ? `${width}px` : width,
	} as unknown as CSSProperties;

	return (
		<div className="flex flex-col tracking-[0.09rem] text-[14px] font-semibold" style={separatorStyle}>
			<div className={`${cssStyle.separator}`}>{text}</div>
			<div className="w-full text-center">{textLine2}</div>
		</div>
	);
}
