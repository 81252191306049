import { RecommendedContainerInterface } from 'interfaces/RecommendedContainerInterfaces';

import { Carousel } from './Carousel';

interface Props {
	carousels?: RecommendedContainerInterface[];
	isLoading?: boolean;
	numLoadSkeletons?: number;
}

export function CarouselList({ carousels, isLoading, numLoadSkeletons = 1 }: Props) {
	return (
		<>
			{carousels?.map((carousel) => <Carousel key={carousel._id} label={carousel.name} boxList={carousel.boxes} />)}
			{isLoading &&
				(!carousels || carousels?.length === 0) &&
				Array.from({ length: numLoadSkeletons }).map((_, index) => <Carousel key={index} isLoading={true} />)}
		</>
	);
}
