import axios from 'axios';
import { API_DOMAIN } from '../../../constants';
import { useMutation } from 'react-query';

interface LoginCredentialsInterface {
	email: string;
	password: string;
}

async function handleLogin(loginCredentials: LoginCredentialsInterface) {
	try {
		const response = await axios.post(
			`${API_DOMAIN.mrlootBackend}/api/user/login`,
			{ email: loginCredentials.email, password: loginCredentials.password },
			{ withCredentials: true }
		);
		if (response.data.status !== 'success') {
			throw new Error(response.data.message ? response.data.message : 'Unexpected error: something went wrong');
		}
	} catch (err) {
		console.error(err);
		throw err;
	}
}

interface Props {
	onSuccess?: () => void;
	onError?: (message: string) => void;
}

export function useLoginRequest({ onSuccess, onError }: Props = {}) {
	const {
		mutate: callLogin,
		isLoading,
		error,
	} = useMutation(handleLogin, {
		onSuccess: () => {
			if (onSuccess) onSuccess();
		},
		onError: (err: any) => {
			if (onError) onError(err.response?.data?.message || err.message || 'Login failed due to server error');
		},
	});

	async function handleSignIn(credentials: LoginCredentialsInterface) {
		callLogin({ email: credentials.email, password: credentials.password });
	}

	return { handleSignIn, error, isLoading };
}
