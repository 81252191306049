import { FreeFont } from 'assets/images';
import { AmountDisplayWithCurrency } from 'components/common/AmountDisplayWithCurrency';

interface Props {
	subtotalProducts: number;
	shippingCosts: number;
}

export function OrderCostDetails({ shippingCosts, subtotalProducts }: Props) {
	return (
		<>
			<div className="w-full flex items-center justify-between">
				<h3 className="text-[14px] font-semibold">Subtotal</h3>

				<h3 className="text-[15px] font-bold flex items-center font-inter gap-x-[4px]">
					<AmountDisplayWithCurrency amount={subtotalProducts} useDivWrapper={false} imgStyle={{ height: '20px' }} />
				</h3>

				{subtotalProducts === 0 && <img src={FreeFont} alt="no shipping costs" className="hidden h-[20px]" />}
			</div>
			<div className="w-full flex items-center justify-between mt-[15px] mb-[8px]">
				<h3 className="text-[14px] font-semibold">Shipping</h3>

				<h3 className="text-[15px] font-bold flex items-center font-inter gap-x-[4px]">
					<AmountDisplayWithCurrency amount={shippingCosts} useDivWrapper={false} imgStyle={{ height: '20px' }} />
				</h3>

				{shippingCosts === 0 && <img src={FreeFont} alt="no shipping costs" className="hidden h-[20px]" />}
			</div>
		</>
	);
}
