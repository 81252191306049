import gsap from 'gsap';
import { Ref, useEffect } from 'react';

interface Props {
	ref: Ref<HTMLDivElement>;
	animationTime?: number;
}

export function useStretchUpAnimation({ ref, animationTime = 0.5 }: Props) {
	useEffect(() => {
		// We need to handle the ref more generically since it might not be a RefObject
		const element = typeof ref === 'function' ? null : ref?.current;
		const tl = gsap.timeline();

		if (element) {
			const autoHeight = element.getBoundingClientRect().height;
			tl.set(element, { height: 0 });
			tl.to(element, { height: autoHeight, duration: animationTime });
		}

		return () => {
			tl.kill();
		};
	}, [ref, animationTime]);
}
