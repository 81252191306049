import { useState } from 'react';

interface Props {
	description?: string;
}

const DISPLAYED_ROWS = 4;

export function ProductDescription({ description }: Props) {
	const [isExpanded, setIsExpanded] = useState(false);
	const isLongDescription = description ? description.split('\n').length > DISPLAYED_ROWS : false;

	const toggleExpand = () => {
		setIsExpanded((prev) => !prev);
	};

	return (
		<div className="relative flex flex-col gap-y-[12px]">
			<p
				className={`text-[14px] text-[rgba(255,255,255,0.8)] mt-[10px] leading-[27px] ${
					!isExpanded ? 'line-clamp-4' : ''
				}`}
			>
				{description}
			</p>
			{isLongDescription && (
				<button type="button" onClick={toggleExpand} className="text-blue-500">
					{isExpanded ? 'Show Less' : 'Show More'}
				</button>
			)}
		</div>
	);
}
