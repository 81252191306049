import { AmountDisplayWithCurrency } from 'components/common/AmountDisplayWithCurrency';
import { WithImgSkeleton } from 'components/common/WithImgSkeleton';
import { useBoxViewLogger } from 'hooks';
import { useEnterOrSpacePress } from 'hooks/utility/event-listener/useEnterOrSpacePress';
import { BoxInterface, PopulatedBoxInterface } from 'interfaces/BoxInterface';
import React, { forwardRef } from 'react';
import { useNavigate } from 'react-router';
import { getBoxLink } from 'utils';

import { CLOUDFRONT_IMG_URL } from '../../../constants';

interface Props {
	box: BoxInterface | PopulatedBoxInterface;
	styles?: React.CSSProperties;
	isHoverable?: boolean;
	onClick?: () => void;
	ref?: React.RefObject<HTMLDivElement>;
	trackEvents?: boolean;
}

export const BoxCard = forwardRef<HTMLDivElement, Props>(
	({ box, styles, isHoverable, trackEvents = false, onClick }: Props, ref) => {
		const {
			handleMouseEnter: startBoxViewTracking,
			handleMouseLeave: endBoxViewTracking,
			isHovering,
		} = useBoxViewLogger({ box, disableTracking: !trackEvents });

		const navigate = useNavigate();

		function handleBoxClick() {
			if (onClick) {
				onClick();
			} else {
				const boxLink = getBoxLink(box.name);
				navigate(boxLink);
			}
		}

		const handleKeyPress = useEnterOrSpacePress(handleBoxClick);

		return (
			<div
				ref={ref}
				className={`relative cursor-pointer flex-shrink-0 border-[rgba(255,255,255,0.1)] box-content border-[0.5px] rounded-[8px] aspect-[0.65/1] overflow-hidden`}
				style={{ ...styles }}
				onKeyDown={(event) => handleKeyPress(event)}
				role="button"
				tabIndex={0}
				onMouseEnter={startBoxViewTracking}
				onMouseLeave={endBoxViewTracking}
				onClick={handleBoxClick}
			>
				<div
					className={`${isHoverable && isHovering ? 'border-white border-[3px]' : 'border-transparent'}
                absolute left-[-2px] z-40 rounded-[5px] w-[calc(100%+4px)] top-[-2px] h-[calc(100%+4px)]`}
				/>
				<WithImgSkeleton>
					<img
						src={`${CLOUDFRONT_IMG_URL}/${box.portraitImageUrls?.qualityMedium}`}
						alt={box.name}
						className="w-full cursor-pointer object-cover flex-grow rounded-[4px]"
						style={{ ...styles }}
					/>
				</WithImgSkeleton>
				<div
					className={`absolute right-0 bottom-0 bg-[#2377F4] rounded-[24px_0_4px_0] pl-4 pr-2 h-[24px] text-white flex items-center`}
				>
					<AmountDisplayWithCurrency amount={box.price} style={{ fontSize: '13px' }} />
				</div>
			</div>
		);
	}
);
