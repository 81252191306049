import { useCallback, useEffect, useRef } from 'react';

interface UseTimerProps {
	duration: number; // Time in milliseconds
	onTimerEnd?: () => void; // Callback when timer ends
}

export const useTimer = ({ duration, onTimerEnd }: UseTimerProps) => {
	const isRunningRef = useRef(false); // Track whether the timer is running
	const timeLeftRef = useRef(duration); // Track remaining time
	const timerId = useRef<NodeJS.Timeout | null>(null);

	const start = useCallback(() => {
		if (isRunningRef.current) {
			return; // Prevent starting if already running
		}

		isRunningRef.current = true;
		timeLeftRef.current = duration;

		timerId.current = setInterval(() => {
			timeLeftRef.current -= 1000;

			if (timeLeftRef.current <= 0) {
				clearInterval(timerId.current!);
				timerId.current = null;
				isRunningRef.current = false;
				timeLeftRef.current = 0;
				if (onTimerEnd) {
					onTimerEnd();
				}
			}
		}, 1000);
	}, [duration, onTimerEnd]);

	const stop = useCallback(() => {
		if (timerId.current) {
			clearInterval(timerId.current);
			timerId.current = null;
		}
		isRunningRef.current = false;
	}, []);

	useEffect(() => {
		return () => {
			if (timerId.current) {
				clearInterval(timerId.current);
			}
		};
	}, []);

	return {
		isRunning: isRunningRef.current,
		timeLeft: timeLeftRef.current,
		start,
		stop,
	};
};
