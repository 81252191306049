import { IconEye, IconEyeSlash } from 'assets/icons';
import { useState } from 'react';
import { FieldError, FieldValues, Path, UseFormRegister } from 'react-hook-form';

interface PasswordInputProps<T extends FieldValues> {
	name: Path<T>;
	placeholder: string;
	register: UseFormRegister<T>;
	error?: FieldError;
}

export const PasswordInput = <T extends FieldValues>({ name, placeholder, register, error }: PasswordInputProps<T>) => {
	const [isRevealed, setIsRevealed] = useState(false);

	return (
		<div className="flex flex-col gap-y-[8px]">
			<div className="relative w-full h-[53px]">
				<input
					type={isRevealed ? 'text' : 'password'}
					className="rounded-[15px] w-full h-[53px] pl-[12px] pr-[36px] text-[15px] font-semibold outline-mrLootBlue"
					placeholder={placeholder}
					{...register(name, {
						required: `Enter a value`,
						minLength: {
							value: 8,
							message: `Password must be at least 8 characters long`,
						},
					})}
				/>
				<button
					type="button"
					onClick={() => setIsRevealed((prev) => !prev)}
					className="absolute right-1 p-1 top-[50%] translate-y-[-50%] text-[rgba(0,0,0,0.7)]"
				>
					{isRevealed ? <IconEyeSlash /> : <IconEye />}
				</button>
			</div>
			{error && <span className="text-mrLootBlue">{error.message}</span>}
		</div>
	);
};
