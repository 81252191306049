import { PrimaryBtn } from 'components/common/buttons/PrimaryBtn';
import { Line } from 'components/common/Line';
import { DBOrderReturnItemPopulated, OrderReturnGroup, ReturnGroupType } from 'interfaces/OrderReturns.interface';
import { ReturnInstructionPopup } from 'pages/MyOrders/components/ReturnInstructionPopup/ReturnInstructionPopup';
import { useState } from 'react';

import { ReturnProductInfoRow } from '../MyOrdersOverview/components/ReturnProductInfoRow/ReturnProductInfoRow';
import { ProductsHeader } from './ProductsHeader';

interface Props {
	returnGroup?: OrderReturnGroup;
	items: DBOrderReturnItemPopulated[];
}

export function MobileReturnGroupContainer({ items, returnGroup }: Props) {
	const [showReturnInstructionPopup, setShowReturnInstructionPopup] = useState(false);

	if (!returnGroup) {
		return null;
	}

	const filteredItems = items.filter((item) => item.groupId === returnGroup._id);

	if (filteredItems.length === 0) {
		return null;
	}
	// Filter the items that belong to this returnGroup by matching the groupId

	const header = returnGroup.type === ReturnGroupType.QR_CODE ? 'QR Code' : 'Instructions';
	const showInstructionBtn =
		!!returnGroup.returnInstructionPdfUrl || returnGroup.returnInstructionQRCodeUrl || returnGroup.returnInstructions;
	return (
		<div className="flex flex-col gap-y-[15px] px-mobile-side-padding bg-white pt-[15px] pb-[10px]">
			<ProductsHeader totalItems={filteredItems?.length} heading="Product Return" />

			<Line style={{ backgroundColor: '#DBDBDB' }} />

			{showReturnInstructionPopup && (
				<ReturnInstructionPopup onClose={() => setShowReturnInstructionPopup(false)} returnGroup={returnGroup} />
			)}
			<div className="flex flex-col gap-y-[15px]">
				<div className="flex flex-col gap-y-[10px]">
					{filteredItems.map((item, index) => (
						<ReturnProductInfoRow key={index} returnOrderItem={item} />
					))}
				</div>
				{showInstructionBtn && (
					<PrimaryBtn
						content={header}
						style={{ width: '140px', fontSize: '15px' }}
						onClick={() => setShowReturnInstructionPopup(true)}
					/>
				)}
			</div>
		</div>
	);
}
