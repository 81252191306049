import { EmptyInventoryFont, EmptyInventoryMrloot } from 'assets/images';

export const EmptyCart = () => (
	<div className="flex flex-col space-y-4 items-center justify-center my-auto w-full h-full">
		<img
			alt=""
			src={EmptyInventoryMrloot}
			className="object-scale-down h-[25vh] xs:h-[29vh] ss:h-[32vh] sm:h-[35vh] max-w-full"
		/>
		<img
			alt="Inventory is empty"
			src={EmptyInventoryFont}
			className="object-scale-down h-[9vh] xs:h-[10vh] ss:h-[11vh] sm:h-[12vh] max-w-[85%]"
		/>
	</div>
);
