import { BoxIcon, TrophyIcon } from 'assets/images';
import { useInventoryStore } from 'pages/inventory/store/InventoryStore';

export function BoxViewSwitch() {
	const { isBoxView, toogleBoxView } = useInventoryStore((state) => ({
		isBoxView: state.isBoxView,
		toogleBoxView: state.toogleBoxView,
	}));

	const buttons = [
		{
			label: 'Products',
			icon: TrophyIcon,
			active: !isBoxView,
		},
		{
			label: 'WinBoxes',
			icon: BoxIcon,
			active: isBoxView,
		},
	];

	return (
		<div className="flex items-center gap-x-[15px] mx-auto">
			{buttons.map((button, index) => (
				<button
					key={index}
					className={`w-[145px] xxs:w-[155px] ms:w-[180px] h-[75px] ms:h-[90px] text-[18px] xxs:text-[20px] ms:text-[25px] font-bold flex items-center justify-center gap-x-[8px] rounded-[20px] border-[2px] border-darkBlue text-white ${
						button.active ? 'bg-primary-gradient outline outline-white outline-[3px]' : 'bg-secondary-gradient'
					}`}
					onClick={toogleBoxView}
				>
					<img src={button.icon} className="h-[20px] xxs:h-[22px] ms:h-[28px] object-contain" alt={button.label} />
					<span>{button.label}</span>
				</button>
			))}
		</div>
	);
}
