import { PopulatedBoxItemInterface } from 'interfaces/BoxInterface';
import { PrizeInterface } from 'interfaces/ItemInterfaces';
import { createWithEqualityFn } from 'zustand/traditional';

import { pushHistoryState, updateHistoryState } from './HistoryManager';

export interface BoxStack {
	id: string;
	boxName: string;
	isSimiliarBoxesPopupVisible: boolean;
	prizeInspectPopup: null | PopulatedBoxItemInterface;
}

interface StoreStateInterface {
	wonPrize: PrizeInterface | null;
	slotPrizesPreWon: PrizeInterface[] | null;
	slotPrizesSurroundingWon: PrizeInterface[] | null;

	isDemoSpin: boolean;
	isAutoSpin: boolean;
	isFastSpin: boolean;
	autoSpinCount: number;

	boxPopupStack: BoxStack[];

	isSpinAmountChooserPopupVisible: boolean;
	isBoxPrizesPopupVisible: boolean;
	isOpenWithoutSessionPopupVisible: boolean;

	showWonScreen: boolean;
	isBoxOpening: boolean;
	clientSeed: string;
	hashedServerSeed: string;

	setIsAutoSpin: (isAutoSpoin: boolean) => void;
	setIsDemoSpin: (isDemoSpin: boolean) => void;
	setIsFastSpin: (isFastSpin: boolean) => void;
	setAutoSpinCount: (autoSpins: number) => void;

	setIsSpinAmountChooserPopupVisible: (isOpen: boolean) => void;
	setIsBoxPrizesPopupVisible: (isOpen: boolean) => void;
	setIsOpenWithoutSessionPopupVisible: (isOpen: boolean) => void;

	setShowSimiliarBoxesPopupOfCurrentBoxStack: (isVisible: boolean) => void;
	setPrizeInspectPopupOfCurrentBoxStack: (item: PopulatedBoxItemInterface | null) => void;
	setBoxPopupStack: (boxStack: BoxStack[]) => void;
	addBoxToPopupStack: (boxStackEl: BoxStack) => void;
	resetBoxPopupStack: () => void;
	removeLastBoxFromPopupStack: () => void;

	setShowWonScreen: (show: boolean) => void;
	setWonPrize: (prize: PrizeInterface) => void;
	setSlotPrizesPreWon: (prizes: PrizeInterface[]) => void;
	setSlotPrizesSurroundingWon: (prizes: PrizeInterface[]) => void;

	setClientSeed: (seed: string) => void;
	setHashedServerSeed: (seed: string) => void;

	toggleIsBoxOpening: () => void;
	setIsBoxOpening: (isOpening: boolean) => void;

	resetStore: () => void;
	decreaseAutoSpinCount: () => void;
	increaseAutoSpinCount: (addValue: number) => void;
}

export const useBoxOpeningStoreMobile = createWithEqualityFn<StoreStateInterface>(
	(set) => ({
		wonPrize: null,
		slotPrizesPreWon: null,
		slotPrizesSurroundingWon: null,

		isSpinAmountChooserPopupVisible: false,
		isBoxPrizesPopupVisible: false,
		isOpenWithoutSessionPopupVisible: false,

		isAutoSpin: false,
		autoSpinCount: 1,
		isDemoSpin: false,
		isFastSpin: false,

		boxPopupStack: [],

		showWonScreen: false,
		isBoxOpening: false,
		clientSeed: '',
		hashedServerSeed: '',

		setShowWonScreen: (show: boolean) => set({ showWonScreen: show }),
		setWonPrize: (prize: PrizeInterface) => set({ wonPrize: prize }),
		setSlotPrizesPreWon: (prize: PrizeInterface[]) => set({ slotPrizesPreWon: prize }),
		setSlotPrizesSurroundingWon: (prize: PrizeInterface[]) => set({ slotPrizesSurroundingWon: prize }),

		setIsSpinAmountChooserPopupVisible: (isOpen: boolean) => set({ isSpinAmountChooserPopupVisible: isOpen }),
		setIsBoxPrizesPopupVisible: (isOpen: boolean) => set({ isBoxPrizesPopupVisible: isOpen }),
		setIsOpenWithoutSessionPopupVisible: (isOpen: boolean) => set({ isOpenWithoutSessionPopupVisible: isOpen }),

		setShowSimiliarBoxesPopupOfCurrentBoxStack: (isVisible) =>
			set((state) => {
				if (state.boxPopupStack.length === 0) {
					return state;
				}
				const lastIndex = state.boxPopupStack.length - 1;
				const updatedBoxPopupStack = state.boxPopupStack.map((boxStack, index) =>
					index === lastIndex ? { ...boxStack, isSimiliarBoxesPopupVisible: isVisible } : boxStack
				);

				updateHistoryState({ boxPopupStack: updatedBoxPopupStack });

				return { boxPopupStack: updatedBoxPopupStack };
			}),

		setPrizeInspectPopupOfCurrentBoxStack: (item) =>
			set((state) => {
				if (state.boxPopupStack.length === 0) {
					return state;
				}
				const lastIndex = state.boxPopupStack.length - 1;
				const updatedBoxPopupStack = state.boxPopupStack.map((boxStack, index) =>
					index === lastIndex ? { ...boxStack, prizeInspectPopup: item } : boxStack
				);

				updateHistoryState({ boxPopupStack: updatedBoxPopupStack });

				return { boxPopupStack: updatedBoxPopupStack };
			}),

		setBoxPopupStack: (boxStack) => set({ boxPopupStack: boxStack }),
		addBoxToPopupStack: (boxStackEl) =>
			set((state) => {
				const newBoxStackState = [...state.boxPopupStack, boxStackEl];

				// adding state to go back in history
				if (newBoxStackState.length === 1) {
					updateHistoryState({ boxPopupStack: newBoxStackState });
				} else if (newBoxStackState.length > 1) {
					pushHistoryState({ boxPopupStack: newBoxStackState });
				}

				return { boxPopupStack: newBoxStackState };
			}),
		resetBoxPopupStack: () => set({ boxPopupStack: [] }),
		removeLastBoxFromPopupStack: () =>
			set((state) => ({
				boxPopupStack: state.boxPopupStack.slice(0, -1),
			})),

		setIsAutoSpin: (isAutoSpin: boolean) => set({ isAutoSpin: isAutoSpin }),
		setAutoSpinCount: (autSpins: number) => set({ autoSpinCount: autSpins }),
		setIsDemoSpin: (isDemoSpin: boolean) => set({ isDemoSpin: isDemoSpin }),
		setIsFastSpin: (isFastSpin: boolean) => set({ isFastSpin: isFastSpin }),

		setClientSeed: (seed: string) => set({ clientSeed: seed }),
		setHashedServerSeed: (seed: string) => set({ hashedServerSeed: seed }),

		toggleIsBoxOpening: () => set((state) => ({ isBoxOpening: !state.isBoxOpening })),
		setIsBoxOpening: (isOpening: boolean) => set({ isBoxOpening: isOpening }),
		decreaseAutoSpinCount: () => set((prev) => ({ autoSpinCount: Math.max(0, prev.autoSpinCount - 1) })),
		increaseAutoSpinCount: (addedValue) =>
			// eslint-disable-next-line no-magic-numbers
			set((prev) => ({ autoSpinCount: Math.min(50, prev.autoSpinCount + addedValue) })),

		resetStore: () =>
			set({
				wonPrize: null,
				slotPrizesPreWon: null,
				slotPrizesSurroundingWon: null,
				isSpinAmountChooserPopupVisible: false,
				isBoxPrizesPopupVisible: false,
				isOpenWithoutSessionPopupVisible: false,
				isAutoSpin: false,
				autoSpinCount: 1,
				isDemoSpin: false,
				isFastSpin: false,
				showWonScreen: false,
				isBoxOpening: false,
				clientSeed: '',
				hashedServerSeed: '',
				boxPopupStack: [],
			}),
	}),
	Object.is
);
