import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AmountDisplayWithCurrency } from 'components/common/AmountDisplayWithCurrency';

import { CLOUDFRONT_IMG_URL } from '../../../constants';
import { useMerchStore } from '../MerchStore';
import { BasektProductWithId } from '../shop.interfaces';
import { QuantityInput } from './QuantityInput';

interface Props {
	product: BasektProductWithId;
}

export function CartProductCard({ product }: Props) {
	const { removeProduct, changeQtyOfProduct } = useMerchStore();
	return (
		<div className="grid grid-cols-[auto_125px_auto] gap-x-[20px] items-start">
			<div className="aspect-square w-full sm:bg-white sm:flex sm:p-3 items-center justify-center">
				<img
					src={`${CLOUDFRONT_IMG_URL}/${product.item.imageUrls.qualityHigh}`}
					alt={product.item.name}
					className="object-scale-down w-full"
				/>
			</div>
			<div className="flex flex-col gap-y-[5px] text-white ">
				<h2 className="font-bold text-[20px]">{product.item.name}</h2>
				<AmountDisplayWithCurrency
					amount={product.item.price}
					imgStyle={{ height: '18px' }}
					style={{ fontSize: '18px', marginTop: '10px' }}
				/>
				{product.options?.map((option) => (
					<div key={option.label} className="flex flex-col gap-y-[3px] mt-[5px]">
						<span className="">{option.label}</span>
						<span>{option.value}</span>
					</div>
				))}
				<div className="flex items-center gap-x-[10px] mt-[10px]">
					<QuantityInput value={product.quantity} onChange={(newQty) => changeQtyOfProduct(product._id, newQty)} />
					<button onClick={() => removeProduct(product._id)}>
						<FontAwesomeIcon icon={faTrashCan} />
					</button>
				</div>
			</div>

			<AmountDisplayWithCurrency
				amount={product.quantity * product.item.price}
				style={{ marginLeft: 'auto', fontSize: '18px' }}
				imgStyle={{ height: '18px' }}
			/>
		</div>
	);
}
