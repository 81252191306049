import { createContext, PropsWithChildren, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const myHistoryContext = createContext<{
	history: Location[] | undefined;
}>({ history: [] });

export default function HistoryContext(props: PropsWithChildren) {
	const [history, setHistory] = useState<Location[]>([]);
	const location = useLocation();

	useEffect(() => {
		setHistory((prevHistory) => [...prevHistory, window.location]);
	}, [location]);

	return <myHistoryContext.Provider value={{ history }}>{props.children}</myHistoryContext.Provider>;
}
