import { WithPageTitle } from 'components/misc/WithPageTitle';
import { DesktopChangePassword } from 'pages/AccountSettings/desktop/edit-profile/DesktopChangePassword';
import { DesktopEditProfile } from 'pages/AccountSettings/desktop/edit-profile/DesktopEditProfile';
import { ManageAddresses } from 'pages/AccountSettings/desktop/edit-profile/ManageAddresses';
import { WinBoxHistory } from 'pages/AccountSettings/desktop/winbox-history/WInBoxHistory';
import { AddAddressForm } from 'pages/AccountSettings/mobile/address-forms/AddAddressForm';
import { UpdateAddressForm } from 'pages/AccountSettings/mobile/address-forms/UpdateAddressForm';
import { CurrencyChooser } from 'pages/AccountSettings/mobile/choose-currency/CurrencyChoose';
import { MobileChangeMail } from 'pages/AccountSettings/mobile/edit-profiles/MobileChangeMail';
import { MobileChangePassword } from 'pages/AccountSettings/mobile/edit-profiles/MobileChangePassword';
import { MobileEditProfile } from 'pages/AccountSettings/mobile/edit-profiles/MobileEditProfile';
import { MobileAccountSettings } from 'pages/AccountSettings/mobile/MobileAccountSettings';
import { WinBoxHistoryOverview } from 'pages/AccountSettings/mobile/WinboxHistory/WinBoxHistory';
import { DesktopMyOrders } from 'pages/MyOrders/desktop/DesktopMyOrders';
import { MobileMyOrders } from 'pages/MyOrders/mobile/MobileMyOrders';
import { OrderDetailsPage } from 'pages/MyOrders/mobile/OrderDetailsPage/OrderDetailsPage';
import { OrderReturn } from 'pages/MyOrders/mobile/OrderReturn/OrderReturn';
import { RouteObject } from 'react-router';

export const mobileAccountRoutes: RouteObject[] = [
	{
		path: 'my-account',
		element: (
			<WithPageTitle title="Account Settings - Manage Your Profile | MrLoot">
				<MobileAccountSettings />
			</WithPageTitle>
		),
	},
	{
		path: 'my-account/edit-profile',
		element: (
			<WithPageTitle title="Edit Profile - Update Your Information | MrLoot">
				<MobileEditProfile />
			</WithPageTitle>
		),
	},
	{
		path: 'my-account/choose-currency',
		element: (
			<WithPageTitle title="Currencies - Play with Bitcoin | MrLoot">
				<CurrencyChooser />
			</WithPageTitle>
		),
	},
	{
		path: 'my-account/edit-profile/change-mail',
		element: (
			<WithPageTitle title="Change Email - Update Your Email Address | MrLoot">
				<MobileChangeMail />
			</WithPageTitle>
		),
	},
	{
		path: 'my-account/edit-profile/change-password',
		element: (
			<WithPageTitle title="Change Password - Secure Your Account | MrLoot">
				<MobileChangePassword />
			</WithPageTitle>
		),
	},
	{
		path: 'my-account/edit-profile/add-address',
		element: (
			<WithPageTitle title="Add Address - Add New Delivery Location | MrLoot">
				<AddAddressForm />
			</WithPageTitle>
		),
	},
	{
		path: 'my-account/edit-profile/update-address',
		element: (
			<WithPageTitle title="Update Address - Modify Delivery Information | MrLoot">
				<UpdateAddressForm />
			</WithPageTitle>
		),
	},
	{
		path: 'my-account/winbox-history',
		element: (
			<WithPageTitle title="WinBox History - View Your Loot Wins | MrLoot">
				<WinBoxHistoryOverview />
			</WithPageTitle>
		),
	},
	{
		path: 'my-account/my-orders',
		element: (
			<WithPageTitle title="My Orders - Track Your Purchases | MrLoot">
				<MobileMyOrders />
			</WithPageTitle>
		),
	},
	{
		path: 'my-account/my-orders/details',
		element: (
			<WithPageTitle title="Order Details - View Purchase Information | MrLoot">
				<OrderDetailsPage />
			</WithPageTitle>
		),
	},
	{
		path: 'my-account/my-orders/return-items',
		element: (
			<WithPageTitle title="Return Items - Manage Product Returns | MrLoot">
				<OrderReturn />
			</WithPageTitle>
		),
	},
	{
		path: 'my-account/*',
		element: (
			<WithPageTitle title="Account Settings - Manage Your Profile | MrLoot">
				<MobileAccountSettings />
			</WithPageTitle>
		),
	},
];

export const desktopAccountRoutes: RouteObject[] = [
	{
		path: 'my-account/edit-profile',
		element: (
			<WithPageTitle title="Edit Profile - Update Your Information | MrLoot">
				<DesktopEditProfile />
			</WithPageTitle>
		),
	},
	{
		path: 'my-account/choose-currency',
		element: (
			<WithPageTitle title="Currencies - Play with Bitcoin | MrLoot">
				<CurrencyChooser />
			</WithPageTitle>
		),
	},
	{
		path: 'my-account/edit-profile/change-mail',
		element: (
			<WithPageTitle title="Change Email - Update Your Email Address | MrLoot">
				<DesktopEditProfile />
			</WithPageTitle>
		),
	},
	{
		path: 'my-account/edit-profile/change-password',
		element: (
			<WithPageTitle title="Change Password - Secure Your Account | MrLoot">
				<DesktopChangePassword />
			</WithPageTitle>
		),
	},
	{
		path: 'my-account/edit-profile/manage-address',
		element: (
			<WithPageTitle title="Manage Addresses - Update Your Delivery Details | MrLoot">
				<ManageAddresses />
			</WithPageTitle>
		),
	},
	{
		path: 'my-account/edit-profile/add-address',
		element: (
			<WithPageTitle title="Add Address - Add New Delivery Location | MrLoot">
				<AddAddressForm />
			</WithPageTitle>
		),
	},
	{
		path: 'my-account/edit-profile/update-address',
		element: (
			<WithPageTitle title="Update Address - Modify Delivery Information | MrLoot">
				<UpdateAddressForm />
			</WithPageTitle>
		),
	},
	{
		path: 'my-account/winbox-history',
		element: (
			<WithPageTitle title="WinBox History - View Your Loot Wins | MrLoot">
				<WinBoxHistory />
			</WithPageTitle>
		),
	},
	{
		path: 'my-account/my-orders',
		element: (
			<WithPageTitle title="My Orders - Track Your Purchases | MrLoot">
				<DesktopMyOrders />
			</WithPageTitle>
		),
	},
	{
		path: 'my-account/my-orders/details',
		element: (
			<WithPageTitle title="Order Details - View Purchase Information | MrLoot">
				<OrderDetailsPage />
			</WithPageTitle>
		),
	},
	{
		path: 'my-account/*',
		element: (
			<WithPageTitle title="Edit Profile - Update Your Information | MrLoot">
				<DesktopEditProfile />
			</WithPageTitle>
		),
	},
];
