import { persist } from 'zustand/middleware';
import { createWithEqualityFn } from 'zustand/traditional';

export enum CurrencyOptions {
	LOOTIES = 'looties',
	US_DOLLAR = 'us-dollar',
	BITCOIN = 'bitcoin',
	ETHERIUM = 'etherium',
	SOLANA = 'solana',
	DOGECOIN = 'dogecoin',
}

interface AppState {
	newWonItemIds: string[] | null;
	newInventoryBoxIds: string[] | null;
	clearNewWonItemIds: () => void;
	clearNewInventoryBoxIds: () => void;
	addNewWonItemId: (newId: string) => void;
	addNewInventoryBoxId: (newId: string) => void;
	lastLocationBeforeLogin: string | null;
	setLastLocationBeforeLogin: (location: string) => void;
	showBackBtnInTopBar: boolean;
	setShowBackBtnInTopBar: (show: boolean) => void;
	scrollPositions: { [key: string]: number };
	saveScrollPosition: (key: string, position: number) => void;
	restoreScrollPosition: (key: string) => number | undefined;
	resetScrollPosition: () => void;
	selectedCurrency: CurrencyOptions;
	cryptoPollingStartTime: Date | undefined; // Set when polling for crypto deposit begins
	setCryptoPollingStartTime: (date: Date | undefined) => void;
	setSelectedCurrency: (currency: CurrencyOptions) => void;
}

export const useAppStore = createWithEqualityFn<AppState>()(
	persist(
		(set, get) => ({
			newWonItemIds: null,
			newInventoryBoxIds: null,
			addNewWonItemId: (newId) =>
				set((state) => ({
					newWonItemIds: state.newWonItemIds ? [...state.newWonItemIds, newId] : [newId],
				})),
			addNewInventoryBoxId: (newId) =>
				set((state) => ({
					newInventoryBoxIds: state.newInventoryBoxIds ? [...state.newInventoryBoxIds, newId] : [newId],
				})),
			clearNewWonItemIds: () => set({ newWonItemIds: null }),
			clearNewInventoryBoxIds: () => set({ newInventoryBoxIds: null }),
			lastLocationBeforeLogin: null,
			setLastLocationBeforeLogin: (location: string) => set({ lastLocationBeforeLogin: location }),
			showBackBtnInTopBar: false,
			setShowBackBtnInTopBar: (show) => set({ showBackBtnInTopBar: show }),
			scrollPositions: {},
			saveScrollPosition: (key: string, position: number) => {
				const { scrollPositions } = get();
				set({ scrollPositions: { ...scrollPositions, [key]: position } });
			},
			restoreScrollPosition: (key: string) => {
				const { scrollPositions } = get();
				return scrollPositions[key];
			},
			resetScrollPosition: () => set({ scrollPositions: {} }),
			selectedCurrency: CurrencyOptions.US_DOLLAR,
			setSelectedCurrency: (selectedCurrency: CurrencyOptions) => set({ selectedCurrency: selectedCurrency }),

			isCurrencyPopupVisible: false,
			cryptoPollingStartTime: undefined,
			setCryptoPollingStartTime: (date) => set({ cryptoPollingStartTime: date }),
		}),
		{
			name: 'app-store',
			partialize: (state) => ({
				lastLocationBeforeLogin: state.lastLocationBeforeLogin,
				newWonItemIds: state.newWonItemIds,
				scrollPositions: state.scrollPositions,
				cryptoPollingStartTime: state.cryptoPollingStartTime,
				selectedCurrency: state.selectedCurrency,
				// We explicitly do not include showBackBtnInTopBar here, so it is not persisted
			}),
		}
	),
	Object.is
);
