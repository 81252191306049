import { Dropdown } from 'components';
import { ItemOption } from 'interfaces/ItemInterfaces';
import { SelectedItemOption } from 'pages/checkout/InterfacesCheckout';

interface ItemOptionDropdownProps {
	selectedOption: SelectedItemOption;
	availableOptions: ItemOption[];
	onSelectionChange: (updatedOption: SelectedItemOption) => void;
	hasError: boolean;
}

export function ItemOptionDropdown({
	selectedOption,
	availableOptions,
	hasError,
	onSelectionChange,
}: ItemOptionDropdownProps) {
	// Find the matching availableOption based on the optionProperty
	const matchingAvailableOption = availableOptions.find(
		(option) => option.optionProperty === selectedOption.optionProperty
	);

	// Extract values from matchingAvailableOption or set an empty array
	const dropdownOptions = matchingAvailableOption ? matchingAvailableOption.values : [];

	return (
		<div className="grid grid-cols-[80px_auto] items-center flex-wrap mt-[8px] gap-[10px]">
			<span className="text-[15px] text-white font-semibold">{selectedOption.label}:*</span>
			<Dropdown
				options={dropdownOptions}
				onOptionSelect={(optionValue) => onSelectionChange({ ...selectedOption, value: optionValue })}
				selectedOption={selectedOption.value}
				buttonStyle={{ height: 'fit-content' }}
				hasError={hasError}
			/>
		</div>
	);
}
