import { CSSProperties, ReactNode } from 'react';

interface Props {
	style?: CSSProperties;
	placeholder?: string;
	icon?: ReactNode; // Optional FontAwesome icon component
}

export function PrimaryInput({ style, placeholder, icon }: Props) {
	return (
		<div className="relative w-full">
			{icon && <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-[#C7C6CA]">{icon}</span>}

			<input
				className={`outline outline-[2px] outline-white border-2 border-[#3559D7] rounded-[10px] h-[48px] w-full shadow-[0px_4px_5px_rgba(0,_0,_0,_0.3)] px-4 text-white placeholder-[#C7C6CA] ${icon ? 'pl-10' : 'pl-4'}`}
				style={{
					background: 'linear-gradient(331.41deg, rgba(35, 109, 244, 0.3) 6.78%, rgba(51, 240, 167, 0.3) 204.87%)',
					...style,
				}}
				placeholder={placeholder}
			/>
		</div>
	);
}
