import { LogoBlue } from 'assets/images';
import { Link, useNavigate } from 'react-router-dom';

import { PasswordInputWrapper } from './components/PasswordInputWrapper';
import { EmailInputWrapper } from './components/EmailInputWrapper';
import { SubmitHandler, useForm } from 'react-hook-form';
import { CheckboxWrapper } from './components/CheckboxWrapper';
import { useRegisterRequest } from '../hooks/useRegisterRequest';
import { PrimaryBtn } from 'components/common/buttons/PrimaryBtn';
import { useUserContext } from 'hooks';
import { ToastErrorNotification } from 'components/toasts/ToastErrorNotification';
import { toast } from 'react-toastify';
import { SeparatorWithText } from 'components/Separator/SeparatorWithText';
import OAuthBtnsHorizontal from './components/OAuthBtnsHorizontal';

interface RegisterFormState {
	email: string;
	password: string;
	passwordRepeat: string;
	acceptedInfoMails: boolean;
	acceptedTerms: boolean;
}

// eslint-disable-next-line max-lines-per-function
export function DesktopRegister() {
	const navigate = useNavigate();

	const { load: loadUser } = useUserContext();

	const { control, handleSubmit, setError } = useForm<RegisterFormState>();

	const onSubmit: SubmitHandler<RegisterFormState> = (data) => {
		if (data.password !== data.passwordRepeat) {
			setError('passwordRepeat', {
				type: 'manual',
				message: 'Passwords do not match',
			});
			return;
		}

		handleSignUp(data);
	};

	const { handleSignUp, isLoading: isRegisterLoading } = useRegisterRequest({
		onSuccess: () => {
			loadUser();
			navigate('/successful-register');
		},
		onError: (message) => {
			toast(<ToastErrorNotification message={message ?? 'Something went wrong'} />);
			if (message === 'Email Address is already taken') {
				setError('email', {
					type: 'manual',
					message: message,
				});
			}
		},
	});

	return (
		<div className="overflow-y-auto h-full max-h-full w-full">
			<form className={`h-full flex flex-col items-center justify-around gap-y-5`} onSubmit={handleSubmit(onSubmit)}>
				<img alt="" src={LogoBlue} className="h-[3rem]" />
				<div className="w-[75%] flex flex-col gap-y-[20px] mt-[10px]">
					<OAuthBtnsHorizontal
						isRequestLoading={isRegisterLoading}
						textStyle={{ color: 'black' }}
						checkboxStyle={{ color: 'black', borderColor: 'black' }}
					/>
				</div>
				<SeparatorWithText
					text="Or"
					propStyle={{ color: 'rgba(0,0,0,0.5)', fontWeight: 400, margin: '10px 0 5px' }}
					width={120}
					lineColor="rgba(0, 0, 0, 0.4)"
				/>
				<div className="flex flex-col w-[75%] space-y-3">
					<h1 className="text-[1.4rem] text-black font-semibold">Register</h1>
					<EmailInputWrapper
						name="email"
						control={control}
						rules={{
							required: 'Email is required',
							pattern: {
								value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
								message: 'Invalid email address',
							},
						}}
						label="Email Address"
					/>
					<PasswordInputWrapper
						name="password"
						control={control}
						rules={{
							required: 'Password is required',
							minLength: {
								value: 8,
								message: 'Password must be at least 8 characters long',
							},
						}}
						label="Password"
						placeholder="Enter your password"
					/>
					<PasswordInputWrapper
						name="passwordRepeat"
						control={control}
						rules={{
							required: 'Password is required',
							minLength: {
								value: 8,
								message: 'Password must be at least 8 characters long',
							},
						}}
						label="Password"
						placeholder="Enter your password"
					/>
					<div className="flex space-x-3 items-center">
						<CheckboxWrapper name="acceptedInfoMails" control={control} />
						<span>I would like to receive free boxes, discounts, promotions and informations via email.</span>
					</div>
					<div className="flex space-x-3 items-center">
						<CheckboxWrapper
							name="acceptedTerms"
							control={control}
							rules={{
								required: 'You must agree to the terms and conditions',
							}}
						/>
						<span>
							I declare to have read, understood and accepted the{' '}
							<Link to="general-terms-and-conditions" className="underline">
								General Terms and Conditions
							</Link>
							. By clicking this box, I also declare that I am 18+ years old. *
						</span>
					</div>
					<PrimaryBtn
						isDisabled={isRegisterLoading}
						type="submit"
						content="Sign Up"
						style={{ height: '50px', minHeight: '50px' }}
					/>
				</div>

				<div className="flex items-center text-xs">
					<span>Already have an account?</span>
					<Link to="/auth/login" className="font-semibold ml-1 hover:underline">
						Go back to Login
					</Link>
				</div>
			</form>
		</div>
	);
}
