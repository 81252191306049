import { Skeleton } from 'components';

interface Props {
	showBottomBorder: boolean;
}

export function SkeletonCartProductContainer({ showBottomBorder }: Props) {
	return (
		<div
			className={`flex flex-col w-full ${showBottomBorder && 'border-white border-b-[3px] '} p-[15px] space-y-[15px]`}
		>
			<div className="flex flex-col space-y-[5px]">
				<div className="w-full flex items-center justify-between">
					<Skeleton style={{ height: '25px', width: '160px' }} />
					<Skeleton style={{ height: '30px', width: '90px' }} />
				</div>
				<Skeleton style={{ height: '18px', width: '80px' }} />
			</div>

			<div className="w-full h-fit grid grid-cols-[3fr_4fr] gap-[20px] items-center">
				<Skeleton style={{ width: '100%', aspectRatio: ' 1 / 1', height: 'fit-content' }} />
				<div className="w-full h-fit mt-auto flex-grow flex flex-col items-center justify-end space-y-[20px]">
					<Skeleton style={{ width: '100%', height: '40px' }} />
					<Skeleton style={{ width: '100%', height: '40px' }} />
					<Skeleton style={{ width: '50%', height: '23px' }} />
				</div>
			</div>
		</div>
	);
}
