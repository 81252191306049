import { PrimaryBtn } from 'components/common/buttons/PrimaryBtn';
import { ToastErrorNotification } from 'components/toasts/ToastErrorNotification';
import { COLORS } from '../../../../constants';
import { useResetPasswordMail } from 'pages/authentication/hooks/useResetPasswordMail';
import { useAuthenticationStore } from 'pages/authentication/store/useAuthenticationStore';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { LogoBlue } from 'assets/images';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { EmailInputWrapper } from '../components/EmailInputWrapper';

export function DesktopResetPasswordEmailStep() {
	const { setEmailForPasswordReset } = useAuthenticationStore();

	const navigate = useNavigate();

	const { mutate: requestResetPasswordMail, isLoading } = useResetPasswordMail({
		onSuccess: () => {
			navigate('/auth/reset-password/verification-code');
		},
		onError: (message) => {
			toast(<ToastErrorNotification message={message} />, {
				style: { backgroundColor: COLORS.blue90 },
			});
		},
	});

	const { control, handleSubmit } = useForm<{ email: string }>();

	const onSubmit: SubmitHandler<{ email: string }> = (data) => {
		setEmailForPasswordReset(data.email);
		requestResetPasswordMail(data.email);
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)} className="h-full w-full flex flex-col items-center justify-around gap-y-5">
			<img alt="" src={LogoBlue} className="h-[3rem]" />
			<div className="flex flex-col w-[75%] gap-y-3">
				<h1 className="text-[1.4rem] text-black font-semibold">Password Reset</h1>
				<EmailInputWrapper
					name="email"
					control={control}
					rules={{
						required: 'Email is required',
						pattern: {
							value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
							message: 'Invalid email address',
						},
					}}
					label="Email Address"
				/>
				<PrimaryBtn type="submit" content="Send" style={{ height: '46px', marginTop: '15px' }} isDisabled={isLoading} />
			</div>
			<Link to="/auth/login" className="flex items-center gap-x-[9px]">
				<FontAwesomeIcon icon={faChevronLeft} />
				<span>back to login</span>
			</Link>
		</form>
	);
}
