import { useController, UseControllerProps, FieldValues } from 'react-hook-form';
import { EmailInput } from './EmailInput';

interface EmailInputWrapperProps<T extends FieldValues> extends UseControllerProps<T> {
	label?: string;
}

export function EmailInputWrapper<T extends FieldValues>({ name, control, rules, label }: EmailInputWrapperProps<T>) {
	const {
		field,
		fieldState: { error },
	} = useController({
		name,
		control,
		rules,
	});

	return <EmailInput label={label} error={error?.message} {...field} />;
}
