import { ItemOption } from 'interfaces/ItemInterfaces';
import { FailedItemsMap, SelectedItemOption, SelectedOptionSet } from 'pages/checkout/InterfacesCheckout';
import { useEffect, useState } from 'react';

import { ItemOptionDropdown } from './ItemOptionDropdown';

interface Props {
	itemId: string;
	itemOptions: ItemOption[];
	quantity: number;
	onSelectedOptionChange: (updatedOptionSet: SelectedItemOption, indexOfOptionSet: number) => void;
	selectedOptions?: SelectedOptionSet[];
	optionErrors?: FailedItemsMap;
}

export function ItemOptionsSection({
	itemId,
	itemOptions,
	optionErrors,
	quantity,
	onSelectedOptionChange,
	selectedOptions,
}: Props) {
	const [currentOptionSetIndex, setCurrentOptionSetIndex] = useState(0);

	useEffect(() => {
		if (currentOptionSetIndex > quantity - 1) {
			setCurrentOptionSetIndex(0);
		}
	}, [currentOptionSetIndex, quantity]);

	const hasChooseButtonError = (index: number) => {
		return optionErrors && optionErrors[itemId] && optionErrors[itemId][index];
	};

	// Helper function to check if a dropdown has an error
	const hasDropdownError = (optionId: string, index: number) => {
		return (
			optionErrors &&
			optionErrors[itemId] &&
			optionErrors[itemId][index] &&
			optionErrors[itemId][index].some((option) => option._id === optionId)
		);
	};

	const showOptionChooseBtn = itemOptions.length > 0 && quantity > 1;

	return (
		<div className="flex flex-col">
			{showOptionChooseBtn && (
				<span className="text-[12px] text-[rgba(255,255,255,0.6)] mt-[10px]">Product Variants:</span>
			)}
			{showOptionChooseBtn && (
				<div className="flex items-center gap-[5px] flex-wrap my-[10px]">
					{selectedOptions?.map((option, index) => (
						<button
							key={index}
							onClick={() => setCurrentOptionSetIndex(index)}
							className={`${hasChooseButtonError(index) ? 'border-errorRed' : 'border-transparent'} border-[1.5px] ${index === currentOptionSetIndex ? 'bg-mrLootBlue text-white' : 'bg-white text-black'} px-[4px] rounded-[4px] flex items-center justify-center w-[80px] h-[32px] text-[11px]`}
						>
							<span className="line-clamp-1">
								{option.optionSelections.every((el) => el.value.length > 0)
									? option.optionSelections.map((el) => el.value).join(',')
									: `${index + 1}. Choose`}
							</span>
						</button>
					))}
				</div>
			)}

			{selectedOptions &&
				selectedOptions.length >= quantity - 1 &&
				selectedOptions.length >= currentOptionSetIndex - 1 &&
				selectedOptions[currentOptionSetIndex].optionSelections?.map((option) => (
					<ItemOptionDropdown
						key={option._id}
						selectedOption={option}
						availableOptions={itemOptions}
						hasError={!!hasDropdownError(option._id, currentOptionSetIndex)}
						onSelectionChange={(updatedOption) => onSelectedOptionChange(updatedOption, currentOptionSetIndex)}
					/>
				))}
		</div>
	);
}
