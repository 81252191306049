import { faCheck, faPenToSquare, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ToastErrorNotification } from 'components/toasts/ToastErrorNotification';
import { ToastSuccessNotification } from 'components/toasts/ToastSuccessNotification';
import { useResendVerificationMail } from 'pages/AccountSettings/hooks/useResendVerificationMail';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppStore } from 'store/useAppStore';
import { shallow } from 'zustand/shallow';

interface Props {
	value?: string;
	isVerified?: boolean;
}

export function EmailAddressChangeLink({ value, isVerified }: Props) {
	const navigate = useNavigate();
	const setShowBackBtnInTopBar = useAppStore((state) => state.setShowBackBtnInTopBar, shallow);

	function handleLinkClick() {
		setShowBackBtnInTopBar(true);
		navigate('change-mail');
	}

	const { mutate: resendVerificationMail, isLoading: isResendMailReqLoading } = useResendVerificationMail({
		onSuccess: () => {
			toast(<ToastSuccessNotification message="Verification e-mail has been send to your mail address" />);
		},
		onError: () => {
			toast(<ToastErrorNotification message="Could not send mail to your mail address" />);
		},
	});

	return (
		<div className="flex flex-col gap-y-[6px]">
			<button
				onClick={handleLinkClick}
				className={`${!value ? 'text-[rgba(0,0,0,0.7)]' : isVerified ? 'text-black' : 'text-orange-500'} w-full h-[53px] flex items-center justify-between pl-[18px] pr-[12px] text-[14px] bg-white rounded-[15px]`}
			>
				{isVerified && <FontAwesomeIcon icon={faCheck} className="mr-4 text-green-400" />}
				<span className={`font-semibold ${isVerified && 'text-green-400 mr-auto'}`}>
					{value ?? 'Add E-Mail Address'}
				</span>
				<div className="text-black rounded-full flex items-center justify-center h-[36px] w-[36px] border-[1px] border-black shadow-[0_4px_4px_rgba(0,0,0,0.25)]">
					<FontAwesomeIcon icon={faPenToSquare} className="text-[18px]" />
				</div>
			</button>
			{!isVerified && (
				<div className="flex items-center justify-between">
					<span className="text-[14px] text-orange-500">Not verified</span>
					<button disabled={isResendMailReqLoading} onClick={() => resendVerificationMail()} className="text-white">
						{isResendMailReqLoading && <FontAwesomeIcon icon={faSpinner} className="animate-spin" />}
						{!isResendMailReqLoading && <span>verify</span>}
					</button>
				</div>
			)}
		</div>
	);
}
