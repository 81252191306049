import { gsap } from 'gsap';
import { RefObject, useCallback } from 'react';

interface Props {
	itemsWrapperRef: RefObject<HTMLDivElement>;
}

export function useTranslatePrizeWrapper({ itemsWrapperRef }: Props) {
	const translatePrizeWrapperByCards = useCallback(
		(numCards: number) => {
			if (!itemsWrapperRef) {
				return;
			}

			// Calculate the current offset
			const currentX = gsap.getProperty(itemsWrapperRef.current, 'x') as number;

			// Determine the card width and gap
			const firstItemCardEl = itemsWrapperRef.current?.children[0] as HTMLElement;
			const secondItemCardEl = itemsWrapperRef.current?.children[1] as HTMLElement;

			if (!firstItemCardEl || !secondItemCardEl) {
				console.error('Item card elements are missing.');
				return;
			}

			const cardWidth = firstItemCardEl.getBoundingClientRect().width;
			const gapBetweenItems = secondItemCardEl.offsetLeft - (firstItemCardEl.offsetLeft + firstItemCardEl.offsetWidth);

			const moveOffset = numCards * (cardWidth + gapBetweenItems);

			// Calculate the new position
			const targetX = currentX - moveOffset;

			// Move the wrapper
			gsap.set(itemsWrapperRef.current, { x: targetX });
		},
		[itemsWrapperRef]
	);

	return { translatePrizeWrapperByCards };
}
