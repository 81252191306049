import { screens } from '../../../constants';

/* eslint-disable no-magic-numbers */
const BANNER_ASPECT_RATIO_DEFAULT = 1.8 / 1;
const BANNER_ASPECT_RATIO_XS = 2.2 / 1;
const BANNER_ASPECT_RATIO_SS = 2.4 / 1;
const BANNER_ASPECT_RATIO_SM = 3 / 1;

export function getAspectRatio(screenWidth: number): number {
	if (screenWidth >= screens.sm.width) {
		return BANNER_ASPECT_RATIO_SM;
	} else if (screenWidth >= screens.ss.width) {
		return BANNER_ASPECT_RATIO_SS;
	} else if (screenWidth >= screens.xs.width) {
		return BANNER_ASPECT_RATIO_XS;
	}
	return BANNER_ASPECT_RATIO_DEFAULT;
}
