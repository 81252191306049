import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PrimaryBtn } from 'components/common/buttons/PrimaryBtn';
import { useNavigate } from 'react-router';

import { Breadcrumb } from '../components/Breadcrumb';
import { EmailSettingsRow } from './EmailSettingsRow';

export function DesktopEditProfile() {
	const navigate = useNavigate();

	return (
		<div className="flex flex-col gap-y-[20px]">
			<Breadcrumb items={[{ label: 'Account' }]} />

			<EmailSettingsRow />
			<PrimaryBtn
				onClick={() => navigate('change-password')}
				renderEllipse={false}
				style={{ height: '52px', width: '350px', borderRadius: '10px' }}
				innerContentStyle={{ borderRadius: '8px' }}
				content={
					<div className="w-full flex justify-between items-center text-white text-[15px] font-semibold">
						<span>Change Password</span>
						<FontAwesomeIcon icon={faChevronRight} />
					</div>
				}
			/>
			<PrimaryBtn
				onClick={() => navigate('/my-account/edit-profile/manage-address')}
				renderEllipse={false}
				style={{ height: '52px', width: '350px', borderRadius: '10px' }}
				innerContentStyle={{ borderRadius: '8px' }}
				content={
					<div className="w-full flex justify-between items-center text-white text-[15px] font-semibold">
						<span>Manage Shipping Address</span>
						<FontAwesomeIcon icon={faChevronRight} />
					</div>
				}
			/>
		</div>
	);
}
