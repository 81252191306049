import { usePaymentTransactions } from 'hooks/data/transactions/usePaymentTransactions';
import { useBoxOpeningStoreDesktop } from 'pages/OpenBox/store/useBoxOpeningStoreDesktop';

import { NotEnoughMoneyPopup } from '../views/NotEnoughMoneyPopup';
import { OpenOptionsScreen } from '../views/OpenOptionsScreen';
import { RegisterPopup } from '../views/RegisterPopup/RegisterPopup';
import WinScreen from '../views/WinScreen/WinScreen';

const useBoxOpeningStorePopupSelector = () => {
	return useBoxOpeningStoreDesktop((state) => ({
		showWonScreen: state.showWonScreen,
		showOpenOptionsScreen: state.showOpenOptionsScreen,
		showRegisterScreen: state.showRegisterScreen,
		setIsNotEnoughBalancePopupVisible: state.setIsNotEnoughBalancePopupVisible,
		isNotEnoughBalancePopupVisible: state.isNotEnoughBalancePopupVisible,
	}));
};

export function SlotPopupHandler() {
	const {
		showWonScreen,
		showOpenOptionsScreen,
		showRegisterScreen,
		setIsNotEnoughBalancePopupVisible,
		isNotEnoughBalancePopupVisible,
	} = useBoxOpeningStorePopupSelector();

	const { data: paymentHistory } = usePaymentTransactions();

	return (
		<>
			{showOpenOptionsScreen && <OpenOptionsScreen />}
			{showWonScreen && <WinScreen hideSellBtn={!paymentHistory || paymentHistory.length === 0} />}
			{showRegisterScreen && <RegisterPopup />}

			{isNotEnoughBalancePopupVisible && (
				<NotEnoughMoneyPopup onClose={() => setIsNotEnoughBalancePopupVisible(false)} />
			)}
		</>
	);
}
