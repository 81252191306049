import { CheckBox } from 'components';
import { ShippingAddressInterface } from 'interfaces/OrderInterfaces';
import { Link } from 'react-router-dom';

import { GroupedOrderItemInterface } from '../../InterfacesCheckout';
import { ProductContainer } from '../ProductsContainer/ProductsContainer';

interface Props {
	address?: ShippingAddressInterface;
	sortedOrderItems?: GroupedOrderItemInterface[];
	isExpressShipping: boolean;
}

export function Step3({ address, sortedOrderItems, isExpressShipping }: Props) {
	return (
		<div className="flex-col w-full py-[32px] border-[3px] rounded-[24px] shadow-[0_4px_4px_rgba(0,0,0,0.25)] border-white01 px-[28px] text-white">
			<h1 className="text-[24px] mb-[35px]">3. Review</h1>

			<h2 className="text-[20px] font-semibold mb-[15px]">Shipping</h2>
			<div className="flex items-start space-x-[30px] mb-[50px]">
				<span className="text-[15px]">Delivery Address:</span>
				<div className="flex flex-col text-[14px]">
					<span className="font-[500] mb-[9px]">
						{address?.firstName} {address?.lastName}
					</span>
					<span className="font-[500] mb-[5px]">
						{address?.street} {address?.houseNr}
					</span>
					<span className="font-[500] mb-[5px]">
						{address?.zipCode} {address?.city}
					</span>
					<span className="font-[500]">{address?.country}</span>
				</div>
			</div>

			<h2 className="text-[20px] font-semibold mb-[15px]">Order Assignment</h2>
			<div className="flex items-center w-full justify-start text-[14px] space-x-[20px]">
				<CheckBox
					checked={true}
					borderColor="rgba(255,255,255,0.2)"
					borderWidth="2px"
					backgroundColor="white"
					borderRadius="4px"
					strokeColor="#1A1C1E"
					size={{ height: '19px', width: '19px' }}
				/>
				<span>
					I have read and agree to the{' '}
					<Link to="shipping-policy" className="underline">
						Shipping Policy
					</Link>{' '}
					and wish to proceed with shipping
				</span>
			</div>

			<h2 className="text-[20px] font-semibold mb-[15px] mt-[50px]">Items in this order({sortedOrderItems?.length})</h2>
			<div className="space-y-[15px] pointer-events-none">
				{sortedOrderItems?.map((el) => (
					<ProductContainer
						key={el.selectedInventoryItemIds.toString()}
						item={el.item}
						quantity={el.quantity}
						onlyDisplay={true}
						additionalPurchaseNum={el.quantity - el.selectedInventoryItemIds.length}
						isExpressShipping={isExpressShipping}
						note={el.note}
						selectedOptions={el.selectedOptions}
					/>
				))}
			</div>
		</div>
	);
}
