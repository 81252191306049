import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AmountDisplayWithCurrency } from 'components/common/AmountDisplayWithCurrency';
import { forwardRef } from 'react';

interface Props {
	isDisabled: boolean;
	isLoading: boolean;
	onClick: () => void;
	sellPrice: number;
}

export const SellButton = forwardRef<HTMLButtonElement, Props>(({ isDisabled, isLoading, onClick, sellPrice }, ref) => (
	<button
		ref={ref}
		disabled={isDisabled}
		onClick={onClick}
		className={`w-[145px] h-[55px] absolute bottom-0 right-[10px] text-white `}
	>
		<div
			className={`${isDisabled && 'opacity-50'} h-full w-full border-[2px] border-white flex items-center justify-between px-[20px] font-inter space-x-[10px] bg-[#E9B329] rounded-[18px] `}
		>
			{!isLoading ? (
				<>
					<span className="font-bold text-[16px] text-white flex items-center">Sell</span>
					<AmountDisplayWithCurrency
						amount={sellPrice}
						imgStyle={{ height: '18px' }}
						amountStyle={{ fontSize: '16px' }}
					/>
				</>
			) : (
				<FontAwesomeIcon icon={faSpinner} className="animate-spin mx-auto" />
			)}
		</div>
	</button>
));

SellButton.displayName = 'SellButton';
