import { FilterOptions } from '../shop.interfaces';

interface Props {
	selectedFilter: FilterOptions;
	onChange: (filter: FilterOptions) => void;
}

export function FilterOptionList({ onChange, selectedFilter }: Props) {
	return (
		<div className="flex items-center justify-center flex-wrap gap-[10px]">
			{Object.values(FilterOptions).map((el, index) => (
				<button
					key={index}
					className={`${
						el === selectedFilter ? 'bg-primary-gradient' : ''
					} px-[14px] h-[35px] border-[2px] border-[rgba(255,255,255,0.7)] text-white rounded-[20px] text-[13px] hover:bg-primary-gradient shadow-btnSm font-semibold`}
					onClick={() => onChange(el)}
				>
					{el}
				</button>
			))}
		</div>
	);
}
