import { useAutoAnimate } from '@formkit/auto-animate/react';
import { Skeleton } from 'components';
import { LoadSpinner } from 'components/common/loading/LoadSpinner';
import { useAutoFocus, useInfiniteScroll, useWindowDimensions } from 'hooks';
import { useUserContext } from 'hooks/context/useUserContext';
import { useCallback, useRef } from 'react';

import { screens } from '../../../constants';
import { useInventoryBoxes } from '../hooks/useInventoryBoxes';
import EmptyInventory from './EmptyInventory';
import { InventoryOrderOptions } from './InterfacesUI';
import { InventoryBoxCard } from './InventoryCard/InventoryBoxCard';

interface Props {
	searchInput: string;
	orderOption: InventoryOrderOptions;
}

const DEFAULT_ROWS = 5;
const ITEMS_XL = 7;
const ITEMS_LGX = 6;
const ITEMS_LG = 5;
const ITEMS_MD = 4;
const ITEMS_MDS = 6;
const ITEMS_DEFAULT = 5;

const getItemsPerPage = (screenWidth: number) => {
	return screenWidth >= screens.xl.width
		? ITEMS_XL * DEFAULT_ROWS
		: screenWidth >= screens.lgx.width
			? ITEMS_LGX * DEFAULT_ROWS
			: screenWidth >= screens.lg.width
				? ITEMS_LG * DEFAULT_ROWS
				: screenWidth >= screens.md.width
					? ITEMS_MD * DEFAULT_ROWS
					: screenWidth >= screens.mds.width
						? ITEMS_MDS * DEFAULT_ROWS
						: ITEMS_DEFAULT * DEFAULT_ROWS;
};

export function BoxesGrid({ searchInput, orderOption }: Props) {
	const { user } = useUserContext();

	const lastBoxRef = useRef<HTMLDivElement>(null);

	const firstBoxRef = useRef<HTMLDivElement>(null);
	useAutoFocus(firstBoxRef);

	const { screenWidth } = useWindowDimensions();
	const itemsPerPage = getItemsPerPage(screenWidth);

	const {
		data: inventoryBoxes,
		isLoading: isInventoryLoading,
		hasNextPage,
		isFetchingNextPage,
		loadMore,
	} = useInventoryBoxes({
		limit: itemsPerPage,
		order: orderOption,
		query: searchInput,
	});

	const handleLoadOnInfiniteScroll = useCallback(() => {
		if (hasNextPage && (!isInventoryLoading || !isFetchingNextPage)) {
			loadMore();
		}
	}, [hasNextPage, isFetchingNextPage, isInventoryLoading, loadMore]);

	useInfiniteScroll(lastBoxRef, handleLoadOnInfiniteScroll);

	const isInventoryEmpty = !isInventoryLoading && inventoryBoxes?.length === 0;

	const [parent] = useAutoAnimate();

	return (
		<div className="w-full h-full">
			{isInventoryEmpty && <EmptyInventory needToDeposit={!!user && user.balance === 0} />}

			{!isInventoryEmpty && (
				<div className="flex flex-col py-[15px] px-[15px] lg:px-[25px] lg:py-[25px]">
					<div
						ref={parent}
						className="w-full flex-grow mb-4 grid grid-cols-5 mds:grid-cols-6 md:grid-cols-4 lg:grid-cols-5 lgx:grid-colds-6 xl:grid-cols-7 gap-[15px] lg:gap-[25px]"
					>
						{!isInventoryLoading &&
							inventoryBoxes &&
							inventoryBoxes.length > 0 &&
							inventoryBoxes.map((el) => <InventoryBoxCard key={el._id} box={el} />)}

						{isInventoryLoading &&
							Array(itemsPerPage)
								.fill(null)
								.map((_, index) => <Skeleton key={index} style={{ width: '100%', aspectRatio: '0.65 / 1' }} />)}
					</div>

					<div ref={lastBoxRef} className="flex items-center w-full justify-center">
						{isFetchingNextPage && <LoadSpinner size="24px" />}
					</div>
				</div>
			)}
		</div>
	);
}
