import { CheckBox } from 'components';

interface Props {
	isExpressShipping: boolean;
	onIsExpressShippingChange: () => void;
}

export function ExpressShippingBtn({ isExpressShipping, onIsExpressShippingChange }: Props) {
	return (
		<button
			onClick={() => onIsExpressShippingChange()}
			className="border-[3px] rounded-[24px] border-white01 hover:border-[rgba(255,255,255,0.2)] h-[30px] px-[20px] space-x-[12px] flex items-center justify-center text-[13px]"
		>
			<CheckBox
				checked={isExpressShipping}
				borderColor="rgba(255,255,255,0.2)"
				borderWidth="2px"
				backgroundColor="white"
				borderRadius="4px"
				strokeColor="#1A1C1E"
				size={{ height: '15px', width: '15px' }}
			/>
			<span className="text-white font-semibold">Express Shipping</span>
		</button>
	);
}
