import { useKeywordSuggestionBoxSearch } from 'hooks/data/box/useKeywordSuggestionBoxSearch';
import React from 'react';

interface Props {
	searchInput: string;
	onSearchInputChange: (keyword: string) => void;
}

export function SearchKeywordSuggestions({ searchInput, onSearchInputChange }: Props) {
	const { data: keywordSuggestions } = useKeywordSuggestionBoxSearch({
		query: searchInput,
	});

	return (
		<div className="min-h-[50px] sm:min-h-[65px] ms:flex items-start mb-[23px] space-x-[12px]">
			<h2 className="hidden sm:block text-[16px] text-[rgba(255,255,255,0.7)] font-semibold min-w-fit">
				More to discover:
			</h2>
			<div className="flex items-center flex-wrap gap-y-[1px] gap-x-[6px] sm:gap-x-[8px] sm:gap-y-[8px]">
				{keywordSuggestions?.map((keyword, index) => (
					<React.Fragment key={keyword}>
						<button
							onClick={() => onSearchInputChange(keyword)}
							className="text-white text-[13px] sm:text-[16px] break-words font-semibold"
						>
							{keyword}
						</button>
						{index !== keywordSuggestions.length - 1 && <span className="text-[rgba(255,255,255,0.7)]">|</span>}
					</React.Fragment>
				))}
			</div>
		</div>
	);
}
