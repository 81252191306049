import { IconLeftArrow } from 'assets/icons/IconLeftArrow';
import { LogoBlue } from 'assets/images';
import { MobileWallet } from 'components/layout/nav/mobile/MobileWallet';
import { useUserContext } from 'hooks';
import { ReactNode } from 'react';

import { MobileRegisterBtn } from '../../components/MobileRegisterBtn';

interface Props {
	onLogoClick: () => void;
	onIconClick: () => void;
	icon?: ReactNode;
	displayLogin?: boolean;
}

export function MobileMenuHeader({
	displayLogin,
	onLogoClick,
	onIconClick,
	icon = <IconLeftArrow height={20} width={20} />,
}: Props) {
	const { user } = useUserContext();
	return (
		<div className="flex items-center justify-between w-full">
			<div className="flex items-center space-x-[8px]">
				<button
					onClick={onIconClick}
					className="text-[#7B7878] bg-[rgba(0,0,0,0.35)] rounded-[12px] flex items-center justify-center w-[37px] h-[37px]"
				>
					{icon}
				</button>
				<button onClick={onLogoClick}>
					<img src={LogoBlue} className="h-[29px]" alt="Mrloot Logo" />
				</button>
			</div>
			{user && <MobileWallet />}
			{!user && displayLogin && <MobileRegisterBtn />}
		</div>
	);
}
