import { countries } from 'country-data';
import { useOutsideClick } from 'hooks';
import { ShippingAddressInterface } from 'interfaces/OrderInterfaces';
import { useRef, useState } from 'react';
import { FieldError, UseFormRegister, UseFormSetValue } from 'react-hook-form';

import { PhoneInputField } from './PhoneInputField';

interface CountryOption {
	name: string;
	flagUrl: string;
	phonePrefix: string;
}

interface Props {
	register: UseFormRegister<ShippingAddressInterface>;
	setValue: UseFormSetValue<ShippingAddressInterface>;
	error?: FieldError;
}

const sortedCountries: CountryOption[] = countries.all
	.filter((country) => country.countryCallingCodes[0]) // filter countries that have a calling code
	.map((country) => ({
		name: country.name,
		flagUrl: `https://flagcdn.com/w20/${country.alpha2.toLowerCase()}.png`,
		phonePrefix: country.countryCallingCodes[0],
	}))
	.sort((a, b) => a.name.localeCompare(b.name));

const mostCommonCountries: CountryOption[] = [
	{ flagUrl: 'https://flagcdn.com/w20/us.png', name: 'United States', phonePrefix: '+1' },
	{ flagUrl: 'https://flagcdn.com/w20/gb.png', name: 'United Kingdom', phonePrefix: '+44' },
	{ flagUrl: 'https://flagcdn.com/w20/de.png', name: 'Germany', phonePrefix: '+49' },
];

export function PhoneInputs({ register, setValue, error }: Props) {
	const [isDropdownExtended, setIsDropdownExtended] = useState(false);
	const [selectedCountry, setSelectedCountry] = useState<CountryOption>({
		flagUrl: 'https://flagcdn.com/w20/de.png',
		name: 'Germany',
		phonePrefix: '+49',
	});

	const phonePrefixBtnRef = useRef<HTMLButtonElement>(null);
	const phonePrefixDropdownRef = useRef<HTMLDivElement>(null);

	useOutsideClick([phonePrefixBtnRef, phonePrefixDropdownRef], () => {
		setIsDropdownExtended(false);
	});

	function handleCountryChange(countryOption: CountryOption) {
		setSelectedCountry(countryOption);
		setValue('phonePrefix', countryOption.phonePrefix);
		setIsDropdownExtended(false);
	}

	return (
		<div className="relative w-full flex justify-between gap-x-[8px]">
			<button
				ref={phonePrefixBtnRef}
				type="button"
				onClick={() => setIsDropdownExtended((prev) => !prev)}
				className="relative h-[60px] w-[calc(35%-4px)] flex items-center justify-center gap-x-[7px] rounded-[4px] bg-white"
			>
				<img src={selectedCountry?.flagUrl} alt={selectedCountry?.name} className="h-[22px] w-[25px] rounded-[4px]" />
				<span className="font-semibold">{selectedCountry?.phonePrefix}</span>

				{isDropdownExtended && (
					<div
						className="absolute left-[50%] translate-x-[-50%] bottom-0 translate-y-[100%] bg-white w-[25px] h-[15px]"
						style={{ clipPath: 'polygon(50% 0%, 0% 100%, 100% 100%)' }}
					/>
				)}
			</button>

			<PhoneInputField register={register} error={error} />

			{isDropdownExtended && (
				<div
					ref={phonePrefixDropdownRef}
					className="bg-white shadow-[4px_4px_4px_rgba(0,0,0,0.5)] absolute left-0 right-0 top-0 py-[7px] translate-y-[70px] rounded-[4px]"
				>
					<ul className="flex flex-col overflow-y-auto max-h-[230px]  ">
						{mostCommonCountries.map((country, index) => (
							<li key={index} className="w-full ">
								<button
									type="button"
									onClick={() => handleCountryChange(country)}
									className={`${index === mostCommonCountries.length - 1 && 'border-b-[2px] border-[rgba(0,0,0,0.4)]'} w-full flex items-center gap-[10px] px-[15px] py-[9px] font-semibold text-[15px]`}
								>
									<img
										src={country.flagUrl}
										alt={country.name}
										className="h-[20px] w-[23px] rounded-[4px] !mr-[12px]"
									/>
									<span>{country.name}</span>
									<span> ({country.phonePrefix})</span>
								</button>
							</li>
						))}
						{sortedCountries.map((country, index) => (
							<li key={index} className="w-full">
								<button
									type="button"
									onClick={() => handleCountryChange(country)}
									className="w-full flex items-center gap-[10px] px-[15px] py-[9px] font-semibold text-[15px]"
								>
									<img
										src={country.flagUrl}
										alt={country.name}
										className="h-[20px] w-[23px] rounded-[4px] !mr-[12px]"
									/>
									<span>{country.name}</span>
									<span> ({country.phonePrefix})</span>
								</button>
							</li>
						))}
					</ul>
				</div>
			)}
		</div>
	);
}
