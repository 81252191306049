import { useCallback, useEffect } from 'react';

import { GridSearchBoxes } from './components/GridSearchBoxes';
import { SearchInputField } from './components/SeachInputField';
import { SearchKeywordSuggestions } from './components/SearchKeywordSuggestions';
import { useSearchStore } from './store/SearchStore';

export function DesktopSearch() {
	const { searchQuery, setSearchQuery } = useSearchStore();

	useEffect(() => {
		console.count();
	});

	return (
		<div className="pb-10 mt-[63px] flex flex-col">
			<SearchInputField searchInput={searchQuery} onSearchInputChange={(v) => setSearchQuery(v)} />

			<SearchKeywordSuggestions
				searchInput={searchQuery}
				onSearchInputChange={useCallback((v) => setSearchQuery(v), [setSearchQuery])}
			/>

			<GridSearchBoxes searchInput={searchQuery} />
		</div>
	);
}
