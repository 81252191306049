import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

interface Props {
	register: UseFormRegisterReturn;
}

export function OptionalShippingInstructions({ register }: Props) {
	const [showInput, setShowInput] = useState(false);
	return (
		<>
			{!showInput && (
				<button
					onClick={() => setShowInput(true)}
					className="text-mrLootBlue font-semibold text-[15px] gap-x-[10px] flex items-center"
				>
					<FontAwesomeIcon icon={faPlus} className="" /> <span>Add Additional Shipping Instructions</span>
				</button>
			)}
			{showInput && (
				<textarea
					{...register}
					placeholder="Shipping Instructions (Optional)"
					className="h-[105px] rounded-[4px] w-full outline-none border-[2px] border-white bg-white px-[20px] py-[10px]"
				/>
			)}
		</>
	);
}
