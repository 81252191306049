import { FontSold } from 'assets/images';
import { AmountDisplayWithCurrency } from 'components/common/AmountDisplayWithCurrency';
import { ItemInterface } from 'interfaces/ItemInterfaces';
import { getPlusImageByNumber } from 'utils';

import { CLOUDFRONT_IMG_URL } from '../../constants';

interface Props {
	displayedItem: ItemInterface;
	totalValue: number;
	itemCount: number;
}

const MAX_IMG_NUMBER = 51;

export function ToastMultipleSoldNotification({ displayedItem, totalValue, itemCount }: Props) {
	return (
		<div className="text-white w-full h-[62px] sm:h-[78px] flex flex-col items-start justify-between gap-y-[10px]">
			<img src={FontSold} className="h-[20px]" alt="new Loot" />
			<div className="flex items-center w-full gap-x-[17px]">
				<div className="relative h-[35px] sm:h-[45px] w-fit min-w-fit">
					<img
						src={`${CLOUDFRONT_IMG_URL}/${displayedItem.imageUrls.qualityMedium}`}
						alt={displayedItem.name}
						className="h-full object-scale-down min-w-fit"
					/>
					{itemCount < MAX_IMG_NUMBER && (
						<img
							src={getPlusImageByNumber(itemCount - 1)}
							className="absolute bottom-0 left-[50%] translate-x-[-50%] translate-y-[30%] h-[25px]"
							alt={`additional ${itemCount - 1}`}
						/>
					)}
				</div>

				<h2 className="text-white line-clamp-1 font-bold text-[15px] max-w-[50%]">{displayedItem.name}</h2>
				<div className="text-white ml-auto flex items-center justify-center gap-x-[6px] border-[2px] border-white rounded-[24px] h-[35px] px-[17px]">
					<AmountDisplayWithCurrency
						amount={totalValue}
						amountStyle={{ textWrap: 'nowrap', whiteSpace: 'nowrap', fontSize: '14px' }}
						imgStyle={{ height: '16px' }}
						useDivWrapper={false}
					/>
				</div>
			</div>
		</div>
	);
}
