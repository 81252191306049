import { ToastErrorNotification } from 'components/toasts/ToastErrorNotification';
import { ToastSuccessNotification } from 'components/toasts/ToastSuccessNotification';
import { useScrollLock, useUserContext } from 'hooks';
import { ShippingAddressInterface } from 'interfaces/OrderInterfaces';
import { useUpdateUserAddress } from 'pages/checkout/hooks/useUpdateUserAddress';
import { toast } from 'react-toastify';

import { WithSecondaryPopup } from '../WithSecondaryPopup';
import { AddressForm } from './AddressForm';

interface Props {
	onClose: () => void;
	address: ShippingAddressInterface;
}

export function EditAddressPopup({ onClose, address }: Props) {
	const { load: reloadUser } = useUserContext();

	const { mutate: updateUserAddressToDB, isLoading: isAddRequestLoading } = useUpdateUserAddress({
		onUpdate: () => {
			toast(<ToastSuccessNotification message="'Address was updated" />);

			reloadUser();
			onClose();
		},
		onError: (msg) => {
			toast(<ToastErrorNotification message={msg} />);
		},
	});

	useScrollLock();

	return (
		<WithSecondaryPopup
			onClose={onClose}
			containerStyle={{
				maxHeight: 'calc(90vh - 63px)',
				width: 'min(80vw, 900px)',
			}}
		>
			<h1 className="text-[20px] font-bold mb-[18px]">Edit Delivery Address</h1>
			<div className="overflow-auto max-h-[80%] w-full">
				<AddressForm
					isLoading={isAddRequestLoading}
					onSubmit={(data) => updateUserAddressToDB({ addressId: data._id, address: data })}
					submitBtnLabel="Update Address"
					address={address}
				/>
			</div>
		</WithSecondaryPopup>
	);
}
