import axios from 'axios';
import { ItemInterface } from 'interfaces/ItemInterfaces';
import { useQuery } from 'react-query';

import { API_DOMAIN, FIVE_MINUTES_IN_MILLISECONDS, QUERY_KEYS } from '../../../constants';

async function findItemsByTags(tags: string[]) {
	try {
		const response = await axios.post(`${API_DOMAIN.mrlootBackend}/api/items/by-tags`, {
			tags,
		});

		if (response.data.status === 'success') {
			return response.data.data;
		}
	} catch (err) {
		if (axios.isAxiosError(err)) {
			const message = err.response?.data?.message || err.message || 'Something unexpected went wrong';
			throw new Error(message);
		}
		throw err;
	}
}

export function useItemsByTags(tags: string[]) {
	return useQuery<ItemInterface[]>({
		queryKey: [QUERY_KEYS.itemData, 'tags', tags],
		queryFn: () => findItemsByTags(tags),
		cacheTime: FIVE_MINUTES_IN_MILLISECONDS,
		staleTime: FIVE_MINUTES_IN_MILLISECONDS,
	});
}
