import { FeatureBanners } from './desktop/FeatureBanners/FeatureBanners';
import { RecommendedBoxCarousels } from './desktop/RecommendedBoxCarousels';

export function DesktopHome() {
	return (
		<>
			<FeatureBanners />
			<RecommendedBoxCarousels />
		</>
	);
}
