import { useEffect } from 'react';

export function useInfiniteScroll(
	observerElementRef: React.RefObject<HTMLDivElement>,
	fetchMoreFunc: () => void,
	itemCount?: number
) {
	useEffect(() => {
		const currentElement = observerElementRef.current;
		if (!currentElement) {
			return;
		}

		const observer = new IntersectionObserver(
			(entries) => {
				const first = entries[0];
				if (first.isIntersecting) {
					fetchMoreFunc();
				}
			},
			{ threshold: 1.0 }
		);

		observer.observe(currentElement);

		return () => {
			observer.disconnect();
		};
	}, [observerElementRef, fetchMoreFunc, itemCount]); // depends also on itemCount to retrigger hook when item len changes
}
