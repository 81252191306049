import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
	onCollapse: () => void;
}

export function BottomCheckoutExtendedHeader({ onCollapse }: Props) {
	return (
		<div className="w-full flex justify-between items-center mt-[15px]">
			<h2 className="text-[18px] font-semibold">Order Review</h2>
			<button onClick={onCollapse} className="text-mrLootBlue text-[15px] p-1">
				<FontAwesomeIcon icon={faChevronDown} />
			</button>
		</div>
	);
}
