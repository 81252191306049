import { gsap } from 'gsap/gsap-core';
import { ToastNewLootNotification } from 'pages/OpenBox/components/ToastNewLootNotification';
import { useBoxOpeningStoreDesktop } from 'pages/OpenBox/store/useBoxOpeningStoreDesktop';
import { RefObject } from 'react';
import { toast } from 'react-toastify';

interface Props {
	prizeImgRef: RefObject<HTMLImageElement>;
	parentRef: RefObject<HTMLDivElement>;
	contentWrapperRef: RefObject<HTMLDivElement>;
}

export function useAddToInventoryBtn({ parentRef, prizeImgRef, contentWrapperRef }: Props) {
	const { setShowWonScreen, setIsBoxOpening, wonPrize, setAutoSpinCount } = useBoxOpeningStoreDesktop();

	function handleAddToInventory() {
		setIsBoxOpening(false);
		const prizeImgEl = prizeImgRef.current;
		const contentWrapperEl = contentWrapperRef.current;

		if (prizeImgEl && contentWrapperEl) {
			const tl = gsap.timeline();
			tl.to(parentRef.current, {
				duration: 0.7,
				opacity: 0,
				ease: 'power1.out',
			});
			tl.to(
				prizeImgEl,
				{
					duration: 1.2,
					ease: 'power3.out',
					y: () => {
						// Get the inventory button element by ID
						const inventoryBtn = document.getElementById('inventory-topbar-link');
						if (!inventoryBtn) {
							console.error('Inventory button not found!');
							return 0;
						}

						const inventoryBtnRect = inventoryBtn.getBoundingClientRect();
						const PrizeImgRect = prizeImgEl.getBoundingClientRect();

						const inventoryBtnCenterY = inventoryBtnRect.top + inventoryBtnRect.height / 2;
						const prizeImgCenterY = PrizeImgRect.top + PrizeImgRect.height / 2;
						const translateY = inventoryBtnCenterY - prizeImgCenterY;

						return translateY;
					},
					x: () => {
						const inventoryBtn = document.getElementById('inventory-topbar-link');
						if (!inventoryBtn) {
							console.error('Inventory button not found!');
							return 0;
						}

						const inventoryBtnRect = inventoryBtn.getBoundingClientRect();
						const PrizeImgRect = prizeImgEl.getBoundingClientRect();

						const inventoryBtnCenterX = inventoryBtnRect.left + inventoryBtnRect.width / 2;
						const prizeImgCenterX = PrizeImgRect.left + PrizeImgRect.width / 2;
						const translateX = inventoryBtnCenterX - prizeImgCenterX;

						return translateX;
					},
					zIndex: 50,
				},
				'<'
			);
			tl.to(
				prizeImgEl,
				{
					scale: 0,
					duration: 1.2,
					ease: 'power1.inOut',
					onComplete: () => {
						gsap.set(prizeImgEl, {
							z: 30,
						});
						if (wonPrize) {
							toast(<ToastNewLootNotification item={wonPrize?.data} />);
						}
						setShowWonScreen(false);
						setAutoSpinCount(1);
					},
				},
				'<'
			);
		} else {
			if (wonPrize) {
				toast(<ToastNewLootNotification item={wonPrize?.data} />);
			}
			setShowWonScreen(false);
			setAutoSpinCount(1);
		}
	}

	return { handleAddToInventory };
}
