interface Props {
	stepNumber: number;
	heading: string;
}

export const StepHeading = ({ stepNumber, heading }: Props) => (
	<div className="flex items-center space-x-[20px] w-full">
		<div className="rounded-full h-[40px] w-[40px] text-[18px] flex items-center justify-center text-mrLootBlue font-semibold font-inter border-[2px] border-mrLootBlue">
			{stepNumber}
		</div>
		<h2 className="text-white font-bold text-[17px]">{heading}</h2>
	</div>
);
