import axios from 'axios';
import { PopulatedBoxInterface } from 'interfaces/BoxInterface';
import { useQuery } from 'react-query';

import { API_DOMAIN, QUERY_CACHE_CONFIG, QUERY_KEYS } from '../../../constants';

async function fetchCheapBoxContainer(): Promise<ReturnType> {
	try {
		const response = await axios.get(`${API_DOMAIN.mrlootBackend}/api/box-recommendations/cheap-box-container`);

		if (response.data.status === 'success') {
			return response.data.data;
		}
		throw new Error('Failed to load data');
	} catch (err) {
		throw err;
	}
}

interface ReturnType {
	boxes: PopulatedBoxInterface[];
	containerName: string;
}

export function useCheapBoxContainer() {
	return useQuery<ReturnType>({
		queryKey: [QUERY_KEYS.boxContainer, QUERY_KEYS.cheapBoxes],
		queryFn: fetchCheapBoxContainer,
		cacheTime: QUERY_CACHE_CONFIG.longTerm.cacheTime,
		staleTime: QUERY_CACHE_CONFIG.longTerm.staleTime,
	});
}
