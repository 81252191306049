import { LogoBlue, OpenFont } from 'assets/images';
import { AmountDisplayWithCurrency } from 'components/common/AmountDisplayWithCurrency';
import { PrimaryBtn } from 'components/common/buttons/PrimaryBtn';
import { BoxInterface } from 'interfaces/BoxInterface';
import { useNavigate } from 'react-router-dom';

import { CLOUDFRONT_IMG_URL } from '../../../../constants';
import { BoxTimeOrQuantityLimit } from './BoxTimeOrQuantityLimit';

interface Props {
	box: BoxInterface;
}

// aspect-[3/1] lg:aspect-[3.3/1] xl:aspect-[3.6/1]

export function FeatureBoxCard({ box }: Props) {
	const navigate = useNavigate();

	const navigateToBox = (event: React.MouseEvent | React.KeyboardEvent) => {
		if (event.type === 'click' || (event.type === 'keydown' && (event as React.KeyboardEvent).key === 'Enter')) {
			const formattedName = box.name.replace(/ /g, '-');
			navigate(`/boxes/open/${formattedName}`);
		}
	};

	return (
		<div
			onClick={navigateToBox}
			onKeyDown={navigateToBox}
			tabIndex={0}
			role="button"
			className={`relative cursor-pointer w-full min-w-full aspect-[3/1] lg:aspect-[3.3/1] xl:aspect-[3.6/1] bg-center bg-cover`}
			style={{
				backgroundImage: `url("${CLOUDFRONT_IMG_URL}/${box.withoutTitleLandscapeImageUrls?.qualityHigh}")`,
			}}
		>
			<BoxTimeOrQuantityLimit box={box} />

			<div className="flex flex-col justify-end absolute z-20 top-0 h-full left-[25px] pb-[2.5%] space-y-[20px]">
				<div className="flex items-center space-x-[14px]">
					<img alt="" src={LogoBlue} className="h-[20px] lg:h-[1rem] xl:h-[30px]" />
					<span className="text-white text-[13px] tracking-[0.34em] font-semibold font-sans">EXCLUSIVE</span>
				</div>

				{box.titleImageUrls && (
					<img
						src={`${CLOUDFRONT_IMG_URL}/${box.titleImageUrls?.qualityMedium}`}
						alt={box.name}
						className="h-[90px] max-w-[90%] object-scale-down w-fit"
					/>
				)}
				{!box.titleImageUrls && (
					<h1
						className="font-bold max-w-[min(550px,35vw)] uppercase text-white font-sans text-[2.5rem] leading-[2.7rem] lg:leading-[3.3rem] lg:text-[4.3rem] xl:text-[5.5rem] xl:leading-[4.8rem] tracking-[-0.04em] mb-5 mt-1 xl:mb-6"
						style={{ textShadow: '0px 4px 4px rgba(0,0,0,0.35)' }}
					>
						{box.name}
					</h1>
				)}

				<div className="flex items-center space-x-[12px] text-[12px]">
					<PrimaryBtn
						content={<img src={OpenFont} alt="Open Btn" className="h-[15px] object-scale-down" />}
						renderEllipse={false}
						style={{ width: '110px', height: '38px' }}
					/>
					<div className="relative px-[8px] h-[32px] rounded-full border-white border-[2px] flex items-center justify-between space-x-[5px] text-[12px] text-white">
						<AmountDisplayWithCurrency
							amount={box.price}
							useDivWrapper={false}
							imgStyle={{ height: '13px' }}
							amountStyle={{ fontWeight: 'bold' }}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
