import { EventEmitter } from 'events';

// Create a singleton EventEmitter instance
class BackButtonEmitter extends EventEmitter {}

const backButtonEmitter = new BackButtonEmitter();

// Constants for event names
const BACK_BUTTON_CLICKED = 'backButtonClicked';

// Utility functions to handle back button events
const emitTopbarBackButtonClicked = () => {
	backButtonEmitter.emit(BACK_BUTTON_CLICKED);
};

const onTopbarBackButtonClicked = (callback: () => void) => {
	backButtonEmitter.on(BACK_BUTTON_CLICKED, callback);
};

const offTopbarBackButtonClicked = (callback: () => void) => {
	backButtonEmitter.off(BACK_BUTTON_CLICKED, callback);
};

export { emitTopbarBackButtonClicked, offTopbarBackButtonClicked, onTopbarBackButtonClicked };
