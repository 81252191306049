/* eslint-disable complexity */
import 'swiper/css/bundle';

import { Skeleton } from 'components';
import { useCarouselBtns } from 'components/data-display/carousel/hooks/useCarouselBtns';
import { useWindowDimensions } from 'hooks';
import { PopulatedBoxInterface } from 'interfaces/BoxInterface';
import { TOP10_CARD_HEIGHT_TO_WIDTH_RATIO } from 'pages/overview/home.contants';
import { CSSProperties, useEffect, useRef, useState } from 'react';

import { screens } from '../../../../constants';
import { useLoadTopBoxes } from '../../hooks/useLoadTopBoxes';
import { NextBtn } from './NextBtn';
import { PrevBtn } from './PrevBtn';
import { Top10BoxHighlightViewer } from './Top10BoxHighlightViewer';
import { Top10BoxSlide } from './Top10BoxSlide';

const DEFAULT_BOX_LEN = 10;

interface Props {
	style?: CSSProperties;
}

export default function Top10BoxCarousel({ style }: Props) {
	const scrollRef = useRef<HTMLDivElement>(null);
	const parentRef = useRef<HTMLDivElement>(null);

	const carouselSwiperRef = useRef<HTMLDivElement>(null);
	const carouselContainerRef = useRef<HTMLDivElement>(null);

	const { screenWidth } = useWindowDimensions();

	// eslint-disable-next-line no-magic-numbers
	const cardHeight = screenWidth >= screens.lg.width ? 190 : 160;
	const spaceBetween = 25;
	const slidesPerGroup = Math.floor(
		(carouselContainerRef.current?.clientWidth ?? 0) / (cardHeight * TOP10_CARD_HEIGHT_TO_WIDTH_RATIO + spaceBetween)
	);

	const {
		handleBtnNext,
		handleBtnPrev,
		showNextBtn,
		showPrevBtn,
		isAnimating: isCarouselAnimating,
	} = useCarouselBtns({
		carouselSwiperRef,
		slidesPerGroup,
	});
	const { data: boxes, isLoading: isTopBoxesLoading } = useLoadTopBoxes();

	const [selectedBox, setSelectedBox] = useState<PopulatedBoxInterface>();

	useEffect(() => {
		if (boxes && boxes.length > 0) {
			setSelectedBox(boxes[0]);
		}
	}, [boxes]);

	return (
		<div
			ref={parentRef}
			className={`'relative w-full ${isTopBoxesLoading ? 'h-fit' : 'h-[calc(100vh-100px)]'}`}
			style={style}
		>
			{!isTopBoxesLoading && (
				<Top10BoxHighlightViewer selectedBox={selectedBox} parentRef={parentRef} scrollRef={scrollRef} />
			)}

			<div ref={scrollRef} className="sticky z-30 w-full" style={{ top: `calc(100% - 300px)` }}>
				<div className="relative z-10 flex items-center mb-2">
					<span className="text-white text-[18px] font-semibold font-inter">Top 10</span>
				</div>
				<div
					ref={carouselContainerRef}
					className="overflow-x-hidden relative -left-desktop-right-padding w-[calc(100%+40px)]"
				>
					<div
						ref={carouselSwiperRef}
						style={{ columnGap: spaceBetween }}
						className="no-scrollbar overflow-x-auto flex items-center pl-desktop-right-padding"
					>
						{!isTopBoxesLoading &&
							boxes?.map((el, index) => (
								<Top10BoxSlide
									onHover={() => setSelectedBox(el)}
									key={el._id}
									box={el}
									cardHeight={cardHeight}
									position={index}
									isSelected={el._id === selectedBox?._id}
								/>
							))}
						{isTopBoxesLoading &&
							Array.from({ length: DEFAULT_BOX_LEN })
								.fill(null)
								.map((_, index) => (
									<Skeleton key={index} style={{ height: cardHeight, aspectRatio: '3 / 2', width: 'auto' }} />
								))}
					</div>

					{!isCarouselAnimating && showPrevBtn && <PrevBtn onClick={handleBtnPrev} />}
					{!isCarouselAnimating && showNextBtn && <NextBtn onClick={handleBtnNext} />}
				</div>
			</div>
		</div>
	);
}
