import { ItemInterface } from 'interfaces/ItemInterfaces';
import React, { useMemo } from 'react';

import { ItemCard, SimpleItemCard } from '../item-cards';

interface Props {
	items: ItemInterface[];
	boxWidth?: number;
	bigItemWrapperRef: React.RefObject<HTMLDivElement>;
	smallItemWrapperRef: React.RefObject<HTMLDivElement>;
	parentDimensions: { width: number; height: number };
}

const MAX_WIDTH_TO_SHOW_SIMPLE_CARD = 120;
const ONLY_BIG_CARD_RATIO = 0.8;
const WITH_SMALLCARDS_RATIO = 0.67;

export function ItemPreview({ items, boxWidth, smallItemWrapperRef, bigItemWrapperRef, parentDimensions }: Props) {
	const showOnlyBigCard = parentDimensions.width >= MAX_WIDTH_TO_SHOW_SIMPLE_CARD;

	const { height: cardHeight, width: cardWidth } = useMemo(() => {
		const showOnlyBigCard = parentDimensions.width <= MAX_WIDTH_TO_SHOW_SIMPLE_CARD;
		const width = showOnlyBigCard
			? parentDimensions.width * ONLY_BIG_CARD_RATIO
			: parentDimensions.width * WITH_SMALLCARDS_RATIO;
		const height = showOnlyBigCard
			? parentDimensions.height * ONLY_BIG_CARD_RATIO
			: parentDimensions.height * WITH_SMALLCARDS_RATIO;
		return { width, height };
	}, [parentDimensions]);

	return (
		<>
			<div ref={bigItemWrapperRef} className={`flex items-center `}>
				{items.map((item, index) => (
					<div key={index} style={{ width: `${boxWidth}px` }} className={`flex items-center justify-center`}>
						<ItemCard
							item={item}
							style={{
								width: `${cardWidth}px`,
								height: `${cardHeight}px`,
							}}
							showBrandname={false}
						/>
					</div>
				))}
			</div>
			{showOnlyBigCard && (
				<div ref={smallItemWrapperRef} className={`flex items-center space-x-[8px] pl-2 `}>
					{items.map((item) => (
						<SimpleItemCard key={item._id} item={item} />
					))}
				</div>
			)}
		</>
	);
}
