import { IconLeftArrow } from 'assets/icons/IconLeftArrow';
import { LogoBlue } from 'assets/images';
import { Link, useNavigate } from 'react-router-dom';

interface Props {
	onBackClick?: () => void;
}

export function LoginHeader({ onBackClick }: Props) {
	const navigate = useNavigate();

	function handleBackClick() {
		if (onBackClick) {
			onBackClick();
			return;
		}
		navigate('/');
	}

	return (
		<div className="flex items-center space-x-[8px]">
			<button
				type="button"
				onClick={handleBackClick}
				className="text-[#7B7878] bg-[rgba(0,0,0,0.35)] rounded-[12px] flex items-center justify-center w-[37px] h-[37px]"
			>
				<IconLeftArrow height={20} width={20} />
			</button>
			<Link to="/">
				<img src={LogoBlue} className="h-[29px]" />
			</Link>
		</div>
	);
}
