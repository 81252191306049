import { gsap } from 'gsap';
import { useCallback, useEffect, useRef, useState } from 'react';

interface Props {
	slideWrapperRef: React.RefObject<HTMLDivElement>;
	slideLength: number;
	slideTime?: number;
}

export function useAutoSlideAnimation({ slideWrapperRef, slideLength, slideTime = 5500 }: Props) {
	const [activeIndex, setActiveIndex] = useState(0);
	const slideInterval = useRef<NodeJS.Timeout | null>(null);

	const animateSlide = useCallback(
		(nextIndex: number) => {
			if (slideWrapperRef.current) {
				gsap.to(slideWrapperRef.current, {
					duration: 0.5,
					x: `-${nextIndex * 100}%`,
					ease: 'power1.out',
				});
			}
		},
		[slideWrapperRef]
	);

	const startAutoSlide = useCallback(() => {
		slideInterval.current = setInterval(() => {
			setActiveIndex((prevIndex) => {
				const nextIndex = (prevIndex + 1) % slideLength;
				animateSlide(nextIndex);
				return nextIndex;
			});
		}, slideTime);
	}, [animateSlide, slideLength, slideTime]);

	useEffect(() => {
		startAutoSlide();

		return () => {
			if (slideInterval.current) {
				clearInterval(slideInterval.current);
			}
		};
	}, [activeIndex, slideLength, startAutoSlide]);

	const handleManualSlideChange = (index: number) => {
		if (slideInterval.current) {
			clearInterval(slideInterval.current);
		}

		setActiveIndex(index);
		animateSlide(index);

		startAutoSlide();
	};

	return { activeIndex, handleManualSlideChange };
}
