import { ItemInterface } from 'interfaces/ItemInterfaces';
import { SelectedItemOption, SelectedOptionSet } from 'pages/checkout/InterfacesCheckout';
import { generateObjectId } from 'utils';

import { BasketItemOption } from './shop.interfaces';

export function convertItemOptionsForCheckout(item: ItemInterface, options: BasketItemOption[], quantity: number) {
	const optionSets: SelectedOptionSet[] = [];
	if (!item.options || item.options.length === 0) {
		return undefined;
	}
	for (let i = 0; i < quantity; i++) {
		const optionSelections: SelectedItemOption[] = options.map((option) => {
			return {
				_id: generateObjectId(),
				optionProperty: option.optionProperty,
				value: option.value,
				label: option.label,
			};
		});

		optionSets.push({
			_id: generateObjectId(), // This will be an ID for each option set (can be generated later)
			optionSelections,
		});
	}
	return optionSets;
}
